import React, { memo, useMemo } from 'react';
import DocumentMeta from 'react-document-meta';

import { useOfflineStoreLocationListPageMetaTitleAndDescriptionAndUrl } from './utils';

const OfflineStoreLocationListPageMeta = memo(() => {
  const { title, description, url } =
    useOfflineStoreLocationListPageMetaTitleAndDescriptionAndUrl();

  const meta = useMemo(
    () => ({
      property: {
        'og:title': title,
        'og:description': description,
        'twitter:title': title,
        'twitter:description': description,
      },
      name: {
        robots: 'index, follow',
      },
    }),
    [description, title],
  );

  const link = useMemo(
    () => ({
      rel: {
        canonical: url,
      },
    }),
    [url],
  );

  return (
    <DocumentMeta
      link={link}
      canonical={url}
      title={title}
      description={description}
      meta={meta}
      extend
    />
  );
});

export default OfflineStoreLocationListPageMeta;
