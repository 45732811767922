import update from 'immutability-helper';
import keyMirror from 'keymirror';
import { normalize } from 'normalizr';

import config from 'config';

import { AT as AT_AUTH } from 'app-libs/redux_modules/auth';

import { Schemas } from '../../entities';
import contractReducer, {
  initialState as contractInitialState,
} from './contract';
import labelReducer, { initialState as labelInitialState } from './label';
import memberReducer, { initialState as memberInitialState } from './member';
import noteReducer, { initialState as noteInitialState } from './note';
import phaseReducer, { initialState as phaseInitialState } from './phase';
import reimbursementReducer, {
  initialState as reimbursementInitialState,
} from './reimbursement/reimbursement';

export const AT = keyMirror({
  // PROJECT
  LOAD_PROJECT: null,
  LOAD_PROJECT_SUCCESS: null,
  LOAD_PROJECT_FAIL: null,
  LOAD_SUMMARY_PROJECT: null,
  LOAD_SUMMARY_PROJECT_SUCCESS: null,
  LOAD_SUMMARY_PROJECT_FAIL: null,
  LOAD_PROJECT_DESIGN_EST_COST: null,
  LOAD_PROJECT_DESIGN_EST_COST_SUCCESS: null,
  LOAD_PROJECT_DESIGN_EST_COST_FAIL: null,
  LOAD_PROJECT_BLUEPRINT: null,
  LOAD_PROJECT_BLUEPRINT_SUCCESS: null,
  LOAD_PROJECT_BLUEPRINT_FAIL: null,
  LOAD_PROJECT_SURVEY_RESULT: null,
  LOAD_PROJECT_SURVEY_RESULT_SUCCESS: null,
  LOAD_PROJECT_SURVEY_RESULT_FAIL: null,
  LOAD_PROJECTS: null,
  LOAD_PROJECTS_SUCCESS: null,
  LOAD_PROJECTS_FAIL: null,

  LOAD_CUSTOMER_PROJECTS: null,
  LOAD_CUSTOMER_PROJECTS_SUCCESS: null,
  LOAD_CUSTOMER_PROJECTS_FAIL: null,

  CREATE_DESIGN_BUILD_PROJECT: null,
  CREATE_DESIGN_BUILD_PROJECT_SUCCESS: null,
  CREATE_DESIGN_BUILD_PROJECT_FAIL: null,

  UPDATE_PROJECT: null,
  UPDATE_PROJECT_SUCCESS: null,
  UPDATE_PROJECT_FAIL: null,

  CREATE_SUMMARY_DESIGN_BUILD_PROJECT: null,
  CREATE_SUMMARY_DESIGN_BUILD_PROJECT_SUCCESS: null,
  CREATE_SUMMARY_DESIGN_BUILD_PROJECT_FAIL: null,

  // PROFESSIONAL
  LOAD_DESIGNER_STATE: null,
  LOAD_DESIGNER_STATE_SUCCESS: null,
  LOAD_DESIGNER_STATE_FAIL: null,
  LOAD_PROJECT_MANAGER_STATE: null,
  LOAD_PROJECT_MANAGER_STATE_SUCCESS: null,
  LOAD_PROJECT_MANAGER_STATE_FAIL: null,
  LOAD_BUSINESS_MANAGER_STATE: null,
  LOAD_BUSINESS_MANAGER_STATE_SUCCESS: null,
  LOAD_BUSINESS_MANAGER_STATE_FAIL: null,
  LOAD_SALES_PERSON_STATE: null,
  LOAD_SALES_PERSON_STATE_SUCCESS: null,
  LOAD_SALES_PERSON_STATE_FAIL: null,

  // SURVEY
  SEND_PROJECT_SURVEY_RESULT: null,
  SEND_PROJECT_SURVEY_RESULT_SUCCESS: null,
  SEND_PROJECT_SURVEY_RESULT_FAIL: null,

  UPDATE_PROJECT_DESCRIPTION: null,
  UPDATE_PROJECT_DESCRIPTION_SUCCESS: null,
  UPDATE_PROJECT_DESCRIPTION_FAIL: null,

  UPDATE_PROJECT_STATUS: null,
  UPDATE_PROJECT_STATUS_SUCCESS: null,
  UPDATE_PROJECT_STATUS_FAIL: null,

  UPDATE_PROJECT_CUSTOMER_VALIDATION: null,
  UPDATE_PROJECT_CUSTOMER_VALIDATION_SUCCESS: null,
  UPDATE_PROJECT_CUSTOMER_VALIDATION_FAIL: null,

  ADD_PROJECT_FEED_ITEM: null,

  // QUOTATION
  CREATE_QUOTATION: null,
  CREATE_QUOTATION_SUCCESS: null,
  CREATE_QUOTATION_FAIL: null,

  LOAD_QUOTATIONS: null,
  LOAD_QUOTATIONS_SUCCESS: null,
  LOAD_QUOTATIONS_FAIL: null,

  LOAD_QUOTATION_DETAIL: null,
  LOAD_QUOTATION_DETAIL_SUCCESS: null,
  LOAD_QUOTATION_DETAIL_FAIL: null,

  // INVOICE
  CREATE_INVOICE: null,
  CREATE_INVOICE_SUCCESS: null,
  CREATE_INVOICE_FAIL: null,

  LOAD_INVOICES: null,
  LOAD_INVOICES_SUCCESS: null,
  LOAD_INVOICES_FAIL: null,

  LOAD_INVOICE_DETAIL: null,
  LOAD_INVOICE_DETAIL_SUCCESS: null,
  LOAD_INVOICE_DETAIL_FAIL: null,

  // DASHBOARD
  OPEN_PROJECT_INFO: null,
  CLOSE_PROJECT_INFO: null,

  SHOW_LINE_RETAIL_PRICE: null,

  // WORKSHOP
  ASSIGN_WORKSHOP: null,
  ASSIGN_WORKSHOP_SUCCESS: null,
  ASSIGN_WORKSHOP_FAIL: null,
});

const initialState = {
  currentUserIsDesigner: undefined,
  currentUserIsProjectManager: undefined,
  currentUserIsBusinessManager: undefined,
  currentUserIsSalesPerson: undefined,

  currentUserProjects: [],
  currentUserDesignedProjects: [],
  currentUserManagedProjects: [],
  currentUserSalesProjects: [],

  isLoadingDesignEstCost: false,
  isLoadingProjectBlueprint: false,

  isCreatingDesignBuildProject: false,
  isUpdatingProjectDescription: false,
  isUpdatingProjectStatus: false,
  isSendingProjectSurveyResult: false,

  isCreatingSummaryDesignBuildProject: false,

  isLoadingOwnedProjects: false,
  isLoadingDesignedProjects: false,
  isLoadingManagedProjects: false,
  isLoadingSalesProjects: false,
  isLoadingSummaryProject: false,

  feedItems: [],

  settings: {
    isShowProjectInfoTab: true,
  },

  note: noteInitialState,
  phase: phaseInitialState,
  member: memberInitialState,
  reimbursement: reimbursementInitialState,
  contract: contractInitialState,
  label: labelInitialState,

  workshop: {
    postingAssignment: false,
  },

  // referral code
  referralCode: null,
};

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Reducer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export default function projectReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;
  switch (action.type) {
    case AT_AUTH.LOGIN_SUCCESS: // reset upon login
    case AT_AUTH.LOGOUT_SUCCESS: // reset upon logout
      state = initialState;
      break;
    /**
     * START Workshop States
     */
    case AT.ASSIGN_WORKSHOP:
      state = update(state, {
        workshop: {
          $merge: {
            postingAssignment: true,
          },
        },
      });
      break;
    case AT.ASSIGN_WORKSHOP_SUCCESS:
    case AT.ASSIGN_WORKSHOP_FAIL:
      state = update(state, {
        workshop: {
          $merge: {
            postingAssignment: false,
          },
        },
      });
      break;
    /**
     * START Professional States
     */
    case AT.LOAD_DESIGNER_STATE_SUCCESS:
      state = update(state, {
        $merge: {
          currentUserIsDesigner: true,
        },
      });
      break;
    case AT.LOAD_DESIGNER_STATE_FAIL:
      state = update(state, {
        $merge: {
          currentUserIsDesigner: false,
        },
      });
      break;
    case AT.LOAD_PROJECT_MANAGER_STATE_SUCCESS:
      state = update(state, {
        $merge: {
          currentUserIsProjectManager: true,
        },
      });
      break;
    case AT.LOAD_PROJECT_MANAGER_STATE_FAIL:
      state = update(state, {
        $merge: {
          currentUserIsProjectManager: false,
        },
      });
      break;
    case AT.LOAD_BUSINESS_MANAGER_STATE_SUCCESS:
      state = update(state, {
        $merge: {
          currentUserIsBusinessManager: true,
        },
      });
      break;
    case AT.LOAD_BUSINESS_MANAGER_STATE_FAIL:
      state = update(state, {
        $merge: {
          currentUserIsBusinessManager: false,
        },
      });
      break;
    case AT.LOAD_SALES_PERSON_STATE:
      state = update(state, {
        $merge: {
          currentUserIsSalesPerson: true,
        },
      });
      break;
    case AT.LOAD_SALES_PERSON_STATE_FAIL:
      state = update(state, {
        $merge: {
          currentUserIsSalesPerson: false,
        },
      });
      break;
    /**
     * END Professional States
     */

    case AT.LOAD_PROJECT_DESIGN_EST_COST:
      state = update(state, {
        $merge: {
          isLoadingDesignEstCost: true,
        },
      });
      break;
    case AT.LOAD_PROJECT_DESIGN_EST_COST_SUCCESS:
    case AT.LOAD_PROJECT_DESIGN_EST_COST_FAIL:
      state = update(state, {
        $merge: {
          isLoadingDesignEstCost: false,
        },
      });
      break;
    case AT.LOAD_PROJECT_BLUEPRINT:
      state = update(state, {
        $merge: {
          isLoadingProjectBlueprint: true,
        },
      });
      break;
    case AT.LOAD_PROJECT_BLUEPRINT_SUCCESS:
    case AT.LOAD_PROJECT_BLUEPRINT_FAIL:
      state = update(state, {
        $merge: {
          isLoadingProjectBlueprint: false,
        },
      });
      break;
    case AT.CREATE_DESIGN_BUILD_PROJECT:
      state = update(state, {
        $merge: {
          isCreatingDesignBuildProject: true,
        },
      });
      break;
    case AT.CREATE_DESIGN_BUILD_PROJECT_SUCCESS:
    case AT.CREATE_DESIGN_BUILD_PROJECT_FAIL:
      state = update(state, {
        $merge: {
          isCreatingDesignBuildProject: false,
        },
      });
      break;
    case AT.CREATE_SUMMARY_DESIGN_BUILD_PROJECT:
      state = update(state, {
        $merge: {
          isCreatingSummaryDesignBuildProject: true,
        },
      });
      break;
    case AT.CREATE_SUMMARY_DESIGN_BUILD_PROJECT_SUCCESS:
    case AT.CREATE_SUMMARY_DESIGN_BUILD_PROJECT_FAIL:
      state = update(state, {
        $merge: {
          isCreatingSummaryDesignBuildProject: false,
        },
      });
      break;
    case AT.UPDATE_PROJECT_DESCRIPTION:
      state = update(state, {
        $merge: {
          isUpdatingProjectDescription: true,
        },
      });
      break;
    case AT.UPDATE_PROJECT_DESCRIPTION_SUCCESS:
    case AT.UPDATE_PROJECT_DESCRIPTION_FAIL:
      state = update(state, {
        $merge: {
          isUpdatingProjectDescription: false,
        },
      });
      break;
    case AT.UPDATE_PROJECT_STATUS:
      state = update(state, {
        $merge: {
          isUpdatingProjectStatus: true,
        },
      });
      break;
    case AT.UPDATE_PROJECT_STATUS_SUCCESS:
    case AT.UPDATE_PROJECT_STATUS_FAIL:
      state = update(state, {
        $merge: {
          isUpdatingProjectStatus: false,
        },
      });
      break;
    case AT.SEND_PROJECT_SURVEY_RESULT:
      state = update(state, {
        $merge: {
          isSendingProjectSurveyResult: true,
        },
      });
      break;
    case AT.SEND_PROJECT_SURVEY_RESULT_SUCCESS:
    case AT.SEND_PROJECT_SURVEY_RESULT_FAIL:
      state = update(state, {
        $merge: {
          isSendingProjectSurveyResult: false,
        },
      });
      break;
    case AT.LOAD_PROJECTS:
      state = update(state, {
        $merge: {
          isLoadingOwnedProjects: true,
        },
      });
      break;
    case AT.LOAD_PROJECTS_SUCCESS:
      state = update(state, {
        $merge: {
          currentUserProjects: action.result.content,
        },
      });
    /* falls through */
    case AT.LOAD_PROJECTS_FAIL:
      state = update(state, {
        $merge: {
          isLoadingOwnedProjects: false,
        },
      });
      break;
    case AT.LOAD_SUMMARY_PROJECT:
      state = update(state, {
        $merge: {
          isLoadingSummaryProject: true,
        },
      });
      break;
    case AT.LOAD_SUMMARY_PROJECT_SUCCESS:
      state = update(state, {
        $merge: {
          currentUserSummaryProjects: action.result.content,
          isLoadingSummaryProject: false,
        },
      });
    /* falls through */
    case AT.LOAD_SUMMARY_PROJECT_FAIL:
      state = update(state, {
        $merge: {
          isLoadingSummaryProject: false,
        },
      });
      break;
    case AT.ADD_PROJECT_FEED_ITEM:
      state = update(state, {
        feedItems: { $push: [action.payload] },
      });
      break;

    case AT.OPEN_PROJECT_INFO:
      state = update(state, {
        settings: {
          $merge: {
            isShowProjectInfoTab: true,
          },
        },
      });
      break;
    case AT.CLOSE_PROJECT_INFO:
      state = update(state, {
        settings: {
          $merge: {
            isShowProjectInfoTab: false,
          },
        },
      });
      break;
    default:
      break;
  }

  /**
   * note reducers
   */
  state.note = noteReducer(state.note, action);

  /**
   * phase reducers
   */
  state.phase = phaseReducer(state.phase, action);

  /**
   * member reducers
   */
  state.member = memberReducer(state.member, action);
  /**
   * reimbursement reducers
   */
  state.reimbursement = reimbursementReducer(state.reimbursement, action);
  /**
   * label reducers
   */
  state.label = labelReducer(state.label, action);
  /**
   * contract reducers
   */
  state.contract = contractReducer(state.contract, action);

  return state;
}

export function loadSummaryProject(projectKey, publicKey, callback) {
  return {
    types: [
      AT.LOAD_SUMMARY_PROJECT,
      AT.LOAD_SUMMARY_PROJECT_SUCCESS,
      AT.LOAD_SUMMARY_PROJECT_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_CHITAURI}/portfolio-projects/${projectKey}/`,
        {
          params: {
            publicKey,
          },
        },
      ),
    isLoginRequired: false,
    options: {
      transformer: (result) => {
        const normalizedSummaryProject = normalize(
          result,
          Schemas.SUMMARY_PROJECT,
        );
        return {
          content: normalizedSummaryProject.result,
          entities: normalizedSummaryProject.entities,
        };
      },
      callback,
    },
  };
}

export function createProject(project, sourceType, callback) {
  const projectWithSourceType = {
    ...project,
    // eslint-disable-next-line camelcase
    source_type_name: sourceType,
  };
  return {
    types: [
      AT.CREATE_DESIGN_BUILD_PROJECT,
      AT.CREATE_DESIGN_BUILD_PROJECT_SUCCESS,
      AT.CREATE_DESIGN_BUILD_PROJECT_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_CHITAURI}/projects/`, {
        data: projectWithSourceType,
      }),
    options: {
      transformer: (result) => {
        const { project: projectResult } = result;
        const normalized = normalize(projectResult, Schemas.PROJECT);
        return {
          result,
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}
