import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  Colors,
  FlatList,
  ReflowView,
  StyleSheet,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import ProductVariationLabel from '../ProductVariationLabel';
import { ProductCardProduct } from '../types';

export const K_DEFAULT_WIDTH = 167.5;
const K_NUMBER_OF_PRODUCT_VARIATIONS_IN_SHOW = 3.5;
const K_PRODUCT_VARIATION_MARGIN_TOP = 8;

type ProductVariationsProps = {
  product: ProductCardProduct;
  aspectRatio?: number;
  colorSiblingProducts?: Array<ProductCardProduct>;
  width?: number;
  onClickProductVariation: (item: ProductCardProduct) => void;
};

const ProductVariations: React.FC<ProductVariationsProps> = ({
  product,
  aspectRatio,
  colorSiblingProducts,
  width,
  onClickProductVariation,
}) => {
  const cardWidth = width || K_DEFAULT_WIDTH;
  const productVariationSize = Math.floor(
    cardWidth / K_NUMBER_OF_PRODUCT_VARIATIONS_IN_SHOW,
  );
  const containerHeight = productVariationSize + K_PRODUCT_VARIATION_MARGIN_TOP;

  const renderProductVariation = ({ item }: { item: ProductCardProduct }) => {
    const isSelected = item.objectID === product.objectID;
    const borderOffset = isSelected ? 4 : 2;
    const containerStyle = isSelected
      ? s.selectedCardVariation
      : s.unselectedCardVariation;
    const imageSize = productVariationSize - borderOffset;
    const imageAdditionalQuery = {
      fit: 'bounds',
      auto: 'webp',
      optimize: 'high',
      'trim-color': 'ffffff',
      'bg-color': 'ffffff',
      pad: product.image ? 0 : 50,
    };
    return (
      <View style={[cStyles.mls, { width: productVariationSize }]}>
        <ButtonWrapper onPress={() => onClickProductVariation(item)}>
          {() => (
            <View style={containerStyle}>
              <ReflowView
                widthProportion={aspectRatio}
                heightProportion={aspectRatio}
              >
                <SmartImage
                  source={item.image}
                  sizes={`${productVariationSize}px`}
                  additionalQuery={imageAdditionalQuery}
                  width={imageSize}
                  height={imageSize}
                  resizeMode="contain"
                />
              </ReflowView>
              <ProductVariationLabel product={item} imageSize={imageSize} />
            </View>
          )}
        </ButtonWrapper>
      </View>
    );
  };

  return (
    <FlatList
      keyExtractor={(item: ProductCardProduct) => item.objectID}
      data={colorSiblingProducts}
      renderItem={renderProductVariation}
      initialNumToRender={3}
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={[
        s.productVariationsContentContainer,
        { marginTop: K_PRODUCT_VARIATION_MARGIN_TOP },
      ]}
      style={[
        s.productVariationsContainer,
        { height: containerHeight, top: cardWidth },
      ]}
    />
  );
};

const s = StyleSheet.create({
  productVariationsContainer: {
    ...cStyles.width100,
    ...cStyles.abs,
    ...cStyles.left,
  },

  productVariationsContentContainer: {
    ...cStyles.mls,
    ...cStyles.prm,
  },

  selectedCardVariation: {
    borderColor: Colors.C_SECONDARY_TOSCA_4,
    borderWidth: 2,
    overflow: 'hidden',
    ...cStyles.brm,
  },

  unselectedCardVariation: {
    borderColor: Colors.C_BLACK_20,
    overflow: 'hidden',
    ...cStyles.bwam,
    ...cStyles.brm,
  },
});
export default ProductVariations;
