import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import moment from 'moment';

import { makeParametricUrl } from 'app-libs/etc';
import { genProductUrl } from 'app-libs/etc/productHelper';
import {
  getProjectTemplateMainAttributeValue,
  getProjectTemplateStyleNames,
  getProjectTemplateTitle,
} from 'app-libs/redux_modules/entity_modules/selectors/projectTemplate';

import { K_ROUTE_POST_DETAIL } from 'constants/routeConstants';

import { genBaseUtmPropFromPath } from './base';

export function trackSignedInUser(dateJoined, usingFacebookAuth) {
  return {
    isNewUser: moment().diff(moment(dateJoined), 'minutes') <= 1,
    usingFacebookAuth,
  };
}

export function genNotificationItem(notificationItem) {
  return pick(notificationItem, [
    'title',
    'body',
    'engagementType',
    'url',
    'objectId',
  ]);
}

export function genHotspot(hotspot) {
  return pick(hotspot, ['uuid', 'title']);
}

export function genSearch(queryString, to = '') {
  return { queryString, to };
}

export function genExtraFilter(label, title, category) {
  return { label, title, category };
}

export function genProduct(product, baseUrl = '') {
  const productFeatures = get(product, 'productFeatures', []);
  const isAvailableToBuy = get(product, 'availability.isAvailableToBuy', false);
  const isHavingProductFeatures = !isEmpty(productFeatures);

  return Object.assign(
    {},
    pick(product, ['objectID', 'title', 'brand', 'url', 'price', 'categories']),
    {
      isAvailableToBuy,
      isHavingProductFeatures,
      url: genProductUrl(product, baseUrl),
      hasBundleOffer: product?.hasBundleOffer ?? false,
    },
  );
}

export function genLookSimilarProductsInfo(
  product,
  position,
  clickedFrom,
  sortIndex,
) {
  return {
    ...genProduct(product),
    position,
    clickedFrom,
    sortIndex,
  };
}

export function genChatInfo(product, clickedFrom) {
  return {
    ...genProduct(product),
    clickedFrom,
  };
}

export function genProductCellInfo(
  product,
  position,
  clickedFrom,
  sortIndex,
  usedFilter,
) {
  return {
    ...genProduct(product),
    position,
    clickedFrom,
    sortIndex,
    ...usedFilter,
  };
}

export function genAsset(asset) {
  return Object.assign(
    {},
    pick(asset, [
      'objectID',
      'title',
      'brand',
      'url',
      'assetPrice',
      'categories',
      'styles',
      'rooms',
      'isProductAvailable',
      'isDynamic',
      'isGeneric',
    ]),
  );
}

export function genProductListSer({
  products,
  query,
  breadcrumb,
  index,
  page,
  nbPages,
  roomName,
  offerName,
  openedFrom,
  url,
}) {
  /*
    @note(dika) 13 June 2022: Only show first 5 products
    Reason: amplitude has a limit of only 1024 characters
    https://help.amplitude.com/hc/en-us/articles/115002923888#h_84aa2818-b187-4a80-b838-4ebd11b36904
  */
  const productsData = products.slice(0, 5).map((product) => {
    const price = parseInt(product.price, 10);
    const siteOfferDiscountValue = product.productOffer?.discount ?? 0;
    const priceAfterDiscount = price - siteOfferDiscountValue;
    return {
      upc: product.objectID,
      title: product.title,
      price,
      priceAfterDiscount,
    };
  });
  return {
    products: productsData,
    query,
    breadcrumb,
    index,
    page,
    nbPages,
    roomName,
    offerName,
    openedFrom,
    url,
  };
}

export function genProductCategorySer(to, categoryName) {
  return {
    ...trackTransitionTo(to),
    categoryName,
  };
}

export function genProductImage(product, imageUrl, index) {
  return {
    product: genProduct(product),
    url: imageUrl,
    index,
  };
}

export function genAddToCart({
  product,
  quantity,
  addons,
  pathName,
  baseUrl,
  isWishlist,
  addToCartReferenceNote,
  addToCartReference,
}) {
  return {
    product: genProduct(product, baseUrl),
    addons: addons.map((addon) => addon.productUpc),
    quantity,
    pathName,
    addToCartReference,
    referrerUpc: addToCartReferenceNote,
    isFavorited: isWishlist,
  };
}

export function genAddOrderToCart({ order, pathName }) {
  return {
    order: genOrder(order),
    pathName,
  };
}

export function genBasketSer({ basket, wishlistItems, baseUrl }) {
  return {
    products: basket.lines.map((line) => genProduct(line.product, baseUrl)),
    ...genWishlistPins(wishlistItems),
  };
}

export function genViewedProduct({
  product,
  referrer = null,
  baseUrl,
  isWishlist,
  openedFrom,
}) {
  return {
    product: genProduct(product, baseUrl),
    moodboard: genProduct(product, baseUrl), // terlanjur salah, backward compat dulu sampai akhir tahun 2017
    pathName: referrer,
    isFavorited: isWishlist,
    openedFrom,
  };
}

export function genMoodboard(moodboard) {
  return pick(moodboard, ['id', 'title', 'itemIds']);
}

export function genViewedMoodboard(moodboard, referrer) {
  return {
    moodboard: genMoodboard(moodboard),
    pathName: referrer,
  };
}

export function genMattressSearch(filterUsed, from) {
  return { filterUsed, from };
}

export function genMattressBrand(name, from) {
  return { name, from };
}

export function genOpenedFrom(from) {
  return { from };
}

export function genCustomerAddress(address) {
  return { address };
}

export function genPaymentMethod(paymentMethod) {
  return { paymentMethod };
}

export function genRequestVerifyPayment(order, reference) {
  return {
    order,
    reference,
  };
}

export function genPurchase(order, lines, wishlistItems, paymentMethod) {
  return {
    order: Object.assign({}, order, {
      lines:
        [] /** Amplitude Cannot have object nested in an array property value */,
      linesId: lines.map((line) => line.product.objectID),
      ...genWishlistPins(wishlistItems),
      paymentMethod,
    }),
  };
}

export function genPurchaseSingle(order, line) {
  const { product, quantity } = line;
  return {
    order: {
      number: order.number,
    },
    product: genProduct(product),
    quantity,
  };
}

export function genQuotation(quotation) {
  return {
    ...quotation,
    lines:
      [] /** Amplitude Cannot have object nested in an array property value */,
    linesId: quotation.lines.map((line) => line.product.upc),
  };
}

export function genOrder(order) {
  return {
    ...order,
    lines:
      [] /** Amplitude Cannot have object nested in an array property value */,
    linesId: order.lines.map((line) => line.product.upc),
  };
}

export function trackTransitionTo(to, pathName = null) {
  return {
    to,
    pathName,
  };
}

export function genAdBanner(bannerName, bannerLocation, to) {
  return {
    bannerName,
    bannerLocation,
    to,
  };
}

export function trackSimilarProduct(mainProduct, product, productIndex) {
  return {
    product: genProduct(mainProduct),
    similarProduct: {
      ...genProduct(product),
      similarScores: product.similarScores,
    },
    similarProductIndex: productIndex,
  };
}

export function trackBestSeller(product, productIndex) {
  return {
    product: genProduct(product),
    bestSellerProductIndex: productIndex,
  };
}

export function trackRelatedProduct(mainProduct, product, productIndex) {
  return {
    product: genProduct(mainProduct),
    relatedProduct: genProduct(product),
    relatedProductIndex: productIndex,
  };
}

export function trackRelatedMoodboard(product, pathName) {
  return {
    product: genProduct(product),
    pathName,
  };
}

export function genUtmPropFromPath(path) {
  return genBaseUtmPropFromPath(path);
}

export function genDailyDigestChannelSlug(channelSlug) {
  return {
    channelSlug,
  };
}

export function genDailyDigestChannelReadTime(channelSlug, timestampDiff) {
  return {
    channelSlug,
    time: {
      seconds: timestampDiff % 60,
      minute: (timestampDiff / 60) % 60,
      hour: timestampDiff / 3600,
    },
  };
}

export function trackLikeAlbum(placement, isLoggedIn) {
  return {
    placement,
    isLoggedIn,
  };
}

export function genAlbumPin(contentType, placement, isLoggedIn) {
  return {
    contentType,
    placement,
    isLoggedIn,
  };
}

export function genOpenAlbumDetailSer(placement, album) {
  return { placement, album };
}

export function genOpenPhotoDetailSer(photo) {
  return { photo };
}

export function genFirstActionSer(action) {
  return { action };
}

export function genCreatedNewAlbum(isPrivate) {
  return { isPrivate };
}

export function genWishlistPins(items) {
  if (!items) return {};

  return {
    favoritedCount: items.length,
    favoritedItems: items.map((item) =>
      item.objectID ? item.objectID : item.product.objectID,
    ),
  };
}

export function genWishlistPin(placement, isWishlist, productInfo) {
  return { placement, isFavorited: isWishlist, productInfo };
}

export function genClickMainTab(tabName) {
  return { tabName };
}

export function genAppBannerExperiment(variant) {
  return { variant };
}

export function genRequestProjectSer(questionnaire) {
  const {
    projectTemplate,
    serviceTypeName,
    propertyTypeName,
    dealValue,
    source,
  } = questionnaire;
  const minPrice = dealValue;
  const styleString = getProjectTemplateStyleNames(projectTemplate).join(', ');
  const projectTemplateTitle = getProjectTemplateTitle(projectTemplate);
  const type = getProjectTemplateMainAttributeValue(projectTemplate);
  return {
    value: minPrice, // min budget,
    currency: 'IDR',
    style: styleString, // style string
    serviceTypeName, // service type
    type, // primary filter,
    propertyTypeName, // property type,
    title: projectTemplateTitle, // project template title
    source,
  };
}

export function genProjectSer(project) {
  const {
    serviceTypeName,
    propertyTypeName,
    dealValue,
    source,
    customerName,
    customerEmail,
    projectKey,
    sourceCampaign,
  } = project;
  return {
    value: dealValue,
    currency: 'IDR',
    serviceTypeName,
    propertyTypeName,
    source,
    customerName,
    customerEmail,
    projectKey,
    sourceCampaign,
  };
}

export function genProjectServiceTypeSer(to, serviceTypeName) {
  return {
    ...trackTransitionTo(to),
    serviceTypeName,
  };
}

export function projectSuggestionSer(projectSuggestionId, matchmaking) {
  const { project, firstVisit } = matchmaking;
  const modifiedProject = pick(project, [
    'number',
    'customerName',
    'serviceType',
    'propertyType',
    'roomCount',
  ]);
  return { projectSuggestionId, project: modifiedProject, firstVisit };
}

export function genClickedUpdateProductDetail(review) {
  return {
    review,
  };
}

export function genDesignerIds(designers) {
  const designerIds = designers.map((designer) => designer.memberId);
  return { designerIds };
}

// #region Designer Portfolio
export function genPortfolioIdsSer(portfolioList) {
  const portfolioIds = portfolioList.map((o) => o.id);
  return { portfolioIds };
}

export function genPortfolioSer(portfolio) {
  return pick(portfolio, [
    'projectKey',
    'title',
    'cartLinesValuePublic',
    'propertyTypeName',
    'serviceTypeName',
  ]);
}

// #endregion

export function genClickedRegisterDesignerBtn(btnPosition, isMobile) {
  return { btnPosition, isMobile };
}

export function genArticleSer(article, adSlots = []) {
  const { id, slug } = article;
  const categories = Object.keys(article.categories);
  const tags = Object.keys(article.tags);
  const url = makeParametricUrl(K_ROUTE_POST_DETAIL, {
    postid: id,
    postslug: slug,
  });
  const adSlotsInfo = adSlots.map((adSlot) => adSlot.name);

  return {
    categories,
    tags,
    url,
    ...pick(article, 'id', 'title', 'slug'),
    adSlots: adSlotsInfo,
  };
}

export function genSubscribedArticleSer(article, email) {
  return {
    article: genArticleSer(article),
    subscriberEmail: email,
  };
}

export function genServiceTypeButton(serviceType) {
  return { serviceType };
}

export function genCalculatorFields(calculatorFields) {
  const { serviceType, area, minPrice, maxPrice } = calculatorFields;
  return {
    serviceType,
    area,
    minPrice,
    maxPrice,
  };
}
export function genAuthSer(userData, type) {
  return typeof userData === 'object'
    ? genAuthWithObjectUserDataSer(userData, type)
    : genAuthWithStringUserDataSer(userData, type);
}

export function genAuthWithObjectUserDataSer(userData, type) {
  return {
    ...userData,
    type,
  };
}

export function genAuthWithStringUserDataSer(userData, type) {
  return {
    userData,
    type,
  };
}

export function genUserSer(username, firstName, lastName, email, phoneNumber) {
  const data = {
    id: username,
    email,
  };
  if (firstName) data.firstName = firstName;
  if (lastName) data.lastName = lastName;
  data.email = !email ? null : email;
  data.phoneNumber = !phoneNumber ? null : email;

  return data;
}

// #region DekoHouse

export function genLocationCardSer(to, locationName) {
  return {
    ...trackTransitionTo(to),
    locationName,
  };
}

// #endregion

export function genRegistrationPartnerSer(
  formFields,
  userId,
  errorMessage = '',
) {
  return {
    userId,
    ...pick(formFields, ['companyName', 'email']),
    errorMessage,
  };
}

// DekoHub
export function genRegisterBusinessUserHub({
  email,
  firstName,
  phoneNumber,
  businessType,
  city,
}) {
  return {
    name: firstName,
    email,
    phoneNumber,
    businessType,
    city,
  };
}

// #endregion DekoHub

export function genOfferIdentity(user) {
  const groups = user.groups.slice();
  if (user.isStaff) {
    groups.push('admin');
  }
  return {
    email: get(user, 'email'),
    role: groups.join('_'),
  };
}

export function genRegisterMembership(membershipFormData) {
  return {
    email: membershipFormData.email,
    phoneNumber: membershipFormData.phoneNumber,
    gender: membershipFormData.gender,
    occupation: membershipFormData.occupation,
  };
}

// #region OTP
export function genOTPSer(phoneNumber, platform) {
  return { phoneNumber, platform };
}
// #endregion
