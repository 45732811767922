import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';

import withSearch from 'app-libs/SearchKit/components/withSearch';
import { transformFacetLabel } from 'app-libs/etc/categoryHelper';

import ListComponent from '../ListComponent';

export const createDisjunctiveSelector = (
  searchAdapter,
  { name },
  config = {},
) => {
  const state = searchAdapter.getState();
  let result = searchAdapter.getResult();

  // fallback to state
  if (isEmpty(result)) {
    result = {
      disjunctiveFacets: Object.keys(state.disjunctiveFacetsRefinements).map(
        (facetName) => ({
          name: facetName,
          data: Object.assign(
            {},
            ...state.disjunctiveFacetsRefinements[facetName].map(
              (attribute) => ({ [attribute]: 0 }),
            ),
          ),
        }),
      ),
    };
  }

  let data = {};
  if (result.disjunctiveFacets) {
    data =
      (result.disjunctiveFacets.find((facet) => facet.name === name) || {})
        .data || {};
  }
  const selected = state.disjunctiveFacetsRefinements[name] || [];

  data = Object.keys(data).map((item) => {
    const res = {
      label: transformFacetLabel(item),
      value: item,
      count: data[item],
    };
    if (!config.hideSelected) {
      res.selected = selected.indexOf(item) > -1;
    }
    return res;
  });

  const { sortFunction = null } = config;

  if (sortFunction) {
    return data.sort(sortFunction);
  }

  return data;
};

@withSearch({ data: createDisjunctiveSelector })
export default class DisjunctiveFacetFilter extends Component {
  render() {
    return <ListComponent {...this.props} />;
  }
}
