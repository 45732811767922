import React, { memo } from 'react';

import { StyleSheet, View, ActivityIndicator, Text } from '@dkrm/ui-kit-basic';
import { ModalPortal } from '@dkrm/ui-kit-basic/v2';

import cStyles from '@dkrm/general-libs/theme/styles';

export interface ModalFallbackProps {
  closeModal: () => void;
}

const ModalFallback: React.FC<ModalFallbackProps> = memo(({ closeModal }) => {
  return (
    <ModalPortal>
      <View onClick={closeModal} style={s.backdrop}>
        <View style={s.container}>
          <ActivityIndicator size={40} />
          <View style={s.textContainer}>
            <Text theme="h4-black90">Mohon Tunggu</Text>
            <Text theme="body3-black70" style={s.description}>
              Halamanmu sedang dimuat...
            </Text>
          </View>
        </View>
      </View>
    </ModalPortal>
  );
});

export default ModalFallback;

const s = StyleSheet.create({
  backdrop: {
    ...cStyles.flexMiddle,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 32, 34, 0.5)',
  },
  container: {
    ...cStyles.bgw,
    ...cStyles.brs,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.phxxl,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    paddingVertical: 20,
  },
  textContainer: cStyles.mlxl,
  description: cStyles.mtm,
});
