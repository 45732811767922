import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

export const getArea = (state) => state.area;

/**
 * Cities
 */
export const getSupportedDnBCities = (state) => {
  const area = getArea(state);
  return area.supportedDnBCities.data;
};

export const getCities = (state) => {
  const area = getArea(state);
  return area.cities.data;
};

export const getCityObjects = (state) => {
  const area = getArea(state);
  return area.cities.objects;
};

export const getSelectedCity = (state) => {
  const area = getArea(state);
  return area.selectedCityShippingCoverage;
};

export const getSelectedCityObject = (state) => {
  const area = getArea(state);
  return area.selectedCityObject;
};

export const isLoadCities = (state) => {
  const area = getArea(state);
  return area.cities.asyncState.loading;
};

export const isCitiesLoaded = (state) => {
  const area = getArea(state);
  return area.cities.asyncState.loaded;
};

/**
 * District
 */

export const getIsLoadingDistricts = createSelector(
  getArea,
  (area) => area.districts.asyncState.loading || false,
);

export const getDistricts = (state) => {
  const area = getArea(state);
  return area.districts.data;
};

export const getShippingPricingCoverageProductFromArea = (
  shippingClassName,
  area,
) => {
  const { shippingPricingCoverages } = area;
  const shippingPricingCoverageProduct = shippingPricingCoverages.find(
    (shippingPricingCoverage) =>
      shippingPricingCoverage.shippingClassName === shippingClassName,
  );
  return shippingPricingCoverageProduct;
};

export const isProductCanBeDeliveredFromArea = (shippingClassName, area) => {
  if (shippingClassName) {
    const shippingPricingCoverageProduct = getShippingPricingCoverageProductFromArea(
      shippingClassName,
      area,
    );
    if (isEmpty(shippingPricingCoverageProduct)) return false;
  }
  return true;
};

export const getEstimateShippingPrice = (shippingClassName, area) => {
  if (!isProductCanBeDeliveredFromArea(shippingClassName, area))
    return undefined;
  const shippingPricingCoverageProduct = getShippingPricingCoverageProductFromArea(
    shippingClassName,
    area,
  );
  return shippingPricingCoverageProduct.price;
};
