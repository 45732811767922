import { ShippingAreaFromGoblin } from 'app-libs/redux_modules/flow_modules/area/types';

import { AT as AT_AUTH } from '../../auth';
import { AT, LocationAction, LocationState } from './types';

export const initialState: LocationState = {
  id: 1277,
  district: 'Kebayoran Baru',
  city: 'Jakarta Selatan',
  province: 'DKI Jakarta',
  island: 'Jawa',
  zoneCode: 'z1',
  routingZoneCode: 'z1',
  isCurrentLocationSavedFromUserAction: false,
  isInitial: true,
};

export default function locationReducer(
  mutableState = initialState,
  action: LocationAction = {},
) {
  let state: LocationState = mutableState;
  switch (action.type) {
    case AT_AUTH.LOGOUT_SUCCESS: // reset upon logout
      state = initialState;
      break;
    case AT.SET_CURRENT_LOCATION:
      state = { ...state, ...action.payload };
      break;
    default:
      break;
  }
  return state;
}

export const setCurrentLocation = (
  shippingArea: ShippingAreaFromGoblin,
  isCurrentLocationSavedFromUserAction = false,
) => {
  const locationStatePayload: LocationState = {
    ...shippingArea,
    isCurrentLocationSavedFromUserAction,
    isInitial: false,
  };

  return {
    type: AT.SET_CURRENT_LOCATION,
    payload: locationStatePayload,
  };
};
