import config from 'config';

export const K_ASSET_TYPE_BANNER = 'Banner';
export const K_ASSET_TYPE_LOTTIE = 'Lottie';

/** General */
export const K__GENERAL__ALL_PAGE__TOP_NOTIFICATION =
  'GENERAL__ALL_PAGE__TOP_NOTIFICATION';

/** Storefront Web */
export const K__STOREFRONT_WEB__ALL_SALE__TOP = 'STOREFRONT_WEB__ALL_SALE__TOP';
export const K__STOREFRONT_WEB__HOMEPAGE__BANK_SLIDER =
  'STOREFRONT_WEB__HOMEPAGE__BANK_SLIDER';

/** Storefront Mobile Web */
export const K__STOREFRONT_MWEB__HOMEPAGE__MAIN_BANNER =
  'STOREFRONT_MWEB__HOMEPAGE__MAIN_BANNER';
export const K__STOREFRONT_MWEB__HOMEPAGE__MAIN_BANNER_STAGING =
  'STOREFRONT_MWEB__HOMEPAGE__MAIN_BANNER_STAGING';
export const K__STOREFRONT_MWEB__HOMEPAGE__SECONDARY_BANNER =
  'STOREFRONT_MWEB__HOMEPAGE__SECONDARY_BANNER';
export const K__STOREFRONT_MWEB__HOMEPAGE__MARKETPLACE_CATEGORY_SECTION =
  'STOREFRONT_MWEB__HOMEPAGE__MARKETPLACE_CATEGORY_SECTION';
export const K__STOREFRONT_MWEB__LOGIN_WALL__BANNER =
  'STOREFRONT_MWEB__LOGIN_WALL__BANNER';
export const K__STOREFRONT_MWEB__PROMO_LIST__MAIN_BANNER =
  'STOREFRONT_MWEB__PROMO_LIST__MAIN_BANNER';
export const K__STOREFRONT_MWEB__FLASH_SALE__MAIN_BANNER =
  'STOREFRONT_MWEB__FLASH_SALE__MAIN_BANNER';
export const K__STOREFRONT_MWEB__SALE_DETAIL__TOP =
  'STOREFRONT_MWEB__SALE_DETAIL__TOP';
export const K__STOREFRONT_MWEB__HOMEPAGE_MATTRESS__TOP =
  'STOREFRONT_MWEB__HOMEPAGE_MATTRESS__TOP';
export const K__STOREFRONT__PDP__CAMPAIGN_RIBBON =
  'STOREFRONT__PDP__CAMPAIGN_RIBBON';
export const K__STOREFRONT__HOMEPAGE_AND_PRODUCT_LIST__FLOATING_ICON =
  'STOREFRONT__HOMEPAGE_AND_PRODUCT_LIST__FLOATING_ICON';
export const K__STOREFRONT__HOMEPAGE__POPUP_BANNER =
  'STOREFRONT__HOMEPAGE__POPUP_BANNER';
export const K__STOREFRONT__HOMEPAGE__SEARCH_BAR_ICON =
  'STOREFRONT__HOMEPAGE__SEARCH_BAR_ICON';
export const K_STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER =
  'STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER';
export const K__STOREFRONT_MWEB__CART__GIFT_WITH_PURCHASE =
  'STOREFRONT_MWEB__CART__GIFT_WITH_PURCHASE';
export const K__STOREFRONT_MWEB__PRODUCT_LIST__SPECIAL_OFFER_STICKER_BADGE =
  'STOREFRONT_MWEB__PRODUCT_LIST__SPECIAL_OFFER_STICKER_BADGE';
export const K__STOREFRONT_MWEB__ACCESSORIES_HOMEPAGE__BANNER =
  'STOREFRONT_MWEB__ACCESSORIES_HOMEPAGE__BANNER';
export const K__STOREFRONT_MWEB__OFFLINE_STORE_LIST__TOP =
  'STOREFRONT_MWEB__OFFLINE_STORE_LIST__TOP';
export const K__INTERIOR_MWEB__AREA_DEFAULT__BANNER =
  'INTERIOR_MWEB__AREA_DEFAULT__BANNER';

export const K_DEFAULT_BANNER_ADVERTISEMENT = {
  [K__STOREFRONT_WEB__ALL_SALE__TOP]: {
    name: 'Dekoruma All Sale',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/banner/dekoruma-all-sale.jpg?auto=webp`,
    link: '',
  },
  [K__STOREFRONT_MWEB__FLASH_SALE__MAIN_BANNER]: {
    name: 'Dekoruma Flash Sale',
    imageUrl: `${config.API_URL_MEDIA_CDN}/flash-sale/banner/dekoruma-flash-sale.png?auto=webp`,
    link: '',
  },
};

/** DNB */
export const K__DNB__HOMEPAGE__PROJECT_REQUEST_FORM =
  'DNB__HOMEPAGE__PROJECT_REQUEST_FORM';
