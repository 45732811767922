import { Centarius } from 'centarius/core';
import PropTypes from 'prop-types';
import React from 'react';
import { AppContainer, hot } from 'react-hot-loader';
import { QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import IsomorphicStateContext from '@dkrm/general-libs/IsomorphicState/context';
import { UIKitImageContext } from '@dkrm/ui-kit-v2';

import './index.scss';
import routes from './routes';

const beforeNavigating = () => {};

const afterNavigating = () => {
  window.scrollTo(0, 0);
};
/** End of Centarius settings */

const App = ({
  store,
  ssrData,
  queryClient,
  reactQueryDehydratedState,
  isomorphicState,
  uiKitImageIsomorphicState,
}) => {
  const options = {
    staticMethod: 'fetchData',
    store,
    queryClient,
  };

  return (
    <AppContainer>
      {/** React.Fragment is required here to substitute DeviceInfoProvider */}
      {/** Without it, client hydration DOM will mismatch with server render */}
      <React.Fragment>
        <Provider store={store}>
          <IsomorphicStateContext.Provider value={isomorphicState}>
            <UIKitImageContext.Provider value={uiKitImageIsomorphicState}>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={reactQueryDehydratedState}>
                  <BrowserRouter>
                    <Centarius
                      data={ssrData}
                      routes={routes}
                      options={options}
                      beforeNavigating={beforeNavigating}
                      afterNavigating={afterNavigating}
                    />
                  </BrowserRouter>
                </Hydrate>
              </QueryClientProvider>
            </UIKitImageContext.Provider>
          </IsomorphicStateContext.Provider>
        </Provider>
      </React.Fragment>
    </AppContainer>
  );
};

App.propTypes = {
  store: PropTypes.shape().isRequired,
  ssrData: PropTypes.shape().isRequired,
  queryClient: PropTypes.shape().isRequired,
  reactQueryDehydratedState: PropTypes.shape().isRequired,
  isomorphicState: PropTypes.shape().isRequired,
  uiKitImageIsomorphicState: PropTypes.shape().isRequired,
};

export default hot(module)(App);
