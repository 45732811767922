import keyMirror from 'keymirror';

export const AT = keyMirror({
  INITIALIZE_PROJECT_CATEGORY_TREE: null,
  INITIALIZE_PROJECT_LOCATION_CATEGORY_TREES: null,
});

export const initialState = {
  categoryTree: {},

  projectLocationCategoryTrees: {},
};

export default function categoryReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.INITIALIZE_PROJECT_CATEGORY_TREE: {
      const categoryTree = action.payload;
      state = { ...state, categoryTree };
      break;
    }
    case AT.INITIALIZE_PROJECT_LOCATION_CATEGORY_TREES: {
      const projectLocationCategoryTrees = action.payload;
      state = {
        ...state,
        projectLocationCategoryTrees,
      };
      break;
    }
  }
  return state;
}

export function initializeCategoryTree(categoryTree) {
  return {
    type: AT.INITIALIZE_PROJECT_CATEGORY_TREE,
    payload: categoryTree,
  };
}

export function initializeProjectLocationCategoryTrees(categoryTrees) {
  return {
    type: AT.INITIALIZE_PROJECT_LOCATION_CATEGORY_TREES,
    payload: categoryTrees,
  };
}
