import { Colors } from '@dkrm/ui-kit-basic';

import { ShippingEventOrderLine } from 'app-libs/redux_modules/entity_modules/shipping/types';

import { InsuranceDetail, InsuranceOrderLine } from 'entities/order/types';

import {
  K_CERMATI_INSURANCE_STATUS_CODE_DATA_COMPLETE,
  K_CERMATI_INSURANCE_STATUS_CODE_EXPIRED,
  K_CERMATI_INSURANCE_STATUS_CODE_ISSUED,
  K_CERMATI_INSURANCE_STATUS_LABEL_INACTIVE,
  K_INSURANCE_STATUS_LABELS_BY_INSURANCE_STATUS_CODE,
  K_INSURANCE_STATUS_LABEL_BACKGROUND_THEMES_BY_INSURANCE_STATUS_LABEL,
  K_INSURANCE_STATUS_LABEL_THEMES_BY_INSURANCE_STATUS_LABEL,
} from 'constants/insuranceConstants';

import { dayjs } from './dateHelperV2';

// Functions for Order Line Insurance ---------
export function getIsOrderLineHasInsurance(
  productLine: ShippingEventOrderLine,
  insuranceLines: ShippingEventOrderLine[],
): boolean {
  return insuranceLines.some(
    (insuranceLine) => insuranceLine.parentLineId === productLine.id,
  );
}

function getInsuranceStatusCode(insurance: InsuranceDetail | null) {
  if (!insurance) return K_CERMATI_INSURANCE_STATUS_CODE_DATA_COMPLETE;

  const coverageEndDate = insurance.coverageEndDate
    ? dayjs(insurance.coverageEndDate)
    : null;
  if (!coverageEndDate) return insurance.status;

  const now = dayjs();
  return coverageEndDate < now
    ? K_CERMATI_INSURANCE_STATUS_CODE_EXPIRED
    : insurance.status;
}

export function getOrderLineInsuranceInfo(
  insuranceLine: InsuranceOrderLine | null,
) {
  const insuranceDetail = insuranceLine?.insuranceDetail ?? null;
  const insuranceStatusCode = getInsuranceStatusCode(insuranceDetail);
  const insuranceStatusLabel =
    K_INSURANCE_STATUS_LABELS_BY_INSURANCE_STATUS_CODE[insuranceStatusCode] ??
    K_CERMATI_INSURANCE_STATUS_LABEL_INACTIVE;
  const insuranceStatusLabelTheme = `h6-${
    K_INSURANCE_STATUS_LABEL_THEMES_BY_INSURANCE_STATUS_LABEL[
      insuranceStatusLabel
    ] ?? 'black80'
  }`;
  const insuranceStatusLabelBackgroundTheme =
    K_INSURANCE_STATUS_LABEL_BACKGROUND_THEMES_BY_INSURANCE_STATUS_LABEL[
      insuranceStatusLabel
    ] ?? Colors.C_BLACK_50;

  const insuranceCoverageEndDate = insuranceDetail?.coverageEndDate
    ? dayjs(insuranceDetail.coverageEndDate).format('DD MMMM YYYY')
    : '-';

  const insuranceSrpUrl = insuranceDetail?.srpUrl ?? null;
  const insurancePolicyNumber = insuranceDetail?.policyNumber ?? null;

  const canSeeInsurancePolicy =
    insuranceStatusCode !== K_CERMATI_INSURANCE_STATUS_CODE_DATA_COMPLETE;
  const canClaim =
    insuranceStatusCode === K_CERMATI_INSURANCE_STATUS_CODE_ISSUED;
  const canSeeSrpDocument = !!insuranceSrpUrl;

  return {
    insurancePolicyNumber,
    insuranceStatusLabel,
    insuranceStatusLabelTheme,
    insuranceStatusLabelBackgroundTheme,
    insuranceCoverageEndDate,
    insuranceSrpUrl,
    canSeeInsurancePolicy,
    canSeeSrpDocument,
    canClaim,
  };
}
