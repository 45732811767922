import React from 'react';
import { View, Text, StyleSheet } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

export interface FooterSectionItemProps {
  label: string;
  Icon?: React.ElementType;
}
export const FooterSectionItem: React.FC<FooterSectionItemProps> = ({
  label,
  Icon,
}) => {
  return (
    <View style={s.container}>
      {!!Icon && <Icon theme="black" style={cStyles.mrm} />}
      <Text theme="body3-black">{label}</Text>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.flexDirRow,
    ...cStyles.prxl,
  },
});

export default FooterSectionItem;
