import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { LoveEmpty, LoveFull } from '@dkrm/icons';

import LoginQueryModal from 'app-libs/components/LoginQueryModal';
import * as authSelectors from 'app-libs/redux_modules/selectors/auth';
import * as albumSelectors from 'app-libs/redux_modules/entity_modules/selectors/album';

import { K_TRACKING_PLACEMENT_PRODUCT_CARD } from 'constants/trackingConstants';

@connect((state) => ({
  isLoading: albumSelectors.getPinsLoading(state),
  isLoggedIn: authSelectors.isLoggedIn(state),
}))
export default class WishlistButton extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    toggleWishlistPin: PropTypes.func.isRequired,
    pinId: PropTypes.number,
    product: PropTypes.shape({
      objectID: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.array,
    }).isRequired,
    isLoggedIn: PropTypes.bool,
    isUsingDebounce: PropTypes.bool,
  };

  static defaultProps = {
    isLoggedIn: false,
    pinId: null,
    isUsingDebounce: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      showLoginQueryModal: false,
      hasPin: Boolean(props.pinId),
      objectID: props.product.objectID,
      isLoading: true,
    };
    this.actionToggleWishlistPinWithDebounce = debounce(
      props.toggleWishlistPin,
      props.isUsingDebounce ? 500 : 0,
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isLoading !== state.isLoading) {
      return {
        hasPin: Boolean(props.pinId),
        objectID: props.product.objectID,
        isLoading: props.isLoading,
      };
    }
    if (
      Boolean(props.pinId) !== state.hasPin &&
      props.product.objectID !== state.objectID
    ) {
      return { hasPin: Boolean(props.pinId), objectID: props.product.objectID };
    }
    return null;
  }

  actionChangeShouldLoginModal = (value) => {
    this.setState({
      showLoginQueryModal: value,
    });
  };

  actionChangeWishlistPin = () => {
    const { pinId, product } = this.props;
    this.setState(
      (prevState) => ({
        hasPin: !prevState.hasPin,
      }),
      () => {
        const { hasPin } = this.state;
        if (hasPin) {
          this.actionToggleWishlistPinWithDebounce(
            null,
            product,
            K_TRACKING_PLACEMENT_PRODUCT_CARD,
          );
        } else if (pinId) {
          this.actionToggleWishlistPinWithDebounce(
            pinId,
            product,
            K_TRACKING_PLACEMENT_PRODUCT_CARD,
          );
        } else {
          this.actionToggleWishlistPinWithDebounce.cancel();
        }
      },
    );
  };

  render() {
    const { isLoggedIn, ...rest } = this.props;
    const { hasPin, showLoginQueryModal } = this.state;

    const LoveComponent = hasPin ? LoveFull : LoveEmpty;
    const loveTheme = hasPin ? 'red' : 'black80';

    return (
      <React.Fragment>
        <Button
          onPress={
            isLoggedIn
              ? () => this.actionChangeWishlistPin()
              : () => this.actionChangeShouldLoginModal(true)
          }
        >
          {() => <LoveComponent size={20} theme={loveTheme} />}
        </Button>
        {!isLoggedIn && (
          <LoginQueryModal
            showLoginQueryModal={showLoginQueryModal}
            changeShouldLoginModal={this.actionChangeShouldLoginModal}
            {...rest}
          />
        )}
      </React.Fragment>
    );
  }
}
