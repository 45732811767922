import { SEOArea } from 'entities/seo/types';

import { unslugify } from 'app-libs/etc';

export const generateTopCategoryAreaFooterItems = (
  areas: SEOArea[],
  category: string,
) =>
  (areas || []).map((area: SEOArea) => ({
    label: `${unslugify(category)} ${area.name}`,
    linkUrl: `/${category}/${area.slug}`,
  }));
