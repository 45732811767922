export function genProductPurchase(line) {
  const { product, quantity } = line;
  let category = null;
  if (product.categories && product.categories.length) {
    category = product.categories[0].split(' > ').join('/');
  }
  return {
    id: product.objectID,
    sku: product.objectID,
    name: product.title,
    brand: product.brand,
    price: product.price,
    category: category,
    quantity: quantity,
  };
}

export function genPurchase(order, lines) {
  const products = lines.map((line) => genProductPurchase(line));
  return {
    ecommerce: {
      event: 'purchase',
      purchase: {
        actionField: {
          id: order.number, // Transaction ID. Required for purchases and refunds.
          affiliation: 'Dekoruma',
          revenue: order.totalExclTax, // Total transaction value (incl. tax and shipping)
          tax: order.totalInclTax - order.totalExclTax,
          shipping: order.shippingExclTax,
        },
        products: products,
      },
    },
  };
}

export function genTransaction(order, lines) {
  const products = lines.map((line) => genProductPurchase(line));
  return {
    transactionId: order.number,
    transactionAffiliation: 'Dekoruma',
    transactionTotal: order.totalExclTax,
    transactionTax: order.totalInclTax - order.totalExclTax,
    transactionShipping: order.shippingExclTax,
    transactionProducts: products,
  };
}
