import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';

const styles = StyleSheet.create({
  modalContainer: {
    ...cStyles.width100,
    ...cStyles.height100,
    ...cStyles.flexMiddle,
    ...cStyles.phxxl,
    position: 'absolute',
    backgroundColor: colors.generateRGBA('black', 0.1),
  },

  confirmationBox: {
    ...cStyles.width100,
    ...cStyles.brs,
    ...cStyles.ptxxl,
    ...cStyles.plxxl,
    ...cStyles.pbm,
    ...cStyles.prm,
    backgroundColor: colors.C_WHITE_100,
    shadowColor: '#002022',
    maxWidth: 600,
    shadowOffset: {
      width: 0.5,
      height: 2,
    },
    shadowRadius: 15,
    shadowOpacity: 0.3,
    elevation: 4,
  },

  confirmationButtonContainer: {
    ...cStyles.width100,
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default styles;
