import keyMirror from 'keymirror';
import update from 'immutability-helper';

import asyncStateReducer, {
  initialAsyncState,
} from '../../../helper_modules/asyncState';

export const AT = keyMirror({
  CREATE_REIMBURSEMENT: null,
  CREATE_REIMBURSEMENT_SUCCESS: null,
  CREATE_REIMBURSEMENT_FAIL: null,

  LOAD_REIMBURSEMENTS: null,
  LOAD_REIMBURSEMENTS_SUCCESS: null,
  LOAD_REIMBURSEMENTS_FAIL: null,

  LOAD_REIMBURSEMENT_DETAIL: null,
  LOAD_REIMBURSEMENT_DETAIL_SUCCESS: null,
  LOAD_REIMBURSEMENT_DETAIL_FAIL: null,

  LOAD_REIMBURSEMENT_ITEM_CATEGORIES: null,
  LOAD_REIMBURSEMENT_ITEM_CATEGORIES_SUCCESS: null,
  LOAD_REIMBURSEMENT_ITEM_CATEGORIES_FAIL: null,
});

export const initialState = {
  asyncState: { ...initialAsyncState },
  itemCategories: {
    asyncState: { ...initialAsyncState },
    result: [],
  },
};

export default function reimbursementReducer(
  mutableState = initialState,
  action,
) {
  const { type, result } = action;

  let state = mutableState;
  state.asyncState = asyncStateReducer(
    state.asyncState,
    action,
    '_REIMBURSEMENT_',
  );

  state.itemCategories.asyncState = asyncStateReducer(
    state.itemCategories.asyncState,
    action,
    '_REIMBURSEMENT_ITEM_CATEGORIES_',
  );

  switch (type) {
    case AT.LOAD_REIMBURSEMENT_ITEM_CATEGORIES_SUCCESS:
      state = update(state, {
        itemCategories: {
          result: {
            $set: result,
          },
        },
      });
      break;
  }

  return state;
}
