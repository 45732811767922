import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';

import config from 'config';

import { getRefinedFacets, slugify, unslugify } from 'app-libs/etc';
// eslint-disable-next-line import/no-cycle
import {
  enrichParamsWithAllDescendingCatLvl,
  enrichParamsWithCatLvl,
} from 'app-libs/etc/routeHelper';

import {
  K_LOCATION_JAKARTA_LOWER_CASE_PATHNAME,
  K_LOCATION_JAKARTA_STATE_VALUE,
} from 'constants/designBuildConstants';

/** ******************************
 * BREADCRUMB RELATED
 * *******************************
 */

export const getBreadcrumbLength = (breadcrumb) =>
  breadcrumb.split(' > ').length;

export function getFirstBreadcrumbFromProductCategories(categories) {
  if (categories && categories.length) {
    const firstBreadcrumb = categories[0];
    if (firstBreadcrumb) return firstBreadcrumb;
  }
  return '';
}

export function getBreadcrumbFromParams(params) {
  const sortedKeys = Object.keys(params).sort();
  const breadcrumb = sortedKeys
    .map((key) => (key.startsWith('lvl') ? unslugify(params[key]) : null))
    .filter((n) => !!n)
    .join(' > ');
  return breadcrumb;
}

/** Eg. dikasih params.lvl0 doang, disuruh ubah jadi full breadcrumb
 * Kayak ngereverse categoryTree (Meja -> Furnitur/Meja)
 * params: 'shouldReturnNullIfNotExists' (default: false)
 *   if set to true, then return null if we cannot get the breadcrumb
 */
export function getBreadcrumbFromIncompleteParams(
  _params,
  allBreadcrumbs,
  shouldReturnNullIfNotExists = false,
) {
  let params = {
    ..._params,
  };
  params = {
    ...enrichParamsWithCatLvl(
      params,
      allBreadcrumbs,
      shouldReturnNullIfNotExists,
    ),
  };
  return getBreadcrumbFromParams(params);
}

export function getBreadcrumbFromIncompleteLastCat(
  lastCategory,
  allBreadcrumbs,
  shouldReturnNullIfNotExists = false,
) {
  const params = Object.assign(
    {},
    enrichParamsWithCatLvl(
      { lvl0: lastCategory },
      allBreadcrumbs,
      shouldReturnNullIfNotExists,
    ),
  );
  return getBreadcrumbFromParams(params);
}

export function getAllDescendingBreadcrumbsFromIncompleteCat(
  category,
  allBreadcrumbs,
) {
  const params = enrichParamsWithAllDescendingCatLvl(
    { lvl0: category },
    allBreadcrumbs,
  );
  if (isEmpty(params)) return null;
  return params.map((param) => getBreadcrumbFromParams(param));
}

export function getParamsFromBreadcrumb(breadcrumb) {
  if (!breadcrumb) return '';
  const categories = breadcrumb.split(' > ');
  const params = {};
  categories.forEach((category, idx) => {
    params[`lvl${idx}`] = slugify(category);
  });
  return params;
}

export function getBreadcrumbRightMost(breadcrumb) {
  if (!breadcrumb) return '';
  const categories = breadcrumb.split(' > ');
  return categories.pop();
}

export function getBreadcrumbMinusOne(breadcrumb) {
  if (!breadcrumb) return '';
  const categories = breadcrumb.split(' > ');
  if (categories.length) {
    categories.pop();
  }
  return categories.join(' > ');
}

export function getBreadcrumbFromSearchParameters(searchParameters) {
  let breadcrumb = '';
  try {
    [breadcrumb] =
      searchParameters.hierarchicalFacetsRefinements.hcategories_by_function;
  } catch (e) {
    // console.log(e);
    // pass
  }
  return breadcrumb;
}

export function getMaterialFromSearchParameters(searchParameters) {
  try {
    const materials =
      searchParameters.disjunctiveFacetsRefinements[
        'filteringAttributes.aMaterial'
      ];
    if (!!materials && materials.length) {
      const material = materials[0];
      return material;
    }
  } catch (e) {
    // console.log(e);
    // pass
  }
  return '';
}

export function getPatternFromSearchParameters(searchParameters) {
  try {
    const patterns =
      searchParameters.disjunctiveFacetsRefinements[
        'filteringAttributes.aPattern'
      ];
    if (!!patterns && patterns.length) {
      const pattern = patterns[0];
      return pattern;
    }
  } catch (e) {
    // pass
  }
  return '';
}

export function getBreadcrumbFromFacets(facets) {
  return facets.map((cat) => cat.name).join(' > ');
}

export function getLocationFromUrlWithoutCategory(
  urlWithoutCategory,
  projectLocationCategoryTree,
) {
  const {
    projectCategoriesByCity,
    projectCategoriesByDistrict,
    projectCategoriesByProvince,
  } = projectLocationCategoryTree;

  let location = unslugify(urlWithoutCategory);

  if (location.toLowerCase() === K_LOCATION_JAKARTA_LOWER_CASE_PATHNAME) {
    location = K_LOCATION_JAKARTA_STATE_VALUE;
  }

  const locationDictionariesByLocationType = {
    state: projectCategoriesByProvince,
    line4: projectCategoriesByCity,
    line3: projectCategoriesByDistrict,
  };

  const locationTypeLocationDictPair = Object.entries(
    locationDictionariesByLocationType,
  ).find(([, locationDictionary]) =>
    Object.keys(locationDictionary)
      .map((locationName) => locationName.toLowerCase())
      .includes(location.toLowerCase()),
  );

  const locationType =
    locationTypeLocationDictPair === undefined
      ? undefined
      : locationTypeLocationDictPair[0];

  if (locationType) {
    const projectCategoriesByLocation = locationTypeLocationDictPair[1];

    location = Object.keys(projectCategoriesByLocation).find(
      (locationName) => locationName.toLowerCase() === location.toLowerCase(),
    );

    return [locationType, location];
  }
  return [undefined, undefined];
}

export function getURLAdditionalStringFromDisjunctiveFacetRefinements(
  disjunctiveFacetRefinements,
  projectLocationCategoryTree,
) {
  let location = '';

  if (disjunctiveFacetRefinements?.['address.state']?.length > 0) {
    location =
      disjunctiveFacetRefinements?.['address.state']?.length === 1
        ? disjunctiveFacetRefinements?.['address.state'][0]
        : '';
  }

  if (disjunctiveFacetRefinements?.['address.line4']?.length > 0) {
    location =
      disjunctiveFacetRefinements?.['address.line4']?.length === 1
        ? disjunctiveFacetRefinements?.['address.line4'][0]
        : '';
  }

  if (disjunctiveFacetRefinements?.['address.line3']?.length > 0) {
    location =
      disjunctiveFacetRefinements?.['address.line3']?.length === 1
        ? disjunctiveFacetRefinements?.['address.line3'][0]
        : '';
  }

  const {
    projectCategoriesByCity,
    projectCategoriesByDistrict,
    projectCategoriesByProvince,
  } = projectLocationCategoryTree;

  if (location.toLowerCase() === K_LOCATION_JAKARTA_LOWER_CASE_PATHNAME) {
    location = K_LOCATION_JAKARTA_STATE_VALUE;
  }

  const locationDictionariesByLocationType = {
    state: projectCategoriesByProvince,
    line4: projectCategoriesByCity,
    line3: projectCategoriesByDistrict,
  };

  const isLocationInProjectLocationCategoryTree = Object.entries(
    locationDictionariesByLocationType,
  ).find(([, locationDictionary]) =>
    Object.keys(locationDictionary)
      .map((locationName) => locationName.toLowerCase())
      .includes(location.toLowerCase()),
  );

  return isLocationInProjectLocationCategoryTree
    ? slugify(location).toLowerCase()
    : '';
}

export function getBreadcrumbFromSearchResults(searchResults) {
  let facets = [];
  if (searchResults.hierarchicalFacets) {
    const facet = searchResults.hierarchicalFacets.find(
      (f) => f.name === 'hcategories_by_function',
    );
    facets = getRefinedFacets(facet);
  }
  return getBreadcrumbFromFacets(facets);
}

export function getBreadcrumbFromPath(path) {
  if (!path) return '';
  let cleanedPath = path.replace('/search', '');
  cleanedPath = path.replace('search', '');
  let breadcrumb = cleanedPath
    .split('/')
    .filter(Boolean)
    .map(unslugify)
    .join(' > ');

  try {
    // find & remove "?"
    [breadcrumb] = breadcrumb.split('?');
  } catch (e) {
    // pass
  }

  return breadcrumb;
}

// given path, eg /Tanaman --> Dekor > Tanaman
export function getBreadcrumbFromIncompletePath(path, allBreadcrumbs) {
  const breadcrumb = getBreadcrumbFromPath(path);
  return getBreadcrumbFromIncompleteLastCat(breadcrumb, allBreadcrumbs);
}

export function getHFacetPathRedirection(path) {
  const breadcrumb = getBreadcrumbFromPath(path);
  const lastCategory = getBreadcrumbRightMost(breadcrumb);
  if (path === '/Kasur') {
    return 'kasur';
  }

  return slugify(lastCategory);
}

export function getHCategoryLevelBreadcrumbRightMost(breadcrumb) {
  const categories = breadcrumb.split(' > ');
  const numOfCategories = categories.length;
  return `hcategories.lvl${numOfCategories - 1}`;
}

export function getHCategoryImageUrl(categoryLevel, categoryLabel, imageWidth) {
  return `${config.API_URL_MEDIA_CDN}/facets/${categoryLevel}/${categoryLabel}.png?width=${imageWidth}&auto=webp`;
}

/**
 * Given, a path eg.
 *   /search/Furnitur/Kursi
 * it should return (as per catContextual)
 *   /search/Furnitur/Kursi/Kursi Makan
 */
export function getBreadcrumbPathRedirection(path) {
  const breadcrumb = getBreadcrumbFromPath(path);

  // Check if furnitur
  if (breadcrumb === 'Furnitur' && path) {
    return path.replace('search/Furnitur', 'furniture');
  }

  return getHFacetPathRedirection(path);
}

/**
 * Given
 *   subcat: Meja,
 *   allbreadcrumbs: [Furnitur, Furnitur > Meja])
 * return True
 */
export function isSubCatInBreadcrumbs(subCat, breadcrumbs) {
  return (
    breadcrumbs.map(getBreadcrumbRightMost).findIndex((cat) => cat === subCat) >
    -1
  );
}

export function isBreadcrumbFurnitureWide(breadcrumb) {
  const aList = [
    'Furniture > Sofa',
    'Furniture > Meja & Kursi Makan > Kursi Panjang',
    'Furniture > Meja Tamu & Nakas > Meja Tamu',
    'Furniture > Meja & Kursi Makan > Meja Makan',
    'Furniture > Rak & Penyimpanan > Meja TV',
    'Furniture > Meja & Kursi Kerja > Meja Kerja',
    'Furniture > Meja & Kursi Kerja > Meja Kantor',
    'Furniture > Sofa > Sofa 1 Seater & Armchair',
    'Furniture > Sofa > Sofa 2 Seater',
    'Furniture > Sofa > Sofa 3 Seater',
    'Furniture > Sofa > Sofa L',
    'Furniture > Sofa > Kursi Ottoman & Stool',
    'Furniture > Tempat Tidur > Tempat Tidur Single',
    'Furniture > Tempat Tidur > Tempat Tidur Queen',
    'Furniture > Tempat Tidur > Tempat Tidur King',
  ];
  return Boolean(aList.indexOf(breadcrumb) >= 0);
}

export function isBreadcrumbSofaWide(breadcrumb) {
  if (!breadcrumb) return false;
  const hcategory = breadcrumb.split(' > ');
  return hcategory[1] === 'Sofa';
}

export function constructCategoryOptions(categoryLevelTree) {
  const catOptions = Object.keys(categoryLevelTree).map((key) => ({
    value: key,
    label: key,
  }));

  return catOptions;
}

/**
 * Generate Breadcrum Item for NavigationSecondaryWithBreadcrumb (components/NavigationSecondaryWithBreadcrumb)
 *
 * Given
 *   category: 'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
 *   prefixPath: '/proyek'
 *
 * return [{
 *   name: 'Desain Interior'
 *   path: '/proyek/Desain-Interior'
 * }, {
 *   name: 'Desain Interior Apartemen'
 *   path: '/proyek/Desain-Interior-Apartemen'
 * }, {
 *   name: 'Desain Interior Apartemen 2 Kamar Tidur'
 *   path: '/proyek/Desain-Interior-Apartemen-2-Kamar-Tidur'
 * }]
 */
export function getBreadcrumbItemsFromCategory(
  category,
  prefixPath = '',
  options = {},
) {
  const breadcrumbs = [];
  if (!category) return breadcrumbs;
  const arrOfCategories = category.split(' > ');
  let previousCategory = '';
  arrOfCategories.forEach((name, index) => {
    const paramsMatch = arrOfCategories
      .map((c) => {
        if (options.lowerCase) {
          return slugify(c).toLowerCase();
        }
        return slugify(c);
      })
      .filter((_, i) => i <= index);
    const isDisabled = name.includes('!');
    const path = paramsMatch
      ? `${prefixPath}/${paramsMatch.pop()}`
      : `${prefixPath}/`;
    breadcrumbs.push({
      name: previousCategory !== '' ? name.replace('!', '') : name,
      path,
      isDisabled,
      ...options,
    });
    previousCategory = name;
  });
  return breadcrumbs;
}

export function replaceSpecialCharacter(inputString, replacementChar) {
  return replace(inputString, /([._ *+?^$|(){}[\]])/g, replacementChar);
}

/** ******************************
 * CONTEXTUAL FILTER
 * *******************************
 */

// Filter 1
const catContextualLvl2 = {};
catContextualLvl2['Furnitur > Kursi'] = [
  'Armchair',
  'Kursi Makan',
  'Kursi Bar',
  'Kursi Kafe',
  'Kursi Malas',
  'Kursi Teras',
  'Stool',
  'Ottoman',
  'Kursi Panjang',
  'Kursi Tamu',
  'Bean Bag',
  'Kursi Lipat',
  'Kursi Dingklik',
  'Kursi Anak',
  'Kursi Kantor',
  'Kursi Lesehan',
  'Kursi Goyang',
];
catContextualLvl2['Furnitur > Sofa'] = [
  'Sofa 1 Dudukan',
  'Sofa 2 Dudukan',
  'Sofa 3 Dudukan',
  'Sofa Bed',
  'Sofa Set',
  'Sofa L',
  'Sofa Anak',
];
catContextualLvl2['Furnitur > Lemari'] = [
  'Lemari Pakaian 2 Pintu',
  'Lemari Pakaian 3 Pintu',
  'Lemari Anak',
  'Lemari Dapur',
];
catContextualLvl2['Furnitur > Rak & Penyimpanan'] = [
  'Kabinet',
  'Rak Display',
  'Rak Buku',
  'Rak Sepatu',
  'Rak TV',
  'Rak Dinding',
  'Laci Anak',
  'Lemari Arsip',
  'Lemari Loker',
];
catContextualLvl2['Furnitur > Tempat Tidur'] = [
  'Tempat Tidur King',
  'Tempat Tidur Queen',
  'Tempat Tidur Single',
  'Tempat Tidur Bayi',
  'Tempat Tidur Tingkat',
];
catContextualLvl2['Furnitur > Meja'] = [
  'Meja Makan',
  'Coffee Table',
  'Side Table',
  'Meja Rias',
  'Meja Belajar',
  'Meja Komputer',
  'Meja Lipat',
  'Meja TV',
  'Meja Anak',
  'Meja Kantor',
  'Meja Bar',
  'Meja Konsul',
  'Meja Teras',
];
catContextualLvl2['Furnitur > Set Ruangan'] = [
  'Set Kamar Tidur',
  'Set Ruang Keluarga',
];

// Filter 2
const catContextualFacet = {};
catContextualFacet['Furnitur > Kursi > Kursi Makan'] = {
  name: 'filteringAttributes.aFKursimakanTipe',
  values: [
    'Kursi Makan Upholstery',
    'Kursi Makan Kayu',
    'Kursi Makan Metal',
    'Kursi Makan Plastik',
  ],
};
catContextualFacet['Furnitur > Kursi > Kursi Kafe'] = {
  name: 'filteringAttributes.aFKursikafeTipe',
  values: ['Kursi Kafe Kayu', 'Kursi Kafe Metal', 'Kursi Kafe Rotan'],
};
catContextualFacet['Furnitur > Kursi > Kursi Malas'] = {
  name: 'filteringAttributes.aFKursimalasTipe',
  values: ['Kursi Santai', 'Kursi Lesehan', 'Kursi Goyang'],
};
catContextualFacet['Furnitur > Kursi > Stool'] = {
  name: 'filteringAttributes.aFStoolTipe',
  values: ['Stool Upholstery', 'Stool Kayu', 'Stool Metal', 'Stool Plastik'],
};
catContextualFacet['Furnitur > Lemari > Lemari Pakaian'] = {
  name: 'filteringAttributes.aFLemaripakaianTipe',
  values: ['Lemari 2 Pintu', 'Lemari 3+ Pintu', 'Add-Ons'],
};
catContextualFacet['Furnitur > Rak & Penyimpanan > Rak TV'] = {
  name: 'filteringAttributes.aFRaktvTipe',
  values: ['Rak TV', 'Set Rak TV'],
};
catContextualFacet['Furnitur > Rak & Penyimpanan > Rak Dinding'] = {
  name: 'filteringAttributes.aFRakdindingTipe',
  values: ['Rak Dinding', 'Bracket'],
};
catContextualFacet['Furnitur > Tempat Tidur > Tempat Tidur King'] = {
  name: 'filteringAttributes.aFTempattidurTipe',
  values: ['Tempat Tidur', 'Add-Ons'],
};
catContextualFacet['Furnitur > Tempat Tidur > Tempat Tidur Queen'] = {
  name: 'filteringAttributes.aFTempattidurTipe',
  values: ['Tempat Tidur', 'Add-Ons'],
};
catContextualFacet['Furnitur > Tempat Tidur > Tempat Tidur Single'] = {
  name: 'filteringAttributes.aFTempattidurTipe',
  values: ['Tempat Tidur', 'Add-Ons'],
};
catContextualFacet['Furnitur > Tempat Tidur > Tempat Tidur Bayi'] = {
  name: 'filteringAttributes.aFTempattidurTipe',
  values: ['Tempat Tidur', 'Add-Ons'],
};
catContextualFacet['Furnitur > Meja > Meja Makan'] = {
  name: 'filteringAttributes.aFMejamakanTipe',
  values: ['Meja Makan', 'Set Meja Makan'],
};
catContextualFacet['Furnitur > Meja > Coffee Table'] = {
  name: 'filteringAttributes.aFCoffeetableTipe',
  values: ['Coffee Table', 'Set Coffee Table'],
};
catContextualFacet['Furnitur > Meja > Meja Rias'] = {
  name: 'filteringAttributes.aFMejariasTipe',
  values: ['Meja Rias', 'Set Meja Rias'],
};
catContextualFacet['Furnitur > Meja > Meja Rias'] = {
  name: 'filteringAttributes.aFMejariasTipe',
  values: ['Meja Rias', 'Set Meja Rias'],
};
catContextualFacet['Furnitur > Meja > Meja Belajar & Meja Kerja'] = {
  name: 'filteringAttributes.aFMejabelajarTipe',
  values: ['Meja Belajar & Meja Kerja', 'Set Meja Belajar'],
};
catContextualFacet['Furnitur > Meja > Meja Anak'] = {
  name: 'filteringAttributes.aFMejaanakTipe',
  values: ['Meja Anak', 'Set Meja Anak'],
};

export const catContextual = {
  lvl2: catContextualLvl2,
  facet: catContextualFacet,
};

/** ******************************
 * FACET & CATEGORY NAMING
 * *******************************
 */

export function transformFacetLabel(facetValue) {
  // Sprei
  if (facetValue === 'Single') return 'Single (100x200cm)';
  if (facetValue === 'Super Single') return 'Super Single (120x200cm)';
  if (facetValue === 'Queen Size') return 'Queen Size (160x200cm)';
  if (facetValue === 'King Size') return 'King Size (180x200cm)';
  if (facetValue === 'Extra King') return 'Extra King (200x200cm)';
  if (facetValue === 'Extra King') return 'Extra King (200x200cm)';
  if (facetValue === 'Double') return 'Double (230x230cm)';

  // Dekor
  if (facetValue === 'Bantal Sofa & Cushion') return 'Sarung Bantal + Isi';
  if (facetValue === 'Sarung Bantal Sofa & Cushion') {
    return 'Sarung Bantal Saja';
  }

  // Desain Interior
  let _facetValue = facetValue; // eslint-disable-line no-underscore-dangle
  if (_facetValue.startsWith('Desain Interior ')) {
    _facetValue = _facetValue.replace('Desain Interior ', '');
  } else if (_facetValue.startsWith('Kitchen Set ')) {
    _facetValue = _facetValue.replace('Kitchen Set ', '');
  }

  if (_facetValue.startsWith('Apartemen ')) {
    _facetValue = _facetValue.replace('Apartemen ', '');
  } else if (_facetValue.startsWith('Rumah ')) {
    _facetValue = _facetValue.replace('Rumah ', '');
  }
  return _facetValue;
}
