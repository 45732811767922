import config from 'config';

export const K_BUNDLE_OFFER_BUNDLE_TYPE_BOGO = 'BOGO';
export const K_BUNDLE_OFFER_BUNDLE_TYPE_FREE_GIFT = 'Free Gift';
export const K_BUNDLE_OFFER_BUNDLE_TYPE_COMBO = 'Combo Set';
export const K_BUNDLE_OFFER_BUNDLE_TYPE_WHOLESALE = 'Wholesale';

export const K_BUNDLE_OFFER_LABEL_BOGO = 'Beli 1 Gratis 1!';
export const K_BUNDLE_OFFER_LABEL_FREE_GIFT = 'Hadiah Barang Gratis!';
export const K_BUNDLE_OFFER_LABEL_COMBO = 'Kombo Hemat!';
export const K_BUNDLE_OFFER_LABEL_WHOLESALE = 'Beli Grosir Lebih Hemat!';

export const K_BUNDLE_OFFER_LOGO_IMAGE_URL_BOGO = `${config.API_URL_MEDIA_CDN}/assets/icons/bogo.png`;
export const K_BUNDLE_OFFER_LOGO_IMAGE_URL_FREE_GIFT = `${config.API_URL_MEDIA_CDN}/assets/icons/free-gift.png`;
export const K_BUNDLE_OFFER_LOGO_IMAGE_URL_COMBO = `${config.API_URL_MEDIA_CDN}/assets/icons/kombo-hemat.png`;
export const K_BUNDLE_OFFER_LOGO_IMAGE_URL_WHOLESALE = `${config.API_URL_MEDIA_CDN}/assets/icons/grosir.png`;

export const K_BUNDLE_OFFER_LOGO_IMAGE_URL_MAPPING_BY_BUNDLE_TYPE: Record<
  string,
  string
> = {
  [K_BUNDLE_OFFER_BUNDLE_TYPE_BOGO]: K_BUNDLE_OFFER_LOGO_IMAGE_URL_BOGO,
  [K_BUNDLE_OFFER_BUNDLE_TYPE_FREE_GIFT]:
    K_BUNDLE_OFFER_LOGO_IMAGE_URL_FREE_GIFT,
  [K_BUNDLE_OFFER_BUNDLE_TYPE_COMBO]: K_BUNDLE_OFFER_LOGO_IMAGE_URL_COMBO,
  [K_BUNDLE_OFFER_BUNDLE_TYPE_WHOLESALE]:
    K_BUNDLE_OFFER_LOGO_IMAGE_URL_WHOLESALE,
};

export const K_BUNDLE_OFFER_LABEL_MAPPING_BY_BUNDLE_TYPE: Record<
  string,
  string
> = {
  [K_BUNDLE_OFFER_BUNDLE_TYPE_BOGO]: K_BUNDLE_OFFER_LABEL_BOGO,
  [K_BUNDLE_OFFER_BUNDLE_TYPE_FREE_GIFT]: K_BUNDLE_OFFER_LABEL_FREE_GIFT,
  [K_BUNDLE_OFFER_BUNDLE_TYPE_COMBO]: K_BUNDLE_OFFER_LABEL_COMBO,
  [K_BUNDLE_OFFER_BUNDLE_TYPE_WHOLESALE]: K_BUNDLE_OFFER_LABEL_WHOLESALE,
};

export const K_BUNDLE_OFFER_STATUS_ONGOING = 'Berjalan';

export const K_BUNDLE_OFFER_DISCOUNT_TYPE_PERCENTAGE = 'Percentage';

export const K_GWP_LOGO_URL_TOSCA = `${config.API_URL_MEDIA_CDN}/assets/icons/gwp-promo-green.png?auto=webp`;
export const K_GWP_LOGO_URL_RED = `${config.API_URL_MEDIA_CDN}/assets/icons/gwp-promo-red.png?auto=webp`;
export const K_GWP_LOGO_URL_RED_FILLED = `${config.API_URL_MEDIA_CDN}/assets/icons/gwp-promo-red-filled.png?auto=webp`;
