import React, { forwardRef, memo, MutableRefObject, useCallback } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Close } from '@dkrm/icons';
import {
  Colors,
  HorizontalDivider,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import SearchBar from 'app-libs/components/Search/SearchBar';

import { SearchBarRef } from '../types';

interface PopularCitiesModalProps {
  onClose: () => void;
  query: string;
  ref: MutableRefObject<SearchBarRef | null>;
  setQuery: (query: string) => void;
  shouldShowCloseButton: boolean;
}

const K_POPULAR_CITIES = [
  'Jakarta',
  'Bekasi',
  'Bandung',
  'Depok',
  'Tangerang',
  'Bogor',
  'Semarang',
  'Malang',
  'Surabaya',
  'Bali',
  'Medan',
];

const PopularCitiesModal: React.FC<PopularCitiesModalProps> = memo(
  forwardRef(({ onClose, query, setQuery, shouldShowCloseButton }, ref) => {
    const actionSetQueryInSearchBar = useCallback(
      (newQuery: string) => {
        if (typeof ref !== 'function') {
          ref?.current?.searchBarBasicRef?.current?.textInputRef?.current.setNativeProps(
            { text: newQuery },
          );
        }
      },
      [ref],
    );

    const handleCityClick = useCallback(
      (city: string) => {
        setQuery(city);
        actionSetQueryInSearchBar(city);
      },
      [actionSetQueryInSearchBar, setQuery],
    );

    const handleClearInput = useCallback(() => {
      setQuery('');
    }, [setQuery]);

    const handleSearchQueryChange = useCallback(
      (newQuery: string) => {
        setQuery(newQuery);
      },
      [setQuery],
    );

    return (
      <View style={s.container}>
        {shouldShowCloseButton ? (
          <ButtonWrapper
            onPress={onClose}
            hoverableViewStyle={{ alignSelf: 'flex-end' }}
          >
            {() => <Close size={24} theme="black" />}
          </ButtonWrapper>
        ) : (
          <View style={cStyles.mvl} />
        )}
        <Text theme="h4" style={cStyles.mvm}>
          Pilih Tujuan Pengiriman
        </Text>
        <Text theme="body3-black60" style={cStyles.mbxl}>
          Untuk memudahkan pengalaman belanja Kamu. Yuk pilih tujuan pengiriman
          sekarang
        </Text>
        <Text theme="h5" style={cStyles.mbxl}>
          Pilih Kota & Kecamatan
        </Text>
        <SearchBar
          ref={ref}
          value={query}
          autoFocus
          showClearButton
          placeholder="Tulis minimal 3 karakter"
          containerStyle={cStyles.pan}
          onSearchClear={handleClearInput}
          onSearchQueryChange={handleSearchQueryChange}
        />
        <HorizontalDivider color={Colors.C_BLACK_10} style={cStyles.mvxl} />
        <Text theme="h5" style={cStyles.mbxl}>
          Pilih dari Kota populer
        </Text>
        <View style={[cStyles.flexDirRow, cStyles.flexWrap]}>
          {K_POPULAR_CITIES.map((city) => {
            return (
              <ButtonWrapper
                key={city}
                onPress={() => handleCityClick(city)}
                hoverableViewStyle={s.hoverButtonStyle}
                style={s.buttonStyle}
              >
                {() => <Text theme="body3-black80">{city}</Text>}
              </ButtonWrapper>
            );
          })}
        </View>
      </View>
    );
  }),
);

const s = StyleSheet.create({
  buttonStyle: {
    ...cStyles.pam,
  },
  hoverButtonStyle: {
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.mbl,
    ...cStyles.mrl,
    alignSelf: 'flex-start',
    borderColor: Colors.C_BLACK_10,
  },
  container: {
    ...cStyles.height100,
    ...cStyles.paxxl,
    ...cStyles.width100,
  },
});

export default PopularCitiesModal;
