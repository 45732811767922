import isEmpty from 'lodash/isEmpty';
import { isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import {
  pushJob,
  startWorker,
} from 'app-libs/redux_modules/helper_modules/queue';

/**
 * This provides a default for options property
 * @property {Function} transformer - transform a result value inside `action object`
 *   before that action being dispatched. Default to identity function.
 */
const defaultOptions = {
  transformer: (result) => result,
};

/**
 * Based on article http://rackt.github.io/redux/docs/recipes/ReducingBoilerplate.html
 *
 * Middleware lets you inject custom logic that interprets every action object
 * before it is dispatched. Async actions are the most common use case for middleware.
 *
 * Without any middleware, dispatch only accepts a plain object,
 * so we have to perform AJAX calls inside our components:
 *
 * promise, types, options, shouldCallAPI = () => true
 * @property {Promise} promise
 * @property {string} types - action name
 * @property {Object |} options - additional config
 * - @property {Function} transformer - option function to transform successful result, receive @arg result
 *
 * @example
 * This is the expected action generated by this client middleware
 *
 * 1. Request
 * {
      actionType: SEARCH_QUERY_STRING_CHANGE,
      ... <all parameters, normally the action creator pass `payload`:`something`>
    }
 *
 * 2. Success
 * {
      actionType: SEARCH_QUERY_STRING_CHANGE,
      ... <all parameters, normally the action creator pass `payload`:`something`>
    }
 *
 * 3. Failure
 * {
    actionType: SEARCH_QUERY_STRING_CHANGE,
    error: <error object returned>
    ... <all parameters previously sent in request>
   }
 */

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => (next) => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }
    const {
      promise,
      types,
      options,
      shouldCallAPI = () => true,
      ...rest
    } = action;
    const tOptions = Object.assign({}, defaultOptions, options);
    // const mOptions = merge(defaultOptions, options);
    if (!promise) {
      return next(action);
    }

    /**
     * Feature QUEUE
     * [onQueue property for queue redux (make to by syncronize)]
     * @type {Object}
     */
    const {
      onQueue: channelName,
      _actionHasBeenPutIntoQueue = false,
      ...optionWithoutQueue
    } = tOptions;
    if (!_actionHasBeenPutIntoQueue && !isEmpty(channelName)) {
      const actionWithAttributeHasBeenPutIntoQueue = Object.assign({}, action, {
        options: {
          ...tOptions,
          _actionHasBeenPutIntoQueue: true,
        },
      });
      dispatch(pushJob(actionWithAttributeHasBeenPutIntoQueue, channelName));
      return dispatch(startWorker(channelName));
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    return executePromiseAction(client, dispatch, getState, next, action);
  };
}

function executePromiseAction(client, dispatch, getState, next, action) {
  // Feature LOADAUTHWHENREQUIRED: to loadAuth before calling action that has isLoginRequired
  if (action.isLoginRequired && !isAuthLoaded(getState())) {
    return _executePromiseActionWithLoadAuth(
      client,
      dispatch,
      getState,
      next,
      action,
    );
  }
  return _executePromiseAction(client, getState, next, action);
}

async function _executePromiseActionWithLoadAuth(
  client,
  dispatch,
  getState,
  next,
  action,
) {
  await dispatch(loadAuth());
  return _executePromiseAction(client, getState, next, action);
}

function _executePromiseAction(client, getState, next, action) {
  const {
    promise,
    types,
    options,
    shouldCallAPI = () => true,
    ...rest
  } = action;
  const [requestActionType, successActionType, failureActionType] = types;
  const tOptions = Object.assign({}, defaultOptions, options);
  next({ ...rest, type: requestActionType });
  return promise(client, getState)
    .then((result) => {
      const { transformer } = tOptions;
      return next({
        ...rest,
        result: transformer(result),
        type: successActionType,
      });
    })
    .then((action2) => {
      const { callback } = tOptions;
      if (callback) {
        return callback(action2.result, action2.error);
      }
      return action2;
    })
    .catch((error) => {
      const { callback } = tOptions;
      if (callback) {
        callback(null, error);
      }
      return next({ ...rest, error: error, type: failureActionType });
    });
}
