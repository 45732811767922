import produce from 'immer';
import keyMirror from 'keymirror';

import config from 'config';

import { AT as AT_AUTH } from 'app-libs/redux_modules/auth';

import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

export const AT = keyMirror({
  REGISTER_MEMBER: null,
  REGISTER_MEMBER_SUCCESS: null,
  REGISTER_MEMBER_FAIL: null,

  LOAD_MEMBER: null,
  LOAD_MEMBER_SUCCESS: null,
  LOAD_MEMBER_FAIL: null,

  UPDATE_MEMBER: null,
  UPDATE_MEMBER_SUCCESS: null,
  UPDATE_MEMBER_FAIL: null,

  UPDATE_PARENT_MEMBER: null,
  UPDATE_PARENT_MEMBER_SUCCESS: null,
  UPDATE_PARENT_MEMBER_FAIL: null,

  GET_APPLY_REFERRAL_CODE: null,
  GET_APPLY_REFERRAL_CODE_SUCCESS: null,
  GET_APPLY_REFERRAL_CODE_FAIL: null,

  GET_REFERRALS: null,
  GET_REFERRALS_SUCCESS: null,
  GET_REFERRALS_FAIL: null,
});

const initialState = {
  member: {
    asyncState: initialAsyncState,
    result: {},
  },
  referralCode: {
    asyncState: initialAsyncState,
    result: {},
  },
  referrals: {
    asyncState: initialAsyncState,
    result: {},
  },
};

const membershipReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case AT_AUTH.LOGOUT_SUCCESS:
      draft = initialState;
      break;
    case AT.LOAD_MEMBER:
    case AT.LOAD_MEMBER_SUCCESS:
    case AT.LOAD_MEMBER_FAIL:
    case AT.UPDATE_MEMBER:
    case AT.UPDATE_MEMBER_SUCCESS:
    case AT.UPDATE_MEMBER_FAIL:
    case AT.REGISTER_MEMBER:
    case AT.REGISTER_MEMBER_SUCCESS:
    case AT.REGISTER_MEMBER_FAIL:
      draft.member = memberReducer(draft.member, action);
      break;
    case AT.GET_APPLY_REFERRAL_CODE:
    case AT.GET_APPLY_REFERRAL_CODE_SUCCESS:
    case AT.GET_APPLY_REFERRAL_CODE_FAIL:
      draft.referralCode = referralCodeReducer(draft.referralCode, action);
      break;
    case AT.GET_REFERRALS:
    case AT.GET_REFERRALS_SUCCESS:
    case AT.GET_REFERRALS_FAIL:
      draft.referrals = referralsReducer(draft.referrals, action);
      break;
  }
  return draft;
}, initialState);

const memberReducer = produce((draft = initialState.member, action) => {
  draft.asyncState = asyncStateReducer(draft.asyncState, action, '_MEMBER_');
  switch (action.type) {
    case AT.REGISTER_MEMBER_SUCCESS:
    case AT.LOAD_MEMBER_SUCCESS:
    case AT.UPDATE_MEMBER_SUCCESS:
      draft.result = action.result;
      break;
  }
  return draft;
}, initialState.member);

const referralCodeReducer = produce(
  (draft = initialState.referralCode, action) => {
    draft.asyncState = asyncStateReducer(
      draft.asyncState,
      action,
      '_APPLY_REFERRAL_CODE_',
    );
    switch (action.type) {
      case AT.GET_APPLY_REFERRAL_CODE_SUCCESS:
        draft.result = action.result;
        break;
    }
    return draft;
  },
  initialState.referralCode,
);

const referralsReducer = produce((draft = initialState.referrals, action) => {
  draft.asyncState = asyncStateReducer(draft.asyncState, action, '_REFERRALS_');
  switch (action.type) {
    case AT.GET_REFERRALS_SUCCESS:
      draft.result = action.result;
      break;
  }
  return draft;
}, initialState.referrals);

export const validateReferralCode = (referralCode) => ({
  types: [
    AT.GET_APPLY_REFERRAL_CODE,
    AT.GET_APPLY_REFERRAL_CODE_SUCCESS,
    AT.GET_APPLY_REFERRAL_CODE_FAIL,
  ],
  promise: (client) =>
    client.get(`${config.API_URL_DOOM}/referral-code/${referralCode}`),
});

export const loadMemberByJwt = () => ({
  types: [AT.LOAD_MEMBER, AT.LOAD_MEMBER_SUCCESS, AT.LOAD_MEMBER_FAIL],
  promise: (client) => client.get(`${config.API_URL_DOOM}/member`),
});

export const updateMemberProfile = (memberProfile) => ({
  types: [AT.UPDATE_MEMBER, AT.UPDATE_MEMBER_SUCCESS, AT.UPDATE_MEMBER_FAIL],
  promise: (client) =>
    client.put(`${config.API_URL_DOOM}/member`, {
      data: memberProfile,
    }),
});

export function registerMemberPro(memberRegistration) {
  return {
    types: [
      AT.REGISTER_MEMBER,
      AT.REGISTER_MEMBER_SUCCESS,
      AT.REGISTER_MEMBER_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_DOOM}/register-pro`, {
        data: memberRegistration,
      }),
  };
}

export function registerMemberFamily(memberRegistration) {
  return {
    types: [
      AT.REGISTER_MEMBER,
      AT.REGISTER_MEMBER_SUCCESS,
      AT.REGISTER_MEMBER_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_DOOM}/register-family`, {
        data: memberRegistration,
      }),
  };
}

export function upgradeMemberSoma(memberRegistration) {
  return {
    types: [
      AT.REGISTER_MEMBER,
      AT.REGISTER_MEMBER_SUCCESS,
      AT.REGISTER_MEMBER_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_DOOM}/update-pro-soma`, {
        data: memberRegistration,
      }),
  };
}

export const getReferralsByEmail = (email) => ({
  types: [AT.GET_REFERRALS, AT.GET_REFERRALS_SUCCESS, AT.GET_REFERRALS_FAIL],
  promise: (client) =>
    client.get(`${config.API_URL_DOOM}/referral?email=${email}`),
});

export const updateMemberParent = (userUuid, email, parentReferralCode) => ({
  types: [
    AT.UPDATE_PARENT_MEMBER,
    AT.UPDATE_PARENT_MEMBER_SUCCESS,
    AT.UPDATE_PARENT_MEMBER_FAIL,
  ],
  promise: (client) =>
    client.post(`${config.API_URL_EGO}/membership/link-parent/`, {
      data: {
        userUuid,
        email,
        parentReferralCode,
      },
    }),
});

export default membershipReducer;
