import {
  K_ASSET_TYPE_BANNER,
  K_ASSET_TYPE_LOTTIE,
} from 'constants/advertisementConstants';
import {
  Advertisement,
  Banner,
  BannerTextInfo,
  Lottie,
} from 'app-libs/redux_modules/entity_modules/advertisement/types';

export function getAdvertisementByCampaignName(
  advertisements: Advertisement[],
  campaignName: string,
): Advertisement | undefined {
  return advertisements.find(
    (advertisement) => advertisement.campaign.name === campaignName,
  );
}

export function getBannersFromAdvertisements(
  advertisements: Advertisement[],
): Banner[] {
  const sortedAdvertisementsByRank = advertisements.sort(
    (a, b) => a.rank - b.rank,
  );

  return sortedAdvertisementsByRank.reduce(
    (_banners: Banner[], advertisement) => {
      if (advertisement.assetType === K_ASSET_TYPE_BANNER) {
        return [..._banners, advertisement.asset as Banner];
      }

      return _banners;
    },
    [],
  );
}

export function getFirstBannerDataFromAdvertisements(
  advertisements: Advertisement[],
) {
  const banners = getBannersFromAdvertisements(advertisements);

  const firstBanner = banners?.[0];
  const bannerUrl = firstBanner?.imageUrl || '';
  const link = firstBanner?.url || '';
  const bannerName = firstBanner?.name || '';

  return {
    bannerUrl,
    link,
    bannerName,
  };
}

export function getLottiesFromAdvertisements(
  advertisements: Advertisement[],
): Lottie[] {
  const sortedAdvertisementsByRank = advertisements.sort(
    (a, b) => a.rank - b.rank,
  );

  return sortedAdvertisementsByRank.reduce(
    (_lotties: Lottie[], advertisement) => {
      if (advertisement.assetType === K_ASSET_TYPE_LOTTIE) {
        return [..._lotties, advertisement.asset as Lottie];
      }

      return _lotties;
    },
    [],
  );
}

export function getFirstLottieDataFromAdvertisements(
  advertisements: Advertisement[],
) {
  const lotties = getLottiesFromAdvertisements(advertisements);

  const firstLottie = lotties?.[0];
  const lottieAnimationUrl = firstLottie?.animationUrl || '';
  const lottieToUrl = firstLottie?.toUrl || '';

  return {
    lottieAnimationUrl,
    lottieToUrl,
  };
}

export function getPromoTncsFromBannerTextInfo(
  bannerTextInfo: BannerTextInfo | null,
): string[] {
  if (bannerTextInfo) {
    const splittedTexts = (bannerTextInfo?.text || '')
      .replace(/\r\n/g, '')
      .split('(-)')
      .filter(Boolean);
    return splittedTexts.map((splittedText) => splittedText.trim());
  }
  return [];
}
