import superagent from 'superagent';
import config from 'config';

import { AvailablePremiumShippingSlotResponse } from './types';

export const getSearchUserAddressQueryKey = (query: string, page: number) =>
  `search-user-address-${query}-${page}`;

export const getAvailablePremiumShippingSlotQueryKey = (
  city: string,
  district: string,
) => ['premium-shipping-slot', city, district];

export const searchUserAddress = async (query: string, page: number) => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/orders/shipping-addresses/search/`)
    .query({
      page,
      phoneNumber: query,
    })
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const getAvailablePremiumShippingSlot = async (
  city: string,
  district: string,
): Promise<AvailablePremiumShippingSlotResponse> => {
  const res = await superagent.get(
    `${config.API_URL_GOBLIN}/shipping/premium-shipping-slots-v2/${city}/${district}/`,
  );
  return res.body;
};
