import { schema } from 'normalizr';

const activitySchema = new schema.Entity(
  'activitiesById',
  {},
  { idAttribute: 'id' },
);
const activityCollectionSchema = [activitySchema];

export const Schemas = {
  ACTIVITY: activitySchema,
  ACTIVITY_COLLECTION: activityCollectionSchema,
};
