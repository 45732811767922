import { getFirstBreadcrumbFromProductCategories } from 'app-libs/etc/categoryHelper';
import { getCartLinesValuePublic } from 'app-libs/redux_modules/entity_modules/selectors/project';
import {
  getMainProjectTemplateImageUrl,
  getProjectTemplateMainAttributeValue,
  getProjectTemplateMinPrice,
  getProjectTemplateStyleNames,
  getProjectTemplateTitle,
} from 'app-libs/redux_modules/entity_modules/selectors/projectTemplate';

export function genProduct(product) {
  return {
    content_name: product.title,
    content_category: getFirstBreadcrumbFromProductCategories(
      product.categories,
    ),
    content_ids: [product.objectID],
    content_type: 'product',
    value: product.price,
    currency: 'IDR',
  };
}

export function genPurchase(order, lines) {
  return {
    content_ids: lines.map((line) => line.product.objectID),
    content_type: 'product',
    value: order.totalExclTax,
    currency: order.currency,
  };
}

export function genRequestProjectSer(questionnaire) {
  const { projectTemplate, serviceTypeName, propertyTypeName, dealValue } =
    questionnaire;
  const minPrice = dealValue;
  const styleString = getProjectTemplateStyleNames(projectTemplate).join(', ');
  const projectTemplateTitle = getProjectTemplateTitle(projectTemplate);
  const type = getProjectTemplateMainAttributeValue(projectTemplate);
  return {
    value: minPrice, // min budget,
    currency: 'IDR',
    style: styleString, // style string
    category: serviceTypeName, // service type
    type, // primary filter,
    building: propertyTypeName, // property type,
    title: projectTemplateTitle, // project template title
    source: 'Web',
  };
}

export function genProjectSer(project) {
  const { serviceTypeName, propertyTypeName, dealValue, source, projectKey } =
    project;
  return {
    value: dealValue,
    currency: 'IDR',
    serviceTypeName,
    propertyTypeName,
    source,
    content_ids: [projectKey],
    content_type: 'product',
  };
}

export function genProjectTemplateSer(projectTemplate, pathName) {
  const { serviceType } = projectTemplate;
  const minPrice = getProjectTemplateMinPrice(projectTemplate);
  const styleString = getProjectTemplateStyleNames(projectTemplate).join(', ');
  const type = getProjectTemplateMainAttributeValue(projectTemplate);
  const projectTemplateTitle = getProjectTemplateTitle(projectTemplate);
  const imageUrl = getMainProjectTemplateImageUrl(projectTemplate);

  return {
    value: minPrice, // min budget
    currency: 'IDR',
    style: styleString, // style string
    serviceTypeName: serviceType, // service type
    type, // primary filter,
    title: projectTemplateTitle, // project template title
    url: pathName, // url
    imageUrl, // image url
  };
}

export function genPortfolioSer(project) {
  const { title, serviceTypeName, projectKey, category, propertyTypeName } =
    project;
  const cartLinesValuePublic = getCartLinesValuePublic(project);
  return {
    content_type: 'product',
    currency: 'IDR',
    content_name: title,
    serviceTypeName,
    content_ids: [projectKey],
    content_category: category,
    value: parseFloat(cartLinesValuePublic || 0).toFixed(2),
    propertyTypeName,
  };
}
