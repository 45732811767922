import { createSelector } from 'reselect';

const emptyObject = {};

export const getAlbum = (state) => state.album.album;

/** Album: Wishlist-specific functions */

export const isPinsLoaded = (state) => state.album.status.pinsLoaded;

export const getPins = (state) => state.album.album.pins || [];

export const getPinByObjectId = (state) => {
  const pins = getPins(state);
  const pinByObjectId = {};
  pins.forEach((pin) => {
    pinByObjectId[pin.contentId] = pin;
  });
  return pinByObjectId;
};

export const getNumberOfPins = (state) => getPins(state).length;

export const getPinsLoading = (state) => state.album.status.pinsLoading;

export const getPinsError = (state) => state.album.status.error;

export const getPinByProduct = (state, product) => {
  const pins = getPins(state);
  const pin = pins.find((item) => item.contentId === product.objectID);
  return pin || {};
};

export const getPinByProductV2 = (product) =>
  createSelector(
    getPins,
    (pins) =>
      pins.find((item) => item.contentId === product.objectID) || emptyObject,
  );

export function getWishlistItemsInBasket(state) {
  const { basket } = state;
  const pinByObjectId = getPinByObjectId(state);
  return basket.lines.filter((item) => pinByObjectId[item.product.objectID]);
}
