import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    ...cStyles.bgw,
    ...cStyles.brs,
    ...cStyles.mlm,
    ...cStyles.mbm,
    ...cStyles.pvs,
    ...cStyles.shadowm,
    paddingHorizontal: 6,
    shadowRadius: 2,
    shadowOpacity: 0.25,
    shadowOffset: { width: 0, height: 2 },
  },
  text: {
    fontSize: 12,
  },
});
