export const K_DEKORUMA_FACEBOOK_PIXEL_ID = '209543129387571';
export const K_DNB_FACEBOOK_PIXEL_ID = '326653771224316';
export const K_CAREER_FACEBOOK_PIXEL_ID = '7080374112367261';

export const K_TRACK_CLICKED_TOP_PRODUCT_RECOMMENDATION =
  'clicked_top_product_recommendation_ENGAGEMENT';
export const K_TRACK_CLICKED_BEST_SELLER =
  'clicked_product_best_seller_ENGAGEMENT';
export const K_TRACK_CLICKED_PRODUCT_FROM_MOODBOARD =
  'clicked_product_from_moodboard_ENGAGEMENT';
export const K_TRACK_CLICKED_SIMILAR_ITEMS =
  'clicked_product_similar_items_ENGAGEMENT';
export const K_TRACK_CLICKED_RECOMMENDED_SERIES =
  'clicked_product_recommended_series_ENGAGEMENT';
export const K_TRACK_CLICKED_FREQUENTLY_BOUGHT_TOGETHER =
  'clicked_product_frequently_bought_together_ENGAGEMENT';
export const K_TRACK_ADDED_PRODUCT_FREQUENTLY_BOUGHT_TOGETHER_TO_CART =
  'added_product_frequently_bought_together_to_cart_CHECKOUT';
export const K_TRACK_CLICKED_PRODUCT_SIBLING =
  'clicked_product_sibling_ENGAGEMENT';
export const K_TRACK_CLICKED_UPSELL_ITEMS =
  'clicked_product_upsell_items_ENGAGEMENT';
export const K_TRACK_CLICKED_RELATED_MOODBOARDS =
  'clicked_product_related_moodboards_ENGAGEMENT';
export const K_TRACK_CLICKED_USER_WHO_VIEWED_THIS_ALSO_VIEWED_ITEMS =
  'clicked_product_user_who_viewed_this_also_viewed_items_ENGAGEMENT';
export const K_TRACK_CLICKED_SPONSORED_PRODUCT_RECOMMENDATION =
  'clicked_sponsored_product_recommendation_ENGAGEMENT';
export const K_TRACK_CLICKED_EXCLUSIVE_PRODUCT_RECOMMENDATION =
  'clicked_exclusive_product_recommendation_ENGAGEMENT';
export const K_TRACK_CLICKED_TOP_BRAND_CATEGORY_PRODUCT_RECOMMENDATION =
  'clicked_top_brand_category_product_recommendation_ENGAGEMENT';
export const K_TRACK_CLICKED_PRODUCT_CAROUSEL_CATEGORY_PRODUCT_RECOMMENDATION =
  'clicked_product_carousel_category_product_recommendation_ENGAGEMENT';
export const K_TRACK_CLICKED_ROOM_DETAIL_FURNITURE_OF_THE_MONTH =
  'clicked_room_detail_furniture_of_the_month_ENGAGEMENT';

export const K_PROPERTY_OPENED_FROM_TOP_PRODUCT = 'top-product';
export const K_PROPERTY_OPENED_FROM_BEST_SELLER = 'best seller';
export const K_PROPERTY_OPENED_FROM_SIMILAR_ITEMS = 'similar items';
export const K_PROPERTY_OPENED_FROM_RECOMMENDED_SERIES = 'recommended series';
export const K_PROPERTY_OPENED_FROM_FREQUENTLY_BOUGHT_TOGETHER =
  'frequently bought together';
export const K_PROPERTY_OPENED_FROM_USER_WHO_VIEWED_THIS_ALSO_VIEWED_ITEMS =
  'user who viewed this also viewed';
export const K_PROPERTY_OPENED_FROM_SPONSORED_PRODUCT_RECOMMENDATION =
  'sponsored product';
export const K_PROPERTY_OPENED_FROM_EXCLUSIVE_PRODUCT_RECOMMENDATION =
  'exclusive product';
export const K_PROPERTY_OPENED_FROM_PRODUCT_SIBLING = 'product sibling';
export const K_PROPERTY_OPENED_FROM_MOODBOARD = 'moodboard';

export const K_PROPERTY_OPENED_FROM_MATTRESS_HOMEPAGE = 'mattress homepage';
export const K_PROPERTY_OPENED_FROM_MATTRESS_ADVANCED_FILTER_PAGE =
  'mattress advanced filter page';
export const K_PROPERTY_OPENED_FROM_MATTRESS_SEARCH_PAGE =
  'mattress search page';
export const K_PROPERTY_OPENED_FROM_MATTRESS_BRAND_SEARCH_PAGE =
  'mattress brand search page';

export const K_TRACK_VIEWED_APP_BANNER = 'viewed_app_banner_ADS';
export const K_TRACK_CLICKED_APP_BANNER = 'clicked_app_banner_ADS';

export const K_TRACK_VIEWED_BOOKING_FOOTER_BAR =
  'viewed_booking_footer_bar_HOUSE';

export const K_TRACKING_CONTENT_TYPE_PRODUCT = 'product';
export const K_TRACKING_CONTENT_TYPE_IMAGE = 'image';
export const K_TRACKING_CONTENT_TYPE_TEXT = 'text';

export const K_TRACKING_PLACEMENT_DRAWER_MENU = 'Drawer Menu';
export const K_TRACKING_PLACEMENT_TOP_BANNER = 'Top Banner';
export const K_TRACKING_PLACEMENT_PRODUCT_DETAIL = 'Product detail';
export const K_TRACKING_PLACEMENT_PRODUCT_CARD = 'Product card';

export const K_TRACKING_PLACEMENT_FOOTER = 'Footer';
export const K_TRACKING_PLACEMENT_SECTION = 'Section';

export const K_TRACKING_PLACEMENT_MY_ALBUM = 'My album';

export const K_TRACKING_MAIN_TAB_PHOTOS = 'Photos';
export const K_TRACKING_MAIN_TAB_ARTICLES = 'Articles';
export const K_TRACKING_MAIN_TAB_SHOP = 'Shop';
export const K_TRACKING_MAIN_TAB_DESIGN_AND_BUILD = 'Design and Build';
export const K_TRACKING_MAIN_TAB_DEKOHUB = 'Dekohub';
export const K_TRACKING_MAIN_TAB_DEKOHOUSE = 'Dekohouse';

/**
 * Tracking constants tag manager
 */
export const K_TAG_MANAGER_TRACK_ADD_TO_CART = 'marketplace-add-to-cart';
export const K_TAG_MANAGER_TRACK_INITIATE_CHECKOUT =
  'marketplace-initiate-checkout';
export const K_TAG_MANAGER_TRACK_CLICK_PLACE_ORDER = 'marketplace-place-order';
