import {
  K_IMAGE_LANDSCAPE_THUMB_CROP_PARAMS,
  K_IMAGE_LANDSCAPE_CROP_PARAMS,
} from 'constants/productConstants';
import {
  getCDNImageUrl as getCDNImageUrlAdapter,
  getS3ImageAbsoluteUrl as getS3ImageAbsoluteUrlAdapter,
  getPatternImageCropUrl as getPatternImageCropUrlAdapter,
  getNoImageUrl as getNoImageUrlAdapter,
  getProductImageUrl as getProductImageUrlAdapter,
  getSmallerQualityImageUrl as getSmallerQualityImageUrlAdapter,
  getUrlAppendedWithDimension as getUrlAppendedWithDimensionAdapter,
  getUrlWithoutWhiteBackground as getUrlWithoutWhiteBackgroundAdapter,
  getImageSizeFromUrl as getImageSizeFromUrlAdapter,
  resizeImage as resizeImageAdapter,
  getResponsiveImageUrl as getResponsiveImageUrlAdapter,
} from '@dkrm/general-libs/Utils/imageUtils';

export function getUrlWithoutQueryParam(uri) {
  const idx = uri.indexOf('?');
  let currentUri = uri;
  if (idx >= 0 && idx < uri.length) {
    currentUri = uri.substring(0, idx);
  }
  return currentUri;
}

/**
 * @deprecated use getCDNImageUrl(image) from general-libs/Utils/imageUtils instead
 * Util to transform s3 absolute URL, into URL prefixed by Media CDN
 * eg.
 *     input: http://f1-static.s3-ap-southeast-1.amazonaws.com/catalogue/KER-7.jpg?versionId=123
 *     output: https://media.dekoruma.com/catalogue/KER-7.jpg
 */
export function getCDNImageUrl(image) {
  return getCDNImageUrlAdapter(image);
}

/**
 * @deprecated use getS3ImageAbsoluteUrl() from '@dkrm/general-libs/Utils/imageUtils' instead
 * Util to get s3 absolute URL, from imageName or s3 absolute URL itself
 * eg.
 *     input: http://f1-static.s3-ap-southeast-1.amazonaws.com/catalogue/KER-7.jpg
 *     output: catalogue/KER-7.jpg
 */
export function getS3ImageAbsoluteUrl(image) {
  return getS3ImageAbsoluteUrlAdapter(image);
}

/**
 * @deprecated use getProductImageUrl() from '@dkrm/general-libs/Utils/imageUtils' instead
 * Given the upc, get the Media CDN product url
 * @param  upc INF-1517
 * @return url https://media.dekoruma.com/catalogue/INF-1517.jpg
 */
export function getProductImageUrl(upc) {
  return getProductImageUrlAdapter(upc);
}

export function getImageThumbVideoUrl(image) {
  return image
    .replace('mp4', 'jpg')
    .replace('video', 'image')
    .replace('c_limit,h_480,w_960', 'c_lpad,h_140,w_280');
}

export function getImageNormalLandscapeCropUrl(image) {
  if (image.toLowerCase().endsWith('.mp4')) return image;

  return getCDNImageUrl(image) + K_IMAGE_LANDSCAPE_CROP_PARAMS;
}

export function getImageThumbLandscapeCropUrl(image) {
  if (image.toLowerCase().endsWith('.mp4')) return getImageThumbVideoUrl(image);

  return `${getCDNImageUrl(image)}?${K_IMAGE_LANDSCAPE_THUMB_CROP_PARAMS}`;
}

/**
 * @deprecated use getNoImageUrl() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getNoImageUrl() {
  return getNoImageUrlAdapter();
}

/**
 * @deprecated use getPatternImageCropUrl() from @dkrm/general-libs/Utils/imageUtils instead
 * Util to grab pattern URL from s3 bucket, already cropped with specific size by Media CDN.
 * With assumption that patternName equals to imagename
 * eg.
 *     input: Teddybear
 *     output: https://media.dekoruma.com/contextual-images/teddybear.jpg
 */
export function getPatternImageCropUrl(patternName) {
  return getPatternImageCropUrlAdapter(patternName);
}

/**
 * @deprecated use getSmallerQualityImageUrl() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getSmallerQualityImageUrl(url, quality) {
  return getSmallerQualityImageUrlAdapter(url, quality);
}

/**
 * @deprecated use getUrlAppendedWithDimension() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getUrlAppendedWithDimension(uri, w, h, type) {
  return getUrlAppendedWithDimensionAdapter(uri, w, h, type);
}

/**
 * @deprecated use getUrlWithoutWhiteBackground() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getUrlWithoutWhiteBackground(uri) {
  return getUrlWithoutWhiteBackgroundAdapter(uri);
}

/**
 * @deprecated use getImageSizeFromUrl() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getImageSizeFromUrl(url) {
  return getImageSizeFromUrlAdapter(url);
}

/**
 * @deprecated use resizeImage() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function resizeImage(uri, imageWidth, imageHeight, fit) {
  return resizeImageAdapter(uri, imageWidth, imageHeight, fit);
}

/**
 * @deprecated use getResponsiveImageUrl() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getResponsiveImageUrl(
  uri,
  imageWidth,
  imageHeight,
  ratioMultiplier,
  fit,
) {
  return getResponsiveImageUrlAdapter(
    uri,
    imageWidth,
    imageHeight,
    ratioMultiplier,
    fit,
  );
}
