/*
 * last updated 27 Sep 2024.
 *
 * retrieve from https://assortment.dekoruma.com/api/v1/category-tree
 *
 *
 */

export const productCategoryTree = {
  'Aksesoris Rumah': {
    Cermin: {
      'Cermin Gantung': {},
      'Standing Mirror': {},
    },
    'Perlengkapan Kamar Tidur': {
      'Alas Kasur': {},
      'Selimut, Bed Cover & Quilt': {},
      Slippers: {},
      'Kasur Lipat': {},
      'Bantal & Guling': {},
      Sprei: {},
    },
    Dekorasi: {
      Jam: {},
      'Hiasan Gantung': {},
      Poster: {},
      'Tempat Lilin': {},
      Figurin: {},
      'Pengharum Ruangan': {},
      'Bingkai Foto': {},
    },
    Lampu: {
      'Aksesoris Lampu': {},
      'Lampu Dinding': {},
      'Standing Lamp': {},
      'Lampu Taman': {},
      'Lampu Meja': {},
      'Lampu Gantung & Lampu Plafon': {},
      'Bohlam & Lampu LED': {},
    },
    'Tanaman Hias & Vas': {
      'Tanaman Hias': {},
      'Vas & Pot Bunga': {},
    },
    Tekstil: {
      Handuk: {},
      'Isi Bantal': {},
      'Serbet & Celemek': {},
      'Sarung Galon & Kulkas': {},
      Karpet: {},
      'Taplak Meja': {},
      'Sarung Bantal': {},
      Keset: {},
      'Bantal Dekorasi': {},
      'Bean Bag & Kursi Lesehan': {},
    },
    'Gorden & Aksesoris': {},
    'Peralatan Dapur': {
      'Kitchen Utensils': {},
      'Loyang & Wadah Panggang': {},
      'Wajan & Panci': {},
      'Kotak Makanan & Toples': {},
      'Rak Dapur': {},
      'Pisau & Talenan': {},
      'Baking Utensils': {},
    },
    'Peralatan Makan & Minum': {
      Placemat: {},
      Pitcher: {},
      Nampan: {},
      Gelas: {},
      Piring: {},
      'Piring Saji': {},
      Teko: {},
      Mangkok: {},
      'Cangkir & Mug': {},
      'Alat Makan': {},
      'Botol & Tumbler': {},
    },
    'Perkakas Rumah Tangga': {
      Keran: {},
      'Wastafel & Closet': {},
      Sink: {},
      'Alat Pembersih': {},
      Tangga: {},
      Shower: {},
    },
    'Tempat Penyimpanan': {
      'Keranjang Baju': {},
      'Kotak Penyimpanan': {},
      'Tempat Tisu': {},
      'Rak Kamar Mandi': {},
      'Gantungan Pakaian': {},
      'Aksesoris Kamar Mandi': {},
    },
  },
  Elektronik: {
    'Home Appliances': {
      'Air Conditioner': {},
      'Vacuum Cleaner': {},
      'Water Heater': {},
      Dryer: {},
      Televisi: {},
      Kulkas: {},
      'Mesin Cuci': {},
      'Smart Home': {},
    },
    'Elektronik Dapur': {
      Dispenser: {},
      'Rice Cooker': {},
      'Slow Cooker': {},
      Kompor: {},
      'Deep Fryer': {},
      Microwave: {},
      Mixer: {},
      'Oven & Toaster': {},
      Blender: {},
    },
  },
  'Product Part': {},
  Furniture: {
    'Dekorasi & Pelengkap': {},
    Komponen: {
      Aksesoris: {},
      Hardware: {},
    },
    'Meja Tamu & Nakas': {
      Nakas: {},
      'Meja Tamu': {},
    },
    'Meja & Kursi Makan': {
      'Meja Bar': {},
      'Kursi Makan': {},
      'Meja Makan': {},
      'Kursi Bar': {},
      'Kursi Panjang': {},
    },
    Sofa: {
      'Sofa L': {},
      'Sofa 2 Seater': {},
      'Sofa 1 Seater & Armchair': {},
      'Sofa 3 Seater': {},
      'Kursi Ottoman & Stool': {},
      'Sofa Bed': {},
    },
    'Kabinet Dapur & Troli': {
      'Kabinet Dapur': {},
      'Rak & Troli Dapur': {},
    },
    'Lemari Pakaian': {
      'Meja Rias': {},
      'Lemari Pakaian 2 Pintu': {},
      'Lemari Pakaian 3 Pintu': {},
      'Lemari Laci': {},
    },
    'Meja & Kursi Kerja': {
      'Kursi Kantor': {},
      'Meja Kerja': {},
      'Meja Kantor': {},
      'Kursi Tunggu': {},
    },
    'Penyimpanan Kantor': {
      'Lemari Kantor': {},
    },
    'Rak & Penyimpanan': {
      'Rak Sepatu': {},
      'Meja TV': {},
      'Rak Buku & Rak Display': {},
      'Rak Dinding & Ambalan': {},
      Credenza: {},
    },
    Kasur: {
      'Super Fit': {},
      'Homedoki Sleep': {},
      Wesleep: {},
      'Zees By Ananta': {},
      Ocean: {},
      Olympic: {},
      Domi: {},
      Aeroflow: {},
      Luxio: {},
      'The Luxe': {},
      'Eazy Sleep': {},
      Dunlopillo: {},
      Simmons: {},
      'Mimpi Sleep': {},
      'Simply Bed': {},
      'La Dova': {},
      Superland: {},
      Theraspine: {},
      'Lady Americana': {},
      'In The Box': {},
      'Tote Bed': {},
      Guhdo: {},
      'King Koil': {},
      Serta: {},
      Zees: {},
      Elite: {},
      Airland: {},
      Comforta: {},
      Florence: {},
      Quantum: {},
      'Sleep Care': {},
      Cozylila: {},
      Zinus: {},
      'Livien Sleep': {},
      Resta: {},
      Starlead: {},
    },
    Wallpanel: {
      Accessories: {},
    },
    'Tempat Tidur': {
      'Tempat Tidur Queen': {},
      'Tempat Tidur Single': {},
      'Tempat Tidur King': {},
    },
  },
  Sipil: {
    Keramik: {},
    Dinding: {
      Wallpaper: {},
    },
    Lantai: {
      'Instalasi Vinyl & Parket': {},
      Vinyl: {},
      Parket: {},
    },
  },
  Tirai: {
    Blind: {},
  },
  'Staff Only': {
    'Barang Pelengkap': {},
  },
  Preliminary: {
    Mobilisasi: {},
  },
  Countertop: {
    Quadra: {},
  },
};

export default productCategoryTree;

export const projectCategoryTree = {
  'Kitchen Set': {
    'Kitchen Set Scandinavian': {},
    'Kitchen Set Korea': {},
    'Kitchen Set Modern Minimalis': {},
    'Kitchen Set Letter I': {},
    'Kitchen Set Island': {},
    'Kitchen Set Letter L': {},
    'Kitchen Set Letter U': {},
  },
  'Desain Interior': {
    'Desain Interior Apartemen': {
      'Desain Interior Apartemen Studio': {},
      'Desain Interior Apartemen 1 Kamar Tidur': {},
      'Desain Interior Apartemen 2 Kamar Tidur': {},
      'Desain Interior Apartemen Space Saving dan Multifungsi': {},
      'Desain Interior Apartemen 3+ Kamar Tidur': {},
    },
    'Desain Interior Rumah': {
      'Desain Interior Rumah Modern Minimalis': {},
      'Desain Interior Rumah Scandinavian': {},
      'Desain Interior Rumah Modern Klasik': {},
      'Desain Interior Rumah Space Saving dan Multifungsi': {},
      'Desain Interior Rumah 1 Kamar Tidur': {},
      'Desain Interior Rumah 2 Kamar Tidur': {},
      'Desain Interior Rumah 3+ Kamar Tidur': {},
    },
  },
  'Hospitality & Retail': {},
  Developer: {},
  Office: {},
  'Walk-in Closet': {},
};

export const projectLocationCategoryTree = {
  projectCategoriesByProvince: {
    'Jawa Barat': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
      'Kitchen Set > Kitchen Set Modern Minimalis',
      'Kitchen Set > Kitchen Set Scandinavian',
      'Office',
      'Walk-in Closet',
    ],
    Banten: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
      'Hospitality & Retail',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Jawa Timur': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Modern Klasik',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    '': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
    ],
    'DKI Jakarta': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 3+ Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Modern Minimalis',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Scandinavian',
      'Developer',
      'Hospitality & Retail',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
      'Office',
    ],
  },
  projectCategoriesByCity: {
    Depok: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Jakarta Selatan': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 3+ Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Modern Minimalis',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Scandinavian',
      'Developer',
      'Hospitality & Retail',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
      'Office',
    ],
    Bandung: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Modern Minimalis',
      'Kitchen Set > Kitchen Set Scandinavian',
    ],
    Surabaya: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Modern Klasik',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    Tangerang: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Jakarta Timur': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Tangerang Selatan': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
      'Hospitality & Retail',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    Cimahi: [
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Kab. Sidoarjo': [
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    Bekasi: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Modern Minimalis',
      'Kitchen Set > Kitchen Set Scandinavian',
      'Office',
      'Walk-in Closet',
    ],
    'Jakarta Pusat': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
    ],
    'Jakarta Barat': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Developer',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
      'Office',
    ],
    'Jakarta Utara': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
    ],
    'DKI Jakarta': ['Kitchen Set > Kitchen Set Island'],
    'Kab. Tangerang': ['Hospitality & Retail'],
    'Kab. Bogor': ['Kitchen Set > Kitchen Set Letter L'],
  },
  projectCategoriesByDistrict: {
    Pagedangan: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
    ],
    'Jakarta Selatan': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter U',
      'Office',
    ],
    Tangerang: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Developer',
      'Kitchen Set > Kitchen Set Letter I',
    ],
    Rawalumbu: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Scandinavian',
    ],
    Pamulang: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Jakarta Timur': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
    ],
    'Kramat Jati': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Kembangan: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Developer',
    ],
    Pancoran: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Office',
    ],
    'Kebon Jeruk': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
    ],
    Serpong: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Hospitality & Retail',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Pancoran Mas': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Mampang Prapatan': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
    ],
    Cilodong: ['Kitchen Set > Kitchen Set Letter L'],
    'Serpong Utara': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
    ],
    Beji: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Kebayoran Lama': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter U',
      'Office',
    ],
    Grogol: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
      'Office',
    ],
    Cakung: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Mulyorejo: [
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    Cilandak: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Modern Minimalis',
      'Developer',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
      'Office',
    ],
    Jatisampurna: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
    ],
    'Pondok Aren': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Jatinegara: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
    ],
    Cinere: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Pondokgede: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Modern Minimalis',
      'Kitchen Set > Kitchen Set Scandinavian',
      'Walk-in Closet',
    ],
    'Johar Baru': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
    ],
    Pademangan: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
    ],
    'Kebayoran Baru': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Hospitality & Retail',
      'Kitchen Set > Kitchen Set Island',
      'Office',
    ],
    Setiabudi: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 3+ Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Scandinavian',
    ],
    'Jakarta Barat': ['Developer'],
    Sawangan: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Coblong: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter I',
      'Kitchen Set > Kitchen Set Modern Minimalis',
      'Kitchen Set > Kitchen Set Scandinavian',
    ],
    Gubeng: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
    ],
    'Cempaka Putih': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
    ],
    Karawaci: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Developer',
    ],
    'Duren Sawit': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter I',
    ],
    Limo: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
    ],
    Ciputat: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Bekasi Utara': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
    ],
    'Pasar Minggu': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter I',
    ],
    Jakarta: ['Kitchen Set > Kitchen Set Island'],
    'Ciputat Timur': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Mustika Jaya': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
    ],
    'Bekasi Barat': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter I',
    ],
    'Dukuh Pakis': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah Modern Klasik',
      'Kitchen Set > Kitchen Set Island',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Cimahi Tengah': ['Kitchen Set > Kitchen Set Letter L'],
    Penjaringan: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
    ],
    'Keb lama': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Ciracas: ['Kitchen Set > Kitchen Set Island'],
    Cimahi: ['Kitchen Set > Kitchen Set Letter I'],
    Cicendo: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
    ],
    Jagakarsa: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter I',
    ],
    Cengkareng: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Developer',
    ],
    Kemayoran: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 2 Kamar Tidur',
    ],
    'Bekasi Selatan': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
      'Office',
    ],
    Cipayung: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Setu: ['Kitchen Set > Kitchen Set Letter L'],
    Pesanggrahan: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
    ],
    Gedangan: [
      'Kitchen Set > Kitchen Set Letter L',
      'Kitchen Set > Kitchen Set Letter U',
    ],
    'Kelapa Gading': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
    ],
    Tebet: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
    ],
    Cipondoh: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 3+ Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    'Pulo Gadung': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen Studio',
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
    ],
    Pinang: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
    ],
    'Medan Satria': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
    ],
    Bandung: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
      'Kitchen Set > Kitchen Set Island',
    ],
    Jatiasih: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Kitchen Set > Kitchen Set Letter L',
    ],
    Bekasi: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
      'Developer',
    ],
    Ciledug: [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 1 Kamar Tidur',
    ],
    'Tanah Abang': [
      'Desain Interior > Desain Interior Rumah > Desain Interior Rumah 2 Kamar Tidur',
    ],
    Cileungsi: ['Kitchen Set > Kitchen Set Letter L'],
    Palmerah: ['Kitchen Set > Kitchen Set Letter U', 'Office'],
    'Tambun Selatan': [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
    ],
    Karangtengah: ['Kitchen Set > Kitchen Set Letter L'],
    Senen: [
      'Desain Interior > Desain Interior Apartemen > Desain Interior Apartemen 1 Kamar Tidur',
    ],
    'Kelapa Dua': ['Hospitality & Retail'],
  },
  cityByProvince: {
    'DKI Jakarta': [
      'DKI Jakarta',
      'Jakarta Barat',
      'Jakarta Pusat',
      'Jakarta Selatan',
      'Jakarta Timur',
      'Jakarta Utara',
      'Tangerang Selatan',
    ],
    Banten: ['Kab. Tangerang', 'Tangerang', 'Tangerang Selatan'],
    'Jawa Timur': ['Kab. Sidoarjo', 'Surabaya'],
    'Jawa Barat': ['Bandung', 'Bekasi', 'Cimahi', 'Depok', 'Kab. Bogor'],
    '': ['Depok'],
  },
  districtByCity: {
    'Jakarta Barat': [
      'Cengkareng',
      'Grogol',
      'Jakarta Barat',
      'Kalideres',
      'Kebon Jeruk',
      'Kembangan',
      'Palmerah',
    ],
    Depok: [
      'Beji',
      'Cibubur',
      'Cilodong',
      'Cimanggis',
      'Cinere',
      'Limo',
      'Pancoran Mas',
      'Sawangan',
    ],
    Surabaya: ['Dukuh Pakis', 'Gubeng', 'Mulyorejo'],
    Bekasi: [
      'Bekasi',
      'Bekasi Barat',
      'Bekasi Selatan',
      'Bekasi Timur',
      'Bekasi Utara',
      'Jatiasih',
      'Jatisampurna',
      'Medan Satria',
      'Mustika Jaya',
      'Pondokgede',
      'Rawalumbu',
      'Tambun Selatan',
    ],
    'Jakarta Selatan': [
      'Cilandak',
      'Jagakarsa',
      'Jakarta Selatan',
      'Keb lama',
      'Kebayoran Baru',
      'Kebayoran Lama',
      'Mampang Prapatan',
      'Pancoran',
      'Pasar Minggu',
      'Pesanggrahan',
      'Setiabudi',
      'Tebet',
    ],
    'Jakarta Timur': [
      'Cakung',
      'Cipayung',
      'Ciracas',
      'Duren Sawit',
      'Jakarta Timur',
      'Jatinegara',
      'Kramat Jati',
      'Pulo Gadung',
    ],
    'Jakarta Utara': ['Kelapa Gading', 'Pademangan', 'Penjaringan'],
    Bandung: ['Bandung', 'Cicendo', 'Coblong'],
    'Jakarta Pusat': [
      'Cempaka Putih',
      'Johar Baru',
      'Kemayoran',
      'Senen',
      'Tanah Abang',
    ],
    Tangerang: [
      'Ciledug',
      'Cipondoh',
      'Karangtengah',
      'Karawaci',
      'Pinang',
      'Tangerang',
    ],
    'Tangerang Selatan': [
      'Ciputat',
      'Ciputat Timur',
      'Pagedangan',
      'Pamulang',
      'Pondok Aren',
      'Serpong',
      'Serpong Utara',
      'Setu',
    ],
    'Kab. Sidoarjo': ['Gedangan'],
    'DKI Jakarta': ['Jakarta'],
    Cimahi: ['Cimahi', 'Cimahi Tengah'],
    'Kab. Tangerang': ['Kelapa Dua', 'Pagedangan'],
    'Kab. Bogor': ['Cileungsi'],
  },
};

export const threeDimensionalAssetCategoryTree = {
  Loose: {
    Dekorasi: {},
    Furnitur: {
      Sofa: {},
      Bed: {},
      'Side Table': {},
      'Rak Display': {},
      'Rak Dinding': {},
      'Dining Table': {},
      'Coffee Table': {},
      Credenza: {},
      Chair: {},
      Cabinet: {},
      'Meja Kerja': {},
      'Meja Rias': {},
      'Shoe Rack': {},
      Stool: {},
    },
    Kitchen: {
      Aksesoris: {},
      Appliances: {},
    },
    Lampu: {},
  },
  Modular: {
    'Kitchen Part': {
      Kitchenset: {},
      'Aksesoris Modular': {},
      'Countertop, Backsplash, Ambalan': {},
    },
    Wardrobe: {
      'Style Kana': {},
      'Style Kalea': {},
      'Style Kafka': {},
      Aksesoris: {},
    },
  },
  Material: {
    Wallpaper: {},
    Vinyl: {},
    'Taco Sheet': {},
    'Solid Surface': {},
    Parquet: {},
    Mozaik: {},
    Melaminto: {},
    Keramik: {},
    'Kain Sofa': {},
    Kaca: {},
    HPL: {},
    'Granit & Marmer': {},
    Duco: {},
    Cat: {},
  },
  'Sketchup Template': {
    'Kitchen Set': {},
    'Desain Interior': {},
  },
};
