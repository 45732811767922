import { Basket, BasketLine, BasketVoucher } from './types';

export function getLinesTotalDiscount(lines: BasketLine[]): number {
  let discount = 0;

  lines.forEach((line) => {
    discount +=
      parseFloat(line.priceInclTaxExclDiscounts) -
      parseFloat(line.priceInclTax);
  });

  return discount;
}

export function getLinePriceAfterDiscount(line: BasketLine): number {
  return parseFloat(line.priceInclTax);
}

export function getUnavailableBasketVouchers(basket: Basket): BasketVoucher[] {
  const { vouchers } = basket;
  return vouchers.filter((voucher) => !voucher.isAvailable);
}
