import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import { useVisibility } from 'app-libs/hook_modules/visibility';

import { getBasket } from 'app-libs/redux/selector';
import { Basket } from 'app-libs/redux_modules/entity_modules/basket/types';

import RemoveFromCartConfirmationModal from 'app-libs/components/Checkout/Cart/components/shared/RemoveFromCartConfirmationModal';

import { useDeleteBasket } from './utils';

const ClearBasketButton: React.FC = memo(() => {
  const basket: Basket = useSelector(getBasket);
  const [shouldShowModal, actionShowModal, actionHideModal] = useVisibility();

  const deleteBasket = useDeleteBasket();

  const actionClearBasket = useCallback(() => {
    actionHideModal();
    deleteBasket(basket?.id);
  }, [actionHideModal, basket, deleteBasket]);

  const actionOpenClearBasketConfirmationModal = useCallback(() => {
    actionShowModal();
  }, [actionShowModal]);

  return (
    <>
      <ButtonWithText
        themeType="tertiary"
        theme="red"
        title="Reset"
        small
        onPress={actionOpenClearBasketConfirmationModal}
      />
      {shouldShowModal && (
        <RemoveFromCartConfirmationModal
          onCancel={actionHideModal}
          onSubmit={actionClearBasket}
        />
      )}
    </>
  );
});

export default ClearBasketButton;
