import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  Colors,
  HorizontalDivider,
  ScrollView,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';

import { withSearchConsumer } from 'app-libs/AlgoliaAdapter';
import { createDisjunctiveSelector } from 'app-libs/SearchKit/components/filters/DisjunctiveFacetFilter';
import { trackClickedApplyQuickFilterButton } from 'app-libs/trackers';

import styles from './styles';

const K_DISJUNCTIVE_FACET = 'DISJUNCTIVE';
const K_NUMERICAL_FACET = 'NUMERICAL';
const K_MAX_NB_HITS = 1000;
const K_SHOWN_LABEL_MAPPING = {
  'Discount Up To 50%': 'Diskon 50%',
  'Discount Up To 80%': 'Diskon 80%',
  'New Arrival': 'New Arrival',
  'Rating 4+': 'Rating 4+',
  'Exclusive Brand': 'Exclusive Brand',
};

const getNumberOfHits = (searchAdapter) => searchAdapter.getNumberOfHits();

const getFilterData = (searchAdapter, { filterFacets }) => {
  const state = searchAdapter.getState();
  return filterFacets.reduce((acc, facet) => {
    if (facet.facetType === K_DISJUNCTIVE_FACET) {
      const {
        isUsingDisjunctiveSelector = true,
        isUsingMultipleValueDisjunctiveFacet = false,
      } = facet;
      const usedFacets =
        state.disjunctiveFacetsRefinements?.[facet.facetName] || [];
      const disjunctiveSelector = createDisjunctiveSelector(
        searchAdapter,
        { name: facet.facetName },
        { hideSelected: true },
      );
      if (isUsingMultipleValueDisjunctiveFacet) {
        const hasData = disjunctiveSelector.some(
          (disjunctiveFacet) =>
            disjunctiveFacet.count > 0 &&
            facet.value.includes(disjunctiveFacet.label),
        );
        if (!hasData) return acc;
        const selected = facet.value.every((val) => usedFacets.includes(val));
        return [
          ...acc,
          {
            ...facet,
            selected,
          },
        ];
      }
      if (isUsingDisjunctiveSelector) {
        const facetData = disjunctiveSelector
          .filter((item) =>
            Object.keys(K_SHOWN_LABEL_MAPPING).includes(item.label),
          )
          .map((item) => ({
            ...facet,
            ...item,
            selected: usedFacets.includes(item.value),
          }));
        return [...acc, ...facetData];
      }
      const hasData =
        disjunctiveSelector.findIndex((item) => item.value === 'true') > -1;
      if (hasData) {
        return [
          ...acc,
          { ...facet, selected: usedFacets.includes(facet.value) },
        ];
      }
      return acc;
    }
    if (facet.facetType === K_NUMERICAL_FACET) {
      const facetData = createDisjunctiveSelector(
        searchAdapter,
        { name: facet.facetName },
        { hideSelected: true },
      );
      const { '>=': min, '<=': max } = facet.value;
      const hasData = Object.keys(facetData).some(
        (data) =>
          (!min || parseFloat(facetData[data]?.label, 10) >= min) &&
          (!max || parseFloat(facetData[data]?.value, 10) <= max) &&
          facetData[data]?.count > 0,
      );
      if (!hasData) {
        return acc;
      }

      const usedFacet = state?.numericRefinements?.[facet.facetName] ?? {};
      const usedMin = usedFacet?.['>=']?.[0];
      const usedMax = usedFacet?.['<=']?.[0];
      const selected =
        ((!min && !usedMin) || (usedMin && min === usedMin)) &&
        ((!max && !usedMax) || (usedMax && max === usedMax));
      return [...acc, { ...facet, selected }];
    }
    return acc;
  }, []);
};

@withSearchConsumer({
  filterData: getFilterData,
  nbHits: getNumberOfHits,
})
export default class QuickFilterGroup extends React.PureComponent {
  static propTypes = {
    history: PropTypes.shape().isRequired,
    filterData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ),
    nbHits: PropTypes.number,
    onDisjunctiveChange: PropTypes.func.isRequired,
    onNumericalChange: PropTypes.func.isRequired,
    onApplyFilter: PropTypes.func.isRequired,
    searchAdapter: PropTypes.shape({
      getState: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    filterData: [],
    nbHits: 0,
  };

  handleDisjunctiveChange = ({ facetName, label, value }) => {
    const { onDisjunctiveChange, filterData, onApplyFilter, history } =
      this.props;
    const selectedFilterData = filterData.find(
      (item) => item.facetName === facetName,
    );
    if (selectedFilterData?.isUsingMultipleValueDisjunctiveFacet) {
      if (selectedFilterData.selected) {
        onDisjunctiveChange(facetName, []);
      } else {
        onDisjunctiveChange(facetName, selectedFilterData.value);
      }
    } else {
      const selectedFilter = filterData
        .filter(
          (item) =>
            item.facetName === facetName &&
            (item.value === value ? !item.selected : item.selected),
        )
        .map((item) => item.value);
      onDisjunctiveChange(facetName, selectedFilter);
    }
    trackClickedApplyQuickFilterButton(
      get(history, 'location.pathname', ''),
      label,
    );
    onApplyFilter();
  };

  handleNumericalChange = ({ facetName, selected, label, value }) => {
    const { onNumericalChange, onApplyFilter, history } = this.props;
    if (selected) {
      onNumericalChange(facetName, {});
    } else {
      onNumericalChange(facetName, value);
    }
    trackClickedApplyQuickFilterButton(
      get(history, 'location.pathname', ''),
      label,
    );
    onApplyFilter();
  };

  renderFilterButton(filterItem) {
    const { label, selected, facetType, Icon = null } = filterItem;
    const handleFilterClick =
      facetType === K_DISJUNCTIVE_FACET
        ? this.handleDisjunctiveChange
        : this.handleNumericalChange;
    const buttonTextStyle = !selected
      ? styles.unselectedButtonText
      : styles.selectedButtonText;
    const buttonStyle = !selected
      ? styles.unselectedButton
      : styles.selectedButton;
    return (
      <View key={label} style={styles.buttonContainer}>
        <Button
          style={[styles.buttonStyle, buttonStyle]}
          small
          onPress={() => handleFilterClick(filterItem)}
        >
          {() => (
            <>
              <Text
                theme={selected ? 'tosca' : 'grey'}
                style={[styles.buttonTextStyle, buttonTextStyle]}
              >
                {K_SHOWN_LABEL_MAPPING[label]}
              </Text>
              {!!Icon && (
                <Icon
                  size={16}
                  style={[cStyles.mls, { marginVertical: 'auto' }]}
                />
              )}
            </>
          )}
        </Button>
      </View>
    );
  }

  renderQuickFilterButtons() {
    const { filterData } = this.props;
    return filterData.map((item) => this.renderFilterButton(item));
  }

  render() {
    const { nbHits, filterData } = this.props;
    if (isEmpty(filterData)) {
      return null;
    }
    const numProducts =
      nbHits >= K_MAX_NB_HITS
        ? `${Math.floor(nbHits / K_MAX_NB_HITS)}rb+`
        : nbHits;
    return (
      <>
        <ScrollView
          horizontal
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <Text theme="body3-black65" style={styles.numberOfProductsContainer}>
            {`${numProducts} Produk`}
          </Text>
          {this.renderQuickFilterButtons()}
        </ScrollView>
        <HorizontalDivider color={Colors.C_BLACK_15} />
      </>
    );
  }
}
