import keyMirror from 'keymirror';
import { normalize } from 'normalizr';

export const AT = keyMirror({
  SET_SEARCH_STATE: null,
  REMOVE_SEARCH_STATE: null,
  CLEAR_SEARCH_STATE: null,
});

export const initialState = {};

export default function searchStateReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case AT.SET_SEARCH_STATE:
      return {
        ...state,
        [payload.key]: { state: payload.state, result: payload.result },
      };
    case AT.REMOVE_SEARCH_STATE: {
      const { [payload.key]: value, ...newState } = state;
      return newState;
    }
    case AT.CLEAR_SEARCH_STATE:
      return initialState;
    default:
      return state;
  }
}

export function setSearchState(key, state, result = [], schema) {
  const action = {
    type: AT.SET_SEARCH_STATE,
    payload: { key, state, result },
  };

  /**
   * If schema is supplied, result will contain entities
   * so that it can be passed to entities reducer
   */
  if (schema) {
    action.result = normalize(result.hits, schema);
  }

  return action;
}

export function removeSearchState(key) {
  return {
    type: AT.REMOVE_SEARCH_STATE,
    payload: { key },
  };
}

export function clearSearchState() {
  return { type: AT.CLEAR_SEARCH_STATE };
}
