import React, { memo, useContext, useCallback } from 'react';
import { View } from 'react-native';
import { Link as RouterLink } from 'react-router-dom';
import { RouteContext } from 'app-libs/contexts/RouteContext';

interface LinkPropTypes {
  to?:
    | string
    | {
        pathname: string;
        search: string;
        hash: string;
      };
  isUsingAnchor?: boolean;
  shouldOpenNewTab?: boolean;
  showUnderlineOnHover?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Link: React.FC<LinkPropTypes> = memo(
  ({
    to = undefined,
    isUsingAnchor = false,
    shouldOpenNewTab = false,
    showUnderlineOnHover = true,
    disabled = false,
    onClick = () => null,
    ...rest
  }) => {
    if (!to || disabled) return <View {...rest} />;

    /** open new tab */
    const target = shouldOpenNewTab ? '_blank' : undefined;
    const className = showUnderlineOnHover ? 'link text-normal' : 'link-reset';

    const { setIsChangingRoute } = useContext(RouteContext);

    const handleClick = useCallback(() => {
      setIsChangingRoute(true);
      onClick();
    }, [onClick, setIsChangingRoute]);

    if (isUsingAnchor) {
      let href = '';
      if (typeof to === 'string') {
        href = to;
      } else if (typeof to === 'object') {
        if (to.pathname) href += to.pathname;
        if (to.search) href += `?${to.search}`;
        if (to.hash) href += `#${to.hash}`;
      }
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return (
        <a
          className={className}
          href={href}
          {...rest}
          target={target}
          onClick={handleClick}
        />
      );
    }

    return (
      <RouterLink
        className={className}
        to={to}
        {...rest}
        target={target}
        onClick={onClick}
      />
    );
  },
);

Link.defaultProps = {
  to: undefined,
  isUsingAnchor: false,
  shouldOpenNewTab: false,
  showUnderlineOnHover: true,
  disabled: false,
};

export default Link;
