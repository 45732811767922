import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';
import COLORS from '@dkrm/general-libs/theme/colors';

export default StyleSheet.create({
  container: {
    marginRight: 8,
  },
  popupContainer: {
    ...cStyles.flex1,
    ...cStyles.height100,
    backgroundColor: COLORS.WHITE,
  },
  popupHeading: {
    ...cStyles.flexDirRow,
    ...cStyles.bwbxs,
    ...cStyles.bcm,
    alignItems: 'center',
  },
  headingContainer: {
    ...cStyles.flex1,
    ...cStyles.flexMiddle,
  },
  applyButtonContainer: {
    ...cStyles.pvl,
    ...cStyles.phxl,
    ...cStyles.bwtxs,
    ...cStyles.bcm,
  },
  popupContent: {
    ...cStyles.flex1,
  },
  textHeading: {
    ...cStyles.subtitlem,
    ...cStyles.pvl,
  },
  textClear: {
    ...cStyles.fs5,
    ...cStyles.pal,
    ...cStyles.textBabu,
  },
  closeButtonContainer: {
    ...cStyles.pal,
  },
});
