import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ActivityIndicator, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ModalEnhanced } from '@dkrm/ui-kit-basic/v2';

const LoadingModal = memo(() => {
  return (
    <ModalEnhanced isVisible style={s.backdrop}>
      <View style={s.container}>
        <ActivityIndicator size={40} />
        <View style={s.textContainer}>
          <Text theme="h4-black90">Mohon Tunggu</Text>
          <Text theme="body3-black70" style={s.description}>
            Loading...
          </Text>
        </View>
      </View>
    </ModalEnhanced>
  );
});

export default LoadingModal;

const s = StyleSheet.create({
  backdrop: {
    ...cStyles.flexMiddle,
    margin: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    ...cStyles.bgw,
    ...cStyles.brs,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
    ...cStyles.phxxl,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    paddingVertical: 20,
  },
  textContainer: cStyles.mlxl,
  description: cStyles.mtm,
});
