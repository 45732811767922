import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Clipboard, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Copy } from '@dkrm/icons';
import cStyles from '@dkrm/general-libs/theme/styles';

import { showSnackBar } from 'app-libs/redux_modules/flow_modules/notification';
import { K_POSITION_TOP_CENTER } from 'app-libs/components/Notification/BasePopup/constants';

interface CoverageIdSectionProps {
  insurancePolicyNumber: string | null;
}

const CoverageIdSection: React.FC<CoverageIdSectionProps> = ({
  insurancePolicyNumber,
}) => {
  const dispatch = useDispatch();

  const shouldShowCopyCoverageIdButton = !!insurancePolicyNumber;
  const coverageId = insurancePolicyNumber ?? '-';

  const actionCopyCoverageId = useCallback(() => {
    Clipboard.setString(coverageId);
    dispatch(
      showSnackBar({
        message: 'Coverage ID berhasil disalin!',
        position: K_POSITION_TOP_CENTER,
      }),
    );
  }, [coverageId, dispatch]);

  return (
    <View style={s.container}>
      <Text theme="body3-black60">Coverage ID</Text>
      <View style={s.copyButtonContainer}>
        <Text theme="h5-tosca" style={cStyles.mrs}>
          {coverageId}
        </Text>
        {shouldShowCopyCoverageIdButton && (
          <ButtonWrapper onPress={actionCopyCoverageId}>
            {() => <Copy theme="black" />}
          </ButtonWrapper>
        )}
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pal,
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.bgw,
    ...cStyles.flexDirCol,
    justifyContent: 'center',
  },
  copyButtonContainer: {
    ...cStyles.mts,
    ...cStyles.flexDirRow,
  },
});

export default CoverageIdSection;
