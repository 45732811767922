import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  ActivityIndicator,
  Colors,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';

import { getOrderLineInsuranceInfo } from 'app-libs/etc/addonInsuranceHelper';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { isAuthLoaded as _isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';

import { useQueryLoadOrderInsuranceInfo } from 'entities/order/utils';

import CoverageIdSection from './CoverageIdSection';
import InsuranceActionButtons from './InsuranceActionButtons';
import InsuranceHeader from './InsuranceHeader';

const OrderProtectionLineDetailPage = memo(() => {
  const dispatch = useDispatch();

  const params = useParams<{ orderKey: string; lineId: string }>();
  const { orderKey, lineId: insuranceLineId } = params;

  const { data, isLoading } = useQueryLoadOrderInsuranceInfo(orderKey);

  const isAuthLoaded = useSelector(_isAuthLoaded);

  const insuranceLine = useMemo(() => {
    return (
      data?.insuranceOrderLines.find(
        (line) => line.id === parseInt(insuranceLineId, 10),
      ) || null
    );
  }, [data, insuranceLineId]);

  useEffect(() => {
    if (!isAuthLoaded) {
      dispatch(loadAuth());
    }
  }, [dispatch, isAuthLoaded]);

  const {
    insurancePolicyNumber,
    insuranceStatusLabel,
    insuranceStatusLabelTheme,
    insuranceCoverageEndDate,
    insuranceSrpUrl,
    canSeeInsurancePolicy,
    canSeeSrpDocument,
    canClaim,
  } = getOrderLineInsuranceInfo(insuranceLine);

  if (!data || isLoading || !insuranceLine) {
    return <ActivityIndicator />;
  }

  return (
    <ScrollView style={s.container}>
      <InsuranceHeader
        order={data}
        insuredOrderLine={insuranceLine.insuredOrderLine}
      />
      <View style={s.bodyContainer}>
        <CoverageIdSection insurancePolicyNumber={insurancePolicyNumber} />
        <View style={s.policyInfoAndButtonContainer}>
          <View style={s.policyInfoContainer}>
            <View style={s.policyInfo}>
              <Text theme="body3-black80">Tipe Proteksi</Text>
              <Text theme="body3">Proteksi Furnitur</Text>
            </View>
            <View style={s.policyInfo}>
              <Text theme="body3-black80">Status Polis</Text>
              <Text theme={insuranceStatusLabelTheme}>
                {insuranceStatusLabel}
              </Text>
            </View>
            <View style={s.policyInfo}>
              <Text theme="body3-black80">Masa Aktif</Text>
              <Text theme="body3">{`12 Bulan (sampai ${insuranceCoverageEndDate})`}</Text>
            </View>
          </View>
          <InsuranceActionButtons
            insuranceSrpUrl={insuranceSrpUrl}
            canSeeInsurancePolicy={canSeeInsurancePolicy}
            canSeeSrpDocument={canSeeSrpDocument}
            canClaim={canClaim}
          />
        </View>
      </View>
    </ScrollView>
  );
});

const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.C_BLACK_5,
  },
  bodyContainer: {
    ...cStyles.bgw,
    ...cStyles.phxxl,
    ...cStyles.flexDirCol,
    paddingVertical: 20,
  },
  policyInfoAndButtonContainer: {
    ...cStyles.flexDirCol,
    justifyContent: 'space-between',
  },
  policyInfoContainer: {
    ...cStyles.flexDirCol,
    ...cStyles.mvl,
  },
  policyInfo: {
    ...cStyles.mvm,
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
    justifyContent: 'space-between',
  },
});

export default OrderProtectionLineDetailPage;
