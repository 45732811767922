export const K_SHORTHAND_YEAR = 'y';
export const K_SHORTHAND_MONTH = 'M';
export const K_SHORTHAND_WEEK = 'w';
export const K_SHORTHAND_DAY = 'd';
export const K_SHORTHAND_HOUR = 'h';
export const K_SHORTHAND_MINUTE = 'm';
export const K_SHORTHAND_SECOND = 's';
export const K_SHORTHAND_MILLISECOND = 'ms';

export const K_MILLISECONDS_IN_A_DAY = 1 * 24 * 60 * 60 * 1000;
