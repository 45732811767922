// voucher
export const K_VOUCHER_DEFAULT_TNC_1 =
  'Voucher ini tidak berlaku untuk metode pembayaran COD / Bayar di tempat.';
export const K_VOUCHER_DEFAULT_TNC_2 =
  'Voucher ini hanya berlaku untuk pembelian di website Dekoruma.';
export const K_VOUCHER_DEFAULT_TNC_3 =
  'Produk yang sudah dibeli dengan voucher ini tidak dapat ditukar kembali.';
export const K_VOUCHER_DEFAULT_TNC_4 =
  'Dekoruma berhak membatalkan transaksi jika ditemukan kecurangan terhadap Syarat & Ketentuan Berlaku.';
export const K_VOUCHER_DEFAULT_TNC_5 =
  'Dekoruma berhak mengubah Syarat & Ketentuan Berlaku, termasuk menghentikan promosi tanpa pemberitahuan terlebih dahulu.';

export const K_VOUCHER_DEFAULT_TNCS = [
  K_VOUCHER_DEFAULT_TNC_1,
  K_VOUCHER_DEFAULT_TNC_2,
  K_VOUCHER_DEFAULT_TNC_3,
  K_VOUCHER_DEFAULT_TNC_4,
  K_VOUCHER_DEFAULT_TNC_5,
];

export const K_FALLBACK_VOUCHER_IMG_URL =
  'https://s3-ap-southeast-1.amazonaws.com/f1-static/promotion/THRLEBARANDKRM';

// advertisements
export const K_ADS_DEFAULT_TNC_1 =
  'Promo ini tidak berlaku untuk metode pembayaran COD / Bayar di tempat.';
export const K_ADS_DEFAULT_TNC_2 =
  'Promo ini hanya berlaku untuk pembelian di website Dekoruma.';
export const K_ADS_DEFAULT_TNC_3 =
  'Produk yang sudah dibeli dengan promo ini tidak dapat ditukar kembali.';
export const K_ADS_DEFAULT_TNC_4 =
  'Dekoruma berhak membatalkan transaksi jika ditemukan kecurangan terhadap Syarat & Ketentuan Berlaku.';
export const K_ADS_DEFAULT_TNC_5 =
  'Dekoruma berhak mengubah Syarat & Ketentuan Berlaku, termasuk menghentikan promosi tanpa pemberitahuan terlebih dahulu.';

export const K_ADS_DEFAULT_TNCS = [
  K_ADS_DEFAULT_TNC_1,
  K_ADS_DEFAULT_TNC_2,
  K_ADS_DEFAULT_TNC_3,
  K_ADS_DEFAULT_TNC_4,
  K_ADS_DEFAULT_TNC_5,
];

export const K_PROMO_LABEL_ALL = 'Semua';
export const K_PROMO_LABEL_CASHBACK = 'Cashback';
export const K_PROMO_LABEL_MY_VOUCHERS = 'Voucher Saya';
export const K_PROMO_LABEL_PAYMENT = 'Pembayaran';
export const K_PROMO_LABEL_SHIPPING = 'Pengiriman';
export const K_PROMO_LABEL_DISCOUNT = 'Diskon';

export const K_PROMO_TYPE_USER_VOUCHERS = 'User Voucher';
export const K_PROMO_TYPE_PAYMENT = 'Payment';
export const K_PROMO_TYPE_SHIPPING = 'Shipping';
export const K_PROMO_TYPE_DISCOUNT = 'Discount';
export const K_PROMO_TYPE_VOUCHER = 'Voucher';
export const K_PROMO_TYPE_CASHBACK = 'Cashback';

export const K_VOUCHER_TYPE_PRODUCT = 'product';
export const K_VOUCHER_TYPE_SHIPPING = 'shipping';

export const K_OFFER_TYPE_SITE = 'Site';
export const K_OFFER_TYPE_VOUCHER = 'Voucher';
export const K_OFFER_TYPE_CREDIT_CARD = 'Credit Card';

export const K_CONDITION_TYPE_VALUE = 'Value';
export const K_CONDITION_TYPE_QUANTITY = 'Quantity';

export const K_VOUCHER_DISCOUNT_TYPE_PERCENTAGE = 'Percentage';
export const K_DISCOUNT_TYPE_SHIPPING_GMV = 'Shipping Percentage of GMV';
export const K_VOUCHER_DISCOUNT_TYPE_SHIPPING_PERCENTAGE =
  'Shipping percentage';
export const K_VOUCHER_DISCOUNT_TYPE_CASHBACK = 'Cashback';

export const K_DISCOUNT_TYPES_PERCENTAGE = [
  K_VOUCHER_DISCOUNT_TYPE_PERCENTAGE,
  K_VOUCHER_DISCOUNT_TYPE_SHIPPING_PERCENTAGE,
  K_DISCOUNT_TYPE_SHIPPING_GMV,
];

export const K_PROMO_LABEL_BY_TYPE = {
  [K_PROMO_TYPE_CASHBACK]: K_PROMO_LABEL_CASHBACK,
  [K_PROMO_TYPE_DISCOUNT]: K_PROMO_LABEL_DISCOUNT,
  [K_PROMO_TYPE_PAYMENT]: K_PROMO_LABEL_PAYMENT,
  [K_PROMO_TYPE_SHIPPING]: K_PROMO_LABEL_SHIPPING,
  [K_PROMO_TYPE_USER_VOUCHERS]: K_PROMO_LABEL_MY_VOUCHERS,
};

export const K_PROMO_LABELS = [
  K_PROMO_LABEL_ALL,
  K_PROMO_LABEL_MY_VOUCHERS,
  K_PROMO_LABEL_PAYMENT,
  K_PROMO_LABEL_SHIPPING,
  K_PROMO_LABEL_DISCOUNT,
  K_PROMO_LABEL_CASHBACK,
];

export const K_PROMO_LABEL_DISCOUNT_TYPES = [
  'Percentage',
  'Fixed price',
  'Absolute',
  'Cashback',
];

export const K_PROMO_LABEL_SHIPPING_TYPES = [
  'Shipping absolute',
  'Shipping fixed price',
  'Shipping percentage',
  K_DISCOUNT_TYPE_SHIPPING_GMV,
];

export const K_VOUCHER_ISSUER_DEKORUMA = 'Dekoruma';
export const K_VOUCHER_ISSUER_MERCHANT = 'Merchant';

export const K_VOUCHER_AVAILABLE_IN_APP_ONLY_ERROR_MESSAGE =
  'Voucher hanya berlaku untuk transaksi di aplikasi, ';
export const K_VOUCHER_AVAILABLE_IN_DEC_ONLY_ERROR_MESSAGE =
  'Voucher hanya dapat digunakan di showroom Dekoruma Experience Center';

export const K_COPY_VOUCHER_CODE_SUCCESS_MESSAGE =
  'Kode voucher berhasil disalin';
export const K_COPY_VOUCHER_BUTTON_LABEL = 'Salin';
