import config from 'config';

export const K_A_TYPE_OPTION = 'option';

export const K_A_WEIGHT = 'a_weight';
export const K_A_DIM_LENGTH = 'a_dim_length';
export const K_A_DIM_WIDTH = 'a_dim_width';
export const K_A_DIM_HEIGHT = 'a_dim_height';
export const K_A_STD_COLOR_CODE = 'a_color';
export const K_A_ISI_PAKET_CODE = 'a_isi_paket';
export const K_A_SHOP_PRICE_CODE = 'a_shop_price';
export const K_A_VIDEO = 'a_video';
export const K_A_PRODUCT_ADDITIONAL_VIDEO = 'a_product_additional_video';
export const K_A_PRICE_TRADE_IN = 'a_price_trade_in';
export const K_A_PRICE_COMPETITOR = 'a_price_competitor';
export const K_A_VIEW_LAYOUT = 'a_view_layout';
export const K_A_FLATPACK = 'a_flatpack';
export const K_A_MANUAL = 'a_manual';
export const K_A_WARRANTY_LONG = 'a_jangka_waktu_garansi';
export const K_A_WARRANTY_DESC = 'a_ketentuan_garansi';

export const K_PRODUCT_DEFAULT_L_W_H = '10';

export const K_A_MATTRESS_CAT = 'a_kategori_matras';
export const K_A_MATTRESS_TYPE = 'a_tipe_matras';
export const K_A_MATTRESS_FIRMNESS = 'a_detail_matress_firmness';

export const K_A_BOHLAM = 'a_bohlam';
export const K_A_TIPE_FITTING = 'a_tipe_fitting';
export const K_A_MATERIAL = 'a_material';

export const K_A_OPTION_PREFIX = 'a_o_';
export const K_A_FURNITURE_PREFIX = 'a_f_';

export const K_A_STD_LENGTH_CODE = 'a_size_length';
export const K_A_STD_WIDTH_CODE = 'a_size_width';
export const K_A_SIZE_THICKNESS = 'a_size_thickness';
export const K_A_SIZE_HEIGHT = 'a_size_height';
export const K_A_SIZE_TINGGI = 'a_size_tinggi';
export const K_A_SIZE_LENGTH = 'a_size_panjang';
export const K_A_SIZE_WIDTH = 'a_size_lebar';
export const K_A_SIZE_TYPE = 'a_size_type';
export const K_A_UNIT = 'a_unit';
export const K_A_COLOR_TONE = 'a_color_tone';
export const K_A_REPEAT = 'a_repeat';
export const K_A_MADE_IN = 'a_made_in';
export const K_A_COVERAGE = 'a_coverage';
export const K_A_VOLUME = 'a_volume';
export const K_A_CABINET_SIZE = 'a_cabinet_size';
export const K_A_PATTERN = 'a_pattern';
export const K_A_ROOM = 'a_room';
export const K_A_STYLE = 'a_style';
export const K_A_SUB_STYLE = 'a_sub_style';
export const K_A_QUALITY = 'a_quality';
export const K_A_CUSTOM_COLOR = 'a_custom_color';
export const K_A_FIRMNESS_MATTRESS = 'a_firmness_matras';
export const K_A_CAPACITY = 'a_kapasitas';
export const K_A_POWER_CONSUMPTION = 'a_konsumsi_daya';
export const K_A_MICROWAVE_SAFE = 'a_microwave_safe';
export const K_A_SERIES = 'a_series';
export const K_A_SPECIAL_FEATURE = 'a_special_feature';

export const K_A_O_PILIH_BARANG_LAIN = 'a_o_pilih_barang_lain';

export const K_A_CUSTOMER_TYPE = 'a_tipe_customer';
export const K_A_BUSINESS_TYPE = 'a_tipe_business';

export const K_A_F_PRODUCT_CONDITION = 'a_f_kondisi_produk';
export const K_A_MANUAL_INCLUDED = 'a_manual_disertakan';
export const K_A_FREE_INSTALLATION = 'a_gratis_perakitan';

export const K_SIZE_LABEL = 'sizeLabel';

export const K_C_CODE_DIFFERENTIATOR = 'c_code_differentiator';

export const K_CONFIG_ATTRIBUTE = 'c_';

export const K_COLOR_SCORE = 0.11;
export const K_MATERIAL_SCORE = 0.11;
export const K_PATTERN_SCORE = 0.11;
export const K_FIRMNESS_SCORE = 0.11;
export const K_PRICE_SCORE = 0.11;
export const K_SIZE_SCORE = 0.11;
export const K_ROOM_SCORE = 0.11;
export const K_STYLE_SCORE = 0.11;
export const K_QUALITY_SCORE = 0.11;

export const K_FBT_STYLE_SCORE = 0.3;
export const K_FBT_SUBSTYLE_SCORE = 0.4;
export const K_FBT_BRAND_SCORE = 0.1;
export const K_FBT_SIZE_LABEL_SCORE = 0.2;
export const K_FBT_PRICE_SCORE = 0.1;
export const K_FBT_CATEGORY_SCORE = 0.15;
export const K_FBT_OFFER_SCORE = 0.3;
export const K_FBT_PAIRED_CATEGORY_SCORE = 0.9;

export const K_A_STD_PACKAGING_CODES = [
  K_A_DIM_LENGTH,
  K_A_DIM_WIDTH,
  K_A_DIM_HEIGHT,
];
export const K_A_STD_SIZE_CODES = [
  'a_size_panjang',
  'a_size_lebar',
  'a_size_tinggi',
];
export const K_A_COMMODITY_SPECS = [
  K_A_STD_LENGTH_CODE,
  K_A_STD_WIDTH_CODE,
  K_A_SIZE_HEIGHT,
  K_A_SIZE_TYPE,
  K_A_SIZE_THICKNESS,
  K_A_VOLUME,
  K_A_COLOR_TONE,
  K_A_REPEAT,
  K_A_MADE_IN,
  K_A_COVERAGE,
  K_A_CABINET_SIZE,
  K_A_UNIT,
];
export const K_A_SPECS = [...K_A_COMMODITY_SPECS, K_A_WEIGHT];

export const K_A_GENERALS = [
  K_A_STD_COLOR_CODE,
  K_A_STYLE,
  K_A_CUSTOM_COLOR,
  K_A_FIRMNESS_MATTRESS,
  K_A_ISI_PAKET_CODE,
  K_A_WARRANTY_LONG,
  K_A_CAPACITY,
  K_A_MATTRESS_CAT,
  K_A_WARRANTY_DESC,
  K_A_POWER_CONSUMPTION,
  K_A_MICROWAVE_SAFE,
  K_A_PATTERN,
  K_A_QUALITY,
  K_A_SERIES,
  K_A_SPECIAL_FEATURE,
];

export const K_A_FILTERING_PRODUCT_TYPE = 'filteringAttributes.aTipeProduk';
const K_A_FILTERING_SIZE_TYPE = 'filteringAttributes.aSizeType';
const K_A_FILTERING_DINING_TABLE_CAPACITY =
  'filteringAttributes.aFKapasitasMejaMakan';
const K_A_FILTERING_CHAIR_MATERIAL =
  'filteringAttributes.aFKursiMaterialDudukan';
const K_A_FILTERING_WARDROBE_MIRROR = 'filteringAttributes.aFCerminLemari';
const K_A_FILTERING_PATTERN = 'filteringAttributes.aPattern';
const K_A_FILTERING_TABLE_TOP_TYPE = 'filteringAttributes.aFBentukTableTop';
const K_A_FILTERING_SOFA_MATERIAL_COVER =
  'filteringAttributes.aFSofaMaterialCover';
const K_A_FILTERING_FITTING_TYPE = 'filteringAttributes.aTipeFitting';

export const K_A_FILTERING_EXTRA = {
  'Kursi Kantor': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Kursi Kantor',
    },
  ],
  Sprei: [
    {
      attribute: K_A_FILTERING_SIZE_TYPE,
      label: 'Ukuran Sprei',
    },
  ],
  Kompor: [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  'Meja Makan': [
    {
      attribute: K_A_FILTERING_DINING_TABLE_CAPACITY,
      label: 'Kapasitas Meja Makan',
    },
  ],
  'Kursi Makan': [
    {
      attribute: K_A_FILTERING_CHAIR_MATERIAL,
      label: 'Material Dudukan Khusus Kursi',
    },
  ],
  'Kursi Bar': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  'Kursi Panjang': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  'Lemari Pakaian 2 Pintu': [
    {
      attribute: K_A_FILTERING_WARDROBE_MIRROR,
      label: 'Ada Cermin atau Tidak',
    },
  ],
  'Lemari Pakaian 3 Pintu': [
    {
      attribute: K_A_FILTERING_WARDROBE_MIRROR,
      label: 'Ada Cermin atau Tidak',
    },
  ],
  'Kursi Ottoman & Stool': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  Dispenser: [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  Mixer: [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  'Bantal Dekorasi': [
    {
      attribute: K_A_FILTERING_PATTERN,
      label: 'Motif',
    },
  ],
  'Sarung Bantal': [
    {
      attribute: K_A_FILTERING_PATTERN,
      label: 'Motif',
    },
  ],
  Poster: [
    {
      attribute: K_A_FILTERING_PATTERN,
      label: 'Motif',
    },
  ],
  'Bingkai Foto': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  Nakas: [
    {
      attribute: K_A_FILTERING_TABLE_TOP_TYPE,
      label: 'Bentuk Top Table',
    },
  ],
  'Bean Bag & Kursi Lesehan': [
    {
      attribute: K_A_FILTERING_PATTERN,
      label: 'Motif Bean Bag',
    },
  ],
  'Rak Sepatu': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  'Sofa 2 Seater': [
    {
      attribute: K_A_FILTERING_SOFA_MATERIAL_COVER,
      label: 'Material cover',
    },
  ],
  'Sofa Bed': [
    {
      attribute: K_A_FILTERING_SOFA_MATERIAL_COVER,
      label: 'Material cover',
    },
  ],
  'Sofa 3 Seater': [
    {
      attribute: K_A_FILTERING_SOFA_MATERIAL_COVER,
      label: 'Material cover',
    },
  ],
  'Sofa 1 Seater & Armchair': [
    {
      attribute: K_A_FILTERING_SOFA_MATERIAL_COVER,
      label: 'Material cover',
    },
  ],
  'Sofa L': [
    {
      attribute: K_A_FILTERING_PRODUCT_TYPE,
      label: 'Tipe Produk',
    },
  ],
  'Bohlam & Lampu LED': [
    {
      attribute: K_A_FILTERING_FITTING_TYPE,
      label: 'Tipe Fitting',
    },
  ],
};

export const K_A_NON_PRODUCT_TYPE = [K_A_BUSINESS_TYPE, K_A_CUSTOMER_TYPE];

export const K_UNIT_BY_ATTRIBUTE = {
  [K_A_WEIGHT]: 'kg',
  [K_A_STD_LENGTH_CODE]: 'mm',
  [K_A_STD_WIDTH_CODE]: 'mm',
  [K_A_SIZE_HEIGHT]: 'mm',
  [K_A_SIZE_THICKNESS]: 'mm',
  [K_A_REPEAT]: 'mm',
  [K_A_COVERAGE]: 'm2',
  [K_A_CABINET_SIZE]: 'mm',
};

export const K_P_STRUCTURE_CHILD = 'child';

export const K_PRODUCT_CHILDREN_KEYS = [
  'siblingIds',
  'recommendedSeriesIds',
  'freqBoughtTogetherIds',
  'userWhoViewedThisAlsoViewedItemsIds',
  'upsellItemsIds',
  'productRecommendations.similarItemsIds',
  'productRecommendations.exclusiveItemsIds',
  'productRecommendations.sponsoredItemsIds',
];

export const K_V_VIEW_PRODUCT_LAYOUT_FURNITUR = 'View Layout Furnitur';

export const K_IMAGE_SMALL_THUMB_CROP_PARAMS =
  'auto=webp&trim-color=ffffff&width=96&height=96&fit=bounds&bg-color=ffffff';
export const K_IMAGE_THUMB_CROP_PARAMS =
  'auto=webp&trim-color=ffffff&width=280&height=280&fit=bounds&bg-color=ffffff';
export const K_IMAGE_NORMAL_CROP_PARAMS =
  'width=640&height=640&fit=crop&auto=webp';
export const K_IMAGE_NORMAL_SITEMAP_CROP_PARAMS =
  'auto=webp&trim-color=ffffff&width=640&height=640&fit=bounds&bg-color=ffffff';
export const K_IMAGE_LANDSCAPE_CROP_PARAMS =
  '?width=960&height=480&fit=bounds&auto=webp';
export const K_IMAGE_LANDSCAPE_THUMB_CROP_PARAMS =
  'width=280&height=140&fit=bounds&auto=webp';
export const K_MOODBOARD_NORMAL_CROP_PARAMS = 'width=640&auto=webp';
export const K_MOODBOARD_LANDSCAPE_CROP_PARAMS = 'width=960&auto=webp';

export const K_WISHLIST_BUSINESS_UNIT_HUB = 'HUB';
export const K_WISHLIST_BUSINESS_UNIT_MARKETPLACE = 'MARKETPLACE';

export const K_SEARCH_SUGGESTION_LIST_PRODUCT_KEY = 'query';
export const K_SEARCH_SUGGESTION_LIST_POST_KEY = 'post_title';

export const K_FEATURE_LIMITED_STOCK = 'Cepat Habis';
export const K_FEATURE_VALUE_PACK = 'Value Pack';

export const K_MERCHANT_PARTNER_FAKE = 'Rumah Rumahan';
export const K_WARRANTY_TEXT = ' bulan garansi penjual';

export const K_HIDDEN_ATTRIBUTE_CODES = [
  'a_bahan_matras',
  'a_lebar_matras',
  'a_shop_price',
  K_A_PRICE_COMPETITOR,
  K_A_PRICE_TRADE_IN,
  K_A_VIEW_LAYOUT,
  K_A_WARRANTY_DESC,
  K_A_WARRANTY_LONG,
  K_A_MATTRESS_CAT,
  K_A_MATTRESS_TYPE,
  K_A_ROOM,
  K_A_VIDEO,
  ...K_A_STD_PACKAGING_CODES,
  ...K_A_STD_SIZE_CODES,
  ...K_A_NON_PRODUCT_TYPE,
];

export const K_PRIVATE_LABEL_HEIM_STUDIO = 'Heim Studio';
export const K_PRIVATE_LABEL_NARA = 'Nara';
export const K_PRIVATE_LABEL_NARA_FULL_NAME =
  'Nara - PT. Dekoruma Niaga Sejahtera';
export const K_PRIVATE_LABEL_KAMIMI = 'Kamimi';
export const K_PRIVATE_LABEL_SLEEP_CARE = 'Sleep Care';
export const K_PRIVATE_LABEL_VALLERY_QUINCY = 'Vallery Quincy';
export const K_PRIVATE_LABEL_ELEGANCE = 'Elegance';
export const K_PRIVATE_LABEL_SISTA = 'Sista';
export const K_PRIVATE_LABEL_DANARI = 'Danari';
export const K_PRIVATE_LABEL_LINORI = 'Linori';
export const K_PRIVATE_LABEL_TENZO_LIVING = 'Tenzo Living';

export const K_PRIVATE_LABEL_BRANDS = [
  K_PRIVATE_LABEL_HEIM_STUDIO,
  K_PRIVATE_LABEL_SLEEP_CARE,
  K_PRIVATE_LABEL_DANARI,
  K_PRIVATE_LABEL_LINORI,
  K_PRIVATE_LABEL_TENZO_LIVING,
];

export const K_PARTNER_NAME_EXCLUSIVE_TRADING =
  'Exclusive Trading - PT. Dekoruma Niaga Sejahtera';

export const K_PARTNER_NAME_TENZO_LIVING =
  'Tenzo Living - PT. Dekoruma Niaga Sejahtera';

export const K_PARTNER_NAME_ERGOMOTION =
  'ERGOMOTION - PT. Dekoruma Niaga Sejahtera';

export const K_PRIVATE_LABEL_PARTNERS = [
  K_PRIVATE_LABEL_NARA_FULL_NAME,
  K_PARTNER_NAME_EXCLUSIVE_TRADING,
  K_PARTNER_NAME_TENZO_LIVING,
  K_PARTNER_NAME_ERGOMOTION,
  'Hub-Surabaya',
  'Nara-Hub-Medan',
  'Hub-Makasar',
];

export const K_B2B_TAX = 0.11;

export const K_PRODUCT_BRAND_LIVIEN = 'Livien';
export const K_PRODUCT_BRAND_OLIVE_HOUSE = 'Olive House';
export const K_PRODUCT_BRAND_VIKU = 'Viku Furniture';

export const K_PRIVATE_LABELS = [
  K_PRIVATE_LABEL_HEIM_STUDIO,
  K_PRIVATE_LABEL_NARA,
  K_PRIVATE_LABEL_KAMIMI,
  K_PRIVATE_LABEL_SLEEP_CARE,
  K_PRIVATE_LABEL_VALLERY_QUINCY,
  K_PRIVATE_LABEL_ELEGANCE,
  K_PRIVATE_LABEL_SISTA,
  K_PRIVATE_LABEL_LINORI,
  K_PRIVATE_LABEL_TENZO_LIVING,
];
export const K_FREE_SHIPPING_BRANDS_IN_JAVA = [...K_PRIVATE_LABELS];
export const K_EXCLUSIVE_BRANDS = [
  K_PRIVATE_LABEL_HEIM_STUDIO,
  K_PRIVATE_LABEL_DANARI,
  K_PRIVATE_LABEL_LINORI,
  K_PRIVATE_LABEL_SLEEP_CARE,
  K_PRIVATE_LABEL_TENZO_LIVING,
];

/*
  @note(dika) 5 Sept 2023: Business purposes to show Our WH as Jakarta, Medan, Makassar, etc.
  otherwise Cikarang WH will be shown as Kab. Bekasi not Jakarta
*/
export const K_EXCLUSIVE_BRAND_WAREHOUSE_LOCATION = 'Jakarta, Medan, Makassar';
export const K_TENZO_LIVING_WAREHOUSE_LOCATION = 'Jakarta';

export const K_MAX_SPONSORED_PRODUCT_LENGTH = 40;

/** WhatsApp Chat Marketplace */
export const K_DEKORUMA_OFFICIAL_WHATSAPP_URL = 'https://wa.me/6281905946583/';
export const genWhatsAppTemplateMessage = (productLink) =>
  `Halo Dekoruma, saya ingin bertanya terkait produk ${productLink}`;
export const K_CLICK_TO_CHAT_IMAGE = `${config.API_URL_MEDIA_CDN}/assets/tanya-kania.png?auto=webp`;

/** Product options Related */
export const K_PRODUCT_OPTION_COLOR = 'Opsi Warna';
export const K_PRODUCT_OPTIONS_TO_USE_IMAGE = [K_PRODUCT_OPTION_COLOR];
export const K_PRODUCT_OPTION_SIZE = 'Ukuran';

/** Product offer Related */
export const K_OFFER_OTHER_SALES = 'Sale Lainnya';

/** Product catalogue grouping */
export const K_MARKETPLACE_PRODUCT = 'MARKETPLACE';
export const K_HUB_PRODUCT = 'HUB';
export const K_DNB_PRODUCT = 'DNB';
export const K_HUB_INTERNAL_PRODUCT = 'HUBINTERNAL';

/** Loki Native Related */
export const K_PRODUCT_TAB_PAGE_INDEX = {
  PRODUCT_OVERVIEW: 0,
  PRODUCT_DETAIL: 1,
  BRAND_REVIEW: 2,
  PRODUCT_RELATED: 3,
};

/** Ratings Related */
export const K_MIN_REVIEW_SCORE = 2;
export const K_MAX_REVIEW_SCORE = 5;
export const DEFAULT_MAX_VALUES_PER_FACET = 100;

/** Supply Warehouse Product Related */
export const K_PLACEHOLDER_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/icon/empty-state/image-128.png?auto=webp`;

export const K_DEKORUMA_SERVICE_CENTRE = 'Dekoruma Service Centre';
export const K_DEKORUMA_SERVICE_CENTRE_PARTNER_ID = 338;

export const K_MERCHANT_SERVICE_CENTRE = 'Merchant Service Centre';
export const K_MERCHANT_SERVICE_CENTRE_PARTNER_ID = 6530;

export const K_DEKORUMA_DUMMY_SERVICE_MERCHANT = 'Dummy Service Merchant';
export const K_DEKORUMA_DUMMY_INSTALLATION_SERVICE_CENTRE_PARTNER_ID = 6339;

export const K_DEKORUMA_DUMMY_MERCHANT_PARTNER_NAME = 'Dummy Merchant';
export const K_DEKORUMA_DUMMY_MERCHANT_PARTNER_ID = 6219;

export const K_PARTNER_ID_DEKORUMA_MARKETING = 6363;
export const K_PARTNER_ID_NARA_HUB_MEDAN = 6649;
export const K_PARTNER_ID_DEKORUMA_NARA_MAKASSAR = 6783;

export const K_PARTNER_ID_DEC_MEDAN_GATSU = 6647;
export const K_PARTNER_ID_DEC_MEDAN_CEMARA_ASRI = 6650;
export const K_PARTNER_ID_DEC_MEDAN_RINGROAD = 6651;
export const K_PARTNER_ID_DEC_PEKANBARU = 6735;
export const K_PARTNER_ID_DEC_MAKASSAR_PERINTIS = 6759;
export const K_PARTNER_ID_DEC_MAKASSAR_PETTARANI = 6790;

export const K_PARTNER_IDS_ZONE_3 = [
  K_PARTNER_ID_NARA_HUB_MEDAN,
  K_PARTNER_ID_DEKORUMA_NARA_MAKASSAR,
  K_PARTNER_ID_DEC_MEDAN_GATSU,
  K_PARTNER_ID_DEC_MEDAN_CEMARA_ASRI,
  K_PARTNER_ID_DEC_MEDAN_RINGROAD,
  K_PARTNER_ID_DEC_PEKANBARU,
  K_PARTNER_ID_DEC_MAKASSAR_PERINTIS,
  K_PARTNER_ID_DEC_MAKASSAR_PETTARANI,
];

export const K_DEFAULT_ROUTING_ZONE_CODE = 'z1';

export const K_DEKORUMA_INSTALLATION_PARTNER_IDS = [
  K_DEKORUMA_SERVICE_CENTRE_PARTNER_ID,
  K_MERCHANT_SERVICE_CENTRE_PARTNER_ID,
  K_DEKORUMA_DUMMY_INSTALLATION_SERVICE_CENTRE_PARTNER_ID,
];

export const K_DEKORUMA_INSTALLATION_PARTNER_NAMES = [
  K_DEKORUMA_SERVICE_CENTRE,
  K_MERCHANT_SERVICE_CENTRE,
  K_DEKORUMA_DUMMY_SERVICE_MERCHANT,
];

export const K_CATEGORIES_ABLE_TO_REQUEST_DELIVERY_DATE = [
  'Sofa L',
  'Sofa 3 Dudukan',
  'Sofa 1 Dudukan',
  'Sofa Anak',
  'Sofa 2 Dudukan',
  'Sofa Set',
  'Sofa Bed',
  'Sleep Care',
];

export const K_MOODBOARD_PRODUCT_DEFAULT_ATTRIBUTES_TO_RETRIEVE = [
  'objectID',
  'image',
  'productOffer',
  'price',
  'title',
  'categories',
  'availability',
  'sectionAttributes',
  'maxRebate',
  'rating',
  'numRating',
  'hasBundleOffer',
];

export const K_CATEGORY_FURNITURE = 'Furniture';

export const K_DEFAULT_PRICE_ZONE_CODE = 'z1';
export const K_JAPANDI = 'Japandi';

export const K_PRODUCT_ADDITIONAL_QUANTITY_SOLD = 20;

export const K_PRODUCT_BRAND_TENZO_LIVING = 'Tenzo Living';

export const K_PRODUCT_STYLE_HUMANIZED_TEXT_FROM_SLUG_MAPPING = {
  JAPANDI_MODERN: 'Japandi Modern',
  JAPANDI_NATURAL: 'Japandi Natural',
  JAPANDI_CLASSIC: 'Japandi Classic',
  JAPANDI_INDUSTRIAL: 'Japandi Industrial',
  OTHERS: 'Others',
};

export const K_PRODUCT_STYLE_SERIES_HUMANIZED_TEXT_FROM_SLUG_MAPPING = {
  'JAPANDI_MODERN - ST2001': 'Harata',
  'JAPANDI_NATURAL - ST1003': 'Hikari',
  'JAPANDI_NATURAL - ST1002': 'Ataka',
  'JAPANDI_NATURAL - ST1001': 'Fujika',
  'JAPANDI_MODERN - SE2002': 'Toru',
  'JAPANDI_MODERN - SE2001': 'Hoshi',
  'JAPANDI_NATURAL - SE1005': 'Mari',
  'JAPANDI_NATURAL - SE1004': 'Kitsu',
  'JAPANDI_NATURAL - SE1003': 'Shiro',
  'JAPANDI_NATURAL - SE1001': 'Raku',
  'JAPANDI_NATURAL - SE1002': 'Yuma',
  'JAPANDI_CLASSIC - SE3002': 'Yuga',
  'JAPANDI_CLASSIC - SE3001': 'Hana',
  'JAPANDI_INDUSTRIAL - SE4001': 'Arai',
};
