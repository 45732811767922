import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Link, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import PageSection from 'app-libs/components/Checkout/PageSection';
import { makeParametricUrl, slugify } from 'app-libs/etc';
import { dayjs } from 'app-libs/etc/dateHelperV2';

import { InsuredOrderLine, OrderInsuranceInfo } from 'entities/order/types';

import { K_ROUTE_PRODUCT } from 'constants/routeConstants';

const K_IMAGE_SIZE = 40;

interface InsuranceHeaderProps {
  order: OrderInsuranceInfo;
  insuredOrderLine: InsuredOrderLine;
}

const InsuranceHeader: React.FC<InsuranceHeaderProps> = ({
  order,
  insuredOrderLine,
}) => {
  const productUrl = makeParametricUrl(K_ROUTE_PRODUCT, {
    pid: insuredOrderLine.upc,
    pslug: slugify(insuredOrderLine.title),
  });

  const datePlaced = dayjs(order.datePlaced).format('DD MMMM YYYY');

  return (
    <PageSection title="">
      <View style={s.container}>
        <View style={s.lineInfoContainer}>
          <Link to={productUrl} isUsingAnchor>
            <SmartImage
              source={insuredOrderLine.imageUrl}
              accessibilityLabel={insuredOrderLine.title}
              style={s.image}
              width={K_IMAGE_SIZE}
              height={K_IMAGE_SIZE}
              resizeMode="contain"
            />
          </Link>
          <View style={s.descriptionContainer}>
            <Text theme="h5" numberOfLines={2}>
              {insuredOrderLine.title}
            </Text>
            <Text theme="body3-black60">
              {insuredOrderLine.quantity} barang
            </Text>
          </View>
        </View>
        <View style={s.orderInfoContainer}>
          <View style={s.orderInfo}>
            <Text theme="body3-black80">Nomor Pesanan</Text>
            <Text theme="body3">{order.number}</Text>
          </View>
          <View style={s.orderInfo}>
            <Text theme="body3-black80">Tanggal Transaksi</Text>
            <Text theme="body3">{datePlaced}</Text>
          </View>
        </View>
      </View>
    </PageSection>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.bgw,
    ...cStyles.flexDirCol,
  },
  lineInfoContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
  },
  image: {
    ...cStyles.brs,
    ...cStyles.mrl,
  },
  descriptionContainer: {
    ...cStyles.flexWrap,
    ...cStyles.flex1,
  },
  orderInfoContainer: {
    ...cStyles.flexDirCol,
    ...cStyles.mtl,
  },
  orderInfo: {
    ...cStyles.mvm,
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
    justifyContent: 'space-between',
  },
});

export default InsuranceHeader;
