export const K_ONLINE_STORE_ADMIN_ROLE = 'Online Store Admin';
export const K_SHOP_ASSISTANT_ROLE = 'Shop Assistant';
export const K_BUSINESS_ROLE = 'Business';
export const K_BETA_TESTER_ROLE = 'Beta Tester';
export const K_DEKORUMA_EMPLOYEE_ROLE = 'Dekoruma Employee';
export const K_MARKETING_ROLE = 'Marketing';
export const K_OFFICIAL_STORE_REORDER_ROLE = 'Official Store Reorder';

export const K_DEKORUMA_USER_ORDER_ON_BEHALF_USER_GROUPS = [
  K_ONLINE_STORE_ADMIN_ROLE,
  K_OFFICIAL_STORE_REORDER_ROLE,
  K_SHOP_ASSISTANT_ROLE,
  K_BUSINESS_ROLE,
];

export const K_OFFLINE_STORE_IDS_SHOULD_SHOW_HUB_MEDAN_STOCK_RECORDS = [
  15, // DEC Booth Aeon Mall
  22, // DEC Medan Gatsu
  23, // DEC Medan Cemara Asri
  24, // DEC Medan Ringroad
  28, // DEC Pekanbaru
];

const K_ORDER_CHANNEL_LABEL_DEKORUMA_REORDER_WITHOUT_PAYMENT =
  'Dekoruma - Reorder tanpa payment';
const K_ORDER_CHANNEL_CODE_DEKORUMA_REORDER_WITHOUT_PAYMENT =
  'DEKORUMA-REORDER-WITHOUT-PAYMENT';

export const K_ORDER_CHANNEL_OPTIONS = [
  {
    label: 'Tokopedia',
    value: 'OS - TP',
  },
  {
    label: 'Blibli',
    value: 'OS - BB',
  },
  {
    label: 'Shopee',
    value: 'OS - SP',
  },
  {
    label: 'Bukalapak',
    value: 'OS - BL',
  },
  {
    label: 'Lazada',
    value: 'OS - LZ',
  },
  {
    label: 'Tiktok',
    value: 'OS - TT',
  },
  {
    label: 'Livin Mandiri',
    value: 'OS - LM',
  },
  {
    label: 'CS',
    value: 'CS',
  },
  {
    label: 'OUTBOUND',
    value: 'OUTBOUND',
  },
  {
    label: 'INBOUND',
    value: 'INBOUND',
  },
  {
    label: K_ORDER_CHANNEL_LABEL_DEKORUMA_REORDER_WITHOUT_PAYMENT,
    value: K_ORDER_CHANNEL_CODE_DEKORUMA_REORDER_WITHOUT_PAYMENT,
  },
];

export const K_ADDITIONAL_INFOS_BY_ORDER_CHANNEL_CODE = {
  [K_ORDER_CHANNEL_CODE_DEKORUMA_REORDER_WITHOUT_PAYMENT]:
    'Untuk reorder dekoruma tanpa payment, mohon isi order reference dengan nomor order utama',
};
