import keyMirror from 'keymirror';
import { normalize } from 'normalizr';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import config from 'config';

import { getUrlWithQuery } from 'app-libs/etc/routeHelper';

import { Schemas } from '../entities';
import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

export const AT = keyMirror({
  LINK_REVIEW_WITH_COMPLAIN: null,
  LINK_REVIEW_WITH_COMPLAIN_SUCCESS: null,
  LINK_REVIEW_WITH_COMPLAIN_FAIL: null,
  CREATE_REVIEW_REPLY: null,
  CREATE_REVIEW_REPLY_SUCCESS: null,
  CREATE_REVIEW_REPLY_FAIL: null,
  GENERATE_VOUCHER: null,
  GENERATE_VOUCHER_SUCCESS: null,
  GENERATE_VOUCHER_FAIL: null,
  GET_PENDING_PRODUCT_REVIEWS: null,
  GET_PENDING_PRODUCT_REVIEWS_SUCCESS: null,
  GET_PENDING_PRODUCT_REVIEWS_FAIL: null,
  GET_PRODUCT_REVIEW: null,
  GET_PRODUCT_REVIEW_SUCCESS: null,
  GET_PRODUCT_REVIEW_FAIL: null,
  GET_PRODUCT_REVIEWED: null,
  GET_PRODUCT_REVIEWED_SUCCESS: null,
  GET_PRODUCT_REVIEWED_FAIL: null,
  GET_PRODUCT_REVIEW_TAGS: null,
  GET_PRODUCT_REVIEW_TAGS_SUCCESS: null,
  GET_PRODUCT_REVIEW_TAGS_FAIL: null,
  PATCH_PRODUCT_REVIEW: null,
  PATCH_PRODUCT_REVIEW_SUCCESS: null,
  PATCH_PRODUCT_REVIEW_FAIL: null,
  LOAD_PRODUCT_REVIEWS_SUMMARY: null,
  LOAD_PRODUCT_REVIEWS_SUMMARY_SUCCESS: null,
  LOAD_PRODUCT_REVIEWS_SUMMARY_FAIL: null,
  CLOSE_PRODUCT_REVIEW_BANNER: null,
  CLOSE_PRODUCT_REVIEW_BANNER_SUCCESS: null,
  CLOSE_PRODUCT_REVIEW_BANNER_FAIL: null,
  CLOSE_PRODUCT_REVIEW_MODAL: null,
  CLOSE_PRODUCT_REVIEW_MODAL_SUCCESS: null,
  CLOSE_PRODUCT_REVIEW_MODAL_FAIL: null,
});

const initialState = {
  linkedReviewComplainUrl: {},
  generatedReviewVoucher: {},
  linkingReview: false,
  generatingVoucher: false,
  isLoadingReviewTag: false,
  pendingProductReviews: {
    asyncState: initialAsyncState,
    results: {
      hits: [],
    },
  },
  productReviewed: {
    asyncState: initialAsyncState,
    results: {
      hits: [],
    },
  },
  productReview: {
    loadAsyncState: initialAsyncState,
    patchAsyncState: initialAsyncState,
  },
  productReviewSummary: {
    numPendingReviews: 0,
    shouldShowModal: false,
    shouldShowBanner: false,
    lastPendingReview: null,
  },
};

export default function reviewReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.GET_PRODUCT_REVIEW_TAGS:
      state = {
        ...state,
        isLoadingReviewTag: true,
      };
      break;
    case AT.GET_PRODUCT_REVIEW_TAGS_SUCCESS:
    case AT.GET_PRODUCT_REVIEW_TAGS_FAIL:
      state = {
        ...state,
        isLoadingReviewTag: false,
      };
      break;
    case AT.GENERATE_VOUCHER:
      state = {
        ...state,
        generatingVoucher: true,
      };
      break;

    case AT.GENERATE_VOUCHER_SUCCESS:
      state = {
        ...state,
        generatingVoucher: false,
      };

      if (!state.generatedReviewVoucher[action.payload.reviewId]) {
        const newGeneratedReviewVoucher = {};
        newGeneratedReviewVoucher[action.payload.reviewId] = action.result.code;
        state = {
          ...state,
          generatedReviewVoucher: {
            ...state.generatedReviewVoucher,
            ...newGeneratedReviewVoucher,
          },
        };
      }
      break;

    case AT.GENERATE_VOUCHER_FAIL:
      state = {
        ...state,
        generatingVoucher: false,
      };
      break;

    case AT.LINK_REVIEW_WITH_COMPLAIN:
      state = {
        ...state,
        linkingReview: true,
      };
      break;

    case AT.LINK_REVIEW_WITH_COMPLAIN_SUCCESS:
      state = {
        ...state,
        linkingReview: false,
      };

      if (!state.linkedReviewComplainUrl[action.payload.reviewId]) {
        const newLinkedReview = {};
        newLinkedReview[action.payload.reviewId] = action.result.complainUrl;
        state = {
          ...state,
          linkedReviewComplainUrl: {
            ...state.linkedReviewComplainUrl,
            ...newLinkedReview,
          },
        };
      }
      break;

    case AT.LINK_REVIEW_WITH_COMPLAIN_FAIL:
      state = {
        ...state,
        linkingReview: false,
      };
      break;

    case AT.GET_PENDING_PRODUCT_REVIEWS:
    case AT.GET_PENDING_PRODUCT_REVIEWS_SUCCESS:
    case AT.GET_PENDING_PRODUCT_REVIEWS_FAIL:
      state = {
        ...state,
        pendingProductReviews: pendingProductReviewsReducer(
          state.pendingProductReviews,
          action,
        ),
      };
      break;
    case AT.LOAD_PRODUCT_REVIEWS_SUMMARY_SUCCESS:
      state = {
        ...state,
        productReviewSummary: action.result,
      };
      break;
    case AT.CLOSE_PRODUCT_REVIEW_MODAL_SUCCESS:
      state = {
        ...state,
        productReviewSummary: {
          ...state.productReviewSummary,
          shouldShowModal: false,
        },
      };
      break;
    case AT.CLOSE_PRODUCT_REVIEW_BANNER_SUCCESS:
      state = {
        ...state,
        productReviewSummary: {
          ...state.productReviewSummary,
          shouldShowBanner: false,
        },
      };
      break;
    case AT.GET_PRODUCT_REVIEWED:
    case AT.GET_PRODUCT_REVIEWED_SUCCESS:
    case AT.GET_PRODUCT_REVIEWED_FAIL:
      state = {
        ...state,
        productReviewed: productReviewedReducer(state.productReviewed, action),
      };
      break;

    case AT.GET_PRODUCT_REVIEW:
    case AT.GET_PRODUCT_REVIEW_SUCCESS:
    case AT.GET_PRODUCT_REVIEW_FAIL:
      state = {
        ...state,
        productReview: {
          ...state.productReview,
          loadAsyncState: asyncStateReducer(
            state.productReview.loadAsyncState,
            action,
            '_PRODUCT_REVIEW_',
          ),
        },
      };
      break;

    case AT.PATCH_PRODUCT_REVIEW_SUCCESS: {
      const productReviewKey = action.result.content;
      const isCreatedProductReview = state.productReviewed.results.hits.includes(
        productReviewKey,
      );

      state = {
        ...state,
        pendingProductReviews: {
          ...state.pendingProductReviews,
          results: pendingProductReviewsReducer(
            state.pendingProductReviews.results,
            action,
          ),
        },
        productReview: {
          ...state.productReview,
          patchAsyncState: asyncStateReducer(
            state.productReview.patchAsyncState,
            action,
            '_PRODUCT_REVIEW_',
          ),
        },
      };

      if (!isCreatedProductReview) {
        state = {
          ...state,
          productReviewed: {
            ...state.productReviewed,
            results: productReviewedReducer(
              state.productReviewed.results,
              action,
            ),
          },
        };
      }
    }
  }

  switch (action.type) {
    case AT.PATCH_PRODUCT_REVIEW:
    case AT.PATCH_PRODUCT_REVIEW_SUCCESS:
    case AT.PATCH_PRODUCT_REVIEW_FAIL:
      state = {
        ...state,
        productReview: {
          ...state.productReview,
          patchAsyncState: asyncStateReducer(
            state.productReview.patchAsyncState,
            action,
            '_PRODUCT_REVIEW_',
          ),
        },
      };
      break;
  }

  return state;
}

export function pendingProductReviewsReducer(
  mutableState = initialState.pendingProductReviews,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.GET_PENDING_PRODUCT_REVIEWS_SUCCESS:
      state = {
        ...state,
        results: {
          hits: [...action.result.content],
        },
      };
      break;

    case AT.PATCH_PRODUCT_REVIEW_SUCCESS: {
      const productReviewKey = action.result.content;

      state = {
        ...state,
        hits: state.hits.filter((review) => review !== productReviewKey),
      };
      break;
    }
  }

  switch (action.type) {
    case AT.GET_PENDING_PRODUCT_REVIEWS:
    case AT.GET_PENDING_PRODUCT_REVIEWS_SUCCESS:
    case AT.GET_PENDING_PRODUCT_REVIEWS_FAIL:
      state = {
        ...state,
        asyncState: asyncStateReducer(
          state.asyncState,
          action,
          '_PENDING_PRODUCT_REVIEWS_',
        ),
      };
      break;
  }

  return state;
}

export function productReviewedReducer(
  mutableState = initialState.productReviewed,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.GET_PRODUCT_REVIEWED_SUCCESS:
      state = {
        ...state,
        results: {
          hits: [...action.result.content],
        },
      };
      break;

    case AT.PATCH_PRODUCT_REVIEW_SUCCESS: {
      const productReviewKey = action.result.content;
      state = {
        ...state,
        hits: [productReviewKey, ...state.hits],
      };
      break;
    }
  }

  switch (action.type) {
    case AT.GET_PRODUCT_REVIEWED:
    case AT.GET_PRODUCT_REVIEWED_SUCCESS:
    case AT.GET_PRODUCT_REVIEWED_FAIL:
      state = {
        ...state,
        asyncState: asyncStateReducer(
          state.asyncState,
          action,
          '_PRODUCT_REVIEWED_',
        ),
      };
      break;
  }

  return state;
}
export function loadPendingProductReviews(publicKey) {
  const headers = { 'Api-Key': config.API_KEY_SIN };

  return {
    types: [
      AT.GET_PENDING_PRODUCT_REVIEWS,
      AT.GET_PENDING_PRODUCT_REVIEWS_SUCCESS,
      AT.GET_PENDING_PRODUCT_REVIEWS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_SIN}/reviews/product/?status=Pending&publicKey=${publicKey}`,
        {
          headers,
        },
      ),
    options: {
      transformer: (result) => {
        const normalized = normalize(
          result.results,
          Schemas.PRODUCT_REVIEW_COLLECTION,
        );
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export function loadProductReviewsSummary() {
  return {
    types: [
      AT.LOAD_PRODUCT_REVIEWS_SUMMARY,
      AT.LOAD_PRODUCT_REVIEWS_SUMMARY_SUCCESS,
      AT.LOAD_PRODUCT_REVIEWS_SUMMARY_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_SIN}/reviews/product/summary/`),
    options: {
      transformer: (result) => {
        return result;
      },
    },
  };
}

export function closeProductReviewModal() {
  return {
    types: [
      AT.CLOSE_PRODUCT_REVIEW_MODAL,
      AT.CLOSE_PRODUCT_REVIEW_MODAL_SUCCESS,
      AT.CLOSE_PRODUCT_REVIEW_MODAL_FAIL,
    ],
    promise: (client) =>
      client.patch(`${config.API_URL_SIN}/reviews/close-modal/`),
  };
}

export function closeProductReviewBanner() {
  return {
    types: [
      AT.CLOSE_PRODUCT_REVIEW_BANNER,
      AT.CLOSE_PRODUCT_REVIEW_BANNER_SUCCESS,
      AT.CLOSE_PRODUCT_REVIEW_BANNER_FAIL,
    ],
    promise: (client) =>
      client.patch(`${config.API_URL_SIN}/reviews/close-banner/`),
  };
}

export function loadProductReviewed(publicKey) {
  const headers = { 'Api-Key': config.API_KEY_SIN };

  return {
    types: [
      AT.GET_PRODUCT_REVIEWED,
      AT.GET_PRODUCT_REVIEWED_SUCCESS,
      AT.GET_PRODUCT_REVIEWED_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_SIN}/reviews/product/?status=Completed&publicKey=${publicKey}`,
        {
          headers,
        },
      ),
    options: {
      transformer: (result) => {
        const normalized = normalize(
          result.results,
          Schemas.PRODUCT_REVIEW_COLLECTION,
        );
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export function loadProductReview({ productReviewKey, publicKey }) {
  const headers = { 'Api-Key': config.API_KEY_SIN };

  let url = `${config.API_URL_SIN}/reviews/product/${productReviewKey}`;
  if (publicKey) {
    url = getUrlWithQuery(url, { publicKey });
  } else {
    url += '/';
  }

  return {
    types: [
      AT.GET_PRODUCT_REVIEW,
      AT.GET_PRODUCT_REVIEW_SUCCESS,
      AT.GET_PRODUCT_REVIEW_FAIL,
    ],
    promise: (client) => client.get(url, { headers }),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.PRODUCT_REVIEW);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export function loadProductReviewTagOptions({ score, breadcrumbs, callback }) {
  let [catLvl0, catLvl1, catLvl2] = breadcrumbs.split(' > ');
  catLvl0 = encodeURIComponent(catLvl0);
  catLvl1 = encodeURIComponent(catLvl1);
  catLvl2 = encodeURIComponent(catLvl2);
  let url = `${config.API_URL_SIN}/reviews/tags?category0=${catLvl0}&category1=${catLvl1}&category2=${catLvl2}`;

  if (score) {
    url += `&score=${score}`;
  }

  return {
    types: [
      AT.GET_PRODUCT_REVIEW_TAGS,
      AT.GET_PRODUCT_REVIEW_TAGS_SUCCESS,
      AT.GET_PRODUCT_REVIEW_TAGS_FAIL,
    ],
    promise: (client) => client.get(url),
    options: {
      transformer: (result) => {
        if (isEmpty(result)) {
          return [];
        }

        if (has(result, 'results')) {
          return result.results;
        }

        return result;
      },
      callback,
    },
  };
}

export function updateProductReview({
  productReviewKey,
  score,
  note,
  imageUrls,
  publicKey,
  reviewTags,
}) {
  const headers = { 'Api-Key': config.API_KEY_SIN };

  let url = `${config.API_URL_SIN}/reviews/product/${productReviewKey}/`;
  if (publicKey) url = getUrlWithQuery(url, { publicKey });

  const reviewImagesCommaDelimited = imageUrls.join();

  return {
    types: [
      AT.PATCH_PRODUCT_REVIEW,
      AT.PATCH_PRODUCT_REVIEW_SUCCESS,
      AT.PATCH_PRODUCT_REVIEW_FAIL,
    ],
    promise: (client) =>
      client.patch(url, {
        headers,
        data: { score, note, reviewImagesCommaDelimited, reviewTags },
      }),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.PRODUCT_REVIEW);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}
