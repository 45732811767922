export const K_POST_MESSAGE_TYPE_SET_USER_ID = 'setUserId';
export const K_POST_MESSAGE_TYPE_USER_SIGNED_IN = 'setUserLoggedIn';
export const K_POST_MESSAGE_TYPE_USER_LOGGED_OUT = 'setUserLoggedOut';
export const K_POST_MESSAGE_TYPE_TRACK_EVENT = 'trackEvent';
export const K_POST_MESSAGE_TYPE_FACEBOOK_LOGIN = 'facebookLogin';
export const K_POST_MESSAGE_TYPE_MARK_AS_SEEN_NOTIFICATION =
  'markAsSeenNotification';
export const K_POST_MESSAGE_TYPE_SHARE = 'share';
export const K_POST_MESSAGE_TYPE_SET_DEFAULT_DASHBOARD = 'setDefaultDashboard';
export const K_POST_MESSAGE_TYPE_RELOAD = 'reload';
export const K_POST_MESSAGE_TYPE_SHOW_IN_APP_REVIEW = 'showInAppReview';
export const K_POST_MESSAGE_TYPE_TRACK_FB_EVENT = 'trackFBEvent';
