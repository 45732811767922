import keyMirror from 'keymirror';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';

import asyncStateReducer from 'app-libs/redux_modules/helper_modules/asyncState';
import {
  isMattressSignatureSeries,
  getSignatureMattressSeries,
} from 'app-libs/etc/mattressHelper';
import { fetchObjects } from '../helper_modules/search/algoliaWrapper';

export const AT = keyMirror({
  GET_SIGNATURE_MATTRESSES: null,
  GET_SIGNATURE_MATTRESSES_SUCCESS: null,
  GET_SIGNATURE_MATTRESSES_FAIL: null,

  GET_SIGNATURE_MATTRESSES_SIBLINGS: null,
  GET_SIGNATURE_MATTRESSES_SIBLINGS_SUCCESS: null,
  GET_SIGNATURE_MATTRESSES_SIBLINGS_FAIL: null,
});

const initialState = {
  asyncState: { loading: true },
  signatureMattresses: [],
  signatureMattressesBySeries: {},
};

function getSignatureMattressSuccess(state, action) {
  const signatureMattresses = action.result;
  return {
    ...state,
    signatureMattresses,
  };
}

function getSignatureMattressSiblingsSuccess(state, action) {
  const signatureMattressesBySeries = action.result;
  return {
    ...state,
    signatureMattressesBySeries,
  };
}

export default function mattressReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  state = {
    ...state,
    asyncState: asyncStateReducer(
      state.asyncState,
      action,
      '_SIGNATURE_MATTRESSES_SIBLINGS_',
    ),
  };

  switch (action.type) {
    case AT.GET_SIGNATURE_MATTRESSES_SUCCESS:
      return getSignatureMattressSuccess(state, action);
    case AT.GET_SIGNATURE_MATTRESSES_SIBLINGS_SUCCESS:
      return getSignatureMattressSiblingsSuccess(state, action);
    default:
      return state;
  }
}

export function loadSignatureMattress(upcs, callback) {
  return {
    types: [
      AT.GET_SIGNATURE_MATTRESSES,
      AT.GET_SIGNATURE_MATTRESSES_SUCCESS,
      AT.GET_SIGNATURE_MATTRESSES_FAIL,
    ],
    promise: () => fetchObjects(upcs),
    options: {
      transformer: (result) => {
        const newResult = get(result, 'results', []);
        return newResult.filter(Boolean);
      },
      callback,
    },
  };
}

export function loadSignatureMattressSiblings(upcs) {
  return {
    types: [
      AT.GET_SIGNATURE_MATTRESSES_SIBLINGS,
      AT.GET_SIGNATURE_MATTRESSES_SIBLINGS_SUCCESS,
      AT.GET_SIGNATURE_MATTRESSES_SIBLINGS_FAIL,
    ],
    promise: () => fetchObjects(upcs),
    options: {
      transformer: (result) => {
        const siblings = get(result, 'results', []);
        const filteredSiblings = siblings.filter(
          (mattress) => mattress && isMattressSignatureSeries(mattress),
        );
        const newResult = groupBy(filteredSiblings, (mattress) =>
          getSignatureMattressSeries(mattress),
        );
        return newResult;
      },
    },
  };
}
