import { useCallback, useState } from 'react';

export const useVisibility = (initialState = false) => {
  const [isVisible, setIsVisible] = useState(initialState);

  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => setIsVisible(false), []);

  return [isVisible, show, hide] as [boolean, () => void, () => void];
};
