import keyMirror from 'keymirror';
import { normalize } from 'normalizr';
import config from 'config';

import { Schemas } from '../../entities';
import { Schemas as AdvertisementSchemas } from './schema';
import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';

// ///////////////////// CHALLENGE REDUX MODULE ///////////////////// //

export const AT = keyMirror({
  LOAD_BANNER_ADS: null,
  LOAD_BANNER_ADS_SUCCESS: null,
  LOAD_BANNER_ADS_FAIL: null,
  LOAD_TEXT_ADS: null,
  LOAD_TEXT_ADS_FAIL: null,
  LOAD_TEXT_ADS_SUCCESS: null,
  CLOSE_FLOATING_ICON: null,
  CLOSE_POPUP_BANNER: null,

  LOAD_ADVERTISEMENTS: null,
  LOAD_ADVERTISEMENTS_SUCCESS: null,
  LOAD_ADVERTISEMENTS_FAIL: null,
});

const initialState = {
  banner: { asyncState: initialAsyncState },
  text: { asyncState: initialAsyncState },
  advertisementListsByLocation: {},
  asyncState: initialAsyncState,
  shouldShowFloatingIcon: true,
  lastTimeClosePopupBanner: null, // Persistent-redux
};

export default function advertisementReducer(
  mutableState = initialState,
  action = {},
) {
  let state = Object.assign({}, mutableState);

  state.asyncState = asyncStateReducer(
    state.asyncState,
    action,
    '_ADVERTISEMENTS_',
  );

  switch (action.type) {
    case AT.LOAD_BANNER_ADS:
    case AT.LOAD_BANNER_ADS_SUCCESS:
    case AT.LOAD_BANNER_ADS_FAIL: {
      state = {
        ...state,
        banner: adBannerReducer(state.banner, action),
      };
      break;
    }
    case AT.LOAD_TEXT_ADS:
    case AT.LOAD_TEXT_ADS_SUCCESS:
    case AT.LOAD_TEXT_ADS_FAIL: {
      state = {
        ...state,
        text: adTextReducer(state.text, action),
      };
      break;
    }
    case AT.CLOSE_FLOATING_ICON:
      state = {
        ...state,
        shouldShowFloatingIcon: false,
      };
      break;
    case AT.CLOSE_POPUP_BANNER:
      state = {
        ...state,
        lastTimeClosePopupBanner: Date.now(),
      };
      break;
    case AT.LOAD_ADVERTISEMENTS_SUCCESS: {
      const { entities } = action.result;
      const advertisementListsByLocation = {};
      Object.entries(entities.advertisementListsByLocation).forEach(
        ([idAttribute, value]) => {
          advertisementListsByLocation[idAttribute] =
            value.advertisements ?? [];
        },
      );
      state = {
        ...state,
        advertisementListsByLocation,
      };
      break;
    }
  }

  return state;
}

function adBannerReducer(mutableState = initialState.banner, action = {}) {
  const state = { ...mutableState };
  state.asyncState = asyncStateReducer(
    state.asyncState,
    action,
    '_BANNER_ADS_',
  );
  return state;
}

function adTextReducer(mutableState = initialState.state, action = {}) {
  const state = { ...mutableState };
  state.asyncState = asyncStateReducer(state.asyncState, action, '_TEXT_ADS_');
  return state;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Action Creator
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export function loadAdBanner(callback = () => {}) {
  return {
    types: [
      AT.LOAD_BANNER_ADS,
      AT.LOAD_BANNER_ADS_SUCCESS,
      AT.LOAD_BANNER_ADS_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_KLAW}/locations/?adtype=banner&format=json`),
    options: {
      transformer: (result) => {
        const normalizedData = normalize(
          result,
          AdvertisementSchemas.ADVERTISEMENT_COLLECTION,
        );
        return {
          content: normalizedData.result,
          entities: normalizedData.entities,
        };
      },
      callback,
    },
  };
}

export function loadAdText(callback = () => {}) {
  return {
    types: [AT.LOAD_TEXT_ADS, AT.LOAD_TEXT_ADS_SUCCESS, AT.LOAD_TEXT_ADS_FAIL],
    promise: (client) =>
      client.get(`${config.API_URL_KLAW}/locations/?adtype=text&format=json`),
    options: {
      transformer: (result) => {
        const normalizedData = normalize(
          result,
          Schemas.ADVERTISEMENT_COLLECTION,
        );
        return {
          content: normalizedData.result,
          entities: normalizedData.entities,
        };
      },
      callback,
    },
  };
}

export function loadAdvertisements() {
  return (dispatch) => {
    const promises = [];
    promises.push(dispatch(loadAdBanner()));
    promises.push(dispatch(loadAdText()));
    return Promise.all(promises);
  };
}

export function loadAdvertisementsV2() {
  return {
    types: [
      AT.LOAD_ADVERTISEMENTS,
      AT.LOAD_ADVERTISEMENTS_SUCCESS,
      AT.LOAD_ADVERTISEMENTS_FAIL,
    ],
    promise: (client) => client.get(`${config.API_URL_KLAW}/locations-v2/`),
    options: {
      transformer: (result) => {
        const normalizedData = normalize(
          result,
          AdvertisementSchemas.ADVERTISEMENT_COLLECTION,
        );
        return {
          content: normalizedData.result,
          entities: normalizedData.entities,
        };
      },
    },
  };
}

export function closeFloatingIcon() {
  return {
    type: AT.CLOSE_FLOATING_ICON,
  };
}

export function closePopupBanner() {
  return {
    type: AT.CLOSE_POPUP_BANNER,
  };
}
