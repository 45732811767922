import AT from '../types';
import {
  initialAsyncState,
  fetchingStatus,
  fetchSuccessStatus,
  fetchFailStatus,
} from '../../helper_modules/asyncState';

// @TODO REFACTOR REDUCER INI KE STANDARD BARU
export default function designerProfileReducer(
  mutableState = { asyncState: initialAsyncState },
  action = {},
) {
  const state = mutableState;
  switch (action.type) {
    case AT.REGISTER_DESIGNER:
    case AT.PATCH_PROFILE_DESIGNER:
    case AT.GET_PROFILE_DESIGNER:
      return getDesignerProfile(state);

    case AT.REGISTER_DESIGNER_SUCCESS:
    case AT.PATCH_PROFILE_DESIGNER_SUCCESS:
    case AT.GET_PROFILE_DESIGNER_SUCCESS:
      return getDesignerProfileSuccess(state, action);

    case AT.REGISTER_DESIGNER_FAIL:
    case AT.PATCH_PROFILE_DESIGNER_FAIL:
    case AT.GET_PROFILE_DESIGNER_FAIL:
      return getDesignerProfileFail(state, action);
    default:
      return state;
  }
}

function getDesignerProfile(state) {
  return {
    ...state,
    asyncState: fetchingStatus(),
  };
}

function getDesignerProfileSuccess(state, action) {
  const data = action.result;

  return {
    ...state,
    designerProfile: data,
    asyncState: fetchSuccessStatus(),
  };
}

function getDesignerProfileFail(state, action) {
  return {
    ...state,
    asyncState: fetchFailStatus(action.error),
  };
}
