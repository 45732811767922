import React, { memo, useCallback } from 'react';

import { StyleSheet, Swiper } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import { Banner } from 'app-libs/redux_modules/entity_modules/advertisement/types';

import { ViewStyle } from 'types/styles';
import CampaignBannerSliderItem from './Item';

interface CampaignBannerSliderProps {
  banners: Banner[];
  height: number;
  width: number;
  style?: ViewStyle | ViewStyle[];
}

const CampaignBannerSlider: React.FC<CampaignBannerSliderProps> = memo(
  ({ banners, height, width, style }) => {
    const renderCampaignBannerItem = useCallback(
      (banner: Banner) => (
        <CampaignBannerSliderItem
          height={height}
          width={width}
          banner={banner}
        />
      ),
      [height, width],
    );

    return (
      <Swiper
        data={banners}
        renderItem={renderCampaignBannerItem}
        heightProportion={height}
        widthProportion={width}
        paginationDotSize={8}
        paginationDotStyle={cStyles.maxs}
        paginationStyle={s.pagination}
        paginationTheme="white"
        containerStyle={style}
      />
    );
  },
);

const s = StyleSheet.create({
  pagination: {
    pointerEvents: 'none',
  },
});

export default CampaignBannerSlider;
