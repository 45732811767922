/* global criteo_q */

export function sendEvent(eventName, content = {}) {
  const dynamicContent = Object.assign({}, { event: eventName }, content);
  /* eslint-disable-next-line camelcase */
  if (criteo_q) {
    criteo_q.push(
      { event: 'setAccount', account: 28900 },
      { event: 'setHashedEmail', email: '' },
      { event: 'setSiteType', type: 'd' },
      dynamicContent,
    );
  }
}

export function sendEventDNB(eventName, content = {}) {
  const dynamicContent = Object.assign({}, { event: eventName }, content);
  /* eslint-disable-next-line camelcase */
  if (criteo_q) {
    criteo_q.push(
      { event: 'setAccount', account: 45188 },
      { event: 'setHashedEmail', email: '' },
      { event: 'setSiteType', type: 'd' },
      dynamicContent,
    );
  }
}
