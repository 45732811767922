import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet } from '@dkrm/ui-kit-basic';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  container: {
    height: 48,
  },
  contentContainer: {
    ...cStyles.flexMiddle,
    ...cStyles.flexDirRow,
    ...cStyles.prxxl,
  },
  numberOfProductsContainer: {
    ...cStyles.mlxxl,
    ...cStyles.mrs,
  },
  buttonContainer: {
    ...cStyles.plm,
    ...cStyles.pvm,
  },
  buttonTextStyle: {
    ...tStyles.body3,
  },
  buttonStyle: {
    ...cStyles.pam,
    ...cStyles.brs,
    ...cStyles.flexDirRow,
    minWidth: 30,
  },
  unselectedButtonText: {
    color: Colors.C_NEUTRAL_100,
  },
  unselectedButton: {
    ...cStyles.bwam,
    borderColor: Colors.C_NEUTRAL_30,
  },
  selectedButtonText: {
    color: Colors.C_TEAL_500,
  },
  selectedButton: {
    ...cStyles.bwam,
    backgroundColor: Colors.C_TEAL_100,
    borderColor: Colors.C_TEAL_500,
  },
});
