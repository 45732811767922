import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';

import {
  K_DURATION_LONG,
  K_DURATION_SHORT,
  K_POSITION_BOTTOM,
  K_POSITION_BOTTOM_CENTER,
  K_POSITION_BOTTOM_LEFT,
  K_POSITION_BOTTOM_RIGHT,
  K_POSITION_CENTER,
  K_POSITION_LEFT,
  K_POSITION_MIDDLE,
  K_POSITION_MIDDLE_CENTER,
  K_POSITION_RIGHT,
  K_POSITION_TOP,
  K_POSITION_TOP_CENTER,
  K_POSITION_TOP_LEFT,
  K_POSITION_TOP_RIGHT,
} from './constants';
import styles from './styles';

export default class BasePopup extends React.PureComponent {
  static propTypes = {
    position: PropTypes.oneOf([
      K_POSITION_TOP,
      K_POSITION_BOTTOM,
      K_POSITION_MIDDLE,
      K_POSITION_LEFT,
      K_POSITION_RIGHT,
      K_POSITION_CENTER,
      K_POSITION_TOP_LEFT,
      K_POSITION_TOP_RIGHT,
      K_POSITION_TOP_CENTER,
      K_POSITION_BOTTOM_LEFT,
      K_POSITION_BOTTOM_RIGHT,
      K_POSITION_BOTTOM_CENTER,
      K_POSITION_MIDDLE_CENTER,
    ]).isRequired,
    duration: PropTypes.oneOf([
      K_DURATION_LONG,
      K_DURATION_SHORT,
      PropTypes.number,
    ]),
    containerStyle: stylePropTypes,
    actionHidePopup: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  static defaultProps = {
    containerStyle: null,
    duration: K_DURATION_SHORT,
  };

  componentDidMount() {
    const { duration, actionHidePopup } = this.props;
    if (duration) {
      setTimeout(actionHidePopup, duration);
    }
  }

  getContainerStyles() {
    const { containerStyle } = this.props;
    const containerStyles = [styles.container, ...this.getStyleFromPosition()];
    if (containerStyle) {
      containerStyles.push(containerStyle);
    }
    return containerStyles;
  }

  getStyleFromPosition() {
    let { position } = this.props;
    const style = [];
    if (position) {
      position = position.toLowerCase();
      if (position.includes(K_POSITION_TOP)) {
        style.push(styles.top);
      }
      if (position.includes(K_POSITION_BOTTOM)) {
        style.push(styles.bottom);
      }
      if (position.includes(K_POSITION_MIDDLE)) {
        style.push(styles.middle);
      }
      if (position.includes(K_POSITION_RIGHT)) {
        style.push(styles.right);
      }
      if (position.includes(K_POSITION_LEFT)) {
        style.push(styles.left);
      }
      if (position.includes(K_POSITION_CENTER)) {
        style.push(styles.center);
      }
    }

    return style;
  }

  render = () => {
    const { children } = this.props;
    return <View style={this.getContainerStyles()}>{children}</View>;
  };
}
