import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import cStyles from '@dkrm/general-libs/theme/styles';

import {
  K_ADDON_INSURANCE_CLAIM_URL,
  K_ADDON_INSURANCE_POLICY_WORDING_URL,
} from 'constants/insuranceConstants';

interface InsuranceActionButtonsProps {
  insuranceSrpUrl: string | null;
  canSeeInsurancePolicy: boolean;
  canSeeSrpDocument: boolean;
  canClaim: boolean;
}

const InsuranceActionButtons: React.FC<InsuranceActionButtonsProps> = memo(
  ({ insuranceSrpUrl, canSeeInsurancePolicy, canSeeSrpDocument, canClaim }) => {
    const history = useHistory();

    const handleClaimButtonPress = useCallback(() => {
      actionRouteActionOpenURL(K_ADDON_INSURANCE_CLAIM_URL, history, {
        isUsingAnchor: true,
      });
    }, [history]);

    const handlSrpDocumentButtonPress = useCallback(() => {
      actionRouteActionOpenURL(insuranceSrpUrl, history, {
        isUsingAnchor: true,
      });
    }, [history, insuranceSrpUrl]);

    const handlePolicyWordingButtonPress = useCallback(() => {
      actionRouteActionOpenURL(K_ADDON_INSURANCE_POLICY_WORDING_URL, history, {
        isUsingAnchor: true,
      });
    }, [history]);

    return (
      <View style={s.container}>
        <ButtonWithText
          onPress={handleClaimButtonPress}
          disabled={!canClaim}
          title="Ajukan Klaim"
          themeType="primary"
          buttonStyle={cStyles.mbm}
        />
        <ButtonWithText
          onPress={handlSrpDocumentButtonPress}
          disabled={!canSeeSrpDocument}
          title="Surat Rangkuman Perlindungan"
          themeType="secondary"
          buttonStyle={cStyles.mbm}
        />
        <ButtonWithText
          onPress={handlePolicyWordingButtonPress}
          disabled={!canSeeInsurancePolicy}
          title="Policy Wording"
          themeType="secondary"
        />
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.mtl,
    ...cStyles.flexDirCol,
  },
});

export default InsuranceActionButtons;
