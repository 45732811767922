import keyMirror from 'keymirror';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import produce from 'immer';

export const K_ALERT_NOTICE = 'notice';
export const K_ALERT_ERROR = 'error';
export const K_ALERT_SUCCESS = 'success';
export const K_ALERT_WARNING = 'warning';

export const AT = keyMirror({
  SHOW_NOTIFICATION_FLASH_TOP: null,
  HIDE_NOTIFICATION_FLASH_TOP: null,
  SHOW_NOTIFICATION_PROMPT: null,
  HIDE_NOTIFICATION_PROMPT: null,
  SHOW_NOTIFICATION_TEXT_ME_THE_APP: null,
  HIDE_NOTIFICATION_TEXT_ME_THE_APP: null,
  SHOW_SNACKBAR: null,
  HIDE_SNACKBAR: null,
  SHOW_TOAST: null,
  HIDE_TOAST: null,
});

export const K_DEFAULT_OBJECTS_PER_PAGE = 3;

const initialState = {
  loading: false,
  loaded: false,
  error: null,

  flashTop: null,
  prompt: null,
  snackBar: null,
  toast: null,

  shouldShowNotificationTextMeTheApp: true,

  queryParameters: {
    hitsPerPage: K_DEFAULT_OBJECTS_PER_PAGE,
    page: 0,
  },

  queryResults: {
    objects: [],
    page: 0,
  },
};

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Reducer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

const notificationReducer = produce((draft, action = {}) => {
  switch (action.type) {
    case AT.SHOW_NOTIFICATION_FLASH_TOP:
      draft.flashTop = action.payload;
      break;
    case AT.HIDE_NOTIFICATION_FLASH_TOP:
      draft.flashTop = null;
      break;
    case AT.SHOW_NOTIFICATION_PROMPT:
      draft.prompt = action.payload;
      break;
    case AT.HIDE_NOTIFICATION_PROMPT:
      draft.prompt = initialState.prompt;
      break;
    case AT.SHOW_NOTIFICATION_TEXT_ME_THE_APP:
      draft.shouldShowNotificationTextMeTheApp = true;
      break;
    case AT.HIDE_NOTIFICATION_TEXT_ME_THE_APP:
      draft.shouldShowNotificationTextMeTheApp = false;
      break;
    case AT.SHOW_SNACKBAR:
      draft.snackBar = action.payload;
      break;
    case AT.HIDE_SNACKBAR:
      draft.snackBar = null;
      break;
    case AT.SHOW_TOAST:
      draft.toast = action.payload;
      break;
    case AT.HIDE_TOAST:
      draft.toast = null;
      break;
  }

  /**
   * showPromptOnError is passed in metadata.
   * If the value of variable showPromptOnError is true, it will show the error message, otherwise not.
   * @type Boolean (default: true)
   */
  const showPromptOnError = has(action, 'metadata.showPromptOnError')
    ? action.metadata.showPromptOnError
    : true;

  /** catch all error */

  if (
    has(action, 'error.response.text') &&
    !isEmpty(action.error.response.text) &&
    showPromptOnError
  ) {
    let message = action.error.response.text;
    if (
      has(action, 'error.response.body.message') &&
      has(action, 'error.response.body.code')
    ) {
      // eslint-disable-next-line prefer-destructuring
      message = action.error.response.body.message;
    } else if (has(action, 'error.response.body.reason')) {
      if (typeof action.error.response.body.reason === 'string') {
        if (action.error.response.body.reason.startsWith('a maximum of')) {
          message = `Tidak cukup stock untuk barang yang baru saja Anda tambahkan ke troli. Mohon kurangi quantity barang tersebut di troli agar anda bisa membeli: ${action.error.response.text}`;
        } else if (
          action.error.response.body.reason.startsWith('Stock is unavailable')
        ) {
          if (action.error.response.body.error) {
            const unavailableProducts = [];
            action.error.response.body.error.forEach((errorProduct) => {
              let errorEntry = Object.entries(errorProduct);
              errorEntry = errorEntry.length && errorEntry[0];
              if (errorEntry[1] === 'Stock is unavailable') {
                unavailableProducts.push(errorEntry[0]);
              }
            });
            if (unavailableProducts.length) {
              message = `Stok untuk ${unavailableProducts.join(
                ', ',
              )} kini sudah habis. Mohon hilangkan dari Troli.`;
            } else {
              message = 'Salah satu produk yang ada di troli kini sudah habis.';
            }
          }
        }
      }
    }
    draft.prompt = { title: 'Error', message, type: K_ALERT_ERROR };
  }
}, initialState);

export function showNotificationFlashTop(
  message,
  type = K_ALERT_NOTICE,
  targetId = 'default',
) {
  return {
    type: AT.SHOW_NOTIFICATION_FLASH_TOP,
    payload: { message, type, targetId },
  };
}

export function hideNotificationFlashTop() {
  return {
    type: AT.HIDE_NOTIFICATION_FLASH_TOP,
  };
}

export function showNotificationPrompt(message, title, type = K_ALERT_NOTICE) {
  return {
    type: AT.SHOW_NOTIFICATION_PROMPT,
    payload: { message, title, type },
  };
}

export function hideNotificationPrompt() {
  return {
    type: AT.HIDE_NOTIFICATION_PROMPT,
  };
}

export function showNotificationTextMeTheApp() {
  return {
    type: AT.SHOW_NOTIFICATION_TEXT_ME_THE_APP,
  };
}

export function hideNotificationTextMeTheApp() {
  return {
    type: AT.HIDE_NOTIFICATION_TEXT_ME_THE_APP,
  };
}

export function showSnackBar({
  message,
  position,
  containerStyle,
  snackBarStyle,
  textStyle,
  duration,
  iconName,
  iconProps,
  actionButton,
  animationProps,
}) {
  return {
    type: AT.SHOW_SNACKBAR,
    payload: {
      message,
      position,
      containerStyle,
      snackBarStyle,
      textStyle,
      duration,
      iconName,
      iconProps,
      actionButton,
      animationProps,
    },
  };
}

export function hideSnackBar() {
  return {
    type: AT.HIDE_SNACKBAR,
  };
}

export function showToast({
  message,
  position,
  duration,
  containerStyle,
  toastStyle,
  textStyle,
}) {
  return {
    type: AT.SHOW_TOAST,
    payload: {
      message,
      position,
      containerStyle,
      toastStyle,
      textStyle,
      duration,
    },
  };
}

export function hideToast() {
  return {
    type: AT.HIDE_TOAST,
  };
}

export function dontShowPromptOnError(action) {
  if (typeof action === 'function') {
    // handle if action is a function (dispatch, getState) => {}
    return (dispatch, getState) =>
      action(
        // wrap dispatch param with dontShowPromptOnError (it will run recursively until action is not a function)
        (actionType) => dispatch(dontShowPromptOnError(actionType)),
        getState,
      );
  }

  return {
    ...action,
    metadata: {
      ...(action.metadata || {}),
      showPromptOnError: false,
    },
  };
}

export default notificationReducer;
