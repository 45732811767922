/* eslint-disable camelcase */

/* global gtag */
import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

const ga4 = (
  eventType: string,
  eventName: string,
  eventData: Record<string, any>,
) => {
  gtag(eventType, eventName, {
    ...eventData,
    event_platform: getPlatform(),
  });
};

export default ga4;
