import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ExpandMore, LocationOn } from '@dkrm/icons';
import { StyleSheet, Text } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { ButtonWrapper, ModalEnhanced } from '@dkrm/ui-kit-basic/v2';

import { useQueries } from 'app-libs/hook_modules/router';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';
import {
  getCurrentLocation,
  getIsCurrentLocationSet,
} from 'app-libs/redux_modules/entity_modules/location/selectors';
import {
  getCustomerMainShippingAddress,
  isShippingAddressLoaded as getIsCustomerShippingAddressLoaded,
  getIsCustomerShippingAddressLoading,
  getIsLoadCustomerShippingAddressError,
} from 'app-libs/redux_modules/entity_modules/selectors/customer';
import {
  isLoggedIn as getIsLoggedIn,
  getIsUserOrderOnBehalf,
} from 'app-libs/redux_modules/selectors/auth';

import { ViewStyle } from 'types/styles';

import LocationModal from './LocationModal';
import {
  genLocationName,
  useLoadUserAddressesEffect,
  useSetAnonymousOrOnBehalfUserLocationEffect,
  useSetLocationFromURLEffect,
  useSetLoggedUserLocationEffect,
} from './utils';

interface LocationNavigationProps {
  containerStyle?: ViewStyle | null;
}

const LocationNavigation: React.FC<LocationNavigationProps> = memo(
  ({ containerStyle = null }) => {
    const { isRedirectedFromLogin } = useQueries();

    const [
      shouldOpenLocationModal,
      actionOpenLocationModal,
      actionCloseLocationModal,
    ] = useVisibility();

    const currentLocation = useSelector(getCurrentLocation);
    const isLoggedIn: boolean = useSelector(getIsLoggedIn);
    const isUserOrderOnBehalf: boolean = useSelector(getIsUserOrderOnBehalf);
    const isCurrentLocationSet: boolean = useSelector(getIsCurrentLocationSet);
    const isShippingAddressLoading = useSelector(
      getIsCustomerShippingAddressLoading,
    );
    const isShippingAddressLoaded = useSelector(
      getIsCustomerShippingAddressLoaded,
    );
    const mainShippingAddress = useSelector<
      void,
      UserAddressResponseFromGoblin
    >(getCustomerMainShippingAddress);
    const loadCustomerShippingAddressError = useSelector(
      getIsLoadCustomerShippingAddressError,
    );

    useEffect(() => {
      if (
        isLoggedIn &&
        isRedirectedFromLogin === 'true' &&
        !isCurrentLocationSet &&
        !isShippingAddressLoading &&
        !mainShippingAddress &&
        (isShippingAddressLoaded || !!loadCustomerShippingAddressError) &&
        !isUserOrderOnBehalf
      ) {
        actionOpenLocationModal();
      }
    }, [
      actionOpenLocationModal,
      isLoggedIn,
      isRedirectedFromLogin,
      isCurrentLocationSet,
      isUserOrderOnBehalf,
      isShippingAddressLoading,
      isShippingAddressLoaded,
      mainShippingAddress,
      loadCustomerShippingAddressError,
    ]);

    useLoadUserAddressesEffect();
    useSetLoggedUserLocationEffect();

    useSetAnonymousOrOnBehalfUserLocationEffect();

    useSetLocationFromURLEffect();

    return (
      <>
        {shouldOpenLocationModal && (
          <ModalEnhanced isVisible style={s.modal}>
            <LocationModal
              actionCloseLocationModal={actionCloseLocationModal}
              shouldShowCloseButton={
                isRedirectedFromLogin !== 'true' ||
                isCurrentLocationSet ||
                isUserOrderOnBehalf
              }
            />
          </ModalEnhanced>
        )}
        <ButtonWrapper
          onPress={actionOpenLocationModal}
          style={s.touchableContainer}
          hoverableViewStyle={[s.hoverableContainer, containerStyle]}
        >
          {() => (
            <>
              <LocationOn fill={colors.C_NEUTRAL_100} size={24} />
              <Text theme="body4-black80" style={cStyles.mhm}>
                dikirim ke{' '}
                <Text theme="h6-black80">
                  {genLocationName(currentLocation)}
                </Text>
              </Text>
              <ExpandMore theme="black80" size={16} />
            </>
          )}
        </ButtonWrapper>
      </>
    );
  },
);

const s = StyleSheet.create({
  touchableContainer: {
    ...cStyles.flexDirRow,
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  hoverableContainer: {
    ...cStyles.bgw,
    ...cStyles.pbm,
    ...cStyles.phxxl,
  },
  modal: {
    ...cStyles.bgw,
    margin: 0,
    width: '100vw',
    height: '100vh',
  },
});

export default LocationNavigation;
