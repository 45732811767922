/* eslint-disable camelcase */
import keyMirror from 'keymirror';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { normalize } from 'normalizr';

import config from 'config';

import { generateGuid } from 'app-libs/etc';
import {
  genDekononymousEmail,
  getIsUserOrderOnBehalfFromUserObject,
} from 'app-libs/etc/authHelper';
import { getCreditCardBin } from 'app-libs/etc/paymentHelper';
import { K_SHOULD_USE_REBATE } from 'app-libs/etc/rebateLogic';
import { getAuthUser } from 'app-libs/redux_modules/selectors/auth';

import { K_BANK_MANDIRI } from 'constants/bankConstants';
import {
  INSTANT_PAYMENT_METHODS,
  K_E_WALLET_GOPAY_VALUE,
  K_INSTALLMENT_KREDIVO_VALUE,
  K_INTERNET_BANKING_BCA_KLIKPAY_VALUE,
  PAYMENT_METHODS,
} from 'constants/paymentConstants';
import { K_PARTNER_ID_DEKORUMA_MARKETING } from 'constants/productConstants';
import { K_VOUCHER_TYPE_PRODUCT } from 'constants/promoConstants';

import { AT as AT_AUTH } from '../../auth';
import { Schemas } from '../../entities';
import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';
import {
  getBasketAdditionalProductDiscount,
  getBasketAdditionalShippingDiscount,
} from '../selectors/basket';

export const K_SHIPPING_CLASS_AND_WEIGHT_BASED_SHIPPING =
  'shipping-class-and-weight-based-shipping';
export const K_SHIPPING_CLASS_BASED_SHIPPING = 'shipping-class-based-shipping';
export const K_WEIGHT_BASED_SHIPPING = 'weight-based-shipping';
export const K_SELECT_CREDIT_CARD = 'select-credit-card';
export const K_ADD_NEW_CREDIT_CARD = 'add-new-credit-card';

export const K_CONDITION_TYPE_CC_BANK = 'Credit Card Bank';

const K_PROMO_CODE_TYPE_REFERRAL = 'referral';

export { PAYMENT_METHODS };

export const AT = keyMirror({
  GET_BASKET: null,
  GET_BASKET_SUCCESS: null,
  GET_BASKET_FAIL: null,

  UPDATE_BASKET_PAYMENT_METHOD: null,
  UPDATE_BASKET_SHIPPING_ADDRESS: null,
  RESET_BASKET_SHIPPING_ADDRESS: null,

  LOAD_BASKET_SHIPPING_METHOD: null,
  LOAD_BASKET_SHIPPING_METHOD_SUCCESS: null,
  LOAD_BASKET_SHIPPING_METHOD_FAIL: null,

  CREATE_BASKET_LINE_ITEM: null,
  CREATE_BASKET_LINE_ITEM_SUCCESS: null,
  CREATE_BASKET_LINE_ITEM_FAIL: null,

  CREATE_BASKET_LINE_ITEMS: null,
  CREATE_BASKET_LINE_ITEMS_SUCCESS: null,
  CREATE_BASKET_LINE_ITEMS_FAIL: null,

  CREATE_BASKET_LINE_BUNDLE_ITEMS: null,
  CREATE_BASKET_LINE_BUNDLE_ITEMS_SUCCESS: null,
  CREATE_BASKET_LINE_BUNDLE_ITEMS_FAIL: null,

  UPDATE_BASKET_LINE_ITEM_QUANTITY: null,
  UPDATE_BASKET_LINE_ITEM_QUANTITY_SUCCESS: null,
  UPDATE_BASKET_LINE_ITEM_QUANTITY_FAIL: null,

  BULK_UPDATE_BASKET_LINE_ITEM_SHIPPING_TIER: null,
  BULK_UPDATE_BASKET_LINE_ITEM_SHIPPING_TIER_SUCCESS: null,
  BULK_UPDATE_BASKET_LINE_ITEM_SHIPPING_TIER_FAIL: null,

  UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL: null,
  UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL_SUCCESS: null,
  UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL_FAIL: null,

  UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE: null,
  UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE_SUCCESS: null,
  UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE_FAIL: null,

  UPDATE_BASKET_LINE_ITEM_NOTE: null,
  UPDATE_BASKET_LINE_ITEM_NOTE_SUCCESS: null,
  UPDATE_BASKET_LINE_ITEM_NOTE_FAIL: null,

  REMOVE_BASKET_LINE_ITEM: null,
  REMOVE_BASKET_LINE_ITEM_SUCCESS: null,
  REMOVE_BASKET_LINE_ITEM_FAIL: null,

  REMOVE_BASKET_LINE_ITEM_BY_LINE_ID: null,
  REMOVE_BASKET_LINE_ITEM_BY_LINE_ID_SUCCESS: null,
  REMOVE_BASKET_LINE_ITEM_BY_LINE_ID_FAIL: null,

  REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID: null,
  REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID_SUCCESS: null,
  REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID_FAIL: null,

  CHECKOUT_BASKET: null,
  CHECKOUT_BASKET_SUCCESS: null,
  CHECKOUT_BASKET_FAIL: null,

  HEAD_BASKET: null,
  HEAD_BASKET_SUCCESS: null,
  HEAD_BASKET_FAIL: null,

  APPLY_BASKET_VOUCHER: null,
  APPLY_BASKET_VOUCHER_SUCCESSS: null,
  APPLY_BASKET_VOUCHER_FAIL: null,

  REMOVE_BASKET_VOUCHER: null,
  REMOVE_BASKET_VOUCHER_SUCCESSS: null,
  REMOVE_BASKET_VOUCHER_FAIL: null,

  ADD_NEW_CREDIT_CARD: null,
  DELETE_NEW_CREDIT_CARD: null,
  SELECT_CREDIT_CARD: null,
  FILL_CREDIT_CARD_INFO: null,
  SET_CREDIT_CARD_SECURE_TOKEN: null,
  UPDATE_CREDIT_CARD_INSTALLMENT_TERM: null,
  UPDATE_CREDIT_CARD_INSTALLMENT_BANK: null,

  UPDATE_E_WALLET: null,
  UPDATE_INTERNET_BANKING: null,

  UPDATE_INSTALLMENT_WITHOUT_CARD: null,
  UPDATE_INSTALLMENT_WITHOUT_CARD_TERM: null,

  RETRIEVE_PAYMENT_ROUTE: null,
  RETRIEVE_PAYMENT_ROUTE_SUCCESS: null,
  RETRIEVE_PAYMENT_ROUTE_FAIL: null,

  ALLOW_CREDIT_CARD_INSTALLMENT_CHECKOUT: null,
  DISALLOW_CREDIT_CARD_INSTALLMENT_CHECKOUT: null,

  SET_INSTALLATION_TIME_SLOT: null,
  SET_IS_BASKET_UPDATING_SHIPPING_ADDRESS: null,

  UPDATE_SHIPPING_METHOD_DATA: null,
  UPDATE_SHIPPING_METHOD_ERROR_DATA: null,

  UPDATE_BASKET_LINE_SHIPPING_TIER_DATA: null,

  SET_BASKET_DATA: null,
});

export const initialState = {
  asyncState: initialAsyncState,
  lines: [], // each line item has {item, quantity}
  shippingAddress: {
    id: null,
    firstName: null,
    lastName: null,
    line1: null,
    line3: null, // district
    line4: null, // city
    state: null, // province
    phoneNumber: null,
    postcode: null,
  },
  applyVoucher: { shipping: false, product: false },
  applyVoucherError: { shipping: null, product: null },
  basketShippingAddress: null,
  removeVoucher: { shipping: false, product: false },
  removeVoucherError: { shipping: null, product: null },
  shippingMethod: {
    code: 'no-shipping-required',
    name: 'No shipping required',
    price: {
      exclTax: 0,
      inclTax: 0,
    },
    priceBeforeDiscount: {
      exclTax: 0,
      inclTax: 0,
    },
    error: null,
    loading: false,
    loaded: false,
    unshippableItems: [],
    priceDetail: {},
  },
  vouchers: [],
  voucherDiscounts: [],
  offerDiscounts: [],
  paymentMethod: PAYMENT_METHODS.BANK_TRANSFER, // oneOf(Object.keys(PAYMENT_METHODS)
  creditCardSelectionValue: K_SELECT_CREDIT_CARD,
  selectedCreditCard: {},
  selectedWallet: K_E_WALLET_GOPAY_VALUE,
  selectedInternetBanking: K_INTERNET_BANKING_BCA_KLIKPAY_VALUE,
  selectedInstallmentWithoutCard: K_INSTALLMENT_KREDIVO_VALUE,
  newCreditCard: {},
  creditCardInstallmentTerm: 0,
  installmentWithoutCardTerm: null,
  previousCreditCardInstallmentTerm: 3,
  creditCardInstallmentBank: K_BANK_MANDIRI,
  isApplyingCreditCard: false,
  isRemovingCreditCard: false,
  isUpdatingAppliedCreditCardInstallmentTerm: false,
  isBasketUpdatingShippingAddress: false,

  retrievingPaymentRoute: false,
  paymentRoute: {
    bin: null,
    issuingBank: null,
    acquiringBankFull: null,
    acquiringBankInstallment: null,
    installmentTerms: null,
  },
  allowCreditCardInstallment: true,
  shouldPickUpInHub: false,
  pickUpContact: {
    name: '',
    email: '',
    phoneNumber: '',
  },
  productRecommendations: {
    asyncState: initialAsyncState,
    result: [],
  },

  referralCode: null,
  referralCommission: 0,

  receiveDeliveryRequestDateByPartnerId: {},
  installationTimeSlotsByPartnerId: {},

  additionalValues: [],
  additionalProductDiscount: 0,
  additionalShippingDiscount: 0,
  userUuid: null,
};

const onApplyBasketVoucherSuccess = (state, action) => {
  const voucherType = get(action, 'payload, voucherType');
  const newState = {
    ...state,
    applyVoucher: {
      ...state.applyVoucher,
      [voucherType]: false,
    },
    asyncState: {
      ...state.asyncState,
      applyingVoucher: false,
    },
  };
  const promoCodeType = get(action, 'result.promoCodeType');

  if (promoCodeType === K_PROMO_CODE_TYPE_REFERRAL) {
    return {
      ...newState,
      referralCode: get(action, 'result.referralCode'),
      referralCommission: get(
        action,
        'result.commissionDetails.totalCommission',
        0,
      ),
    };
  }

  const { giftWithPurchaseLine, ...rest } = action.result;
  const { lines } = state;
  let newLines = [];

  newLines = lines.filter(
    (line) => line.product.partner.id !== K_PARTNER_ID_DEKORUMA_MARKETING,
  );
  if (giftWithPurchaseLine) {
    newLines = [...newLines, giftWithPurchaseLine];
  }

  return {
    ...newState,
    lines: newLines,
    ...rest,
  };
};

/**
 * Main Reducer
 */
export default function basketReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  const voucherType = get(action, 'payload.voucherType', '');

  switch (action.type) {
    case AT_AUTH.LOGOUT_SUCCESS: // reset upon logout
      state = initialState;
      break;
    /**
     * Connection to API, replace when received. always replace. thus local is only optimistic update
     * @todo: Racing condition. When changes 1 > optimistic 1 > changes 2 > optimistic 2 > response 1 > response 2
     */
    case AT.ALLOW_CREDIT_CARD_INSTALLMENT_CHECKOUT:
      state = {
        ...state,
        allowCreditCardInstallment: true,
      };
      break;
    case AT.DISALLOW_CREDIT_CARD_INSTALLMENT_CHECKOUT:
      state = {
        ...state,
        allowCreditCardInstallment: false,
      };
      break;
    case AT.RETRIEVE_PAYMENT_ROUTE:
      state = {
        ...state,
        retrievingPaymentRoute: true,
      };
      break;
    case AT.RETRIEVE_PAYMENT_ROUTE_SUCCESS:
      state = {
        ...state,
        retrievingPaymentRoute: false,
        paymentRoute: action.result,
      };
      break;
    case AT.RETRIEVE_PAYMENT_ROUTE_FAIL:
      state = {
        ...state,
        retrievingPaymentRoute: false,
      };
      break;
    case AT.GET_BASKET_SUCCESS: {
      const { customerIdentifier, basketShippingAddress } = action.result;
      /**
       * @note (Varian): This is a tech debt. In the past, everything stored inside redux, including address.
       * customer address stored inside a field called shippingAddress in redux, and this field
       * used at many places. Now there is a need to store address at the backend
       *
       * Instead of placing if everywhere and potentially breaking things,
       * set the address to use at this place.
       *
       * extra note on customerIdentifier, it is used in the if condition to handle offline store usage, ex:
       * 1. SDA x serve customer 1, the address is Jakarta Barat
       * 2. SDA x change the basket into customer 2, customer 2 doesn't have address yet
       *
       * expected: when sda change into customer 2, the shipping address must empty if
       * the basketShippingAddress empty (that means this customer haven't filled the address yet)
       */
      if (basketShippingAddress || customerIdentifier) {
        state = {
          ...state,
          ...action.result,
          shippingAddress: {
            firstName: basketShippingAddress?.firstName ?? null,
            email: basketShippingAddress?.email ?? null,
            line1: basketShippingAddress?.line1 ?? null,
            line3: basketShippingAddress?.line3 ?? null,
            line4: basketShippingAddress?.line4 ?? null,
            state: basketShippingAddress?.state ?? null,
            phoneNumber: basketShippingAddress?.phoneNumber ?? null,
            postcode: basketShippingAddress?.postcode ?? null,
          },
        };
      } else {
        state = {
          ...state,
          ...action.result,
        };
      }
      if (action.result.userUuid) {
        localStorage.setItem('anonymousUserUuid', action.result.userUuid);
      } else {
        localStorage.removeItem('anonymousUserUuid');
      }
      break;
    }
    case AT.CREATE_BASKET_LINE_ITEMS_FAIL:
    case AT.UPDATE_BASKET_LINE_ITEM_QUANTITY_FAIL:
    case AT.UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL_FAIL:
    case AT.UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE_FAIL:
    case AT.UPDATE_BASKET_LINE_ITEM_NOTE_FAIL:
    case AT.REMOVE_BASKET_LINE_ITEM_FAIL:
    case AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID_FAIL:
    case AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID_FAIL:
      state = {
        ...state,
        asyncState: {
          ...state.asyncState,
          updatingLineItem: false,
          updatingLineItemError: action.error,
        },
      };
      break;
    case AT.CREATE_BASKET_LINE_ITEMS_SUCCESS:
    case AT.CREATE_BASKET_LINE_ITEM_SUCCESS:
    case AT.CREATE_BASKET_LINE_BUNDLE_ITEMS_SUCCESS:
    case AT.UPDATE_BASKET_LINE_ITEM_QUANTITY_SUCCESS:
    case AT.UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL_SUCCESS:
    case AT.UPDATE_BASKET_LINE_ITEM_NOTE_SUCCESS:
    case AT.REMOVE_BASKET_LINE_ITEM_SUCCESS:
    case AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID_SUCCESS:
    case AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID_SUCCESS:
      state = {
        ...state,
        ...action.result,
        asyncState: {
          ...state.asyncState,
          updatingLineItem: false,
        },
      };
      if (action.result.userUuid) {
        localStorage.setItem('anonymousUserUuid', action.result.userUuid);
      }
      break;
    case AT.SET_BASKET_DATA:
      state = {
        ...state,
        ...action.payload,
        asyncState: {
          ...state.asyncState,
          updatingLineItem: false,
        },
      };
      break;
    case AT.CHECKOUT_BASKET:
      state = {
        ...state,
        asyncState: {
          ...state.asyncState,
          checkouting: true,
        },
      };
      break;
    case AT.CHECKOUT_BASKET_FAIL:
      state = {
        ...state,
        asyncState: {
          ...state.asyncState,
          checkouting: false,
          checkoutingError: action.error,
        },
      };
      break;
    case AT.CHECKOUT_BASKET_SUCCESS:
      state = Object.assign({}, initialState);
      localStorage.removeItem('anonymousUserUuid');
      break;
    case AT.CREATE_BASKET_LINE_ITEM:
    case AT.CREATE_BASKET_LINE_BUNDLE_ITEMS:
    case AT.CREATE_BASKET_LINE_ITEM_FAIL:
    case AT.CREATE_BASKET_LINE_BUNDLE_ITEMS_FAIL:
    case AT.CREATE_BASKET_LINE_ITEMS:
    case AT.UPDATE_BASKET_LINE_ITEM_QUANTITY:
    case AT.UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL:
    case AT.UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE:
    case AT.UPDATE_BASKET_LINE_ITEM_NOTE:
    case AT.REMOVE_BASKET_LINE_ITEM:
    case AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID:
    case AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID:
      state = {
        ...state,
        asyncState: {
          ...state.asyncState,
          updatingLineItem: true,
        },
      };
      break;
    case AT.UPDATE_BASKET_SHIPPING_ADDRESS: {
      const newShippingAddress = action.payload.shippingAddress;
      state = {
        ...state,
        shippingAddress: Object.assign(
          {},
          initialState.shippingAddress,
          newShippingAddress,
        ),
      };
      break;
    }
    case AT.LOAD_BASKET_SHIPPING_METHOD:
      state = {
        ...state,
        shippingMethod: {
          ...state.shippingMethod,
          loading: true,
          loaded: false,
        },
      };
      break;
    case AT.LOAD_BASKET_SHIPPING_METHOD_SUCCESS: {
      const { shippingDetail, ...rest } = action.result;
      state = {
        ...state,
        shippingMethod: {
          ...shippingDetail,
          unshippableItems: [],
          loading: false,
          loaded: true,
          error: null,
        },
        ...rest,
      };
      break;
    }
    case AT.LOAD_BASKET_SHIPPING_METHOD_FAIL: {
      const errorMessage =
        action.error?.response?.body?.message ||
        'Terjadi kesalahan pada server, mohon coba lagi beberapa saat lagi';
      const errorCode = action.error?.response?.body?.code;
      const unshippableItems =
        errorCode === 'UNSHIPPABLE_ITEMS'
          ? action.error?.response?.body?.data
          : [];

      state = {
        ...state,
        shippingMethod: {
          ...state.shippingMethod,
          unshippableItems,
          loading: false,
          loaded: false,
          error: errorMessage,
        },
      };
      break;
    }
    case AT.UPDATE_SHIPPING_METHOD_DATA: {
      const { shippingDetail, ...rest } = action.payload.data;
      state = {
        ...state,
        shippingMethod: {
          ...shippingDetail,
          unshippableItems: [],
          loading: false,
          loaded: true,
          error: null,
        },
        ...rest,
      };
      break;
    }
    case AT.UPDATE_SHIPPING_METHOD_ERROR_DATA: {
      const errorMessage =
        action.payload.error?.response?.body?.message ||
        'Terjadi kesalahan pada server, mohon coba lagi beberapa saat lagi';
      const errorCode = action.payload.error?.response?.body?.code;
      const unshippableItems =
        errorCode === 'UNSHIPPABLE_ITEMS'
          ? action.payload.error?.response?.body?.data
          : [];

      state = {
        ...state,
        shippingMethod: {
          ...state.shippingMethod,
          unshippableItems,
          loading: false,
          loaded: false,
          error: errorMessage,
        },
      };
      break;
    }
    case AT.UPDATE_BASKET_PAYMENT_METHOD:
      state = {
        ...state,
        paymentMethod: action.payload,
      };
      break;
    case AT.APPLY_BASKET_VOUCHER:
      state = {
        ...state,
        ...action.payload,
        applyVoucher: {
          ...state.applyVoucher,
          [voucherType]: true,
        },
        applyVoucherError: {
          ...state.applyVoucher,
          [voucherType]: null,
        },
        asyncState: {
          ...state.asyncState,
          applyingVoucher: true,
        },
      };
      break;
    case AT.APPLY_BASKET_VOUCHER_SUCCESSS:
      state = onApplyBasketVoucherSuccess(state, action);
      break;
    case AT.APPLY_BASKET_VOUCHER_FAIL:
      state = {
        ...state,
        applyVoucher: {
          ...state.applyVoucher,
          [voucherType]: false,
        },
        applyVoucherError: {
          ...state.applyVoucher,
          [voucherType]: action.error,
        },
        asyncState: {
          ...state.asyncState,
          applyingVoucher: false,
        },
      };
      break;
    case AT.REMOVE_BASKET_VOUCHER:
      state = {
        ...state,
        ...action.payload,
        removeVoucher: {
          ...state.removeVoucher,
          [voucherType]: true,
        },
        removeVoucherError: {
          ...state.removeVoucher,
          [voucherType]: null,
        },
        asyncState: {
          ...state.asyncState,
          removingVoucher: true,
        },
        referralCode: null,
        referralCommission: 0,
      };
      break;
    case AT.REMOVE_BASKET_VOUCHER_SUCCESSS: {
      const { giftWithPurchaseLine, ...rest } = action.result;
      const { lines } = state;
      let newLines = [];

      newLines = lines.filter(
        (line) => line.product.partner.id !== K_PARTNER_ID_DEKORUMA_MARKETING,
      );
      if (giftWithPurchaseLine) {
        newLines = [...newLines, giftWithPurchaseLine];
      }

      state = {
        ...state,
        ...rest,
        lines: newLines,
        removeVoucher: {
          ...state.removeVoucher,
          [voucherType]: false,
        },
        asyncState: {
          ...state.asyncState,
          removingVoucher: false,
        },
      };
      break;
    }
    case AT.REMOVE_BASKET_VOUCHER_FAIL:
      state = {
        ...state,
        ...action.payload,
        removeVoucher: {
          ...state.removeVoucher,
          [voucherType]: false,
        },
        removeVoucherError: {
          ...state.removeVoucher,
          [voucherType]: action.error,
        },
        asyncState: {
          ...state.asyncState,
          removingVoucher: false,
        },
      };
      break;
    case AT.ADD_NEW_CREDIT_CARD:
      state = {
        ...state,
        creditCardSelectionValue: K_ADD_NEW_CREDIT_CARD,
        selectedCreditCard: {},
      };
      break;
    case AT.DELETE_NEW_CREDIT_CARD:
      state = {
        ...state,
        newCreditCard: {},
      };
      break;
    case AT.SELECT_CREDIT_CARD: {
      const creditCard = action.payload;
      const { initialToken, ...creditCardWithoutInitialToken } = creditCard;
      const _selectedCreditCard = {
        ...creditCardWithoutInitialToken,
        tokenizedCreditCard: initialToken,
      };
      const {
        issuingBank,
        acquiringBankFull,
        acquiringBankInstallment,
        isAcquiringBankFullMigs,
        isAcquiringBankInstallmentMigs,
      } = creditCard;
      state = {
        ...state,
        creditCardSelectionValue: JSON.stringify(creditCard),
        newCreditCard: {},
        selectedCreditCard: _selectedCreditCard,
        paymentRoute: {
          bin: getCreditCardBin(initialToken),
          issuingBank,
          acquiringBankFull,
          acquiringBankInstallment,
          isAcquiringBankFullMigs,
          isAcquiringBankInstallmentMigs,
          isInstallmentEnabled: creditCard?.isInstallmentEnabled,
        },
      };
      break;
    }
    case AT.FILL_CREDIT_CARD_INFO: {
      const { attr } = action.payload;
      const newCreditCardData = state.newCreditCard;
      newCreditCardData[attr] = action.payload.value;
      state = {
        ...state,
        newCreditCard: newCreditCardData,
      };
      break;
    }
    case AT.SET_CREDIT_CARD_SECURE_TOKEN: {
      const selectedCreditCardData = state.selectedCreditCard;
      selectedCreditCardData.secureToken = action.payload;
      state = {
        ...state,
        selectedCreditCard: selectedCreditCardData,
      };
      break;
    }
    case AT.UPDATE_CREDIT_CARD_INSTALLMENT_TERM: {
      const previousCreditCardInstallmentTerm = action.payload
        ? action.payload
        : state.previousCreditCardInstallmentTerm;
      state = {
        ...state,
        creditCardInstallmentTerm: action.payload,
        previousCreditCardInstallmentTerm,
      };
      break;
    }
    case AT.UPDATE_CREDIT_CARD_INSTALLMENT_BANK: {
      const { bankConstant, shouldResetCreditCardSelectionValue } =
        action.payload;
      let {
        creditCardSelectionValue,
        paymentRoute,
        newCreditCard,
        selectedCreditCard,
      } = state;

      if (shouldResetCreditCardSelectionValue) {
        creditCardSelectionValue = K_SELECT_CREDIT_CARD;
        paymentRoute = { ...initialState.paymentRoute };
        newCreditCard = { ...initialState.newCreditCard };
        selectedCreditCard = { ...initialState.selectedCreditCard };
      }
      state = {
        ...state,
        creditCardSelectionValue,
        creditCardInstallmentBank: bankConstant,
        paymentRoute,
        newCreditCard,
        selectedCreditCard,
      };
      break;
    }
    case AT.UPDATE_E_WALLET: {
      const { wallet } = action.payload;

      state = {
        ...state,
        selectedWallet: wallet,
      };
      break;
    }
    case AT.UPDATE_INTERNET_BANKING: {
      const { internetBanking } = action.payload;

      state = {
        ...state,
        selectedInternetBanking: internetBanking,
      };
      break;
    }
    case AT.UPDATE_INSTALLMENT_WITHOUT_CARD: {
      const { installmentAgent } = action.payload;

      state = {
        ...state,
        selectedInstallmentWithoutCard: installmentAgent,
      };
      break;
    }
    case AT.UPDATE_INSTALLMENT_WITHOUT_CARD_TERM: {
      const { term } = action.payload;

      state = {
        ...state,
        installmentWithoutCardTerm: term,
      };
      break;
    }
    case AT.SET_INSTALLATION_TIME_SLOT:
      state = {
        ...state,
        installationTimeSlotsByPartnerId: {
          ...state.installationTimeSlotsByPartnerId,
          [action.payload.partnerId]: {
            dateStr: action.payload.installationDateStr,
            shiftSlug: action.payload.installationShiftSlug,
          },
        },
      };
      break;
    case AT.SET_IS_BASKET_UPDATING_SHIPPING_ADDRESS:
      state = {
        ...state,
        isBasketUpdatingShippingAddress:
          action.payload.isBasketUpdatingShippingAddress,
      };
      break;
    default:
      break;
  }

  // return state;
  /** Track async state, with limit to **_BASKET_* */
  state = { ...state, lines: basketLinesReducer(state.lines, action) };
  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_BASKET_'),
  });
}

/**
 * Sub Reducer - it reduce @property {Array} lines
 */
export function basketLinesReducer(
  mutableState = initialState.lines,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.CREATE_BASKET_LINE_ITEMS:
      state = [...state, ...action.payload];
      break;
    case AT.CREATE_BASKET_LINE_ITEM:
      state = [
        ...state,
        {
          product: {
            ...action.payload.product,
            stockRecordId:
              action.payload.stockRecordId ??
              action.payload.product.stockRecordId,
          },
          quantity: action.payload.quantity,
          optionValues: action.payload.optionValues,
          addonProducts: action.payload.addonProducts,
          uuid: action.payload.uuid,
        },
      ];
      break;
    case AT.CREATE_BASKET_LINE_ITEM_FAIL:
      {
        const {
          payload: { stockRecordId: stockRecordIdToBeReverted },
        } = action;
        const indexBasketLineToBeRemoved = state.findIndex(
          (lineItem) =>
            lineItem.product.stockRecordId === stockRecordIdToBeReverted,
        );
        if (indexBasketLineToBeRemoved >= 0) {
          state = [
            ...state.slice(0, indexBasketLineToBeRemoved),
            ...state.slice(indexBasketLineToBeRemoved + 1),
          ];
        }
      }
      break;
    case AT.UPDATE_BASKET_LINE_ITEM_QUANTITY:
      state = state.map((lineItem) => {
        if (lineItem.uuid === action.payload.uuid) {
          return {
            ...lineItem,
            product: {
              ...lineItem.product,
              prevStockRecordId: action.payload.prevStockRecordId,
              stockRecordId: action.payload.stockRecordId,
            },
            quantity: action.payload.quantity ?? lineItem.quantity,
            prevQuantity: lineItem.quantity,
          };
        }
        return lineItem;
      });
      break;
    case AT.UPDATE_BASKET_LINE_ITEM_NEXT_DAY_ARRIVAL:
      {
        const { isNextDayArrival, id } = action.payload;
        state = state.map((lineItem) => {
          if (lineItem.id === id) {
            return {
              ...lineItem,
              isNextDayArrival,
              isPickupInStore: false,
            };
          }
          return lineItem;
        });
      }
      break;
    case AT.UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE:
      {
        const { isPickupInStore, id } = action.payload;
        state = state.map((lineItem) => {
          if (lineItem.id === id) {
            return {
              ...lineItem,
              isPickupInStore,
              isNextDayArrival: false,
            };
          }
          return lineItem;
        });
      }
      break;
    case AT.UPDATE_BASKET_LINE_ITEM_NOTE:
      {
        const newNote = action.payload.note;
        state = state.map((lineItem) => {
          if (isEqual(lineItem.id, action.payload.id)) {
            return {
              ...lineItem,
              note: newNote,
            };
          }
          return lineItem;
        });
      }
      break;
    case AT.UPDATE_BASKET_LINE_SHIPPING_TIER_DATA: {
      const lineIdsAndItsShippingTiers = action.payload.data;
      const shippingTierByLineId = {};
      lineIdsAndItsShippingTiers.forEach(({ id, shippingTier }) => {
        shippingTierByLineId[id] = shippingTier;
      });

      state = state.map((line) => ({
        ...line,
        shippingTier: shippingTierByLineId[line.id] || null,
      }));

      break;
    }
    case AT.REMOVE_BASKET_LINE_ITEM: {
      const objectIDToBeRemoved = action.payload;
      const indexToBeRemoved = state.findIndex(
        (lineItem) => lineItem.product.objectID === objectIDToBeRemoved,
      );
      if (indexToBeRemoved >= 0) {
        state = [
          ...state.slice(0, indexToBeRemoved),
          ...state.slice(indexToBeRemoved + 1),
        ];
      }
      break;
    }
    case AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID: {
      const basketLineIdToBeRemoved = action.payload;
      const indexBasketLineToBeRemoved = state.findIndex(
        (lineItem) => lineItem.id === basketLineIdToBeRemoved,
      );
      if (indexBasketLineToBeRemoved >= 0) {
        state = [
          ...state.slice(0, indexBasketLineToBeRemoved),
          ...state.slice(indexBasketLineToBeRemoved + 1),
        ];
      }
      break;
    }
    case AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID: {
      const billOfMaterialStockRecordIdToRemoved = action.payload;
      state = state.filter(
        (basketLine) =>
          !basketLine.billOfMaterialStockRecordId ||
          basketLine.billOfMaterialStockRecordId !==
            billOfMaterialStockRecordIdToRemoved,
      );
      break;
    }
    default:
      break;
  }
  return state;
}

/**
 * This action creator telling to `create` a new object in the shopping cart
 * @note: create, instead of `add` because it is more declarative, as `add` has multiple understanding
 * in case when there exist the same item, but whether should add the same item, org add quantity
 *
 * We can specify stockRecordId to specify buying same product from non-default suppliers
 */

export function allowCreditCardInstallment() {
  return {
    type: AT.ALLOW_CREDIT_CARD_INSTALLMENT_CHECKOUT,
  };
}

export function disallowCreditCardInstallment() {
  return {
    type: AT.DISALLOW_CREDIT_CARD_INSTALLMENT_CHECKOUT,
  };
}

export function retrievePaymentRoute(bin, callback = null) {
  return {
    types: [
      AT.RETRIEVE_PAYMENT_ROUTE,
      AT.RETRIEVE_PAYMENT_ROUTE_SUCCESS,
      AT.RETRIEVE_PAYMENT_ROUTE_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/payment/credit-card/bins/${bin}/?format=json`,
      ),
    payload: {
      bin,
    },
    options: {
      callback,
    },
  };
}

export function createBasketLineItem(
  product,
  quantity = 1,
  stockRecordId = null,
  optionValues = [],
  addonProducts = [],
  isPickupInStore = false,
  isFromBoughtTogether = false,
  addToCartReferenceNote = null,
  addToCartReference = null,
  note = '',
  uuid = null,
) {
  return {
    types: [
      AT.CREATE_BASKET_LINE_ITEM,
      AT.CREATE_BASKET_LINE_ITEM_SUCCESS,
      AT.CREATE_BASKET_LINE_ITEM_FAIL,
    ],
    payload: {
      id: generateGuid(),
      product,
      quantity,
      stockRecordId,
      optionValues,
      addonProducts,
      isFromBoughtTogether,
      addToCartReferenceNote,
      addToCartReference,
      uuid,
    },
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/basket-v2/add-product/?format=json`,
        {
          data: {
            upc: product.objectID,
            isFromBoughtTogether,
            quantity,
            stockRecordId,
            optionValues,
            addonProducts,
            isPickupInStore,
            addToCartReferenceNote,
            addToCartReference,
            note,
            uuid,
          },
        },
      ),
  };
}

export function createBasketLineBundleItems({
  bundleType,
  bundleOfferStockRecordId,
  quantity,
  bundleProducts,
}) {
  return {
    types: [
      AT.CREATE_BASKET_LINE_BUNDLE_ITEMS,
      AT.CREATE_BASKET_LINE_BUNDLE_ITEMS_SUCCESS,
      AT.CREATE_BASKET_LINE_BUNDLE_ITEMS_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/basket-v2/bundle-products/?format=json`,
        {
          data: {
            bundleType,
            bundleOfferStockRecordId,
            quantity,
            bundleProducts,
          },
        },
      ),
  };
}

export function applyBasketVoucher(
  voucherCode,
  isApplyFromApp,
  paymentMethod,
  shippingAddress = {},
  voucherType = K_VOUCHER_TYPE_PRODUCT,
) {
  const address = {
    ...shippingAddress,
    country: `${config.API_URL_GOBLIN}/countries/ID/`,
  };
  if (address.lastName === null) {
    address.lastName = '';
  }
  return {
    types: [
      AT.APPLY_BASKET_VOUCHER,
      AT.APPLY_BASKET_VOUCHER_SUCCESSS,
      AT.APPLY_BASKET_VOUCHER_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/basket-v2/add-voucher/?format=json`,
        {
          data: {
            voucherCode,
            isApplyFromApp,
            paymentMethod,
            voucherType,
            shippingAddress: address,
          },
        },
      ),
    payload: { voucherType, address },
  };
}

export function removeBasketVoucher(
  voucherCode,
  voucherType = K_VOUCHER_TYPE_PRODUCT,
) {
  return {
    types: [
      AT.REMOVE_BASKET_VOUCHER,
      AT.REMOVE_BASKET_VOUCHER_SUCCESSS,
      AT.REMOVE_BASKET_VOUCHER_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/basket-v2/remove-voucher/?format=json`,
        {
          data: { voucherCode },
        },
      ),
    payload: { voucherType },
  };
}

export function removeBasketVouchers(voucherCodes) {
  return (dispatch) => {
    return Promise.all(
      voucherCodes.map((voucherCode) =>
        dispatch(removeBasketVoucher(voucherCode)),
      ),
    );
  };
}

/**
 * This action creator telling to `create` a new object in the shopping cart
 * @note: create, instead of `add` because it is more declarative, as `add` has multiple understanding
 * in case when there exist the same item, but whether should add the same item, org add quantity
 * @param: productsWithQuantities [{product:product, quantity:3}]
 */
export function createBasketLineItems(productsWithQuantities) {
  const apiData = productsWithQuantities.map((pwq) => ({
    upc: pwq.product.objectID,
    quantity: pwq.quantity,
    stockRecordId: pwq.stockRecordId || null,
    optionValues: pwq.optionValues || [],
    addonProducts: pwq.addonProducts || [],
  }));

  return {
    types: [
      AT.CREATE_BASKET_LINE_ITEMS,
      AT.CREATE_BASKET_LINE_ITEMS_SUCCESS,
      AT.CREATE_BASKET_LINE_ITEMS_FAIL,
    ],
    payload: productsWithQuantities,
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/basket/add-products/?format=json`, {
        data: apiData,
      }),
  };
}

export function updateBasketLineItemQuantity({
  productObjectID,
  newQuantity,
  stockRecordId = null,
  optionValues = [],
  addonProducts = [],
  note = '',
  uuid = null,
  prevStockRecordId = null,
}) {
  return {
    types: [
      AT.UPDATE_BASKET_LINE_ITEM_QUANTITY,
      AT.UPDATE_BASKET_LINE_ITEM_QUANTITY_SUCCESS,
      AT.UPDATE_BASKET_LINE_ITEM_QUANTITY_FAIL,
    ],
    payload: {
      objectID: productObjectID,
      quantity: newQuantity,
      stockRecordId,
      optionValues,
      addonProducts,
      uuid,
      prevStockRecordId,
    },
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/basket-v2/add-product/?format=json`,
        {
          data: {
            upc: productObjectID,
            quantity: newQuantity,
            stockRecordId,
            optionValues,
            addonProducts,
            note,
            uuid,
          },
        },
      ),
  };
}

export function updateBasketLineItemPickupInStore(lineId, isPickupInStore) {
  return {
    types: [
      AT.UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE,
      AT.UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE_SUCCESS,
      AT.UPDATE_BASKET_LINE_ITEM_PICKUP_IN_STORE_FAIL,
    ],
    payload: {
      id: lineId,
      isPickupInStore,
    },
    promise: (client) =>
      client.patch(
        `${config.API_URL_GOBLIN}/basket/line/pickup-in-store/?format=json`,
        {
          data: {
            id: lineId,
            isPickupInStore,
          },
        },
      ),
  };
}

export function removeBasketLineItemByLineId(basketLineId) {
  return {
    types: [
      AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID,
      AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID_SUCCESS,
      AT.REMOVE_BASKET_LINE_ITEM_BY_LINE_ID_FAIL,
    ],
    payload: basketLineId,
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/basket-v2/delete-product-by-line-id/?format=json`,
        {
          data: {
            id: basketLineId,
          },
        },
      ),
  };
}

export function removeBasketLineItemsByBillOfMaterialStockRecordId(
  billOfMaterialStockRecordId,
) {
  return {
    types: [
      AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID,
      AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID_SUCCESS,
      AT.REMOVE_BASKET_LINE_ITEM_BY_BILL_OF_MATERIAL_STOCK_RECORD_ID_FAIL,
    ],
    payload: billOfMaterialStockRecordId,
    promise: (client) =>
      client.del(
        `${config.API_URL_GOBLIN}/basket-v2/bundle-products/${billOfMaterialStockRecordId}/?format=json`,
      ),
  };
}

export function loadBasket() {
  return {
    types: [AT.GET_BASKET, AT.GET_BASKET_SUCCESS, AT.GET_BASKET_FAIL],
    promise: (client) => {
      const isAnonymousUserUuidSet =
        !!__CLIENT__ && !!localStorage.getItem('anonymousUserUuid', null);
      let url = `${config.API_URL_GOBLIN}/basket-v2/`;
      if (isAnonymousUserUuidSet) {
        const anonymousUserUuid = localStorage.getItem('anonymousUserUuid');
        url += `?anonymous_user_uuid=${anonymousUserUuid}`;
      }
      return client.get(url);
    },
  };
}

export function headBasket(callback) {
  return {
    types: [AT.HEAD_BASKET, AT.HEAD_BASKET_SUCCESS, AT.HEAD_BASKET_FAIL],
    promise: (client) =>
      client.head(`${config.API_URL_GOBLIN}/basket-v2/?format=json`),
    options: {
      transformer: (result) => result,
      callback,
    },
  };
}

export function headBasketAndLoadIfExist() {
  return (dispatch) => {
    dispatch(
      headBasket((success, err) => {
        if (!err) {
          return dispatch(loadBasket());
        }
        return null;
      }),
    );
  };
}

export function isBasketLoaded(globalState) {
  return globalState.basket.asyncState.loaded || false;
}

/**
 *  Checking out sample data:
 *
 *  {
    "basket": "http://localhost:8000/api/v2/baskets/4/",
      "guestEmail": "arunaharsa@gmail.com",
      "total": 128200.00,
      "paymentMethodCode": "BANK_TRANSFER",
      "shippingCharge": {
        "currency": "IDR",
        "exclTax": 128000.00,
        "tax": "0"
      },
    "shipping_method_code": "weight-based-shipping",
    "shipping_address": {
      "country": "http://localhost:8000/api/v2/countries/ID/",
      "firstName": "Aru",
      "lastName": "Pinter",
      "line1": "Graha Kencana",
      "line2": "",
      "line3": "",
      "line4": "line4",
      "notes": "wets",
      "phoneNumber": "+62 0812121212",
      "postcode": "7777KK",
      "state": "wtf state?",
      },
      "referralCode": "AH1234"
    }
 *
 */

function genPaymentSpecificData(basket) {
  const { paymentMethod } = basket;
  if (paymentMethod === PAYMENT_METHODS.E_WALLET) {
    return {
      wallet: basket.selectedWallet.toLowerCase(),
    };
  }
  if (paymentMethod === PAYMENT_METHODS.INSTALLMENT_WITHOUT_CARD) {
    return {
      installmentAgent: basket.selectedInstallmentWithoutCard,
      installmentTerm: basket.installmentWithoutCardTerm,
    };
  }
  if (paymentMethod === PAYMENT_METHODS.INTERNET_BANKING) {
    return {
      internetBanking: basket.selectedInternetBanking.toLowerCase(),
    };
  }
  return null;
}

function genCheckoutData(basket, user) {
  const data = {};

  const { firstName, phoneNumber } = basket.shippingAddress;
  const isLoggedIn = user && Object.keys(user).length > 0;
  const isUserOrderOnBehalf = getIsUserOrderOnBehalfFromUserObject(user);

  if (!isLoggedIn) {
    const guestEmail =
      basket.shippingAddress.email ||
      genDekononymousEmail(phoneNumber, firstName);
    data.guestEmail = guestEmail.toLowerCase();
  }

  if (isUserOrderOnBehalf) {
    const email =
      basket.shippingAddress.email ||
      genDekononymousEmail(phoneNumber, firstName);
    data.recipientContact = {
      name: basket.shippingAddress.firstName,
      email: email.toLowerCase(),
      phoneNumber: basket.shippingAddress.phoneNumber,
    };
  }
  /**
   * shippingMethod.price is a Price object (basket/types.ts):
   * {
   *   inclTax: string;
   *   exclTax: string;
   *   currency: string;
   *   tax: string;
   * }
   */
  data.shippingCharge = { ...basket.shippingMethod.price };
  data.serviceFee = basket.shippingMethod.serviceFee;
  data.shippingMethodCode = basket.shippingMethod.code;

  data.basket = basket.url;
  data.total = basket.total;
  if (data.total > 0) {
    data.paymentMethodCode = basket.paymentMethod;
  } else {
    data.paymentMethodCode = PAYMENT_METHODS.NONE;
  }

  data.paymentMethodAdditionalData = {};
  if (INSTANT_PAYMENT_METHODS.includes(basket.paymentMethod)) {
    data.paymentMethodAdditionalData = genPaymentSpecificData(basket);
  }

  if (basket.site) {
    data.site = basket.site;
  }
  /** rebate feature */

  data.shouldUseRebate = K_SHOULD_USE_REBATE;
  data.totalRebate = 0;
  /** end rebate feature */

  const address = Object.assign({}, basket.shippingAddress, {
    country: `${config.API_URL_GOBLIN}/countries/ID/`,
  });
  data.shouldPickUpInHub = basket.shouldPickUpInHub;
  if (basket.shouldPickUpInHub) {
    data.pickUpHubContact = basket.pickUpContact;
    data.shippingAddress = {
      firstName: data.pickUpHubContact.name,
      lastName: '',
      line1: 'Jl. Meruya Illir no. 8-E, Meruya Utara',
      line3: 'Kembangan',
      line4: 'Jakarata Barat',
      phoneNumber: data.pickUpHubContact.phoneNumber,
      postcode: '11630',
      state: 'DKI Jakarta',
    };
  } else {
    /** clean up address from empty values */
    Object.keys(address).map((key) => {
      if (!address[key]) delete address[key];
      return null;
    });
    data.shippingAddress = address;
  }

  /** Also send shipping price detail if available */
  if (!isEmpty(get(basket, 'shippingMethod.priceDetail'))) {
    data.shippingDetail = basket.shippingMethod.priceDetail;
  }

  data.referralCode = basket.referralCode;
  data.receiveDeliveryRequestDateByPartnerId =
    basket.receiveDeliveryRequestDateByPartnerId;

  data.installationTimeSlotsByPartnerId =
    basket.installationTimeSlotsByPartnerId;

  return data;
}

export function checkoutBasket({
  basket,
  orderChannel = '',
  orderReference = '',
  checkoutAssistantEmail = '',
  callback,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const user = getAuthUser(state);
    const additionalProductDiscount = getBasketAdditionalProductDiscount(state);
    const additionalShippingDiscount =
      getBasketAdditionalShippingDiscount(state);

    const data = {
      ...genCheckoutData(basket, user),
      orderChannel,
      orderReference: orderReference.trim(),
      checkoutAssistantEmail,
      additionalProductDiscount,
      additionalShippingDiscount,
    };

    const endpointUrl = `${config.API_URL_GOBLIN}/checkout-v2/?format=json`;

    return dispatch({
      types: [
        AT.CHECKOUT_BASKET,
        AT.CHECKOUT_BASKET_SUCCESS,
        AT.CHECKOUT_BASKET_FAIL,
      ],
      promise: (client) =>
        client.post(endpointUrl, {
          data,
        }),
      options: {
        transformer: (result) => {
          const normalized = normalize(result, Schemas.ORDER);
          return {
            content: normalized.result,
            entities: normalized.entities,
          };
        },
        callback,
      },
    });
  };
}

export function updateBasketShippingAddress(shippingAddress) {
  return {
    type: AT.UPDATE_BASKET_SHIPPING_ADDRESS,
    payload: { shippingAddress },
  };
}

export function updateBasketLineShippingTierData(data) {
  return {
    type: AT.UPDATE_BASKET_LINE_SHIPPING_TIER_DATA,
    payload: { data },
  };
}

export function updateShippingMethodData(data) {
  return {
    type: AT.UPDATE_SHIPPING_METHOD_DATA,
    payload: { data },
  };
}

export function updateShippingMethodErrorData(error) {
  return {
    type: AT.UPDATE_SHIPPING_METHOD_ERROR_DATA,
    payload: { error },
  };
}

export function setIsBasketUpdatingShippingAddress(
  isBasketUpdatingShippingAddress,
) {
  return {
    type: AT.SET_IS_BASKET_UPDATING_SHIPPING_ADDRESS,
    payload: { isBasketUpdatingShippingAddress },
  };
}

export function loadBasketShippingMethod({ shippingAddress = null }) {
  let address = null;
  if (!isEmpty(shippingAddress)) {
    address = {
      ...shippingAddress,
      country: `${config.API_URL_GOBLIN}/countries/ID/`,
    };
    if (address.lastName === null) {
      address.lastName = '';
    }
  }
  const data = {
    shippingAddress: address,
  };
  return {
    types: [
      AT.LOAD_BASKET_SHIPPING_METHOD,
      AT.LOAD_BASKET_SHIPPING_METHOD_SUCCESS,
      AT.LOAD_BASKET_SHIPPING_METHOD_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/basket-v2/shipping-methods/`, {
        data,
      }),
  };
}

export function updateBasketPaymentMethod(paymentMethod) {
  return {
    type: AT.UPDATE_BASKET_PAYMENT_METHOD,
    payload: paymentMethod,
  };
}

export function addNewCreditCard() {
  return {
    type: AT.ADD_NEW_CREDIT_CARD,
  };
}

export function deleteNewCreditCard() {
  return {
    type: AT.DELETE_NEW_CREDIT_CARD,
  };
}

export function selectCreditCard(creditCard) {
  return {
    type: AT.SELECT_CREDIT_CARD,
    payload: creditCard,
  };
}

export function fillCreditCardInfo(attr, value) {
  const payload = {
    attr,
    value,
  };
  return {
    type: AT.FILL_CREDIT_CARD_INFO,
    payload,
  };
}

export function setCreditCardSecureToken(secureToken) {
  return {
    type: AT.SET_CREDIT_CARD_SECURE_TOKEN,
    payload: secureToken,
  };
}

export function updateCreditCardInstallmentTerm(term) {
  return {
    type: AT.UPDATE_CREDIT_CARD_INSTALLMENT_TERM,
    payload: term,
  };
}

export function updateSelectedEWallet(wallet) {
  return {
    type: AT.UPDATE_E_WALLET,
    payload: { wallet },
  };
}

export function updateSelectedInternetBanking(internetBanking) {
  return {
    type: AT.UPDATE_INTERNET_BANKING,
    payload: { internetBanking },
  };
}

export function updateSelectedInstallmentWithoutCard(installmentAgent) {
  return {
    type: AT.UPDATE_INSTALLMENT_WITHOUT_CARD,
    payload: { installmentAgent },
  };
}

export function updateSelectedInstallmentWithoutCardTerm(term) {
  return {
    type: AT.UPDATE_INSTALLMENT_WITHOUT_CARD_TERM,
    payload: { term },
  };
}

export function updateCreditCardInstallmentBank(
  bankConstant,
  shouldResetCreditCardSelectionValue = false,
) {
  return {
    type: AT.UPDATE_CREDIT_CARD_INSTALLMENT_BANK,
    payload: { bankConstant, shouldResetCreditCardSelectionValue },
  };
}

export function setInstallationTimeSlot(
  partnerId,
  installationDateStr,
  installationShiftSlug,
) {
  return {
    type: AT.SET_INSTALLATION_TIME_SLOT,
    payload: {
      partnerId,
      installationDateStr,
      installationShiftSlug,
    },
  };
}

export function setBasketData(basket) {
  return {
    type: AT.SET_BASKET_DATA,
    payload: basket,
  };
}

export function setBasketAdditionalProductDiscount(value) {
  return {
    type: AT.SET_BASKET_DATA,
    payload: {
      additionalProductDiscount: value,
    },
  };
}

export function setBasketAdditionalShippingDiscount(value) {
  return {
    type: AT.SET_BASKET_DATA,
    payload: {
      additionalShippingDiscount: value,
    },
  };
}
