import { useMemo } from 'react';
import {
  UseMutationOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { APIError, DjangoPaginatedResponse } from 'types';

import {
  cancelOrder,
  chargeExistingCreditCard,
  chargeInstallmentWithoutCard,
  chargeInternetBanking,
  chargeNewCreditCard,
  chargeOfflinePayment,
  getLoadOrderDetailQueryKey,
  getLoadOrderInsuranceInfoQueryKey,
  getLoadOrderListQueryKey,
  loadOrderDetail,
  loadOrderInsuranceInfo,
  loadOrderList,
  rechargeEWallet,
  requestVerifyBankTransferPayment,
  updateCreditCardTransactionStatusToPending,
} from './api';
import {
  CancelOrderPayload,
  ChargeExistingCreditCardPayload,
  ChargeInstallmentWithoutCardPayload,
  ChargeInternetBankingPayload,
  ChargeNewCreditCardPayload,
  ChargeOfflinePaymentPayload,
  ChargeOfflinePaymentResponse,
  Order,
  OrderPaymentInfo,
  RechargeEWalletPayload,
  RequestVerifyBankTransferPaymentPayload,
  RequestVerifyBankTransferPaymentResponse,
} from './types';

export const useInfiniteQueryLoadOrderList = (
  status: string,
  options?: Record<string, any>,
) => {
  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isError,
    error,
    data,
  } = useInfiniteQuery<DjangoPaginatedResponse<Order>>(
    getLoadOrderListQueryKey(status),
    ({ pageParam = 0 }) => loadOrderList(pageParam + 1, status),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.next) {
          return lastPage.page + 1;
        }
        return undefined;
      },
      staleTime: 30 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  const orders: Order[] = useMemo(() => {
    const res: Order[] = [];
    (data?.pages ?? []).forEach((page) => res.push(...page.results));

    return res;
  }, [data]);

  return {
    orders,
    isLoading,
    isFetchingNextPage,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
  };
};

export const useQueryLoadOrderDetail = (orderKey: string) => {
  return useQuery(
    getLoadOrderDetailQueryKey(orderKey),
    () => loadOrderDetail(orderKey),
    {
      staleTime: 30 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );
};

export const useQueryLoadOrderInsuranceInfo = (orderKey: string) => {
  return useQuery(
    getLoadOrderInsuranceInfoQueryKey(orderKey),
    () => loadOrderInsuranceInfo(orderKey),
    {
      staleTime: 30 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );
};

export const useMutationCancelOrder = (
  options?: UseMutationOptions<Order, APIError, CancelOrderPayload>,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: Order,
    payload: CancelOrderPayload,
    context: unknown,
  ) => {
    queryClient.invalidateQueries(getLoadOrderDetailQueryKey(payload.orderKey));
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation((payload: CancelOrderPayload) => cancelOrder(payload), {
    ...options,
    onSuccess: handleSuccess,
  });
};

export const useMutationUpdateCreditCardTransactionStatusToPending = (
  options?: UseMutationOptions<OrderPaymentInfo, APIError, string>,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: OrderPaymentInfo,
    payload: string,
    context: unknown,
  ) => {
    const currentOrderData: Order | undefined = queryClient.getQueryData(
      getLoadOrderDetailQueryKey(payload),
    );

    if (currentOrderData) {
      const updatedOrderData = {
        ...currentOrderData,
        payment: res,
      };
      queryClient.setQueryData(
        getLoadOrderDetailQueryKey(payload),
        updatedOrderData,
      );
    }
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: string) => updateCreditCardTransactionStatusToPending(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationChargeNewCreditCard = (
  options?: UseMutationOptions<Order, APIError, ChargeNewCreditCardPayload>,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: Order,
    payload: ChargeNewCreditCardPayload,
    context: unknown,
  ) => {
    queryClient.setQueryData(getLoadOrderDetailQueryKey(payload.orderKey), res);
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: ChargeNewCreditCardPayload) => chargeNewCreditCard(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationChargeExistingCreditCard = (
  options?: UseMutationOptions<
    Order,
    APIError,
    ChargeExistingCreditCardPayload
  >,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: Order,
    payload: ChargeExistingCreditCardPayload,
    context: unknown,
  ) => {
    queryClient.setQueryData(getLoadOrderDetailQueryKey(payload.orderKey), res);
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: ChargeExistingCreditCardPayload) =>
      chargeExistingCreditCard(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationRechargeEWallet = (
  options?: UseMutationOptions<Order, APIError, RechargeEWalletPayload>,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: Order,
    payload: RechargeEWalletPayload,
    context: unknown,
  ) => {
    queryClient.setQueryData(getLoadOrderDetailQueryKey(payload.orderKey), res);
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: RechargeEWalletPayload) => rechargeEWallet(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationChargeInternetBanking = (
  options?: UseMutationOptions<Order, APIError, ChargeInternetBankingPayload>,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: Order,
    payload: ChargeInternetBankingPayload,
    context: unknown,
  ) => {
    queryClient.setQueryData(getLoadOrderDetailQueryKey(payload.orderKey), res);
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: ChargeInternetBankingPayload) => chargeInternetBanking(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationChargeInstallmentWithoutCard = (
  options?: UseMutationOptions<
    Order,
    APIError,
    ChargeInstallmentWithoutCardPayload
  >,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: Order,
    payload: ChargeInstallmentWithoutCardPayload,
    context: unknown,
  ) => {
    queryClient.setQueryData(getLoadOrderDetailQueryKey(payload.orderKey), res);
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: ChargeInstallmentWithoutCardPayload) =>
      chargeInstallmentWithoutCard(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationChargeOfflinePayment = (
  options?: UseMutationOptions<
    ChargeOfflinePaymentResponse,
    APIError,
    ChargeOfflinePaymentPayload
  >,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: ChargeOfflinePaymentResponse,
    payload: ChargeOfflinePaymentPayload,
    context: unknown,
  ) => {
    queryClient.invalidateQueries(getLoadOrderDetailQueryKey(payload.orderKey));
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: ChargeOfflinePaymentPayload) => chargeOfflinePayment(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};

export const useMutationRequestVerifyBankTransferPayment = (
  options?: UseMutationOptions<
    RequestVerifyBankTransferPaymentResponse,
    APIError,
    RequestVerifyBankTransferPaymentPayload
  >,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = (
    res: RequestVerifyBankTransferPaymentResponse,
    payload: RequestVerifyBankTransferPaymentPayload,
    context: unknown,
  ) => {
    queryClient.invalidateQueries(getLoadOrderDetailQueryKey(payload.orderKey));
    options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: RequestVerifyBankTransferPaymentPayload) =>
      requestVerifyBankTransferPayment(payload),
    {
      ...options,
      onSuccess: handleSuccess,
    },
  );
};
