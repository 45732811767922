import React, {
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocation } from 'app-libs/hook_modules/router';

export const RouteContext = createContext<{
  isChangingRoute: boolean;
  setIsChangingRoute: Dispatch<SetStateAction<boolean>>;
}>({
  isChangingRoute: false,
  setIsChangingRoute: () => new Promise((resolve) => resolve(null)),
});

export const RouteContextProvider: React.FC = memo(({ children }) => {
  const location = useLocation();

  const [isChangingRoute, setIsChangingRoute] = useState(false);

  useEffect(() => {
    setIsChangingRoute(false);
  }, [location.pathname]);

  const contextValue = useMemo(
    () => ({
      isChangingRoute,
      setIsChangingRoute,
    }),
    [isChangingRoute, setIsChangingRoute],
  );

  return (
    <RouteContext.Provider value={contextValue}>
      {children}
    </RouteContext.Provider>
  );
});
