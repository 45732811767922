import { RootStateOrAny } from 'react-redux';

import {
  K_DEFAULT_PRICE_ZONE_CODE,
  K_DEFAULT_ROUTING_ZONE_CODE,
} from 'constants/productConstants';

export const getCurrentLocationPriceZoneCode = (state: RootStateOrAny) =>
  state.location?.zoneCode || K_DEFAULT_PRICE_ZONE_CODE;

export const getCurrentLocationRoutingZoneCode = (state: RootStateOrAny) =>
  state.location?.routingZoneCode ?? K_DEFAULT_ROUTING_ZONE_CODE;

export const getCurrentLocation = (state: RootStateOrAny) => state.location;

export const getCurrentLocationShippingAreaId = (
  state: RootStateOrAny,
): number => state.location.id;

export const getIsCurrentLocationSet = (state: RootStateOrAny) =>
  !state.location.isInitial;

export const getIsLocationPersistRehydrated = (state: RootStateOrAny) =>
  getCurrentLocation(state)?._persist?.rehydrated ?? false;
