import keyMirror from 'keymirror';
import { getImageName } from 'app-libs/etc';
import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

export const AT = keyMirror({
  INITIALIZE_MOODBOARD: null,
  ADD_MOODBOARD_ITEM: null,
  MOVE_MOODBOARD_ITEM: null,
  REMOVE_MOODBOARD_ITEM: null,
  UPDATE_MOODBOARD_ITEM: null,
  UPDATE_MOODBOARD: null,
  UPDATE_MOODBOARD_BANNER: null,
  SWAP_MOODBOARD_ITEM: null,

  CHOOSE_MOODBOARD_POST_ID: null,
});

export const BANNER_TYPE = keyMirror({
  TOP_BANNER: null,
  P_BANNER: null,
  P_BANNER_LANDSCAPE: null,
});

const initialState = {
  id: null,

  // main props
  items: [],
  itemLowestPrice: null,
  banner: {
    topBanner: null,
    pBanner: null, // preview banner
    pBannerLandscape: null,
  },
  title: null,
  subtitle: null,
  description: null,

  asyncState: initialAsyncState,
  hasUnsavedChanges: false,
  isNew: false,

  postId: null,
};

const getMinPrice = (lowestPrice, priceToCompare) => {
  const priceToCompareInteger = parseInt(priceToCompare, 10);
  if (lowestPrice === null || priceToCompareInteger < lowestPrice) {
    return priceToCompareInteger;
  }
  return lowestPrice;
};

const getItemLowestPrice = (items) => {
  let itemLowestPrice = null;
  items.forEach((item) => {
    itemLowestPrice = getMinPrice(itemLowestPrice, item.price);
  });
  return itemLowestPrice;
};

// reducer
export default function moodboardReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.INITIALIZE_MOODBOARD:
      if (action.payload.moodboard) {
        state = Object.assign(
          {},
          initialState,
          action.payload.moodboard,
          { hasUnsavedChanges: false },
          { isNew: false },
        );

        if (state.itemLowestPrice === null) {
          state.itemLowestPrice = getItemLowestPrice(state.items);
        }
      } else {
        state = Object.assign(
          {},
          initialState,
          { hasUnsavedChanges: false },
          { isNew: true },
        );
      }
      break;
    case AT.ADD_MOODBOARD_ITEM:
      if (
        !state.items.find(
          (item) => item.objectID === action.payload.item.objectID,
        )
      ) {
        state = Object.assign({}, state, {
          items: [
            ...state.items.slice(0, action.payload.index),
            action.payload.item,
            ...state.items.slice(action.payload.index),
          ],
          itemLowestPrice: getMinPrice(
            state.itemLowestPrice,
            action.payload.item.price,
          ),
          hasUnsavedChanges: true,
        });
      }
      break;
    case AT.MOVE_MOODBOARD_ITEM: {
      const indexToBeAdded = action.payload.index;
      const itemToBeAdded = action.payload.item;

      const indexOfExistingItem = state.items.findIndex(
        (item) => item.objectID === itemToBeAdded.objectID,
      );
      const newItems = [...state.items];
      newItems.splice(indexOfExistingItem, 1); // remove it, then add
      newItems.splice(indexToBeAdded, 0, itemToBeAdded);
      state = Object.assign({}, state, {
        items: newItems,
        hasUnsavedChanges: true,
      });
      break;
    }
    case AT.SWAP_MOODBOARD_ITEM: {
      const newSwappedItems = [...state.items];
      if (action.payload.fromIndex !== action.payload.toIndex) {
        const tmp = newSwappedItems[action.payload.fromIndex];
        newSwappedItems[action.payload.fromIndex] =
          newSwappedItems[action.payload.toIndex];
        newSwappedItems[action.payload.toIndex] = tmp;
        state = Object.assign({}, state, {
          items: newSwappedItems,
          hasUnsavedChanges: true,
        });
      }
      break;
    }
    case AT.REMOVE_MOODBOARD_ITEM: {
      const itemToBeRemoved = action.payload.item;
      const indexToBeRemoved = state.items.findIndex(
        (item) => item.objectID === itemToBeRemoved.objectID,
      );
      if (indexToBeRemoved >= 0) {
        state = Object.assign({}, state, {
          items: [
            ...state.items.slice(0, indexToBeRemoved),
            ...state.items.slice(indexToBeRemoved + 1),
          ],
          hasUnsavedChanges: true,
        });
        const itemToBeRemovedPriceInteger = parseInt(itemToBeRemoved.price, 10);
        if (itemToBeRemovedPriceInteger === state.itemLowestPrice) {
          state.itemLowestPrice = getItemLowestPrice(state.items);
        }
      } else {
        console.log('indexToBeRemoved not found');
      }
      break;
    }
    case AT.UPDATE_MOODBOARD_ITEM: {
      const indexToBeUpdated = state.items.findIndex(
        (item) => item.objectID === action.payload.itemID,
      );
      if (indexToBeUpdated >= 0) {
        state = Object.assign({}, state, {
          items: [
            ...state.items.slice(0, indexToBeUpdated),
            Object.assign(
              {},
              state.items[indexToBeUpdated],
              action.payload.props,
            ),
            ...state.items.slice(indexToBeUpdated + 1),
          ],
          hasUnsavedChanges: true,
        });
      }
      break;
    }
    case AT.UPDATE_MOODBOARD: {
      state = {
        ...state,
        ...action.payload.props,
        hasUnsavedChanges: true,
      };
      break;
    }
    case AT.UPDATE_MOODBOARD_BANNER: {
      const topBanner =
        action.payload.bannerType === BANNER_TYPE.TOP_BANNER
          ? action.payload.bannerUrl
          : state.banner.topBanner;
      const pBanner =
        action.payload.bannerType === BANNER_TYPE.P_BANNER
          ? action.payload.bannerUrl
          : state.banner.pBanner;
      const pBannerLandscape =
        action.payload.bannerType === BANNER_TYPE.P_BANNER_LANDSCAPE
          ? action.payload.bannerUrl
          : state.banner.pBannerLandscape;
      state = {
        ...state,
        banner: {
          topBanner,
          pBanner,
          pBannerLandscape,
        },
        hasUnsavedChanges: true,
      };
      break;
    }

    case AT.CHOOSE_MOODBOARD_POST_ID: {
      state = Object.assign({}, state, {
        postId: action.payload.postId,
      });
    }
  }

  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_MOODBOARD_'),
  });
}

export function addMoodboardItem(item, index = 0) {
  return {
    type: AT.ADD_MOODBOARD_ITEM,
    payload: { item, index },
  };
}

export function updateMoodboardItem(itemID, propsToUpdate) {
  return {
    type: AT.UPDATE_MOODBOARD_ITEM,
    payload: { itemID, props: propsToUpdate },
  };
}

export function updateMoodboard(propsToUpdate) {
  return {
    type: AT.UPDATE_MOODBOARD,
    payload: { props: propsToUpdate },
  };
}

export function removeMoodboardItem(item) {
  return {
    type: AT.REMOVE_MOODBOARD_ITEM,
    payload: { item },
  };
}

export function moveMoodboardItem(item, index) {
  return {
    type: AT.MOVE_MOODBOARD_ITEM,
    payload: { item, index },
  };
}

export function swapMoodboardItem(item, fromIndex, toIndex) {
  return {
    type: AT.SWAP_MOODBOARD_ITEM,
    payload: { item, fromIndex, toIndex },
  };
}

export function updateMoodboardBanner(bannerUrl, bannerType, progress) {
  return {
    type: AT.UPDATE_MOODBOARD_BANNER,
    payload: { bannerUrl: getImageName(bannerUrl), bannerType, progress },
  };
}

export function initializeMoodboard(moodboard) {
  return {
    type: AT.INITIALIZE_MOODBOARD,
    payload: { moodboard },
  };
}

export function chooseMoodboardPostId(postId) {
  return {
    type: AT.CHOOSE_MOODBOARD_POST_ID,
    payload: { postId },
  };
}
