import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getBannersFromAdvertisements } from 'app-libs/etc/advertisementHelper';
import { getAdsByLocation } from 'app-libs/redux_modules/selectors/advertisement';

import CampaignBannerSlider from 'components/promo/Shared/CampaignBannerSlider';

import { K_STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER } from 'constants/advertisementConstants';

interface CampaignBannerProps {
  location: string;
  fallbackLocation?: string;
}

const K_ADS_BANNERS_WIDTH_PROPORTION = 375;
const K_ADS_BANNERS_HEIGHT_PROPORTION = 125;

const CampaignBanner: React.FC<CampaignBannerProps> = memo(
  ({
    location,
    fallbackLocation = K_STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER,
  }) => {
    const advertisements = useSelector((state) =>
      getAdsByLocation(state, location),
    );
    const fallbackAdvertisements = useSelector((state) =>
      getAdsByLocation(state, fallbackLocation),
    );

    const banners = useMemo(
      () =>
        getBannersFromAdvertisements([
          ...advertisements,
          ...fallbackAdvertisements,
        ]),
      [advertisements, fallbackAdvertisements],
    );

    return banners.length > 0 ? (
      <CampaignBannerSlider
        banners={banners}
        height={K_ADS_BANNERS_HEIGHT_PROPORTION}
        width={K_ADS_BANNERS_WIDTH_PROPORTION}
      />
    ) : null;
  },
);

export default CampaignBanner;
