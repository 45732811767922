import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, View } from '@dkrm/ui-kit-basic';

// Component Related Menu
import CheckoutAddShippingAddressButton from 'app-libs/components/Checkout/ShippingAddressSelection/AddShippingAddressButton';
import NavbarProjectDetailV2 from 'app-libs/components/NavbarImplementation/NavbarProjectDetailV2';

// Navbar Related Menu
import NotFoundPage from 'components/@NotFoundPage/Loadable';
import OfflineStoreLocationListPage from 'components/@OfflineStoreLocationListPage';
// App Container Related Menu
import AppContainer from 'components/AppContainer';
import DnbDrawerContainer from 'components/DnbDrawerContainer/Loadable';
// Web View Restricted Container Related Menu
// @note(dika) 15 Sept 2023: Temporary disable login wall
// import WebViewRestrictedContainer from 'components/WebViewRestrictedContainer';
import EditProfilePage from 'components/account/@EditProfilePage/Loadable';
import ForgotPasswordPage from 'components/account/@ForgotPasswordPage/Loadable';
import LoginInternalPage from 'components/account/@LoginInternalPage/Loadable';
import LoginOrRegisterEmailPage from 'components/account/@LoginOrRegisterEmailPage/Loadable';
import LoginOrRegisterPhoneNumberPage from 'components/account/@LoginOrRegisterPhoneNumberPage/Loadable';
import LoginWallPage from 'components/account/@LoginWallPage/Loadable';
import NotificationPage from 'components/account/@NotificationPage/Loadable';
import ResetPasswordPage from 'components/account/@ResetPasswordPage/Loadable';
import ValidateEmailPage from 'components/account/@ValidateEmailPage/Loadable';
import CampaignDetailPage from 'components/campaign/@CampaignDetailPage/Loadable';
import NavbarCart from 'components/cart/NavbarCart';
import AddCreditCardPage from 'components/checkout/@AddCreditCardPage/Loadable';
import ApplyVoucherPage from 'components/checkout/@ApplyVoucherPage/Loadable';
import CartPage from 'components/checkout/@CartPage/Loadable';
import CheckoutReviewPage from 'components/checkout/@CheckoutReviewPage/Loadable';
import CheckoutShippingAddressPage from 'components/checkout/@CheckoutShippingAddressPage/Loadable';
import CheckoutShippingAddressSelectionPage from 'components/checkout/@CheckoutShippingAddressSelectionPage/Loadable';
import RedirectPaymentPage from 'components/checkout/@RedirectPaymentPage/Loadable';
import NavbarCheckout from 'components/checkout/NavbarCheckout';
import FlashSaleDetailPage from 'components/flashsale/@FlashSaleDetailPage/Loadable';
import MainHomePage from 'components/home/@MainHomePage/Loadable';
import ProductHomepage from 'components/home/@ProductHomepage/Loadable';
import MattressHomePage from 'components/mattress/@HomePage/Loadable';
import MattressSearchPage from 'components/mattress/@SearchPage/Loadable';
import MembershipEditProfilePage from 'components/membership/@MembershipEditProfilePage/Loadable';
import MembershipFamilyRegisterPage from 'components/membership/@MembershipFamilyRegisterPage/Loadable';
import MembershipProDashboardPage from 'components/membership/@MembershipProDashboardPage/Loadable';
import MembershipSomaRegisterPage from 'components/membership/@MembershipSomaRegisterPage/Loadable';
import MembershipTutorialDetailPage from 'components/membership/@MembershipTutorialDetailPage/Loadable';
import MembershipTutorialListPage from 'components/membership/@MembershipTutorialListPage/Loadable';
import MoodboardDetailPage from 'components/moodboard/@MoodboardDetailPage/Loadable';
import NavbarDesignerProfile from 'components/navbar/NavbarDesignerProfile/Loadable';
import NavbarSimilarProducts from 'components/navbar/NavbarSimilarProducts';
import NavbarStorefront from 'components/navbar/NavbarStorefront';
import NavbarStorefrontWithShare from 'components/navbar/NavbarStorefrontWithShare';
import NavbarTitleCenteredWrapper from 'components/navbar/NavbarTitleCenteredWrapper';
import OfferHomePage from 'components/offer/@OfferHomePage/Loadable';
import OfferSearchPage from 'components/offer/@OfferSearchPage/Loadable';
import OfflineBasketListPage from 'components/offlineStore/@OfflineBasketListPage/Loadable';
import OrderDetailsPage from 'components/order/@OrderDetailsPage/Loadable';
import OrderListPage from 'components/order/@OrderListPage/Loadable';
import OrderProtectionLineDetailPage from 'components/order/@OrderProtectionLineDetailPage';
import OrderProtectionListPage from 'components/order/@OrderProtectionListPage';
import NavbarOrder from 'components/order/NavbarOrder';
import BoughtTogetherPage from 'components/product/@BoughtTogetherPage/Loadable';
import ProductBrandsPage from 'components/product/@BrandsPage/Loadable';
import ProductDetailsPage from 'components/product/@ProductDetailsPage/Loadable';
import ReferralCommissionSearchPage from 'components/product/@ProductReferralCommissionSearchPage/Loadable';
import ProductSearchSuggestionPage from 'components/product/@ProductSearchSuggestionPage/Loadable';
import RoomDetailPage from 'components/product/@RoomDetailPage/Loadable';
import RoomProductCategoryPage from 'components/product/@RoomProductCategoryPage/Loadable';
import RoomsPage from 'components/product/@RoomsPage/Loadable';
import SimilarProductsPage from 'components/product/@SimilarProductsPage/Loadable';
import AccessoriesHomepage from 'components/product/productSearch/@AccessoriesHomepage/Loadable';
import BrandPage from 'components/product/productSearch/@BrandPage/Loadable';
import BrandingPushedHomepage from 'components/product/productSearch/@BrandingPushedHomepage';
import FurnitureHomepage from 'components/product/productSearch/@FurnitureHomepage/Loadable';
import ProductSearchPage from 'components/product/productSearch/@ProductSearchPage/Loadable';
import TopSearchPage from 'components/product/productSearch/@TopSearchPage';
import DesignerLevelDetailPage from 'components/profile/@DesignerLevelDetailPage/Loadable';
import DesignerProfilePage from 'components/profile/@DesignerProfilePage/Loadable';
import DesignerEditProfileMenuPage from 'components/profile/DesignerEditProfile/@DesignerEditProfileMenuPage/Loadable';
import ProjectBOQPage from 'components/project/@ProjectBOQPage/Loadable';
import ProjectDealPackageDetailPage from 'components/project/@ProjectDealPackageDetailPage/Loadable';
import ProjectDetailPage from 'components/project/@ProjectDetailPage/Loadable';
import ProjectListPage from 'components/project/@ProjectListPage/Loadable';
import MyVoucherListPage from 'components/promo/@MyVoucherListPage/Loadable';
import PromoListPage from 'components/promo/@PromoListPage/Loadable';
import NavbarPromo from 'components/promo/NavbarPromo';
import ProductReviewDetailPage from 'components/review/@ProductReviewDetailPage/Loadable';
import ProductReviewListPage from 'components/review/@ProductReviewListPage/Loadable';
import WishlistPage from 'components/wishlist/@WishlistPage/Loadable';

import {
  K_ROUTE_ACCESSORIES_HOMEPAGE,
  K_ROUTE_ADD_CREDIT_CARD,
  K_ROUTE_APPLY_VOUCHER,
  K_ROUTE_BOUGHT_TOGETHER,
  K_ROUTE_BRANDING_PUSHED_HOMEPAGE,
  K_ROUTE_BRANDS,
  K_ROUTE_BRAND_DETAIL,
  K_ROUTE_CAMPAIGNS,
  K_ROUTE_CAMPAIGN_DETAIL,
  K_ROUTE_CART,
  K_ROUTE_CHECKOUT_PAYMENT_REDIRECT,
  K_ROUTE_CHECKOUT_REVIEW,
  K_ROUTE_CHECKOUT_SHIPPING_ADDRESS,
  K_ROUTE_CHECKOUT_SHIPPING_ADDRESS_SELECTION,
  K_ROUTE_DEAL_PACKAGE_DETAIL,
  K_ROUTE_DEAL_PACKAGE_DETAIL_SIMPLIFIED,
  K_ROUTE_DESIGNER_EDIT_PROFILE_V2,
  K_ROUTE_DESIGNER_LEVEL_DETAIL,
  K_ROUTE_DESIGNER_PROFILE_SIMPLIFIED_V2,
  K_ROUTE_DESIGNER_PROFILE_V2,
  K_ROUTE_EDIT_PROFILE,
  K_ROUTE_FLASH_SALE,
  K_ROUTE_FORGOT_PASSWORD,
  K_ROUTE_FURNITURE_HOMEPAGE,
  K_ROUTE_FURNITURE_JAPANDI_HOMEPAGE,
  K_ROUTE_LOGIN,
  K_ROUTE_LOGIN_EMAIL,
  K_ROUTE_LOGIN_INTERNAL,
  K_ROUTE_LOGIN_PHONE_NUMBER,
  K_ROUTE_LOGIN_WALL,
  K_ROUTE_MATTRESS,
  K_ROUTE_MEMBERSHIP_CATALOGUE,
  K_ROUTE_MEMBERSHIP_DASHBOARD_PRO,
  K_ROUTE_MEMBERSHIP_EDIT_PROFILE,
  K_ROUTE_MEMBERSHIP_REGISTER_FAMILY,
  K_ROUTE_MEMBERSHIP_REGISTER_SOMA,
  K_ROUTE_MEMBERSHIP_TUTORIAL_DETAIL,
  K_ROUTE_MEMBERSHIP_TUTORIAL_LIST,
  K_ROUTE_MOODBOARD,
  K_ROUTE_MY_VOUCHERS,
  K_ROUTE_NOTIFICATION,
  K_ROUTE_OFFLINE_BASKET_LIST,
  K_ROUTE_OFFLINE_STORE_LIST,
  K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER,
  K_ROUTE_ORDERS,
  K_ROUTE_ORDER_COMPLAIN,
  K_ROUTE_ORDER_DETAIL,
  K_ROUTE_ORDER_PROTECTION_LINE_DETAIL,
  K_ROUTE_ORDER_PROTECTION_LIST,
  K_ROUTE_PASSWORD_RESET,
  K_ROUTE_PORTFOLIO_BOQ,
  K_ROUTE_PORTFOLIO_LIST,
  K_ROUTE_PRODUCT,
  K_ROUTE_PRODUCT_HOMEPAGE,
  K_ROUTE_PRODUCT_SIMPLIFIED,
  K_ROUTE_PROMOTION,
  K_ROUTE_PROMOTION_DETAILS,
  K_ROUTE_REVIEW_DETAIL,
  K_ROUTE_REVIEW_LIST,
  K_ROUTE_ROOMS,
  K_ROUTE_ROOM_DETAIL,
  K_ROUTE_ROOM_PRODUCT_CATEGORY,
  K_ROUTE_SEARCH,
  K_ROUTE_SEARCH_SUGGESTION,
  K_ROUTE_SIGNUP,
  K_ROUTE_SIMILAR_PRODUCTS,
  K_ROUTE_TOP_SEARCH,
  K_ROUTE_VERIFY_EMAIL,
  K_ROUTE_WISHLIST,
} from 'constants/routeConstants';

const K_MAX_ORDER_PAGE_WIDTH = 1024;

interface Route {
  component?: React.FC | ((props: any) => JSX.Element);
  render?: (props: any) => JSX.Element;
  path?: string;
  routes?: Route[];
  exact?: boolean;
}

const routes: Route[] = [
  {
    component: AppContainer,
    routes: [
      // @note(dika) 15 Sept 2023: Temporary disable login wall
      // {
      //   path: '/',
      //   exact: true,
      //   component: WebViewRestrictedContainer,
      //   routes: [
      //     {
      //       path: '/',
      //       exact: true,
      //       component: MainHomePage,
      //     },
      //   ],
      // },
      {
        path: '/',
        exact: true,
        component: MainHomePage,
      },
      {
        path: K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER,
        exact: true,
        component: OfflineStoreLocationListPage,
      },
      {
        path: K_ROUTE_OFFLINE_STORE_LIST,
        exact: true,
        component: OfflineStoreLocationListPage,
      },
      {
        path: K_ROUTE_OFFLINE_BASKET_LIST,
        exact: true,
        component: OfflineBasketListPage,
      },
      {
        path: K_ROUTE_PRODUCT_HOMEPAGE,
        exact: true,
        component: NavbarStorefront,
        routes: [
          {
            component: ProductHomepage,
          },
        ],
      },
      {
        path: K_ROUTE_NOTIFICATION,
        exact: true,
        component: NavbarStorefront,
        routes: [
          {
            component: NotificationPage,
          },
        ],
      },
      {
        path: K_ROUTE_SEARCH_SUGGESTION,
        exact: true,
        component: ProductSearchSuggestionPage,
      },
      {
        path: K_ROUTE_REVIEW_LIST,
        exact: true,
        render: (props) => (
          <NavbarTitleCenteredWrapper {...props} title="Review Produk" />
        ),
        routes: [
          {
            component: ProductReviewListPage,
          },
        ],
      },
      {
        path: K_ROUTE_REVIEW_DETAIL,
        exact: true,
        render: (props) => (
          <NavbarTitleCenteredWrapper {...props} title="Isi Review" />
        ),
        routes: [
          {
            component: ProductReviewDetailPage,
          },
        ],
      },
      {
        /**
         * @warning
         * K_ROUTE_DESIGNER_EDIT_PROFILE_V2 MUST BE ABOVE K_ROUTE_DESIGNER_PROFILE_V2
         * because of slug
         */
        path: K_ROUTE_DESIGNER_EDIT_PROFILE_V2,
        exact: true,
        component: DesignerEditProfileMenuPage,
      },
      {
        path: K_ROUTE_DESIGNER_PROFILE_SIMPLIFIED_V2,
        exact: true,
        component: NavbarDesignerProfile,
        routes: [
          {
            component: DesignerProfilePage,
          },
        ],
      },
      {
        path: K_ROUTE_DESIGNER_PROFILE_V2,
        exact: true,
        component: NavbarDesignerProfile,
        routes: [
          {
            component: DesignerProfilePage,
          },
        ],
      },
      {
        path: K_ROUTE_DESIGNER_LEVEL_DETAIL,
        exact: true,
        component: DesignerLevelDetailPage,
      },
      {
        path: K_ROUTE_SIGNUP,
        exact: true,
        component: LoginOrRegisterPhoneNumberPage,
      },
      {
        path: K_ROUTE_LOGIN,
        exact: true,
        component: LoginOrRegisterPhoneNumberPage,
      },
      {
        path: K_ROUTE_LOGIN_EMAIL,
        exact: true,
        component: LoginOrRegisterEmailPage,
      },
      {
        path: K_ROUTE_LOGIN_PHONE_NUMBER,
        exact: true,
        component: LoginOrRegisterPhoneNumberPage,
      },
      {
        path: K_ROUTE_LOGIN_WALL,
        exact: true,
        component: LoginWallPage,
      },
      {
        path: K_ROUTE_LOGIN_INTERNAL,
        exact: true,
        component: LoginInternalPage,
      },
      {
        path: K_ROUTE_EDIT_PROFILE,
        exact: true,
        component: EditProfilePage,
      },
      {
        path: K_ROUTE_FORGOT_PASSWORD,
        exact: true,
        component: ForgotPasswordPage,
      },
      {
        path: K_ROUTE_PASSWORD_RESET,
        exact: true,
        component: ResetPasswordPage,
      },
      {
        path: K_ROUTE_VERIFY_EMAIL,
        exact: true,
        component: ValidateEmailPage,
      },
      {
        path: K_ROUTE_PORTFOLIO_BOQ,
        exact: true,
        component: ProjectBOQPage,
      },
      {
        path: `${K_ROUTE_PORTFOLIO_LIST}`,
        component: DnbDrawerContainer,
        routes: [
          {
            path: '/:projectKey(\\d+)/:slug',
            exact: true,
            component: NavbarProjectDetailV2,
            routes: [
              {
                component: ProjectDetailPage,
              },
            ],
          },
          {
            path: '/:projectKey(\\d+)',
            exact: true,
            component: NavbarProjectDetailV2,
            routes: [
              {
                component: ProjectDetailPage,
              },
            ],
          },
          {
            path: '/:filter',
            exact: true,
            component: ProjectListPage,
          },
          {
            exact: true,
            component: ProjectListPage,
          },
        ],
      },
      {
        path: K_ROUTE_DEAL_PACKAGE_DETAIL,
        exact: true,
        component: ProjectDealPackageDetailPage,
      },
      {
        path: K_ROUTE_DEAL_PACKAGE_DETAIL_SIMPLIFIED,
        exact: true,
        component: ProjectDealPackageDetailPage,
      },
      {
        path: K_ROUTE_ROOMS,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: RoomsPage,
          },
        ],
      },
      {
        path: K_ROUTE_ROOM_DETAIL,
        exact: true,
        routes: [
          {
            component: RoomDetailPage,
          },
        ],
      },
      {
        path: K_ROUTE_ROOM_PRODUCT_CATEGORY,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: RoomProductCategoryPage,
          },
        ],
      },
      {
        path: K_ROUTE_MEMBERSHIP_CATALOGUE,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ReferralCommissionSearchPage,
          },
        ],
      },
      {
        path: `${K_ROUTE_MOODBOARD}/:moodboardId`,
        exact: true,
        component: NavbarStorefront,
        routes: [
          {
            component: MoodboardDetailPage,
          },
        ],
      },
      {
        path: K_ROUTE_SEARCH,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ProductSearchPage,
          },
        ],
      },
      {
        path: K_ROUTE_TOP_SEARCH,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: TopSearchPage,
          },
        ],
      },
      {
        path: K_ROUTE_BRANDS,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ProductBrandsPage,
          },
        ],
      },
      {
        path: `${K_ROUTE_BRAND_DETAIL}/:tabKey`,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: BrandPage,
          },
        ],
      },
      {
        path: K_ROUTE_BRAND_DETAIL,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: BrandPage,
          },
        ],
      },
      {
        path: K_ROUTE_SIMILAR_PRODUCTS,
        component: NavbarSimilarProducts,
        routes: [
          {
            component: SimilarProductsPage,
          },
        ],
      },
      {
        path: K_ROUTE_BOUGHT_TOGETHER,
        render: (props) => (
          <NavbarTitleCenteredWrapper
            title="Cocok Digabung Dengan"
            {...props}
          />
        ),
        routes: [
          {
            component: BoughtTogetherPage,
          },
        ],
      },
      {
        path: K_ROUTE_PRODUCT_SIMPLIFIED,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ProductDetailsPage,
          },
        ],
      },
      {
        path: K_ROUTE_CART,
        exact: true,
        render: (props) => <NavbarCart {...props} title="Keranjang" />,
        routes: [
          {
            component: CartPage,
          },
        ],
      },
      {
        path: K_ROUTE_CHECKOUT_SHIPPING_ADDRESS,
        exact: true,
        render: (props) => (
          <NavbarCheckout {...props} defaultTitle="Tambah Alamat" />
        ),
        routes: [
          {
            component: CheckoutShippingAddressPage,
          },
        ],
      },
      {
        path: K_ROUTE_CHECKOUT_SHIPPING_ADDRESS_SELECTION,
        exact: true,
        render: (props) => (
          <NavbarCheckout
            {...props}
            title="Alamat"
            rightItems={[<CheckoutAddShippingAddressButton />]}
          />
        ),
        routes: [
          {
            component: CheckoutShippingAddressSelectionPage,
          },
        ],
      },
      {
        path: K_ROUTE_FLASH_SALE,
        exact: true,
        render: (props) => <NavbarStorefront {...props} shouldUseBackButton />,
        routes: [
          {
            component: FlashSaleDetailPage,
          },
        ],
      },
      {
        path: K_ROUTE_APPLY_VOUCHER,
        exact: true,
        routes: [
          {
            component: ApplyVoucherPage,
          },
        ],
      },
      {
        path: K_ROUTE_ADD_CREDIT_CARD,
        exact: true,
        render: (props) => (
          <NavbarCheckout {...props} title="Tambah Kartu Kredit" />
        ),
        routes: [
          {
            component: AddCreditCardPage,
          },
        ],
      },
      {
        path: K_ROUTE_CHECKOUT_PAYMENT_REDIRECT,
        exact: true,
        render: NavbarStorefront,
        routes: [
          {
            component: RedirectPaymentPage,
          },
        ],
      },
      {
        path: K_ROUTE_CHECKOUT_REVIEW,
        exact: true,
        routes: [
          {
            component: CheckoutReviewPage,
          },
        ],
      },
      {
        path: K_ROUTE_ORDERS,
        exact: true,
        render: (props) => (
          <View style={s.orderPage}>
            <NavbarTitleCenteredWrapper title="Daftar Pesanan" {...props} />
          </View>
        ),
        routes: [
          {
            component: OrderListPage,
          },
        ],
      },
      {
        path: K_ROUTE_ORDER_DETAIL,
        exact: true,
        render: (props) => (
          <View style={s.orderPage}>
            <NavbarOrder {...props} />
          </View>
        ),
        routes: [
          {
            component: OrderDetailsPage,
          },
        ],
      },
      {
        path: K_ROUTE_ORDER_COMPLAIN,
        exact: true,
        render: (props) => (
          <View style={s.orderPage}>
            <NavbarOrder {...props} />
          </View>
        ),
        routes: [
          {
            component: OrderDetailsPage,
          },
        ],
      },
      {
        path: K_ROUTE_ORDER_PROTECTION_LIST,
        exact: true,
        render: (props) => (
          <View style={s.orderPage}>
            <NavbarOrder {...props} />
          </View>
        ),
        routes: [
          {
            component: OrderProtectionListPage,
          },
        ],
      },
      {
        path: K_ROUTE_ORDER_PROTECTION_LINE_DETAIL,
        exact: true,
        render: (props) => (
          <View style={s.orderPage}>
            <NavbarOrder {...props} />
          </View>
        ),
        routes: [
          {
            component: OrderProtectionLineDetailPage,
          },
        ],
      },
      {
        path: K_ROUTE_MATTRESS,
        routes: [
          {
            path: '/',
            exact: true,
            routes: [
              {
                component: MattressHomePage,
              },
            ],
          },
          {
            path: '/:areaSlug',
            exact: true,
            routes: [
              {
                component: MattressHomePage,
              },
            ],
          },
          {
            path: `${K_ROUTE_SEARCH}/:cat`,
            exact: true,
            component: NavbarStorefrontWithShare,
            routes: [
              {
                component: MattressSearchPage,
              },
            ],
          },
          {
            path: `${K_ROUTE_SEARCH}/:cat/:brands`,
            exact: true,
            component: NavbarStorefrontWithShare,
            routes: [
              {
                component: MattressSearchPage,
              },
            ],
          },
          {
            path: K_ROUTE_PRODUCT_SIMPLIFIED,
            exact: true,
            component: NavbarStorefrontWithShare,
            routes: [
              {
                component: ProductDetailsPage,
              },
            ],
          },
          {
            path: K_ROUTE_PRODUCT,
            exact: true,
            component: NavbarStorefrontWithShare,
            routes: [
              {
                component: ProductDetailsPage,
              },
            ],
          },
        ],
      },
      {
        path: K_ROUTE_PROMOTION_DETAILS,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: CampaignDetailPage,
          },
        ],
      },
      {
        path: K_ROUTE_MEMBERSHIP_REGISTER_FAMILY,
        exact: true,
        routes: [
          {
            component: MembershipFamilyRegisterPage,
          },
        ],
      },

      {
        path: K_ROUTE_MEMBERSHIP_REGISTER_SOMA,
        exact: true,
        routes: [
          {
            component: MembershipSomaRegisterPage,
          },
        ],
      },
      {
        path: K_ROUTE_MEMBERSHIP_DASHBOARD_PRO,
        exact: true,
        routes: [
          {
            component: MembershipProDashboardPage,
          },
        ],
      },
      {
        path: K_ROUTE_MEMBERSHIP_TUTORIAL_DETAIL,
        exact: true,
        routes: [
          {
            component: MembershipTutorialDetailPage,
          },
        ],
      },
      {
        path: K_ROUTE_MEMBERSHIP_TUTORIAL_LIST,
        exact: true,
        routes: [
          {
            component: MembershipTutorialListPage,
          },
        ],
      },
      {
        path: K_ROUTE_MEMBERSHIP_EDIT_PROFILE,
        exact: true,
        routes: [
          {
            component: MembershipEditProfilePage,
          },
        ],
      },
      {
        path: K_ROUTE_CAMPAIGNS,
        exact: true,
        render: (props) => <NavbarStorefront {...props} shouldUseBackButton />,
        routes: [
          {
            component: OfferHomePage,
          },
        ],
      },
      {
        path: K_ROUTE_CAMPAIGN_DETAIL,
        exact: true,
        component: OfferSearchPage,
      },
      {
        path: K_ROUTE_WISHLIST,
        exact: true,
        component: NavbarStorefront,
        routes: [
          {
            component: WishlistPage,
          },
        ],
      },
      {
        path: K_ROUTE_PROMOTION,
        exact: true,
        component: NavbarPromo,
        routes: [
          {
            component: PromoListPage,
          },
        ],
      },
      {
        path: K_ROUTE_MY_VOUCHERS,
        exact: true,
        component: MyVoucherListPage,
      },
      {
        path: K_ROUTE_FURNITURE_JAPANDI_HOMEPAGE,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: FurnitureHomepage,
          },
        ],
      },
      {
        path: K_ROUTE_BRANDING_PUSHED_HOMEPAGE,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            path: '/',
            exact: true,
            component: BrandingPushedHomepage,
          },
        ],
      },
      {
        path: K_ROUTE_FURNITURE_HOMEPAGE,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            path: '/',
            exact: true,
            component: FurnitureHomepage,
          },
          {
            path: '/:areaSlug',
            exact: true,
            component: FurnitureHomepage,
          },
        ],
      },
      {
        path: K_ROUTE_ACCESSORIES_HOMEPAGE,
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: AccessoriesHomepage,
          },
        ],
      },
      {
        path: '/:lvl0-:style(Japandi|japandi)',
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ProductSearchPage,
          },
        ],
      },
      {
        path: '/:lvl0',
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ProductSearchPage,
          },
        ],
      },
      {
        path: '/:lvl0/:areaSlug',
        exact: true,
        component: NavbarStorefrontWithShare,
        routes: [
          {
            component: ProductSearchPage,
          },
        ],
      },
      {
        path: '*',
        component: NotFoundPage,
      },
    ],
  },
];

const s = StyleSheet.create({
  orderPage: {
    ...cStyles.width100,
    marginHorizontal: 'auto',
    maxWidth: K_MAX_ORDER_PAGE_WIDTH,
  },
});

export default routes;
