/* eslint-disable camelcase */
import keyMirror from 'keymirror';
import { normalize } from 'normalizr';

import config from 'config';

import { getCreditCardNumberWithoutSpace } from 'app-libs/etc/paymentHelper';

import { Schemas } from '../../entities';

export const AT = keyMirror({
  CHARGE_AND_ADD_NEW_CREDIT_CARD: null,
  CHARGE_AND_ADD_NEW_CREDIT_CARD_SUCCESS: null,
  CHARGE_AND_ADD_NEW_CREDIT_CARD_FAIL: null,
  CHARGE_USING_E_WALLET: null,
  CHARGE_USING_E_WALLET_SUCCESS: null,
  CHARGE_USING_E_WALLET_FAIL: null,
  CHARGE_USING_INTERNET_BANKING: null,
  CHARGE_USING_INTERNET_BANKING_SUCCESS: null,
  CHARGE_USING_INTERNET_BANKING_FAIL: null,
  CHARGE_USING_INSTALLMENT_WIHTOUT_CARD: null,
  CHARGE_USING_INSTALLMENT_WIHTOUT_CARD_SUCCESS: null,
  CHARGE_USING_INSTALLMENT_WIHTOUT_CARD_FAIL: null,
  CHARGE_EXISTING_CREDIT_CARD: null,
  CHARGE_EXISTING_CREDIT_CARD_SUCCESS: null,
  CHARGE_EXISTING_CREDIT_CARD_FAIL: null,
  CANCEL_OFFLINE_ORDER: null,
  CANCEL_OFFLINE_ORDER_SUCCESS: null,
  CANCEL_OFFLINE_ORDER_FAIL: null,
});

const initialState = {
  cancellingOfflineOrder: false,
  isChargingCreditCard: false,

  orderKey: null,
  id: null,
  number: null,
  shippingAddress: null,
  payment: {
    source: null,
    events: [],
  },
};

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Reducer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export default function orderReducer(mutableState = initialState, action = {}) {
  let state = mutableState;
  switch (action.type) {
    case AT.CANCEL_OFFLINE_ORDER:
      state = {
        ...state,
        cancellingOfflineOrder: true,
      };
      break;
    case AT.CANCEL_OFFLINE_ORDER_SUCCESS:
    case AT.CANCEL_OFFLINE_ORDER_FAIL:
      state = {
        ...state,
        cancellingOfflineOrder: false,
      };
      break;
    case AT.CHARGE_AND_ADD_NEW_CREDIT_CARD:
      state = {
        ...state,
        isChargingCreditCard: true,
      };
      break;
    case AT.CHARGE_AND_ADD_NEW_CREDIT_CARD_SUCCESS:
      state = {
        ...action.result.entities.orders[action.result.content],
        isChargingCreditCard: false,
      };
      break;
    case AT.CHARGE_AND_ADD_NEW_CREDIT_CARD_FAIL:
      state = {
        ...state,
        isChargingCreditCardError: action.error,
        isChargingCreditCard: false,
      };
      break;
    case AT.CHARGE_EXISTING_CREDIT_CARD:
      state = {
        ...state,
        isChargingCreditCard: true,
      };
      break;
    case AT.CHARGE_EXISTING_CREDIT_CARD_SUCCESS:
      state = {
        ...action.result.entities.orders[action.result.content],
        isChargingCreditCard: false,
      };
      break;
    case AT.CHARGE_EXISTING_CREDIT_CARD_FAIL:
      state = {
        ...state,
        isChargingCreditCard: false,
        isChargingCreditCardError: action.error,
      };
      break;
    default:
      break;
  }

  return state;
}

export function chargeAndAddNewCreditCardV2(
  cardNumber,
  month,
  year,
  secureToken,
  cvv,
  installmentTerm,
  installmentBank,
  orderKey,
  saveNewCreditCard,
) {
  const numberWithoutSpace = getCreditCardNumberWithoutSpace(cardNumber);
  const payload = {
    order: `${config.API_URL_GOBLIN}/orders/${orderKey}/`,
    lastFourDigits: numberWithoutSpace,
    month,
    year,
    secureToken,
    cvv,
    installmentTerm,
    installmentBank,
    saveCreditCard: saveNewCreditCard,
  };
  return {
    types: [
      AT.CHARGE_AND_ADD_NEW_CREDIT_CARD,
      AT.CHARGE_AND_ADD_NEW_CREDIT_CARD_SUCCESS,
      AT.CHARGE_AND_ADD_NEW_CREDIT_CARD_FAIL,
    ],
    payload,
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/payment-v2/credit-card/charge/`, {
        data: payload,
      }),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.ORDER);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export function chargeExistingCreditCardV2(
  initialToken,
  secureToken,
  installmentTerm,
  installmentBank,
  orderKey,
) {
  const payload = {
    order: `${config.API_URL_GOBLIN}/orders/${orderKey}/`,
    initialToken,
    secureToken,
    installmentTerm,
    installmentBank,
  };
  return {
    types: [
      AT.CHARGE_EXISTING_CREDIT_CARD,
      AT.CHARGE_EXISTING_CREDIT_CARD_SUCCESS,
      AT.CHARGE_EXISTING_CREDIT_CARD_FAIL,
    ],
    payload,
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/payment-v2/credit-card/following-charge/`,
        {
          data: payload,
        },
      ),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.ORDER);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export function chargeUsingEWallet(orderKey, walletName, callback) {
  const payload = {
    orderKey,
    walletName,
    callbackUrl: `https://dekoruma.com/orders/${orderKey}`,
  };
  return {
    types: [
      AT.CHARGE_USING_E_WALLET,
      AT.CHARGE_USING_E_WALLET_SUCCESS,
      AT.CHARGE_USING_E_WALLET_FAIL,
    ],
    payload,
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/payment-v2/e-wallet/charge/`, {
        data: payload,
      }),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.ORDER);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function chargeUsingInternetBanking(
  orderKey,
  internetBankingName,
  callback = () => null,
) {
  const payload = {
    orderKey,
    internetBankingName,
  };
  return {
    types: [
      AT.CHARGE_USING_INTERNET_BANKING,
      AT.CHARGE_USING_INTERNET_BANKING_SUCCESS,
      AT.CHARGE_USING_INTERNET_BANKING_FAIL,
    ],
    payload,
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/payment-v2/internet-banking/charge/`,
        {
          data: payload,
        },
      ),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.ORDER);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function chargeUsingInstallmentWithoutCard(
  orderKey,
  installmentAgent,
  installmentTerm,
  callback,
) {
  const payload = {
    orderKey,
    installmentAgent,
    installmentTerm,
    callbackUrl: `https://dekoruma.com/orders/${orderKey}`,
  };
  return {
    types: [
      AT.CHARGE_USING_INSTALLMENT_WIHTOUT_CARD,
      AT.CHARGE_USING_INSTALLMENT_WIHTOUT_CARD_SUCCESS,
      AT.CHARGE_USING_INSTALLMENT_WIHTOUT_CARD_FAIL,
    ],
    payload,
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/payment-v2/installment-without-card/charge/`,
        {
          data: payload,
        },
      ),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.ORDER);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function cancelOfflineOrder({ orderNumber, callback = () => null }) {
  return {
    types: [
      AT.CANCEL_OFFLINE_ORDER,
      AT.CANCEL_OFFLINE_ORDER_SUCCESS,
      AT.CANCEL_OFFLINE_ORDER_FAIL,
    ],
    promise: (client) =>
      client.put(
        `${config.API_URL_GOBLIN}/orders/cancel-offline-order/?format=json`,
        {
          data: {
            orderNumber,
          },
        },
      ),
    options: {
      callback,
    },
  };
}
