import React, { memo } from 'react';
import { ViewStyle } from 'react-native';

import { StyleSheet, Link, Colors, View, Text } from '@dkrm/ui-kit-basic';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import cStyles from '@dkrm/general-libs/theme/styles';

const K_ICON_SIZE = 72;

interface USPCardProps {
  iconUri: string;
  title: string;
  description: string;
  linkUrl: string;
  style?: ViewStyle;
  shouldHideDescription?: boolean;
}

const USPCard: React.FC<USPCardProps> = memo(
  ({ iconUri, title, description, linkUrl, style, shouldHideDescription }) => (
    <View style={[s.container, style]}>
      <Link
        to={linkUrl}
        showUnderlineOnHover={false}
        isUsingAnchor
        shouldOpenNewTab
      >
        <View style={s.innerContainer}>
          <SmartImage
            source={iconUri}
            width={K_ICON_SIZE}
            height={K_ICON_SIZE}
            style={s.icon}
            resizeMode="contain"
            shouldPreloadSmallerVersion={false}
          />
          <View style={s.textContainer}>
            <Text theme="h4">{title}</Text>
            {!shouldHideDescription && (
              <Text theme="body4-black60" style={s.description}>
                {description}
              </Text>
            )}
          </View>
        </View>
      </Link>
    </View>
  ),
);

const s = StyleSheet.create({
  container: {
    ...cStyles.pam,
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.flex1,
    alignItems: 'center',
    borderColor: Colors.C_BLACK_20,
  },
  innerContainer: {
    ...cStyles.flexMiddleLeft,
    ...cStyles.flexDirRow,
  },
  icon: {
    ...cStyles.mrm,
  },
  textContainer: cStyles.flex1,
});

export default USPCard;
