import { schema } from 'normalizr';

const advertisementSchema = new schema.Entity(
  'advertisementListsByLocation',
  {},
  { idAttribute: 'name' },
);
const advertisementCollectionSchema = [advertisementSchema];

export const Schemas = {
  ADVERTISEMENT: advertisementSchema,
  ADVERTISEMENT_COLLECTION: advertisementCollectionSchema,
};
