import { StyleSheet } from 'react-native';
import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    minWidth: '100%',
    borderRadius: 4,
    ...cStyles.paxxl,
    ...cStyles.flexDirRow,
  },
  snackBar: {
    ...cStyles.width100,
    backgroundColor: colors.C_SECONDARY_TOSCA_5,
    ...cStyles.paxl,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    alignItems: 'center',
    shadowColor: '#002022',
    shadowOffset: {
      width: 0.5,
      height: 2,
    },
    shadowRadius: 15,
    shadowOpacity: 0.3,
    elevation: 6,
    borderRadius: 4,
  },
  stackedSnackBar: {
    ...cStyles.flexDirCol,
    ...cStyles.flexLeftMiddle,
    ...cStyles.pbm,
  },
  text: {
    color: colors.C_WHITE_100,
  },
  icon: {
    marginRight: 14,
  },
  iconAndTextContainer: {
    ...cStyles.flexDirRow,
    flex: 1,
    alignItems: 'center',
  },
  actionButtonContainerBottom: {
    ...cStyles.mtm,
    alignSelf: 'flex-end',
  },
  actionButton: {
    ...cStyles.pam,
    ...cStyles.man,
  },
  actionButtonText: {
    color: colors.C_SECONDARY_TOSCA_3,
  },
});
