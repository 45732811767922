/* eslint-disable camelcase */
import crypto from 'crypto';
import superagent from 'superagent';

import config from 'config';

import {
  getPlatform,
  isWebView,
} from '@dkrm/general-libs/Utils/userAgentDetector';

import { dayjs } from 'app-libs/etc/dateHelperV2';
import { User } from 'app-libs/redux_modules/auth/types';

declare const pinterestc: any;

const K_PINTEREST_PIXEL_ID_RETAIL = '549768030136';
const K_PINTEREST_PIXEL_ID_INTERIOR = '549768030136';
const K_PINTEREST_API_VERSION = 'v5';
const K_HASH_ALGORITHM = 'sha256';

const K_PINTEREST_CAPI_ENDPOINT_RETAIL = `https://api.pinterest.com/${K_PINTEREST_API_VERSION}/ad_accounts/${K_PINTEREST_PIXEL_ID_RETAIL}/events`;
const K_PINTEREST_CAPI_ENDPOINT_INTERIOR = `https://api.pinterest.com/${K_PINTEREST_API_VERSION}/ad_accounts/${K_PINTEREST_PIXEL_ID_INTERIOR}/events`;
const K_IPIFY_ENDPOINT = 'https://api.ipify.org?format=json';

/*
  @note(radit) 28 Aug 2024: Pinterest Capi documentation
  https://developers.pinterest.com/payload-helper/
  https://ads.pinterest.com/advertiser/549768030136/conversions/setup-api/
*/

interface IpifyResponse {
  body: {
    ip: string;
  };
}

const pinterestCapiWrapper = {
  _generateEventTime: () => {
    return dayjs().unix();
  },
  _generateActionSource: () => {
    if (!isWebView()) return 'website';
    const platform = getPlatform();
    if (platform === 'ios') {
      return 'app_ios';
    }
    if (platform === 'android') {
      return 'app_android';
    }

    /* @note (Radit) 28 Aug 2024: should not reach this part */
    return 'invalid_platform';
  },
  _getClientIpAddress: async () => {
    await superagent.get(K_IPIFY_ENDPOINT).then((res: IpifyResponse) => {
      if (res?.body?.ip) {
        pinterestc.user.client_ip_address = res?.body?.ip;
      }
    });
  },
  _getSourceUrl: () => {
    return window?.location?.href ?? '';
  },
  logEvent: async (
    eventName: string,
    customData: Record<string, any>,
    eventID = '',
    businessUnit = 'retail',
  ) => {
    let endpoint =
      businessUnit === 'retail'
        ? K_PINTEREST_CAPI_ENDPOINT_RETAIL
        : K_PINTEREST_CAPI_ENDPOINT_INTERIOR;
    if (!config.isProduction) {
      endpoint += '?test=true';
    }

    let data: Record<string, any>[] = [
      {
        event_name: eventName,
        event_time: pinterestCapiWrapper._generateEventTime(),
        event_source_url: pinterestCapiWrapper._getSourceUrl(),
        action_source: pinterestCapiWrapper._generateActionSource(),
        user_data: pinterestc.user,
        custom_data: customData,
      },
    ];

    if (eventID) {
      data = [
        {
          ...data[0],
          event_id: eventID,
        },
      ];
    }

    superagent
      .post(endpoint)
      .set('Authorization', `Bearer ${config.PINTEREST_CAPI_ACCESS_TOKEN}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .send({ data })
      .end(() => null);
  },
  identify: async (user?: User) => {
    if (!pinterestc.user || Object.keys(pinterestc.user).length === 0) {
      pinterestc.user = {};
    }
    /* @note(radit) 28 Aug 2024: Hash normalized user data using sha256, same with fbCapi tracker.

      Email: Normalize by trimming any spaces and converting to lower case
      e.g. ` AndikA.Kusuma@Dekoruma.com ` to `andika.kusuma@dekoruma.com`
      Phone Number: Normalize by removing + sign
      e.g. `+628121979000` to `628121979000`
    */
    if (user?.email) {
      const normalizedEmail = user.email.trim().toLowerCase();
      pinterestc.user.em = [
        crypto
          .createHash(K_HASH_ALGORITHM)
          .update(normalizedEmail)
          .digest('hex'),
      ];
    } else {
      delete pinterestc.user.em;
    }

    if (user?.phoneNumber) {
      const normalizedPhoneNumber = user.phoneNumber.replace('+', '');
      pinterestc.user.ph = [
        crypto
          .createHash(K_HASH_ALGORITHM)
          .update(normalizedPhoneNumber)
          .digest('hex'),
      ];
    } else {
      delete pinterestc.user.ph;
    }

    if (user?.uuid) {
      pinterestc.user.external_id = [
        crypto.createHash(K_HASH_ALGORITHM).update(user.uuid).digest('hex'),
      ];
    } else {
      delete pinterestc.user.external_id;
    }

    if (navigator?.userAgent && !pinterestc.user.client_user_agent) {
      pinterestc.user.client_user_agent = navigator?.userAgent;
    }

    if (!pinterestc.user.client_ip_address) {
      await pinterestCapiWrapper._getClientIpAddress();
    }
  },
};

export default pinterestCapiWrapper;
