import React, { memo, useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';

import { ReflowView, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { useLocation } from 'app-libs/hook_modules/router';

import { getHostnameFromUrl, getPathFromUrl } from 'app-libs/etc/routeHelper';
import { K_DKRMA_HOSTNAME } from 'constants/routeConstants';
import { trackClickedBannerSlideritem } from 'app-libs/trackers';

import cStyles from '@dkrm/general-libs/theme/styles';

import { Banner } from 'app-libs/redux_modules/entity_modules/advertisement/types';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

interface CampaignBannerSliderItemProps {
  banner: Banner;
  height: number;
  width: number;
}

const CampaignBannerSliderItem: React.FC<CampaignBannerSliderItemProps> = memo(
  ({ banner: { name, url, imageUrl }, height, width }) => {
    const location = useLocation();
    const linkUrl = url || '';

    const isUsingAnchor = getHostnameFromUrl(linkUrl) === K_DKRMA_HOSTNAME;

    const to =
      getHostnameFromUrl(linkUrl) !== K_DKRMA_HOSTNAME
        ? getPathFromUrl(linkUrl)
        : linkUrl;

    const actionTrackClickedBannerSliderItem = useCallback(() => {
      trackClickedBannerSlideritem(location?.pathname, to);
    }, [location, to]);

    return (
      <ButtonWrapper
        to={to}
        onPress={actionTrackClickedBannerSliderItem}
        linkProps={{
          isUsingAnchor,
        }}
        disabled={isEmpty(linkUrl)}
      >
        {() => (
          <View style={cStyles.flex1}>
            <ReflowView heightProportion={height} widthProportion={width}>
              <SmartImage
                accessibilityLabel={name}
                source={imageUrl}
                style={cStyles.flex1}
                resizeMode="contain"
              />
            </ReflowView>
          </View>
        )}
      </ButtonWrapper>
    );
  },
);

export default CampaignBannerSliderItem;
