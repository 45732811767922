import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  getLoadCategorySEOQueryKey,
  getLoadSEOAreasQueryKey,
  getLoadSEOArticleQueryKey,
  getLoadSEOTopSearchQueriesQueryKey,
  loadCategorySEO,
  loadSEOAreas,
  loadSEOArticle,
  loadSEOTopSearchQueries,
} from './api';
import { LoadCategorySEOPayload } from './types';

const K_FOUR_HOUR_IN_MILLISECONDS = 4 * 3600 * 1000;
const K_TWO_HOUR_IN_MILLISECONDS = 2 * 3600 * 1000;

export const useQueryLoadSEOAreas = () => {
  const { data, isError, isSuccess, isLoading, error } = useQuery(
    getLoadSEOAreasQueryKey(),
    () => loadSEOAreas(),
    {
      enabled: true,
      staleTime: K_TWO_HOUR_IN_MILLISECONDS,
      cacheTime: K_FOUR_HOUR_IN_MILLISECONDS,
      refetchOnWindowFocus: false,
    },
  );

  const areas = useMemo(() => data || [], [data]);

  return {
    areas,
    isError,
    isSuccess,
    isLoading,
    error,
  };
};

export const useQueryLoadSEOTopSearchQueries = () => {
  const { data, isError, isSuccess, isLoading, error } = useQuery(
    getLoadSEOTopSearchQueriesQueryKey(),
    () => loadSEOTopSearchQueries(),
    {
      staleTime: K_TWO_HOUR_IN_MILLISECONDS,
      cacheTime: K_FOUR_HOUR_IN_MILLISECONDS,
      refetchOnWindowFocus: false,
    },
  );

  const topSearchQueries = useMemo(() => data?.results || [], [data]);

  return {
    topSearchQueries,
    isError,
    isSuccess,
    isLoading,
    error,
  };
};

export const useQueryLoadCategorySEO = (payload: LoadCategorySEOPayload) => {
  return useQuery(
    getLoadCategorySEOQueryKey(payload),
    () => loadCategorySEO(payload),
    {
      staleTime: K_TWO_HOUR_IN_MILLISECONDS,
      cacheTime: K_FOUR_HOUR_IN_MILLISECONDS,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );
};

export const useQueryLoadSEOArticle = (articleId?: string) => {
  return useQuery(
    getLoadSEOArticleQueryKey(articleId),
    () => loadSEOArticle(articleId),
    {
      enabled: !!articleId,
      retry: 1,
      staleTime: K_TWO_HOUR_IN_MILLISECONDS,
      cacheTime: K_FOUR_HOUR_IN_MILLISECONDS,
      refetchOnWindowFocus: false,
    },
  );
};
