import React from 'react';
import { View, Text, StyleSheet, Colors } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

export interface TopCategorySectionItemProps {
  label: string;
}
export const TopCategorySectionItem: React.FC<TopCategorySectionItemProps> = ({
  label,
}) => {
  return (
    <View style={s.container}>
      <Text theme="body3-black" style={{ color: Colors.C_BLACK_80 }}>
        {label}
      </Text>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.flexDirRow,
    ...cStyles.prxl,
  },
});

export default TopCategorySectionItem;
