import keyMirror from 'keymirror';
import config from 'config';
import { loadMoodboardWithProductsAndInitialize } from 'app-libs/redux_modules/flow_modules/browse';

export const AT = keyMirror({
  GET_VOUCHERS: null,
  GET_VOUCHERS_FAIL: null,
  GET_VOUCHERS_SUCCESS: null,
  GET_VOUCHER_DETAIL: null,
  GET_VOUCHER_DETAIL_SUCCESS: null,
  GET_VOUCHER_DETAIL_FAIL: null,
  GET_PRODUCT_VOUCHERS: null,
  GET_PRODUCT_VOUCHERS_SUCCESS: null,
  GET_PRODUCT_VOUCHERS_FAIL: null,
});

export const initialState = {
  loading: false,
  loaded: false,
  error: null,

  vouchers: [],
  voucherDetail: {
    name: '',
    code: '',
    description: '',
    period: '',
    usage: '',
    moodboardId: '',
    includedBrands: [],
    includedCategories: [],
    discountValue: '',
    discountType: '',
    bannerUrl: '',
    termsAndConditions: [],
    usageTerms: [],
  },

  productVouchers: [],
  productVouchersLoading: false,
  productVouchersLoaded: false,
  productVouchersError: null,
};

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Reducer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
export default function promotionReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;
  let changedState = {};

  switch (action.type) {
    case AT.GET_VOUCHERS:
      changedState = {
        loading: true,
        error: null,
      };
      state = Object.assign({}, state, changedState);
      break;
    case AT.GET_VOUCHERS_FAIL:
      changedState = {
        loading: false,
        error: action.result,
        vouchers: [],
      };
      state = Object.assign({}, state, changedState);
      break;
    case AT.GET_VOUCHERS_SUCCESS:
      changedState = {
        loading: false,
        loaded: true,
        error: null,
        vouchers: [...action.result.results],
      };
      state = Object.assign({}, state, changedState);
      break;
    case AT.GET_PRODUCT_VOUCHERS:
      changedState = {
        productVouchersLoading: true,
        productVouchersError: null,
      };
      state = Object.assign({}, state, changedState);
      break;
    case AT.GET_PRODUCT_VOUCHERS_SUCCESS:
      changedState = {
        productVouchersLoading: false,
        productVouchersLoaded: true,
        productVouchersError: null,
        productVouchers: [...action.result],
      };
      state = Object.assign({}, state, changedState);
      break;
    case AT.GET_PRODUCT_VOUCHERS_FAIL:
      changedState = {
        productVouchersLoading: false,
        productVouchersError: action.result,
        productVoucher: [],
      };
      state = Object.assign({}, state, changedState);
      break;
    /*
     * Detail Purchase Order
     */
    case AT.GET_VOUCHER_DETAIL_SUCCESS:
      state.voucherDetail = Object.assign({}, action.result);
      break;
  }

  return state;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Action Creator
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
export function loadVouchers() {
  return {
    types: [AT.GET_VOUCHERS, AT.GET_VOUCHERS_SUCCESS, AT.GET_VOUCHERS_FAIL],
    promise: (client) =>
      client.get(`${config.API_URL_GOBLIN}/vouchers/?format=json`),
  };
}

export function loadVoucherDetail(voucherCode) {
  return {
    types: [
      AT.GET_VOUCHER_DETAIL,
      AT.GET_VOUCHER_DETAIL_SUCCESS,
      AT.GET_VOUCHER_DETAIL_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/vouchers/${voucherCode}/?format=json`,
      ),
  };
}

export function loadProductVouchers(upc) {
  return {
    types: [
      AT.GET_PRODUCT_VOUCHERS,
      AT.GET_PRODUCT_VOUCHERS_SUCCESS,
      AT.GET_PRODUCT_VOUCHERS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/products/${upc}/vouchers/?format=json`,
      ),
  };
}

export function loadVoucherDetailWithMoodboard(voucherCode) {
  return async (dispatch, getState) => {
    await dispatch(loadVoucherDetail(voucherCode));
    const { voucherDetail } = getState().promotion;
    const { moodboardId } = voucherDetail;
    return dispatch(loadMoodboardWithProductsAndInitialize(moodboardId));
  };
}
