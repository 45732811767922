import config from 'config';

import { K_BANK_LOGO, K_CREDIT_CARD_TYPE } from 'constants/bankConstants';

export const PAYMENT_METHODS = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  CREDIT_CARD: 'CREDIT_CARD',
  INSTALLMENT_WITHOUT_CARD: 'INSTALLMENT_WITHOUT_CARD',
  E_WALLET: 'E_WALLET',
  CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
  CREDIT_CARD_INSTALLMENT: 'CREDIT_CARD_INSTALLMENT',
  CASH: 'CASH',
  EDC: 'EDC',
  PAY_LATER: 'PAY_LATER',
  PAYMENT_LINK: 'PAYMENT_LINK',
  INTERNET_BANKING: 'INTERNET_BANKING',
  QRIS: 'QRIS',
  NONE: 'NONE',
};

export const K_PAYMENT_METHODS_TO_SHOW_HELP_BUTTON = [PAYMENT_METHODS.E_WALLET];

export const INSTANT_PAYMENT_METHODS = [
  PAYMENT_METHODS.E_WALLET,
  PAYMENT_METHODS.INTERNET_BANKING,
  PAYMENT_METHODS.INSTALLMENT_WITHOUT_CARD,
];

export const K_PAYMENT_STATUS_PENDING = 'pending';

/**
 * Sample option values:
 * {
 *   label: 'OVO',
 *   value: 'OVO',
 *   logo: K_E_WALLET_LOGO.OVO, // omit for no logo
 *   new: true, // omit to hide "BARU" badge
 *   discountText: 'Cashback 20%' // omit to hide badge
 * },
 */

/**
 * Bank Transfer
 */
export const K_BANK_TRANSFER_OPTIONS = [
  {
    label: 'Transfer Bank',
    value: PAYMENT_METHODS.BANK_TRANSFER,
  },
];

export const K_BANK_TRANSFER_BANK_LOGOS = [
  K_BANK_LOGO.BCA,
  K_BANK_LOGO.MANDIRI,
];

export const K_BANK_TRANSFER_BCA_LINE =
  'Pembayaran melalui Bank BCA akan dikonfirmasi secara otomatis dalam 5 menit. Pastikan jumlah yang ditransfer tepat.';
export const K_BANK_TRANSFER_INSTRUCTION_DESC_LINE_1 =
  'Transfer dapat dilakukan melalui ATM, Internet Banking, SMS Banking, atau Mobile Banking setelah pesanan dibuat.';
export const K_BANK_TRANSFER_INSTRUCTION_DESC_LINE_2 =
  'Instruksi lebih lanjut mengenai bagaimana cara transfer akan diberikan setelah halaman ini.';

export const K_BANK_TRANSFER_DESCRIPTION = [
  K_BANK_TRANSFER_BCA_LINE,
  K_BANK_TRANSFER_INSTRUCTION_DESC_LINE_1,
  K_BANK_TRANSFER_INSTRUCTION_DESC_LINE_2,
];

/**
 * Credit/Debit Card
 */
export const K_CC_FULL_PAYMENT_VALUE = 'full';
export const K_CC_INSTALLMENT_INFO_URL =
  'https://dekoruma.com/promosi/cicilan-0-persen-retail';

export const K_CC_VALUES = [
  K_CREDIT_CARD_TYPE.MASTERCARD,
  K_CREDIT_CARD_TYPE.VISA,
  K_CREDIT_CARD_TYPE.AMEX,
  K_CREDIT_CARD_TYPE.JCB,
];

export const K_CC_LOGO_BY_CC_TYPE = {
  [K_CREDIT_CARD_TYPE.MASTERCARD]: `${config.API_URL_MEDIA_CDN}/assets/bank/mastercard-2.png?auto=webp`,
  [K_CREDIT_CARD_TYPE.VISA]: `${config.API_URL_MEDIA_CDN}/assets/bank/visa-2.png?auto=webp`,
  [K_CREDIT_CARD_TYPE.AMEX]: `${config.API_URL_MEDIA_CDN}/assets/bank/amex-3.png?auto=webp`,
  [K_CREDIT_CARD_TYPE.JCB]: `${config.API_URL_MEDIA_CDN}/assets/bank/jcb-2.png?auto=webp`,
};

export const K_CC_LOGOS = [
  `${config.API_URL_MEDIA_CDN}/assets/bank/visa-2.png?auto=webp`,
  `${config.API_URL_MEDIA_CDN}/assets/bank/mastercard-2.png?auto=webp`,
  `${config.API_URL_MEDIA_CDN}/assets/bank/amex-3.png?auto=webp`,
  `${config.API_URL_MEDIA_CDN}/assets/bank/jcb-2.png?auto=webp`,
];

export const K_CREDIT_CARD_OPTIONS = [
  {
    label: 'Kartu Kredit/Debit',
    value: 'CREDIT_CARD',
  },
];

export const K_FAILED_CREDIT_CARD_DEFAULT_MESSAGE =
  'Mohon hubungi call center pihak bank penerbit kartu kredit Anda.';

export const K_FAILED_MESSAGE_BY_CREDIT_CARD_STATUS = {
  '05': 'terdapat kesalahan pada kartu Anda.',
  51: 'dana Anda tidak cukup.',
  P3: 'minimum transaksi belum tercapai.',
};

/**
 * Installment w/o Card
 */
export const K_INSTALLMENT_KREDIVO_VALUE = 'KREDIVO';

export const K_INSTALLMENT_LABELS = {
  [K_INSTALLMENT_KREDIVO_VALUE]: 'Kredivo',
};

export const K_INSTALLMENT_LOGO = {
  [K_INSTALLMENT_KREDIVO_VALUE]: `${config.API_URL_MEDIA_CDN}/assets/kredivo.png?auto=webp`,
};

export const K_INSTALLMENT_OPTIONS = [
  {
    label: K_INSTALLMENT_LABELS[K_INSTALLMENT_KREDIVO_VALUE],
    value: K_INSTALLMENT_KREDIVO_VALUE,
    logo: K_INSTALLMENT_LOGO[K_INSTALLMENT_KREDIVO_VALUE],
  },
];

export const K_KREDIVO_DESC_LINE_1 =
  'Maksimal Rp 3,000,000 untuk bayar dalam durasi 30 hari';
export const K_KREDIVO_DESC_LINE_2 =
  'Rp 1,000,000 - Rp 30,000,000 untuk cicilan 3 bulan (bunga 0%)';
export const K_KREDIVO_DESC_LINE_3 =
  'Rp 1,000,000 - Rp 30,000,000 untuk cicilan 6 & 12 bulan (bunga 2.6%)';

export const K_INSTALLMENT_DESCRIPTIONS = {
  [K_INSTALLMENT_KREDIVO_VALUE]: [
    K_KREDIVO_DESC_LINE_1,
    K_KREDIVO_DESC_LINE_2,
    K_KREDIVO_DESC_LINE_3,
  ],
};

export const K_INSTALLMENT_KREDIVO_TERMS = [
  {
    label: 'Bayar dalam 30 hari',
    value: '30_days',
    minPayment: 0,
    maxPayment: 3000000,
    interest: 0,
  },
  {
    label: 'Cicilan 3 bulan (bunga 0%)',
    value: '3_months',
    minPayment: 1000000,
    maxPayment: 30000000,
    interest: 0,
  },
  {
    label: 'Cicilan 6 bulan (Bunga 2.6%)',
    value: '6_months',
    minPayment: 1000000,
    maxPayment: 30000000,
    interest: 2.6,
  },
  {
    label: 'Cicilan 12 bulan (Bunga 2.6%)',
    value: '12_months',
    minPayment: 1000000,
    maxPayment: 30000000,
    interest: 2.6,
  },
];

export const K_INSTALLMENT_TERMS_BY_AGENT = {
  [K_INSTALLMENT_KREDIVO_VALUE]: K_INSTALLMENT_KREDIVO_TERMS,
};

export const K_INSTALLMENT_KREDIVO_ADDITIONAL_INFO = [
  'Siapkan dokumen berikut ini untuk diunggah (khusus pengguna baru Kredivo):',
  'Kartu identitas (KTP)',
  '2 (Dua) bukti tempat tinggal (kartu keluarga / STNK / tagihan TV kabel / tagihan telepon / tagihan kartu kredit)',
  '2 (Dua) bukti penghasilan (slip gaji / surat gaji kantor / rekening koran / laporan pajak terbaru)',
];

export const K_INSTALLMENT_ADDITIONAL_INFO_BY_AGENT = {
  [K_INSTALLMENT_KREDIVO_VALUE]: K_INSTALLMENT_KREDIVO_ADDITIONAL_INFO,
};

export const K_INSTALLMENT_LOGO_BY_INSTALLMENT_PARTNER_NAME = {
  ...K_BANK_LOGO,
  BANK_MANDIRI: K_BANK_LOGO.MANDIRI,
  BANK_BCA: K_BANK_LOGO.BCA,
  [K_INSTALLMENT_LABELS[
    K_INSTALLMENT_KREDIVO_VALUE
  ]]: `${config.API_URL_MEDIA_CDN}/assets/kredivo.png?auto=webp`,
};

/**
 * e-Wallet
 */
export const K_E_WALLET_GOPAY_VALUE = 'GOPAY';
export const K_E_WALLET_OVO_VALUE = 'OVO';
export const K_E_WALLET_DANA_VALUE = 'DANA';

export const K_E_WALLET_LOGO = {
  [K_E_WALLET_GOPAY_VALUE]: `${config.API_URL_MEDIA_CDN}/assets/e-wallet/go-pay.png?auto=webp`,
  [K_E_WALLET_OVO_VALUE]: `${config.API_URL_MEDIA_CDN}/assets/e-wallet/ovo.png?auto=webp`,
  [K_E_WALLET_DANA_VALUE]: `${config.API_URL_MEDIA_CDN}/assets/e-wallet/dana.png?auto=webp`,
};

export const K_E_WALLET_LABELS = {
  [K_E_WALLET_GOPAY_VALUE]: 'GoPay',
  [K_E_WALLET_OVO_VALUE]: 'OVO',
  [K_E_WALLET_DANA_VALUE]: 'DANA',
};

export const K_E_WALLET_OPTIONS = [
  {
    label: K_E_WALLET_LABELS[K_E_WALLET_GOPAY_VALUE],
    value: K_E_WALLET_GOPAY_VALUE,
    logo: K_E_WALLET_LOGO[K_E_WALLET_GOPAY_VALUE],
  },
  {
    label: K_E_WALLET_LABELS[K_E_WALLET_OVO_VALUE],
    value: K_E_WALLET_OVO_VALUE,
    logo: K_E_WALLET_LOGO[K_E_WALLET_OVO_VALUE],
  },
];

export const getEWalletDescription = (walletName) =>
  `Batas waktu pembayaran untuk ${walletName} adalah 1 × 24 jam sejak Anda melakukan pemesanan.`;

/** Internet Banking */
export const K_INTERNET_BANKING_BCA_KLIKPAY_VALUE = 'BCA_KLIKPAY';
export const K_INTERNET_BANKING_CIMB_CLICKS_VALUE = 'CIMB_CLICKS';

export const K_INTERNET_BANKING_LABEL_BY_VALUE = {
  [K_INTERNET_BANKING_BCA_KLIKPAY_VALUE]: 'BCA KlikPay',
  [K_INTERNET_BANKING_CIMB_CLICKS_VALUE]: 'CIMB Clicks',
};

export const K_INTERNET_BANKING_LOGO_BY_VALUE = {
  [K_INTERNET_BANKING_BCA_KLIKPAY_VALUE]: `${config.API_URL_MEDIA_CDN}/assets/bank/bca-klikpay.jpg?auto=webp`,
  [K_INTERNET_BANKING_CIMB_CLICKS_VALUE]: `${config.API_URL_MEDIA_CDN}/assets/bank/cimb-clicks.png?auto=webp`,
};

export const K_INTERNET_BANKING_OPTIONS = [
  {
    label:
      K_INTERNET_BANKING_LABEL_BY_VALUE[K_INTERNET_BANKING_BCA_KLIKPAY_VALUE],
    value: K_INTERNET_BANKING_BCA_KLIKPAY_VALUE,
    logo: K_INTERNET_BANKING_LOGO_BY_VALUE[
      K_INTERNET_BANKING_BCA_KLIKPAY_VALUE
    ],
    helpLink: 'https://dkr.ma/BCAKlikpay',
  },
];

export const getInternetBankingDescription = (internetBankingName) =>
  `Batas waktu pembayaran untuk ${internetBankingName} adalah 1x24 jam sejak Anda melakukan pemesanan.`;

/** COD */
export const K_COD_LABEL = 'Bayar di Tempat';
export const K_COD_VALUE = 'CASH_ON_DELIVERY';

export const K_COD_OPTIONS = [
  {
    label: K_COD_LABEL,
    value: K_COD_VALUE,
  },
];

const K_COD_DESC_LINE_1 =
  'Barang yang anda pesan akan langsung dikirim. Ketika barang Anda sampai, mohon membayar sesuai total transaksi kepada kurir kami.';
const K_COD_DESC_LINE_2 =
  'Mohon menyiapkan uang pas untuk memudahkan transaksi.';

const K_COD_DISALLOW_AREA_LINE_1 =
  'Maaf, daerah Anda belum terjangkau layanan COD kami.';
const K_COD_DISALLOW_SHIPPING_CLASS_LINE_1 =
  'Maaf, produk yang Anda beli belum dapat dikirim melalui layanan COD kami.';

export const K_COD_DESCRIPTIONS = [K_COD_DESC_LINE_1, K_COD_DESC_LINE_2];

export const K_COD_DISALLOW_AREA_DESCRIPTIONS = [K_COD_DISALLOW_AREA_LINE_1];
export const K_COD_DISALLOW_SHIPPING_CLASS_DESCRIPTIONS = [
  K_COD_DISALLOW_SHIPPING_CLASS_LINE_1,
];

/** CC Installment */
export const K_CREDIT_CARD_INSTALLMENT_OPTIONS = [
  {
    label: 'Cicilan 0%',
    value: 'CREDIT_CARD_INSTALLMENT',
  },
];

/** Offline Payment */
export const K_CASH_LABEL = 'Bayar di Tempat';
export const K_CASH_VALUE = 'CASH';
export const K_CASH_OPTIONS = [
  {
    label: K_CASH_LABEL,
    value: K_CASH_VALUE,
  },
];

export const K_EDC_LABEL = 'Bayar langsung menggunakan EDC';
export const K_EDC_VALUE = 'EDC';
export const K_EDC_OPTIONS = [
  {
    label: K_EDC_LABEL,
    value: K_EDC_VALUE,
  },
];

export const K_PAY_LATER_LABEL = 'Bayar menggunakan Pay Later';
export const K_PAY_LATER_VALUE = 'PAY_LATER';
export const K_PAY_LATER_OPTIONS = [
  {
    label: K_PAY_LATER_LABEL,
    value: K_PAY_LATER_VALUE,
  },
];
export const K_PAY_LATER_INDODANA = 'Indodana';
export const K_PAY_LATER_KREDIVO = 'Kredivo';
export const K_PAY_LATER_TRAVELOKA = 'Traveloka Pay Later';
export const K_PAY_LATER_AKULAKU = 'Akulaku';
export const K_PAY_LATER_HOME_CREDIT = 'Home Credit';
export const K_PAY_LATER_PROVIDER_OPTIONS = [
  {
    label: K_PAY_LATER_INDODANA,
    value: K_PAY_LATER_INDODANA,
  },
  {
    label: K_PAY_LATER_KREDIVO,
    value: K_PAY_LATER_KREDIVO,
  },
  {
    label: K_PAY_LATER_TRAVELOKA,
    value: K_PAY_LATER_TRAVELOKA,
  },
  {
    label: K_PAY_LATER_AKULAKU,
    value: K_PAY_LATER_AKULAKU,
  },
  {
    label: K_PAY_LATER_HOME_CREDIT,
    value: K_PAY_LATER_HOME_CREDIT,
  },
];

/** ALL PAYMENT METHODS */
export const K_PAYMENT_METHOD_BANK_TRANSFER_TEXT = 'Transfer Bank';
export const K_PAYMENT_METHOD_CREDIT_CARD_TEXT = 'Kartu Kredit/Debit';
export const K_PAYMENT_METHOD_INSTALLMENT = 'Cicilan Tanpa Kartu Kredit';
export const K_PAYMENT_METHOD_E_WALLET_TEXT = 'E-Wallet';
export const K_PAYMENT_METHOD_COD_TEXT = 'Bayar di Tempat';
export const K_PAYMENT_METHOD_INSTALLMENT_TEXT = 'Cicilan 0%';
export const K_PAYMENT_METHOD_CASH_TEXT = 'Bayar tunai di kasir';
export const K_PAYMENT_METHOD_EDC_TEXT = 'Bayar langsung menggunakan EDC';
export const K_PAYMENT_METHOD_PAY_LATER_TEXT = 'Pay Later';

export const K_PAYMENT_METHOD_BANK_TRANSFER_TEXT_SHORT = 'Transfer';
export const K_PAYMENT_METHOD_CREDIT_CARD_TEXT_SHORT = 'Kartu Kredit';

export const K_PAYMENT_METHODS = [
  {
    title: K_PAYMENT_METHOD_BANK_TRANSFER_TEXT,
    value: PAYMENT_METHODS.BANK_TRANSFER,
    options: K_BANK_TRANSFER_OPTIONS,
    logoOptions: K_BANK_TRANSFER_BANK_LOGOS,
  },
  {
    title: K_PAYMENT_METHOD_CREDIT_CARD_TEXT,
    value: PAYMENT_METHODS.CREDIT_CARD,
    options: K_CREDIT_CARD_OPTIONS,
    logoOptions: K_CC_LOGOS,
    micrositeUrl: K_CC_INSTALLMENT_INFO_URL,
  },
  {
    title: K_PAYMENT_METHOD_INSTALLMENT,
    value: PAYMENT_METHODS.INSTALLMENT_WITHOUT_CARD,
    options: K_INSTALLMENT_OPTIONS,
  },
  {
    title: K_PAYMENT_METHOD_E_WALLET_TEXT,
    value: PAYMENT_METHODS.E_WALLET,
    options: K_E_WALLET_OPTIONS,
  },
];

export const K_HUMANIZED_PAYMENT_CHOICES_BY_PAYMENT_OPTIONS = {
  [PAYMENT_METHODS.BANK_TRANSFER]: 'Bank Transfer',
  [PAYMENT_METHODS.CREDIT_CARD]: 'Kartu Kredit',
  [PAYMENT_METHODS.CREDIT_CARD_INSTALLMENT]: 'Cicilan Kartu Kredit',
  [PAYMENT_METHODS.E_WALLET]: 'E-WALLET',
  [PAYMENT_METHODS.INTERNET_BANKING]: 'Internet Banking',
  [PAYMENT_METHODS.INSTALLMENT_WITHOUT_CARD]: 'Cicilan Tanpa Kartu Kredit',
  [PAYMENT_METHODS.CASH_ON_DELIVERY]: 'COD',
  [K_E_WALLET_GOPAY_VALUE]: 'GoPay',
  [K_E_WALLET_OVO_VALUE]: 'OVO',
  [K_INTERNET_BANKING_BCA_KLIKPAY_VALUE]: 'BCA-KlikPay',
  [K_INSTALLMENT_KREDIVO_VALUE]: 'Kredivo',
};

export const K_DEFAULT_CREDIT_CARD = 'default';

/** 3rd party payment related */
export const K_GOPAY_WALLET_MIDTRANS_KEY = 'gopay';
export const K_OVO_WALLET_FASPAY_KEY = 'ovo';

export const K_CREDIT_CARD_TRANSACTION_STATUS_CHALLENGE = 'challenge';
export const K_CREDIT_CARD_TRANSACTION_STATUS_PENDING = 'pending';
export const K_CREDIT_CARD_TRANSACTION_STATUS_REJECTED = 'rejected';

/* Payment event types */
export const PAYMENT_EVENTS = {
  ISSUED: 'ISSUED',
  REQUESTED_TO_VERIFY: 'REQUESTED_TO_VERIFY',
  RECEIVED: 'RECEIVED',
  REFUNDED: 'REFUNDED',
};
