import get from 'lodash/get';

import { unformatPrice } from 'app-libs/etc';

const K_DEFAULT_PROJECT_KEY = '04173';

const genProductLineItems = (lines = []) =>
  lines.map((line) => ({
    id: line.product.objectID,
    price: unformatPrice(line.product.price),
    quantity: line.quantity,
  }));

export function genBasketSer(basket) {
  const items = genProductLineItems(basket.lines);
  return { item: items };
}

export function genPurchase(order, lines, paymentMethod) {
  const items = genProductLineItems(lines);
  return {
    id: order.number,
    item: items,
    paymentMethod,
  };
}

export function genPortfolioIdsSer(portfolioList) {
  const portfolioIds = portfolioList.map((o) => o.projectKey);
  return portfolioIds;
}

export function genPortfolioSer(portfolio) {
  return portfolio.projectKey;
}

export function genPhotoRecordIdSer(photoRecord) {
  const topThreeItems =
    get(photoRecord, 'image.itemTags', [])
      .slice(0, 3)
      .map((hit) => hit.upc) || [];
  return topThreeItems;
}

export function genAskPriceWithPortfolioSer(project, user = undefined) {
  return {
    email: get(user, 'email', ''),
    item: [
      {
        id: get(project, 'projectKey', K_DEFAULT_PROJECT_KEY),
        price: '1',
        quantity: '1',
      },
    ],
  };
}

export function getProjectCreatedSer(
  project,
  referralPortfolioId = K_DEFAULT_PROJECT_KEY,
) {
  return {
    id: get(project, 'projectKey'),
    item: [
      {
        id: referralPortfolioId,
        price: '1',
        quantity: '1',
      },
    ],
  };
}
