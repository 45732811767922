import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Text } from '@dkrm/ui-kit-basic';

import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import styles from './styles';

export default class BeddingSizeItem extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { onPress, label, value } = this.props;
    return onPress(label, value);
  };

  render() {
    const { label } = this.props;
    const [title, subtitle = ''] = label.split(' (');
    return (
      <Button onPress={this.handleClick} containerStyle={styles.container}>
        {() => (
          <>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>{subtitle.slice(0, -1)}</Text>
          </>
        )}
      </Button>
    );
  }
}
