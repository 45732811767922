import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

/**
 * How to safely use React context
 * https://medium.com/@mweststrate/how-to-safely-use-react-context-b7e343eff076
 */

export default class SearchProvider extends Component {
  static propTypes = {
    searchAdapter: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      getResult: PropTypes.func.isRequired,
      getData: PropTypes.func.isRequired,
      subscribe: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = {
    searchAdapter: PropTypes.shape({
      getState: PropTypes.func.isRequired,
      getResult: PropTypes.func.isRequired,
      getData: PropTypes.func.isRequired,
      subscribe: PropTypes.func.isRequired,
    }).isRequired,
  };

  getChildContext() {
    return { searchAdapter: this.props.searchAdapter };
  }

  render() {
    return <View style={{ flex: 1 }}>{this.props.children}</View>;
  }
}
