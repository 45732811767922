import get from 'lodash/get';
import { createSelector } from 'reselect';

import { K_BUNDLE_OFFER_BUNDLE_TYPE_WHOLESALE } from 'constants/offerConstants';
import { PAYMENT_METHODS } from 'constants/paymentConstants';
import { K_DEFAULT_ROUTING_ZONE_CODE } from 'constants/productConstants';

const emptyObject = {};

export const getBasket = (state) => state.basket;
export const getBasketBasketShippingAddress = (state) =>
  state.basket.basketShippingAddress;

export const getBasketAsyncState = (state) => getBasket(state).asyncState;
export const isBasketLoading = (state) => getBasketAsyncState(state).loading;
export const isBasketLoaded = (state) => getBasketAsyncState(state).loaded;

export const getBasketRoutingZoneCode = (state) =>
  getBasket(state)?.routingZone?.zoneCode ?? K_DEFAULT_ROUTING_ZONE_CODE;

export const getProductRecommendations = (state) =>
  getBasket(state).productRecommendations;

export const getProductRecommendationsAsyncState = (state) =>
  getProductRecommendations(state).asyncState;
export const isProductRecommendationsLoading = (state) =>
  getProductRecommendationsAsyncState(state).loading;
export const isProductRecommendationsLoaded = (state) =>
  getProductRecommendationsAsyncState(state).loaded;

export const getBasketAdditionalProductDiscount = (state) =>
  state.basket.additionalProductDiscount;

export const getBasketAdditionalShippingDiscount = (state) =>
  state.basket.additionalShippingDiscount;

export const isBasketUpdatingShippingAddress = (state) =>
  state.basket.isBasketUpdatingShippingAddress;

export const getBasketLines = createSelector(
  getBasket,
  (basket) => basket.lines || [],
);

export const getBasketShippingMethod = createSelector(
  getBasket,
  (basket) => basket.shippingMethod || {},
);

export const getBasketShippingMethodChargeByMerchant = createSelector(
  getBasketShippingMethod,
  (shippingMethod) =>
    shippingMethod.priceDetail?.chargeByMerchant || emptyObject,
);

export const getBasketShippingAddress = createSelector(
  getBasket,
  (basket) => basket.shippingAddress || {},
);

export const getBasketDeliveryRequestDate = createSelector(
  getBasket,
  (basket) => basket.receiveDeliveryRequestDateByPartnerId,
);

export const getBasketInstallationTimeSlotsByPartnerId = createSelector(
  getBasket,
  (basket) => basket.installationTimeSlotsByPartnerId,
);

export const getRequestDeliveryDateFromPartnerId = (partnerId) =>
  createSelector(
    getBasketDeliveryRequestDate,
    (receiveDeliveryRequestDateByPartnerId) =>
      receiveDeliveryRequestDateByPartnerId[partnerId] || '',
  );

export const makeGetInstallationTimeSlotByPartnerIdSelector = () =>
  createSelector(
    getBasketInstallationTimeSlotsByPartnerId,
    (_, partnerId) => partnerId,
    (installationTimeSlotsByPartnerId, partnerId) =>
      installationTimeSlotsByPartnerId[partnerId] ?? null,
  );

export const getProductOfBasketLine = (basketLine) => basketLine.product;

/**
 * findNonBundleProductInBasketLine must return basketLine object in basket state from params product and optionValues
 * @param  state
 * @param  product
 * @return basketLine
 */
export const findNonBundleProductInBasketLine = (state, product) => {
  const basketLines = getBasketLines(state);

  // @note(dika) 21 Aug 2024: Bundle Wholesale should be joined with non bundle basket lines
  return basketLines.find(
    (basketLine) =>
      (!basketLine.billOfMaterialStockRecordId ||
        basketLine.bundleOfferInfo?.bundleType ===
          K_BUNDLE_OFFER_BUNDLE_TYPE_WHOLESALE) &&
      getProductOfBasketLine(basketLine).objectID === product.objectID,
  );
};

export const findNonBundleStockRecordInBasketLine = (state, stockRecordId) => {
  const basketLines = getBasketLines(state);

  // @note(dika) 21 Aug 2024: Bundle Wholesale should be joined with non bundle basket lines
  return basketLines.find(
    (basketLine) =>
      (!basketLine.billOfMaterialStockRecordId ||
        basketLine.bundleOfferInfo?.bundleType ===
          K_BUNDLE_OFFER_BUNDLE_TYPE_WHOLESALE) &&
      (getProductOfBasketLine(basketLine).stockRecordId === stockRecordId ||
        getProductOfBasketLine(basketLine).prevStockRecordId === stockRecordId),
  );
};

export const findNonBundleBasketLine = (
  state,
  product,
  stockRecordId,
  isShopAssistant,
) => {
  if (isShopAssistant) {
    return findNonBundleStockRecordInBasketLine(state, stockRecordId);
  }
  return findNonBundleProductInBasketLine(state, product);
};

export const getQuantityOfBasketLine = (basketLine) =>
  basketLine?.quantity || 0;

export const getTotalQuantityOfBasket = (state) => {
  const basketLines = getBasketLines(state);
  return basketLines
    .map((lineItem) => {
      let totalLineItemAddon = 0;
      if (lineItem.addonProducts) {
        totalLineItemAddon = lineItem.addonProducts
          .map((addonProduct) => addonProduct.quantity)
          .reduce((prev, curr) => prev + curr, 0);
      }
      return lineItem.quantity + totalLineItemAddon;
    })
    .reduce((prev, curr) => prev + curr, 0);
};

export const getIsBasketRequireShipping = (state) =>
  getBasket(state).isBasketRequireShipping;

export const getPaymentMethod = (state) => getBasket(state).paymentMethod;

export const isShippingMethodLoading = (state) =>
  get(state, 'basket.shippingMethod.loading', false);

export const isShippingMethodLoaded = (state) =>
  get(state, 'basket.shippingMethod.loaded', false);

export const getShippingMethodError = (state) =>
  state.basket.shippingMethod.error;

export const getHasUnshippableItems = (state) =>
  (state.basket.shippingMethod.unshippableItems || []).length > 0;

export const getPaymentMethodDetail = (state) => {
  const basket = getBasket(state);
  const { paymentMethod } = basket;
  let paymentMethodExtraArgs = '';
  switch (paymentMethod) {
    case PAYMENT_METHODS.CREDIT_CARD:
    case PAYMENT_METHODS.CREDIT_CARD_INSTALLMENT:
      paymentMethodExtraArgs = get(
        basket,
        'paymentRoute.issuingBank',
        'default',
      );
      break;
    case PAYMENT_METHODS.INSTALLMENT_WITHOUT_CARD:
      paymentMethodExtraArgs = basket.selectedInstallmentWithoutCard;
      break;
    case PAYMENT_METHODS.E_WALLET:
      paymentMethodExtraArgs = basket.selectedWallet;
      break;
    case PAYMENT_METHODS.INTERNET_BANKING:
      paymentMethodExtraArgs = basket.selectedInternetBanking;
      break;
    case PAYMENT_METHODS.EDC:
    case PAYMENT_METHODS.CASH:
    case PAYMENT_METHODS.CASH_ON_DELIVERY:
    case PAYMENT_METHODS.BANK_TRANSFER:
    default:
      return paymentMethod;
  }
  return `${paymentMethod}-${paymentMethodExtraArgs}`;
};
