import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { LocationOn } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

interface LocationButtonProps {
  onPress: () => void;
}

const LocationButton: React.FC<LocationButtonProps> = memo(({ onPress }) => {
  return (
    <ButtonWrapper onPress={onPress} style={s.buttonContainer}>
      {() => (
        <View style={s.searchButton}>
          <LocationOn size={24} theme="black" style={{ alignSelf: 'center' }} />
          <View style={cStyles.mll}>
            <Text theme="h5" style={cStyles.mbs}>
              Pilih Kota & Kecamatan
            </Text>
            <Text theme="body3-black60">
              Pilih tujuan pengiriman secara manual
            </Text>
          </View>
        </View>
      )}
    </ButtonWrapper>
  );
});

const s = StyleSheet.create({
  buttonContainer: {
    ...cStyles.brm,
    ...cStyles.bwam,
    ...cStyles.mvm,
    borderColor: Colors.C_BLACK_10,
  },
  searchButton: {
    ...cStyles.flexDirRow,
    ...cStyles.pal,
  },
});

export default LocationButton;
