/* eslint-disable camelcase */
import { Product } from 'app-libs/components/Product/types';
import { getFirstBreadcrumbFromProductCategories } from 'app-libs/etc/categoryHelper';
import {
  getBasketLinesTotalValue,
  getOfferDiscountAmount,
} from 'app-libs/etc/productHelper';
import {
  BasketLine,
  BasketLineProduct,
} from 'app-libs/redux_modules/entity_modules/basket/types';
import { getCartLinesValuePublic } from 'app-libs/redux_modules/entity_modules/selectors/project';

import { Order } from 'entities/order/types';

import { PortfolioProject } from './types';

const K_FBCAPI_IDR_CURRENCY = 'IDR';
const K_FBCAPI_CONTENT_TYPE_PRODUCT = 'product';

export const genProduct = (product: Product | BasketLineProduct) => ({
  currency: K_FBCAPI_IDR_CURRENCY,
  value: product.priceNumber - getOfferDiscountAmount(product),
  content_category: getFirstBreadcrumbFromProductCategories(product.categories),
  content_name: product.title,
  content_type: K_FBCAPI_CONTENT_TYPE_PRODUCT,
  content_ids: [product.objectID],
});

export const genOrder = (order: Order, lines: BasketLine[]) => {
  return {
    currency: K_FBCAPI_IDR_CURRENCY,
    value: getBasketLinesTotalValue(lines),
    order_id: order.number,
    content_type: K_FBCAPI_CONTENT_TYPE_PRODUCT,
    contents: lines.map(({ product, quantity }) => ({
      id: product.objectID,
      quantity,
      item_price: product.priceNumber - getOfferDiscountAmount(product),
    })),
  };
};

export const genProject = (project: PortfolioProject) => {
  return {
    currency: K_FBCAPI_IDR_CURRENCY,
    value: getCartLinesValuePublic(project),
    content_category: project.category,
    content_name: project.title,
    content_type: K_FBCAPI_CONTENT_TYPE_PRODUCT,
    content_ids: [project.projectKey],
    designer_name: project?.mainDesigner?.member?.name || '',
    service_type: project.serviceTypeName,
    property_type: project.propertyTypeName,
  };
};
