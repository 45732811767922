import React, { memo } from 'react';
import DocumentMeta from 'react-document-meta';

const K_META_TITLE =
  'Furniture Terbaik Pasti Harga Pas | Dekoruma - No. 1 Japandi Style Furnishing';
const K_META_DESCRIPTION =
  'Beli Furniture Terbaik dan Pasti Harga Pas di Dekoruma, No. 1 Japandi Style Furnishing  | ☑️Pembelian Minimal 2 Juta, Bisa Cicilan 0% ☑️Gratis Ongkir';

const BrandingPushedHomepageMeta = memo(() => {
  const meta = {
    property: {
      'og:title': K_META_TITLE,
      'og:description': K_META_DESCRIPTION,
      'twitter:title': K_META_TITLE,
      'twitter:description': K_META_DESCRIPTION,
    },
  };

  return (
    <DocumentMeta
      title={K_META_TITLE}
      description={K_META_DESCRIPTION}
      meta={meta}
      extend
    />
  );
});

export default BrandingPushedHomepageMeta;
