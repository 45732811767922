import React, { memo, useCallback } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { SectionList, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { useParams } from 'app-libs/hook_modules/router';

import {
  OfflineStoreLocation,
  OfflineStoreLocationSEOArea,
} from 'entities/offlineStore/types';

import OfflineStoreLocationCard from './OfflineStoreLocationCard';
import { SectionData } from './types';
import { useOfflineStoreLocationListSectionData } from './utils';

const K_SECTION_LIST_NUM_COLUMNS = 2;

interface OfflineStoreLocationListPageContentProps {
  offlineStoreLocations: OfflineStoreLocation[];
  offlineStoreSEOAreas: OfflineStoreLocationSEOArea[];
}

const OfflineStoreLocationListPageContent: React.FC<OfflineStoreLocationListPageContentProps> =
  memo(({ offlineStoreLocations, offlineStoreSEOAreas }) => {
    const { areaName } = useParams<{ areaName?: string }>();

    const sectionData = useOfflineStoreLocationListSectionData(
      offlineStoreLocations,
      offlineStoreSEOAreas,
    );

    const renderItem = useCallback(
      ({ section, index }: { section: SectionData; index: number }) => {
        /**
         * SectionList does not support numColumns props like flatList. But for this page, section list is needed
         * because the page contains multiple sections and each section has items
         *
         * Solution: render current item + next item for every call of renderItem
         * https://stackoverflow.com/questions/47833581/react-native-sectionlist-numcolumns-support
         */

        if (index % K_SECTION_LIST_NUM_COLUMNS !== 0) {
          return null;
        }

        const currentItem = section.data[index];
        const nextItem = section.data[index + 1];

        return (
          <View style={s.rowContainer}>
            <OfflineStoreLocationCard
              area={section.title}
              offlineStoreLocation={currentItem}
              style={s.cardRowSeparator}
            />
            {nextItem ? (
              <OfflineStoreLocationCard
                area={section.title}
                offlineStoreLocation={nextItem}
              />
            ) : (
              <View style={cStyles.flex1} />
            )}
          </View>
        );
      },
      [],
    );

    const renderSectionHeader = useCallback(
      ({ section }: { section: SectionData }) => {
        if (areaName) {
          return null;
        }
        return <Text theme="h5-neutral900">{section.title}</Text>;
      },
      [areaName],
    );

    const renderSectionSeparator = useCallback(() => {
      return <View style={s.sectionSeparator} />;
    }, []);

    return (
      <SectionList
        sections={sectionData}
        keyExtractor={(item: OfflineStoreLocation) => item.storeId}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        SectionSeparatorComponent={renderSectionSeparator}
        style={s.container}
      />
    );
  });

const s = StyleSheet.create({
  container: cStyles.phxl,
  sectionSeparator: cStyles.mbm,
  cardRowSeparator: cStyles.mrxl,
  rowContainer: {
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
  },
});

export default OfflineStoreLocationListPageContent;
