import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { DefaultRootState, useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, ReflowView, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { VariantLabel } from 'app-libs/components/Product/ProductDetail/Shared';
import ProductSticker from 'app-libs/components/ProductSticker';
import { genProductUrlWithoutHost } from 'app-libs/etc/productHelper';
import { getIsOutOfStockByRoutingZoneCode } from 'app-libs/etc/productHelperV2';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';
import {
  getIsAlmostOutOfStock,
  getProductByListOfObjectIDsFromProductEntities,
} from 'app-libs/redux_modules/entity_modules/selectors/product';
import { trackClickedProductVariations } from 'app-libs/trackers';

import {
  K_PLACEHOLDER_IMAGE_URL,
  K_PRODUCT_OPTION_SIZE,
} from 'constants/productConstants';

import BottomButtons from './BottomButtons';
import CampaignSticker from './CampaignSticker';
import ProductDescription from './ProductDescription';
import ProductVariations from './ProductVariations';
import ReferralCommission from './ReferralCommission';
import { ProductCardProduct } from './types';
import { getCampaignStickerAssetUrl } from './utils';

export const K_DEFAULT_WIDTH = 167.5;
export const K_NUMBER_OF_LINES = 2;
const K_NUMBER_OF_PRODUCT_VARIATIONS_IN_SHOW = 3.5;
const K_PRODUCT_VARIATION_MARGIN_TOP = 8;

type ProductCardProps = {
  width: number;
  aspectRatio?: number;
  product: ProductCardProduct;
  history: {
    location: { pathname: string };
  };
  colorSiblings: Array<ProductCardProduct>;
  referrer?: string;
  baseUrl?: string;
  shouldShowSimilarProductsButton?: boolean;
  showReferralCommission?: boolean;
  containerStyle?: object;
  contentStyle?: object;
  buttonProps?: object;
  onPress?: () => void;
  onClickSimilarProducts?: () => void;
  toggleWishlistPin?: (pinId: number, product: ProductCardProduct) => void;
  shouldShowQuickAddToCart?: boolean;
  onQuickAddToCart?: (
    upc: string,
    stockRecordId: number,
    addedQuantity: number,
  ) => void;
};

const noop = () => null;

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const {
    product: propsProduct,
    history,
    colorSiblings = [],
    aspectRatio = 1,
    width = K_DEFAULT_WIDTH,
    containerStyle = null,
    contentStyle = null,
    buttonProps = {},
    onPress = noop,
    baseUrl = '',
    referrer,
    showReferralCommission = false,
    onClickSimilarProducts = noop,
    shouldShowSimilarProductsButton = true,
    toggleWishlistPin = noop,
    shouldShowQuickAddToCart = false,
    onQuickAddToCart = noop,
  } = props;

  const colorSiblingProducts = useSelector<
    DefaultRootState,
    Array<ProductCardProduct>
  >(getProductByListOfObjectIDsFromProductEntities(colorSiblings));

  const routingZoneCode = useSelector(getCurrentLocationRoutingZoneCode);

  const [product, setProduct] = useState<ProductCardProduct>(
    colorSiblingProducts?.[0] ?? propsProduct,
  );

  const handleClickProductVariation = (item: ProductCardProduct) => {
    const pathname = history?.location?.pathname ?? '';
    trackClickedProductVariations(product, item, pathname);
    setProduct(item);
  };

  const cardWidth = width || K_DEFAULT_WIDTH;
  const productVariationsSize = Math.floor(
    cardWidth / K_NUMBER_OF_PRODUCT_VARIATIONS_IN_SHOW,
  );
  const containerHeight =
    productVariationsSize + K_PRODUCT_VARIATION_MARGIN_TOP;

  const imageUrl = product.image ? product.image : K_PLACEHOLDER_IMAGE_URL;
  const imageAdditionalQuery = {
    fit: 'bounds',
    auto: 'webp',
    optimize: 'high',
    'trim-color': 'auto',
    'bg-color': product.image ? 'ffffff' : 'BFC2C3', // quick hack if no image, we don't want to have white background
    pad: product.image ? 0 : 50,
  };

  const variants = product?.simplifiedOptions?.variants ?? [];
  const shownVariant = variants.find((variant) =>
    variant.type.includes(K_PRODUCT_OPTION_SIZE),
  );

  const campaignStickerAssetUrl = getCampaignStickerAssetUrl(product);

  const { availability } = product;

  const isOutOfStock = getIsOutOfStockByRoutingZoneCode(
    product,
    routingZoneCode,
  );

  return (
    <View style={[s.container, containerStyle]}>
      <Button
        to={{
          pathname: genProductUrlWithoutHost(product, baseUrl),
          state: { openedFrom: referrer },
        }}
        anchor
        showUnderlineOnHover={false}
        onPress={onPress}
        style={cStyles.flex1}
        hoverableViewStyle={cStyles.flex1}
        {...buttonProps}
      >
        {() => (
          <View style={[s.content, contentStyle]}>
            <ReflowView
              widthProportion={aspectRatio}
              heightProportion={aspectRatio}
            >
              <SmartImage
                source={imageUrl}
                accessibilityLabel={product.title}
                additionalQuery={imageAdditionalQuery}
                style={cStyles.flex1}
                resizeMode="contain"
              />
              {!isOutOfStock && getIsAlmostOutOfStock(availability) && (
                <View style={s.stockAlertContainer}>
                  <Text
                    style={s.stockAlert}
                    theme="caption3-red"
                  >{`Tersisa ${availability.numAvailable} lagi`}</Text>
                </View>
              )}
              {isOutOfStock && (
                <>
                  <View style={s.emptyStockOverlay} />
                  <View style={s.emptyStockTextContainer}>
                    <Text theme="h4-white">STOK HABIS</Text>
                  </View>
                </>
              )}
              <CampaignSticker assetUrl={campaignStickerAssetUrl} />
              {!isEmpty(shownVariant) && (
                <View style={s.variantLabelContainer}>
                  <VariantLabel variant={shownVariant} />
                </View>
              )}
            </ReflowView>
            {colorSiblingProducts.length > 0 && (
              <View style={{ marginTop: containerHeight }} />
            )}
            <ProductDescription product={product} isOutOfStock={isOutOfStock} />
          </View>
        )}
      </Button>
      {colorSiblingProducts.length > 0 && (
        <ProductVariations
          product={product}
          aspectRatio={aspectRatio}
          colorSiblingProducts={colorSiblingProducts}
          width={width}
          onClickProductVariation={handleClickProductVariation}
        />
      )}
      <BottomButtons
        firstProduct={propsProduct}
        product={product}
        referrer={referrer}
        buttonProps={buttonProps}
        onSimilarProductClick={onClickSimilarProducts}
        toggleWishlistPin={toggleWishlistPin}
        shouldShowSimilarProductsButton={shouldShowSimilarProductsButton}
        shouldShowQuickAddToCart={shouldShowQuickAddToCart}
        onQuickAddToCart={onQuickAddToCart}
      />
      <ProductSticker
        product={product}
        isShowingCampaignSticker={!!campaignStickerAssetUrl}
      />
      {showReferralCommission && <ReferralCommission product={product} />}
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    boxShadow: '0px 2px 5px rgba(90, 90, 101, 0.2)',
    borderRadius: 4,
    overflow: 'hidden',
    ...cStyles.mhm,
    ...cStyles.mbxl,
  },
  content: {
    maxWidth: '100%',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    ...cStyles.flex1,
  },
  stockAlertContainer: {
    ...cStyles.abs,
    ...cStyles.flexMiddle,
    ...cStyles.mlm,
    ...cStyles.mtxl,
    borderRadius: 2,
    backgroundColor: Colors.C_SECONDARY_RED_1,
  },
  stockAlert: {
    ...cStyles.mhs,
    ...cStyles.mvxs,
    ...cStyles.fwb,
    ...cStyles.flex1,
  },
  dummyProduct: {
    visibility: 'hidden',
    ...cStyles.mhm,
    ...cStyles.mbxl,
    ...cStyles.flex1,
  },
  emptyStockOverlay: {
    backgroundColor: Colors.C_BLACK_65,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    opacity: 0.45,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  emptyStockTextContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...cStyles.flex1,
    ...cStyles.flexMiddle,
  },
  variantLabelContainer: {
    ...cStyles.abs,
    ...cStyles.bottom,
    ...cStyles.left,
  },
});

export const K_DUMMY_PRODUCT = 'dummy-product';
export const K_DUMMY_PRODUCT_STYLE = s.dummyProduct;

export default ProductCard;
