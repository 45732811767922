/* global amplitude */

import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

const K_APP_PLATFORMS = ['ios', 'android'];
const K_UNKNOWN_APP_VERSION = 'unknown';

const amplitudeWrapper = {
  logEvent: (eventType, eventProperties, optCallback) => {
    const platform = getPlatform();
    let appVersion = K_UNKNOWN_APP_VERSION;
    if (K_APP_PLATFORMS.includes(platform)) {
      appVersion = window?.__APP_VERSION__ || K_UNKNOWN_APP_VERSION;
    }
    amplitude.logEvent(
      eventType,
      { ...eventProperties, platform, appVersion },
      optCallback,
    );
  },
  logRevenue: (price, quantity, title) => {
    amplitude.logRevenue(price, quantity, title);
  },
  setUserId: (id) => {
    amplitude.setUserId(id);
  },
  setUserProperties(properties) {
    amplitude.setUserProperties(properties);
  },
};

export default amplitudeWrapper;
