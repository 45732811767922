import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';

import { slugify } from 'app-libs/etc';

import { withSearch } from '../../index';
import SimpleFilterItem from '../SimpleFilterItem';
import SimpleFilterList from '../SimpleFilterList';

export const createHierarchicalSelector = (
  searchAdapter,
  { name, level, customData, additionalData = [] },
) => {
  if (customData) {
    return customData;
  }
  const result = searchAdapter.getResult();
  const hierarchicalFacets = get(result, 'hierarchicalFacets', []);
  let { data } = hierarchicalFacets.find((item) => item.name === name) || {};

  for (let depth = level; depth > 1; depth -= 1) {
    try {
      // eslint-disable-next-line prefer-destructuring
      data = data.find((item) => item.isRefined).data;
    } catch (err) {
      // in case of TypeError (data.find returns undefined)
      data = [];
      break;
    }
  }

  data = data || [];

  return [
    ...data.map((item) => ({
      label: item.name,
      value: item.path,
      count: item.count,
      selected: item.isRefined,
    })),
    ...additionalData,
  ];
};

@withSearch({ data: createHierarchicalSelector })
export default class HierarchicalFacetFilter extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    listComponent: PropTypes.func,
    itemComponent: PropTypes.func,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        count: PropTypes.number,
        selected: PropTypes.bool,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    label: PropTypes.string,
    labelStyle: stylePropTypes,
    listProps: PropTypes.shape(),
    baseUrl: PropTypes.string,
    numColumns: PropTypes.number,
  };

  static defaultProps = {
    onChange: () => {},
    listComponent: SimpleFilterList,
    itemComponent: SimpleFilterItem,
    label: null,
    labelStyle: {},
    listProps: {},
    baseUrl: null,
    numColumns: 1,
  };

  shouldComponentUpdate(nextProps) {
    const { data, listProps } = this.props;
    return (
      !isEqual(data, nextProps.data) ||
      !isEqual(listProps.page, nextProps.listProps.page) ||
      !isEqual(listProps.extraData, nextProps.listProps.extraData)
    );
  }

  getHierarchicalFacetFilterItemUrl = (category) => {
    const { baseUrl } = this.props;
    return `${baseUrl}/${slugify(category)}`;
  };

  handleChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const {
      label,
      labelStyle,
      data,
      listComponent: List,
      itemComponent,
      name,
      level,
      listProps,
    } = this.props;
    let labelText = null;
    if (label !== undefined) {
      labelText = label;
    } else {
      labelText = `${name}.lvl${level}`;
    }
    return (
      <List
        label={labelText}
        getHierarchicalFacetFilterItemUrl={
          this.getHierarchicalFacetFilterItemUrl
        }
        data={data}
        onChange={this.handleChange}
        itemComponent={itemComponent}
        labelStyle={labelStyle}
        level={level}
        {...listProps}
      />
    );
  }
}
