import React, { memo, useMemo } from 'react';
import DocumentMeta from 'react-document-meta';

import config from 'config';

import { Colors } from '@dkrm/ui-kit-v2';

import { useLocation } from 'app-libs/hook_modules/router';

import { K_HOST } from 'constants/routeConstants';

const DefaultAppMeta: React.FC = memo(() => {
  const location = useLocation();

  const titleMetaString =
    'Dekoruma © - Toko Furnitur & Jasa Desain Interior Terbaik | Bisa Cicilan 0%';
  const descriptionMetaString =
    'Jual Furnitur & Jasa Desain Interior Teraman, Terlengkap & Terpercaya | ☑️Belanja Minimal 2 Juta, Bisa Cicilan 0% ☑️Gratis Ongkir ☑️Garansi Uang Kembali';
  const imageMetaString = `${config.API_URL_MEDIA_CDN}/homepage/logo/v2/dekoruma/dekoruma_black.png`;
  const canonicalUrl = `${K_HOST}${location.pathname.replace(/\/$/, '')}`;

  const meta = useMemo(
    () => ({
      charSet: 'utf-8',
      name: {
        'theme-color': Colors.C_NEUTRAL_0,
      },
      property: {
        'og:site_name': titleMetaString,
        'og:image': [imageMetaString],
        'og:locale': 'en_US',
        'og:title': titleMetaString,
        'og:description': descriptionMetaString,
        'twitter:card': descriptionMetaString,
        'twitter:site': '@hellodekoruma',
        'twitter:creator': '@hellodekoruma',
        'twitter:title': titleMetaString,
        'twitter:description': descriptionMetaString,
        'twitter:image': imageMetaString,
        'twitter:image:width': '200',
        'twitter:image:height': '200',
      },
    }),
    [imageMetaString],
  );

  return (
    <DocumentMeta
      canonical={canonicalUrl}
      title={titleMetaString}
      description={descriptionMetaString}
      meta={meta}
    />
  );
});

export default DefaultAppMeta;
