import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { getAttributeValueOf } from 'app-libs/etc/productAttributesUtil';
// eslint-disable-next-line import/no-cycle
import {
  getProductOptionValuesByUpcFromAttributes,
  isMaterialProduct,
  virtualizeProduct,
} from 'app-libs/etc/productHelper';
import { getStockConstant } from 'app-libs/etc/shippingHelper';
import { getSelectedCity } from 'app-libs/redux_modules/flow_modules/selectors/area';

import { K_FAKE_BEDDING_RATING_BY_BRAND } from 'constants/beddingConstants';
import { K_LANG_STOCK_MADE_TO_ORDER } from 'constants/langConstants';
import {
  K_A_COVERAGE,
  K_A_DIM_HEIGHT,
  K_A_DIM_LENGTH,
  K_A_DIM_WIDTH,
  K_A_FREE_INSTALLATION,
  K_A_F_PRODUCT_CONDITION,
  K_A_MATERIAL,
  K_A_MATTRESS_FIRMNESS,
  K_A_PATTERN,
  K_A_QUALITY,
  K_A_ROOM,
  K_A_SIZE_LENGTH,
  K_A_SIZE_TINGGI,
  K_A_SIZE_WIDTH,
  K_A_STD_COLOR_CODE,
  K_A_STYLE,
  K_A_SUB_STYLE,
  K_PLACEHOLDER_IMAGE_URL,
} from 'constants/productConstants';
import {
  K_AVAILABILITY_EMPTY_STOCK,
  K_AVAILABILITY_SHOW_INSTANT,
} from 'constants/shippingConstants';
import { K_PROPERTY_OPENED_FROM_FREQUENTLY_BOUGHT_TOGETHER } from 'constants/trackingConstants';

import { getProductEntities } from './entities';

export const K_MAX_NUMBER_ALMOST_OUT_OF_STOCK_DEFAULT = 5;
const K_PRODUCT_CHILDREN_ATTRIBUTE_KEY_BY_IDS_ATTRIBUTE_KEY = {
  siblingIds: 'siblings',
  freqBoughtTogetherIds: 'freqBoughtTogethers',
  recommendedSeriesIds: 'recommendedSeries',
  similarItemsIds: 'similarItems',
  userWhoViewedThisAlsoViewedItemsIds: 'userWhoViewedThisAlsoViewedItems',
  upsellItemsIds: 'upsellItems',
};

export const K_MAX_BACKORDER = 999999;
const emptyObject = Object.freeze({});

/**
 * [_transformProduct description]
 * upper layer that transform product
 * - remove if product is not visible
 * - add attribute imageName of product
 *
 * @param  product
 * @return product
 */

export function _transformProduct(rawProduct) {
  let product = Object.assign({}, rawProduct);
  if (
    !isMaterialProduct(product) &&
    has(product, 'isBlacklisted') &&
    product.isBlacklisted
  ) {
    return {};
  }
  product = virtualizeProduct(product);
  return product;
}

/**
 * get product that has been initialized (stored in product reducer)
 * @param  state
 * @return product
 */
export const getProduct = (state) => _transformProduct(state.product) || {};

/**
 * get product by objectID
 * @param  state
 * @param  objectID
 * @return product
 */
export const getProductByObjectIDFromProductEntities = (state, objectID) =>
  _transformProduct(getProductEntities(state)[objectID] || {});

export const getProductRawByObjectIDFromProductEntities = (state, objectID) =>
  getProductEntities(state)[objectID] || emptyObject;

export const getProductByListOfObjectIDsFromProductEntities = memoize(
  (objectIDs) =>
    createSelector(getProductEntities, (products) =>
      objectIDs
        .map((upc) => _transformProduct(products[upc] || {}))
        .filter((product) => !!product && Object.keys(product).length > 0),
    ),
);

export const getProductAddonByObjectIDFromProductEntities = (state, objectID) =>
  getProductEntities(state)[objectID] || {};

export const getProductWithChildrenByObjectIDFromProductEntities = (
  state,
  objectID,
) => {
  const product = getProductByObjectIDFromProductEntities(state, objectID);
  Object.keys(K_PRODUCT_CHILDREN_ATTRIBUTE_KEY_BY_IDS_ATTRIBUTE_KEY).forEach(
    (K_PRODUCT_CHILDREN_IDS_KEY) => {
      const K_PRODUCT_CHILDREN_ATTRIBUTE_KEY =
        K_PRODUCT_CHILDREN_ATTRIBUTE_KEY_BY_IDS_ATTRIBUTE_KEY[
          K_PRODUCT_CHILDREN_IDS_KEY
        ];
      if (has(product, K_PRODUCT_CHILDREN_IDS_KEY)) {
        product[K_PRODUCT_CHILDREN_ATTRIBUTE_KEY] = product[
          K_PRODUCT_CHILDREN_IDS_KEY
        ].map((id) => getProductByObjectIDFromProductEntities(state, id))
          .filter(Boolean)
          .map(_transformProduct);
      }
    },
  );
  return product;
};

/**
 * handling get product in product detail
 * - if product is not in entities, return props.productFromPrevious
 * - else return product from entities
 * @param  state
 * @param  props {pid: 'ASA-8', productFromPrevious: product}
 * @return product
 */
export const getProductFromEntitiesIfExistsElseGetFromProps = (
  state,
  props,
) => {
  let product = getProductByObjectIDFromProductEntities(
    state,
    get(props, 'pid') || get(props, 'params.pid'),
  );
  if (isEmpty(product)) {
    product = props.productFromPrevious;
  }
  return product || {};
};

export const getProductUPC = (product) => product.objectID || '';

export const getProductPrice = (product) => parseInt(product.price, 10) || 0;

export const getProductBulkPrices = (product) => product.bulkPrices || [];

export const getProductCurrentPrice = (product, quantity = 1) => {
  const price = getProductPrice(product);
  const bulkPrices = orderBy(
    getProductBulkPrices(product),
    ['minimumPurchaseQuantity'],
    ['desc'],
  );
  const availableBulkPrice = bulkPrices.find(
    (bulkPrice) => quantity >= bulkPrice.minimumPurchaseQuantity,
  );
  return !availableBulkPrice ? price : availableBulkPrice.price;
};

export const getProductCurrentPriceAfterDiscount = (product, quantity = 1) => {
  const price = getProductCurrentPrice(product, quantity);
  return isEmpty(product.productOffer)
    ? price
    : price - product.productOffer.discount;
};

export const getProductUrl = (product) => product.url || '';

export const getProductMaxPrice = (product) =>
  parseInt(product.maxPrice, 10) || 0;

export const getProductImageUrl = (product) =>
  product.image ? product.image : K_PLACEHOLDER_IMAGE_URL;

export const getProductOffer = (product) => product.productOffer || {};

export const getProductOfferPrice = (product) => {
  const productOffer = getProductOffer(product);
  if (isEmpty(productOffer)) return 0;
  return parseInt(productOffer.discount, 10);
};

export const getProductOfferPercentage = (product) => {
  const productOffer = getProductOffer(product);
  if (isEmpty(productOffer)) return 0;
  return productOffer.offer.discountValue;
};

export const getProductPriceInclDiscount = (product) => {
  const price = getProductPrice(product);
  return Math.max(0, price - getProductOfferPrice(product));
};

export const getProductPricePerCoverageUnit = (product) => {
  const price = getProductPrice(product);
  const coverage = getProductCoverageAttributes(product);
  return price / coverage || '';
};

export const getProductTitle = (product) => product.title || '';
export const getProductDescription = (product) => product.description || '';

export const getProductCategories = (product) => get(product, 'categories', []);
export const getLastProductCategory = (product) => {
  const categories = getProductCategories(product);
  if (!categories) return null;
  if (!categories.length) return null;
  const lastCategory = categories[0].split(' > ').pop();
  if (lastCategory.length) return lastCategory;
  return null;
};
export const getProductCategory = (product) => getProductCategories(product)[0];

export const getProductBrand = (product) => product.brand || '';

export const getProductPartner = (product) => product.partner || {};

export const getProductPartnerName = (product) =>
  getProductPartner(product).name || '';

export const getProductBrandChartReviewData = (product) => {
  const brand = getProductBrand(product).replace(/\s/g, '');
  if (has(K_FAKE_BEDDING_RATING_BY_BRAND, brand)) {
    return K_FAKE_BEDDING_RATING_BY_BRAND[brand];
  }
  return {};
};

export const getOfflineStockRecordByStoreCode = (product, storeCode) => {
  return (product.stockRecordChoices || []).find(
    (choice) => choice.isOfflineStock && choice.storeCode === storeCode,
  );
};

export const getOnlineRegionalHubStockRecord = (
  product,
  storeRoutingZoneCode,
) => {
  return (product.stockRecordChoices || []).find((stockRecord) => {
    const { partner } = stockRecord;
    return (
      !stockRecord.isOfflineStock &&
      partner.routingZoneCodes?.includes(storeRoutingZoneCode)
    );
  });
};

export const getProductStockRecordByStockRecordId = (
  product,
  stockRecordId,
) => {
  if (product.stockRecordChoices) {
    return product.stockRecordChoices.find(
      (choice) => choice.id === stockRecordId,
    );
  }
  return {};
};

export const getShopAssistantAvailableStockRecordBasedOnPriority = (
  product,
  storeCode = '',
  storeRoutingZoneCode = '',
) => {
  /**
    @note(dika) 21 Sept 2022: Default stock record logic
    1. Offline stock with correct store code
    2. Online regional hub (medan / makassar) stock if shop assistant should show regional hub stock
    3. Default online stock
  */
  const offlineStockRecord = getOfflineStockRecordByStoreCode(
    product,
    storeCode,
  );

  if (offlineStockRecord?.id && !getIsOutOfStock(offlineStockRecord)) {
    return offlineStockRecord;
  }

  const onlineRegionalHubStockRecord = getOnlineRegionalHubStockRecord(
    product,
    storeRoutingZoneCode,
  );

  if (
    onlineRegionalHubStockRecord?.id &&
    !getIsOutOfStock(onlineRegionalHubStockRecord)
  ) {
    return onlineRegionalHubStockRecord;
  }

  return getProductStockRecordByStockRecordId(product, product.stockRecordId);
};

export const getProductAvailability = (product) => product.availability || {};

export const getProductAvailabilityFromStockRecord = (stockRecord) => ({
  fulfillmentTime: get(stockRecord, 'fulfillmentTime', 2),
  backorderAllowed: get(stockRecord, 'backorderAllowed', false),
  codFulfillmentTime: get(stockRecord, 'codFulfillmentTime', 0),
  codAllowed: get(stockRecord, 'partner.codAllowed', false),
  numAvailable: get(stockRecord, 'numAvailable', 0),
  backorderFulfillmentTime: get(stockRecord, 'backorderFulfillmentTime', 0),
  isAvailableToBuy: get(stockRecord, 'isAvailableToBuy', false),
  maxRebate: get(stockRecord, 'maxRebate', 0),
});

export const isProductMadeInOrder = (product) => {
  const productAvailability = getProductAvailability(product);
  return (
    productAvailability.backorderAllowed && getTotalStockProduct(product) <= 0
  );
};

export const getProductBackorderFulfillmentTime = (product) => {
  if (!isProductMadeInOrder(product)) return 0;
  const productAvailability = getProductAvailability(product);
  return productAvailability.backorderFulfillmentTime;
};

export const getProductFulfillmentTime = (product) => {
  const productAvailability = getProductAvailability(product);
  return {
    minFulfillmentTime: 0,
    maxFulfillmentTime: productAvailability.fulfillmentTime,
  };
};

export const getTotalStockProduct = (product) => {
  const productAvailability = getProductAvailability(product);
  return productAvailability.numAvailable || 0;
};

export const getProductAttributes = (product) => product.attributes || [];

export const getProductStockText = (product, isLokiNative = false) => {
  if (isProductMadeInOrder(product)) {
    if (isLokiNative) return 'Stok langsung dibuat setelah pemesanan';
    return K_LANG_STOCK_MADE_TO_ORDER;
  }
  const totalStok = getTotalStockProduct(product);
  if (totalStok > 0) {
    return `Stok tersedia ${totalStok} buah`;
  }
  return 'Out of stock';
};

export function getProductAttributeFromAttributeCode(product, attributeCode) {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, attributeCode);
}

export function getProductInstallationCondition(product) {
  return getProductAttributeFromAttributeCode(product, K_A_F_PRODUCT_CONDITION);
}

export function getIsFreeInstallation(product) {
  return getProductAttributeFromAttributeCode(product, K_A_FREE_INSTALLATION);
}

export const getProductLength = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_DIM_LENGTH);
};

export const getProductWidth = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_DIM_WIDTH);
};

export const getProductHeight = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_DIM_HEIGHT);
};

export const getProductMaterial = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_MATERIAL);
};

export const getProductPattern = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_PATTERN);
};

export const getProductFirmness = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_MATTRESS_FIRMNESS);
};

export const getProductRoom = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_ROOM);
};

export const getProductStyle = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_STYLE);
};

export const getProductSubStyle = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_SUB_STYLE);
};

export const getProductQuality = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_QUALITY);
};

export const getProductExtension = (product) => product.extension || {};

export const getProductExtensionReview = (product) => {
  const extension = getProductExtension(product);
  return extension.review || {};
};

export const getProductWidthSize = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_SIZE_WIDTH);
};

export const getProductLengthSize = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_SIZE_LENGTH);
};

export const getProductHeightSize = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_SIZE_TINGGI);
};

export const getProductCoverageAttributes = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_COVERAGE);
};

export const getProductColorAttributes = (product) => {
  const attributes = getProductAttributes(product);
  return getAttributeValueOf(attributes, K_A_STD_COLOR_CODE);
};

export function isProductFreeInstallation(product) {
  return getIsFreeInstallation(product).toLowerCase() === 'ya';
}

export const getIsAlmostOutOfStock = (
  availability,
  maxNumberAlmostOutOfStock = K_MAX_NUMBER_ALMOST_OUT_OF_STOCK_DEFAULT,
) =>
  availability &&
  getStockConstant(availability) === K_AVAILABILITY_SHOW_INSTANT &&
  availability.numAvailable <= maxNumberAlmostOutOfStock;

export const genProductOptionAttributesByUpc = (state) => {
  const products = getProductEntities(state);
  return getProductOptionValuesByUpcFromAttributes(products);
};

export const getIsOutOfStock = (availability) =>
  availability && getStockConstant(availability) === K_AVAILABILITY_EMPTY_STOCK;

export const getReviewedProductsByUpc = (state, brand, upc) =>
  get(state, `product.reviewedProductsByBrand.${brand}.${upc}`, {});

export const isReviewedProductByBrandLoaded = (state, brand) =>
  has(state, `product.reviewedProductsByBrand.${brand}`);

export const getBrandStats = (state, brand) =>
  get(state, `product.brandStats.${brand}`, {});

export const getAllProductShippingCharge = (state) =>
  get(state, 'product.shippingCharge', {});

export const getShippingChargeByStockRecordIdAndCity = (
  shippingCharge,
  stockRecordId,
  city,
) => get(shippingCharge, [stockRecordId, city], {});

export const getRecommendedFBTCategory = (state, category) =>
  get(state, `product.recommendedFbtCategoryByName.${category}`, []);

export const getIsFBTModalOpened = (state, upc) =>
  get(state, `product.isFBTModalOpened.${upc}`, false);

export const getIsProductFromFBT = (state, upc) =>
  Object.keys(get(state, 'product.clickedFBTProducts', {})).includes(upc);

export const getProductFBTAddToCartReferenceNote = (state, upc) =>
  get(state, `product.clickedFBTProducts.${upc}`, null);

export const getAddToCartReference = (state, upc) => {
  const fbtAddToCartReferenceNote = getProductFBTAddToCartReferenceNote(
    state,
    upc,
  );
  if (fbtAddToCartReferenceNote)
    return K_PROPERTY_OPENED_FROM_FREQUENTLY_BOUGHT_TOGETHER;
  return null;
};

export const getAddToCartReferenceNote = (state, upc) => {
  const fbtAddToCartReferenceNote = getProductFBTAddToCartReferenceNote(
    state,
    upc,
  );
  return fbtAddToCartReferenceNote || null;
};

export const makeGetShippingChargeByStockRecordIdSelector = () =>
  createSelector(
    getAllProductShippingCharge,
    getSelectedCity,
    (_, stockRecordId) => stockRecordId,
    (shippingCharges, selectedCity, stockRecordId) =>
      getShippingChargeByStockRecordIdAndCity(
        shippingCharges,
        stockRecordId,
        selectedCity,
      ),
  );

export const getCustomScoreConfigurationsAsyncState = (state) =>
  state.product.customScoreConfigurationsAsyncState;

export const getIsCustomScoreConfigurationsLoaded = createSelector(
  getCustomScoreConfigurationsAsyncState,
  (asyncState) => asyncState.loaded || false,
);

export const getCustomScoreConfigurationsEntities = (state) =>
  state.product.customScoreConfigurationsByName;

export const makeGetCustomScoreConfigurationByNameSelector = () =>
  createSelector(
    getCustomScoreConfigurationsEntities,
    (_, configName) => configName,
    (customScoreConfigurationsEntities, configName) =>
      customScoreConfigurationsEntities?.[configName]?.value || null,
  );
