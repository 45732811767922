import React, { memo } from 'react';

import { Whatsapp } from '@dkrm/icons';
import { Link } from '@dkrm/ui-kit-basic';
import { cStyles } from '@dkrm/ui-kit-v2';

import { FooterSectionItem } from '../FooterSection/FooterSectionItem';

const K_COMPLAINT_SERVICE_WA_URL = 'https://wa.me/085311111010';

const ConsumerComplaintServiceSection = memo(() => {
  return (
    <>
      <FooterSectionItem label="Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga Kementerian Perdagangan Republik Indonesia" />
      <Link style={cStyles.mt16} isUsingAnchor to={K_COMPLAINT_SERVICE_WA_URL}>
        <FooterSectionItem label="0853-1111-1010" Icon={Whatsapp} />
      </Link>
    </>
  );
});

export default ConsumerComplaintServiceSection;
