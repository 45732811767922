import React, { memo, useMemo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ActivityIndicator, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import { OfflineBasketPreview } from 'entities/offlineBasket/types';
import { useQueryLoadOfflineBasketList } from 'entities/offlineBasket/utils';

import {
  K_ROUTE_CART,
  K_ROUTE_OFFLINE_BASKET_LIST,
} from 'constants/routeConstants';

const BasketPreview = memo(() => {
  const {
    data,
    isLoading: isBasketListLoading,
    isFetching,
  } = useQueryLoadOfflineBasketList();

  const linkProps = useMemo(
    () => ({
      isUsingAnchor: true,
    }),
    [],
  );

  if (isBasketListLoading || isFetching) {
    return <ActivityIndicator />;
  }

  const selectedBasketPreview = (data || []).find(
    (result: OfflineBasketPreview) => result.isActiveBasket,
  );

  if (!selectedBasketPreview) {
    return (
      <View style={s.container}>
        <Text style={s.title}>Belum memilih customer</Text>
        <ButtonWithText
          title="Pilih Customer"
          to={K_ROUTE_OFFLINE_BASKET_LIST}
        />
      </View>
    );
  }

  return (
    <>
      <View style={s.contentContainer}>
        <View>
          <Text theme="h5">
            {selectedBasketPreview.customerIdentifier || '<Tanpa Nama>'}
          </Text>
          <Text theme="body3-black60">
            Jumlah barang: {selectedBasketPreview.totalQuantities}
          </Text>
        </View>
        <ButtonWithText
          title="Detail"
          to={K_ROUTE_CART}
          linkProps={linkProps}
        />
      </View>
      <ButtonWithText
        title="Tambah atau Ganti Customer"
        themeType="tertiary"
        to={K_ROUTE_OFFLINE_BASKET_LIST}
      />
    </>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.flexMiddle,
  },
  contentContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mbl,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    ...cStyles.mbl,
  },
});

export default BasketPreview;
