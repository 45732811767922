import { Colors } from '@dkrm/ui-kit-basic';

export const K_PARTNER_NAME_DEKORUMA_INSURANCE_CENTER =
  'Dekoruma Insurance Center';

export const K_ADDON_INSURANCE_TERMS_AND_CONDITION_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000131830-syarat-ketentuan-garansi-furniture-1-tahun';
export const K_ADDON_INSURANCE_CLAIM_URL =
  'https://www.cognitoforms.com/Dekorumacom/FormKlaimAsuransiFurnitureDekoruma';
export const K_ADDON_INSURANCE_POLICY_WORDING_URL =
  'https://static.cermati.com/documents/insurance/Goods%20Insurance/Policy-Wording_MPAR-with-watermark---2022_11_301122071459_216.pdf';

export const K_CERMATI_INSURANCE_STATUS_CODE_DATA_COMPLETE = 'DATA_COMPLETE';
export const K_CERMATI_INSURANCE_STATUS_CODE_ISSUED = 'ISSUED';
export const K_CERMATI_INSURANCE_STATUS_CODE_CANCELLED = 'CANCELLED';
export const K_CERMATI_INSURANCE_STATUS_CODE_EXPIRED = 'EXPIRED';

export const K_CERMATI_INSURANCE_STATUS_LABEL_INACTIVE = 'Belum Aktif';
export const K_CERMATI_INSURANCE_STATUS_LABEL_ACTIVE = 'Aktif';
export const K_CERMATI_INSURANCE_STATUS_LABEL_CANCELLED = 'Dibatalkan';
export const K_CERMATI_INSURANCE_STATUS_LABEL_EXPIRED = 'Kadaluarsa';

export const K_INSURANCE_STATUS_LABELS_BY_INSURANCE_STATUS_CODE: Record<
  string,
  string
> = {
  [K_CERMATI_INSURANCE_STATUS_CODE_DATA_COMPLETE]:
    K_CERMATI_INSURANCE_STATUS_LABEL_INACTIVE,
  [K_CERMATI_INSURANCE_STATUS_CODE_ISSUED]:
    K_CERMATI_INSURANCE_STATUS_LABEL_ACTIVE,
  [K_CERMATI_INSURANCE_STATUS_CODE_CANCELLED]:
    K_CERMATI_INSURANCE_STATUS_LABEL_CANCELLED,
  [K_CERMATI_INSURANCE_STATUS_CODE_EXPIRED]:
    K_CERMATI_INSURANCE_STATUS_LABEL_EXPIRED,
};

export const K_INSURANCE_STATUS_LABEL_THEMES_BY_INSURANCE_STATUS_LABEL: Record<
  string,
  string
> = {
  [K_CERMATI_INSURANCE_STATUS_LABEL_INACTIVE]: 'yellow',
  [K_CERMATI_INSURANCE_STATUS_LABEL_ACTIVE]: 'tosca',
  [K_CERMATI_INSURANCE_STATUS_LABEL_CANCELLED]: 'red',
  [K_CERMATI_INSURANCE_STATUS_LABEL_EXPIRED]: 'black80',
};

export const K_INSURANCE_STATUS_LABEL_BACKGROUND_THEMES_BY_INSURANCE_STATUS_LABEL: Record<
  string,
  string
> = {
  [K_CERMATI_INSURANCE_STATUS_LABEL_INACTIVE]: Colors.C_TERTIARY_YELLOW_1,
  [K_CERMATI_INSURANCE_STATUS_LABEL_ACTIVE]: Colors.C_SECONDARY_TOSCA_1,
  [K_CERMATI_INSURANCE_STATUS_LABEL_CANCELLED]: Colors.C_SECONDARY_RED_1,
  [K_CERMATI_INSURANCE_STATUS_LABEL_EXPIRED]: Colors.C_BLACK_10,
};
