import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActivityIndicator, ScrollView } from '@dkrm/ui-kit-basic';

import { useParams } from 'app-libs/hook_modules/router';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { isAuthLoaded as _isAuthLoaded } from 'app-libs/redux_modules/selectors/auth';

import { useQueryLoadOrderInsuranceInfo } from 'entities/order/utils';

import InsuranceOrderLineCard from './InsuranceOrderLineCard';

const OrderProtectionListPage = memo(() => {
  const dispatch = useDispatch();

  const params = useParams<{ orderKey: string }>();
  const { orderKey } = params;

  const isAuthLoaded = useSelector(_isAuthLoaded);

  const { data, isLoading } = useQueryLoadOrderInsuranceInfo(orderKey);

  useEffect(() => {
    if (!isAuthLoaded) {
      dispatch(loadAuth());
    }
  }, [dispatch, isAuthLoaded]);

  if (isLoading || !data) {
    return <ActivityIndicator />;
  }

  const { insuranceOrderLines } = data;

  return (
    <ScrollView>
      {insuranceOrderLines.map((line) => (
        <InsuranceOrderLineCard
          key={line.id}
          order={data}
          insuranceLine={line}
        />
      ))}
    </ScrollView>
  );
});

export default OrderProtectionListPage;
