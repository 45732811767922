import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useSelector } from 'react-redux';
import { APIError } from 'types';

import { useAuth } from 'app-libs/hook_modules/auth';
import {
  Basket,
  BasketShippingAddress,
} from 'app-libs/redux_modules/entity_modules/basket/types';
import { isLoggedIn as getIsLoggedIn } from 'app-libs/redux_modules/selectors/auth';

import {
  clearBasketLines,
  createOfflineBasket,
  createOfflineBasketShippingAddress,
  createShopAssistantActiveBasket,
  deleteOfflineBasket,
  editCustomerIdentifier,
  getLoadOfflineBasketListQueryKey,
  loadOfflineBasketList,
} from './api';
import {
  EditCustomerIdentifierPayload,
  OfflineBasketPreview,
  OfflineBasketShippingAddressPayload,
  ShopAssistantActiveBasketInfo,
} from './types';

const K_ONE_MINUTE_IN_MILLISECONDS = 60000;

export const useInvalidateBasketListQuery = () => {
  const queryClient = useQueryClient();
  const { isShopAssistant } = useAuth();

  if (isShopAssistant) {
    return () =>
      queryClient.invalidateQueries(getLoadOfflineBasketListQueryKey());
  }
  return () => null;
};

export const useQueryLoadOfflineBasketList = () => {
  const isLoggedIn: boolean = useSelector(getIsLoggedIn);

  return useQuery(
    getLoadOfflineBasketListQueryKey(),
    () => loadOfflineBasketList(),
    {
      staleTime: 0,
      cacheTime: 5 * K_ONE_MINUTE_IN_MILLISECONDS,
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: isLoggedIn,
    },
  );
};

export const useMutationCreateOfflineBasket = (
  options?: UseMutationOptions<OfflineBasketPreview, APIError, string>,
) => {
  return useMutation(
    (customerIdentifier: string) => createOfflineBasket(customerIdentifier),
    options,
  );
};

export const useMutationDeleteOfflineBasket = (
  options?: UseMutationOptions<null, Error, number>,
) => {
  return useMutation(
    (basketId: number) => deleteOfflineBasket(basketId),
    options,
  );
};

export const useMutationEditCustomerIdentifier = (
  options?: UseMutationOptions<
    OfflineBasketPreview,
    APIError,
    EditCustomerIdentifierPayload
  >,
) => {
  const queryClient = useQueryClient();

  const handleSuccess = async (
    res: OfflineBasketPreview,
    payload: EditCustomerIdentifierPayload,
    context: unknown,
  ) => {
    const offlineBasketPreviews: OfflineBasketPreview[] =
      queryClient.getQueryData(getLoadOfflineBasketListQueryKey()) || [];
    const updatedOfflineBasketPreviews = offlineBasketPreviews.filter(
      (offlineBasketPreview) => offlineBasketPreview.id !== res.id,
    );
    updatedOfflineBasketPreviews.push(res);

    queryClient.setQueryData(
      getLoadOfflineBasketListQueryKey(),
      updatedOfflineBasketPreviews,
    );
    await options?.onSuccess?.(res, payload, context);
  };

  return useMutation(
    (payload: EditCustomerIdentifierPayload) => editCustomerIdentifier(payload),
    { ...options, onSuccess: handleSuccess },
  );
};

export const useMutationClearBasketLines = (
  options?: UseMutationOptions<Basket, Error, number>,
) => {
  return useMutation((basketId: number) => clearBasketLines(basketId), options);
};

export const useMutationSelectShopAssistantActiveBasket = (
  options?: UseMutationOptions<ShopAssistantActiveBasketInfo, Error, number>,
) => {
  return useMutation(
    (basketId) => createShopAssistantActiveBasket(basketId),
    options,
  );
};

export const useMutationCreateOfflineBasketShippingAddress = (
  options?: UseMutationOptions<
    BasketShippingAddress,
    APIError,
    OfflineBasketShippingAddressPayload
  >,
) => {
  return useMutation(
    (address) => createOfflineBasketShippingAddress(address),
    options,
  );
};
