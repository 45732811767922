import get from 'lodash/get';
import pick from 'lodash/pick';

import { genProductUrl } from 'app-libs/etc/productHelper';

export function genProduct(product, baseUrl = '') {
  const isAvailableToBuy = get(product, 'availability.isAvailableToBuy', false);
  const price = get(product, 'priceNumber', 0);

  return Object.assign(
    {},
    pick(product, ['objectID', 'title', 'brand', 'url', 'categories']),
    {
      isAvailableToBuy,
      url: genProductUrl(product, baseUrl),
      price,
    },
  );
}

export function genPurchase(order, lines) {
  return {
    order: Object.assign({}, order, {
      linesId: lines.map((line) => line.product.objectID),
    }),
  };
}

export function genPaymentMethod(paymentMethod) {
  return {
    paymentMethod,
  };
}
