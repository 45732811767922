import keyMirror from 'keymirror';
import { Dimensions } from 'react-native';

export const AT = keyMirror({
  SET_DIMENSION: null,
});

const initialWidth = Dimensions.get('window').width;
const initialHeight = Dimensions.get('window').height;

export const initialState = {
  width: initialWidth,
  height: initialHeight,
};

export default function dimensionReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case AT.SET_DIMENSION:
      return { ...state, ...payload };
    default:
      return state;
  }
}

export function setDimension({ width, height }) {
  return {
    type: AT.SET_DIMENSION,
    payload: { width, height },
  };
}
