import React from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { isSketchUp } from '@dkrm/general-libs/Utils/userAgentDetector';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';

import config from 'config';

const K_SOMA_ASSET_WAREHOUSE_URL = `${config.SOMA_URL}/asset-warehouse`;

const NavbarOrder = ({ children, rightItems, history }) => {
  if (!isSketchUp()) {
    const title = get(history, 'location.state.title', 'Detail Pemesanan');
    return (
      <>
        <NavbarTitleCentered title={title} />
        {children}
      </>
    );
  }

  return (
    <>
      <NavbarTitleCentered
        title="Detail Pesanan"
        leftButtonAction={() => {
          actionRouteActionOpenURL(K_SOMA_ASSET_WAREHOUSE_URL, history, {
            isUsingAnchor: true,
          });
        }}
        rightItems={rightItems}
      />
      {children}
    </>
  );
};
NavbarOrder.propTypes = {
  children: PropTypes.node,
  rightItems: PropTypes.arrayOf(PropTypes.element),
  history: PropTypes.shape().isRequired,
};

NavbarOrder.defaultProps = {
  children: null,
  rightItems: [],
};

export default withRouter(NavbarOrder);
