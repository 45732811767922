import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useLocationAPIUtils,
  useLocationChange,
} from 'app-libs/hook_modules/location';
import { loadBasket } from 'app-libs/redux_modules/entity_modules/basket';
import { setCurrentLocation } from 'app-libs/redux_modules/entity_modules/location/reducer';
import {
  ShippingAreaFromAlgolia,
  ShippingAreaFromGoblin,
} from 'app-libs/redux_modules/flow_modules/area/types';
import {
  isLoggedIn as getIsLoggedIn,
  getIsShopAssistant,
  getIsUserOrderOnBehalf,
} from 'app-libs/redux_modules/selectors/auth';

import { useInvalidateBasketStockRecordRelatedQueries } from 'entities/basket/utils';

export const convertShippingAreaFromAlgoliaToGoblin = (
  shippingArea: ShippingAreaFromAlgolia,
): ShippingAreaFromGoblin => ({
  id: parseInt(shippingArea.objectID, 10),
  ...shippingArea,
});

export const useLocationSelectHandler = () => {
  const dispatch = useDispatch();
  const isLoggedIn: boolean = useSelector(getIsLoggedIn);
  const isUserOrderOnBehalf: boolean = useSelector(getIsUserOrderOnBehalf);
  const isShopAssistant: boolean = useSelector(getIsShopAssistant);

  const { handleLocationChangeFromShippingArea } = useLocationChange();
  const actionInvalidateBasketStockRecordRelatedQueries =
    useInvalidateBasketStockRecordRelatedQueries();
  const {
    actionCreateBasketShippingAddressFromShippingArea,
    actionCreateDraftUserShippingAddressFromShippingArea,
  } = useLocationAPIUtils();

  const handleLocationSelect = useCallback(
    async (shippingArea: ShippingAreaFromAlgolia) => {
      const shippingAreaForGoblin =
        convertShippingAreaFromAlgoliaToGoblin(shippingArea);

      const isUserLoggedInNonOrderOnBehalf = isLoggedIn && !isUserOrderOnBehalf;
      const isAnonymousOrOrderOnBehalfExcludeSDA =
        !isLoggedIn || (isUserOrderOnBehalf && !isShopAssistant);

      if (isUserLoggedInNonOrderOnBehalf) {
        await actionCreateDraftUserShippingAddressFromShippingArea({
          shippingAreaGoblin: shippingAreaForGoblin,
          onSuccess: () => {
            dispatch(loadBasket());
          },
          onError: () => {
            dispatch(setCurrentLocation(shippingAreaForGoblin, true));
          },
        });
      } else if (isAnonymousOrOrderOnBehalfExcludeSDA) {
        await actionCreateBasketShippingAddressFromShippingArea({
          shippingAreaGoblin: shippingAreaForGoblin,
          onSuccess: () => {
            dispatch(loadBasket());
          },
          onError: () => {
            dispatch(setCurrentLocation(shippingAreaForGoblin, true));
          },
        });
      } else {
        // SDA handled differently even though they are order on behalf, don't create basket shipping address
        // because SDA has feature that allowed them to search address if the basketShippingAddress
        // not yet filled.
        // Other Reason: SDA should be rarely change the location
        // because they stay in the offline store that always in the same location
        // they change the location maybe to just show price to customer if they buy it to Medan (for example)
        handleLocationChangeFromShippingArea({
          shippingArea: shippingAreaForGoblin,
          isSavedFromUserAction: true,
          shouldReloadBasket: false,
        });
      }
      actionInvalidateBasketStockRecordRelatedQueries();
    },
    [
      isLoggedIn,
      isUserOrderOnBehalf,
      isShopAssistant,
      actionCreateDraftUserShippingAddressFromShippingArea,
      dispatch,
      actionCreateBasketShippingAddressFromShippingArea,
      handleLocationChangeFromShippingArea,
      actionInvalidateBasketStockRecordRelatedQueries,
    ],
  );

  return {
    handleLocationSelect,
  };
};
