import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Rating, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { getNumberInHumanReadableText } from 'app-libs/etc/numberHelper';
import { getQuantitySold } from 'app-libs/etc/productAttributesUtil';

import { ProductCardProduct } from '../types';

const K_MIN_RATING_TO_SHOW = 3;
const K_MIN_NUM_RATING_TO_SHOW = 10;

type ProductRatingProps = {
  product: ProductCardProduct;
};

const ProductRating: React.FC<ProductRatingProps> = ({ product }) => {
  const rating = product?.rating ?? 0;
  const numRating = product?.numRating ?? 0;
  const roundedRating = rating.toFixed(1);
  const quantitySold = getQuantitySold(product);
  const ratingText = ` (${getNumberInHumanReadableText(quantitySold)} Terjual)`;
  if (rating >= K_MIN_RATING_TO_SHOW || numRating >= K_MIN_NUM_RATING_TO_SHOW) {
    return (
      <View style={s.ratingContainer}>
        {rating > 0 && (
          <Rating
            style={cStyles.mtxs}
            base={5}
            value={roundedRating}
            theme="yellow"
            size={12}
          />
        )}
        <Text theme="body5-black60">{ratingText}</Text>
      </View>
    );
  }
  return null;
};

const s = StyleSheet.create({
  ratingContainer: {
    ...cStyles.flexDirRow,
  },
});

export default ProductRating;
