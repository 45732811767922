import keyMirror from 'keymirror';

import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';

export const AT = keyMirror({
  // MEETING
  LOAD_NOTES: null,
  LOAD_NOTES_SUCCESS: null,
  LOAD_NOTES_FAIL: null,

  CREATE_NOTE: null,
  CREATE_NOTE_SUCCESS: null,
  CREATE_NOTE_FAIL: null,

  UPDATE_NOTE: null,
  UPDATE_NOTE_SUCCESS: null,
  UPDATE_NOTE_FAIL: null,

  DELETE_NOTE: null,
  DELETE_NOTE_SUCCESS: null,
  DELETE_NOTE_FAIL: null,
});

export const initialState = {
  asyncState: Object.assign({}, initialAsyncState),
  notes: [],
};

export default function noteReducer(immutableState = initialState, action) {
  const state = Object.assign({}, immutableState);
  state.asyncState = asyncStateReducer(state.asyncState, action, '_NOTES_');
  return state;
}
