import { User } from 'app-libs/redux_modules/auth/types';
import { K_DEKORUMA_USER_ORDER_ON_BEHALF_USER_GROUPS } from 'constants/userConstants';

const K_USERNAME_LENGTH_LIMIT = 30;

export const genDekononymousEmail = (
  phoneNumber: string,
  firstName: string,
): string => {
  if (phoneNumber.length === 0 || firstName.length === 0) {
    return '';
  }

  let phoneNumberWithoutPlus = phoneNumber;
  if (phoneNumber[0] === '+') {
    phoneNumberWithoutPlus = phoneNumber.slice(1);
  }

  const cleanedFirstName = firstName.replace(/[^a-zA-Z0-9]/g, '');
  const firstNameTruncated = cleanedFirstName
    .trim()
    .substring(0, K_USERNAME_LENGTH_LIMIT - phoneNumberWithoutPlus.length);

  return `${firstNameTruncated}${phoneNumberWithoutPlus}@dekononymous.com`.toLowerCase();
};

export const getIsUserOrderOnBehalfFromUserObject = (user: User | null) => {
  return (user?.groups || []).some((group) =>
    K_DEKORUMA_USER_ORDER_ON_BEHALF_USER_GROUPS.includes(group),
  );
};
