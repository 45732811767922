import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { View, Button } from 'react-native';
import { COLORS } from '@dkrm/general-libs/theme';

export default class SimpleFilterItem extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleClick = () => this.props.onChange(this.props.value);

  render() {
    const { label, selected, count } = this.props;
    return (
      <View style={{ padding: 2 }}>
        <Button
          onPress={this.handleClick}
          color={selected ? COLORS.C_PRIMARY : COLORS.C_DEFAULT}
          title={`${label} (${count})`}
        />
      </View>
    );
  }
}
