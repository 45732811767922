import React from 'react';
import { View } from 'react-native';
import { SearchBarBasic } from '@dkrm/algolia-components';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import styles from './styles';

export default class SearchBar extends React.PureComponent {
  static propTypes = {
    containerStyle: stylePropTypes,
  };

  static defaultProps = {
    containerStyle: null,
  };

  searchBarBasicRef = React.createRef();

  render() {
    const { containerStyle, ...rest } = this.props;
    return (
      <View style={[styles.padder, containerStyle]}>
        <SearchBarBasic ref={this.searchBarBasicRef} {...rest} />
      </View>
    );
  }
}
