/**
 * README
 *
 * This file is created as is as a preparation for big refactor.
 * All used routes in StorefrontWeb is defined in this file,
 * So that specific routes for StorefrontWeb can be seen later on
 * when routes/constants.js for all apps have been defined like this
 *
 * Please insert new internal routes between import and export
 * see: "f1-loki/packages/apps/Home/@HomeWeb/src/routes/constants"
 *
 * The followings are sorted alphabetically.
 *
 * @todo Fix all route name constants to include context,
 *       so if it is sorted alphabetically, it also means sorted contextaully
 */

/**
 * David: Code below is copy pasta from app-libs/constants/routeConstants
 */

/**
 * @todo: refactor to some constants only, or just remove this & refactor the component path
 */
import config from 'config';

/** General */
export const K_ROUTE_PRINT = '/print';
export const K_ROUTE_BETA = '/beta';
export const K_ROUTE_ABOUT_US =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115047-dekoruma';

/** Articles */
export const K_ROUTE_3P_HELP =
  'https://www.dekoruma.com/artikel/7305/Cara-Pemesanan,-Pembayaran-dan-Pengiriman';
export const K_ROUTE_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=com.lokinative';
export const K_ROUTE_SMART_BANNER_TOP =
  'https://go.onelink.me/dgWt?pid=smart_banner&c=top&af_dp=dkrm%3A%2F%2Fopen';
export const K_ROUTE_SMART_BANNER_TOP_BANNER =
  'https://go.onelink.me/dgWt?pid=smart_banner&c=top_banner&af_dp=dkrm%3A%2F%2Fopen';
export const K_ROUTE_SMART_BANNER_DRAWER_MENU =
  'https://go.onelink.me/dgWt?pid=smart_banner&c=drawer_menu&af_dp=dkrm%3A%2F%2Fopen';
export const K_ROUTE_SMART_BANNER_DRAWER_MENU_DNB =
  'https://go.onelink.me/dgWt?pid=smart_banner&c=drawer_menu_dnb&af_dp=dkrm%3A%2F%2Fopen';
export const K_ROUTE_SMART_BANNER_TOP_IMPRESSION =
  'https://impression.appsflyer.com/com.lokinative?pid=smart_banner&c=top';
export const K_ROUTE_HOW_TO_SHOP =
  'https://www.dekoruma.com/artikel/10507/bagaimana-saya-berbelanja-di-dekoruma-com';
export const K_ROUTE_HOW_TO_SELL =
  'https://www.dekoruma.com/merchant/register-partner';
export const K_ROUTE_TERMS_CONDITION =
  'https://dekoruma.freshdesk.com/en/support/solutions/articles/17000115625-cara-pembayaran-cicilan';
export const K_ROUTE_HELP =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000117057-hubungi-dekoruma';
export const K_RETURN_POLICY =
  'https://www.dekoruma.com/artikel/54477/perlindungan-pembeli-dekoruma';
export const K_RETAIL_WARRANTY =
  'https://dekoruma.freshdesk.com/en/support/solutions/articles/17000111704-kebijakan-perlindungan-produk-dekoruma';
export const K_PRIVACY_POLICY =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115048-kebijakan-privasi';
export const K_TERMS_CONDITIONS =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115049-syarat-dan-ketentuan-layanan';
export const K_HOW_TO_SHOP_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115039-cara-belanja';
export const K_PROMO_VOUCHER_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115617-promo-voucher';
export const K_INSTALLMENT_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115625-cara-pembayaran-cicilan';
export const K_RETAIL_PRODUCT_PROTECTION_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000111704-perlindungan-produk-retail';
export const K_PRODUCT_REPLACEMENT_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000111705-penukaran-produk-replace';
export const K_REFUND_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000111707-pengembalian-dana-refund';
export const K_ASSEMBLE_SERVICE_URL =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000111706-partner-jasa-rakit-dekoruma';

/** Storefront */
export const K_ROUTE_SEARCH = '/search';
export const K_ROUTE_TOP_SEARCH = '/s/:querySlug';

export const K_ROUTE_CART = '/cart';
export const K_ROUTE_OFFLINE_BASKET_LIST = '/offline-baskets';
export const K_ROUTE_MENU = '/menu';
export const K_ROUTE_CHECKOUT = '/checkout';
export const K_ROUTE_APPLY_VOUCHER = '/checkout/pakai-voucher/:voucherType';
export const K_ROUTE_ADD_CREDIT_CARD = '/checkout/add-card';
export const K_ROUTE_CHECKOUT_PAYMENT_REDIRECT = '/checkout/redirect';
export const K_ROUTE_CHECKOUT_REVIEW = '/checkout/review';
export const K_ROUTE_CHECKOUT_SIGNUP = '/checkout/signup';
export const K_ROUTE_CHECKOUT_LOGIN = '/checkout/login';

export const K_ROUTE_CHECKOUT_SHIPPING_ADDRESS = '/checkout/address/edit';
export const K_ROUTE_CHECKOUT_SHIPPING_ADDRESS_SELECTION =
  '/checkout/address/select';

export const K_ROUTE_OFFLINE_STORE_LIST = '/toko-furniture';
export const K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER = `${K_ROUTE_OFFLINE_STORE_LIST}/:areaSlug`;

export const K_ROUTE_ME = '/me';
export const K_ROUTE_PROFILE = '/profile';
export const K_ROUTE_WALLET = '/celengan';
export const K_ROUTE_LOGIN = '/login';
export const K_ROUTE_LOGIN_EMAIL = '/login-email';
export const K_ROUTE_LOGIN_PHONE_NUMBER = '/login-phone-number';
export const K_ROUTE_LOGIN_V2 = '/login-otp';
export const K_ROUTE_LOGIN_WALL = '/login-wall';
export const K_ROUTE_LOGIN_INTERNAL = '/login-internal';
export const K_ROUTE_LOGOUT = '/logout';
export const K_ROUTE_SIGNUP = '/signup';
export const K_ROUTE_SIGNUP_V2 = '/signup-otp';
export const K_ROUTE_FORGOT_PASSWORD = '/lupa_password';
export const K_ROUTE_PASSWORD_RESET = '/password-reset/confirm/:uidb64/:token';
export const K_ROUTE_VERIFY_EMAIL = '/verify-email/confirm/:uidb64/:token';
export const K_ROUTE_EDIT_PROFILE = '/edit-profile';

export const K_ROUTE_REVIEW_LIST = '/reviews';
export const K_ROUTE_REVIEW_PENDING_LIST = '/reviews/pending';
export const K_ROUTE_REVIEW_COMPLETED_LIST = '/reviews/completed';
export const K_ROUTE_REVIEW_DETAIL = '/reviews/:productReviewKey';

export const K_ROUTE_PROMOTION = '/promosi';
export const K_ROUTE_PROMOTION_DETAILS = '/promosi/:slug';

export const K_ROUTE_PROMOTION_FIMA_WARDROBE = `${K_ROUTE_PROMOTION}/lemari-custom-fima`;
export const K_ROUTE_PROMOTION_DAMA_KITCHEN = `${K_ROUTE_PROMOTION}/dapur-dama`;

export const K_ROUTE_MY_VOUCHERS = '/voucher-saya';

export const K_ROUTE_CAMPAIGNS = '/sale';
export const K_ROUTE_CAMPAIGN_DETAIL = '/sale/:slug';

export const K_BASE_URL_CAMPAIGN_BANNER_URL = `${config.API_URL_MEDIA_CDN}/campaign`;

export const K_ROUTE_NOTIFICATION = '/notifikasi';

export const K_ROUTE_COMPLAIN_GENERIC = '/formulir-retur';
export const K_ROUTE_ORDERS = '/orders';
export const K_ROUTE_ORDER_DETAIL = `/orders/:orderKey`;
export const K_ROUTE_ORDER_DETAIL_V2 = 'orders/v2/:orderKey';
export const K_ROUTE_ORDER_COMPLAIN = `/orders/:orderKey/retur`;
export const K_ROUTE_ORDER_PROTECTION_LIST = `${K_ROUTE_ORDER_DETAIL}/protection`;
export const K_ROUTE_ORDER_PROTECTION_LINE_DETAIL = `${K_ROUTE_ORDER_PROTECTION_LIST}/line/:lineId`;
export const K_ROUTE_ORDERS_COMPLAIN_CREATE = '/retur/create';
export const K_ROUTE_CHECKOUT_COMPLETE_MY_ORDERS = '/orders/pixels';

export const K_ROUTE_SHIPPING_EVENT_LIST = '/shipping-events';

export const K_ROUTE_PRODUCT_HOMEPAGE = '/produk';
export const K_ROUTE_PRODUCT_HOMEPAGE_2 = '/produk2';
export const K_ROUTE_SIMILAR_PRODUCTS = '/p/similar-products/:pid';
export const K_ROUTE_PRODUCT = '/p/:pid/:pslug';
export const K_ROUTE_PRODUCT_SIMPLIFIED = '/p/:pid'; // backward compatibility
export const K_ROUTE_PRODUCT_OVERLAY =
  '/p/:pid/:ismodal'; /** @todo: separated from :params * */
export const K_ROUTE_BOUGHT_TOGETHER = '/bought-together/:pid';
export const K_ROUTE_PRODUCT_OVERLAY_REDIRECT = '/p/:pid/:ismodal/redirect';
export const K_ROUTE_AFTER_ADD_TO_CART_OFFER = '/offer/:pid';

export const K_ROUTE_SHOP_THE_LOOK = '/inspirasi-ruangan';

export const K_PARAMS_H_CATEGORIES = ['/:lvl0', '/:lvl1', '/:lvl2', '/:lvl3'];
export const K_ROUTE_SEARCH_CATEGORIES_LAST =
  K_ROUTE_SEARCH + K_PARAMS_H_CATEGORIES.join('');
export const K_ROUTE_FURNITURE_HOMEPAGE = '/:lvl0(Furniture)';
export const K_ROUTE_FURNITURE_HOMEPAGE_WITH_AREA =
  '/:lvl0(Furniture)/:areaSlug';
export const K_ROUTE_BRANDING_PUSHED_HOMEPAGE = '/pasti-harga-pas';
export const K_ROUTE_FURNITURE_JAPANDI_HOMEPAGE =
  '/:lvl0(Furniture)-:style(Japandi|japandi)';
export const K_ROUTE_ACCESSORIES_HOMEPAGE = '/Aksesoris-Rumah';
export const K_ROUTE_SOFA_HOMEPAGE = '/sofa-pengenalan';
export const K_ROUTE_SOFA_MATERIAL_HOMEPAGE = '/sofa/material';
export const K_ROUTE_HASH_MATERIAL = {
  FABRIC: 'kain',
  LEATHER: 'kulit',
  SOLID_WOOD: 'kayu-solid',
  PROCESSED_WOOD: 'kayu-olahan',
};

/** Mattress */
export const K_ROUTE_MATTRESS = '/kasur';
export const K_ROUTE_MATTRESS_ALL_CATEGORIES = `${K_ROUTE_MATTRESS}${K_ROUTE_SEARCH}/Semua-Kategori`; // prettier-ignore
export const K_ROUTE_CAT = '/cat';
export const K_ROUTE_GUIDE = '/panduan';

export const K_PARAMS_ERR_CATCH = [
  '/:err0',
  '/:err1',
  '/:err2',
  '/:err3',
  '/:err4',
  '/:err5',
];

export const K_ROUTE_BRANDS = '/brands';
export const K_ROUTE_BRAND_DETAIL = '/brands/:bslug';

export const K_ROUTE_ROOMS = '/rooms';
export const K_ROUTE_ROOM_DETAIL = '/rooms/:roomName';
export const K_ROUTE_ROOM_PRODUCT_CATEGORY = '/rooms/:roomName/:lvl0';

export const K_ROUTE_POST_SEARCH = '/artikel'; // '/stories';

/** Article */
export const K_ROUTE_POSTS = '/artikel';
export const K_ROUTE_POSTS_CATEGORY_LIST = '/artikel/categories';
export const K_ROUTE_POSTS_CATEGORY = '/artikel/categories/:postcat';
export const K_ROUTE_POSTS_TAG = '/artikel/tags/:posttag';
export const K_ROUTE_POST_DETAIL = '/artikel/:postid/:postslug';
export const K_ROUTE_POST_DETAIL_SIMPLIFIED = '/artikel/:postid';

export const K_ROUTE_PINNED = '/hot';
export const K_ROUTE_PINNED_DETAIL = '/hot/:lookupkey';

export const K_ROUTE_HASH_JUMP = 'jump';

/** Design - Build */
export const K_ROUTE_DESIGN_BUILD = '/interior';
export const K_ROUTE_DESIGN_REQUEST_FORM = '/interior/request';
export const K_ROUTE_DESIGN_REQUEST_FORM_V2 = '/interior/request-v2';
export const K_ROUTE_DESIGN_REQUEST_FORM_V3 = '/interior/request-v3';
export const K_ROUTE_PROJECT_AUTH = '/interior/my-projects';

export const K_ROUTE_PROJECT_TEMPLATE_SEARCH = '/:type';
export const K_ROUTE_PROJECT_TEMPLATE_GROUP =
  '/project-template-group/:groupId';

export const K_ROUTE_HOW_IT_WORKS = '#how-it-works';
export const K_ROUTE_DESIGN_BUILD_HOW_IT_WORKS =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_HOW_IT_WORKS;

export const K_ROUTE_MATERIAL_PAGE = '/material';
export const K_ROUTE_BUSINESS = '/business';
export const K_ROUTE_BUSINESS_REQUEST_FORM = '/business/inquiry';

export const K_ROUTE_PROJECT_SELECT = '/projects';
export const K_ROUTE_PROJECT_DASHBOARD = '/projects/:id';

export const K_ROUTE_PROJECT_MEETINGS = '/meetings';

export const K_ROUTE_PROJECT_QUOTATION = '/quotation';
export const K_ROUTE_PROJECT_QUOTATION_DETAIL = '/quotation/:quotationId';

export const K_ROUTE_PROJECT_PHOTO = '/photo';

export const K_ROUTE_PROJECT_OFFER = '/offer';

export const K_ROUTE_AB_TEST = '/interior/test-ab';

/**
 * Design - Build | Register Designer
 */
export const K_ROUTE_REGISTER_DESIGNER_HOME = '/register-designer';
export const K_ROUTE_REGISTER_DESIGNER_FORM = '/desainer-interior/register';
export const K_ROUTE_DESIGN_BUILD_REGISTER_DESIGNER_HOME =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_REGISTER_DESIGNER_HOME;
export const K_ROUTE_DESIGN_BUILD_REGISTER_DESIGNER_FORM =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_REGISTER_DESIGNER_FORM;

export const K_ROUTE_INTERIOR_DESIGN_PARTNER = '/idp';
export const K_ROUTE_INTERIOR_DESIGN_INDEPENDENT = '/idi';
export const K_ROUTE_THANK_YOU = '/thank-you';

export const K_ROUTE_REGISTER_DESIGNER_IDP =
  K_ROUTE_REGISTER_DESIGNER_HOME + K_ROUTE_INTERIOR_DESIGN_PARTNER;
export const K_ROUTE_REGISTER_INTERIOR_DESIGN_INDEPENDENT =
  K_ROUTE_REGISTER_DESIGNER_HOME + K_ROUTE_INTERIOR_DESIGN_INDEPENDENT;
export const K_ROUTE_REGISTER_DESIGNER_THANK_YOU =
  K_ROUTE_REGISTER_DESIGNER_HOME + K_ROUTE_THANK_YOU;

export const K_ROUTE_DESIGN_BUILD_REGISTER_DESIGNER_IDP =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_REGISTER_DESIGNER_IDP;
export const K_ROUTE_DESIGN_BUILD_REGISTER_INTERIOR_DESIGN_INDEPENDENT =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_REGISTER_INTERIOR_DESIGN_INDEPENDENT;

/**
 * Design - Build | Designer Profile
 */
export const K_ROUTE_DESIGNER_LEVEL_DETAIL = '/about/designer-levels';

export const K_ROUTE_DESIGNER_PROFILE_V2 =
  '/desainer-interior/v2/:userUuid/:userSlug';
export const K_ROUTE_DESIGNER_PROFILE_SIMPLIFIED_V2 =
  '/desainer-interior/v2/:userUuid'; // backward compatibility
export const K_ROUTE_DESIGNER_EDIT_PROFILE_V2 =
  '/desainer-interior/v2/:userUuid/edit';

/**
 * Design - Build | Project Template
 */
export const K_ROUTE_PROJECT_TEMPLATE = '/project-template';
export const K_ROUTE_DESIGN_BUILD_PROJECT_TEMPLATE =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_PROJECT_TEMPLATE;

/**
 * Design - Build | Request
 */
export const K_ROUTE_REQUEST = '/request';
export const K_ROUTE_REQUEST_V2 = '/request-v2';
export const K_ROUTE_DESIGN_BUILD_REQUEST_PROJECT =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_REQUEST;

/**
 * Design - Build | Project dashboard
 */
export const K_ROUTE_DESIGN_BUILD_PROJECT_SELECT =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_PROJECT_SELECT;
export const K_ROUTE_DESIGN_BUILD_DASHBOARD =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_PROJECT_DASHBOARD;
export const K_ROUTE_DESIGN_BUILD_LOGIN = K_ROUTE_DESIGN_BUILD + K_ROUTE_LOGIN;

/**
 * Design - Build | Projects Dashboard
 */
export const K_ROUTE_DESIGN_BUILD_PIPELINE = '/pipeline';
export const K_ROUTE_DESIGN_BUILD_PROJECTS_DASHBOARD =
  K_ROUTE_DESIGN_BUILD_PROJECT_SELECT + K_ROUTE_DESIGN_BUILD_PIPELINE;

export const K_ROUTE_PROJECT_LIST_TABLE = '/table';
export const K_ROUTE_DESIGN_BUILD_PROJECT_LIST_TABLE =
  K_ROUTE_DESIGN_BUILD_PROJECT_SELECT + K_ROUTE_PROJECT_LIST_TABLE;

export const K_ROUTE_SUMMARY_PROJECT_CREATE = '/portfolio/create';

/**
 * Design - Build | Invoice
 */
export const K_ROUTE_PROJECT_INVOICE = '/invoice';
export const K_ROUTE_PROJECT_INVOICE_DETAIL = '/invoice/:invoiceId';
export const K_ROUTE_DESIGN_BUILD_INVOICE =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_INVOICE;
export const K_ROUTE_DESIGN_BUILD_INVOICE_DETAIL =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_INVOICE_DETAIL;

/**
 * Desin - Build | Reimbursement
 */
export const K_ROUTE_PROJECT_REIMBURSEMENT = '/reimbursement';
export const K_ROUTE_PROJECT_REIMBURSEMENT_DETAIL = `${K_ROUTE_PROJECT_REIMBURSEMENT}/:reimbursementId`;

export const K_ROUTE_PROJECT_CASH_ADVANCE = '/cash-advance';
export const K_ROUTE_PROJECT_CASH_ADVANCE_DETAIL = `${K_ROUTE_PROJECT_CASH_ADVANCE}/:cashAdvanceId`;

/**
 * Design - Build | CART
 */
export const K_ROUTE_PROJECT_CART = '/cart';
export const K_ROUTE_PROJECT_CART_REVISION = '/cart/:activeCartRevisionId';
export const K_ROUTE_PROJECT_CREATE_CART_LINE = `${K_ROUTE_PROJECT_CART}/create`;
export const K_ROUTE_PROJECT_CART_LINE = `${K_ROUTE_PROJECT_CART}/:cartLineId`;
export const K_ROUTE_PROJECT_EDIT_CART_LINE = `${K_ROUTE_PROJECT_CART_LINE}/edit`;
export const K_ROUTE_PROJECT_CART_CREATE_PURCHASE_ORDER = `${K_ROUTE_PROJECT_CART}/create-purchase-order/`;
export const K_ROUTE_DESIGN_BUILD_PROJECT_CART =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_CART;
export const K_ROUTE_DESIGN_BUILD_PROJECT_EDIT_CART_LINE =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_EDIT_CART_LINE;
export const K_ROUTE_DESIGN_BUILD_PROJECT_CREATE_CART_LINE =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_CREATE_CART_LINE;

/**
 * Design - Build | Purchase Order
 */

export const K_ROUTE_PROJECT_PURCHASE_ORDER = '/purchase-order';
export const K_ROUTE_PROJECT_PURCHASE_ORDER_DETAIL =
  '/purchase-order/:purchaseOrderUUID';

/**
 * Design - Build | Records
 */
export const K_ROUTE_PROJECT_RECORDS = '/records';
export const K_ROUTE_DESIGN_BUILD_PROJECT_RECORDS =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_RECORDS;

export const K_ROUTE_DESIGN_BUILD_MEETINGS =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_MEETINGS;
export const K_ROUTE_DESIGN_BUILD_QUOTATION =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_QUOTATION;
export const K_ROUTE_DESIGN_BUILD_QUOTATION_DETAIL =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_QUOTATION_DETAIL;

export const K_ROUTE_DESIGN_BUILD_PHOTO =
  K_ROUTE_DESIGN_BUILD_DASHBOARD + K_ROUTE_PROJECT_PHOTO;

/**
 * Design - Build | Project Template
 */
export const K_ROUTE_DESIGN_BUILD_PROJECT_TEMPLATE_DETAIL =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_PROJECT_TEMPLATE_GROUP;

/**
 * Design - Build | Activity
 */
export const K_ROUTE_ACTIVITY = '/activity';
export const K_ROUTE_TASK = '/tasks';
export const K_ROUTE_MY_TASK = K_ROUTE_ME + K_ROUTE_TASK;

export const K_ROUTE_ACTIVITY_DETAIL = `/:projectKey${K_ROUTE_ACTIVITY}/:id`;

/** Admin Constant */
export const K_ROUTE_MOODBOARD = '/publication/moodboard';

/** Merchant Constant */
export const K_ROUTE_MERCHANT = '/merchant';
export const K_ROUTE_PERFORMANCE = '/performance';
export const K_ROUTE_PARTNER_PERFORMANCE =
  K_ROUTE_MERCHANT + K_ROUTE_PERFORMANCE;
export const K_ROUTE_STOCK_RECORD = '/stockrecord';
export const K_ROUTE_STOCK_RECORD_REVIEW = '/stockrecord/review';
export const K_ROUTE_BULK_ORDERS_PRINT = '/bulk-print';
export const K_ROUTE_MERCHANT_STOCK_RECORD =
  K_ROUTE_MERCHANT + K_ROUTE_STOCK_RECORD;
export const K_ROUTE_MERCHANT_STOCK_RECORD_REVIEW =
  K_ROUTE_MERCHANT + K_ROUTE_STOCK_RECORD_REVIEW;
export const K_ROUTE_MERCHANT_BULK_ORDERS_PRINT =
  K_ROUTE_MERCHANT + K_ROUTE_BULK_ORDERS_PRINT;
export const K_ROUTE_ASSORTMENT = '/assortment';
export const K_ROUTE_NEW = '/new';
export const K_ROUTE_BULK_UPLOAD = '/bulk-upload';
export const K_ROUTE_CATEGORY_INFORMATION = '/category-information';
export const K_ROUTE_CATEGORY_INFORMATION_DETAILS =
  '/category-information/:categoryBreadcrumb';
export const K_ROUTE_EDIT = '/edit';
export const K_ROUTE_SUCCESS = '/success';
export const K_ROUTE_FAILED = '/failed';
export const K_ROUTE_APPROVED = '/approved';
export const K_ROUTE_REJECTED = '/rejected';
export const K_ROUTE_REQUESTED = '/requested';
export const K_ROUTE_DRAFT = '/draft';
export const K_ROUTE_CSV = '/csv';
export const K_ROUTE_MERCHANT_ASSORTMENT =
  K_ROUTE_MERCHANT + K_ROUTE_ASSORTMENT;
export const K_ROUTE_MERCHANT_ASSORTMENT_NEW =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_NEW;
export const K_ROUTE_MERCHANT_ASSORTMENT_EDIT =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_EDIT;
export const K_ROUTE_MERCHANT_ASSORTMENT_CSV =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_CSV;
export const K_ROUTE_MERCHANT_ASSORTMENT_SUCCESS =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_SUCCESS;
export const K_ROUTE_MERCHANT_ASSORTMENT_FAILED =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_FAILED;
export const K_ROUTE_MERCHANT_ASSORTMENT_APPROVED =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_APPROVED;
export const K_ROUTE_MERCHANT_ASSORTMENT_REJECTED =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_REJECTED;
export const K_ROUTE_MERCHANT_ASSORTMENT_REQUESTED =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_REQUESTED;
export const K_ROUTE_MERCHANT_ASSORTMENT_DRAFT =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_DRAFT;
export const K_ROUTE_MERCHANT_ASSORTMENT_BULK_UPLOAD =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_BULK_UPLOAD;
export const K_ROUTE_MERCHANT_ASSORTMENT_CATEGORY_INFORMATION =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_CATEGORY_INFORMATION;
export const K_ROUTE_MERCHANT_ASSORTMENT_CATEGORY_INFORMATION_DETAILS =
  K_ROUTE_MERCHANT_ASSORTMENT + K_ROUTE_CATEGORY_INFORMATION_DETAILS;
export const K_ROUTE_REGISTER_PARTNER = '/register-partner';
export const K_ROUTE_MERCHANT_REGISTER_PARTNER =
  K_ROUTE_MERCHANT + K_ROUTE_REGISTER_PARTNER;

/** Black Assortment */
export const K_ROUTE_PLAIN_BLACK_ASSORTMENT = '/black-assortment';
export const K_ROUTE_BLACK_ASSORTMENT =
  K_ROUTE_MERCHANT + K_ROUTE_PLAIN_BLACK_ASSORTMENT;
export const K_ROUTE_BLACK_ASSORTMENT_NEW =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_NEW;
export const K_ROUTE_BLACK_ASSORTMENT_EDIT =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_EDIT;
export const K_ROUTE_BLACK_ASSORTMENT_CSV =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_CSV;
export const K_ROUTE_BLACK_ASSORTMENT_SUCCESS =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_SUCCESS;
export const K_ROUTE_BLACK_ASSORTMENT_FAILED =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_FAILED;
export const K_ROUTE_BLACK_ASSORTMENT_APPROVED =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_APPROVED;
export const K_ROUTE_BLACK_ASSORTMENT_REJECTED =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_REJECTED;
export const K_ROUTE_BLACK_ASSORTMENT_REQUESTED =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_REQUESTED;
export const K_ROUTE_BLACK_ASSORTMENT_DRAFT =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_DRAFT;
export const K_ROUTE_BLACK_ASSORTMENT_BULK_UPLOAD =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_BULK_UPLOAD;
export const K_ROUTE_BLACK_ASSORTMENT_CATEGORY_INFORMATION =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_CATEGORY_INFORMATION;
export const K_ROUTE_BLACK_ASSORTMENT_CATEGORY_INFORMATION_DETAILS =
  K_ROUTE_BLACK_ASSORTMENT + K_ROUTE_CATEGORY_INFORMATION_DETAILS;

/** Modular Assortment */
export const K_ROUTE_PLAIN_MODULAR_ASSORTMENT = '/modular-assortment';
export const K_ROUTE_MODULAR_ASSORTMENT =
  K_ROUTE_MERCHANT + K_ROUTE_PLAIN_MODULAR_ASSORTMENT;
export const K_ROUTE_MODULAR_ASSORTMENT_NEW =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_NEW;
export const K_ROUTE_MODULAR_ASSORTMENT_EDIT =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_EDIT;
export const K_ROUTE_MODULAR_ASSORTMENT_CSV =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_CSV;
export const K_ROUTE_MODULAR_ASSORTMENT_SUCCESS =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_SUCCESS;
export const K_ROUTE_MODULAR_ASSORTMENT_FAILED =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_FAILED;
export const K_ROUTE_MODULAR_ASSORTMENT_APPROVED =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_APPROVED;
export const K_ROUTE_MODULAR_ASSORTMENT_REJECTED =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_REJECTED;
export const K_ROUTE_MODULAR_ASSORTMENT_REQUESTED =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_REQUESTED;
export const K_ROUTE_MODULAR_ASSORTMENT_DRAFT =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_DRAFT;
export const K_ROUTE_MODULAR_ASSORTMENT_BULK_UPLOAD =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_BULK_UPLOAD;
export const K_ROUTE_MODULAR_ASSORTMENT_CATEGORY_INFORMATION =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_CATEGORY_INFORMATION;
export const K_ROUTE_MODULAR_ASSORTMENT_CATEGORY_INFORMATION_DETAILS =
  K_ROUTE_MODULAR_ASSORTMENT + K_ROUTE_CATEGORY_INFORMATION_DETAILS;

export const K_ROUTE_REPORT = '/report';
export const K_ROUTE_ACCOUNT_BALANCE = '/balance';
export const K_ROUTE_TRANSACTION = '/transaction';
export const K_ROUTE_DISBURSEMENT = '/disbursement';
export const K_ROUTE_DISBURSED_SHIPPING = '/disbursed-shipping';
export const K_ROUTE_MERCHANT_REPORT = K_ROUTE_MERCHANT + K_ROUTE_REPORT;
export const K_ROUTE_MERCHANT_ACCOUNT_BALANCE =
  K_ROUTE_MERCHANT_REPORT + K_ROUTE_ACCOUNT_BALANCE;
export const K_ROUTE_MERCHANT_TRANSACTION =
  K_ROUTE_MERCHANT_REPORT + K_ROUTE_TRANSACTION;
export const K_ROUTE_MERCHANT_DISBURSEMENT =
  K_ROUTE_MERCHANT_REPORT + K_ROUTE_DISBURSEMENT;
export const K_ROUTE_MERCHANT_DISBURSED_SHIPPING =
  K_ROUTE_MERCHANT_REPORT + K_ROUTE_DISBURSED_SHIPPING;

export const K_ROUTE_ADMIN = '/admin';
export const K_ROUTE_CATEGORY_MIGRATION = '/category-migration';
export const K_ROUTE_PRODUCT_EXPORT = '/product-export';
export const K_ROUTE_CREATIVE_APPROVAL = '/creative-approval';
export const K_ROUTE_MERCHANDISING_APPROVAL = '/merchandising-approval';
export const K_ROUTE_MERCHANT_ADMIN = K_ROUTE_MERCHANT + K_ROUTE_ADMIN;
export const K_ROUTE_ADMIN_PRODUCT_EXPORT =
  K_ROUTE_MERCHANT_ADMIN + K_ROUTE_PRODUCT_EXPORT;
export const K_ROUTE_ADMIN_CATEGORY_MIGRATION =
  K_ROUTE_MERCHANT_ADMIN + K_ROUTE_CATEGORY_MIGRATION;
export const K_ROUTE_ADMIN_CREATIVE_APPROVAL =
  K_ROUTE_MERCHANT_ADMIN + K_ROUTE_CREATIVE_APPROVAL;
export const K_ROUTE_ADMIN_MERCHANDISING_APPROVAL =
  K_ROUTE_MERCHANT_ADMIN + K_ROUTE_MERCHANDISING_APPROVAL;

export const K_ROUTE_FAQ = '/faq';
export const K_ROUTE_MERCHANT_FAQ = K_ROUTE_MERCHANT + K_ROUTE_FAQ;

/** Merchant v3 */
export const K_ROUTE_MERCHANT_V3 = '/merchant-v3';

// Host information
export const K_HOST = 'https://www.dekoruma.com';
export const K_DOMAIN = 'www.dekoruma.com';
export const K_DKRMA_HOSTNAME = 'dkr.ma';

/** Query Parameters */
export const K_SORT = 'sort';
export const K_QUERY = 'query';
export const K_PAGE = 'page';
export const K_DISTINCT = 'distinct';
export const K_DF = 'df';
export const K_DFR = 'dfr';
export const K_HF = 'hf';
export const K_HFR = 'hfr';
export const K_FR = 'fr'; // facetsRefinements
export const K_FR_ALL = 'fra'; // facetsRefinements showAll
export const K_NR = 'nr'; // numericRefinements
export const K_HITS_PER_PAGE = 'nh'; // number of hits/product returned

export const K_MOODBOARD_SEARCH_QUERY_PARAM = 'moodboardQuery';
export const K_MOODBOARD_SEARCH_PAGE_PARAM = 'moodboardPage';
export const K_MOODBOARD_SEARCH_SORT_PARAM = 'moodboardSort';
export const K_MOODBOARD_SEARCH_FR_ALL_PARAM = 'moodboardFra';
export const K_MOODBOARD_SEARCH_HITS_PER_PAGE_PARAM = 'moodboardNh';
export const K_MOODBOARD_SEARCH_DFR_PARAM = 'moodboardDfr';
export const K_MOODBOARD_SEARCH_FR_PARAM = 'moodboardFr';
export const K_MOODBOARD_SEARCH_NR_PARAM = 'moodboardNr';

/** Home - Portfolio List & Detail */
export const K_ROUTE_PORTFOLIO_LIST = '/portfolio';
export const K_ROUTE_PORTFOLIO_DETAIL = '/portfolio/:projectKey/:slug';
export const K_ROUTE_PORTFOLIO_DETAIL_V2 = '/portfolio-v2/:projectKey/:slug';
export const K_ROUTE_PORTFOLIO_DETAIL_SIMPLIFIED = '/portfolio/:projectKey';
export const K_ROUTE_PORTFOLIO_DETAIL_SIMPLIFIED_V2 =
  '/portfolio-v2/:projectKey';
export const K_ROUTE_PORTFOLIO_BOQ = '/portfolio/:projectKey/:slug/boq';
export const K_ROUTE_PORTFOLIO_BOQ_SIMPLIFIED = '/portfolio/:projectKey/boq';
export const K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_PATHNAME =
  '/modern-kontemporer';
export const K_PORTFOLIO_STYLE_MODERN_CLASSIC_PATHNAME = '/modern-klasik';
export const K_PORTFOLIO_STYLE_MODERN_TROPIC_PATHNAME = '/modern-tropis';
export const K_PORTFOLIO_STYLE_JAPANDI_NATURAL_PATHNAME = '/japandi-natural';
export const K_ROUTE_PORTOFOLIO_LIST_APARTMENT = `${K_ROUTE_PORTFOLIO_LIST}/apartemen`;
export const K_ROUTE_PORTFOLIO_LIST_HOUSE = `${K_ROUTE_PORTFOLIO_LIST}/rumah`;
export const K_ROUTE_PORTFOLIO_LIST_PREMIUM = `${K_ROUTE_PORTFOLIO_LIST}/premium`;

export const K_ROUTE_DEAL_PACKAGE_DETAIL =
  'interior/deal-package/:projectKey/:slug';
export const K_ROUTE_DEAL_PACKAGE_DETAIL_SIMPLIFIED =
  'interior/deal-package/:projectKey';
export const K_ROUTE_BUSINESS_PORTFOLIO = '/projects';

export const K_ROUTE_BUSINESS_PORTFOLIO_HOSPITALITY_RETAIL = `${K_ROUTE_BUSINESS_PORTFOLIO}/hospitality-&-retail`;
export const K_ROUTE_BUSINESS_PORTFOLIO_DEVELOPER = `${K_ROUTE_BUSINESS_PORTFOLIO}/developer`;
export const K_ROUTE_BUSINESS_PORTFOLIO_OFFICE = `${K_ROUTE_BUSINESS_PORTFOLIO}/office`;

export const K_ROUTE_INTERIOR_DESIGN = '/desain-interior';
export const K_ROUTE_KITCHEN_SET = '/kitchen-set';
export const K_ROUTE_DESIGN = '/desain';
export const K_ROUTE_WALK_IN_CLOSET = '/walk-in-closet';

export const K_ROUTE_INTERIOR_DESIGN_APARTMENT = `${K_ROUTE_INTERIOR_DESIGN}-apartemen`;
export const K_ROUTE_INTERIOR_DESIGN_APARTMENT_1_ROOM = `${K_ROUTE_INTERIOR_DESIGN_APARTMENT}-1-kamar-tidur`;
export const K_ROUTE_INTERIOR_DESIGN_APARTMENT_2_ROOM = `${K_ROUTE_INTERIOR_DESIGN_APARTMENT}-2-kamar-tidur`;
export const K_ROUTE_INTERIOR_DESIGN_APARTMENT_3_ROOM = `${K_ROUTE_INTERIOR_DESIGN_APARTMENT}-3+-kamar-tidur`;
export const K_ROUTE_INTERIOR_DESIGN_APARTMENT_STUDIO = `${K_ROUTE_INTERIOR_DESIGN_APARTMENT}-studio`;

export const K_ROUTE_INTERIOR_DESIGN_HOUSE = `${K_ROUTE_INTERIOR_DESIGN}-rumah`;
export const K_ROUTE_INTERIOR_DESIGN_HOUSE_1_ROOM = `${K_ROUTE_INTERIOR_DESIGN_HOUSE}-1-kamar-tidur`;
export const K_ROUTE_INTERIOR_DESIGN_HOUSE_2_ROOM = `${K_ROUTE_INTERIOR_DESIGN_HOUSE}-2-kamar-tidur`;
export const K_ROUTE_INTERIOR_DESIGN_HOUSE_3_ROOM = `${K_ROUTE_INTERIOR_DESIGN_HOUSE}-3+-kamar-tidur`;

export const K_ROUTE_KITCHEN_SET_LETTER_I = `${K_ROUTE_KITCHEN_SET}-letter-i`;
export const K_ROUTE_KITCHEN_SET_LETTER_L = `${K_ROUTE_KITCHEN_SET}-letter-l`;
export const K_ROUTE_KITCHEN_SET_LETTER_U = `${K_ROUTE_KITCHEN_SET}-letter-u`;

export const K_ROUTE_KITCHEN_SET_ISLAND = `${K_ROUTE_KITCHEN_SET}-island`;

export const K_ROUTE_SEARCH_SUGGESTION = '/search-suggestion';

/** Supply Warehouse */
export const K_ROUTE_SUPPLY_WAREHOUSE = '/supply-warehouse';
export const K_ROUTE_DESIGN_BUILD_SUPPLY_WAREHOUSE =
  K_ROUTE_DESIGN_BUILD + K_ROUTE_SUPPLY_WAREHOUSE;

export const K_ROUTE_FLASH_SALE = '/flash-sale';

/** Home */
export const K_ROUTE_HOME = K_ROUTE_DESIGN_BUILD;
export const K_ROUTE_HOME_REGISTER_DESIGNER_HOME =
  K_ROUTE_HOME + K_ROUTE_REGISTER_DESIGNER_HOME;

export const K_ROUTE_HOME_PROJECT_TEMPLATE =
  K_ROUTE_HOME + K_ROUTE_PROJECT_TEMPLATE;

export const K_ROUTE_HOME_REQUEST_PROJECT = K_ROUTE_HOME + K_ROUTE_REQUEST;

export const K_ROUTE_HOME_PROJECT_TEMPLATE_DETAIL =
  K_ROUTE_HOME + K_ROUTE_PROJECT_TEMPLATE_GROUP;

export const K_ROUTE_HOME_PROJECT_SELECT =
  K_ROUTE_HOME + K_ROUTE_PROJECT_SELECT;

export const K_ROUTE_HOME_DASHBOARD = K_ROUTE_HOME + K_ROUTE_PROJECT_DASHBOARD;

export const K_ROUTE_HOME_LOGIN = K_ROUTE_HOME + K_ROUTE_LOGIN;

export const K_ROUTE_HOME_PIPELINE = '/pipeline';

export const K_ROUTE_HOME_PROJECTS_DASHBOARD =
  K_ROUTE_HOME_PROJECT_SELECT + K_ROUTE_HOME_PIPELINE;

export const K_ROUTE_HOME_PROJECT_LIST_TABLE =
  K_ROUTE_HOME_PROJECT_SELECT + K_ROUTE_PROJECT_LIST_TABLE;

export const K_ROUTE_HOME_INVOICE =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_INVOICE;

export const K_ROUTE_HOME_INVOICE_DETAIL =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_INVOICE_DETAIL;

export const K_ROUTE_HOME_PROJECT_CART =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_CART;

export const K_ROUTE_HOME_PROJECT_CART_REVISION =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_CART_REVISION;

export const K_ROUTE_HOME_PROJECT_PURCHASE_ORDER =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_PURCHASE_ORDER;

export const K_ROUTE_HOME_PROJECT_PURCHASE_ORDER_DETAIL =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_PURCHASE_ORDER_DETAIL;

export const K_ROUTE_HOME_PROJECT_EDIT_CART_LINE =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_EDIT_CART_LINE;

export const K_ROUTE_HOME_PROJECT_CREATE_CART_LINE =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_CREATE_CART_LINE;

export const K_ROUTE_HOME_PROJECT_CART_CREATE_PURCHASE_ORDER =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_CART_CREATE_PURCHASE_ORDER;

export const K_ROUTE_HOME_PROJECT_RECORDS =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_RECORDS;

export const K_ROUTE_HOME_MEETINGS =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_MEETINGS;

export const K_ROUTE_HOME_QUOTATION =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_QUOTATION;

export const K_ROUTE_HOME_QUOTATION_DETAIL =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_QUOTATION_DETAIL;

export const K_ROUTE_HOME_OFFER =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_OFFER;

export const K_ROUTE_HOME_PHOTO =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_PHOTO;

export const K_ROUTE_HOME_REIMBURSEMENT =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_REIMBURSEMENT;

export const K_ROUTE_HOME_REIMBURSEMENT_DETAIL =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_REIMBURSEMENT_DETAIL;

export const K_ROUTE_HOME_CASH_ADVANCE =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_CASH_ADVANCE;

export const K_ROUTE_HOME_CASH_ADVANCE_DETAIL =
  K_ROUTE_HOME_DASHBOARD + K_ROUTE_PROJECT_CASH_ADVANCE_DETAIL;

/** House List */
export const K_ROUTE_REAL_ESTATE_LIST = '/list-properti';
export const K_ROUTE_REAL_ESTATE_LIST_APARTMENT = `${K_ROUTE_REAL_ESTATE_LIST}/apartemen`;
export const K_ROUTE_REAL_ESTATE_LIST_HOUSING = `${K_ROUTE_REAL_ESTATE_LIST}/rumah`;
export const K_ROUTE_REAL_ESTATE_LIST_CITY = `${K_ROUTE_REAL_ESTATE_LIST}/kota`;
export const K_ROUTE_REAL_ESTATE_LIST_SEARCH_SUGGESTION =
  K_ROUTE_REAL_ESTATE_LIST + K_ROUTE_SEARCH_SUGGESTION;

/** House */
export const K_ROUTE_HOUSE = '/properti';
export const K_ROUTE_UNIT_TYPE_LIST = `${K_ROUTE_HOUSE}/tipe-unit`;
export const K_ROUTE_HOUSE_UNIT_SPECIAL = `${K_ROUTE_HOUSE}/special`;

export const K_ROUTE_HOUSE_BOOKING_VISIT_LIST = `/visits`;
export const K_ROUTE_HOUSE_BOOKING_AGREEMENT_LIST = `/agreements`;
export const K_ROUTE_HOUSE_BOOKING_CLAIM_COMMISSION_LIST = `/claim-commissions`;
export const K_ROUTE_HOUSE_BOOKING_DETAIL = `/bookings/:bookingUUID`;
export const K_ROUTE_HOUSE_BOOKING_INVOICE = `/bookings/:bookingUUID/invoice`;
export const K_ROUTE_HOUSE_BOOKING_INVOICE_DETAIL = `/bookings/:bookingUUID/invoice/:invoiceID`;

/** Seller Central */
export const K_ROUTE_HOUSE_SELLER_CENTRAL = `${K_ROUTE_HOUSE}/jual-hunian`;
export const K_ROUTE_HOUSE_SELLER_CENTRAL_LANDING_PAGE = `${K_ROUTE_HOUSE}/promosi/jual-hunian`;

/** House - Daily Tasks */
export const K_ROUTE_HOUSE_DAILY_TASKS = `${K_ROUTE_HOUSE}/daily-tasks`;

/** Booking Dashboard */
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD = `${K_ROUTE_HOUSE}/dashboard`;
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD_VISIT_LIST =
  K_ROUTE_HOUSE_BOOKING_DASHBOARD + K_ROUTE_HOUSE_BOOKING_VISIT_LIST;
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD_AGREEMENT_LIST =
  K_ROUTE_HOUSE_BOOKING_DASHBOARD + K_ROUTE_HOUSE_BOOKING_AGREEMENT_LIST;
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD_CLAIM_COMMISSION_LIST =
  K_ROUTE_HOUSE_BOOKING_DASHBOARD + K_ROUTE_HOUSE_BOOKING_CLAIM_COMMISSION_LIST;
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD_DETAIL =
  K_ROUTE_HOUSE_BOOKING_DASHBOARD + K_ROUTE_HOUSE_BOOKING_DETAIL;
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD_INVOICE =
  K_ROUTE_HOUSE_BOOKING_DASHBOARD + K_ROUTE_HOUSE_BOOKING_INVOICE;
export const K_ROUTE_HOUSE_BOOKING_DASHBOARD_INVOICE_DETAIL =
  K_ROUTE_HOUSE_BOOKING_DASHBOARD + K_ROUTE_HOUSE_BOOKING_INVOICE_DETAIL;

/** Booking Request */
export const K_ROUTE_HOUSE_REQUEST_FORM = `${K_ROUTE_HOUSE}/request`;
export const K_ROUTE_HOUSE_REQUEST_SUCCESS = `${K_ROUTE_HOUSE_REQUEST_FORM}/success`;
export const K_ROUTE_HOUSE_CONSULTANT = `${K_ROUTE_HOUSE_REQUEST_FORM}/consultant`;

/** House - Property Detail */
export const K_ROUTE_PROPERTY_DETAIL_SIMPLIFIED = `${K_ROUTE_HOUSE}/unit/:upc`;
export const K_ROUTE_PROPERTY_DETAIL = `${K_ROUTE_PROPERTY_DETAIL_SIMPLIFIED}/:slug`;

/** House - Real Estate Detail */
export const K_ROUTE_REAL_ESTATE_LEVEL = K_ROUTE_HOUSE;
export const K_ROUTE_REAL_ESTATE_DETAIL = `${K_ROUTE_REAL_ESTATE_LEVEL}/:slug`;
export const K_ROUTE_REAL_ESTATE_FACILITIES = `${K_ROUTE_REAL_ESTATE_DETAIL}/facilities`;

/** House - Resale Unit List */
export const K_ROUTE_RESALE_UNIT_LIST = `${K_ROUTE_REAL_ESTATE_LIST}/properti-dijual`;

// Wishlist
export const K_ROUTE_WISHLIST = '/favorit';

// Knowledge Center
export const K_ROUTE_OTP_KNOWLEDGE_CENTER =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000115554-cara-mendapatkan-kode-otp';

export const K_ROUTE_SKETCHUP_DOWNLOAD =
  'https://s3-ap-southeast-1.amazonaws.com/f1-static/design-and-build/3d-models/:fileName';

export const K_ROUTE_CAREER = 'https://www.kalibrr.id/id-ID/c/dekoruma/jobs';

// Membership
export const K_ROUTE_MEMBERSHIP = '/membership';
export const K_ROUTE_MEMBERSHIP_DASHBOARD_PRO = `${K_ROUTE_MEMBERSHIP}/dashboard-pro`;
export const K_ROUTE_MEMBERSHIP_DASHBOARD_FAMILY =
  '/properti/referral-dashboard';
export const K_ROUTE_MEMBERSHIP_REGISTER_FAMILY = `${K_ROUTE_MEMBERSHIP}/register-family`;
export const K_ROUTE_MEMBERSHIP_REGISTER_SOMA = `${K_ROUTE_MEMBERSHIP}/register-soma`;
export const K_ROUTE_MEMBERSHIP_CATALOGUE = `${K_ROUTE_MEMBERSHIP}/catalogue`;
export const K_ROUTE_MEMBERSHIP_TUTORIAL_LIST = `${K_ROUTE_MEMBERSHIP}/tutorial`;
export const K_ROUTE_MEMBERSHIP_TUTORIAL_DETAIL = `${K_ROUTE_MEMBERSHIP}/tutorial/:tutorialType`;
export const K_ROUTE_MEMBERSHIP_EDIT_PROFILE = `${K_ROUTE_MEMBERSHIP}/edit-profile`;
export const K_ROUTE_SOMA = '/soma';

export const K_ROUTE_INTERIOR = '/interior';
export const K_ROUTE_INTERIOR_PREMIUM = '/interior-premium';
export const K_ROUTE_COVERAGE_AREA = `${K_ROUTE_INTERIOR}#coverage-area`;

export const K_ROUTE_INTERIOR_REQUEST = `${K_ROUTE_INTERIOR}/request`;
export const K_ROUTE_TERMS_AND_CONDITION =
  'https://www.dekoruma.com/artikel/75423';
export const K_ROUTE_ABOUT_US_ARTICLE = '/artikel/2650/about-us';
export const K_ROUTE_ARTICLE = '/artikel';
export const K_ROUTE_DEKORUMA = '/';

export const K_ROUTE_INTERIOR_HOW_IT_WORKS =
  K_ROUTE_INTERIOR + K_ROUTE_HOW_IT_WORKS;

export const K_ROUTE_INTERIOR_FAQ =
  'https://dekoruma.freshdesk.com/support/solutions/articles/17000118208-frequently-asked-question-faq-proyek-desain-interior';

export const K_ROUTE_INTERIOR_PROJECT_SELECT =
  K_ROUTE_INTERIOR + K_ROUTE_PROJECT_SELECT;

export const K_JAPANDI_NATURAL_STYLE_INTERIOR_DESIGN_PATHNAME = `${K_ROUTE_DESIGN}-japandi`;
export const K_JAPANDI_CLASSIC_STYLE_INTERIOR_DESIGN_PATHNAME = `${K_ROUTE_DESIGN}-classic`;
export const K_JAPANDI_MODERN_STYLE_INTERIOR_DESIGN_PATHNAME = `${K_ROUTE_DESIGN}-minimalis-modern`;
export const K_JAPANDI_INDUSTRIAL_STYLE_INTERIOR_DESIGN_PATHNAME = `${K_ROUTE_DESIGN}-industrial`;

export const K_JAPANDI_NATURAL_STYLE_KITCHEN_SET_PATHNAME = `${K_ROUTE_KITCHEN_SET}-minimalis`;
export const K_JAPANDI_CLASSIC_STYLE_KITCHEN_SET_PATHNAME = `${K_ROUTE_KITCHEN_SET}-klasik`;
export const K_JAPANDI_MODERN_STYLE_KITCHEN_SET_PATHNAME = `${K_ROUTE_KITCHEN_SET}-modern`;
export const K_JAPANDI_INDUSTRIAL_STYLE_KITCHEN_SET_PATHNAME = `${K_ROUTE_KITCHEN_SET}-industrial`;
