export const K_SHIPPING_UNIT_MEASUREMENT_CHOICES = [
  { label: 'kg', value: 'kg' },
  { label: 'km', value: 'km' },
];

export const K_SHIPPING_UNIT_MEASUREMENT_CHOICES_DEFAULT =
  K_SHIPPING_UNIT_MEASUREMENT_CHOICES[0];

export const K_DEFAULT_SHIPPING_CITY = 'Jakarta Pusat';
export const K_JABODETABEK = [
  'Jakarta Pusat',
  'Jakarta Timur',
  'Jakarta Utara',
  'Jakarta Selatan',
  'Jakarta Barat',
  'Kepulauan Seribu',
  'Bogor',
  'Kab. Bogor',
  'Depok',
  'Tangerang',
  'Tangerang Selatan',
  'Kab. Tangerang',
  'Bekasi',
  'Kab. Bekasi',
];

export const K_SHIPPING_INFO_FREE = 'Gratis!';

// availability to ship
export const K_AVAILABILITY_SHOW_INSTANT = 'K_AVAILABILITY_SHOW_INSTANT';
export const K_AVAILABILITY_SHOW_INSTANT_CAN_MTO =
  'K_AVAILABILITY_SHOW_INSTANT_CAN_MTO';
export const K_AVAILABILITY_MTO = 'K_AVAILABILITY_MTO';
export const K_AVAILABILITY_EMPTY_STOCK = 'K_AVAILABILITY_EMPTY_STOCK';

export const K_SHIPPING_CLASS_MATERIAL = 'Material';
export const K_MINIMUM_PRICE_FOR_FREE_SHIPPING_MATERIAL = 5000000;

export const K_BULK_CALCULATED_SHIPPING_CLASSES = [K_SHIPPING_CLASS_MATERIAL];

export const K_MIN_BASKET_AMOUNT_ELIGIBLE_FOR_SUBSIDIZED_SHIPPING = 800000;
export const K_MAX_SUBSIZED_SHIPPING_AMOUNT = 200000;
export const K_SUBSIDIZED_SHIPPING_ISLAND = 'Jawa';

export const K_PAYMENT_MIN_PROCESS_DAYS = 0;
export const K_PAYMENT_MAX_PROCESS_DAYS = 0;
