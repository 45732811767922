import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import colors from '@dkrm/ui-kit-basic/colors';
import { View } from '@dkrm/ui-kit-basic';

import { trackClickedExtraFilter } from 'app-libs/trackers';

import { K_A_FILTERING_EXTRA } from 'constants/productConstants';

import CategoryCard from 'app-libs/components/CategoryCard';

import {
  K_BEDDING_QUALITY_ORDER,
  K_BEDDING_SIZE_ORDER,
  K_ATTRIBUTE_LABEL_BEDDING_QUALITY,
  K_ATTRIBUTE_LABEL_BEDDING_SIZE,
} from 'constants/beddingConstants';

import cStyles from '@dkrm/general-libs/theme/styles';
import styles from './styles';

import DisjunctiveFacetFilter from '../DisjunctiveFacetFilter';
import ScrollFilterList from '../lists/ScrollFilterList';
import BeddingSizeItem from '../items/BeddingSizeItem';

const ExtraFilter = React.memo((props) => {
  const { title, breadcrumb, disjunctive, handleOnPress } = props;
  const categories = breadcrumb.split(' > ');

  const beddingQualityComparator = (a, b) =>
    K_BEDDING_QUALITY_ORDER[a.label] - K_BEDDING_QUALITY_ORDER[b.label];
  const beddingSizeComparator = (a, b) =>
    K_BEDDING_SIZE_ORDER[a.label] - K_BEDDING_SIZE_ORDER[b.label];

  const getComparator = (label) => {
    if (label === K_ATTRIBUTE_LABEL_BEDDING_SIZE) return beddingSizeComparator;
    if (label === K_ATTRIBUTE_LABEL_BEDDING_QUALITY)
      return beddingQualityComparator;
    return () => null;
  };
  const getAttributeListProps = (res, comparator) => ({
    contentContainerStyle: styles.container,
    labelStyle: styles.label,
    cellStyle: styles.extraFilterContainer,
    itemSeparatorComponent: () => <View style={cStyles.mrm} />,
    linkUrl: null,
    onPress: (label, value) => {
      trackClickedExtraFilter(value, res.label, title);
      handleOnPress(res.attribute, [value]);
    },
    sortComparator: comparator,
  });

  const renderExtraFilterItem = (res) => (
    <CategoryCard
      {...res}
      labelStyle={{
        ...cStyles.fwn,
        color: colors.C_BLACK_100,
        fontSize: 14,
        lineHeight: 20,
      }}
      containerStyle={{
        ...cStyles.flex1,
        backgroundColor: colors.C_BLACK_5,
        borderColor: colors.C_BLACK_5,
        minHeight: 32,
        minWidth: 64,
      }}
      contentContainerStyle={[styles.container, { height: 32 }]}
    />
  );
  const renderBeddingSizeItem = (res) => (
    <BeddingSizeItem cellStyle={styles.beddingContainer} {...res} />
  );

  return (
    <>
      {categories.map((category) =>
        get(K_A_FILTERING_EXTRA, category, []).map((res) => {
          const comparator = getComparator(res.label);
          const attributeListProps = getAttributeListProps(res, comparator);

          const hasAttribute =
            Object.keys(disjunctive).indexOf(res.attribute) > -1;

          return (
            !hasAttribute && (
              <DisjunctiveFacetFilter
                name={res.attribute}
                listComponent={ScrollFilterList}
                itemComponent={
                  res.label === 'Ukuran Sprei'
                    ? renderBeddingSizeItem
                    : renderExtraFilterItem
                }
                label={res.label}
                listProps={attributeListProps}
              />
            )
          );
        }),
      )}
    </>
  );
});

ExtraFilter.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumb: PropTypes.string.isRequired,
  disjunctive: PropTypes.shape().isRequired,
  handleOnPress: PropTypes.func.isRequired,
};

export default ExtraFilter;
