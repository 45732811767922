/* eslint-disable no-undef */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsShopAssistant } from 'app-libs/redux_modules/selectors/auth';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import ClearBasketButton from '../ClearBasketButton';

const NavbarCart: React.FC = ({ children }) => {
  const isShopAssistant = useSelector(getIsShopAssistant);

  const rightItems = [];
  if (isShopAssistant) {
    rightItems.push(<ClearBasketButton />);
  }

  return (
    <>
      <NavbarTitleCentered title="Keranjang" rightItems={rightItems} />
      {children}
    </>
  );
};

NavbarCart.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavbarCart;
