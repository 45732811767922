import superagent from 'superagent';

import config from 'config';

/*
 * This silly underscore is here to avoid a mysterious "ReferenceError: ApiClient is not defined" error.
 * See Issue #14. https://github.com/erikras/react-redux-universal-hot-example/issues/14
 *
 * Remove it at your own risk.
 */

class ApiClient_ {
  constructor(req) {
    this.jwtToken = null; // Feature APICLIENTWITHAUTHTOKEN: to store temporary token

    ['get', 'post', 'put', 'patch', 'del', 'head'].forEach((method) => {
      this[method] = (path, options) =>
        new Promise((resolve, reject) => {
          const request = superagent[method](this.formatUrl(path));

          // request._callback = function() {}; // workaround https://github.com/visionmedia/superagent/issues/741
          if (options && options.params) {
            request.query(options.params);
          }
          if (options && options.headers) {
            request.set(options.headers);

            // Object.entries(options.headers).map(([k, v]) => {
            //   request.set(k, v);
            // });
          }

          if (options && options.timeout) {
            request.timeout(options.timeout);
          }

          if (__SERVER__) {
            if (!req) console.error('Please pass req in "new ApiClient(req)"');
            if (req && req.get('cookie')) {
              request.set('cookie', req.get('cookie'));
            }
          }
          if (options && options.data) {
            request.send(options.data);
          }

          // Feature APICLIENTWITHAUTHTOKEN: to add token to headers
          // How to use:
          // by default JWT will be passed
          // - add withoutCredentials:false when we do not want it
          if (__CLIENT__) {
            if (options && options.withoutCredentials) {
              // pass
            } else {
              request.withCredentials(); // ? work or not
            }
          }

          request.end((err, res) => {
            if (err) {
              reject(err);
            } else if (res.type === 'text/csv') {
              resolve({ data: res.text, url: res.req.url });
            } else {
              resolve(res.body);
            }
          });
        });
    });
  }

  getUserData(path, options) {
    return this.get(path, options).then((response) => {
      return response;
    });
  }

  login(path, options) {
    return this.post(path, options).then((response) => {
      return response;
    });
  }

  logout(path, options) {
    return this.post(path, options).then((response) => {
      return response;
    });
  }

  /* This was originally a standalone function outside of this class, but babel kept breaking, and this fixes it  */
  formatUrl(path) {
    /**
     * Third party request
     */
    if (
      path.startsWith('http://') ||
      path.startsWith('https://') ||
      path.startsWith('//')
    ) {
      return path;
    }

    /**
     * Default API client
     */
    const adjustedPath = path[0] !== '/' ? `/${path}` : path;
    if (__SERVER__) {
      // Prepend host and port of the API server to the path.
      return `http://localhost:${config.apiPort}${adjustedPath}`;
    }
    if (adjustedPath.startsWith('/api')) {
      return adjustedPath;
    }
    // Prepend `/api` to relative URL, to proxy to API server.
    return `/api${adjustedPath}`;
  }
}
const ApiClient = ApiClient_;

export default ApiClient;
