import React, { memo, useCallback } from 'react';
import { FCFetchData } from 'types';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ActivityIndicator, View } from '@dkrm/ui-kit-basic';

import { SearchConsumer, withSSRSearch } from 'app-libs/AlgoliaAdapter';
import {
  brandingPushedProductInitialState,
  forcedState,
} from 'app-libs/AlgoliaAdapter/searchStates/productSearchStates';
import { SearchAdapter } from 'app-libs/AlgoliaAdapter/types';
import ProductSearchUrlSerializer from 'app-libs/AlgoliaAdapter/urlSerializers/ProductSearchUrlSerializer';
import { withError } from 'app-libs/components/ErrorBoundary';
import { ProductCardProduct } from 'app-libs/components/Product/ProductCard/types';

import { K_ROUTE_BRANDING_PUSHED_HOMEPAGE } from 'constants/routeConstants';

import ProductSearchWrapper from '../ProductSearchWrapper';
import { consumeSSRResultFunction, fetchProductSearchData } from '../utils';
import BrandingPushedHomepageMeta from './BrandingPushedHomepageMeta';

const K_TITLE = 'Furniture Terbaik Pasti Harga Pas';

interface BrandingPushedHomepageProps {
  loading: boolean;
}

const BrandingPushedHomepage: FCFetchData<BrandingPushedHomepageProps> = memo(
  (props) => {
    const { loading: isCentariusLoading } = props;

    const genBreadcrumbs = useCallback((category, options = {}) => {
      return [
        {
          name: category,
          path: K_ROUTE_BRANDING_PUSHED_HOMEPAGE,
          ...options,
        },
      ];
    }, []);

    if (isCentariusLoading) {
      return <ActivityIndicator />;
    }

    return (
      <SearchConsumer>
        {({
          searchAdapter,
        }: {
          searchAdapter: SearchAdapter<ProductCardProduct>;
        }) => (
          <ProductSearchWrapper
            searchAdapter={searchAdapter}
            searchResult={searchAdapter.getResult()}
            loading={isCentariusLoading}
            HeaderInsertComponent={() => <View style={cStyles.mvs} />}
            renderSEOComponent={() => <BrandingPushedHomepageMeta />}
            overrideTitleBreadcrumbAndLevel={{
              level: 3,
              title: K_TITLE,
              breadcrumb: K_TITLE,
            }}
            genBreadcrumbs={genBreadcrumbs}
            shouldHideQuickFilter
            shouldHideSeparator
            shouldHideFloatingSortAndFilter
            shouldHideHierarchicalFacetFilter
          />
        )}
      </SearchConsumer>
    );
  },
);

BrandingPushedHomepage.fetchData = async (context) => {
  return fetchProductSearchData(context);
};

export default withError(
  withSSRSearch({
    key: 'productVisible',
    initialState: brandingPushedProductInitialState,
    forcedState,
    consumeSSRResultFunction,
    UrlSerializer: ProductSearchUrlSerializer,
    shouldSearchOnChange: false,
  })(BrandingPushedHomepage),
);
