import { useCallback } from 'react';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useDispatch } from 'react-redux';
import { APIError } from 'types';

import {
  setBasketData,
  updateBasketLineShippingTierData,
} from 'app-libs/redux_modules/entity_modules/basket';
import { BasketShippingAddress } from 'app-libs/redux_modules/entity_modules/basket/types';

import { useInvalidateLoadAvailablePremiumShippingSlotQuery } from 'entities/shipping/utils';

import {
  K_QUERY_KEY_PREFIX_LOAD_BASKET_ALTERNATIVE_STOCK_INFO,
  K_QUERY_KEY_PREFIX_LOAD_SHIPPING_CHOICE,
  K_QUERY_KEY_PREFIX_LOAD_SHIPPING_METHOD,
  blacklistGWP,
  bulkUpdateBasketLineShippingTier,
  getLoadBasketAlternativeStockInfoQueryKey,
  getLoadShippingChoicesQueryKey,
  getLoadShippingMethodQueryKey,
  loadBasketAlternativeStockInfo,
  loadBasketShippingChoices,
  loadBasketShippingMethod,
  refreshBasketStockAvailable,
  unblacklistGWP,
  updateOrCreateBasketShippingAddress,
} from './api';
import {
  BasketShippingAddressPayload,
  BlacklistGiftWithPurchaseResponse,
  BulkUpdateBasketLineShippingTierByPartnerPayload,
  BulkUpdateBasketLineShippingTierByPartnerResponse,
  LoadBasketAlternativeStockInfoResponse,
  LoadShippingMethodPayload,
  LoadShippingMethodResponse,
  RefreshBasketStockAvailablePayload,
  RefreshBasketStockAvailableResponse,
  ShippingChoicesPayload,
  ShippingChoicesResponse,
  UnblacklistGiftWithPurchaseResponse,
} from './types';

export const useMutationBulkUpdateBasketLineShippingTierByPartner = (
  options?: UseMutationOptions<
    BulkUpdateBasketLineShippingTierByPartnerResponse,
    APIError,
    BulkUpdateBasketLineShippingTierByPartnerPayload[]
  >,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleSuccess = async (
    result: BulkUpdateBasketLineShippingTierByPartnerResponse,
    payload: BulkUpdateBasketLineShippingTierByPartnerPayload[],
    context: unknown,
  ) => {
    await dispatch(updateBasketLineShippingTierData(result.lines));

    await queryClient.invalidateQueries(
      getLoadShippingMethodQueryKey(result.id),
    );
    options?.onSuccess?.(result, payload, context);
  };

  return useMutation((payload) => bulkUpdateBasketLineShippingTier(payload), {
    ...options,
    onSuccess: handleSuccess,
  });
};

export const useMutationUpdateOrCreateBasketShippingAddress = (
  options?: UseMutationOptions<
    BasketShippingAddress,
    APIError,
    BasketShippingAddressPayload
  >,
) => {
  const actionInvalidateShippingRelatedQueries =
    useInvalidateShippingRelatedQueries();
  const actionInvalidateAvailablePremiumShippingMethodQuery =
    useInvalidateLoadAvailablePremiumShippingSlotQuery();
  const actionInvalidateBasketStockRecordRelatedQueries =
    useInvalidateBasketStockRecordRelatedQueries();

  const onSuccess = async (
    result: BasketShippingAddress,
    payload: BasketShippingAddressPayload,
    context: unknown,
  ) => {
    actionInvalidateBasketStockRecordRelatedQueries();
    actionInvalidateAvailablePremiumShippingMethodQuery(
      result.line4,
      result.line3,
    );
    actionInvalidateShippingRelatedQueries();

    options?.onSuccess?.(result, payload, context);
  };

  return useMutation(
    (address) => updateOrCreateBasketShippingAddress(address),
    {
      ...options,
      onSuccess,
    },
  );
};

export const useQueryLoadBasketShippingChoices = (
  basketId: number,
  payload: ShippingChoicesPayload,
  options?: UseQueryOptions<ShippingChoicesResponse, APIError>,
) => {
  return useQuery(
    getLoadShippingChoicesQueryKey(basketId),
    () => loadBasketShippingChoices(payload),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export const useQueryLoadBasketShippingMethod = (
  basketId: number,
  payload: LoadShippingMethodPayload,
  options?: UseQueryOptions<LoadShippingMethodResponse, APIError>,
) => {
  return useQuery(
    getLoadShippingMethodQueryKey(basketId),
    () => loadBasketShippingMethod(payload),
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export const useInvalidateShippingRelatedQueries = () => {
  const queryClient = useQueryClient();

  return async () => {
    await queryClient.invalidateQueries(
      K_QUERY_KEY_PREFIX_LOAD_SHIPPING_CHOICE,
    );
    await queryClient.invalidateQueries(
      K_QUERY_KEY_PREFIX_LOAD_SHIPPING_METHOD,
    );
  };
};

export const useInvalidateBasketStockRecordRelatedQueries = () => {
  const queryClient = useQueryClient();

  const actionInvalidate = useCallback(async () => {
    await queryClient.invalidateQueries(
      K_QUERY_KEY_PREFIX_LOAD_BASKET_ALTERNATIVE_STOCK_INFO,
    );
  }, [queryClient]);

  return actionInvalidate;
};

export const useQueryLoadBasketAlternativeStockInfo = (
  basketId: number,
  routingZoneCode: string,
  options?: UseQueryOptions<LoadBasketAlternativeStockInfoResponse, APIError>,
) => {
  return useQuery(
    getLoadBasketAlternativeStockInfoQueryKey(basketId, routingZoneCode),
    loadBasketAlternativeStockInfo,
    {
      staleTime: 10 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      retry: 1,
      ...options,
    },
  );
};

export const useMutationRefreshBasketStockAvailable = (
  options?: UseMutationOptions<
    RefreshBasketStockAvailableResponse,
    APIError,
    RefreshBasketStockAvailablePayload
  >,
) => {
  const dispatch = useDispatch();

  const handleSuccess = async (
    result: RefreshBasketStockAvailableResponse,
    payload: RefreshBasketStockAvailablePayload,
    context: unknown,
  ) => {
    dispatch(setBasketData(result));
    options?.onSuccess?.(result, payload, context);
  };

  return useMutation(refreshBasketStockAvailable, {
    ...options,
    onSuccess: handleSuccess,
  });
};

export const useMutationBlacklistGWP = (
  options?: UseMutationOptions<
    BlacklistGiftWithPurchaseResponse,
    APIError,
    number
  >,
) => {
  return useMutation(blacklistGWP, options);
};

export const useMutationUnblacklistGWP = (
  options?: UseMutationOptions<
    UnblacklistGiftWithPurchaseResponse,
    APIError,
    number
  >,
) => {
  return useMutation(unblacklistGWP, options);
};
