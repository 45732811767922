import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FCFetchData } from 'types';

import { ActivityIndicator } from '@dkrm/ui-kit-basic';

import { SearchConsumer, withSSRSearch } from 'app-libs/AlgoliaAdapter';
import {
  forcedState,
  initialState,
} from 'app-libs/AlgoliaAdapter/searchStates/productSearchStates';
import { SearchAdapter } from 'app-libs/AlgoliaAdapter/types';
import ProductSearchUrlSerializer from 'app-libs/AlgoliaAdapter/urlSerializers/ProductSearchUrlSerializer';
import { withError } from 'app-libs/components/ErrorBoundary';
import { ProductCardProduct } from 'app-libs/components/Product/ProductCard/types';
import { getCategoryAreaSeos } from 'app-libs/redux_modules/entity_modules/categoryAreaSeo/selectors';

import ProductSearchWrapper from '../ProductSearchWrapper';
import {
  consumeSSRResultFunction,
  fetchProductSearchData,
  useProductListDataPreloadEffect,
} from '../utils';

interface TopSearchPageProps {
  loading: boolean;
}

const TopSearchPage: FCFetchData<TopSearchPageProps> = (
  props: TopSearchPageProps,
) => {
  const { loading: isCentariusLoading } = props;

  useProductListDataPreloadEffect();

  if (isCentariusLoading) {
    return <ActivityIndicator />;
  }

  return (
    <SearchConsumer>
      {({
        searchAdapter,
      }: {
        searchAdapter: SearchAdapter<ProductCardProduct>;
      }) => {
        return (
          <ProductSearchWrapper
            searchAdapter={searchAdapter}
            searchResult={searchAdapter.getResult()}
            loading={isCentariusLoading}
          />
        );
      }}
    </SearchConsumer>
  );
};

TopSearchPage.fetchData = async (context) => {
  return fetchProductSearchData(context);
};

export default compose(
  withError,
  withSSRSearch({
    key: 'productVisible',
    initialState,
    forcedState,
    consumeSSRResultFunction,
    UrlSerializer: ProductSearchUrlSerializer,
    shouldSearchOnChange: false,
  }),
  connect((state) => ({
    categoryAreaSeos: getCategoryAreaSeos(state),
    categorySeos: get(state, 'entities.categorySeos'),
    posts: get(state, 'entities.posts'),
    isCategorySeoLoading: get(
      state,
      'categorySeo.categorySeo.asyncState.loading',
    ),
    categorySeoLoadError: get(
      state,
      'categorySeo.categorySeo.asyncState.loadingError',
    ),
    isPostLoading: get(state, 'browse.post.asyncState.loading'),
  })),
)(TopSearchPage);
