/* eslint-disable camelcase */
import keyMirror from 'keymirror';
import config from 'config';
import { getCreditCardNumberWithoutSpace } from 'app-libs/etc/paymentHelper';
import { AT as AT_AUTH } from '../../auth';

export const AT = keyMirror({
  LOAD_SHIPPING_ADDRESS: null,
  LOAD_SHIPPING_ADDRESS_SUCCESS: null,
  LOAD_SHIPPING_ADDRESS_FAIL: null,

  CREATE_SHIPPING_ADDRESS: null,
  CREATE_SHIPPING_ADDRESS_SUCCESS: null,
  CREATE_SHIPPING_ADDRESS_FAIL: null,

  CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS: null,
  CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_SUCCESS: null,
  CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_FAIL: null,

  UPDATE_SHIPPING_ADDRESS: null,
  UPDATE_SHIPPING_ADDRESS_SUCCESS: null,
  UPDATE_SHIPPING_ADDRESS_FAIL: null,

  REMOVE_SHIPPING_ADDRESS: null,
  REMOVE_SHIPPING_ADDRESS_SUCCESS: null,
  REMOVE_SHIPPING_ADDRESS_FAIL: null,

  SET_DEFAULT_SHIPPING_ADDRESS: null,
  SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS: null,
  SET_DEFAULT_SHIPPING_ADDRESS_FAIL: null,

  SET_SHIPPING_ADDRESS_TO_EDIT: null,

  UPDATE_CUSTOMER: null,
  UPDATE_CUSTOMER_SUCCESS: null,
  UPDATE_CUSTOMER_FAIL: null,
  UPDATE_CUSTOMER_SEGMENT: null,

  REDEEM_VOUCHER: null,
  REDEEM_VOUCHER_SUCCESS: null,
  REDEEM_VOUCHER_FAIL: null,

  LOAD_CREDIT_CARDS: null,
  LOAD_CREDIT_CARDS_SUCCESS: null,
  LOAD_CREDIT_CARDS_FAIL: null,

  ADD_NEW_CREDIT_CARD: null,
  ADD_NEW_CREDIT_CARD_SUCCESS: null,
  ADD_NEW_CREDIT_CARD_FAIL: null,

  RETRIEVE_LAST_USED_PAYMENT: null,
  RETRIEVE_LAST_USED_PAYMENT_SUCCESS: null,
  RETRIEVE_LAST_USED_PAYMENT_FAIL: null,

  POST_TRACK_SUBMIT_CUSTOMER_ADDRESS: null,
  POST_TRACK_SUBMIT_CUSTOMER_ADDRESS_SUCCESS: null,
  POST_TRACK_SUBMIT_CUSTOMER_ADDRESS_FAIL: null,
});

export const initialState = {
  id: null,
  username: null,
  firstName: null,
  email: null,
  lastName: null,
  phoneNumber: null,
  walletBalance: null,
  segment: null,

  shippingAddresses: [],
  creditCards: [],
  lastUsedPayment: {},

  isShippingAddressLoading: false,
  isShippingAddressLoaded: false,
  loadCustomerShippingAddressError: null,
  loaded: false,
  error: null, // error fetching data

  updating: false,
  updateError: false,
  isUpdatingShippingAddress: false,

  redeemingVoucher: false,
  redeemingVoucherError: false,

  shippingAddressIDToEdit: null,
};

// "id": 13,
// "url": "http://localhost:8000/api/v2/addresses/13/",
// "title": "Mr",
// "first_name": "Aru5",
// "last_name": "Pinter",
// "line1": "Graha Kencana",
// "line2": "",
// "line3": "",
// "line4": "line4",
// "state": "wtf state?",
// "postcode": "7777KK",
// "country": "http://localhost:8000/api/v2/countries/ID/",
// "phone_number": "+62 812-121-212",
// "notes": "wets",
// "num_orders": 0,
// "is_default_for_shipping": true,
// "is_default_for_billing": false,
// "date_created": "2015-11-09T11:44:49.320590Z"

/**
 * Main Reducer
 */
export default function customerReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;
  switch (action.type) {
    case AT_AUTH.LOAD_AUTH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AT_AUTH.LOAD_AUTH_SUCCESS:
    case AT_AUTH.LOGIN_SUCCESS:
    case AT_AUTH.SIGNUP_SUCCESS:
      if (action.result) {
        state = {
          ...state,
          ...action.result,
          loading: false,
          loaded: true,
        };
      }
      break;
    case AT.UPDATE_CUSTOMER:
      state = {
        ...state,
        ...action.payload, // Experiment optimistic: updating
        updating: true,
      };
      break;
    case AT.UPDATE_CUSTOMER_SUCCESS:
      if (action.result) {
        state = {
          ...state,
          ...action.result, // Experiment optimistic: get persisted data from server
          updating: false,
        };
      }
      break;
    case AT.UPDATE_CUSTOMER_FAIL:
      state = {
        ...state, // Experiment optimistic: when fail, not update, data wont be gone, but have to show error to retry
        updating: false,
        updateError: action.error,
      };
      break;
    case AT.UPDATE_CUSTOMER_SEGMENT:
      state = {
        ...state,
        segment: action.payload,
      };
      break;
    case AT_AUTH.LOGOUT_SUCCESS:
      state = {
        ...initialState,
        loaded: true,
        segment: state.segment,
      };
      break;
    case AT.LOAD_SHIPPING_ADDRESS:
      state = {
        ...state,
        isShippingAddressLoading: true,
      };
      break;
    case AT.LOAD_SHIPPING_ADDRESS_SUCCESS:
      state = {
        ...state,
        isShippingAddressLoaded: true,
        isShippingAddressLoading: false,
        shippingAddresses: shippingAddressesReducer(
          state.shippingAddresses,
          action,
        ),
      };
      break;
    case AT.LOAD_SHIPPING_ADDRESS_FAIL:
      state = {
        ...state,
        isShippingAddressLoading: false,
        loadCustomerShippingAddressError: action.error,
        shippingAddresses: shippingAddressesReducer(
          state.shippingAddresses,
          action,
        ),
      };
      break;
    case AT.CREATE_SHIPPING_ADDRESS:
    case AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS:
    case AT.UPDATE_SHIPPING_ADDRESS:
    case AT.REMOVE_SHIPPING_ADDRESS:
      state = {
        ...state,
        isUpdatingShippingAddress: true,
      };
      break;
    case AT.CREATE_SHIPPING_ADDRESS_SUCCESS:
    case AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_SUCCESS:
    case AT.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case AT.REMOVE_SHIPPING_ADDRESS_SUCCESS:
      state = {
        ...state,
        isUpdatingShippingAddress: false,
        shippingAddresses: shippingAddressesReducer(
          state.shippingAddresses,
          action,
        ),
      };
      break;
    case AT.CREATE_SHIPPING_ADDRESS_FAIL:
    case AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_FAIL:
    case AT.UPDATE_SHIPPING_ADDRESS_FAIL:
    case AT.REMOVE_SHIPPING_ADDRESS_FAIL:
      state = {
        ...state,
        isUpdatingShippingAddress: false,
        updatingAddressError: action.error,
      };
      break;
    case AT.SET_DEFAULT_SHIPPING_ADDRESS:
      state = {
        ...state,
        settingDefaultAddress: true,
      };
      break;
    case AT.SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS:
      state = {
        ...state,
        settingDefaultAddress: false,
        shippingAddresses: shippingAddressesReducer(
          state.shippingAddresses,
          action,
        ),
      };
      break;
    case AT.SET_DEFAULT_SHIPPING_ADDRESS_FAIL:
      state = {
        ...state,
        settingDefaultAddress: false,
        settingDefaultAddressError: action.error,
      };
      break;
    case AT.SET_SHIPPING_ADDRESS_TO_EDIT:
      state = {
        ...state,
        shippingAddressIDToEdit: action.payload,
      };
      break;
    case AT.REDEEM_VOUCHER:
      state = {
        ...state,
        ...action.payload,
        redeemingVoucher: true,
      };
      break;
    case AT.REDEEM_VOUCHER_SUCCESS:
      if (action.result) {
        state = {
          ...state,
          ...action.result,
          redeemingVoucher: false,
        };
      }
      break;
    case AT.REDEEM_VOUCHER_FAIL:
      state = {
        ...state,
        redeemingVoucher: false,
        redeemingVoucherError: action.error,
      };
      break;
    case AT.LOAD_CREDIT_CARDS:
      state = {
        ...state,
        checkingCreditCard: true,
      };
      break;
    case AT.LOAD_CREDIT_CARDS_SUCCESS:
      state = {
        ...state,
        checkingCreditCard: false,
        creditCards: action.result,
      };
      break;
    case AT.LOAD_CREDIT_CARDS_FAIL:
      state = {
        ...state,
        checkingCreditCardError: action.error,
      };
      break;
    case AT.ADD_NEW_CREDIT_CARD:
      state = {
        ...state,
        isAddingCreditCard: true,
      };
      break;
    case AT.ADD_NEW_CREDIT_CARD_SUCCESS:
      state = {
        ...state,
        isAddingCreditCard: false,
        creditCards: action.result,
      };
      break;
    case AT.ADD_NEW_CREDIT_CARD_FAIL:
      state = {
        ...state,
        isAddingCreditCard: false,
        isAddingCreditCardError: action.error,
      };
      break;
    case AT.RETRIEVE_LAST_USED_PAYMENT:
      state = {
        ...state,
        isGettingLastUsedPayment: true,
      };
      break;
    case AT.RETRIEVE_LAST_USED_PAYMENT_SUCCESS:
      state = {
        ...state,
        isGettingLastUsedPayment: false,
        lastUsedPayment: action.result,
      };
      break;
    case AT.RETRIEVE_LAST_USED_PAYMENT_FAIL:
      state = {
        ...state,
        isGettingLastUsedPayment: false,
        isGettingLastUsedPaymentError: action.error,
      };
      break;
    default:
      break;
  }
  return state;
}

/**
 * Sub Reducer - it reduce @property {Array} shippingAddresses
 */
export function shippingAddressesReducer(
  mutableState = initialState.shippingAddresses,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.LOAD_SHIPPING_ADDRESS_SUCCESS:
      state = [...action.result.results];
      break;
    case AT.CREATE_SHIPPING_ADDRESS_SUCCESS:
      state = [action.result, ...state];
      break;
    case AT.UPDATE_SHIPPING_ADDRESS_SUCCESS:
      {
        const newAddress = state.find(
          (address) => address.id === action.payload.id,
        );
        Object.assign(newAddress, action.result);
      }
      break;
    case AT.REMOVE_SHIPPING_ADDRESS_SUCCESS:
      state = state.filter((address) => {
        return address.id !== action.payload;
      });
      break;
    case AT.SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS:
      state = state.map((address) => ({
        ...address,
        isDefaultForShipping: address.id === action.payload,
      }));
      break;
    case AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_SUCCESS:
      state = state.filter((address) => {
        return address.id !== action.result.id;
      });
      state = [action.result, ...state];
      state = state.map((address) => ({
        ...address,
        isDefaultForShipping: address.id === action.result.id,
      }));
      break;
    default:
      break;
  }

  return state;
}

/**
 * @param  {Object} address - KeyValue pair of firstName, lastName, etc....
 * eg. {
 *    shippingAddressID: 1,
      address: 'Bumbox @ Hall of Residence 15, Main Lobby',
      city: 141,
      firstName: 'Albert',
      district: 2393,
      lastName: 'Datui',
      phoneNumber: '+6581608486',
      province: 12,
      zipCode: '637635'
  }
 */

export function loadShippingAddress(callback) {
  return {
    types: [
      AT.LOAD_SHIPPING_ADDRESS,
      AT.LOAD_SHIPPING_ADDRESS_SUCCESS,
      AT.LOAD_SHIPPING_ADDRESS_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_GOBLIN}/addresses/?format=json`),
    options: {
      callback,
    },
  };
}

export function createShippingAddress(address, callback) {
  const _address = {
    ...address,
    country: `${config.API_URL_GOBLIN}/countries/ID/?format=json`,
  };

  return {
    types: [
      AT.CREATE_SHIPPING_ADDRESS,
      AT.CREATE_SHIPPING_ADDRESS_SUCCESS,
      AT.CREATE_SHIPPING_ADDRESS_FAIL,
    ],
    payload: address,
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/addresses/?format=json`, {
        data: _address,
      }),
    options: {
      callback,
    },
  };
}

export function createUpdateDraftShippingAddress(address, callback) {
  const _address = {
    ...address,
    country: `${config.API_URL_GOBLIN}/countries/ID/?format=json`,
  };

  return {
    types: [
      AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS,
      AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_SUCCESS,
      AT.CREATE_UPDATE_DRAFT_SHIPPING_ADDRESS_FAIL,
    ],
    payload: address,
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/draft-user-address/?format=json`, {
        data: _address,
      }),
    options: {
      callback,
    },
  };
}

export function updateShippingAddress(address, callback) {
  return {
    types: [
      AT.UPDATE_SHIPPING_ADDRESS,
      AT.UPDATE_SHIPPING_ADDRESS_SUCCESS,
      AT.UPDATE_SHIPPING_ADDRESS_FAIL,
    ],
    payload: address,
    promise: (client) =>
      client.put(`${config.API_URL_GOBLIN}/addresses/${address.id}/`, {
        data: address,
      }),
    options: {
      callback,
    },
  };
}

export function removeShippingAddress(addressID) {
  return {
    types: [
      AT.REMOVE_SHIPPING_ADDRESS,
      AT.REMOVE_SHIPPING_ADDRESS_SUCCESS,
      AT.REMOVE_SHIPPING_ADDRESS_FAIL,
    ],
    payload: addressID,
    promise: (client) =>
      client.del(`${config.API_URL_GOBLIN}/addresses/${addressID}/`),
  };
}

export function setDefaultShippingAddress(defaultAddressID) {
  return {
    types: [
      AT.SET_DEFAULT_SHIPPING_ADDRESS,
      AT.SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
      AT.SET_DEFAULT_SHIPPING_ADDRESS_FAIL,
    ],
    payload: defaultAddressID,
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/addresses/${defaultAddressID}/default-for-shipping/?format=json`,
      ),
  };
}

export function setShippingAddressToEdit(shippingAddressID) {
  return {
    type: AT.SET_SHIPPING_ADDRESS_TO_EDIT,
    payload: shippingAddressID,
  };
}

export function resetShippingAddressToEdit() {
  return {
    type: AT.SET_SHIPPING_ADDRESS_TO_EDIT,
    payload: null,
  };
}

/**
 * @param  {Object} creditCard - KeyValue pair of cvv, initialToken, lastFourDigits, month, year
 * eg. {
 *    cvv: '123',
      initialToken: '481111uCBKGXkhYqowiEHGyBSYDg1114',
      lastFourDigits: '1114',
      month: '12',
      year: '2018'
  }
 */
export function loadCreditCards() {
  return {
    types: [
      AT.LOAD_CREDIT_CARDS,
      AT.LOAD_CREDIT_CARDS_SUCCESS,
      AT.LOAD_CREDIT_CARDS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/payment/credit-card/check/?format=json`,
      ),
    options: {
      transformer: (result) => result.results,
    },
  };
}

export function addNewCreditCard(cardNumber, month, year, cvv, callback) {
  const numberWithoutSpace = getCreditCardNumberWithoutSpace(cardNumber);
  const payload = {
    number: numberWithoutSpace,
    month,
    year,
    cvv,
  };
  return {
    types: [
      AT.ADD_NEW_CREDIT_CARD,
      AT.ADD_NEW_CREDIT_CARD_SUCCESS,
      AT.ADD_NEW_CREDIT_CARD_FAIL,
    ],
    payload,
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/payment/credit-card/add/?format=json`,
        {
          data: payload,
        },
      ),
    options: {
      callback,
    },
  };
}

export function redeemingVoucher(voucherCode) {
  return {
    types: [
      AT.REDEEM_VOUCHER,
      AT.REDEEM_VOUCHER_SUCCESS,
      AT.REDEEM_VOUCHER_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/vouchers/redeem/?format=json`, {
        data: {
          redeemCode: voucherCode,
        },
      }),
  };
}

/**
 * @param  {Object} customer - KeyValue pair of
 * {firstName, lastName, username, email, phoneNumber}
 */
export function updateCustomer(customer) {
  return {
    types: [
      AT.UPDATE_CUSTOMER,
      AT.UPDATE_CUSTOMER_SUCCESS,
      AT.UPDATE_CUSTOMER_FAIL,
    ],
    promise: (client) =>
      client.post('/signup', {
        data: customer,
      }),
  };
}

/**
 * Set local customer
 *
 * - example usage:
 *     Injecting customer information from get parameter, although the user is not created yet

 * @param  {Object} customer - KeyValue pair of
 * {firstName, lastName, username, email, phoneNumber}
 */
export function updateLocalCustomer(customer) {
  return {
    type: AT.UPDATE_CUSTOMER,
    payload: customer,
  };
}

export function updateCustomerSegment(segment) {
  return {
    type: AT.UPDATE_CUSTOMER_SEGMENT,
    payload: segment,
  };
}

export function retrieveLastUsedPayment() {
  return {
    types: [
      AT.RETRIEVE_LAST_USED_PAYMENT,
      AT.RETRIEVE_LAST_USED_PAYMENT_SUCCESS,
      AT.RETRIEVE_LAST_USED_PAYMENT_FAIL,
    ],
    promise: (client) => client.get(`${config.API_URL_GOBLIN}/payment/last/`),
  };
}

export function trackSubmitCustomerAddress(address) {
  return {
    types: [
      AT.POST_TRACK_SUBMIT_CUSTOMER_ADDRESS,
      AT.POST_TRACK_SUBMIT_CUSTOMER_ADDRESS_SUCCESS,
      AT.POST_TRACK_SUBMIT_CUSTOMER_ADDRESS_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_GOBLIN}/customer/track-address/`, {
        data: address,
      }),
  };
}
