import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Navbar, NavbarImageButton } from '@dkrm/ui-kit-basic';
import { K_CLOSE, K_ICON_URL_BY_NAMES } from '@dkrm/ui-kit-basic/constants';
import cStyles from '@dkrm/general-libs/theme/styles';

export default class NavbarErrorPrompt extends Component {
  static propTypes = {
    onCloseButtonPress: PropTypes.func,
    children: PropTypes.any.isRequired,
  };

  static defaultProps = {
    onCloseButtonPress: () => null,
  };

  render() {
    return (
      <View style={cStyles.flex1}>
        <Navbar
          style={{ shadowOpacity: 0, elevation: 0 }}
          leftButtonNode={
            <NavbarImageButton
              uri={K_ICON_URL_BY_NAMES[K_CLOSE]}
              onPress={this.props.onCloseButtonPress}
            />
          }
        />
        {this.props.children}
      </View>
    );
  }
}
