/* eslint-disable camelcase, no-global-assign */

/**
 * To prevent uninitialized function of tracker,
 * or anything that's loaded asynchronously
 *
 * case #1: mozila firefox v36 can't initialize Google Tag Manager
 */

export default function createDummyGlobalVariableThirdParty() {
  if (typeof ttq === 'undefined') {
    window.ttq = {
      track: () => {},
      identify: () => {},
      push: () => {},
    };
  }
  if (typeof fbq === 'undefined') {
    window.fbq = () => {};
  }
  if (typeof fbc === 'undefined') {
    window.fbc = {
      user: {},
    };
  }
  if (typeof pinterestc === 'undefined') {
    window.pinterestc = {
      user: {},
      logEvent: () => {},
    };
  }
  if (typeof pintrk === 'undefined') {
    window.pintrk = () => {};
  }

  if (typeof ga === 'undefined') {
    window.ga = () => {};
  }
  if (typeof gtag === 'undefined') {
    window.gtag = () => {};
  }
  if (typeof amplitude === 'undefined') {
    window.amplitude = {
      logEvent: () => {},
      logRevenue: () => {},
      setUserId: () => {},
      setUserProperties: () => {},
    };
  }
  if (typeof criteo_q === 'undefined') {
    window.criteo_q = [];
  }
  if (typeof goog_report_conversion === 'undefined') {
    window.goog_report_conversion = () => {};
  }

  if (typeof _cio === 'undefined') {
    window._cio = {
      track: () => {},
      identify: () => {},
      push: () => {},
    };
  }

  window.dataLayer = window.dataLayer || [];

  if (typeof handleAppStateOnActive === 'undefined') {
    window.handleAppStateOnActive = () => null;
  }

  // Handle bug console error
  // https://app.getsentry.com/dekoruma/loki/issues/128806359/
  // http://stackoverflow.com/questions/3326650/console-is-undefined-error-for-internet-explorer
  if (!window.console) console = { log() {} };
}
