/**
 * Function for find an item in array base on an attribute,
 * and merge additional attribute to that item, and pass a new array
 *
 * @example:
 * var sample_objects = [{
 *   id: 1,
 *   name: 'John'
 * }, {
 *   id: 3,
 *   name: 'Harry'
 * }]
 *
 * var newArrays = findAndUpsert(array, item => item.id == 'Harry', {name: 'World'}, callback);
 * //result:
 * [{
 *   id: 1,
 *   name: 'John'
 * }, {
 *   id: 3,
 *   name: 'World'
 * }]
 */
export default function findAndUpsert(
  array,
  booleanFunction,
  additionalAttribute = {},
  callback = (item) => item,
) {
  let mutableArray = array;

  const indexToBeUpsert = mutableArray.findIndex(booleanFunction);
  if (indexToBeUpsert >= 0) {
    mutableArray = mutableArray.map((item) => {
      if (booleanFunction(item)) {
        return callback(Object.assign({}, item, additionalAttribute));
      }
      return item;
    });
  } else {
    mutableArray = mutableArray.concat(callback(additionalAttribute));
  }
  return mutableArray;
}

/**
 * Find and object in array, if found, update it. otherwise, ignore.
 */
export function findAndUpdate(
  array,
  booleanFunction,
  additionalAttribute = {},
  callback = (item) => item,
) {
  let mutableArray = array;

  const indexToBeUpsert = mutableArray.findIndex(booleanFunction);
  if (indexToBeUpsert >= 0) {
    mutableArray = mutableArray.map((item) => {
      if (booleanFunction(item)) {
        return callback(Object.assign({}, item, additionalAttribute));
      }
      return item;
    });
  }
  return mutableArray;
}
