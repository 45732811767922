import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  title: {
    ...cStyles.pbs,
    ...cStyles.phxl,
  },

  listTitle: {
    ...cStyles.phxl,
    ...cStyles.pbxl,
    ...cStyles.mtxl,
    marginTop: 32,
    fontSize: 21,
  },
  disjunctiveFiltersContainer: {
    ...cStyles.bgw,
    ...cStyles.bwbs,
    ...cStyles.bcm,
  },

  disjunctiveFiltersContentContainer: {
    ...cStyles.mhxl,
    ...cStyles.pvm,
  },

  productCellContainer: {
    ...cStyles.mhs,
    maxWidth: '50%',
  },

  breadCrumbContainer: {
    height: 36,
    ...cStyles.width100,
    ...cStyles.bgw,
    ...cStyles.mbm,
  },

  breadCrumb: {
    ...cStyles.phxl,
    ...cStyles.pvl,
    ...cStyles.width100,
    ...cStyles.flexMiddleLeft,
    ...cStyles.flexWrap,
  },

  breadCrumbText: {
    ...tStyles.caption2,
  },

  productCategory: {
    ...cStyles.flex1,
    ...cStyles.mam,
    ...cStyles.brs,
    justifyContent: 'center',
  },

  productCategoryQuery: {
    flexBasis: '25%',
    flexShrink: 0,
    height: 60,
  },

  filterContainer: {
    ...cStyles.bwtn,
  },

  floatingSortAndFilter: {
    ...cStyles.flexMiddle,
    ...cStyles.width100,
    position: 'fixed',
    minHeight: 48,
  },

  floatingFilterContainer: {
    ...cStyles.bwvn,
    ...cStyles.shadowl,
    shadowOffset: {
      width: 0.4,
      height: 2,
    },
    shadowRadius: 4,
    borderRadius: 24,
  },

  filterLabel: {
    ...cStyles.mhxl,
    ...cStyles.mtm,
    ...cStyles.mbl,
    ...tStyles.h5,
    color: colors.C_BLACK_100,
  },

  productTypeContainer: {
    minWidth: 60,
    height: 32,
  },
  hierarchicalContainer: {
    ...cStyles.mbxl,
  },

  seeAllCatButton: {
    ...cStyles.mvxl,
    ...cStyles.brs,
    ...cStyles.flexMiddle,
    ...cStyles.mhxxl,
    paddingVertical: 10,
    backgroundColor: colors.C_SECONDARY_TOSCA_4,
  },
});
