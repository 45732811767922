import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { momentId } from 'app-libs/etc/dateHelper';
import { parseProject } from 'app-libs/etc/designBuildHelper';
import { getProjectPhaseById } from 'app-libs/redux_modules/entity_modules/selectors/project/phase';

import { K_ROLE_DESIGNER_NAME } from 'constants/designBuildConstants';

import { getDenormalizedPortfolio } from './portfolio';

export const getSummaryProjectByKey = (state, pk) => {
  let project = parseProject(state.entities.summaryProjects[pk]); // parse description
  if (project) {
    project = getDenormalizedPortfolio(project);

    return {
      ...project,
      dateUpdated: momentId(project.dateUpdated),
      phases: project.phases.map((phaseId) =>
        getProjectPhaseById(state, phaseId),
      ),
    };
  }

  /**
   * @todo: move search results from redux to summary project entity
   * the following codes will load project directly from searchState, so project will be loaded faster perceptually
   */
  project = parseProject(
    get(state, 'searchState.projectPublic.result.hits', []).find(
      (p) => p.projectKey === pk,
    ),
  );
  if (!project) return null;

  return {
    ...project,
    dateUpdated: momentId(project.dateUpdated),
  };
};

export const getProjectStateStatus = (state, projectKey) => {
  const entitiesStatusProjects = state.entitiesStatus.projects || {};
  const statusProject = entitiesStatusProjects[projectKey] || {};
  return statusProject;
};

export const isProjectLoading = (state, projectKey) => {
  const statusProject = getProjectStateStatus(state, projectKey);
  return statusProject.loading;
};

export const isLoadingSummaryProject = (state) =>
  state.project.isLoadingSummaryProject;

/**
 * PROJECT MEMBER ROLE
 */
export const isMemberADesigner = (memberRole) =>
  memberRole.role.name === K_ROLE_DESIGNER_NAME;

/**
 * END PROJECT MEMBER ROLE
 */

export const getCartLinesValuePublic = (project) => {
  if (!project) return 0;
  return (
    project?.portfolioCart?.cartLinesValuePublic ||
    project?.cartLinesValuePublic ||
    0
  );
};

export const getProjectRequestFormReferralCode = (state) => {
  return state.project.referralCode;
};

/** Non-pure selector utilities */
export const isCommercialProject = (project) => {
  if (isEmpty(project)) return null;

  const { category } = project;

  return ['Hospitality & Retail', 'Developer', 'Office'].includes(category);
};
