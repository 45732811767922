import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  extraFilterContainer: {
    minWidth: 60,
    height: 32,
  },
  beddingContainer: {
    minWidth: 100,
    height: 64,
  },
  container: {
    ...cStyles.phxl,
    ...cStyles.mbm,
  },
  label: {
    ...cStyles.mhxl,
    ...cStyles.mtm,
    ...cStyles.mbm,
    ...tStyles.h5,
    color: colors.C_BLACK_100,
  },
});
