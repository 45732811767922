import { useMemo, useEffect, useRef } from 'react';
import {
  useLocation,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import qs from 'query-string';

export const useQueries = () => {
  const location = useLocation();
  return useMemo(() => qs.parse(location.search), [location]);
};

const usePreviousProps = (value: TYPEFIXME) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { useLocation, usePreviousProps, useHistory, useParams, useRouteMatch };
