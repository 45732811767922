import { ALGOLIA_AREA_INDEX_NAME } from 'config';

const K_DEFAULT_HITS_PER_PAGE = 999;

export const initialState = {
  index: ALGOLIA_AREA_INDEX_NAME,
  query: '',
  hitsPerPage: K_DEFAULT_HITS_PER_PAGE,
};

export const forcedState = {};
