import React, { memo, useMemo } from 'react';
import DocumentMeta from 'react-document-meta';

import { SearchAdapter } from 'app-libs/AlgoliaAdapter/types';
import { ProductCardProduct } from 'app-libs/components/Product/ProductCard/types';
import { unslugify } from 'app-libs/etc';
import { generateProductImagesMeta } from 'app-libs/etc/metaHelper';
import { useLocation, useParams } from 'app-libs/hook_modules/router';

import { K_HOST } from 'constants/routeConstants';

interface ProductListPageMetaProps {
  searchAdapter: SearchAdapter<ProductCardProduct>;
}

const ProductListPageMeta: React.FC<ProductListPageMetaProps> = memo(
  ({ searchAdapter }) => {
    const { pathname } = useLocation();
    const {
      lvl0 = '',
      areaSlug = '',
      style = '',
    } = useParams<Record<string, string>>();
    const products = searchAdapter.getData();
    const { page = 0 } = searchAdapter.getResult();

    const styleString = style !== '' ? ' Japandi' : '';
    const areaString = areaSlug !== '' ? ` ${unslugify(areaSlug)}` : '';
    const pageString = page > 0 ? ` - Halaman ${page + 1}` : '';
    const additionalString = styleString || areaString;
    const categoryString = unslugify(lvl0);

    // @note(dika) 31 Oct 2024: Only handle category location page as of now
    // will be updated later to handle other pages in product list
    // Search Page / Furniture Homepage / Brand Page / Accessories Page
    const titleMetaString = additionalString
      ? `Pusat ${categoryString}${additionalString} Dekoruma - No. 1 Japandi Style Furnishing${pageString}`
      : `Jual ${categoryString} | dekoruma.com © - No. 1 Japandi Style Furnishing${pageString}`;
    const descriptionMetaString = `Beli Koleksi Terlengkap ${categoryString}${additionalString} dan sekitarnya hanya di Dekoruma, No. 1 Japandi Style Furnishing | ☑️Pembelian Minimal 2 Juta, Bisa Cicilan 0% ☑️Gratis Ongkir`;
    const productImagesMeta = useMemo(
      () =>
        products
          .slice(0, 3)
          .map((product) => generateProductImagesMeta(product)),
      [products],
    );
    const pageQueryString = page > 0 ? `?page=${page + 1}` : '';
    const productUrlMeta = `${K_HOST}${pathname}${pageQueryString}`;

    const meta = useMemo(
      () => ({
        property: {
          'og:url': productUrlMeta,
          'og:title': titleMetaString,
          'og:image': productImagesMeta,
          'og:type': 'product.group',
          'twitter:image': productImagesMeta,
          'og:description': descriptionMetaString,
        },
      }),
      [
        descriptionMetaString,
        productImagesMeta,
        titleMetaString,
        productUrlMeta,
      ],
    );

    const link = useMemo(
      () => ({
        rel: {
          canonical: productUrlMeta,
        },
      }),
      [productUrlMeta],
    );

    return (
      <DocumentMeta
        title={titleMetaString}
        description={descriptionMetaString}
        canonical={productUrlMeta}
        link={link}
        meta={meta}
        extend
      />
    );
  },
);

export default ProductListPageMeta;
