import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withSearch = (mapSearchToProps) => (WrappedComponent) =>
  class SearchComponent extends Component {
    static displayName = `withSearch(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`;

    static contextTypes = {
      searchAdapter: PropTypes.shape({
        getState: PropTypes.func.isRequired,
        getResult: PropTypes.func.isRequired,
        getData: PropTypes.func.isRequired,
        subscribe: PropTypes.func.isRequired,
      }).isRequired,
    };

    componentDidMount() {
      const { searchAdapter } = this.context;
      searchAdapter.subscribe('change', this.handleForceUpdate);
      searchAdapter.subscribe('result', this.handleForceUpdate);
    }

    handleForceUpdate = () => this.forceUpdate();

    render() {
      const { searchAdapter } = this.context;
      const injectedProps = Object.keys(mapSearchToProps).reduce(
        (accum, props) => ({
          ...accum,
          [props]: mapSearchToProps[props](searchAdapter, this.props),
        }),
        {},
      );
      return <WrappedComponent {...injectedProps} {...this.props} />;
    }
  };

export default withSearch;
