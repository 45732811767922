import creditcardutils from 'creditcardutils';
import isEmpty from 'lodash/isEmpty';

import { formatPrice, formatPriceId } from 'app-libs/etc';

import {
  K_CREDIT_CARD_INSTALLMENT_TERMS,
  K_CREDIT_CARD_TYPE,
  K_MINIMUM_TOTAL_PAYABLE_BY_INSTALLMENT_TERMS,
} from 'constants/bankConstants';

const K_MAX_EXP_YEAR_LENGTH = 4;

export function getCreditCardType(number) {
  const cardNumber = number || '';
  if (cardNumber.startsWith('4')) {
    return K_CREDIT_CARD_TYPE.VISA;
  }

  if (
    cardNumber.startsWith('51') ||
    cardNumber.startsWith('52') ||
    cardNumber.startsWith('53') ||
    cardNumber.startsWith('54') ||
    cardNumber.startsWith('55')
  ) {
    return K_CREDIT_CARD_TYPE.MASTERCARD;
  }

  const firstFourDigits = parseInt(cardNumber.substring(0, 4), 10);
  if (firstFourDigits >= 2221 && firstFourDigits <= 2720) {
    return K_CREDIT_CARD_TYPE.MASTERCARD;
  }

  if (cardNumber.startsWith('34') || cardNumber.startsWith('37')) {
    return K_CREDIT_CARD_TYPE.AMEX;
  }

  if (firstFourDigits >= 3528 && firstFourDigits <= 3589) {
    return K_CREDIT_CARD_TYPE.JCB;
  }

  return null;
}

/** Credit Card Installment Rules */
export function isAllowedCreditCardZeroInterestInstallment({
  totalPayable,
  installmentTerm = 3,
  installmentBank = '',
  ignoreInstallmentBankValidation,
}) {
  return !getCreditCardZeroInstallmentValidationMessage({
    totalPayable,
    installmentTerm,
    installmentBank,
    ignoreInstallmentBankValidation,
  });
}

export function getCreditCardZeroInstallmentValidationMessage({
  totalPayable,
  installmentTerm = 3,
  installmentBank = '',
  ignoreInstallmentBankValidation,
}) {
  const minimumPurchase =
    K_MINIMUM_TOTAL_PAYABLE_BY_INSTALLMENT_TERMS[installmentTerm];
  if (
    !ignoreInstallmentBankValidation &&
    K_CREDIT_CARD_INSTALLMENT_TERMS[installmentBank] &&
    !K_CREDIT_CARD_INSTALLMENT_TERMS[installmentBank].includes(installmentTerm)
  ) {
    return `Cicilan ${installmentTerm} bulan belum bisa dilakukan untuk bank ini`;
  }
  if (totalPayable < minimumPurchase) {
    const minimumTotalPayableString = `Rp ${formatPrice(minimumPurchase)}`;
    return `Belanja lagi sampai dengan ${minimumTotalPayableString} untuk menikmati cicilan ${installmentTerm} bulan`;
  }
  return null;
}

/** Installment Info Helper */
export function getFormattedMonthlyInstallment(total, duration) {
  const monthlyInstallments = Math.ceil(total / duration);
  return formatPriceId(monthlyInstallments);
}

export function getMaxInstallmentTerm(totalPayable) {
  const availableTerms = Object.keys(
    K_MINIMUM_TOTAL_PAYABLE_BY_INSTALLMENT_TERMS,
  ).filter(
    (term) =>
      K_MINIMUM_TOTAL_PAYABLE_BY_INSTALLMENT_TERMS[term] <= totalPayable,
  );
  return Math.max(...availableTerms, 0);
}

export function getInstallmentTotalPayable(
  product,
  installmentDuration,
  ignoreInstallmentBankValidation = false,
) {
  if (!product || !installmentDuration) return 0;
  const { price, productOffer } = product;
  const priceAfterRebate = isEmpty(productOffer)
    ? null
    : price - productOffer.discount;

  let totalPayable = price;
  if (
    (!!priceAfterRebate || priceAfterRebate === 0) &&
    priceAfterRebate !== price
  ) {
    totalPayable = priceAfterRebate;
  }
  if (
    !isAllowedCreditCardZeroInterestInstallment({
      totalPayable,
      installmentTerm: installmentDuration,
      ignoreInstallmentBankValidation,
    })
  ) {
    return 0;
  }
  return totalPayable;
}

export function getCreditCardBin(cardNumberOrInitialToken) {
  if (!cardNumberOrInitialToken) return null;

  const cardNumberOrInitialTokenWithoutSpace = cardNumberOrInitialToken.replace(
    / /g,
    '',
  );
  if (cardNumberOrInitialTokenWithoutSpace.length < 6) return null;
  return cardNumberOrInitialTokenWithoutSpace.substring(0, 6);
}

export function getCreditCardNumberWithoutSpace(number) {
  if (!number) return null;
  return number.replace(/ /g, '');
}

export function getCreditCardLabel(lastFourDigits, expMonth, expYear) {
  const fourDots = '\u2022\u2022\u2022\u2022';

  if (expMonth && expYear) {
    return `${fourDots} ${lastFourDigits} (${expMonth}/${expYear})`;
  }
  return `${fourDots} ${lastFourDigits}`;
}

export function isCreditCardNumberValid(cardNumber) {
  return !!cardNumber && creditcardutils.validateCardNumber(cardNumber);
}

export function isCreditCardExpMonthValid(cardExpMonth) {
  const month = parseInt(cardExpMonth, 10);
  return !!cardExpMonth && month >= 1 && month <= 12;
}

export function isCreditCardExpYearValid(cardExpYear) {
  return (
    !!cardExpYear &&
    (cardExpYear.length === 2 || cardExpYear.length === K_MAX_EXP_YEAR_LENGTH)
  );
}

export function isCreditCardCvvValid(cardCvv) {
  return !!cardCvv && creditcardutils.validateCardCVC(cardCvv);
}

// @TODO: UPDATE this func so can be used along with native
export function isCreditCardDataValid(
  cardNumber,
  cardExpMonth,
  cardExpYear,
  cardCvv,
) {
  return (
    isCreditCardNumberValid(cardNumber) &&
    isCreditCardExpMonthValid(cardExpMonth) &&
    isCreditCardExpYearValid(cardExpYear) &&
    isCreditCardCvvValid(cardCvv) &&
    creditcardutils.validateCardExpiry(cardExpMonth, cardExpYear)
  );
}
