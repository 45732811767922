/* eslint-disable import/no-useless-path-segments */
import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '@dkrm/ui-kit-basic/LoadingPage';

export default loadable(
  () => import('../@ProductReferralCommissionSearchPage'),
  {
    fallback: <LoadingPage />,
  },
);
