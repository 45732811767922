import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';

export const getLocationAddress = (
  keys: Array<keyof UserAddressResponseFromGoblin>,
  userAddress: UserAddressResponseFromGoblin,
) => {
  return keys
    .reduce((acc, key) => {
      if (userAddress[key]) return `${acc}, ${userAddress[key]}`;
      return acc;
    }, '')
    .substring(2);
};

export const getRecipientName = (
  keys: Array<keyof UserAddressResponseFromGoblin>,
  userAddress: UserAddressResponseFromGoblin,
) => {
  return keys
    .reduce((acc, key) => {
      if (userAddress[key]) return `${acc} ${userAddress[key]}`;
      return acc;
    }, '')
    .substring(1);
};
