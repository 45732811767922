import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import qs from 'query-string';

import { makeParametricUrl, slugify, unslugify } from 'app-libs/etc';
import {
  getBreadcrumbFromIncompleteParams,
  getBreadcrumbRightMost,
} from 'app-libs/etc/categoryHelper';
import { pathWithoutParams } from 'app-libs/etc/routeHelper';
import layout from 'app-libs/layout';
import {
  ALGOLIA_DEFAULT_INDEX_NAME,
  K_DEFAULT_HITS_PER_PAGE,
} from 'app-libs/redux_modules/flow_modules/search';
import { category } from 'app-libs/serializers/category';

import {
  K_FR_ALL,
  K_HFR,
  K_ROUTE_BRAND_DETAIL,
  K_ROUTE_CAMPAIGNS,
  K_ROUTE_SEARCH,
} from 'constants/routeConstants';

import { getProductCategoryTree } from '../../redux_modules/flow_modules/selectors/category';
import {
  convertQueryParamsToState,
  convertStateToQueryParams,
} from '../helper/queryParser';

export const HFR_KEY = 'hcategories_by_function';
export const PARAMETRIC_ROUTE = '/:lvl0';
export const DEFAULT_PARAMS = {
  dfr: '{"isVisible":["-false"]}',
  page: 1,
  query: '',
  sort: ALGOLIA_DEFAULT_INDEX_NAME,
  [K_FR_ALL]: true,
};
export const DEFAULT_MAX_VALUES_PER_FACET = 100;

export default class ProductSearchStateManager {
  constructor(getState, options = {}) {
    this.getReduxState = getState;
    if (options && options.basePath) {
      this.basePath = options.basePath;
    } else {
      this.basePath = '';
    }
  }

  initialState = {
    index: ALGOLIA_DEFAULT_INDEX_NAME,
    query: '',
    // Pagination Parameters
    hitsPerPage: K_DEFAULT_HITS_PER_PAGE,
    // page: 0,
    // Faceting Parameters
    // maxValuesPerFacet: DEFAULT_MAX_VALUES_PER_FACET,
    // facets: ['type', 'shipping', 'salePrice'],
    disjunctiveFacets: [
      'brand',
      'color',
      'special',
      'vouchers.code',
      'productOffer.offer.campaign.name',
      'productOffer.discount',
      'filteringAttributes.aPattern',
      'filteringAttributes.aMaterial',
      'filteringAttributes.aFirmnessMatras',
      'filteringAttributes.aLebarMatras',
      'filteringAttributes.aKategoriMatras',
      'filteringAttributes.aSizeType',
      'filteringAttributes.aQuality',
      'lengthWidthDimension',
    ], // di override di etc/layoutHelper, getDisjunctiveFacets
    hierarchicalFacets: [
      {
        name: 'hcategories_by_function',
        attributes: [
          'hcategories.lvl0',
          'hcategories.lvl1',
          'hcategories.lvl2',
          'hcategories.lvl3',
        ],
        sortBy: ['count:desc', 'name:asc'],
      },
    ],

    disjunctiveFacetsRefinements: {
      // - means exclude
      // src : https://github.com/algolia/instantsearch.js/issues/1232
      isVisible: ['-false'],
    },

    // Refinements
    // disjunctiveFacetsRefinements: {},
    // hierarchicalFacetsRefinements: { hcategories_by_function: [ 'Home > Bathroom' ] }
  };

  serialize = (params, queryParams) => {
    const stateFromParams = {};

    const isSalePage =
      params.lvl0 === K_ROUTE_CAMPAIGNS.substring(1, K_ROUTE_CAMPAIGNS.length);

    if (isSalePage) {
      stateFromParams.numericRefinements = {
        'productOffer.discount': {
          '>=': [0],
        },
      };
    } else if (params.bslug) {
      const brandName = unslugify(params.bslug);
      stateFromParams.disjunctiveFacetsRefinements = {
        brand: [brandName],
      };
    } else {
      let breadcrumb = null;

      if (!params.breadcrumb) {
        const categoryTree = getProductCategoryTree(this.getReduxState());
        const allBreadcrumbs = category
          .updateCategoryTree(categoryTree)
          .getBreadcrumbs();
        breadcrumb = getBreadcrumbFromIncompleteParams(params, allBreadcrumbs);
      } else {
        // eslint-disable-next-line prefer-destructuring
        breadcrumb = params.breadcrumb;
      }

      stateFromParams.hitsPerPage = layout.getHitsPerPage(breadcrumb);
      if (breadcrumb && pathWithoutParams(params.lvl0) !== 'search') {
        stateFromParams.hierarchicalFacetsRefinements = {
          [HFR_KEY]: [breadcrumb],
        };
      }
    }

    const stateFromQuery = convertQueryParamsToState(queryParams);

    return merge({}, stateFromQuery, stateFromParams);
  };

  deserialize = (state) => {
    const queryParams = convertStateToQueryParams(state);

    const isSalePage = has(state, [
      'numericRefinements',
      'productOffer.discount',
    ]);

    const isBrandPage = has(state, ['disjunctiveFacetsRefinements', 'brand']);

    // strip default
    Object.keys(queryParams).forEach((key) => {
      if (
        has(DEFAULT_PARAMS, key) &&
        isEqual(queryParams[key], DEFAULT_PARAMS[key])
      ) {
        delete queryParams[key];
      }
    });

    let path = this.basePath;

    if (has(state, ['hierarchicalFacetsRefinements', HFR_KEY])) {
      const rightMostBreadcrumb = getBreadcrumbRightMost(
        state.hierarchicalFacetsRefinements[HFR_KEY][0],
      );
      path += makeParametricUrl(PARAMETRIC_ROUTE, {
        lvl0: slugify(rightMostBreadcrumb),
      });
      delete queryParams[K_HFR];
    } else if (isSalePage) {
      path += K_ROUTE_CAMPAIGNS;
    } else if (isBrandPage) {
      path += makeParametricUrl(K_ROUTE_BRAND_DETAIL, {
        bslug: state.disjunctiveFacetsRefinements.brand[0],
      });
    } else {
      path += K_ROUTE_SEARCH;
    }

    if (!isEmpty(queryParams)) {
      path += `?${qs.stringify(queryParams)}`;
    }

    return path;
  };
}
