import { isBreadcrumbFurnitureWide } from 'app-libs/etc/categoryHelper';
import { isMattressPage } from 'app-libs/etc/layoutHelper';
import { shouldShowPatternFilter } from './indexV2';

export { getGridNumberOfColumns, shouldShowPatternFilter } from './indexV2';

/**
 * @deprecated use shouldShowPatternFilter(breadcrumb) instead
 * @param {string} breadcrumb
 */
export function isLayoutWithoutSidebarFacet(breadcrumb) {
  return shouldShowPatternFilter(breadcrumb);
}

/**
 * Layout Utility Tools
 */
function getNumOfColumn(breadcrumb) {
  // Eg. Sofa, Meja tulis... display 2 column grid
  if (isBreadcrumbFurnitureWide(breadcrumb)) {
    return 3;
  }
  return 4;
}

function getHitsPerPage(breadcrumb) {
  if (isLayoutWithoutSidebarFacet(breadcrumb)) return 6 * 5;
  if (isBreadcrumbFurnitureWide(breadcrumb)) return 6 * 3;
  if (isMattressPage(breadcrumb)) return 10;
  return 6 * 4;
}

export default {
  getHitsPerPage,
  getNumOfColumn,
};
