import keyMirror from 'keymirror';
import { AsyncState, ReduxPromiseAction } from 'types';

export const AT = keyMirror({
  LOAD_AVAILABLE_INSTALLATION_SLOT: null,
  LOAD_AVAILABLE_INSTALLATION_SLOT_SUCCESS: null,
  LOAD_AVAILABLE_INSTALLATION_SLOT_FAIL: null,
});

export interface InstallationState {
  availableInstallationSlot: AvailableInstallationSlotState;
}

export interface AvailableInstallationSlotState {
  asyncState: AsyncState;
  availabilityByPartnerName: Record<string, InstallationAvailability>;
}

export interface InstallationAvailability {
  maxSlotByShift: Record<string, number>;
  reservedShiftByDate: Record<string, Record<string, number>>;
}

export interface AvailableInstallationSlotAction
  extends ReduxPromiseAction<InstallationAvailability> {
  types: [
    typeof AT.LOAD_AVAILABLE_INSTALLATION_SLOT,
    typeof AT.LOAD_AVAILABLE_INSTALLATION_SLOT_SUCCESS,
    typeof AT.LOAD_AVAILABLE_INSTALLATION_SLOT_FAIL,
  ];
  payload?: any;
}

export type InstallationActionTypes = AvailableInstallationSlotAction;
