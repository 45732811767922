import superagent from 'superagent';
import config from 'config';

import { getUrlWithQuery } from 'app-libs/etc/routeHelper';
import { OfflineStoreLocation, OfflineStoreLocationSEOArea } from './types';

export const getLoadOfflineStoreLocationListQueryKey = (
  areaFilter?: string,
) => {
  if (!areaFilter) {
    return ['offline-store-location'];
  }
  return ['offline-store-location', areaFilter];
};

export const K_LOAD_OFFLINE_STORE_LOCATION_SEO_AREA_QUERY_KEY = [
  'offline-store-seo-areas',
];

export const loadOfflineStoreLocationList = async (
  areaFilter?: string,
): Promise<OfflineStoreLocation[]> => {
  const baseUrl = `${config.API_URL_GOBLIN}/shop-assistant/store-locations/`;
  const url = areaFilter
    ? getUrlWithQuery(baseUrl, { seoArea: areaFilter })
    : baseUrl;

  const res = await superagent.get(url);
  return res.body;
};

export const loadOfflineStoreLocationSEOAreaList = async (): Promise<OfflineStoreLocationSEOArea[]> => {
  const url = `${config.API_URL_GOBLIN}/shop-assistant/offline-store-seo-areas/`;
  const res = await superagent.get(url);
  return res.body;
};
