import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  option: {
    ...cStyles.phxxl,
    paddingVertical: 20,
    ...cStyles.bwbm,
    borderBottomColor: colors.C_BLACK_25,
  },

  optionTextContainer: {
    ...cStyles.mrm,
    ...cStyles.mbxxl,
    ...cStyles.flex1,
  },

  addressPart: {
    ...cStyles.mtm,
  },

  actionIconContainer: {
    marginTop: -44,
    marginBottom: 12,
    marginRight: 12,
    alignSelf: 'flex-end',
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleLeft,
  },

  actionIcon: {
    marginHorizontal: 6,
    backgroundColor: colors.C_TRANSPARENT,
    flexGrow: 0,
    flexShrink: 0,
  },

  addAddressButton: {
    ...cStyles.mrs,
  },

  modalBackground: {
    ...cStyles.paxxl,
    ...cStyles.flexMiddle,
    margin: 0,
  },

  modalContainer: {
    ...cStyles.width100,
    maxWidth: 600,
    ...cStyles.brs,
    ...cStyles.phxxl,
    ...cStyles.pvxl,
    ...cStyles.bgw,
  },

  modalText: {
    ...cStyles.mtxl,
    ...cStyles.mbxxl,
  },

  modalButtons: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddleRight,
  },

  snackBarContainer: {
    ...cStyles.fixedBottom,
    ...cStyles.width100,
    ...cStyles.pageMaxWidths,
    ...cStyles.pan,
  },

  snackBar: {
    ...cStyles.width100,
    ...cStyles.phxxl,
    backgroundColor: colors.C_TERTIARY_GREEN_1,
    ...cStyles.bwtm,
    borderTopColor: colors.C_TERTIARY_GREEN_2,
    ...cStyles.shadown,
  },

  snackBarText: {
    ...cStyles.mlxl,
    ...tStyles.body3,
    color: colors.C_BLACK_60,
  },
});
