import keyMirror from 'keymirror';
import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';
import config from 'config';
import { fetchSearchResults } from 'app-libs/redux_modules/helper_modules/search/algoliaWrapper';
import { AT as CustomerAT } from 'app-libs/redux_modules/entity_modules/customer';

import { getSelectedCity } from 'app-libs/redux_modules/flow_modules/selectors/area';

import {
  getMainShippingAddress,
  getCustomerMainShippingAddress,
  getCityFromShippingAddress,
} from 'app-libs/redux_modules/entity_modules/selectors/customer';

export const { ALGOLIA_AREA_INDEX_NAME } = config;

export const AT = keyMirror({
  INITIALIZE_LOCATION_INFO: null,
  GET_PROVINCES: null,
  GET_PROVINCES_SUCCESS: null,
  GET_PROVINCES_FAIL: null,

  GET_CITIES: null,
  GET_CITIES_SUCCESS: null,
  GET_CITIES_FAIL: null,

  GET_CITY_OBJECTS: null,
  GET_CITY_OBJECTS_SUCCESS: null,
  GET_CITY_OBJECTS_FAIL: null,

  GET_DISTRICTS: null,
  GET_DISTRICTS_SUCCESS: null,
  GET_DISTRICTS_FAIL: null,
  UPDATE_SELECTED_DISTRICT: null,
  UPDATE_SELECTED_CITY_SHIPPING_COVERAGE: null,

  UPDATE_SELECTED_CITY_OBJECT: null,

  LOAD_DNB_SUPPORTED_CITIES: null,
  LOAD_DNB_SUPPORTED_CITIES_SUCCESS: null,
  LOAD_DNB_SUPPORTED_CITIES_FAIL: null,

  GET_SHIPPING_AREA: null,
  GET_SHIPPING_AREA_SUCCESS: null,
  GET_SHIPPING_AREA_FAIL: null,
});

const defaultMaxValuesPerFacet = 100000;
const defaultParameters = {
  maxValuesPerFacet: defaultMaxValuesPerFacet,
  facets: [],
  attributesToRetrieve: ['district'],
  attributesToHighlight: [],
};

export const initialState = {
  provinces: {
    asyncState: initialAsyncState,
    data: [],
  },
  cities: {
    asyncState: initialAsyncState,
    data: [],
    objects: [],
  },
  districts: {
    asyncState: initialAsyncState,
    data: [],
  },
  selectedArea: {
    objectID: '',
  },
  selectedCityShippingCoverage: undefined,
  selectedCityObject: undefined,
  shippingClassNames: [],
  supportedDnBCities: {
    asyncState: initialAsyncState,
    data: [],
  },
};

// reducer
export default function areaReducer(mutableState = initialState, action = {}) {
  let state = mutableState;

  switch (action.type) {
    case AT.INITIALIZE_LOCATION_INFO:
      state = Object.assign({}, state, {
        selectedArea: Object.assign(
          {},
          state.selectedArea,
          action.payload.selectedArea,
        ),
      });
      break;
    case AT.GET_PROVINCES:
    case AT.GET_PROVINCES_SUCCESS:
    case AT.GET_PROVINCES_FAIL:
      state = Object.assign({}, state, {
        provinces: provinceReducer(state.provinces, action),
      });
      break;
    case AT.GET_CITIES:
    case AT.GET_CITIES_SUCCESS:
    case AT.GET_CITIES_FAIL:
    case AT.GET_CITY_OBJECTS:
    case AT.GET_CITY_OBJECTS_SUCCESS:
    case AT.GET_CITY_OBJECTS_FAIL:
      state = Object.assign({}, state, {
        cities: cityReducer(state.cities, action),
      });
      break;
    case AT.GET_DISTRICTS:
    case AT.GET_DISTRICTS_SUCCESS:
    case AT.GET_DISTRICTS_FAIL:
      state = Object.assign({}, state, {
        districts: districtReducer(state.districts, action),
      });
      break;
    case AT.UPDATE_SELECTED_DISTRICT:
      state = Object.assign({}, state, {
        selectedArea: state.districts.data.find(
          (district) => district.objectID === action.payload.districtId,
        ),
      });
      break;
    case AT.UPDATE_SELECTED_CITY_SHIPPING_COVERAGE:
      state = Object.assign({}, state, {
        selectedCityShippingCoverage: action.payload,
      });
      break;
    case AT.UPDATE_SELECTED_CITY_OBJECT:
      state = Object.assign({}, state, {
        selectedCityObject: action.payload,
      });
      break;
    case CustomerAT.LOAD_SHIPPING_ADDRESS_SUCCESS: {
      const customerShippingAddress = getMainShippingAddress(
        action.result.results,
      );
      const customerCity = getCityFromShippingAddress(customerShippingAddress);
      state = Object.assign({}, state, {
        selectedCityObject: getCityObjectByCity(
          state.cities.objects,
          customerCity,
        ),
      });
      break;
    }
    case AT.LOAD_DNB_SUPPORTED_CITIES:
    case AT.LOAD_DNB_SUPPORTED_CITIES_SUCCESS:
    case AT.LOAD_DNB_SUPPORTED_CITIES_FAIL:
      state = Object.assign({}, state, {
        supportedDnBCities: supportedDnBCitiesReducer(
          state.supportedDnBCities,
          action,
        ),
      });
      break;
  }

  return state;
}

function getCityObjectByCity(cityObjects, city) {
  if (!cityObjects) return undefined;
  return cityObjects.find((cityObject) => cityObject.city === city);
}

function provinceReducer(mutableState = initialState.provinces, action = {}) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_PROVINCES:
      break;
    case AT.GET_PROVINCES_SUCCESS:
      state = Object.assign({}, state, {
        data: action.result.content.hits,
      });
      break;
    case AT.GET_PROVINCES_FAIL:
      break;
  }

  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_PROVINCES_'),
  });
}

function supportedDnBCitiesReducer(
  mutableState = initialState.supportedDnBAreas,
  action = {},
) {
  let state = mutableState;
  switch (action.type) {
    case AT.LOAD_DNB_SUPPORTED_CITIES:
      break;
    case AT.LOAD_DNB_SUPPORTED_CITIES_SUCCESS:
      state = Object.assign({}, state, {
        data: action.result,
      });
      break;
    case AT.LOAD_DNB_SUPPORTED_CITIES_FAIL:
      break;
  }

  return Object.assign({}, state, {
    asyncState: asyncStateReducer(
      state.asyncState,
      action,
      '_SUPPORTED_AREAS_',
    ),
  });
}

function cityReducer(mutableState = initialState.cities, action = {}) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_CITIES:
      break;
    case AT.GET_CITIES_SUCCESS:
      state = Object.assign({}, state, {
        data: action.result,
      });
      break;
    case AT.GET_CITIES_FAIL:
      break;
    case AT.GET_CITY_OBJECTS_SUCCESS:
      state = Object.assign({}, state, {
        objects: action.result.content.hits,
      });
      break;
  }

  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_CITIES_'),
  });
}

function districtReducer(mutableState = initialState.districts, action = {}) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_DISTRICTS:
      break;
    case AT.GET_DISTRICTS_SUCCESS:
      state = Object.assign({}, state, {
        data: action.result,
      });
      break;
    case AT.GET_DISTRICTS_FAIL:
      break;
  }

  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_DISTRICTS_'),
  });
}

export function loadProvinces(additionalParameters, callback) {
  const searchParameters = Object.assign(
    {},
    defaultParameters,
    {
      hitsPerPage: 0, //  default constant for load district algolia so we can do search faster
      facets: ['hareas.lvl0'],
    },
    additionalParameters,
  );

  return {
    types: [AT.GET_PROVINCES, AT.GET_PROVINCES_SUCCESS, AT.GET_PROVINCES_FAIL],
    promise: () =>
      fetchSearchResults(searchParameters, ALGOLIA_AREA_INDEX_NAME),
    // usually we don't need this, but after changing node version and redux batch dispatcher, but somehow "then" doesn't work
    options: {
      transformer: (result) => {
        const hareas0 = result.content.facets.find(
          (facet) => facet.name === 'hareas.lvl0',
        );
        const provincesData = hareas0 ? Object.keys(hareas0.data) : [];

        typeof callback === 'function' && callback(provincesData);
        return provincesData;
      },
    },
  };
}

export function loadCitiesFilterByShippingClassNames(
  shippingClassNames,
  callback,
) {
  const additionalParameters = {
    facets: ['hareas.lvl0', 'hareas.lvl1', 'shippingPricingCoverages'],
    facetsRefinements: {
      'shippingPricingCoverages.shippingClassName': [...shippingClassNames],
    },
  };
  return loadCities(additionalParameters, callback);
}

export function loadCities(additionalParameters, callback) {
  const searchParameters = Object.assign(
    {},
    defaultParameters,
    {
      hitsPerPage: 999, // load district as much as we can (we assume cities of a province can't be more than 1000)
      facets: ['hareas.lvl0', 'hareas.lvl1'],
    },
    additionalParameters,
  );

  return {
    types: [AT.GET_CITIES, AT.GET_CITIES_SUCCESS, AT.GET_CITIES_FAIL],
    promise: () =>
      fetchSearchResults(searchParameters, ALGOLIA_AREA_INDEX_NAME),
    // usually we don't need this, but after changing node version and redux batch dispatcher, but somehow "then" doesn't work
    options: {
      transformer: (result) => {
        const hareas1 = result.content.facets.find(
          (facet) => facet.name === 'hareas.lvl1',
        );
        const citiesData = hareas1 ? Object.keys(hareas1.data) : [];

        if (typeof callback === 'function') {
          callback(citiesData);
        }
        return citiesData;
      },
    },
  };
}

export function loadCityObjects(additionalParameters, callback) {
  const searchParameters = Object.assign(
    {},
    defaultParameters,
    {
      hitsPerPage: 999, // load district as much as we can (we assume cities of a province can't be more than 1000)
      attributesToRetrieve: [
        'city',
        'province',
        'shippingPricingCoverages',
        'price',
        'island',
        'minDeliveryDay',
        'maxDeliveryDay',
        'isPromoFreeShipping',
      ],
      facets: [
        'hareas.lvl0',
        'hareas.lvl1',
        'shippingPricingCoverages.shippingClassName',
      ],
      distinct: true,
    },
    additionalParameters,
  );

  return {
    types: [
      AT.GET_CITY_OBJECTS,
      AT.GET_CITY_OBJECTS_SUCCESS,
      AT.GET_CITY_OBJECTS_FAIL,
    ],
    promise: () =>
      fetchSearchResults(searchParameters, ALGOLIA_AREA_INDEX_NAME),
  };
}

export function loadCityObjectsAndUpdateSelectedCityObject(
  additionalParameters,
  callback,
) {
  return (dispatch, getState) => {
    dispatch(loadCityObjects(additionalParameters, callback)).then((result) => {
      const state = getState();

      // update selected city object by selected city coverage
      const selectedCity = getSelectedCity(state);
      const cityObject = getCityObjectByCity(
        result.result.content.hits,
        selectedCity,
      );
      if (cityObject) {
        dispatch(updateSelectedCityObject(cityObject));
      } else {
        // update selected city object by shipping address
        const customerMainShippingAddress = getCustomerMainShippingAddress(
          state,
        );
        const customerCity = getCityFromShippingAddress(
          customerMainShippingAddress,
        );
        if (customerCity) {
          const customerCityObject = getCityObjectByCity(
            result.result.content.hits,
            customerCity,
          );
          dispatch(updateSelectedCityObject(customerCityObject));
        }
      }
    });
  };
}

export function loadDistricts(cityPath, additionalParameters, callback) {
  const additionalParametersForShipping = {
    attributesToRetrieve: [
      'district',
      'shippingPricingCoverages',
      'price',
      'city',
      'province',
      'island',
      'minDeliveryDay',
      'maxDeliveryDay',
      'isPromoFreeShipping',
    ],
  };
  const searchParameters = Object.assign(
    {},
    defaultParameters,
    {
      hitsPerPage: 999, // load district as much as we can (we assume district of a city can't be more than 1000)
      facets: ['hareas.lvl1', 'hareas.lvl2'],
    },
    cityPath && { facetFilters: [`hareas.lvl1:${cityPath}`] },
    additionalParameters,
    additionalParametersForShipping,
  );
  return {
    types: [AT.GET_DISTRICTS, AT.GET_DISTRICTS_SUCCESS, AT.GET_DISTRICTS_FAIL],
    promise: () =>
      fetchSearchResults(searchParameters, ALGOLIA_AREA_INDEX_NAME),
    // usually we don't need this, but after changing node version and redux batch dispatcher, but somehow "then" doesn't work
    options: {
      transformer: (result) => {
        const districtsData = result.content.hits;
        typeof callback === 'function' && callback(districtsData);
        return districtsData;
      },
    },
  };
}

export function chooseDistrict(districtId) {
  return {
    type: AT.UPDATE_SELECTED_DISTRICT,
    payload: { districtId },
  };
}

export function loadInitialState(selectedArea) {
  return {
    type: AT.INITIALIZE_LOCATION_INFO,
    payload: { selectedArea },
  };
}

export function updateSelectedCityShippingCoverage(city) {
  return {
    type: AT.UPDATE_SELECTED_CITY_SHIPPING_COVERAGE,
    payload: city,
  };
}

export function updateSelectedCityObject(cityObject) {
  return {
    type: AT.UPDATE_SELECTED_CITY_OBJECT,
    payload: cityObject,
  };
}

export function loadSupportedDnBCities() {
  return {
    types: [
      AT.LOAD_DNB_SUPPORTED_CITIES,
      AT.LOAD_DNB_SUPPORTED_CITIES_SUCCESS,
      AT.LOAD_DNB_SUPPORTED_CITIES_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_CHITAURI}/project-active-cities/`),
  };
}

export function getShippingAreaByViperId(id) {
  return {
    types: [
      AT.GET_SHIPPING_AREA,
      AT.GET_SHIPPING_AREA_SUCCESS,
      AT.GET_SHIPPING_AREA_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_GOBLIN}/shipping/shipping-area/${id}`),
  };
}
