import { K_DEFAULT_AREA } from 'app-libs/constants/locationConstants';

export const getCurrentLocationFromLocalStorage = () => {
  return isCurrentLocationSet()
    ? JSON.parse(localStorage.getItem('currentLocation') || '{}')
    : K_DEFAULT_AREA;
};

export const isCurrentLocationSet = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return __CLIENT__ && !!localStorage.getItem('currentLocation');
};
