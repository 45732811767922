import config from 'config';

/** Icon and Images */

export const K_DEKORUMA_APP_ICON = `${config.API_URL_MEDIA_CDN}/homepage/logo/v2/app-icon.png?auto=webp`;
export const K_DEKORUMA_LOGO_WITHOUT_TEXT = `${config.API_URL_MEDIA_CDN}/homepage/logo/v2/dekoruma/dekoruma_black.png?auto=webp`;
export const K_DEKORUMA_LOGO = `${config.API_URL_MEDIA_CDN}/logo/dekoruma-retail-black-2.png?auto=webp`;
export const K_DEKORUMA_LOGO_WHITE = `${config.API_URL_MEDIA_CDN}/logo/dekoruma-retail-white-2.png?auto=webp`;
export const K_INTERIOR_LOGO_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/logo/dekoruma-interior-black.png?auto=webp`;
export const K_INTERIOR_LOGO_WHITE = `${config.API_URL_MEDIA_CDN}/logo/dekoruma-interior-white.png?auto=webp`;

export const K_DEKORUMA_LOGO_V3_WHITE = `${config.API_URL_MEDIA_CDN}/homepage/logo/v2/dekoruma/dekoruma_white.png`;
export const K_INTERIOR_LOGO_V3_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/homepage/logo/v3/dekoruma_interior_black.svg?auto=webp`;
export const K_INTERIOR_LOGO_V3_WHITE_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/homepage/logo/v3/dekoruma_interior_white.svg?auto=webp`;

/** Product Card Sticker */
export const K_IMAGE_BUNDLE_OFFER_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/kombo-hemat.png?auto=webp`;
export const K_IMAGE_POPULAR_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/popular.png?auto=webp`;
export const K_IMAGE_BEST_PRICE_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/best-price.png?auto=webp`;
export const K_IMAGE_SALE_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/sale.png?auto=webp`;
export const K_IMAGE_NEW_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/new-arrival.png?auto=webp`;
export const K_IMAGE_SPACE_SAVING_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/space-saving.png?auto=webp`;
export const K_IMAGE_BRANDING_PUSHED_URL = `${config.API_URL_MEDIA_CDN}/assets/icons/php.png?auto=webp`;

export const K_IMAGE_PACKAGE_UNAVAILABLE = `${config.API_URL_MEDIA_CDN}/assets/icons/package-unavailable.png?auto=webp`;
export const K_IMAGE_FREE_SHIPPING = `${config.API_URL_MEDIA_CDN}/assets/icons/free-shipping.png?auto=webp`;

export const K_IMAGE_COUPON = `${config.API_URL_MEDIA_CDN}/assets/icons/coupon.png?auto=webp`;

export const K_LOGO_GOOGLE_PLAY = `${config.API_URL_MEDIA_CDN}/homepage/logo/google_play.png?auto=webp`;
export const K_LOGO_APPLE_STORE = `${config.API_URL_MEDIA_CDN}/homepage/logo/apple_store.png?auto=webp`;
