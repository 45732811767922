import React, { memo, useCallback } from 'react';

import { Colors, View, Text, StyleSheet } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';
import cStyles from '@dkrm/general-libs/theme/styles';

import { trackClickMarketplaceCategory } from 'app-libs/trackers';

interface ProductListCategoryCardProps {
  label: string;
  title: string;
  linkUrl: string;
}

const ProductListCategoryCard: React.FC<ProductListCategoryCardProps> = memo(
  ({ label, title, linkUrl }) => {
    const handleCategoryPressed = useCallback(() => {
      trackClickMarketplaceCategory(linkUrl, label, title);
    }, [label, title, linkUrl]);

    return (
      <View style={s.productCategory}>
        <Button
          hoverableViewStyle={s.button}
          style={cStyles.flex1}
          onPress={handleCategoryPressed}
          to={linkUrl}
        >
          {() => (
            <View style={s.buttonContainer}>
              <View style={s.titleContainer}>
                <Text theme="body3" style={s.title}>
                  {label}
                </Text>
              </View>
            </View>
          )}
        </Button>
      </View>
    );
  },
);

const s = StyleSheet.create({
  productCategory: {
    ...cStyles.flex1,
    ...cStyles.mas,
    ...cStyles.brs,
    backgroundColor: Colors.C_BLACK_5,
    justifyContent: 'center',
    minHeight: 52,
  },
  button: {
    ...cStyles.flex1,
    ...cStyles.height100,
  },
  buttonContainer: {
    ...cStyles.pvn,
    ...cStyles.phxl,
    ...cStyles.brs,
    ...cStyles.flex1,
    alignItems: 'center',
  },
  titleContainer: {
    ...cStyles.flex1,
    ...cStyles.mts,
    ...cStyles.mbs,
    ...cStyles.flexMiddle,
  },
  title: {
    textAlign: 'center',
  },
});

export default ProductListCategoryCard;
