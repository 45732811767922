import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { load as loadAuth } from 'app-libs/redux_modules/auth';
import { MarketplaceProfile, User } from 'app-libs/redux_modules/auth/types';
import {
  getAuthMarketplaceProfile,
  getAuthUser,
  isAuthLoaded as getIsAuthLoaded,
  isAuthLoading as getIsAuthLoading,
  isForgettingPassword as getIsForgettingPassword,
  isLoggedIn as getIsLoggedIn,
  getIsShopAssistant,
} from 'app-libs/redux_modules/selectors/auth';

export const useAuth = () => {
  const user: User = useSelector(getAuthUser);
  const marketplaceProfile: MarketplaceProfile = useSelector(
    getAuthMarketplaceProfile,
  );
  const isAuthLoaded: boolean = useSelector(getIsAuthLoaded);
  const isLoggedIn: boolean = useSelector(getIsLoggedIn);
  const isShopAssistant: boolean = useSelector(getIsShopAssistant);
  const isForgettingPassword: boolean = useSelector(getIsForgettingPassword);

  return {
    user,
    isAuthLoaded,
    isForgettingPassword,
    isLoggedIn,
    isShopAssistant,
    marketplaceProfile,
  };
};

export const useLoadAuthEffect = () => {
  const isAuthLoaded: boolean = useSelector(getIsAuthLoaded);
  const isAuthLoading: boolean = useSelector(getIsAuthLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthLoaded && !isAuthLoading) {
      dispatch(loadAuth());
    }
  }, [isAuthLoading, isAuthLoaded, dispatch]);
};
