import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { HorizontalDivider } from '@dkrm/ui-kit-basic';
import cStyles, {
  stylePropTypes,
  K_MOBILE_MAX_WIDTH,
} from '@dkrm/general-libs/theme/styles';

import SectionStyle from 'app-libs/components/Product/ProductDetail/Shared/Sections/style';

@connect((state) => ({
  width: Math.min(K_MOBILE_MAX_WIDTH, state.dimension.width),
}))
export default class Separator extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    style: stylePropTypes,
    align: PropTypes.oneOf(['left', 'center', 'right', 'full']),
    wide: PropTypes.bool,
  };

  static defaultProps = {
    width: K_MOBILE_MAX_WIDTH,
    style: null,
    align: 'center',
    wide: false,
  };

  render() {
    const { style, align, width, wide } = this.props;
    const positionStyle = positionHelper(align, width);
    const separatorStyle = wide
      ? SectionStyle.wideSeparator
      : SectionStyle.separator;

    return <HorizontalDivider style={[separatorStyle, positionStyle, style]} />;
  }
}

const positionHelper = (align, width) => {
  let style = {};

  switch (align) {
    case 'full':
      style = {
        width,
      };
      break;

    case 'right':
      style = {
        ...cStyles.plxxl,
        width: width - 24,
      };
      break;

    case 'left':
      style = {
        width: width - 24,
      };
      break;

    default:
      break;
  }

  return style;
};
