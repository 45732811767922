import superagent from 'superagent';

const K_GET_CURRENT_MOBILE_VERSION_API_ENDPOINT =
  'https://us-central1-f1-version.cloudfunctions.net/mobile-version';
const K_LOCAL_STORAGE_CURRENT_VERSION_HASH_KEY = 'currentVersionHash';

export const getCurrentVersionHashFromLocalStorage = () => {
  return localStorage.getItem(K_LOCAL_STORAGE_CURRENT_VERSION_HASH_KEY);
};

export const setCurrentVersionHashToLocalStorage = (versionHash: string) => {
  localStorage.setItem(K_LOCAL_STORAGE_CURRENT_VERSION_HASH_KEY, versionHash);
};

export const getCurrentVersionHashFromCloudFunction = async () => {
  const versionHash = await superagent.get(
    K_GET_CURRENT_MOBILE_VERSION_API_ENDPOINT,
  );
  return versionHash?.text || '';
};
