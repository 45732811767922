import React from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { useMediaQuery } from 'entities/footer/hooks';

import { FooterSectionTitle } from '../FooterSection/FooterSectionTitle';
import {
  K_HEIGHT_SMALL_ICON,
  K_TABLET_FOOTER_BREAKPOINT,
  K_WIDTH_SMALL_ICON,
} from '../constants';

const K_MEDIA_PARTNERS = [
  'forbes',
  'techinasia',
  'kompascom',
  'idea',
  'casa',
  'jpnncom',
  'cnbc',
  'liputan6',
  'bisniscom',
  'kumparan',
  'suaracom',
  'fimela',
];

export const MediaPartnerSection: React.FC = () => {
  const isMobile = useMediaQuery(K_TABLET_FOOTER_BREAKPOINT);
  return (
    <>
      <FooterSectionTitle title="Media Partner & Publikasi" />
      <View style={isMobile ? s.mContainer : s.container}>
        {K_MEDIA_PARTNERS.map((mediaPartner) => (
          <View style={s.icon} key={mediaPartner}>
            <SmartImage
              source={`${config.API_URL_MEDIA_CDN}/homepage/footer/logo/v2/${mediaPartner}.png?auto=webp`}
              width={K_WIDTH_SMALL_ICON}
              height={K_HEIGHT_SMALL_ICON}
              additionalQuery={{
                fit: 'bounds',
              }}
            />
          </View>
        ))}
      </View>
    </>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.mtm,
    minWidth: 300,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 96px)',
    gridColumnGap: '12px',
    gridRowGap: '12px',
  },
  mContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 72px)',
    gridColumnGap: '12px',
    gridRowGap: '12px',
  },
  icon: {
    ...cStyles.flexMiddle,
    ...cStyles.phs,
    ...cStyles.brs,
    ...cStyles.pvxs,
    borderWidth: 1,
    borderColor: Colors.C_BLACK_15,
  },
});

export default MediaPartnerSection;
