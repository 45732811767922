import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

import {
  K_DEKORUMA_APP_ANDROID_PLAY_STORE_URL,
  K_DEKORUMA_APP_IOS_APP_STORE_URL,
} from 'constants/nativeAppConstants';

export const getAppVersion = () => {
  return window?.__APP_VERSION__ || '';
};

export const getAppStoreOrPlayStoreUrl = () => {
  if (getPlatform() === 'android') return K_DEKORUMA_APP_ANDROID_PLAY_STORE_URL;
  return K_DEKORUMA_APP_IOS_APP_STORE_URL;
};

export const compareVersions = (version1: string, version2: string) => {
  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);

  const maxLength = Math.max(v1Parts.length, v2Parts.length);

  for (let i = 0; i < maxLength; i += 1) {
    const v1Part = v1Parts[i] || 0;
    const v2Part = v2Parts[i] || 0;

    if (v1Part !== v2Part) {
      return v1Part > v2Part;
    }
  }

  return false;
};
