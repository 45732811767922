/* eslint-disable camelcase, @typescript-eslint/camelcase */
import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';

import wv from 'app-libs/libs/webView';

interface FBAppLogEventParams {
  eventName: string;
  valueToSum?: number;
  eventData: Record<string, string>;
}

const fbAppWrapper = {
  logEvent: ({ eventName, valueToSum = 0, eventData }: FBAppLogEventParams) => {
    if (isWebView()) {
      wv.actionTrackFBEvent({ eventName, valueToSum, eventData });
    }
  },
};

export default fbAppWrapper;
