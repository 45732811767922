import React, { PureComponent } from 'react';
import RelativePortal from 'react-relative-portal';
import './index.scss';
import MediaQuery from 'react-native-web-responsive';

export default class ModalContainer extends PureComponent {
  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return (
      <RelativePortal component="div">
        <MediaQuery minWidth={576}>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 'calc(100% + 8px)',
              zIndex: 48,
              overflow: 'visible',
              width: 320,
              border: '1px solid #ccc',
            }}
          >
            {children}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={575}>{children}</MediaQuery>
      </RelativePortal>
    );
  }
}
