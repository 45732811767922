import React from 'react';

import { TabBar } from '@dkrm/general-libs/TabView';
import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, TabView, View } from '@dkrm/ui-kit-basic';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

import { useParams, useQueries } from 'app-libs/hook_modules/router';

import { useMediaQuery } from 'entities/footer/hooks';
import { useQueryLoadSEOAreas } from 'entities/seo/utils';

import { K_CATEGORY_FURNITURE } from 'constants/productConstants';

import { ViewStyle } from 'types/styles';

import {
  K_MOBILE_FOOTER_BREAKPOINT,
  K_TOP_CATEGORY_LABELS,
  K_TOP_CATEGORY_SECTION_CATEGORY_AREA,
  K_TOP_CATEGORY_SECTION_ITEMS_MOBILE,
  K_TOP_CATEGORY_SECTION_ITEMS_TABLET,
  K_TOP_CATEGORY_SECTION_TITLE_CATEGORY_AREA_MOBILE,
  K_TOP_CATEGORY_SECTION_TITLE_CATEGORY_AREA_TABLET,
} from '../constants';
import { TopCategorySectionList } from './TopCategorySectionList';
import { generateTopCategoryAreaFooterItems } from './utils';

interface TopCategorySectionProps {
  style?: ViewStyle;
}

export const TopCategorySection: React.FC<TopCategorySectionProps> = React.memo(
  ({ style }) => {
    const isMobile = useMediaQuery(K_MOBILE_FOOTER_BREAKPOINT);
    const { areas } = useQueryLoadSEOAreas();
    const { lvl0 = K_CATEGORY_FURNITURE } = useParams<Record<string, string>>();

    const footerItems = generateTopCategoryAreaFooterItems(areas, lvl0);

    const TOP_CATEGORY_ITEMS = isMobile
      ? K_TOP_CATEGORY_SECTION_ITEMS_MOBILE
      : K_TOP_CATEGORY_SECTION_ITEMS_TABLET;

    const routes = K_TOP_CATEGORY_LABELS.map((type) => ({
      key: type,
      title: TOP_CATEGORY_ITEMS[type].title,
      render: () => (
        <TopCategorySectionList footerItems={TOP_CATEGORY_ITEMS[type].items} />
      ),
    }));

    // dynamic ifems for Top Kategori Area
    if (footerItems?.length > 0) {
      routes.push({
        key: K_TOP_CATEGORY_SECTION_CATEGORY_AREA,
        title: isMobile
          ? K_TOP_CATEGORY_SECTION_TITLE_CATEGORY_AREA_MOBILE
          : K_TOP_CATEGORY_SECTION_TITLE_CATEGORY_AREA_TABLET,
        render: () => <TopCategorySectionList footerItems={footerItems} />,
      });
    }

    const renderTabBar = (props: typeof routes) => (
      <TabBar
        {...props}
        getLabelText={({ route }: any) => route.title}
        labelStyle={s.tabLabel}
        activeLabelStyle={s.activeTabLabel}
        style={[cStyles.mbxl, cStyles.shadowm]}
        totalTabInScreen={2.5}
        scrollEnabled
      />
    );

    const { initialTab } = useQueries();
    const initialRouteIndex = initialTab
      ? routes.findIndex(
          (route) => route.key.toUpperCase() === initialTab.toUpperCase(),
        )
      : 0;
    const initialIndex = initialRouteIndex !== -1 ? initialRouteIndex : 0;

    return (
      <View style={[s.container, isMobile && cStyles.phxl, style]}>
        <TabView
          index={initialIndex}
          routes={routes}
          lazy
          shouldCacheRoutes={false}
          renderHeader={renderTabBar}
          style={cStyles.width100}
        />
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.flexLeftMiddle,
    ...cStyles.paxxl,
    ...cStyles.width100,
  },

  topContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    ...cStyles.flexDirRow,
    ...cStyles.width100,
    justifyContent: 'space-between',
  },

  mTopContent: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  tabLabel: {
    ...tStyles.body3,
    color: Colors.C_BLACK_40,
  },

  activeTabLabel: {
    ...tStyles.h5,
    color: Colors.C_SECONDARY_TOSCA_4,
  },
});

export default TopCategorySection;
