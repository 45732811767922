import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import moment from 'moment';

import { PAYMENT_METHODS } from 'app-libs/redux_modules/entity_modules/basket';
import {
  getProductBackorderFulfillmentTime,
  getProductFulfillmentTime,
  getProductHeight,
  getProductLength,
  getProductWidth,
} from 'app-libs/redux_modules/entity_modules/selectors/product';

import {
  K_BULK_CALCULATED_SHIPPING_CLASSES,
  K_JABODETABEK,
  K_MAX_SUBSIZED_SHIPPING_AMOUNT,
  K_MINIMUM_PRICE_FOR_FREE_SHIPPING_MATERIAL,
  K_MIN_BASKET_AMOUNT_ELIGIBLE_FOR_SUBSIDIZED_SHIPPING,
  K_PAYMENT_MAX_PROCESS_DAYS,
  K_PAYMENT_MIN_PROCESS_DAYS,
  K_SHIPPING_CLASS_MATERIAL,
  K_SUBSIDIZED_SHIPPING_ISLAND,
} from 'constants/shippingConstants';

import {
  getStockRecordBackorderFulfillmentTime,
  getStockRecordFulfillmentTime,
  getWeightAmount,
} from './productHelperV2';
import { isFreeShipping } from './shippingHelper';

// const K_MAX_WEIGHT_BASED_SHIPPING_COST = 1500000;
const K_DEKOHUB_GROUP_FREE_SHIPPING = 'Dekohub Free Shipping';
const K_FULLY_PAID_SHIPPING_WEIGHT = 30;
export const K_MINIMUM_PURCHASE_FOR_FREE_SHIPPING = 70000;

/**
 * By default it will return area's allowCOD,
 * It will loop through the shipping class given in parameter and
 * if one of the them is not allowing COD, it will return false
 *
 * @param  {Object}  area            {allowCOD: true, shippingPricingCoverages: [{shippingClassName: xxx, allowCOD: false, price: xxx}]}
 * @param  {Array}   shippingClassNames ['Sofa', 'Dining Table']
 *
 * @example:
 *   data:
      Semarang --> allow cod
      Semarang, sofa --> allow cod
      Semarang, dining table --> not allow cod

    result:
      sofa --> true
      sofa + dinning table --> false
 */
export function isCODAllowedByArea(area, shippingClassNames) {
  let isAllowed = area.allowCOD || false;

  if (area.shippingPricingCoverages) {
    shippingClassNames.forEach((shippingClass) => {
      const shippingCoveragePerClass = area.shippingPricingCoverages.find(
        (coverage) => coverage.shippingClassName === shippingClass,
      );
      if (shippingCoveragePerClass) {
        isAllowed = isAllowed && shippingCoveragePerClass.codAllowed;
      }
    });
  }
  return isAllowed;
}

export function calculateShippingTotalDetail(
  area,
  basketLines,
  basketSubtotal,
  owner,
) {
  const classBasedBasketLines = basketLines.filter(
    (line) => !!line.product.shippingClassName && !line.isPickupInStore,
  );

  const weightBasedBasketLines = basketLines.filter(
    (line) => !line.product.shippingClassName && !line.isPickupInStore,
  );

  const classBasedShippingTotal = getClassBasedShippingTotal(
    area,
    classBasedBasketLines,
    basketSubtotal,
    owner,
  );
  const classBasedShippingTotalBeforeRebate =
    getClassBasedShippingTotalBeforeRebate(
      area,
      classBasedBasketLines,
      basketSubtotal,
      owner,
    );
  const weightBasedShippingTotal = getWeightBasedShippingTotal(
    area,
    weightBasedBasketLines,
    basketSubtotal,
  );

  const total = classBasedShippingTotal + weightBasedShippingTotal;

  const subsidizedShippingFee = getSubsidizedShippingFee(
    area,
    basketSubtotal,
    total,
  );

  const shippingTotalDetailed = {
    classBased: classBasedShippingTotal,
    weightBased: weightBasedShippingTotal,
    total: total - subsidizedShippingFee,
    totalBeforeRebate:
      classBasedShippingTotalBeforeRebate + weightBasedShippingTotal,
  };

  return shippingTotalDetailed;
}

export function getSubsidizedShippingFee(
  area,
  basketSubtotal,
  shippingFeeTotal,
) {
  if (
    area.island === K_SUBSIDIZED_SHIPPING_ISLAND &&
    basketSubtotal >= K_MIN_BASKET_AMOUNT_ELIGIBLE_FOR_SUBSIDIZED_SHIPPING
  ) {
    return Math.min(shippingFeeTotal, K_MAX_SUBSIZED_SHIPPING_AMOUNT);
  }
  return 0;
}

export function getShippingCoveragesFromAreaSearchResult(
  areaSearchResult,
  shippingClassNames,
  availability,
) {
  const shippingCoverages = [];
  const cities = [];

  areaSearchResult.forEach((area) => {
    if (!includes(cities, area.city)) {
      const compoundShippingCoverage = {
        city: area.city,
        isPromoFreeShipping: area.isPromoFreeShipping,
        weightVolumetricPrice: area.price,
        minDeliveryDay: area.minDeliveryDay,
        maxDeliveryDay: area.maxDeliveryDay,
        island: area.island,
        codAllowed: true,
        price: 0,
      };
      if (!isEmpty(availability)) {
        compoundShippingCoverage.estArrivalDateString =
          getEstimatedArrivalDateString(availability, area);
      }
      shippingClassNames.forEach((shippingClassName) => {
        const shippingCoverage = area.shippingPricingCoverages.find(
          (coverage) => coverage.shippingClassName === shippingClassName,
        );
        compoundShippingCoverage.codAllowed =
          compoundShippingCoverage.codAllowed && shippingCoverage.codAllowed;
        compoundShippingCoverage.price += shippingCoverage.price;
        compoundShippingCoverage.estArrivalDateString =
          compoundShippingCoverage.estArrivalDateString;
      });
      shippingCoverages.push(compoundShippingCoverage);
      cities.push(area.city);
    }
  });

  return shippingCoverages;
}

export function getDurationByPaymentType(paymentType) {
  let duration = 0;
  // payment_type related
  if (paymentType) {
    switch (paymentType) {
      case PAYMENT_METHODS.BANK_TRANSFER:
        duration += 1; // For manual verification. This can be removed for automated verification
        break;
      case PAYMENT_METHODS.VIRTUAL_ACCOUNT:
        break;
      case PAYMENT_METHODS.CASH_ON_DELIVERY:
        break;
      case PAYMENT_METHODS.CREDIT_CARD:
        break;
      default:
        break;
    }
  }
  return duration;
}

export const isJabodetabek = (city) =>
  city && K_JABODETABEK.find((el) => city.includes(el));

export const isJava = (area) => area && area.island === 'Jawa';

export function getProductRangeArrivalTime(
  product,
  area,
  shippingCharge = null,
  paymentType = null,
) {
  let { minFulfillmentTime: minDuration, maxFulfillmentTime: maxDuration } =
    getProductFulfillmentTime(product);
  minDuration += getProductBackorderFulfillmentTime(product);
  minDuration += getDurationByPaymentType(paymentType);
  maxDuration += getProductBackorderFulfillmentTime(product);
  maxDuration += getDurationByPaymentType(paymentType);

  if (
    !isEmpty(shippingCharge) &&
    shippingCharge.minDeliveryDay &&
    shippingCharge.maxDeliveryDay
  ) {
    const { minDeliveryDay, maxDeliveryDay } = shippingCharge;
    minDuration += minDeliveryDay;
    maxDuration += maxDeliveryDay;
  }
  // add min and max in range
  else if (area && area.minDeliveryDay && area.maxDeliveryDay) {
    const { minDeliveryDay, maxDeliveryDay } = area;
    minDuration += minDeliveryDay;
    maxDuration += maxDeliveryDay;
  }
  return [minDuration, maxDuration];
}

export function getStockRecordRangeArrivalTime(
  stockRecord,
  area,
  shippingCharge = null,
  paymentType = null,
) {
  let { minFulfillmentTime: minDuration, maxFulfillmentTime: maxDuration } =
    getStockRecordFulfillmentTime(stockRecord);
  minDuration += getStockRecordBackorderFulfillmentTime(stockRecord);
  minDuration += getDurationByPaymentType(paymentType);
  maxDuration += getStockRecordBackorderFulfillmentTime(stockRecord);
  maxDuration += getDurationByPaymentType(paymentType);

  if (
    !isEmpty(shippingCharge) &&
    shippingCharge.minDeliveryDay &&
    shippingCharge.maxDeliveryDay
  ) {
    const { minDeliveryDay, maxDeliveryDay } = shippingCharge;
    minDuration += minDeliveryDay;
    maxDuration += maxDeliveryDay;
  }
  // add min and max in range
  else if (area && area.minDeliveryDay && area.maxDeliveryDay) {
    const { minDeliveryDay, maxDeliveryDay } = area;
    minDuration += minDeliveryDay;
    maxDuration += maxDeliveryDay;
  }
  return [minDuration, maxDuration];
}

export function getProductsFulfillmentTimeRange(products) {
  let minDuration = 0;
  let maxDuration = 0;
  products.forEach((product) => {
    const { minFulfillmentTime, maxFulfillmentTime } =
      getProductFulfillmentTime(product);
    minDuration = Math.max(minDuration, minFulfillmentTime);
    maxDuration = Math.max(maxDuration, maxFulfillmentTime);
  });
  return [minDuration, maxDuration];
}

export function getProductsBackorderTime(products) {
  let maxBackorderTime = 0;
  products.forEach((product) => {
    maxBackorderTime = Math.max(
      maxBackorderTime,
      getProductBackorderFulfillmentTime(product),
    );
  });
  return maxBackorderTime;
}

export function getProductsArrivalTimeRange(
  products,
  area = null,
  shippingCharge = null,
  isShopAssistant = false,
) {
  let minDuration = 0;
  let maxDuration = 0;
  let maxBackorderTime = 0;

  products.forEach((product) => {
    const { minFulfillmentTime, maxFulfillmentTime } =
      getProductFulfillmentTime(product);
    minDuration = Math.max(minDuration, minFulfillmentTime);
    maxDuration = Math.max(maxDuration, maxFulfillmentTime);
    maxBackorderTime = Math.max(
      maxBackorderTime,
      getProductBackorderFulfillmentTime(product),
    );
  });
  minDuration += maxBackorderTime;
  maxDuration += maxBackorderTime;

  // @note(dika): need to add K_PAYMENT_MIN_PROCESS_DAYS - K_PAYMENT_MAX_PROCESS_DAYS days from payment, 8 Sep 2021
  // only for non offline transaction
  if (!isShopAssistant) {
    minDuration += K_PAYMENT_MIN_PROCESS_DAYS;
    maxDuration += K_PAYMENT_MAX_PROCESS_DAYS;
  }

  if (
    !isEmpty(shippingCharge) &&
    shippingCharge.minDeliveryDay &&
    shippingCharge.maxDeliveryDay
  ) {
    const { minDeliveryDay, maxDeliveryDay } = shippingCharge;
    minDuration += minDeliveryDay;
    maxDuration += maxDeliveryDay;
  }
  // add min and max in range
  else if (area && area.minDeliveryDay && area.maxDeliveryDay) {
    const { minDeliveryDay, maxDeliveryDay } = area;
    minDuration += minDeliveryDay;
    maxDuration += maxDeliveryDay;
  }
  return [minDuration, maxDuration];
}

export function getEstimatedArrivalDate(
  availability,
  area,
  paymentType = null,
) {
  let duration = getProductFulfillmentTime({ availability }).maxFulfillmentTime;
  duration += getProductBackorderFulfillmentTime({ availability });
  duration += getDurationByPaymentType(paymentType);

  // coverage related, ex take average between min and max delivery day
  if (area.minDeliveryDay && area.maxDeliveryDay) {
    const { minDeliveryDay, maxDeliveryDay } = area;
    duration += Math.floor((minDeliveryDay + maxDeliveryDay) / 2);
  }

  const today = moment();
  return today.add(extraDaysForWeekend(today, duration), 'days');
}

export function convertMomentDateToString(date) {
  return date.locale('id').format('dddd, D MMM');
}

export function getEstimatedArrivalDateString(
  availability,
  area,
  paymentType = null,
) {
  return convertMomentDateToString(
    getEstimatedArrivalDate(availability, area, paymentType),
  );
}

// formula skip weekend: https://stackoverflow.com/a/43214254
export function extraDaysForWeekend(startDate, days) {
  const day = startDate.day();
  return (
    days +
    (day === 6 ? 2 : +!day) +
    Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2
  );
}

export function addBusinessDayDuration(startDate, duration) {
  const extraDays = extraDaysForWeekend(
    startDate,
    Math.floor(duration.asDays()),
  );
  const remaining = duration.subtract({ days: Math.floor(duration.asDays()) });
  let result = startDate.clone().add(remaining.add(extraDays, 'd'));
  while (result.day() === 0 || result.day() === 6) {
    result = result.add(moment.duration({ days: 1 }));
  }
  return result;
}

export function getWeightBasedShippingTotal(area, basketLines, basketSubtotal) {
  if (!area.price) return 0;
  if (
    area.isPromoFreeShipping &&
    basketSubtotal >= K_MINIMUM_PURCHASE_FOR_FREE_SHIPPING
  ) {
    return 0;
  }

  const weightAndVolumetricPerPartner =
    getWeightAndVolumetricPerPartner(basketLines);

  let totalWeight = 0;

  Object.keys(weightAndVolumetricPerPartner).forEach((partner) => {
    const detail = weightAndVolumetricPerPartner[partner];
    totalWeight += getWeightAmount(detail);
  });

  if (totalWeight > K_FULLY_PAID_SHIPPING_WEIGHT) {
    totalWeight =
      K_FULLY_PAID_SHIPPING_WEIGHT +
      (totalWeight - K_FULLY_PAID_SHIPPING_WEIGHT) / 2;
  }

  return totalWeight * area.price;
}

/**
 * getWeightAndVolumetricPerPartner: get total of shipping weight for each partner
 *
 * @type Object
 * {
 *   'Ikea': {'weight': 7.8, 'volumetric': 10},
 *   'JYSK': {'weight': 9.3, 'volumetric': 4},
 * }
 */
/**
 * We use volumetric as p * l * t / 4000 (standard logistic volumetric for jalur darat)
 *
 */
export function getWeightAndVolumetricPerPartner(basketLines) {
  return basketLines.reduce((res, curr) => {
    if (!has(res, curr.product.partner.name)) {
      res[curr.product.partner.name] = { weight: 0, volumetric: 0 };
    }
    res[curr.product.partner.name].weight +=
      curr.product.weight * curr.quantity;
    // res[curr.product.partner.name].volumetric +=
    //   curr.product.volumetric * curr.quantity;
    const length = getProductLength(curr.product);
    const width = getProductWidth(curr.product);
    const height = getProductHeight(curr.product);
    res[curr.product.partner.name].volumetric +=
      ((length * width * height) / 4000) * curr.quantity;

    if (curr.addonProducts) {
      curr.addonProducts.forEach((addonProduct) => {
        if (!has(res, addonProduct.product.partner.name)) {
          res[addonProduct.product.partner.name] = { weight: 0, volumetric: 0 };
        }
        res[addonProduct.product.partner.name].weight +=
          addonProduct.product.weight * addonProduct.quantity;
        // res[addonProduct.product.partner.name].volumetric +=
        //   addonProduct.product.volumetric * addonProduct.quantity;
        const addonLength = getProductLength(addonProduct.product);
        const addonWidth = getProductWidth(addonProduct.product);
        const addonHeight = getProductHeight(addonProduct.product);
        res[addonProduct.product.partner.name].volumetric +=
          ((addonLength * addonWidth * addonHeight) / 4000) *
          addonProduct.quantity;
      });
    }

    return res;
  }, {});
}

const getShippingCoveragePerClass = (area, shippingClassName) =>
  area.shippingPricingCoverages.find(
    (coverage) => coverage.shippingClassName === shippingClassName,
  );

export function getClassBasedShippingTotal(
  area,
  basketLines,
  basketSubtotal,
  owner,
) {
  return getClassBasedShippingTotalDetail(
    area,
    basketLines,
    basketSubtotal,
    owner,
  ).price;
}

export function getClassBasedShippingTotalBeforeRebate(
  area,
  basketLines,
  basketSubtotal,
  owner,
) {
  return getClassBasedShippingTotalDetail(
    area,
    basketLines,
    basketSubtotal,
    owner,
  ).priceBeforeRebate;
}

export function getClassBasedShippingTotalDetail(
  area,
  basketLines,
  basketSubtotal,
  owner,
) {
  if (
    isEmpty(basketLines) ||
    !area.shippingPricingCoverages ||
    area.shippingPricingCoverages.length === 0
  ) {
    return {
      price: 0,
      priceBeforeRebate: 0,
    };
  }
  const bulkCalculatedbasketLines = basketLines.filter((line) =>
    K_BULK_CALCULATED_SHIPPING_CLASSES.includes(line.product.shippingClassName),
  );
  if (!isEmpty(bulkCalculatedbasketLines)) {
    return getClassBasedBulkCalculatedShippingTotalDetail(
      area,
      bulkCalculatedbasketLines,
      basketSubtotal,
      owner,
    );
  }
  const unitCalculatedBasketLines = basketLines.filter(
    (line) =>
      !K_BULK_CALCULATED_SHIPPING_CLASSES.includes(
        line.product.shippingClassName,
      ),
  );
  if (!isEmpty(unitCalculatedBasketLines)) {
    return getClassBasedUnitCalculatedShippingTotalDetail(
      area,
      unitCalculatedBasketLines,
    );
  }
  return null;
}

export function getClassBasedUnitCalculatedShippingTotalDetail(
  area,
  basketLines,
) {
  let classBasedShippingTotal = 0;
  let classBasedShippingTotalBeforeRebate = 0;
  basketLines.forEach((basketLine) => {
    if (!isFreeShipping(get(basketLine, 'product.brand'), area.island)) {
      const shippingCoveragePerClass = getShippingCoveragePerClass(
        area,
        basketLine.product.shippingClassName,
      );

      if (shippingCoveragePerClass) {
        const { price } = shippingCoveragePerClass;
        const priceBeforeRebate = get(
          shippingCoveragePerClass,
          'priceBeforeRebate',
          price,
        );

        classBasedShippingTotal += basketLine.quantity * price;
        classBasedShippingTotalBeforeRebate +=
          basketLine.quantity * priceBeforeRebate;
      }

      if (basketLine.addonProducts) {
        basketLine.addonProducts.forEach((addonProduct) => {
          const { shippingClassName } = addonProduct.product;

          if (shippingClassName) {
            const addonShippingCoveragePerClass =
              area.shippingPricingCoverages.find(
                (coverage) => coverage.shippingClassName === shippingClassName,
              );
            if (addonShippingCoveragePerClass) {
              const priceBeforeRebate = get(
                addonShippingCoveragePerClass,
                'priceBeforeRebate',
                addonShippingCoveragePerClass.price,
              );

              classBasedShippingTotal +=
                addonProduct.quantity * addonShippingCoveragePerClass.price;
              classBasedShippingTotalBeforeRebate +=
                basketLine.quantity * priceBeforeRebate;
            }
          }
        });
      }
    }
  });

  return {
    price: classBasedShippingTotal,
    priceBeforeRebate: classBasedShippingTotalBeforeRebate,
  };
}

export function getClassBasedBulkCalculatedShippingTotalDetail(
  area,
  basketLines,
  basketSubtotal,
  owner,
) {
  /** Bulk shippping for Material (DekoHub) */
  const basketLinePriceDetails = basketLines.map((basketLine) => {
    const shippingCoveragePerClass = getShippingCoveragePerClass(
      area,
      basketLine.product.shippingClassName,
    );
    const price = has(shippingCoveragePerClass, 'price')
      ? shippingCoveragePerClass.price
      : 0;
    const priceBeforeRebate = has(shippingCoveragePerClass, 'priceBeforeRebate')
      ? shippingCoveragePerClass.priceBeforeRebate
      : price;
    return {
      price,
      priceBeforeRebate,
    };
  });
  const bulkClassBasedShippingTotalPrice = max(
    basketLinePriceDetails.map(
      (basketLinePriceDetail) => basketLinePriceDetail.price,
    ),
  );
  const bulkClassBasedShippingTotalPriceBeforeRebate = max(
    basketLinePriceDetails.map(
      (basketLinePriceDetail) => basketLinePriceDetail.priceBeforeRebate,
    ),
  );

  if (basketLines[0].product.shippingClassName === K_SHIPPING_CLASS_MATERIAL) {
    if (owner.groups && owner.groups.includes(K_DEKOHUB_GROUP_FREE_SHIPPING)) {
      return {
        price: 0,
        priceBeforeRebate: bulkClassBasedShippingTotalPriceBeforeRebate,
      };
    }
    if (basketSubtotal >= K_MINIMUM_PRICE_FOR_FREE_SHIPPING_MATERIAL) {
      return {
        price: 0,
        priceBeforeRebate: bulkClassBasedShippingTotalPriceBeforeRebate,
      };
    }
  }

  return {
    price: bulkClassBasedShippingTotalPrice,
    priceBeforeRebate: bulkClassBasedShippingTotalPriceBeforeRebate,
  };
}
