export const K_FAKE_BEDDING_RATING_BY_BRAND = {
  Alicia: {
    softness: 82,
    coolness: 71,
    sameAsPicture: 83,
  },
  Belarose: {
    softness: 61,
    coolness: 58,
    sameAsPicture: 91,
  },
  BelarosePremium: {
    softness: 66,
    coolness: 63,
    sameAsPicture: 90,
  },
  Bloomingdale: {
    softness: 90,
    coolness: 95,
    sameAsPicture: 95,
  },
  ButikCeria: {
    softness: 70,
    coolness: 80,
    sameAsPicture: 85,
  },
  ChelseaGold: {
    softness: 73,
    coolness: 77,
    sameAsPicture: 70,
  },
  Crystalbed: {
    softness: 85,
    coolness: 80,
    sameAsPicture: 80,
  },
  Javeline: {
    softness: 70,
    coolness: 75,
    sameAsPicture: 80,
  },
  Juzzshop: {
    softness: 82,
    coolness: 80,
    sameAsPicture: 95,
  },
  KendraSignature: {
    softness: 86,
    coolness: 83,
    sameAsPicture: 70,
  },
  KingRabbit: {
    softness: 87,
    coolness: 89,
    sameAsPicture: 93,
  },
  Monalisa: {
    softness: 75,
    coolness: 82,
    sameAsPicture: 85,
  },
  Palmerhaus: {
    softness: 95,
    coolness: 93,
    sameAsPicture: 96,
  },
  Pantone: {
    softness: 91,
    coolness: 90,
    sameAsPicture: 95,
  },
  Pesona: {
    softness: 81,
    coolness: 78,
    sameAsPicture: 86,
  },
  Rosewell: {
    softness: 83,
    coolness: 80,
    sameAsPicture: 80,
  },
  Sierra: {
    softness: 93,
    coolness: 90,
    sameAsPicture: 95,
  },
  SleepBuddy: {
    softness: 93,
    coolness: 92,
    sameAsPicture: 90,
  },
  Star: {
    softness: 86,
    coolness: 83,
    sameAsPicture: 90,
  },
  Yukero: {
    softness: 90,
    coolness: 86,
    sameAsPicture: 93,
  },
};

export const K_BEDDING_QUALITY_ORDER = {
  Ekonomis: 0,
  Standar: 1,
  Premium: 2,
  Luxury: 3,
};

export const K_BEDDING_SIZE_ORDER = {
  'Single (100x200cm)': 0,
  'Super Single (120x200cm)': 1,
  'Queen Size (160x200cm)': 2,
  'King Size (180x200cm)': 3,
  'Extra King (200x200cm)': 4,
  'Double (230x230cm)': 5,
};

export const K_ATTRIBUTE_LABEL_BEDDING_SIZE = 'Ukuran Sprei';
export const K_ATTRIBUTE_LABEL_BEDDING_QUALITY = 'Kualitas Sprei';
