import {
  K_MOBILE_MAX_WIDTH,
  K_DESKTOP_MAX_WIDTH,
  K_DESKTOP_MAX_WIDTH_S,
} from '@dkrm/general-libs/theme/styles';

/**
 * @param {number - required} width current screen width
 * @param {number} currentMaxWidth  current max screen width (eg. use K_DESKTOP_MAX_WIDTH_S (940))
 */
export function getGridNumberOfColumns(width, currentMaxWidth) {
  if (currentMaxWidth <= K_DESKTOP_MAX_WIDTH_S) {
    if (width < K_MOBILE_MAX_WIDTH) return 3;
    return 4;
  }
  if (width < K_MOBILE_MAX_WIDTH) return 1;
  if (width < K_DESKTOP_MAX_WIDTH_S) return 2;
  if (width < K_DESKTOP_MAX_WIDTH) return 3;
  return 3;
}

/**
 * @param {string} breadcrumb the hierarchical category string e.g 'Kamar Tidur > Sprei'
 */
export function shouldShowPatternFilter(breadcrumb) {
  if (typeof breadcrumb !== 'string') return false;
  if (breadcrumb.startsWith('Perlengkapan Kamar Tidur > Sprei')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Perlengkapan Kamar Tidur > Set Sprei')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Perlengkapan Kamar Tidur > Set Sprei & Sarung Bantal')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Perlengkapan Kamar Tidur > Set Sprei & Bed Cover')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Perlengkapan Kamar Tidur > Sprei Saja')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Dekor > Bantal')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Dekor > Hiasan Dinding > Poster')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Dekor > Wall Covering > Stiker Dinding')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Dekor > Wall Covering > Wallpaper Stiker')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Home Improvement > Wallpaper')) return true; // prettier-ignore
  if (breadcrumb.startsWith('Aksesoris Rumah > Tekstil > Bean Bag & Kursi Lesehan')) return true; // prettier-ignore
  return false;
}
