import React from 'react';
import { View, Link, StyleSheet } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';
import { useMediaQuery } from 'entities/footer/hooks';

import {
  K_MOBILE_FOOTER_BREAKPOINT,
  K_FOOTER_SECTION_ITEMS,
  K_FOOTER_SECTION_DEKORUMA,
  K_FOOTER_SECTION_BUSINESS_UNIT,
  K_FOOTER_SECTION_CONTACT,
} from '../constants';

import { FooterSectionItem } from './FooterSectionItem';
import { FooterSectionTitle } from './FooterSectionTitle';

export interface FooterSectionProps {
  type:
    | typeof K_FOOTER_SECTION_DEKORUMA
    | typeof K_FOOTER_SECTION_BUSINESS_UNIT
    | typeof K_FOOTER_SECTION_CONTACT;
}

export const FooterSection: React.FC<FooterSectionProps> = ({ type }) => {
  const isMobile = useMediaQuery(K_MOBILE_FOOTER_BREAKPOINT);
  const { title, items } = K_FOOTER_SECTION_ITEMS[type];
  const item = isMobile ? items[0] : items[1];

  return (
    <View>
      <FooterSectionTitle title={title} />
      <View
        style={isMobile && type !== K_FOOTER_SECTION_CONTACT && s.mContainer}
      >
        {item.map(({ label, linkUrl, isUsingAnchor = false, Icon }) => (
          <View style={cStyles.mvm} key={`footer-section-item-${label}`}>
            {linkUrl ? (
              <Link isUsingAnchor={isUsingAnchor} to={linkUrl}>
                <FooterSectionItem label={label} Icon={Icon} />
              </Link>
            ) : (
              <FooterSectionItem label={label} Icon={Icon} />
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  mContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

export default FooterSection;
