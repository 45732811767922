export const getCookieByName = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts[1].split(';').shift();
  return null;
};

// expires in UTCString
export const setCookie = (
  key: string,
  value: string,
  expires: string,
  path: string | null = null,
  domain: string | null = null,
) => {
  let cookieString = `${key}=${value};expires=${expires}`;
  if (path) {
    cookieString += `;path=${path}`;
  }
  if (domain) {
    cookieString += `;domain=${domain}`;
  }
  document.cookie = cookieString;
};

export const expiredDateInUTC = (additionalDays: number) => {
  const expiredDate = new Date();

  expiredDate.setDate(expiredDate.getDate() + additionalDays);

  return expiredDate.toUTCString();
};
