import React, { memo } from 'react';

import { getUrlWithOverridenQuery } from 'app-libs/etc/routeHelper';
import { useLocation, useQueries } from 'app-libs/hook_modules/router';

const PageRefresherOn404: React.FC = memo(() => {
  /**
   * @workaround by Natan - auto-refresher
   * Since we have many apps running on same domain,
   * and linking to one another should use anchor (SSR redirect),
   * developer usually missed proper linking causing 404.
   *
   * Here we attempt to refresh the page,
   * so broken link that actually exists in another app will be shown.
   * This is similar to nextjs 404 behavior.
   * */
  const { isRefreshed } = useQueries();
  const routeState = useLocation().state as { shouldRefreshOn404?: boolean };
  if (
    routeState?.shouldRefreshOn404 &&
    !isRefreshed &&
    typeof window !== 'undefined'
  ) {
    window.location.href = getUrlWithOverridenQuery(window.location.href, {
      isRefreshed: 1,
    });
    return null;
  }

  return null;
});

export default PageRefresherOn404;
