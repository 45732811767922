import React, { memo, useMemo } from 'react';
import Link from 'ui-kit-basic-adapter/Link';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

import { makeParametricUrl, unslugify } from 'app-libs/etc';
import { dayjs } from 'app-libs/etc/dateHelperV2';
import { useParams } from 'app-libs/hook_modules/router';

import { useQueryLoadSEOTopSearchQueries } from 'entities/seo/utils';

import { K_ROUTE_TOP_SEARCH } from 'constants/routeConstants';

const K_TOP_SEARCH_QUERIES_MAX_QUERY_TO_SHOW = 15;

const TopSearchPageFooter: React.FC = memo(() => {
  const { querySlug } = useParams<Record<string, string>>();

  const { topSearchQueries } = useQueryLoadSEOTopSearchQueries();

  const now = dayjs();
  const queryString = unslugify(querySlug);

  const footerTitle = `Belanja Produk ${queryString} Terbaru ${now.format(
    'MMMM YYYY',
  )} Tanpa Ribet di Dekoruma.com`;

  // @note(dika) 4 Nov 2024: Hardcoded as of now
  // we are not using article posts as footer anymore
  // instead we use template content
  const footerHtml = useMemo(() => {
    return {
      __html: `
      Buat kamu yang ingin mempercantik rumah tanpa ribet, sekarang kamu bisa beli produk ${queryString} secara online di Dekoruma.com. Tidak hanya menyediakan berbagai pilihan produk berkualitas, Dekoruma.com juga menawarkan beragam kemudahan untuk belanja furniture dan dekorasi interior. Apa saja kelebihannya?
      <h3>1. Desain Stylish dan Terkini</h3>
      Produk-produk ${queryString} di Dekoruma.com selalu memiliki desain yang stylish dan up-to-date, mengikuti tren furniture dan interior terbaru. Jadi, buat kamu yang ingin menyegarkan tampilan ruangan, cukup kunjungi Dekoruma.com dan temukan inspirasi terbaru!
      <h3>2. Layanan Pengantaran Bebas Ongkir*</h3>
      Nikmati kemudahan pengantaran bebas ongkir* untuk produk ${queryString} pesananmu. Tinggal check out dan duduk santai tanpa perlu memikirkan biaya tambahan, pesananmu akan sampai di rumah dengan cepat dan aman.
      <h3>3. Fleksibilitas Pembayaran dan Cicilan</h3>
      Punya rencana makeover furniture tapi terkendala biaya? Jangan khawatir! Dekoruma.com menyediakan berbagai opsi pembayaran dan skema cicilan untuk memudahkan kamu mendapatkan produk ${queryString} impianmu, tanpa harus menunggu.
      <h3>4. Offline Store di Banyak Kota</h3>
      Masih ragu dengan kualitas produk ${queryString} kami? Dekoruma juga memiliki offline store yang tersebar di berbagai kota di Indonesia. Kamu bisa langsung datang dan melihat kualitas produk yang kamu lihat di Dekoruma.com secara langsung.
      Jadi, tunggu apa lagi? Langsung check out di Dekoruma.com dan wujudkan hunian impianmu dengan mudah dan praktis!
      `,
    };
  }, [queryString]);

  return (
    <View style={s.container}>
      <Text h2 theme="b4-neutral500" style={cStyles.tac}>
        {footerTitle}
      </Text>
      <Text theme="b5-neutral100">
        <div dangerouslySetInnerHTML={footerHtml} />
      </Text>
      {topSearchQueries.length > 0 && (
        <>
          <Text h2 theme="b4-neutral500" style={cStyles.tac}>
            Dapatkan Produk Menarik Lainnya
          </Text>
          <View style={s.topSearchQueries}>
            {topSearchQueries
              .slice(0, K_TOP_SEARCH_QUERIES_MAX_QUERY_TO_SHOW)
              .map((topSearchQuery, idx) => {
                const to = makeParametricUrl(K_ROUTE_TOP_SEARCH, {
                  querySlug: topSearchQuery.slug,
                });

                return (
                  <React.Fragment key={querySlug}>
                    {idx > 0 && (
                      <Text theme="b5-neutral100" style={cStyles.mt2}>
                        {' '}
                        |{' '}
                      </Text>
                    )}
                    <Link to={to}>
                      <Text theme="b5-teal500">{topSearchQuery.query}</Text>
                    </Link>
                  </React.Fragment>
                );
              })}
          </View>
        </>
      )}
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pa16,
  },
  topSearchQueries: {
    ...cStyles.flexDirRow,
    display: 'block',
    alignItems: 'center',
  },
});

export default TopSearchPageFooter;
