import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component, PureComponent, memo, useMemo } from 'react';

import { getCDNImageUrl } from '@dkrm/general-libs/Utils/imageUtils';

import { getLastCategory } from 'app-libs/etc';
import { genProductUrl } from 'app-libs/etc/productHelper';
import { getPathFromUrl } from 'app-libs/etc/routeHelper';
import { useLocation } from 'app-libs/hook_modules/router';

import { K_HOST, K_QUERY, K_ROUTE_SEARCH } from 'constants/routeConstants';

const K_SCHEMA_AVAILABILITY_IN_STOCK = 'http://schema.org/InStock';
const K_SCHEMA_AVAILABILITY_SOLD_OUT = 'http://schema.org/SoldOut';
const K_SCHEMA_ITEM_CONDITION_NEW_CONDITION = 'http://schema.org/NewCondition';
const K_PRICE_CURRENCY_IDR = 'IDR';

// This function is a duplicate from storefront/external/utils;
function clearHtmlTagFromString(string) {
  return string.replace(/(<([^>]+)>)/gi, '');
}

/** Breadcrumbs */
export const breadcrumbList = {
  vocab: 'http://schema.org/',
  typeof: 'BreadcrumbList',
};

export const listItem = {
  property: 'itemListElement',
  typeof: 'ListItem',
};

export const itemList = {
  vocab: 'http://schema.org',
  typeof: 'ItemList',
};

export const item = {
  property: 'item',
  typeof: 'WebPage',
};

export const name = {
  property: 'name',
};

export const itemListElement = {
  property: 'itemListElement',
};

export class Position extends PureComponent {
  static propTypes = {
    position: PropTypes.number.isRequired,
  };

  render() {
    const { position } = this.props;
    return <meta property="position" content={position} />;
  }
}

/** Aggregate Rating */
export const aggregateRating = {
  vocab: 'http://schema.org/',
  typeof: 'AggregateRating',
};

export const ratingValue = {
  property: 'ratingValue',
};

export const reviewCount = {
  property: 'reviewCount',
};

export const aggregateRatingProperty = {
  property: 'aggregateRating',
};

/** Product */

export const product = {
  vocab: 'http://schema.org/',
  typeof: 'Product',
};

export const brand = {
  property: 'brand',
};

export const image = {
  property: 'image',
};

export const category = {
  property: 'category',
};

export const color = {
  property: 'color',
};

export const description = {
  property: 'description',
};

export const sku = {
  property: 'sku',
};

export const url = {
  property: 'url',
};

export const offers = {
  property: 'offers',
  typeof: 'Offer',
};

export const seller = {
  property: 'seller',
  typeof: 'Organization',
};

export class Price extends PureComponent {
  static propTypes = {
    productPrice: PropTypes.number.isRequired,
  };

  render() {
    const { productPrice } = this.props;
    return <meta property="price" content={productPrice} />;
  }
}

export class PriceCurrency extends PureComponent {
  render() {
    return <meta property="priceCurrency" content="IDR" />;
  }
}

export class ItemConditionNew extends PureComponent {
  render() {
    return (
      <link property="itemCondition" href="http://schema.org/NewCondition" />
    );
  }
}

export class AvailabilityInStock extends PureComponent {
  render() {
    return <link property="availability" href="http://schema.org/InStock" />;
  }
}

export class AvailabilitySoldOut extends PureComponent {
  render() {
    return <link property="availability" href="http://schema.org/SoldOut" />;
  }
}

export class SiteNavigation extends PureComponent {
  render() {
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'SiteNavigationElement',
      url: [
        'https://www.dekoruma.com/furniture',
        'https://www.dekoruma.com/kasur',
        'https://www.dekoruma.com/sprei',
        'https://www.dekoruma.com/kitchen-set',
        'https://www.dekoruma.com/desain-interior',
        'https://www.dekoruma.com/artikel',
      ],
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

/**
 * Sitelinks Search Box
 * https://developers.google.com/structured-data/slsb-overview
 */

export class SiteNameWithSiteLinkSearchBox extends PureComponent {
  render() {
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'Dekoruma.com',
      url: 'http://www.dekoruma.com',
      potentialAction: {
        '@type': 'SearchAction',
        target: `http://www.dekoruma.com${K_ROUTE_SEARCH}?${K_QUERY}={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

/**
 * Include Your Site Name in Search Results
 * https://developers.google.com/structured-data/site-name
 */
export class SiteName extends PureComponent {
  render() {
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'Dekoruma.com',
      url: 'http://www.dekoruma.com',
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

/**
 * Specifying Your Organization's Logo
 * https://developers.google.com/structured-data/customize/logos
 *
 * Corporate Contacts
 * https://developers.google.com/structured-data/customize/contact-points
 *
 * Social Profiles
 * https://developers.google.com/structured-data/customize/social-profiles
 */
export class OrganizationKnowledgeGraph extends PureComponent {
  render() {
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: 'http://www.dekoruma.com',
      name: 'Dekoruma.com',
      logo: 'http://www.dekoruma.com/i/logo-text.png',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+62-589-09401',
          contactType: 'customer service',
        },
      ],
      sameAs: [
        'https://www.facebook.com/dekoruma',
        'http://instagram.com/dekoruma',
        'https://plus.google.com/+Dekoruma',
        'https://twitter.com/hellodekoruma',
        'https://www.linkedin.com/company/dekoruma',
      ],
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

/**
 * Include Your Site Name in Search Results
 * https://developers.google.com/search/docs/data-types/breadcrumb
 */
export class BreadcrumbStructuredData extends PureComponent {
  static propTypes = {
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };

  getItemListElement = (itemName, itemPath, position) => ({
    '@type': 'ListItem',
    position,
    item: {
      '@id': K_HOST + getPathFromUrl(itemPath),
      name: itemName,
    },
  });

  render() {
    const { breadcrumbs } = this.props;
    const breadcrumbItemListElement = breadcrumbs.map(
      ({ name: itemName, path: itemPath }, idx) =>
        this.getItemListElement(itemName, itemPath, idx + 1),
    );
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbItemListElement,
    };

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

export class ProductListStructuredData extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape()),
    title: PropTypes.string,
    productCount: PropTypes.number,
  };

  static defaultProps = {
    products: [],
    title: '',
    productCount: 0,
  };

  getProductRatingData = (firstProduct, ratingCount) => {
    const { title } = this.props;
    const productPrice = isEmpty(firstProduct.productOffer)
      ? firstProduct.priceNumber
      : firstProduct.productOffer.salePrice;
    return {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: title,
      offers: {
        '@type': 'Offer',
        priceCurrency: K_PRICE_CURRENCY_IDR,
        price: productPrice,
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: 5,
        worstRating: 1,
        bestRating: 5,
        reviewCount: ratingCount,
      },
    };
  };

  getProductItemListElement = (p, position) => ({
    '@type': 'ListItem',
    url: genProductUrl(p),
    position,
  });

  render() {
    const { products, productCount } = this.props;
    let ldProductRating = null;
    if (products.length !== 0) {
      ldProductRating = this.getProductRatingData(products[0], productCount);
    }
    const productItemListElement = products.map((p, idx) =>
      this.getProductItemListElement(p, idx + 1),
    );
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement: productItemListElement,
    };

    return (
      <>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
        />
        {ldProductRating && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(ldProductRating),
            }}
          />
        )}
      </>
    );
  }
}

/**
 * Include Your Site Name in Search Results
 * https://developers.google.com/search/docs/data-types/product
 */
export class ProductDetailStructuredData extends PureComponent {
  static propTypes = {
    product: PropTypes.shape().isRequired,
    numReview: PropTypes.number.isRequired,
    numRating: PropTypes.number.isRequired,
  };

  render() {
    const { product: currentProduct, numReview, numRating } = this.props;
    const {
      brand: productBrand,
      categories,
      color: productColor,
      description: productDescription,
      image: productImage,
      objectID,
      partner,
      title,
    } = currentProduct;
    const lastCategory = getLastCategory(categories) || 'Product';
    const showSoldOut = !get(
      currentProduct,
      'availability.isAvailableToBuy',
      true,
    );
    const productAvailability = showSoldOut
      ? K_SCHEMA_AVAILABILITY_SOLD_OUT
      : K_SCHEMA_AVAILABILITY_IN_STOCK;
    const productPrice = isEmpty(currentProduct.productOffer)
      ? currentProduct.priceNumber
      : currentProduct.productOffer.salePrice;
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: title,
      url: genProductUrl(currentProduct),
      sku: objectID,
      color: productColor,
      image: productImage,
      category: lastCategory,
      description: productDescription,
      brand: {
        name: productBrand,
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: K_PRICE_CURRENCY_IDR,
        price: productPrice,
        availability: productAvailability,
        itemCondition: K_SCHEMA_ITEM_CONDITION_NEW_CONDITION,
        seller: {
          '@type': 'Organization',
          name: partner?.name,
        },
      },
    };

    if (numReview > 0) {
      ld.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: numRating,
        reviewCount: numReview,
        worstRating: 0,
        bestRating: 5,
      };
    }

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

/**
 * Include Your Site Name in Search Results
 * https://developers.google.com/search/docs/data-types/articles
 */
export class PostDetailStructuredData extends PureComponent {
  static propTypes = {
    post: PropTypes.shape().isRequired,
  };

  render() {
    const { post } = this.props;
    const { title, content, excerpt, postThumbnail, date, modified } = post;
    const imagePost = post.image;
    const titleString = title;
    const descriptionString = clearHtmlTagFromString(excerpt || content);
    let imageURL = '';
    if (postThumbnail && postThumbnail.URL) {
      imageURL = postThumbnail.URL;
    } else if (imagePost) {
      imageURL = imagePost;
    }
    const ld = {
      '@context': 'http://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://www.dekoruma.com/artikel/',
      },
      headline: titleString,
      articleBody: content,
      image: {
        '@type': 'ImageObject',
        url: imageURL,
        height: 800,
        width: 480,
      },
      datePublished: date,
      dateModified: modified,
      author: {
        '@type': 'Person',
        name: 'Kania Dekoruma',
      },
      publisher: {
        '@type': 'Organization',
        url: 'http://www.dekoruma.com',
        name: 'Dekoruma.com',
        logo: {
          '@type': 'ImageObject',
          url: 'http://www.dekoruma.com/i/logo-text.png',
          width: 200,
          height: 50,
        },
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '+62-533-2433',
            contactType: 'customer service',
          },
        ],
        sameAs: [
          'https://www.facebook.com/dekoruma',
          'http://instagram.com/dekoruma',
          'https://plus.google.com/+Dekoruma',
          'https://twitter.com/hellodekoruma',
        ],
      },
      description: descriptionString,
    };
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  }
}

export const ProjectListStructuredData = memo(
  ({ title, image: _image, description: _description }) => {
    const { pathname } = useLocation();

    const ld = useMemo(
      () => ({
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: title,
        image: getCDNImageUrl(_image),
        description: _description,
        brand: {
          '@type': 'Brand',
          name: 'Dekoruma',
        },
        offers: {
          '@type': 'Offer',
          url: `https://www.dekoruma.com${pathname}`,
          priceCurrency: 'IDR',
          price: '15000000',
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '5',
          bestRating: '5',
          worstRating: '1',
          ratingCount: '5',
        },
      }),
      [_description, _image, pathname, title],
    );

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  },
);

ProjectListStructuredData.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
