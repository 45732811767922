import superagent from 'superagent';
import config from 'config';
import {
  EditCustomerIdentifierPayload,
  OfflineBasketShippingAddressPayload,
} from './types';

export const getLoadOfflineBasketListQueryKey = () => `offline-basket-list`;

export const getLoadOfflineStoreStockRecordIdQueryKey = (upc: string) =>
  `offline-store-stock-record-upc-${upc}`;

export const createOfflineBasket = async (customerIdentifier: string) => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/offline-baskets/`)
    .send({ customerIdentifier })
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const deleteOfflineBasket = async (basketId: number) => {
  const res = await superagent
    .del(`${config.API_URL_GOBLIN}/offline-baskets/${basketId}/`)
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const editCustomerIdentifier = async (
  payload: EditCustomerIdentifierPayload,
) => {
  const { customerIdentifier, basketId } = payload;
  const res = await superagent
    .put(`${config.API_URL_GOBLIN}/offline-baskets/${basketId}/`)
    .send({ customerIdentifier })
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const clearBasketLines = async (basketId: number) => {
  const res = await superagent
    .del(`${config.API_URL_GOBLIN}/baskets/${basketId}/lines/`)
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const loadOfflineBasketList = async () => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/offline-baskets/`)
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const createShopAssistantActiveBasket = async (basketId: number) => {
  const res = await superagent
    .post(
      `${config.API_URL_GOBLIN}/shop-assistant/shop-assistant-active-baskets/`,
    )
    .send({ basketId })
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const loadOfflineStoreStockRecordId = async (upc: string) => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/products/${upc}/stock-records/offline/`)
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const createOfflineBasketShippingAddress = async (
  address: OfflineBasketShippingAddressPayload,
) => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/offline-basket-shipping-addresses/`)
    .send({ ...address })
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};
