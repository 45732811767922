import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  titleContainer: {
    ...cStyles.flexMiddle,
    ...cStyles.width100,
    paddingVertical: 20,
  },

  navigationIcon: {
    ...cStyles.abs,
    top: '50%',
    marginTop: -16,
    marginHorizontal: 16,
  },

  subtitle: {
    ...cStyles.flexDirRow,
    ...cStyles.width100,
    alignItems: 'center',
  },

  titleStyle: {
    textAlign: 'center',
  },

  rightItemsContainer: {
    ...cStyles.abs,
    ...cStyles.height100,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    top: 0,
    right: 0,
  },
});
