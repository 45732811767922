import React from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { getIsOutOfStockByRoutingZoneCode } from 'app-libs/etc/productHelperV2';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';

import { ProductCardProduct } from '../types';

type ProductVariationLabelProps = {
  product: ProductCardProduct;
  imageSize: number;
};

const ProductVariationLabel: React.FC<ProductVariationLabelProps> = ({
  product,
  imageSize,
}) => {
  const routingZoneCode = useSelector(getCurrentLocationRoutingZoneCode);

  const { isOnSale } = product;
  let label = '';
  let color = '';

  const isOutOfStock = getIsOutOfStockByRoutingZoneCode(
    product,
    routingZoneCode,
  );

  if (isOutOfStock) {
    label = 'HABIS';
    color = Colors.C_BLACK_60;
  } else if (isOnSale) {
    label = 'SALE';
    color = Colors.C_PRIMARY_RED;
  }
  if (!label) return null;
  return (
    <View
      style={[
        s.productVariationLabelContainer,
        { width: imageSize, backgroundColor: color },
      ]}
    >
      <Text theme="body5-white" style={{ fontSize: 8 }}>
        {label}
      </Text>
    </View>
  );
};

const s = StyleSheet.create({
  productVariationLabelContainer: {
    opacity: 0.75,
    ...cStyles.abs,
    ...cStyles.bottom,
    ...cStyles.flexMiddle,
    ...cStyles.width100,
  },
});

export default ProductVariationLabel;
