import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { FlatList, Text } from '@dkrm/ui-kit-basic';

export default class ScrollFilterList extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        selected: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    itemComponent: PropTypes.func.isRequired,
    itemSeparatorComponent: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
    ]),
    label: PropTypes.string,
    labelStyle: stylePropTypes,
    contentContainerStyle: stylePropTypes,
    sortComparator: PropTypes.func,
  };

  static defaultProps = {
    itemSeparatorComponent: null,
    labelStyle: null,
    label: null,
    contentContainerStyle: null,
    sortComparator: () => null,
  };

  render() {
    const {
      data,
      label,
      labelStyle,
      itemComponent: Item,
      itemSeparatorComponent,
      contentContainerStyle,
      sortComparator,
      ...rest
    } = this.props;
    if (!data.length) return null;
    const sortedData = [...data].sort(sortComparator);
    return (
      <React.Fragment>
        {!!label && <Text style={labelStyle}>{label}</Text>}
        <FlatList
          horizontal
          data={sortedData}
          keyExtractor={(item) => item.value}
          contentContainerStyle={contentContainerStyle}
          ItemSeparatorComponent={itemSeparatorComponent}
          renderItem={({ item }) => <Item {...item} {...rest} />}
        />
      </React.Fragment>
    );
  }
}
