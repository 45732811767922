import keyMirror from 'keymirror';
import { generateGuid } from 'app-libs/etc';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import {
  getChannelByChannelName,
  getFirstJob,
  getAvailableWorker,
} from './selectors/queue';

/*
  What is this for?
  To run request in certain order (sequentially + full update, sequentially + partial update)

  Solution:
  1. Ada redux baru queue dan ada channelnya. Di tiap channel ada num of worker. Kalau num of workernya 1, artinya bakal di execute sequentially
  2. Worker dijalanin pas app start
  3. Ada config 'shouldTakeLatest' → artinya smua yg pending di queue nya, bisa ketiban berikutnya. contoh use casenya itu yg tiban2 (eg. case ngetik 'abc' full update jadi: a → save → ab → save → abc → save, bukan partial update  a → save → b → save → c → save)

  FAQ:
  1. kapan harus ada di channel sama kapan harus beda?
      1. kalau sama, artinya berhubungan & harus urut. contohnya update album, utk tiap album id sama, sequence editnya harus sama, tp kalau album id beda bisa paralel. Jadi channel namenya by {album}-{album_id}
  2. kalau bentuk promise gitu (LIKE_ALBUM, LIKE_ALBUM_SUCCESS) apa yg terjadi?
      1. LIKE_ALBUM di execute langsung, tapi LIKE_ALBUM_SUCCESS setelah workernya jalan

  Read more here: https://quip.com/NQvaAVzeq56S
 */

export const AT = keyMirror({
  TEST_HIT_API: null,
  TEST_HIT_API_SUCCESS: null,
  TEST_HIT_API_FAIL: null,

  ENQUEUE_JOB: null, // push
  DEQUEUE_JOB: null, // pop

  TAKE_WORKER: null,
  PUT_BACK_WORKER: null,

  INCREMENT_SUCCESS_JOB: null,
  INCREMENT_FAILED_JOB: null,
});

export const initialStateOfChannel = {
  availableWorker: 1,
  jobs: [],
  failedJobs: [],
  totalSuccessJob: 0,
  totalFailedJob: 0,
};

export const initialState = {
  channelByChannelName: {},
  results: [],
};

export default function queueReducer(mutableState = initialState, action) {
  const state = Object.assign({}, mutableState);
  switch (action.type) {
    case AT.PUT_BACK_WORKER:
    case AT.TAKE_WORKER:
    case AT.DEQUEUE_JOB:
    case AT.ENQUEUE_JOB:
    case AT.INCREMENT_FAILED_JOB:
    case AT.INCREMENT_SUCCESS_JOB:
      const {
        payload: { channelName },
      } = action;
      state.channelByChannelName[channelName] = channelReducer(
        state.channelByChannelName[channelName],
        action,
      );
      break;

    case AT.TEST_HIT_API_SUCCESS:
      state.results = state.results.concat(action.payload);
      break;
  }

  return state;
}

export function channelReducer(mutableState = initialStateOfChannel, action) {
  const state = Object.assign({}, mutableState);

  switch (action.type) {
    case AT.ENQUEUE_JOB:
      state.jobs = jobReducer(state.jobs, action);
      break;

    case AT.DEQUEUE_JOB:
      state.jobs.shift();
      break;

    case AT.TAKE_WORKER:
      if (state.availableWorker > 0) {
        state.availableWorker -= 1;
      }
      break;

    case AT.PUT_BACK_WORKER:
      state.availableWorker += 1;
      break;

    case AT.INCREMENT_SUCCESS_JOB:
      state.totalSuccessJob += 1;
      break;

    case AT.INCREMENT_FAILED_JOB:
      state.totalFailedJob += 1;
      break;
  }

  return state;
}

export function getOldestSameJob(jobs, job) {
  const sameJob = jobs.find(
    (currJob) =>
      isEqual(job.types, currJob.types) && // types should same
      isEqual(job.options.onQueue, currJob.options.onQueue) && // channelName should same
      has(job.options.onQueueSetting, 'shouldTakeLatest') &&
      job.options.onQueueSetting.shouldTakeLatest,
  );
  return sameJob;
}

export function jobReducer(mutableJobs = [], action) {
  let jobs = mutableJobs;
  switch (action.type) {
    case AT.ENQUEUE_JOB:
      const {
        payload: { job },
      } = action;
      const sameJob = getOldestSameJob(jobs, job);
      if (sameJob) {
        // remove the oldest same job
        jobs = jobs.filter((currJob) => currJob.id !== sameJob.id);
      }
      jobs = jobs.concat(job);
  }

  return jobs;
}

export function testHitAPI(payload, channelName = 'default') {
  return {
    types: [AT.TEST_HIT_API, AT.TEST_HIT_API_SUCCESS, AT.TEST_HIT_API_FAIL],
    payload: payload,
    promise: () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 5000);
      }),
    options: {
      onQueue: channelName,
      onQueueSetting: {
        shouldTakeLatest: true,
      },
    },
  };
}

export function pushJob(job, channelName = 'default') {
  return {
    type: AT.ENQUEUE_JOB,
    payload: {
      channelName,
      job: {
        id: generateGuid(),
        ...job,
      },
    },
  };
}

export function popJob(channelName = 'default') {
  return (dispatch, getState) => {
    const { queue: queueState } = getState();
    const channel = getChannelByChannelName(queueState, channelName);
    const job = getFirstJob(channel);
    return dispatch({
      type: AT.DEQUEUE_JOB,
      payload: {
        channelName,
      },
      result: {
        job,
      },
    });
  };
}

export function takeWorker(channelName = 'default') {
  return (dispatch, getState) => {
    const { queue: queueState } = getState();
    const channel = getChannelByChannelName(queueState, channelName);
    const availableWorker = getAvailableWorker(channel);
    return dispatch({
      type: AT.TAKE_WORKER,
      payload: {
        channelName,
      },
      result: {
        availableWorker: availableWorker,
      },
    });
  };
}

export function putBackWorker(channelName = 'default') {
  return {
    type: AT.PUT_BACK_WORKER,
    payload: {
      channelName,
    },
  };
}

export function incrementSuccessJob(channelName) {
  return {
    type: AT.INCREMENT_SUCCESS_JOB,
    payload: { channelName },
  };
}

export function incrementFailedJob(channelName) {
  return {
    type: AT.INCREMENT_FAILED_JOB,
    payload: { channelName },
  };
}

export function startWorker(channelName = 'default') {
  return (dispatch, getState) => {
    const {
      result: { availableWorker },
    } = dispatch(takeWorker(channelName));
    if (availableWorker) {
      const {
        result: { job },
      } = dispatch(popJob(channelName));
      if (!isEmpty(job)) {
        return dispatch(job)
          .then((result, err) => {
            dispatch(incrementSuccessJob(channelName));
            dispatch(putBackWorker(channelName));
            dispatch(startWorker(channelName));
          })
          .catch((err) => {
            dispatch(incrementFailedJob(channelName));
            dispatch(putBackWorker(channelName));
            dispatch(startWorker(channelName));
          });
      }
      return dispatch(putBackWorker(channelName));
    }
  };
}
