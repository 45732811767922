import keyMirror from 'keymirror';
import produce from 'immer';
import { normalize } from 'normalizr';

import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';
import { AT as AT_AUTH } from 'app-libs/redux_modules/auth';
import { AT as AT_BASKET } from 'app-libs/redux_modules/entity_modules/basket';

import { ApiClient } from 'types';
import { loadFlashSalesAPI, loadFlashSaleItemUserQuantityAPI } from './api';
import {
  flashSaleCollectionSchema,
  flashSaleItemUserQuantitySchema,
} from './schema';
import { FlashSale, FlashSaleItemUserQuantity, FlashSaleItem } from './types';

export const AT = keyMirror({
  LOAD_FLASH_SALES: null,
  LOAD_FLASH_SALES_SUCCESS: null,
  LOAD_FLASH_SALES_FAIL: null,

  LOAD_FLASH_SALE_ITEM_USER_QUANTITY: null,
  LOAD_FLASH_SALE_ITEM_USER_QUANTITY_SUCCESS: null,
  LOAD_FLASH_SALE_ITEM_USER_QUANTITY_FAIL: null,
});

export const initialState = {
  flashSalesById: {},
  flashSalesAsyncState: initialAsyncState,
  flashSaleItemUserQuantitiesById: {},
  flashSaleItemUserQuantitiesAsyncState: initialAsyncState,
};

const flashSaleReducer = produce((draft = initialState, action) => {
  draft.flashSalesAsyncState = asyncStateReducer(
    draft.flashSalesAsyncState,
    action,
    '_FLASH_SALES_',
  );

  draft.flashSaleItemUserQuantitiesAsyncState = asyncStateReducer(
    draft.flashSaleItemUserQuantitiesAsyncState,
    action,
    '_FLASH_SALE_ITEM_USER_QUANTITY_',
  );

  switch (action.type) {
    case AT.LOAD_FLASH_SALES_SUCCESS:
      if (action.result?.entities?.flashSalesById) {
        const { entities } = action.result;
        draft.flashSalesById = entities.flashSalesById;
      }
      break;
    case AT.LOAD_FLASH_SALE_ITEM_USER_QUANTITY_SUCCESS:
      if (action.result?.entities?.flashSaleItemUserQuantitiesById) {
        const { entities } = action.result;
        draft.flashSaleItemUserQuantitiesById = {
          ...draft.flashSaleItemUserQuantitiesById,
          ...entities.flashSaleItemUserQuantitiesById,
        };
      }
      break;
    case AT_BASKET.CHECKOUT_BASKET_SUCCESS:
    case AT_AUTH.LOGIN_SUCCESS:
    case AT_AUTH.LOGOUT_SUCCESS:
      /* remove all user related data here */
      draft.flashSaleItemUserQuantitiesById =
        initialState.flashSaleItemUserQuantitiesById;
      draft.flashSaleItemUserQuantitiesAsyncState =
        initialState.flashSaleItemUserQuantitiesAsyncState;
      draft.flashSalesById = initialState.flashSalesById;
      draft.flashSalesAsyncState = initialState.flashSalesAsyncState;
      break;
    case AT_BASKET.CHECKOUT_BASKET_FAIL:
      draft.flashSalesById = initialState.flashSalesById;
      draft.flashSalesAsyncState = initialState.flashSalesAsyncState;
      break;
    default:
      break;
  }

  return draft;
}, initialState);

export const loadFlashSales = () => {
  return {
    types: [
      AT.LOAD_FLASH_SALES,
      AT.LOAD_FLASH_SALES_SUCCESS,
      AT.LOAD_FLASH_SALES_FAIL,
    ],
    promise: () => loadFlashSalesAPI(),
    options: {
      // @todo (Radit): fix this type for algolia result, deadline 31 Jan 2021
      transformer: (result: TYPEFIXME) => {
        const hits: Array<FlashSale> = result?.content?.hits || [];

        // @note by Radit 10 May 2021
        // quick fix for prioritize flash sale that have stock left first.
        hits.forEach((hit: FlashSale) => {
          hit.flashSaleItems.sort((a: FlashSaleItem, b: FlashSaleItem) => {
            const isAStockExist = a.numStockLeft > 0;
            const isBStockExist = b.numStockLeft > 0;
            if (isAStockExist && !isBStockExist) return -1;
            if (!isAStockExist && isBStockExist) return 1;
            return a.id - b.id;
          });
        });

        const normalized = normalize(hits, flashSaleCollectionSchema);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
};

export const loadFlashSaleItemUserQuantity = (flashSaleItemId: number) => {
  return {
    types: [
      AT.LOAD_FLASH_SALE_ITEM_USER_QUANTITY,
      AT.LOAD_FLASH_SALE_ITEM_USER_QUANTITY_SUCCESS,
      AT.LOAD_FLASH_SALE_ITEM_USER_QUANTITY_FAIL,
    ],
    promise: (client: ApiClient<FlashSaleItemUserQuantity>) =>
      loadFlashSaleItemUserQuantityAPI(client, flashSaleItemId),
    options: {
      transformer: (result: FlashSaleItemUserQuantity) => {
        const normalized = normalize(result, flashSaleItemUserQuantitySchema);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
};

export default flashSaleReducer;
