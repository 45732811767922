import React from 'react';

import { View, Text, StyleSheet, Colors } from '@dkrm/ui-kit-basic';
import { formatPriceId } from 'app-libs/etc';

import cStyles from '@dkrm/general-libs/theme/styles';

import { ProductCardProduct } from '../types';

type ReferralCommissionProps = {
  product: ProductCardProduct;
};

const ReferralCommission: React.FC<ReferralCommissionProps> = ({ product }) => {
  const { referralCommission } = product;
  const commissionAmount = referralCommission?.amount ?? 0;
  const commissionPercentage = referralCommission?.commissionPercentage ?? 0;

  return (
    <View style={s.referralCommissionContainer}>
      <Text theme="body4-white" style={s.commissionAmountContainer}>
        Komisi:{'\n'}
        <Text theme="h6-white">Rp{formatPriceId(commissionAmount)}</Text>
      </Text>
      <Text theme="body4-white" style={s.commissionPercentageContainer}>
        {commissionPercentage}%
      </Text>
    </View>
  );
};

const s = StyleSheet.create({
  referralCommissionContainer: {
    ...cStyles.flexDirRow,
    backgroundColor: Colors.C_TERTIARY_GREEN_2,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  commissionAmountContainer: {
    ...cStyles.flex1,
    ...cStyles.mvm,
    ...cStyles.mhl,
  },

  commissionPercentageContainer: {
    ...cStyles.mrm,
    ...cStyles.pas,
    ...cStyles.brs,
    ...cStyles.flexRightMiddle,
    marginTop: 'auto',
    marginBottom: 'auto',
    borderWidth: 0.7,
    borderColor: Colors.C_WHITE_100,
  },
});

export default ReferralCommission;
