import qs from 'query-string';

// eg. 'www.dekoruma.com/baba?foo=bar'
export function genBaseUtmPropFromPath(path) {
  const pathQueryString = qs.extract(path);
  return genUtmPropFromQueryString(pathQueryString);
}

// eg. '?foo=bar'
export function genUtmPropFromQueryString(pathQueryString) {
  const propertiesToReport = {};
  const properties = pathQueryString && qs.parse(pathQueryString);
  if (properties) {
    Object.entries(properties).map(([key, value]) => {
      if (
        key.startsWith('utm_') ||
        key.startsWith('af_') ||
        key.startsWith('campaign') ||
        key.startsWith('is_retargeting') ||
        key.startsWith('media_source')
      ) {
        propertiesToReport[key] = value;
      }
    });
  }
  return propertiesToReport;
}
