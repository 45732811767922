import React, {
  forwardRef,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import debounce from 'lodash/debounce';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Close } from '@dkrm/icons';
import {
  Colors,
  HorizontalDivider,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import {
  withSearchConsumer,
  withSearchProvider,
} from 'app-libs/AlgoliaAdapter';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import {
  initialState,
  forcedState,
} from 'app-libs/AlgoliaAdapter/searchStates/locationSearchState';
import SearchBar from 'app-libs/components/Search/SearchBar';
import { ShippingAreaFromAlgolia } from 'app-libs/redux_modules/flow_modules/area/types';
import LoadingModal from 'app-libs/components/LoadingModal';

import { useLocationSelectHandler } from './utils';
import { SearchBarRef } from '../types';

interface LocationSearchModalProps {
  actionCloseLocationModal: () => void;
  query: string;
  ref: MutableRefObject<SearchBarRef | null>;
  searchAdapter: {
    setQuery: (query: string) => void;
    search: () => void;
  };
  searchResult: { hits: Array<ShippingAreaFromAlgolia> };
  setQuery: (query: string) => void;
  shouldShowCloseButton: boolean;
}

const LocationSearchModal: React.FC<LocationSearchModalProps> = memo(
  forwardRef(
    (
      {
        actionCloseLocationModal,
        query,
        searchAdapter,
        searchResult,
        setQuery,
        shouldShowCloseButton,
      },
      ref,
    ) => {
      const [
        shouldOpenLoadingModal,
        actionOpenLoadingModal,
        actionCloseLoadingModal,
      ] = useVisibility(false);

      const handleClearInput = useCallback(() => {
        setQuery('');
      }, [setQuery]);

      const handleSearchQueryChange = useCallback(
        (newQuery: string) => {
          setQuery(newQuery);
        },
        [setQuery],
      );

      const {
        handleLocationSelect: _handleLocationSelect,
      } = useLocationSelectHandler();

      const handleLocationSelect = async (
        shippingArea: ShippingAreaFromAlgolia,
      ) => {
        actionOpenLoadingModal();
        await _handleLocationSelect(shippingArea);
        actionCloseLoadingModal();
        actionCloseLocationModal();
      };

      const actionSearchLocation = useMemo(
        () =>
          debounce((_query: string) => {
            searchAdapter.setQuery(_query);
            searchAdapter.search();
          }, 500),
        [searchAdapter],
      );

      useEffect(() => {
        if (query.length > 2) {
          actionSearchLocation(query);
        }
      }, [actionSearchLocation, query]);

      return (
        <>
          {shouldOpenLoadingModal && <LoadingModal />}
          <View style={s.container}>
            {shouldShowCloseButton ? (
              <ButtonWrapper
                onPress={actionCloseLocationModal}
                hoverableViewStyle={{ alignSelf: 'flex-end' }}
              >
                {() => <Close size={24} theme="black" />}
              </ButtonWrapper>
            ) : (
              <View style={cStyles.mvl} />
            )}
            <Text theme="h5" style={cStyles.mbxl}>
              Pilih Kota & Kecamatan
            </Text>
            <SearchBar
              ref={ref}
              value={query}
              autoFocus
              showClearButton
              placeholder="Tulis minimal 3 karakter"
              containerStyle={cStyles.pan}
              onSearchClear={handleClearInput}
              onSearchQueryChange={handleSearchQueryChange}
            />
            <HorizontalDivider color={Colors.C_BLACK_10} style={cStyles.mtxl} />
            {query.length > 2 && (
              <ScrollView>
                {(searchResult?.hits || []).map((location) => (
                  <ButtonWrapper
                    key={location.objectID}
                    style={s.searchResultButton}
                    onPress={() => handleLocationSelect(location)}
                  >
                    {() => (
                      <Text theme="body4-black80">
                        {location?.province}, {location?.city},{' '}
                        {location?.district}
                      </Text>
                    )}
                  </ButtonWrapper>
                ))}
              </ScrollView>
            )}
          </View>
        </>
      );
    },
  ),
);

const s = StyleSheet.create({
  searchResultButton: {
    ...cStyles.bwbm,
    ...cStyles.pvxl,
    borderColor: Colors.C_BLACK_10,
  },
  container: {
    ...cStyles.height100,
    ...cStyles.paxxl,
    ...cStyles.width100,
  },
});

export default withSearchProvider({
  key: 'locationSearch',
  initialState,
  forcedState,
})(withSearchConsumer()(LocationSearchModal));
