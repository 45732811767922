import config from 'config';

import { K_ROUTE_LOGIN } from './routeConstants';

export const K_LOGO_GOOGLE_PLAY = `${config.API_URL_MEDIA_CDN}/homepage/logo/google_play.png?auto=webp`;
export const K_LOGO_APPLE_STORE = `${config.API_URL_MEDIA_CDN}/homepage/logo/apple_store.png?auto=webp`;

export const K_PLAY_STORE_LABEL = 'playStore';
export const K_APPLE_STORE_LABEL = 'appleStore';

// @note(dika) 12 Mar 2025: Current live version
// ios: 2.50
// android: 2.78
// Currently we have no minimum app version for feature UNM Login
const K_DEKORUMA_APP_IOS_MIN_APP_VERSION_FOR_UNM_LOGIN = '';
const K_DEKORUMA_APP_ANDROID_MIN_APP_VERSION_FOR_UNM_LOGIN = '';
export const K_DEKORUMA_APP_MIN_APP_VERSION_CONFIG_FOR_UNM_LOGIN = {
  android: K_DEKORUMA_APP_ANDROID_MIN_APP_VERSION_FOR_UNM_LOGIN,
  ios: K_DEKORUMA_APP_IOS_MIN_APP_VERSION_FOR_UNM_LOGIN,
};
export const K_DEKORUMA_APP_PATHNAMES_SHOULD_FORCE_UPDATE_FOR_UNM_LOGIN = [
  K_ROUTE_LOGIN,
];

export const K_DEKORUMA_APP_IOS_APP_STORE_URL =
  'itms-apps://itunes.apple.com/app/id1294213400';
export const K_DEKORUMA_APP_ANDROID_PLAY_STORE_URL =
  'market://details?id=com.lokinative';
