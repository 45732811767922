import update from 'immutability-helper';
import keyMirror from 'keymirror';
import has from 'lodash/has';
import { normalize } from 'normalizr';

import config from 'config';

import { generateGuid } from 'app-libs/etc';
import { loadProducts } from 'app-libs/redux_modules/flow_modules/search';

import { Schemas } from '../../entities';
import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';
import findAndUpsert from '../../helper_modules/findAndUpsert';
import {
  fetchObject,
  fetchObjects,
} from '../../helper_modules/search/algoliaWrapper';
import { getProductEntities } from '../selectors/entities';
import { getProductOptionEntities } from './selectors';

export const K_ASSEMBLY_ADDON_SLUG = 'jasa-rakit';
export const K_FABRIC_SAMPLE_REQUEST_ADDON_SLUG = 'fabric-request-sample';
export const K_TRADE_IN_ADDON_SLUG = 'trade-in';

export const AT = keyMirror({
  // option
  GET_OPTIONS: null,
  GET_OPTIONS_SUCCESS: null,
  GET_OPTIONS_FAIL: null,

  POST_OPTION: null,
  POST_OPTION_SUCCESS: null,
  POST_OPTION_FAIL: null,

  DELETE_OPTION: null,
  DELETE_OPTION_SUCCESS: null,
  DELETE_OPTION_FAIL: null,

  // option value
  POST_OPTION_VALUE: null,
  POST_OPTION_VALUE_SUCCESS: null,
  POST_OPTION_VALUE_FAIL: null,

  DELETE_OPTION_VALUE: null,
  DELETE_OPTION_VALUE_SUCCESS: null,
  DELETE_OPTION_VALUE_FAIL: null,

  // replacement
  ASSIGN_REPLACEMENT_OPTION: null,
  ASSIGN_REPLACEMENT_OPTION_SUCCESS: null,
  ASSIGN_REPLACEMENT_OPTION_FAIL: null,

  REVOKE_REPLACEMENT_OPTION: null,
  REVOKE_REPLACEMENT_OPTION_SUCCESS: null,
  REVOKE_REPLACEMENT_OPTION_FAIL: null,

  // product option value
  GET_PRODUCT_OPTION_VALUE: null,
  GET_PRODUCT_OPTION_VALUE_SUCCESS: null,
  GET_PRODUCT_OPTION_VALUE_FAIL: null,

  GET_PRODUCT_OPTION_VALUES: null,
  GET_PRODUCT_OPTION_VALUES_SUCCESS: null,
  GET_PRODUCT_OPTION_VALUES_FAIL: null,

  // addon option
  POST_ADDON_OPTION: null,
  POST_ADDON_OPTION_SUCCESS: null,
  POST_ADDON_OPTION_FAIL: null,

  DELETE_ADDON_OPTION: null,
  DELETE_ADDON_OPTION_SUCCESS: null,
  DELETE_ADDON_OPTION_FAIL: null,

  // addon
  GET_ADDONS: null,
  GET_ADDONS_SUCCESS: null,
  GET_ADDONS_FAIL: null,

  // addon-group
  GET_ADDON_GROUPS: null,
  GET_ADDON_GROUPS_SUCCESS: null,
  GET_ADDON_GROUPS_FAIL: null,

  POST_ADDON_GROUP: null,
  POST_ADDON_GROUP_SUCCESS: null,
  POST_ADDON_GROUP_FAIL: null,

  UPDATE_ADDON_GROUP: null,
  UPDATE_ADDON_GROUP_SUCCESS: null,
  UPDATE_ADDON_GROUP_FAIL: null,

  DELETE_ADDON_GROUP: null,
  DELETE_ADDON_GROUP_SUCCESS: null,
  DELETE_ADDON_GROUP_FAIL: null,

  // product-addon-group
  GET_PRODUCT_ADDON_GROUPS: null,
  GET_PRODUCT_ADDON_GROUPS_SUCCESS: null,
  GET_PRODUCT_ADDON_GROUPS_FAIL: null,

  POST_PRODUCT_ADDON_GROUP: null,
  POST_PRODUCT_ADDON_GROUP_SUCCESS: null,
  POST_PRODUCT_ADDON_GROUP_FAIL: null,

  DELETE_PRODUCT_ADDON_GROUP: null,
  DELETE_PRODUCT_ADDON_GROUP_SUCCESS: null,
  DELETE_PRODUCT_ADDON_GROUP_FAIL: null,

  // addon type
  POST_ADDON: null,
  POST_ADDON_SUCCESS: null,
  POST_ADDON_FAIL: null,

  DELETE_ADDON: null,
  DELETE_ADDON_SUCCESS: null,
  DELETE_ADDON_FAIL: null,

  // addon product
  POST_ADDON_PRODUCT: null,
  POST_ADDON_PRODUCT_SUCCESS: null,
  POST_ADDON_PRODUCT_FAIL: null,

  DELETE_ADDON_PRODUCT: null,
  DELETE_ADDON_PRODUCT_SUCCESS: null,
  DELETE_ADDON_PRODUCT_FAIL: null,

  // seletected product option value
  UPDATE_SELECTED_OPTION_VALUES: null,
  UPDATE_SELECTED_ADDON_PRODUCTS: null,
});

// const SAMPLE_PRODUCT_OPTION_VALUE = {
//   objectID: 'UPC-1',
//   options: [{
//     id: 1,
//     name: 'Ukuran Kasur',
//     position: 1,
//     optionValues: [{
//       id: 1,
//       value: 'Single',
//       slug: 'single',
//       replacementId: 26,
//       position: 1,
//       targetProductUpc: 'SIE-154'
//     }, {
//       id: 2,
//       value: 'Double',
//       slug: 'double',
//       replacementId: 23,
//       position: 2,
//       targetProductUpc: 'SIE-152'
//     }]
//   }],
//   addons: [{
//     id: 1,
//     name: 'Divan',
//     addonProducts: [{
//      productAddon: 'DIV-1',
//      additionalPrice: 0,
//      position: 1
//     }, {
//      productAddon: 'DIV-2',
//      additionalPrice: 0,
//      position: 1
//     }, {
//      productAddon: 'DIV-3',
//      additionalPrice: 0,
//      position: 1
//     }]
//   }, {
//     id: 2,
//     name: 'Headboard',
//     addonProducts: [{
//      productAddon: 'HED-1',
//      additionalPrice: 0,
//      position: 1
//     }, {
//      productAddon: 'HED-2',
//      additionalPrice: 0,
//      position: 1
//     }, {
//      productAddon: 'HED-3',
//      additionalPrice: 0,
//      position: 1
//     }]
//   }]
// };

const initialState = {
  product: null,
  productOptionValue: {
    objectID: null,
    options: [],
    addons: [],
  },
  options: {
    lines: [],
    asyncState: initialAsyncState,
  },
  addons: {
    lines: [],
    asyncState: initialAsyncState,
  },
  addonGroups: {
    groups: [],
    productAddonGroups: [],
    asyncState: initialAsyncState,
  },
  selectedProductOptionValues: [],
  selectedAddonProducts: [],
  asyncState: initialAsyncState,
  hasUnsavedChanges: false,
};

export default function productOptionReducer(
  mutableState = initialState,
  action = {},
) {
  const state = mutableState;
  state.addonGroups.groups = addonGroupReducer(
    state.addonGroups.groups,
    action,
  );
  state.addonGroups.productAddonGroups = productAddonGroupReducer(
    state.addonGroups.productAddonGroups,
    action,
  );
  switch (action.type) {
    case AT.POST_OPTION:
    case AT.POST_OPTION_SUCCESS:
    case AT.POST_OPTION_FAIL:
    case AT.GET_OPTIONS_SUCCESS:
    case AT.DELETE_OPTION:
    case AT.POST_OPTION_VALUE:
    case AT.POST_OPTION_VALUE_SUCCESS:
    case AT.DELETE_OPTION_VALUE:
      state.options.lines = optionReducer(state.options.lines, action);
      break;

    case AT.ASSIGN_REPLACEMENT_OPTION:
    case AT.REVOKE_REPLACEMENT_OPTION:
    case AT.GET_PRODUCT_OPTION_VALUE_SUCCESS:
    case AT.GET_PRODUCT_OPTION_VALUE_FAIL:
    case AT.POST_ADDON_OPTION:
    case AT.DELETE_ADDON_OPTION:
    case AT.POST_ADDON_PRODUCT:
    case AT.DELETE_ADDON_PRODUCT:
      state.productOptionValue = productOptionValueReducer(
        state.productOptionValue,
        action,
      );
      break;

    case AT.GET_ADDONS_SUCCESS:
    case AT.POST_ADDON:
    case AT.POST_ADDON_SUCCESS:
    case AT.POST_ADDON_FAIL:
    case AT.DELETE_ADDON:
      state.addons.lines = addonReducer(state.addons.lines, action);
      break;

    case AT.UPDATE_SELECTED_OPTION_VALUES:
      state.selectedProductOptionValues = action.payload;
      break;
    case AT.UPDATE_SELECTED_ADDON_PRODUCTS:
      state.selectedAddonProducts = action.payload;
      break;
  }

  state.options.asyncState = asyncStateReducer(
    state.options.asyncState,
    action,
    '_OPTIONS_',
  );
  state.options.asyncState = asyncStateReducer(
    state.options.asyncState,
    action,
    '_OPTION_',
  );
  state.options.asyncState = asyncStateReducer(
    state.options.asyncState,
    action,
    '_OPTION_VALUE_',
  );
  state.options.asyncState = asyncStateReducer(
    state.options.asyncState,
    action,
    '_ADDON_OPTION_',
  );

  state.addons.asyncState = asyncStateReducer(
    state.options.asyncState,
    action,
    '_ADDONS_',
  );
  state.addons.asyncState = asyncStateReducer(
    state.options.asyncState,
    action,
    '_ADDON_',
  );
  return state;
}

export function optionReducer(mutableState, action) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_OPTIONS_SUCCESS:
      state = action.result;
      break;
    case AT.POST_OPTION:
      state = [
        ...state,
        {
          ...action.payload,
        },
      ];
      break;
    case AT.POST_OPTION_SUCCESS:
      state = findAndUpsert(
        state,
        (item) => item.id === action.payload.id,
        action.result,
      );
      break;
    case AT.DELETE_OPTION: {
      const itemIDToBeRemoved = action.payload.id;
      const indexToBeRemoved = state.findIndex(
        (lineItem) => lineItem.id === itemIDToBeRemoved,
      );
      if (indexToBeRemoved >= 0) {
        state = [
          ...state.slice(0, indexToBeRemoved),
          ...state.slice(indexToBeRemoved + 1),
        ];
      }
      break;
    }
    case AT.POST_OPTION_VALUE:
      state = findAndUpsert(
        state,
        (item) => item.id === action.payload.option,
        {},
        (item) => {
          item.optionValues = [
            ...item.optionValues,
            {
              ...action.payload,
            },
          ];
          return item;
        },
      );
      break;
    case AT.POST_OPTION_VALUE_SUCCESS:
      state = findAndUpsert(
        state,
        (item) => item.id === action.payload.option,
        {},
        (item) => {
          item.optionValues = findAndUpsert(
            item.optionValues,
            (optionValue) => optionValue.id === action.payload.id,
            action.result,
          );
          return item;
        },
      );
      break;
    case AT.DELETE_OPTION_VALUE:
      state = findAndUpsert(
        state,
        (item) => item.id === action.payload.option,
        {},
        (item) => {
          const optionValueIDToBeRemoved = action.payload.id;
          const indexOptionValueToBeRemoved = item.optionValues.findIndex(
            (lineItem) => lineItem.id === optionValueIDToBeRemoved,
          );
          if (indexOptionValueToBeRemoved >= 0) {
            item.optionValues = [
              ...item.optionValues.slice(0, indexOptionValueToBeRemoved),
              ...item.optionValues.slice(indexOptionValueToBeRemoved + 1),
            ];
          }
          return item;
        },
      );
      break;
  }
  return state;
}

export function productOptionValueReducer(mutableState, action) {
  let state = mutableState;
  let optionIndex;
  let addonIndex;

  switch (action.type) {
    case AT.GET_PRODUCT_OPTION_VALUE_SUCCESS:
      state = Object.assign(
        {},
        action.result.entities.productOptions[action.result.content],
        { objectID: action.payload },
      );
      break;
    case AT.GET_PRODUCT_OPTION_VALUE_FAIL:
      state = {
        objectID: action.payload,
        options: [],
        addons: [],
      };
      break;
    case AT.ASSIGN_REPLACEMENT_OPTION:
      optionIndex = state.options
        ? state.options.findIndex((item) => item.id === action.payload.option)
        : -1;
      if (optionIndex < 0) {
        if (!state.options) state.options = [];
        state.options.push({ id: action.payload.option, optionValues: [] });
        optionIndex = state.options.length - 1;
      }
      state.options[optionIndex].optionValues.push({
        id: action.payload.option_value,
        value: action.payload.option_value_value,
        targetProductUpc: action.payload.target_product,
      });
      break;
    case AT.REVOKE_REPLACEMENT_OPTION:
      state.options.forEach((option) => {
        option.optionValues = option.optionValues.filter(
          (item) => item.replacementId !== action.payload,
        );
      });
      state.options = state.options.filter(
        (item) => item.optionValues.length !== 0,
      );
      break;
    case AT.POST_ADDON_OPTION:
      optionIndex = state.options
        ? state.options.findIndex((item) => item.id === action.payload.option)
        : -1;
      if (optionIndex < 0) {
        if (!state.options) state.options = [];
        state.options.push({ id: action.payload.option, optionValues: [] });
        optionIndex = state.options.length - 1;
      }
      state.options[optionIndex].optionValues.push({
        id: action.payload.option_value,
        value: action.payload.option_value_value,
        additionalPrice: action.payload.additionalPrice,
      });
      break;
    case AT.DELETE_ADDON_OPTION:
      state.options.forEach((option) => {
        option.optionValues = option.optionValues.filter(
          (item) => item.addonOptionId !== action.payload,
        );
      });
      state.options = state.options.filter(
        (item) => item.optionValues.length !== 0,
      );
      break;
    case AT.POST_ADDON_PRODUCT:
      addonIndex = state.addons
        ? state.addons.findIndex((item) => item.id === action.payload.addon)
        : -1;
      if (addonIndex < 0) {
        if (!state.addons) state.addons = [];
        state.addons.push({ id: action.payload.addon, addonProducts: [] });
        addonIndex = state.addons.length - 1;
      }
      state.addons[addonIndex].addonProducts.push({
        id: generateGuid(),
        productAddon: action.payload.product_addon,
        additionalPrice: action.payload.additionalPrice,
      });
      break;
    case AT.DELETE_ADDON_PRODUCT:
      state.addons.forEach((addon) => {
        addon.addonProducts = addon.addonProducts.filter(
          (item) => item.id !== action.payload,
        );
      });
      state.addons = state.addons.filter(
        (item) => item.addonProducts.length !== 0,
      );
  }
  return state;
}

export function addonReducer(mutableState, action) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_ADDONS_SUCCESS:
      state = action.result;
      break;
    case AT.POST_ADDON:
      state = [
        ...state,
        {
          ...action.payload,
        },
      ];
      break;
    case AT.POST_ADDON_SUCCESS:
      state = findAndUpsert(
        state,
        (item) => item.id === action.payload.id,
        action.result,
      );
      break;
    case AT.DELETE_ADDON: {
      const itemIDToBeRemoved = action.payload.id;
      const indexToBeRemoved = state.findIndex(
        (lineItem) => lineItem.id === itemIDToBeRemoved,
      );
      if (indexToBeRemoved >= 0) {
        state = [
          ...state.slice(0, indexToBeRemoved),
          ...state.slice(indexToBeRemoved + 1),
        ];
      }
      break;
    }
  }
  return state;
}

export function addonGroupReducer(mutableState, action) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_ADDON_GROUPS_SUCCESS: {
      state = action.result;
      break;
    }
    case AT.POST_ADDON_GROUP_SUCCESS: {
      state = update(state, { $push: [action.result] });
      break;
    }
    case AT.UPDATE_ADDON_GROUP_SUCCESS: {
      const updatedAddonGroupName = action.payload.name;
      const updatedAddonGroupIdInState = state.findIndex(
        (group) => group.name === updatedAddonGroupName,
      );
      if (updatedAddonGroupIdInState >= 0) {
        state = update(state, {
          $splice: [[updatedAddonGroupIdInState, 1, action.result]],
        });
      }
      break;
    }
    case AT.DELETE_ADDON_GROUP_SUCCESS: {
      const deletedAddonGroupName = action.payload.name;
      const deletedAddonGroupIdInState = state.findIndex(
        (group) => group.name === deletedAddonGroupName,
      );
      state = update(state, {
        $splice: [[deletedAddonGroupIdInState, 1]],
      });
      break;
    }
  }
  return state;
}

export function productAddonGroupReducer(mutableState, action) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_PRODUCT_ADDON_GROUPS_SUCCESS: {
      state = action.result;
      break;
    }
    case AT.POST_PRODUCT_ADDON_GROUP_SUCCESS: {
      state = update(state, { $push: [action.result] });
      break;
    }
    case AT.DELETE_PRODUCT_ADDON_GROUP_SUCCESS: {
      const deletedProductAddonGroupName = action.payload.addonGroup.name;
      const deletedProductAddonGroupIdInState = state.findIndex(
        (group) => group.name === deletedProductAddonGroupName,
      );
      state = update(state, {
        $splice: [[deletedProductAddonGroupIdInState, 1]],
      });
      break;
    }
  }
  return state;
}

export function loadOptions() {
  return {
    types: [AT.GET_OPTIONS, AT.GET_OPTIONS_SUCCESS, AT.GET_OPTIONS_FAIL],
    payload: {},
    promise: (client) =>
      client.get(`${config.API_URL_GALACTUS}/options?format=json`, {
        params: {},
      }),
  };
}

export function createOptionAndReload(option) {
  return (dispatch) =>
    dispatch(createOption(option)).then(() => dispatch(loadOptions()));
}

export function createOptionValueAndReload(optionValue) {
  return (dispatch) =>
    dispatch(createOptionValue(optionValue)).then(() =>
      dispatch(loadOptions()),
    );
}

export function deleteOptionAndReload(option) {
  return (dispatch) =>
    dispatch(deleteOption(option)).then(() => dispatch(loadOptions()));
}

export function deleteOptionValueAndReload(optionValue) {
  return (dispatch) =>
    dispatch(deleteOptionValue(optionValue)).then(() =>
      dispatch(loadOptions()),
    );
}

export function createOption(option) {
  return {
    types: [AT.POST_OPTION, AT.POST_OPTION_SUCCESS, AT.POST_OPTION_FAIL],
    payload: {
      ...option,
      id: generateGuid(),
    },
    promise: (client) =>
      client.post(`${config.API_URL_GALACTUS}/options?format=json`, {
        data: option,
      }),
  };
}

export function createOptionValue(optionValue) {
  return {
    types: [
      AT.POST_OPTION_VALUE,
      AT.POST_OPTION_VALUE_SUCCESS,
      AT.POST_OPTION_VALUE_FAIL,
    ],
    payload: {
      ...optionValue,
      id: generateGuid(),
    },
    promise: (client) =>
      client.post(`${config.API_URL_GALACTUS}/optionvalues?format=json`, {
        data: optionValue,
      }),
  };
}

export function deleteOption(option) {
  return {
    types: [AT.DELETE_OPTION, AT.DELETE_OPTION_SUCCESS, AT.DELETE_OPTION_FAIL],
    payload: option,
    promise: (client) =>
      client.del(`${config.API_URL_GALACTUS}/options/${option.id}?format=json`),
  };
}

export function deleteOptionValue(optionValue) {
  return {
    types: [
      AT.DELETE_OPTION_VALUE,
      AT.DELETE_OPTION_VALUE_SUCCESS,
      AT.DELETE_OPTION_VALUE_FAIL,
    ],
    payload: optionValue,
    promise: (client) =>
      client.del(
        `${config.API_URL_GALACTUS}/optionvalues/${optionValue.id}?format=json`,
      ),
  };
}

export function assignProductReplacementOptionAndReload(replacementOption) {
  return (dispatch, getState) =>
    dispatch(assignProductReplacementOption(replacementOption)).then(() => {
      const { productOptionValue } = getState().productOption;
      return dispatch(loadProductOptionValue(productOptionValue.objectID));
    });
}

export function assignProductReplacementOption(replacementOption) {
  return {
    types: [
      AT.ASSIGN_REPLACEMENT_OPTION,
      AT.ASSIGN_REPLACEMENT_OPTION_SUCCESS,
      AT.ASSIGN_REPLACEMENT_OPTION_FAIL,
    ],
    payload: replacementOption,
    promise: (client) =>
      client.post(
        `${config.API_URL_GALACTUS}/replacements/assign?format=json`,
        {
          data: replacementOption,
        },
      ),
  };
}

export function unassignProductReplacementOptionAndReload(replacementOptionId) {
  return (dispatch, getState) =>
    dispatch(unassignProductReplacementOption(replacementOptionId)).then(() => {
      const { productOptionValue } = getState().productOption;
      return dispatch(loadProductOptionValue(productOptionValue.objectID));
    });
}

export function unassignProductReplacementOption(replacementOptionId) {
  return {
    types: [
      AT.REVOKE_REPLACEMENT_OPTION,
      AT.REVOKE_REPLACEMENT_OPTION_SUCCESS,
      AT.REVOKE_REPLACEMENT_OPTION_FAIL,
    ],
    payload: replacementOptionId,
    promise: (client) =>
      client.del(
        `${config.API_URL_GALACTUS}/replacements/revoke/${replacementOptionId}?format=json`,
      ),
  };
}

export function loadProductOptionValue(productUpc, callback) {
  return {
    types: [
      AT.GET_PRODUCT_OPTION_VALUE,
      AT.GET_PRODUCT_OPTION_VALUE_SUCCESS,
      AT.GET_PRODUCT_OPTION_VALUE_FAIL,
    ],
    payload: productUpc,
    promise: async () => {
      const productOptionValue = await fetchObject(
        productUpc,
        '*',
        config.ALGOLIA_PRODUCT_OPTION_VALUE_INDEX_NAME,
      );
      return productOptionValue;
    },
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.PRODUCT_OPTION);
        const newResult = {
          content: normalized.result,
          entities: normalized.entities,
        };
        return newResult;
      },
      callback,
    },
  };
}

export function loadProductOptionValues(productsUpc, callback) {
  return (dispatch, getState) => {
    const state = getState();
    const productOptionEntities = getProductOptionEntities(state);

    const additionalProductIdsToLoad = [];
    productsUpc.forEach((upc) => {
      if (!(upc in productOptionEntities)) {
        additionalProductIdsToLoad.push(upc);
      }
    });

    if (additionalProductIdsToLoad.length > 0) {
      return dispatch({
        types: [
          AT.GET_PRODUCT_OPTION_VALUES,
          AT.GET_PRODUCT_OPTION_VALUES_SUCCESS,
          AT.GET_PRODUCT_OPTION_VALUES_FAIL,
        ],
        payload: productsUpc,
        promise: async () => {
          const productOptionValues = await fetchObjects(
            additionalProductIdsToLoad,
            config.ALGOLIA_PRODUCT_OPTION_VALUE_INDEX_NAME,
          );
          return productOptionValues;
        },
        options: {
          transformer: (result) => {
            const cleanedResults = result.results.filter((_result) =>
              Boolean(_result),
            );
            const normalized = normalize(
              cleanedResults,
              Schemas.PRODUCT_OPTION_COLLECTION,
            );
            const newResult = {
              content: normalized.result,
              entities: normalized.entities,
            };
            return newResult;
          },
          callback,
        },
      });
    }

    return null;
  };
}

export function createAddonOptionAndReload(addonOption) {
  return (dispatch, getState) =>
    dispatch(createAddonOption(addonOption)).then(() => {
      const { productOptionValue } = getState().productOption;
      return dispatch(loadProductOptionValue(productOptionValue.objectID));
    });
}

export function createAddonOption(addonOption) {
  return {
    types: [
      AT.POST_ADDON_OPTION,
      AT.POST_ADDON_OPTION_SUCCESS,
      AT.POST_ADDON_OPTION_FAIL,
    ],
    payload: addonOption,
    promise: (client) =>
      client.post(
        `${config.API_URL_GALACTUS}/addonoptions/create?format=json`,
        {
          data: addonOption,
        },
      ),
  };
}

export function removeAddonOptionAndReload(addonOptionId) {
  return (dispatch, getState) =>
    dispatch(removeAddonOption(addonOptionId)).then(() => {
      const { productOptionValue } = getState().productOption;
      return dispatch(loadProductOptionValue(productOptionValue.objectID));
    });
}

export function removeAddonOption(addonOptionId) {
  return {
    types: [
      AT.DELETE_ADDON_OPTION,
      AT.DELETE_ADDON_OPTION_SUCCESS,
      AT.DELETE_ADDON_OPTION_FAIL,
    ],
    payload: addonOptionId,
    promise: (client) =>
      client.del(
        `${config.API_URL_GALACTUS}/addonoptions/destroy/${addonOptionId}?format=json`,
      ),
  };
}

// addon
export function loadAddons() {
  return {
    types: [AT.GET_ADDONS, AT.GET_ADDONS_SUCCESS, AT.GET_ADDONS_FAIL],
    payload: {},
    promise: (client) =>
      client.get(`${config.API_URL_GALACTUS}/addons?format=json`, {
        params: {},
      }),
  };
}

export function createAddonAndReload(addon) {
  return (dispatch) =>
    dispatch(createAddon(addon)).then(() => dispatch(loadAddons()));
}

export function createAddon(addon) {
  return {
    types: [AT.POST_ADDON, AT.POST_ADDON_SUCCESS, AT.POST_ADDON_FAIL],
    payload: {
      ...addon,
      id: generateGuid(),
    },
    promise: (client) =>
      client.post(`${config.API_URL_GALACTUS}/addons?format=json`, {
        data: addon,
      }),
  };
}

export function deleteAddonAndReload(addon) {
  return (dispatch) =>
    dispatch(deleteAddon(addon)).then(() => dispatch(loadAddons()));
}

export function deleteAddon(addon) {
  return {
    types: [AT.DELETE_ADDON, AT.DELETE_ADDON_SUCCESS, AT.DELETE_ADDON_FAIL],
    payload: addon,
    promise: (client) =>
      client.del(`${config.API_URL_GALACTUS}/addons/${addon.id}?format=json`),
  };
}

// addon product
export function createAddonProductAndReload(addonProduct) {
  return (dispatch, getState) =>
    dispatch(createAddonProduct(addonProduct)).then(() => {
      const { productOptionValue } = getState().productOption;
      return dispatch(loadProductOptionValue(productOptionValue.objectID));
    });
}

export function createAddonProduct(addonProduct) {
  return {
    types: [
      AT.POST_ADDON_PRODUCT,
      AT.POST_ADDON_PRODUCT_SUCCESS,
      AT.POST_ADDON_PRODUCT_FAIL,
    ],
    payload: addonProduct,
    promise: (client) =>
      client.post(`${config.API_URL_GALACTUS}/addonproducts?format=json`, {
        data: addonProduct,
      }),
  };
}

export function deleteAddonProductAndReload(addonProductId) {
  return (dispatch, getState) =>
    dispatch(deleteAddonProduct(addonProductId)).then(() => {
      const { productOptionValue } = getState().productOption;
      return dispatch(loadProductOptionValue(productOptionValue.objectID));
    });
}

export function deleteAddonProduct(addonProductId) {
  return {
    types: [
      AT.DELETE_ADDON_PRODUCT,
      AT.DELETE_ADDON_PRODUCT_SUCCESS,
      AT.DELETE_ADDON_PRODUCT_FAIL,
    ],
    payload: addonProductId,
    promise: (client) =>
      client.del(
        `${config.API_URL_GALACTUS}/addonproducts/${addonProductId}?format=json`,
      ),
  };
}

/*
 *  - PairsOptionValue is an object with option_id as a key, and option_value_id as value
 *    structure:
 *    {
 *      [option_id]: [option_value_id]
 *    }
 *  - pairsOptionValue is store in URL with param name 'productOption'
 *
 */
export function getPairsOptionValueFromQuery(query) {
  let pairsOptionValue = has(query, 'productOption') ? query.productOption : {};
  try {
    pairsOptionValue = JSON.parse(pairsOptionValue);
  } catch (err) {
    pairsOptionValue = {};
  }
  return pairsOptionValue;
}

/*
 * - serializePairsOptionValue used for change structure pairsOptionValue to colection of optionValue
 * - structure of optionValue is
 *   {
 *     optionId,
 *     optionName,
 *     optionValueId,
 *     optionValueName
 *   }
 */
export function serializePairsOptionValue(
  pairsOptionValue,
  productOptionValue,
) {
  const optionValues = [];
  for (const optionId in pairsOptionValue) {
    if (pairsOptionValue[optionId]) {
      const optionValueId = pairsOptionValue[optionId];
      const option = productOptionValue.options.find(
        (item) => item.id === parseInt(optionId, 0),
      );
      if (!option) continue;
      const optionValue = option.optionValues.find(
        (item) => item.id === parseInt(optionValueId, 0),
      );
      if (option && optionValue) {
        optionValues.push({
          optionId: option.id,
          optionName: option.name,
          optionValueId: optionValue.id,
          optionValueName: optionValue.value,
        });
      }
    }
  }
  return optionValues;
}

export function deserializeAddonProducts(addonProducts = []) {
  const productAddons = {};
  addonProducts.map((addonProduct) => {
    productAddons[addonProduct.addonId] = {
      selectedProductUpc: addonProduct.product.objectID,
      pairsOptionValue: deserializeOptionValues(addonProduct.options),
    };
  });
  return productAddons;
}

/*
 * deserializeOptionValue used for change structure of collection optionValue to an object of pairsOptionValue
 */
export function deserializeOptionValues(optionValues = []) {
  const pairsOptionValue = {};
  optionValues.map((optionValue) => {
    pairsOptionValue[optionValue.optionId] = optionValue.optionValueId;
  });
  return pairsOptionValue;
}

/*
 * change optionValues structure to pairsOptionValue return as params
 */
export function convertToQueryFromOptionValues(optionValues) {
  const pairsOptionValue = deserializeOptionValues(optionValues);
  return {
    productOption: JSON.stringify(pairsOptionValue),
  };
}

export function convertToQueryFromAddonProducts(addonProducts) {
  const productAddons = deserializeAddonProducts(addonProducts);
  return {
    productAddon: JSON.stringify(productAddons),
  };
}

export function updateSelectedOptionValues(selectedOptionValues) {
  return {
    type: AT.UPDATE_SELECTED_OPTION_VALUES,
    payload: selectedOptionValues,
  };
}

export function updateSelectedAddonProducts(selectedAddonProducts) {
  return {
    type: AT.UPDATE_SELECTED_ADDON_PRODUCTS,
    payload: selectedAddonProducts,
  };
}

export function updateQueryPairsOptionValue(
  contextRouter,
  location,
  pairsOptionValue,
  nextPath = undefined,
) {
  if (__CLIENT__) {
    let currentPath = location.pathname;
    if (nextPath) {
      currentPath = nextPath;
    }
    const existingQueryParams = location.query;
    const newQueryParams = Object.assign({}, existingQueryParams, {
      noscroll: true,
      productOption: JSON.stringify(pairsOptionValue),
    });
    contextRouter.push({ pathname: currentPath, query: newQueryParams });
  }
}

/** *******************
 *
 * - if option already have optionValue that has been selected, use it as initial value.
 * - if option has optionValue with replacementProductUpc with current product page, use it as initial value
 * - other than that, use first optionValue as initialValue
 */
export function getInitialValueOptionValue(
  option,
  pairsOptionValue = {},
  currentProductUpc = '',
) {
  if (pairsOptionValue && option.id in pairsOptionValue) {
    return pairsOptionValue[option.id];
  }

  const optionValueWithCurrentProduct = option.optionValues.find(
    (optionValue) => optionValue.targetProductUpc === currentProductUpc,
  );
  if (optionValueWithCurrentProduct) {
    return optionValueWithCurrentProduct.id;
  }
  const firstOptionValueWithoutTargetProductUpc = option.optionValues.find(
    (optionValue) => !has(optionValue, 'targetProductUpc'),
  );

  if (firstOptionValueWithoutTargetProductUpc) {
    return firstOptionValueWithoutTargetProductUpc.id;
  }
  return option.optionValues[0].id;
}

export function refreshPairsOptionValue(
  pairsOptionValue,
  productOptionValue,
  productUpc,
) {
  const newPairsOptionValue = {};
  productOptionValue.options.map((option) => {
    const initialValueOptionValue = getInitialValueOptionValue(
      option,
      pairsOptionValue,
      productUpc,
    );
    newPairsOptionValue[option.id] = initialValueOptionValue;
  });
  return newPairsOptionValue;
}

/** **********
 *
 * update selectedProductOptionValues in redux
 * base on param in url and initial value of each product option
 */
export function initializeSelectedOptionValues(query) {
  return (dispatch, getState) => {
    const currentProductUpc = getState().product.objectID;

    const prevPairsOptionValue = getPairsOptionValueFromQuery(query);
    const { productOptionValue } = getState().productOption;

    const currentPairsOptionValue = refreshPairsOptionValue(
      prevPairsOptionValue,
      productOptionValue,
      currentProductUpc,
    );
    const selectedProductOptionValues = serializePairsOptionValue(
      currentPairsOptionValue,
      productOptionValue,
    );
    dispatch(updateSelectedOptionValues(selectedProductOptionValues));
  };
}

export function loadProductAddons(productOptionValue, callback) {
  return (dispatch, getState) => {
    const state = getState();
    const productEntities = getProductEntities(state);

    const additionalProductIdsToLoad = [];
    productOptionValue.addons.map((addon) => {
      addon.addonProducts.map((addonProduct) => {
        const id = addonProduct.productAddon;

        // only load addon product if its upc not in entities already
        if (!(id in productEntities)) {
          additionalProductIdsToLoad.push(id);
        }
      });
    });
    if (additionalProductIdsToLoad.length) {
      return dispatch(
        loadProductOptionValues(additionalProductIdsToLoad, () =>
          dispatch(
            loadProducts(
              additionalProductIdsToLoad,
              config.ALGOLIA_DEFAULT_INDEX_NAME,
              callback,
            ),
          ),
        ),
      );
    }
  };
}

export function loadProductAddonsByProductOptions(productOptions) {
  return (dispatch, getState) => {
    const state = getState();
    const productEntities = getProductEntities(state);

    const additionalProductIdsToLoad = productOptions.reduce(
      (res, productOption) => {
        productOption.addons.forEach((addon) => {
          const addonProductsNotYetLoaded = addon.addonProducts.filter(
            (addonProduct) => !(addonProduct.productAddon in productEntities),
          );
          const addonProductUpcs = addonProductsNotYetLoaded.map(
            (addonProduct) => addonProduct.productAddon,
          );
          res = [...res, ...addonProductUpcs];
        });

        return res;
      },
      [],
    );

    if (additionalProductIdsToLoad.length) {
      return dispatch(loadProducts(additionalProductIdsToLoad));
    }

    return null;
  };
}

export function getProductAddonsQuery(query) {
  let productAddons = has(query, 'productAddon') ? query.productAddon : {};
  try {
    productAddons = JSON.parse(productAddons);
  } catch (err) {
    productAddons = {};
  }
  return productAddons;
}

export function updateQueryProductAddons(
  contextRouter,
  location,
  productAddons,
) {
  if (__CLIENT__) {
    const currentPath = location.pathname;
    const existingQueryParams = location.query;
    const newQueryParams = Object.assign({}, existingQueryParams, {
      noscroll: true,
      productAddon: JSON.stringify(productAddons),
    });
    contextRouter.push({ pathname: currentPath, query: newQueryParams });
  }
}

export function serializeAddonProducts(
  addonProducts,
  productOptionValue,
  productOptionValues,
  products,
) {
  const serializedAddonProducts = [];
  for (const addonId in addonProducts) {
    if (addonProducts[addonId]) {
      const addon = productOptionValue.addons.find(
        (item) => item.id === parseInt(addonId, 0),
      );
      if (!addon) continue;
      const addonProduct = addon.addonProducts.find(
        (item) =>
          item.productAddon === addonProducts[addonId].selectedProductUpc,
      );
      if (addon && addonProduct) {
        const currentUpc = addonProduct.productAddon;
        let serializedAddonProduct = {};
        if (productOptionValues[currentUpc]) {
          const newPairsOptionValue = refreshPairsOptionValue(
            addonProducts[addonId].pairsOptionValue,
            productOptionValues[currentUpc],
            currentUpc,
          );
          serializedAddonProduct = {
            addonId: addon.id,
            addonName: addon.name,
            addonSlug: addon.slug,
            productUpc: currentUpc,
            product: products[currentUpc],
            optionValues: serializePairsOptionValue(
              newPairsOptionValue,
              productOptionValues[currentUpc],
            ),
          };
        } else {
          serializedAddonProduct = {
            addonId: addon.id,
            addonName: addon.name,
            addonSlug: addon.slug,
            productUpc: currentUpc,
            product: products[currentUpc],
            optionValues: [],
          };
        }
        serializedAddonProducts.push(serializedAddonProduct);
      }
    }
  }
  return serializedAddonProducts;
}

/** **********
 *
 * update selectedAddonProducts in redux
 * base on param in url and initial value of each product option
 */
export function initializeSelectedAddonProducts(query) {
  return (dispatch, getState) => {
    const currentProductUpc = getState().product.objectID;

    const oldAddonProducts = getProductAddonsQuery(query);

    const { productOptionValue } = getState().productOption;
    const selectedProductAddons = serializeAddonProducts(
      oldAddonProducts,
      productOptionValue,
      getState().entities.productOptions,
      getState().entities.products,
    );
    dispatch(updateSelectedAddonProducts(selectedProductAddons));
  };
}

export function getAddonIdByName(productOptionValue, addonName) {
  const resultAddon = productOptionValue.addons.find(
    (addon) => addon.name === addonName,
  );
  if (resultAddon) return resultAddon.id;
  return null;
}

export function getAddonBySlug(addons, slug) {
  return addons.find((addon) => addon.slug === slug);
}

export function isContainAddon(addons = [], addonSlug) {
  return getAddonBySlug(addons, addonSlug);
}

export function isOptionValuesEqual(
  optionValues = [],
  anotherOptionValues = [],
) {
  if (optionValues.length !== anotherOptionValues.length) return false;
  optionValues.map((optionValue) => {
    const match = anotherOptionValues.find(
      (anotherOptionValue) =>
        anotherOptionValue.optionValueId === optionValue.optionValueId,
    );
    if (!match) return false;
  });
  return true;
}

export function getSelectedAddonProductUpc(addon, location) {
  const existingQueryParams = location.query;
  const productAddons = getProductAddonsQuery(existingQueryParams);
  if (has(productAddons, addon.id)) {
    return productAddons[addon.id].selectedProductUpc;
  }
  return null;
}

export function getSelectedAddonProduct(addon, location) {
  const selectedAddonProductUpc = getSelectedAddonProductUpc(addon, location);
  const selectedAddonProduct = addon.addonProducts.find(
    (addonProduct) => addonProduct.product.objectID === selectedAddonProductUpc,
  );
  return selectedAddonProduct ? selectedAddonProduct.product : null;
}

export function isAddonChanged(addon, product, location) {
  const { addonProducts, quantityInTheBasket } = product;
  if (!quantityInTheBasket) return false;
  const selectedAddonProduct = getSelectedAddonProduct(addon, location);

  const arrAddonProducts = addonProducts || [];
  const addonProductInBasket = arrAddonProducts.find(
    (currAddonProduct) => currAddonProduct.addonId === addon.id,
  );
  if (Boolean(addonProductInBasket) !== Boolean(selectedAddonProduct))
    return true;
  if (addonProductInBasket && selectedAddonProduct) {
    if (addonProductInBasket.product.objectID !== selectedAddonProduct.objectID)
      return true;
  }
  return false;
}

/** Load Addon Group */
export function loadAddonGroups() {
  return {
    types: [
      AT.GET_ADDON_GROUPS,
      AT.GET_ADDON_GROUPS_SUCCESS,
      AT.GET_ADDON_GROUPS_FAIL,
    ],
    promise: (client) => client.get(`${config.API_URL_GALACTUS}/addon-groups`),
  };
}

export function createAddonGroup(name) {
  return {
    types: [
      AT.POST_ADDON_GROUP,
      AT.POST_ADDON_GROUP_SUCCESS,
      AT.POST_ADDON_GROUP_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_GALACTUS}/addon-groups`, {
        data: { name },
      }),
  };
}

/**
 * [SAMPLE] addonGroup: {
    'name': pag_name,
    'addons': [
        {
            'product': 'SKU-1',
            'ordering': 2
        },
        {
            'product': 'SKU-2',
        }
    ]
  }
*/
export function updateAddonGroup(name, addonGroup) {
  return {
    types: [
      AT.UPDATE_ADDON_GROUP,
      AT.UPDATE_ADDON_GROUP_SUCCESS,
      AT.UPDATE_ADDON_GROUP_FAIL,
    ],
    payload: {
      name,
      addonGroup,
    },
    promise: (client) =>
      client.put(`${config.API_URL_GALACTUS}/addon-groups/${name}`, {
        data: addonGroup,
      }),
  };
}

export function deleteAddonGroup(name) {
  return {
    types: [
      AT.DELETE_ADDON_GROUP,
      AT.DELETE_ADDON_GROUP_SUCCESS,
      AT.DELETE_ADDON_GROUP_FAIL,
    ],
    payload: {
      name,
    },
    promise: (client) =>
      client.del(`${config.API_URL_GALACTUS}/addon-groups/${name}`),
  };
}

export function deleteAddonFromGroup(upc, addonGroup) {
  const upcIndexInAddonGroup = addonGroup.addons.findIndex(
    (addon) => addon.product === upc,
  );
  const newAddonGroup = update(addonGroup, {
    addons: {
      $splice: [[upcIndexInAddonGroup, 1]],
    },
  });
  return updateAddonGroup(addonGroup.name, newAddonGroup);
}

/** Product Addon Group */
export function loadProductAddonGroups(upc, addonType) {
  return {
    types: [
      AT.GET_PRODUCT_ADDON_GROUPS,
      AT.GET_PRODUCT_ADDON_GROUPS_SUCCESS,
      AT.GET_PRODUCT_ADDON_GROUPS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GALACTUS}/products/${upc}/addon-types/${addonType}/addon-groups`,
      ),
  };
}

export function createProductAddonGroup(upc, addonType, addonGroup) {
  return {
    types: [
      AT.POST_PRODUCT_ADDON_GROUP,
      AT.POST_PRODUCT_ADDON_GROUP_SUCCESS,
      AT.POST_PRODUCT_ADDON_GROUP_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GALACTUS}/products/${upc}/addon-types/${addonType}/addon-groups`,
        {
          data: { addonGroup: addonGroup.name },
        },
      ),
  };
}

export function deleteProductAddonGroup(upc, addonType, addonGroup) {
  return {
    types: [
      AT.DELETE_PRODUCT_ADDON_GROUP,
      AT.DELETE_PRODUCT_ADDON_GROUP_SUCCESS,
      AT.DELETE_PRODUCT_ADDON_GROUP_FAIL,
    ],
    payload: {
      upc,
      addonType,
      addonGroup,
    },
    promise: (client) =>
      client.del(
        `${config.API_URL_GALACTUS}/products/${upc}/addon-types/${addonType}/addon-groups/${addonGroup.name}`,
      ),
  };
}
