import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet } from '@dkrm/ui-kit-basic';

export default StyleSheet.create({
  popupHeading: {
    ...cStyles.flexDirRow,
    ...cStyles.bwbxs,
    ...cStyles.bcm,
    alignItems: 'center',
  },
  headingContainer: {
    ...cStyles.flex1,
    ...cStyles.flexMiddle,
  },
  popupContent: {
    ...cStyles.flex1,
  },
  buttonStyle: {
    ...cStyles.brs,
    ...cStyles.flexMiddle,
  },
});
