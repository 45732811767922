/* global ga, dataLayer, ttq */
import isEmpty from 'lodash/isEmpty';

import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

import { generateGuid } from 'app-libs/etc';
import amplitude from 'app-libs/libs/amplitude';
import cio from 'app-libs/libs/customerio';
import fbApp from 'app-libs/libs/fbApp';
import fbCapi from 'app-libs/libs/fbCapi';
import fbPixel from 'app-libs/libs/fbPixel';
import ga4 from 'app-libs/libs/ga4';
import pinterestCapi from 'app-libs/libs/pinterestCapi';
import pinterestTag from 'app-libs/libs/pinterestTag';
import wv from 'app-libs/libs/webView';
import * as AmpSer from 'app-libs/serializers/amplitude';
import * as CriteoSer from 'app-libs/serializers/criteo';
import * as CioSer from 'app-libs/serializers/customerio';
import * as DataLayerSer from 'app-libs/serializers/dataLayer';
import * as FbSer from 'app-libs/serializers/fb';
import * as FbAppSer from 'app-libs/serializers/fbApp';
import * as FbCapiSer from 'app-libs/serializers/fbCapi';
import * as GaSer from 'app-libs/serializers/ga';
import * as Ga4Ser from 'app-libs/serializers/ga4';
import * as PinterestSer from 'app-libs/serializers/pinterest';
import * as TiktokSer from 'app-libs/serializers/tiktok';
import * as WvSer from 'app-libs/serializers/webView';

import {
  K_DEKORUMA_FACEBOOK_PIXEL_ID,
  K_DNB_FACEBOOK_PIXEL_ID,
  K_TRACK_CLICKED_UPSELL_ITEMS,
} from 'constants/trackingConstants';

import {
  sendEvent as sendCriteoEvent,
  sendEventDNB as sendCriteoEventDNB,
} from './criteo';

function ttqTrackWithTryCatch(name, trackerData) {
  try {
    ttq.track(name, trackerData);
  } catch (err) {
    console.error('ttq.track error: ', err);
  }
}

function ttqIdentifyWithTryCatch(trackerData) {
  try {
    ttq.identify(trackerData);
  } catch (err) {
    console.error('ttq.identify error: ', err);
  }
}

export function trackIdentifyUser(user) {
  fbCapi.identify(user);
  pinterestCapi.identify(user);
  if (user) {
    const { email, username, firstName, lastName, dateJoined } = user;
    amplitude.setUserId(username);
    if (!isEmpty(email)) {
      wv.setUserLoggedIn(user);
      cio.identify(CioSer.genUser(email, firstName, lastName, dateJoined));
      fbPixel.setEmail(email);
      ttqIdentifyWithTryCatch({ email });
    }
  }
}

export function trackClickedSomaImage() {
  amplitude.logEvent('clicked_soma_image_ENGAGEMENT');
}

export function trackClickedFlashSaleItemCard(product) {
  amplitude.logEvent('clicked_flash_sale_item_card_ENGAGEMENT', product);
}

export function trackClickedFlashSaleRibbon() {
  amplitude.logEvent('clicked_flash_sale_ribbon_ENGAGEMENT');
}

export function trackClickedViewFlashSaleDetailButton() {
  amplitude.logEvent('clicked_view_flash_sale_detail_ENGAGEMENT');
}

export function trackClickedOrderTrackingButton() {
  amplitude.logEvent('clicked_track_order_ENGAGEMENT');
}

export function trackViewedOrderListPage() {
  amplitude.logEvent('viewed_order_list_ENGAGEMENT');
}

export function trackViewedOrderDetailPage() {
  amplitude.logEvent('viewed_order_detail_ENGAGEMENT');
}

export function trackViewedFlashSaleDetailPage() {
  amplitude.logEvent('viewed_flash_sale_detail_ENGAGEMENT');
}

export function trackViewedNotificationList() {
  amplitude.logEvent('viewed_inbox_list_ENGAGEMENT');
  cio.track('viewed_inbox_list_ENGAGEMENT');
}

export function trackClickedNotificationItem(notificationItem) {
  amplitude.logEvent(
    'clicked_inbox_item_ENGAGEMENT',
    AmpSer.genNotificationItem(notificationItem),
  );
  cio.track(
    'clicked_inbox_item_ENGAGEMENT',
    CioSer.genNotificationItem(notificationItem),
  );
}

export function trackLogoutUser() {
  wv.setUserLoggedOut();
  amplitude.setUserId(null);
  cio.identify({});
  fbPixel.setEmail(null);
  ttqIdentifyWithTryCatch(null);
  fbCapi.identify();
  pinterestCapi.identify();
}

export function trackSignedInUser(user, usingFacebookAuth = false) {
  const { dateJoined } = user;
  amplitude.logEvent(
    'signed_in_ENGAGEMENT',
    AmpSer.trackSignedInUser(dateJoined, usingFacebookAuth),
  );
  if (usingFacebookAuth) {
    amplitude.setUserProperties({
      isConnectedWithFacebook: true,
    });
  }
}

export function trackClickedHotspotRegion(hotspot, regionLink) {
  amplitude.logEvent('clicked_hotspot_region_ENGAGEMENT', {
    ...AmpSer.genHotspot(hotspot),
    regionLink,
  });
  const hotspotData = {
    uuid: hotspot.uuid,
    title: hotspot.title,
    link: regionLink,
  };
  fbPixel.logEvent('track', 'ClickHotspot', hotspotData);
  fbCapi.logEvent('ClickHotspot', hotspotData);
  ga4('event', 'click_hotspot', hotspotData);
}

export function trackGuestEmailInShippingAddress(email) {
  amplitude.setUserId(email);
}

export function trackGuestEmailAfterPlaceOrder(email, firstName) {
  const dateNowUnix = parseInt(Date.now(), 10);

  amplitude.setUserId(email);
  cio.identify(CioSer.genUser(email, firstName, '', dateNowUnix));
}

export function trackUTM(email) {
  if (!isEmpty(email)) {
    cio.track('utm_PROPERTY', CioSer.genCurrentUtmProp());
  }
}

export function trackViewedProduct({
  product,
  referrer,
  isWishlist,
  openedFrom = null,
}) {
  amplitude.logEvent(
    'viewed_product_ENGAGEMENT',
    AmpSer.genViewedProduct({ product, referrer, isWishlist, openedFrom }),
  );
  cio.track('viewed_product_ENGAGEMENT', CioSer.genProduct(product));
  ttqTrackWithTryCatch('ViewContent', TiktokSer.genProductProperties(product));
  sendCriteoEvent('viewItem', {
    item: product.objectID,
  });
  ga('ec:setAction', 'detail');
  ga('send', 'pageview');
  ga4('event', 'view_item', Ga4Ser.genProduct(product));
  fbPixel.logEvent('track', 'ViewContent', FbSer.genProduct(product));
  fbCapi.logEvent('ViewContent', FbCapiSer.genProduct(product));
  fbApp.logEvent(FbAppSer.genViewProductLogEventParams(product));
  wv.trackEvent('viewed_product_ENGAGEMENT', WvSer.genProduct(product));

  const pinterestEventId = generateGuid();
  const pinterestProductData = PinterestSer.genProduct(product, 1);

  pinterestCapi.logEvent('pagevisit', pinterestProductData, pinterestEventId);
  pinterestTag.logEvent(
    'track',
    'pagevisit',
    pinterestProductData,
    pinterestEventId,
  );
}

export function trackClickedProductVariations(
  product,
  clickedVariation,
  location,
) {
  const trackedItem = {
    ...AmpSer.genProduct(product),
    clickedUpc: clickedVariation.objectID,
    location,
  };
  amplitude.logEvent('click_product_variation_ENGAGEMENT', trackedItem);
}

export function trackClickedPDPCampaignRibbon(pathname) {
  amplitude.logEvent('clicked_pdp_campaign_ribbon', { from: pathname });
}

export function trackClickedFloatingIcon() {
  amplitude.logEvent('clicked_floating_icon_ENGAGEMENT');
}

export function trackClickedGWPBanner() {
  amplitude.logEvent('clicked_gwp_banner_ENGAGEMENT');
}

export function trackClickedPopupBanner() {
  amplitude.logEvent('clicked_popup_banner_ENGAGEMENT');
}

export function trackClickedBannerSlideritem(from, to) {
  amplitude.logEvent('clicked_banner_slider_item_ENGAGEMENT', {
    from,
    to,
  });
}

export function trackClickedProductDetailInfo(event, product) {
  amplitude.logEvent(event, AmpSer.genProduct(product));
  cio.track(event, CioSer.genProduct(product));
}

export function trackClickedProductCell(
  product,
  position,
  clickedFrom,
  sortIndex,
  usedFilter,
) {
  amplitude.logEvent(
    'clicked_product_cell_ENGAGEMENT',
    AmpSer.genProductCellInfo(
      product,
      position,
      clickedFrom,
      sortIndex,
      usedFilter,
    ),
  );
  cio.track(
    'clicked_product_cell_ENGAGEMENT',
    CioSer.genProductCellInfo(
      product,
      position,
      clickedFrom,
      sortIndex,
      usedFilter,
    ),
  );
}

export function trackClickedProductMattressHomepage(product) {
  amplitude.logEvent('clicked_product_mattress_homepage_ENGAGEMENT', product);
  cio.track('clicked_product_mattress_homepage_ENGAGEMENT', product);
}

export function trackClickedExtraFilter(label, title, category) {
  amplitude.logEvent(
    'clicked_extra_filter_ENGAGEMENT',
    AmpSer.genExtraFilter(label, title, category),
  );
  cio.track(
    'clicked_extra_filter_ENGAGEMENT',
    CioSer.genExtraFilter(label, title, category),
  );
}

export function trackViewedLookSimilarProducts({
  product,
  referrer,
  isWishlist,
  openedFrom = null,
}) {
  amplitude.logEvent(
    'viewed_look_similar_products_ENGAGEMENT',
    AmpSer.genViewedProduct({ product, referrer, isWishlist, openedFrom }),
  );
  cio.track(
    'viewed_look_similar_products_ENGAGEMENT',
    CioSer.genProduct(product),
  );
}

export function trackClickedLookSimilarProducts(
  product,
  position,
  clickedFrom,
  sortIndex,
) {
  amplitude.logEvent(
    'clicked_look_similar_products_ENGAGEMENT',
    AmpSer.genLookSimilarProductsInfo(
      product,
      position,
      clickedFrom,
      sortIndex,
    ),
  );
  cio.track(
    'clicked_look_similar_products_ENGAGEMENT',
    CioSer.genLookSimilarProductsInfo(
      product,
      position,
      clickedFrom,
      sortIndex,
    ),
  );
}

export function trackClickedProductReviewModal(upc) {
  amplitude.logEvent('clicked_product_review_modal_REVIEW', { upc });
}

export function trackClickedProductReviewBanner() {
  amplitude.logEvent('clicked_product_review_banner_REVIEW');
}

export function trackClickedProductImage(product, imageUrl, idx) {
  amplitude.logEvent(
    'clicked_product_image_ENGAGEMENT',
    AmpSer.genProductImage(product, imageUrl, idx),
  );
  cio.track(
    'clicked_product_image_ENGAGEMENT',
    CioSer.genProductImage(product, imageUrl, idx),
  );
}

export function trackClickedProductShipmentArea(
  selectedCity,
  shippingCost,
  duration,
) {
  amplitude.logEvent('clicked_product_shipment_info_ENGAGEMENT', {
    selectedCity,
    shippingCost,
    ETA: duration,
  });

  cio.track('clicked_product_shipment_info_ENGAGEMENT', {
    selectedCity,
    shippingCost,
    ETA: duration,
  });
}

export function trackClickedProductBrand(to, pathName) {
  amplitude.logEvent(
    'clicked_product_brand_ENGAGEMENT',
    AmpSer.trackTransitionTo(to, pathName),
  );
  cio.track(
    'clicked_product_brand_ENGAGEMENT',
    CioSer.trackTransitionTo(to, pathName),
  );
}

export function trackClickedSeeAllProducts(to, from) {
  amplitude.logEvent(
    'clicked_see_all_products_ENGAGEMENT',
    AmpSer.trackTransitionTo(to, from),
  );
  cio.track(
    'clicked_see_all_products_ENGAGEMENT',
    CioSer.trackTransitionTo(to, from),
  );
}

export function trackViewedProductList(
  {
    products,
    query,
    breadcrumb,
    index,
    page,
    nbPages,
    roomName,
    offerName,
    openedFrom,
    url,
  },
  eventName = 'viewed_product_list_ENGAGEMENT',
) {
  const topThreeItems = products.slice(0, 3).map((hit) => hit.objectID);
  sendCriteoEvent('viewList', {
    item: topThreeItems,
  });
  amplitude.logEvent(
    eventName,
    AmpSer.genProductListSer({
      products,
      query,
      breadcrumb,
      index,
      page,
      nbPages,
      roomName,
      offerName,
      openedFrom,
      url,
    }),
  );
  wv.trackEvent('viewed_product_list_ENGAGEMENT', products);
}

export function trackViewedMembershipCatalogue() {
  amplitude.logEvent('viewed_membership_catalogue_MEMBER');
}

export function trackClickedProductDetailMembershipCatalogue() {
  amplitude.logEvent('clicked_product_membership_catalogue_MEMBER');
}

export function trackClickedProductLinkingCategory(to, pathName) {
  amplitude.logEvent(
    'clicked_product_linking_category_ENGAGEMENT',
    AmpSer.trackTransitionTo(to, pathName),
  );
  cio.track(
    'clicked_product_linking_category_ENGAGEMENT',
    CioSer.trackTransitionTo(to, pathName),
  );
}

export function trackViewedCart(basket, wishlistItems) {
  amplitude.logEvent(
    'viewed_cart_CHECKOUT',
    AmpSer.genBasketSer({ basket, wishlistItems }),
  );
  cio.track('viewed_cart_CHECKOUT', CioSer.genBasketSer({ basket }));
  sendCriteoEvent('viewItem', CriteoSer.genBasketSer(basket));
  wv.trackEvent('viewed_cart_CHECKOUT', basket);
  ga4('event', 'view_cart', Ga4Ser.genBasket(basket));
  fbCapi.logEvent('InitiateCheckout', {});
  fbApp.logEvent(FbAppSer.genInitiateCheckoutLogEventParams(basket));
}

export function trackAddProduct({
  product,
  isWishlist,
  addons = [],
  quantity = 1,
  addToCartReferenceNote = null,
  addToCartReference,
  pathName = null,
}) {
  fbPixel.logEvent('track', 'AddToCart', FbSer.genProduct(product));
  ttqTrackWithTryCatch('AddToCart', {
    ...TiktokSer.genProductProperties(product),
    quantity,
  });
  amplitude.logEvent(
    'added_product_to_cart_CHECKOUT',
    AmpSer.genAddToCart({
      product,
      quantity,
      addons,
      pathName,
      isWishlist,
      addToCartReferenceNote,
      addToCartReference,
    }),
  );
  cio.track('added_product_to_cart_CHECKOUT', CioSer.genProduct(product));
  wv.trackEvent('added_product_to_cart_CHECKOUT', { product, quantity });
  ga4('event', 'add_to_cart', Ga4Ser.genProduct(product));
  fbCapi.logEvent('AddToCart', FbCapiSer.genProduct(product));
  fbApp.logEvent(FbAppSer.genAddToCartLogEventParams(product, quantity));

  const pinterestEventId = generateGuid();
  const pinterestProductData = PinterestSer.genProduct(product, quantity);

  pinterestCapi.logEvent('addtocart', pinterestProductData, pinterestEventId);
  pinterestTag.logEvent(
    'track',
    'addtocart',
    pinterestProductData,
    pinterestEventId,
  );
}

export function trackAddInstallationAddon(url) {
  amplitude.logEvent('add_installation_addon_ENGAGEMENT', {
    url,
  });
}

export function trackClickAddToCartButton(product) {
  amplitude.logEvent(
    'clicked_add_to_cart_button_ENGAGEMENT',
    AmpSer.genProduct(product),
  );
}

export function trackRemoveProduct(product) {
  amplitude.logEvent('removed_product_from_cart_CHECKOUT', {
    product: AmpSer.genProduct(product),
  });
  cio.track(
    'removed_product_from_cart_CHECKOUT',
    CioSer.genProductUpcOnly(product),
  );
  ga4('event', 'remove_from_cart', Ga4Ser.genProduct(product));
}

export function trackClickUpsellItem(product, upsellItem) {
  amplitude.logEvent(K_TRACK_CLICKED_UPSELL_ITEMS, {
    product: AmpSer.genProduct(product),
    upsellItem: AmpSer.genProduct(upsellItem),
  });
  cio.track(
    K_TRACK_CLICKED_UPSELL_ITEMS,
    CioSer.genProductUpsell(product, upsellItem),
  );
}

export function trackViewedReviewOrder(isShopAssistant) {
  amplitude.logEvent('viewed_review_order_CHECKOUT', { isShopAssistant });
  cio.track('viewed_review_order_CHECKOUT', { isShopAssistant });
}

export function trackViewedPaymentSelectionPage(isShopAssistant) {
  amplitude.logEvent('viewed_payment_selection_page', { isShopAssistant });
}

export function trackViewedPaymentPage(isShopAssistant, paymentMethod) {
  amplitude.logEvent('viewed_payment_page', { isShopAssistant, paymentMethod });
}

export function trackMutateUserShippingAddress(isShopAssistant, address) {
  amplitude.logEvent('mutate_user_shipping_address', {
    isShopAssistant,
    address,
  });
}

export function trackInitiateCheckout(wishlistItems) {
  fbPixel.logEvent('track', 'InitiateCheckout');
  ttqTrackWithTryCatch('InitiateCheckout');
  amplitude.logEvent(
    'initiated_checkout_CHECKOUT',
    AmpSer.genWishlistPins(wishlistItems),
  );
  cio.track('initiated_checkout_CHECKOUT');
  wv.trackEvent('initiated_checkout_CHECKOUT');
}

export function trackClickedSelectPayment() {
  fbPixel.logEvent('track', 'SelectPayment');
  amplitude.logEvent('clicked_select_payment_CHECKOUT');
  cio.track('clicked_select_payment_CHECKOUT');
  wv.trackEvent('clicked_select_payment_CHECKOUT');
}

export function trackSelectPaymentMethod(basket, paymentMethod) {
  fbPixel.logEvent('track', 'AddPaymentInfo');
  ttqTrackWithTryCatch('AddPaymentInfo');
  amplitude.logEvent(
    'selected_payment_method_CHECKOUT',
    AmpSer.genPaymentMethod(paymentMethod),
  );
  cio.track(
    'selected_payment_method_CHECKOUT',
    CioSer.genPaymentMethod(paymentMethod),
  );
  wv.trackEvent(
    'selected_payment_method_CHECKOUT',
    WvSer.genPaymentMethod(paymentMethod),
  );
  ga4('event', 'add_payment_info', Ga4Ser.genBasket(basket));
  fbCapi.logEvent('AddPaymentInfo', {});
  fbApp.logEvent(FbAppSer.genAddPaymentInfoLogEventParams());
}

export function trackClickPlaceOrder() {
  amplitude.logEvent('clicked_place_order_CHECKOUT', {});
}

export function trackPlaceOrder(order, lines, wishlistItems, paymentMethod) {
  fbPixel.logEvent('track', 'Purchase', FbSer.genPurchase(order, lines), {
    eventID: order.number,
  });
  ttqTrackWithTryCatch('PlaceAnOrder', TiktokSer.genOrderProperties(lines));
  wv.trackEvent('placed_order_CHECKOUT', {
    order,
    lines,
    paymentMethod,
  });
  amplitude.logEvent(
    'placed_order_CHECKOUT',
    AmpSer.genPurchase(order, lines, wishlistItems, paymentMethod),
  );
  cio.track(
    'placed_order_CHECKOUT',
    CioSer.genPurchase(order, lines, paymentMethod),
  );
  sendCriteoEvent(
    'trackTransaction',
    CriteoSer.genPurchase(order, lines, paymentMethod),
  );

  // google analytics
  ga('create', 'UA-71203063-1');
  ga('require', 'ec');

  lines.forEach((line) => {
    const { product, quantity } = line;
    amplitude.logEvent(
      'placed_order_single_CHECKOUT',
      AmpSer.genPurchaseSingle(order, line),
    );
    amplitude.logRevenue(product.price, quantity, product.title);
    cio.track(
      'placed_order_single_CHECKOUT',
      CioSer.genPurchaseSingle(order, line),
    );
    ga('ec:addProduct', GaSer.genProductPurchase(line));
  });
  dataLayer.push(DataLayerSer.genPurchase(order, lines));
  dataLayer.push(DataLayerSer.genTransaction(order, lines));
  ga('ec:setAction', 'purchase', GaSer.genPurchase(order, lines));
  ga('send', 'pageview');
  ga4('event', 'purchase', Ga4Ser.genOrder(order, lines));
  fbCapi.logEvent('Purchase', FbCapiSer.genOrder(order, lines), order.number);
  fbApp.logEvent(FbAppSer.genPurchaseLogEventParams(lines));
}

export function trackClickedCreditCardContinuePayment(orderKey, redirectUrl) {
  amplitude.logEvent('clicked_credit_card_continue_payment', {
    orderKey,
    redirectUrl,
  });
}

export function trackCallbackCreditCardContinuePayment(
  orderKey,
  redirectUrl,
  status,
) {
  amplitude.logEvent('callback_credit_card_continue_payment', {
    orderKey,
    redirectUrl,
    status,
  });
}

export function trackCallbackGopayRecharge(orderKey, paymentUrl) {
  amplitude.logEvent('callback_gopay_recharge', {
    orderKey,
    paymentUrl,
  });
}

export function trackViewedEditShippingAddressPage(address, isLoggedIn) {
  amplitude.logEvent('viewed_edit_shipping_address_page_CHECKOUT', {
    address,
    isLoggedIn,
  });
  cio.track('viewed_edit_shipping_address_page_CHECKOUT', {
    address,
    isLoggedIn,
  });
}

export function trackClickedSaveShippingAddress(basket, address, isLoggedIn) {
  amplitude.logEvent('clicked_save_shipping_address_CHECKOUT', {
    address,
    isLoggedIn,
  });
  cio.track('clicked_save_shipping_address_CHECKOUT', { address, isLoggedIn });
  ga4('event', 'add_shipping_info', Ga4Ser.genBasket(basket));
}

export function trackCompleteShippingAddress(
  validatedAddress,
  isLoggedIn,
  message,
) {
  amplitude.logEvent('completed_shipping_address_CHECKOUT', {
    address: validatedAddress,
    isLoggedIn,
    message,
  });
  cio.track('completed_shipping_address_CHECKOUT', {
    address: validatedAddress,
    isLoggedIn,
    message,
  });
}

export function trackClickTopNavbar(to, pathName) {
  fbPixel.logEvent('track', 'Search');
  amplitude.logEvent(
    'clicked_nav_top_level_menu_ENGAGEMENT',
    AmpSer.trackTransitionTo(to, pathName),
  );
  cio.track(
    'clicked_nav_top_level_menu_ENGAGEMENT',
    CioSer.trackTransitionTo(to, pathName),
  );
}

export function trackClickBreadcrumbCategory(to, pathName) {
  amplitude.logEvent(
    'clicked_category_breadcrumb_ENGAGEMENT',
    AmpSer.trackTransitionTo(to, pathName),
  );
  cio.track(
    'clicked_category_breadcrumb_ENGAGEMENT',
    CioSer.trackTransitionTo(to, pathName),
  );
}

export function trackClickBusinessUnitCategory(to, pathName) {
  amplitude.logEvent(
    'clicked_business_unit_category',
    AmpSer.trackTransitionTo(to, pathName),
  );
}

export function trackClickBreadcrumb(to, from, breadcrumbText) {
  amplitude.logEvent('clicked_breadcrumb_ENGAGEMENT', {
    to,
    from,
    breadcrumbText,
  });
  cio.track('clicked_breadcrumb_ENGAGEMENT', { to, from, breadcrumbText });
}

/*
  Mobile Homepage
 */
export function trackClickBusinessUnitStory(to) {
  amplitude.logEvent(
    'clicked_business_unit_story_ENGAGEMENT',
    AmpSer.trackTransitionTo(to),
  );
  cio.track(
    'clicked_business_unit_story_ENGAGEMENT',
    CioSer.trackTransitionTo(to),
  );
}

export function trackClickedShopByRoomHomepage(to) {
  amplitude.logEvent('clicked_shop_by_room_homepage_ENGAGEMENT', { to });
  cio.track('clicked_shop_by_room_homepage_ENGAGEMENT', { to });
}

export function trackClickedShopByRoom(to, from) {
  amplitude.logEvent(
    'clicked_shop_by_room_ENGAGEMENT',
    AmpSer.trackTransitionTo(to, from),
  );
  cio.track(
    'clicked_shop_by_room_ENGAGEMENT',
    CioSer.trackTransitionTo(to, from),
  );
}

export function trackClickMarketplaceCategory(to, _categoryName, source) {
  const categoryName = _categoryName.replace('\n', ' ');
  amplitude.logEvent('clicked_marketplace_category_homepage_ENGAGEMENT', {
    ...AmpSer.genProductCategorySer(to, categoryName),
    source,
  });
  cio.track('clicked_marketplace_category_homepage_ENGAGEMENT', {
    ...CioSer.genProductCategorySer(to, categoryName),
    source,
  });
}

export function trackClickedSectionSeeOtherHomepage(to) {
  amplitude.logEvent(
    'clicked_section_see_other_homepage_ENGAGEMENT',
    AmpSer.trackTransitionTo(to),
  );
}

export function trackClickedTopProductHomepage(to) {
  amplitude.logEvent(
    'clicked_top_product_homepage_ENGAGEMENT',
    AmpSer.trackTransitionTo(to),
  );
}

export function trackClickedMattressSearch(filterUsed, from) {
  amplitude.logEvent(
    'clicked_mattress_search_ENGAGEMENT',
    AmpSer.genMattressSearch(filterUsed, from),
  );
  cio.track(
    'clicked_mattress_search_ENGAGEMENT',
    CioSer.genMattressSearch(filterUsed, from),
  );
}

export function trackClickedMattressViewAllBrand() {
  amplitude.logEvent('clicked_mattress_view_all_brand_ENGAGEMENT');
  cio.track('clicked_mattress_view_all_brand_ENGAGEMENT');
}

export function trackClickedMattressBrand(name, from) {
  amplitude.logEvent(
    'clicked_mattress_brand_ENGAGEMENT',
    AmpSer.genMattressBrand(name, from),
  );
  cio.track(
    'clicked_mattress_brand_ENGAGEMENT',
    CioSer.genMattressBrand(name, from),
  );
}

export function trackClickedMattressBanner(from) {
  amplitude.logEvent(
    'clicked_mattress_banner_ENGAGEMENT',
    AmpSer.genOpenedFrom(from),
  );
  cio.track('clicked_mattress_banner_ENGAGEMENT', CioSer.genOpenedFrom(from));
}

// data at least contains {customerEmail: String, customerPhone: String}
export function trackErrorEmailAndPhoneNumberMismatch(data) {
  amplitude.logEvent('email_and_phone_number_mismatch_DNB', data);
}

export function trackClickDnbBanner() {
  amplitude.logEvent('clicked_dnb_banner_DNB');
  cio.track('clicked_dnb_banner_DNB');
}

export function trackClickHouseBanner() {
  amplitude.logEvent('clicked_house_banner_HOUSE');
  cio.track('clicked_house_banner_HOUSE');
}

export function trackClickHouseLocationCard(to, locationName) {
  amplitude.logEvent(
    'clicked_house_location_card_HOUSE',
    AmpSer.genLocationCardSer(to, locationName),
  );
  cio.track(
    'clicked_house_location_card_HOUSE',
    CioSer.genLocationCardSer(to, locationName),
  );
}

export function trackClickHouseSellerBanner() {
  amplitude.logEvent('clicked_house_seller_banner_HOUSE');
  cio.track('clicked_house_seller_banner_HOUSE');
}

export function trackClickDnbServiceType(to, serviceTypeName) {
  amplitude.logEvent(
    'clicked_project_category_DNB',
    AmpSer.genProjectServiceTypeSer(to, serviceTypeName),
  );
  cio.track(
    'clicked_project_category_DNB',
    CioSer.genProjectServiceTypeSer(to, serviceTypeName),
  );
}

export function trackClickHomepageBanner(to, index = null) {
  amplitude.logEvent('clicked_homepage_banner_ENGAGEMENT', {
    ...AmpSer.trackTransitionTo(to),
    index,
  });
  cio.track('clicked_homepage_banner_ENGAGEMENT', {
    ...CioSer.trackTransitionTo(to),
    index,
  });
  ga4('event', 'click_homepage_banner', { to, index });
}

export function trackClickSecondaryBanner(to) {
  amplitude.logEvent('clicked_secondary_banner_ENGAGEMENT', { to });
  cio.track('clicked_secondary_banner_ENGAGEMENT', { to });
}

export function trackClickDECMapHomepageBanner() {
  amplitude.logEvent('clicked_dec_map_banner_ENGAGEMENT');
}

export function trickClickDECAction(type) {
  amplitude.logEvent('clicked_dec_action_button_ENGAGEMENT', { type });
}

export function trackRequestVerifyPayment(order, reference) {
  amplitude.logEvent(
    'requested_to_verify_payment_CHECKOUT',
    AmpSer.genRequestVerifyPayment(order, reference),
  );
  cio.track(
    'requested_to_verify_payment_CHECKOUT',
    CioSer.genRequestVerifyPayment(order, reference),
  );
}

export function trackViewedMattressHomepage() {
  amplitude.logEvent('viewed_mattress_homepage_ENGAGEMENT');
  cio.track('viewed_mattress_homepage_ENGAGEMENT');
}

export function trackProductRelatedAction({
  trackingEventName,
  mainProduct,
  product,
  productIndex,
}) {
  amplitude.logEvent(
    trackingEventName,
    AmpSer.trackRelatedProduct(mainProduct, product, productIndex),
  );
  cio.track(
    trackingEventName,
    CioSer.trackRelatedProduct(mainProduct, product, productIndex),
  );
}

export function trackClickedBestSeller({
  trackingEventName,
  product,
  productIndex,
}) {
  amplitude.logEvent(
    trackingEventName,
    AmpSer.trackBestSeller(product, productIndex),
  );
  cio.track(trackingEventName, CioSer.trackBestSeller(product, productIndex));
}

export function trackClickedTopBrandCategory({
  trackingEventName,
  product,
  productIndex,
  brand,
  category,
}) {
  amplitude.logEvent(trackingEventName, {
    product: AmpSer.genProduct(product),
    productIndex,
    brand,
    category,
  });
}

export function trackClickProductFromMoodBoard({
  trackingEventName,
  product,
  productIndex,
}) {
  amplitude.logEvent(trackingEventName, {
    product: AmpSer.genProduct(product),
    productIndex,
  });
  cio.track(trackingEventName, {
    product: CioSer.genProduct(product),
    productIndex,
  });
}

export function trackClickedInitiateChat(product, clickedFrom) {
  amplitude.logEvent(
    'clicked_initiate_chat_ENGAGEMENT',
    AmpSer.genChatInfo(product, clickedFrom),
  );
  cio.track(
    'clicked_initiate_chat_ENGAGEMENT',
    CioSer.genChatInfo(product, clickedFrom),
  );
  ttqTrackWithTryCatch('Contact', TiktokSer.genProductProperties(product));
}

export function trackClickedNavbarChat() {
  amplitude.logEvent('clicked_navbar_chat_ENGAGEMENT');
  ttqTrackWithTryCatch('Contact');
}

export function trackClickedProductCarouselCategory({
  trackingEventName,
  product,
  productIndex,
  category,
}) {
  amplitude.logEvent(trackingEventName, {
    product: AmpSer.genProduct(product),
    productIndex,
    category,
  });
}
export function trackSearchQueryChange(queryString, to = '') {
  fbPixel.logEvent('track', 'Search');
  ttqTrackWithTryCatch('Search');
  amplitude.logEvent(
    'searched_products_ENGAGEMENT',
    AmpSer.genSearch(queryString, to),
  );
  cio.track('searched_products_ENGAGEMENT', CioSer.genSearch(queryString, to));
  wv.trackEvent('searched_products_ENGAGEMENT', queryString);
}

export function trackViewedWishlistPage(items = []) {
  amplitude.logEvent(
    'viewed_favorite_page_ENGAGEMENT',
    AmpSer.genWishlistPins(items),
  );
  cio.track('viewed_favorite_page_ENGAGEMENT', CioSer.genWishlistPins(items));
}

export function trackClickedSearchProductFromWishlist() {
  amplitude.logEvent('clicked_search_product_from_favorite_ENGAGEMENT');
  cio.track('clicked_search_product_from_favorite_ENGAGEMENT');
}

export function trackClickedWishlistPin(placement, isWishlist, product) {
  if (isWishlist) {
    ttqTrackWithTryCatch(
      'AddToWishlist',
      TiktokSer.genProductProperties(product),
    );
    ga4('event', 'add_to_wishlist', Ga4Ser.genProduct(product));
    fbCapi.logEvent('AddToWishlist', FbCapiSer.genProduct(product));
    fbApp.logEvent(FbAppSer.genAddToWishlistLogEventParams(product));
  }
  amplitude.logEvent(
    'clicked_favorite_pin_ENGAGEMENT',
    AmpSer.genWishlistPin(placement, isWishlist, product),
  );
  cio.track(
    'clicked_favorite_pin_ENGAGEMENT',
    CioSer.genWishlistPin(placement, isWishlist, product),
  );
  wv.trackEvent('clicked_favorite_pin_ENGAGEMENT', { product });
}

export function trackViewedProductReviewList() {
  amplitude.logEvent('viewed_product_review_list_REVIEW');
}

export function trackViewedProductReviewDetail() {
  amplitude.logEvent('viewed_product_reviewed_detail_REVIEW');
}

export function trackClickedUpdateProductDetail(review) {
  amplitude.logEvent(
    'clicked_update_product_detail_REVIEW',
    AmpSer.genClickedUpdateProductDetail(review),
  );
}
/**
 * DESIGN BUILD
 */

export function trackClickedRegisterDesignerBtn(btnPosition, isMobile = false) {
  amplitude.logEvent(
    'clicked_register_designer_DNB',
    AmpSer.genClickedRegisterDesignerBtn(btnPosition, isMobile),
  );
}

export function trackViewedDesignerEditProfilePage() {
  amplitude.logEvent('viewed_designer_edit_profile_DNB');
}

export function trackClickFreeConsultation(
  to,
  pathName,
  referenceProject,
  user,
  additionalInfo,
) {
  fbPixel.logEvent('trackSingle', K_DNB_FACEBOOK_PIXEL_ID, 'AddToCart', {
    ...(referenceProject ? FbSer.genPortfolioSer(referenceProject) : {}),
    ...additionalInfo,
  });

  sendCriteoEventDNB('viewBasket', {
    ...(referenceProject
      ? CriteoSer.genAskPriceWithPortfolioSer(referenceProject, user)
      : {}),
    ...additionalInfo,
  });

  amplitude.logEvent('clicked_free_consultation', {
    ...AmpSer.trackTransitionTo(to, pathName),
    ...additionalInfo,
  });
  cio.track('clicked_free_consultation', {
    ...CioSer.trackTransitionTo(to, pathName),
    ...additionalInfo,
  });
  ga4('event', 'add_to_cart', Ga4Ser.genProject(referenceProject));
  fbCapi.logEvent(
    'AddToCart',
    FbCapiSer.genProject(referenceProject),
    '',
    'interior',
  );
}

export function trackOpenedProductOfferingDropdown(via) {
  amplitude.logEvent('opened_product_offering_dropdown_INTERIOR', { via });
}

export function trackClickedProductOffering(via, to) {
  amplitude.logEvent('clicked_product_offering_INTERIOR', { via, to });
}

export function trackClickedHowItWorks(via) {
  amplitude.logEvent('clicked_how_it_works_INTERIOR', { via });
}

export function trackClickedCoverageArea(via) {
  amplitude.logEvent('clicked_coverage_area_INTERIOR', { via });
}

export function trackClickedEntrypointSOMA(via) {
  amplitude.logEvent('clicked_entrypoint_SOMA', { via });
}

export function trackClickedPortfolioList(via) {
  amplitude.logEvent('clicked_portfolio_list_INTERIOR', { via });
}

export function trackViewedRequestForm(sourceCampaign) {
  amplitude.logEvent('view_project_request_form', { sourceCampaign });
  cio.track('view_project_request_form');
  sendCriteoEvent('viewRequestForm');
}

export function trackCreatedNewProject(
  project,
  referralPortfolioId,
  referralPortfolioValue,
  sourceCampaign,
) {
  const projectWithPlatform = {
    ...project,
    source: getPlatform(),
    sourceCampaign,
  };

  amplitude.logEvent(
    'created_new_project_DNB',
    AmpSer.genProjectSer(projectWithPlatform),
  );

  const pinterestEventId = generateGuid();
  const pinterestProjectData = PinterestSer.genProject(project);
  pinterestCapi.logEvent(
    'lead',
    pinterestProjectData,
    pinterestEventId,
    'interior',
  );
  pinterestTag.logEvent(
    'track',
    'lead',
    pinterestProjectData,
    pinterestEventId,
  );

  const referenceProjectWithPlatform = referralPortfolioId
    ? {
        projectKey: referralPortfolioId,
        dealValue: referralPortfolioValue,
        source: getPlatform(),
      }
    : projectWithPlatform;

  fbPixel.logEvent(
    'trackSingle',
    K_DNB_FACEBOOK_PIXEL_ID,
    'Purchase',
    FbSer.genProjectSer(referenceProjectWithPlatform),
  );

  sendCriteoEventDNB(
    'trackTransaction',
    CriteoSer.getProjectCreatedSer(project, referralPortfolioId),
  );
}

export function trackClickButtonRequestForm(questionnaire) {
  const questionnaireWithPlatform = {
    ...questionnaire,
    source: getPlatform(),
  };
  amplitude.logEvent(
    'click_request_form_DNB',
    AmpSer.genRequestProjectSer(questionnaireWithPlatform),
  );
  cio.track(
    'click_request_form_DNB',
    CioSer.genRequestProjectSer(questionnaireWithPlatform),
  );
}

export function trackViewedHomepage() {
  amplitude.logEvent('view_homepage_storefront_ENGAGEMENT');
  cio.track('view_homepage_storefront_ENGAGEMENT');
  sendCriteoEvent('viewHome');
  ttqTrackWithTryCatch('ViewContent', TiktokSer.genHomepageProperties());
}

export function trackViewProductHomepage() {
  amplitude.logEvent('view_product_homepage_storefront_ENGAGEMENT');
  cio.track('view_product_homepage_storefront_ENGAGEMENT');
  sendCriteoEvent('viewHome');
}

// #region Designer Project
export function trackViewedPortfolioList(portfolioList, filters = {}) {
  amplitude.logEvent('viewed_portfolio_list_DNB', {
    ...AmpSer.genPortfolioIdsSer(portfolioList),
    ...filters,
  });
  cio.track('viewed_portfolio_list_DNB', {
    ...CioSer.genPortfolioIdsSer(portfolioList),
    ...filters,
  });
  ga4('event', 'view_portfolio_list', Ga4Ser.genPortfolioKeys(portfolioList));
}

export function trackViewedPortfolioDetail(project) {
  amplitude.logEvent(
    'viewed_portfolio_detail_DNB',
    AmpSer.genPortfolioSer(project),
  );
  cio.track('viewed_portfolio_detail_DNB', CioSer.genPortfolioSer(project));
  sendCriteoEventDNB('viewItem', {
    item: CriteoSer.genPortfolioSer(project),
  });

  const fbPortfolio = FbSer.genPortfolioSer(project);

  fbPixel.logEvent(
    'trackSingle',
    K_DEKORUMA_FACEBOOK_PIXEL_ID,
    'ViewedPortfolio',
    fbPortfolio,
  );
  fbPixel.logEvent(
    'trackSingle',
    K_DNB_FACEBOOK_PIXEL_ID,
    'ViewedPortfolio',
    fbPortfolio,
  );
  fbPixel.logEvent(
    'trackSingle',
    K_DNB_FACEBOOK_PIXEL_ID,
    'ViewContent',
    fbPortfolio,
  );
  ga4('event', 'view_item', Ga4Ser.genProject(project));
  fbCapi.logEvent('ViewContent', FbCapiSer.genProject(project), '', 'interior');

  const pinterestEventId = generateGuid();
  const pinterestPortfolioData = PinterestSer.genPortfolioProject(project);

  pinterestCapi.logEvent(
    'pagevisit',
    pinterestPortfolioData,
    pinterestEventId,
    'interior',
  );
  pinterestTag.logEvent(
    'track',
    'pagevisit',
    pinterestPortfolioData,
    pinterestEventId,
  );
}

export function trackClickedPortfolio(project) {
  amplitude.logEvent('clicked_portfolio_DNB', AmpSer.genPortfolioSer(project));
  cio.track('clicked_portfolio_DNB', CioSer.genPortfolioSer(project));
}

export function trackViewedMicrosite(slug, openedFrom) {
  amplitude.logEvent('viewed_microsite_ENGAGEMENT', {
    slug,
    openedFrom,
  });
  ttqTrackWithTryCatch('ViewContent', TiktokSer.genMicrositeProperties(slug));
}

export function trackClickedProjectFilter(name) {
  amplitude.logEvent('clicked_project_filter_UIUX', { name });
}

export function trackClickedPortfolioFilter(name, type) {
  amplitude.logEvent('clicked_portfolio_filter_DNB', { name, type });
}

export function trackClickedShopByRoomCTA() {
  amplitude.logEvent('clicked_shop_by_room_cta_ROOM');
}

export function trackViewedRoomsPage() {
  amplitude.logEvent('viewed_rooms_page_ROOM');
}

export function trackViewedRoomDetailPage(roomName) {
  amplitude.logEvent('viewed_room_detail_ROOM', { roomName });
}

export function trackViewedPromoPage() {
  amplitude.logEvent('viewed_promotion_page');
}

export function trackViewedApplyVoucherPage(type) {
  amplitude.logEvent('viewed_apply_voucher_page_CHECKOUT', { type });
}

export function trackClickApplyVoucherAndRemoveGWP(basketId, code) {
  amplitude.logEvent('apply_voucher_and_remove_gwp_ENGAGEMENT', {
    basketId,
    code,
  });
}

export function trackClickedApplyVoucher(code, type) {
  amplitude.logEvent('clicked_apply_voucher_CHECKOUT', {
    code,
    type,
  });
}

export function trackAppliedVoucherCode(code, type, message = null) {
  amplitude.logEvent('applied_voucher_CHECKOUT', {
    code,
    type,
    message,
  });
}

export function trackClickedViewVoucherDetail(code, type) {
  amplitude.logEvent('clicked_view_voucher_detail_CHECKOUT', {
    code,
    type,
  });
}

export function trackViewedMyVoucherPage() {
  amplitude.logEvent('viewed_my_voucher_page');
}

export function trackClickedCTAVoucherDetail(voucherName) {
  amplitude.logEvent('clicked_voucher_detail_cta_ENGAGEMENT', {
    voucherName,
  });
}

export function trackClickedCTAAdsDetail(adName) {
  amplitude.logEvent('clicked_ads_detail_cta_ENGAGEMENT', {
    adName,
  });
}

export function trackClickedPromoCard(name, position, type) {
  amplitude.logEvent('clicked_promo_card_ENGAGEMENT', {
    name,
    position,
    type,
  });
}

export function trackClickedAppBanner(
  location,
  type,
  businessUnit = 'Marketplace',
) {
  amplitude.logEvent('clicked_app_banner_ENGAGEMENT', {
    location,
    type,
    businessUnit,
  });
}

export function trackClickedApplyFilterButton(pageName) {
  amplitude.logEvent('clicked_filter_ENGAGEMENT', {
    pageName,
  });
}

export function trackClickedApplyQuickFilterButton(pageName, label) {
  amplitude.logEvent('clicked_quick_filter_ENGAGEMENT', {
    pageName,
    label,
  });
}

export function trackClickedDrawerMenuItem(linkUrl) {
  amplitude.logEvent('clicked_drawer_menu_item_ENGAGEMENT', {
    to: linkUrl,
  });
}

// Membership

export function trackViewedMembershipRegisterForm(membershipType) {
  amplitude.logEvent('viewed_membership_register_form_MEMBER', {
    type: membershipType,
  });
}

export function trackClickedSubmitMembershipRegisterForm(
  modifiedValues,
  membershipType,
) {
  amplitude.logEvent('clicked_submit_membership_register_form_MEMBER', {
    ...AmpSer.genRegisterMembership(modifiedValues),
    type: membershipType,
  });
  cio.track('clicked_submit_membership_register_form_MEMBER', {
    ...CioSer.genRegisterMembership(modifiedValues),
    type: membershipType,
  });
}

export function trackViewedMembershipDashboardForm(membershipType) {
  amplitude.logEvent('viewed_membership_dashboard_MEMBER', {
    type: membershipType,
  });
}

// About Us
export function trackViewedAboutUsPage() {
  amplitude.logEvent('viewed_about_us_page');
}

// OTP

export function trackVerifyOTPSuccess(phoneNumber, platform) {
  amplitude.logEvent(
    'verify_otp_success',
    AmpSer.genOTPSer(phoneNumber, platform),
  );
}

export function trackClickedActionLogin(userData, type) {
  amplitude.logEvent(
    'clicked_action_login_ENGAGEMENT',
    AmpSer.genAuthSer(userData, type),
  );
}

export function trackSuccessLogin(user) {
  const { email, username, firstName, lastName, phoneNumber } = user;

  amplitude.logEvent(
    'success_login_ENGAGEMENT',
    AmpSer.genUserSer(username, firstName, lastName, email, phoneNumber),
  );
}

// Sign Up
export function trackViewedSignUp() {
  amplitude.logEvent('viewed_sign_up_ENGAGEMENT');
}

export function trackClickedActionSignUp(userData, type) {
  amplitude.logEvent(
    'clicked_action_sign_up_ENGAGEMENT',
    AmpSer.genAuthSer(userData, type),
  );
}

export function trackViewedSignUpConfirmation(signUpType) {
  amplitude.logEvent('viewed_sign_up_confirmation_ENGAGEMENT', {
    signUpType,
  });
}

export function trackSuccessSignUp(name, email, phoneNumber) {
  amplitude.logEvent('success_sign_up_ENGAGEMENT', {
    name,
    email,
    phoneNumber,
  });
}

export const trackViewedLoginWallPage = () => {
  amplitude.logEvent('viewed_login_wall_ENGAGEMENT');
};

export const trackViewedLoginOrRegisterPhoneNumberPage = () => {
  amplitude.logEvent('viewed_login_or_register_phone_number_ENGAGEMENT');
};

export const trackViewedLoginOrRegisterEmailPage = () => {
  amplitude.logEvent('viewed_login_or_register_email_ENGAGEMENT');
};

export const trackClickedLoginOrRegisterOtherMethodButton = () => {
  amplitude.logEvent('clicked_other_method_button_ENGAGEMENT');
};

export function trackViewedEditProfile() {
  amplitude.logEvent('viewed_edit_profile_ENGAGEMENT');
}

export function trackEditProfile(email, profileData) {
  const { firstName, lastName, phoneNumber } = profileData;
  amplitude.logEvent('sucess_edit_profile_ENGAGEMENT', {
    emailWhoUpdated: email,
    firstName,
    lastName,
    phoneNumber,
  });
}

export function trackEditDekononymousProfile(dekononymousEmail, profileData) {
  const { firstName, lastName, email, phoneNumber } = profileData;
  amplitude.logEvent('sucess_edit_profile_ENGAGEMENT', {
    emailWhoUpdated: dekononymousEmail,
    firstName,
    lastName,
    phoneNumber,
    email,
  });
  cio.track('update_email_from_dekononymous_email', {
    dekononymousEmail,
    email,
  });
}

export function trackConnection(connectionStatus, url) {
  amplitude.logEvent('connection_ENGAGEMENT', {
    status: connectionStatus,
    url,
  });
}

// generic trackers

export function trackClickBundleOfferFloatingIcon(product) {
  amplitude.logEvent(
    'clicked_bundle_offer_floating_icon',
    AmpSer.genProduct(product),
  );
}

export function trackTriggerShowInAppReview() {
  amplitude.logEvent('trigger_show_in_app_review');
}
