export const K_POSITION_TOP = 'top';
export const K_POSITION_BOTTOM = 'bottom';
export const K_POSITION_MIDDLE = 'middle';
export const K_POSITION_LEFT = 'left';
export const K_POSITION_RIGHT = 'right';
export const K_POSITION_CENTER = 'center';
export const K_POSITION_TOP_LEFT = 'topleft';
export const K_POSITION_TOP_RIGHT = 'topright';
export const K_POSITION_TOP_CENTER = 'topcenter';
export const K_POSITION_BOTTOM_LEFT = 'bottomleft';
export const K_POSITION_BOTTOM_RIGHT = 'bottomright';
export const K_POSITION_BOTTOM_CENTER = 'bottomcenter';
export const K_POSITION_MIDDLE_CENTER = 'middlecenter';

export const K_DURATION_LONG = 3500;
export const K_DURATION_SHORT = 2000;
