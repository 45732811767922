import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  buttonStyle: {
    ...cStyles.flexMiddle,
    ...cStyles.flex1,
    ...cStyles.pan,
    ...cStyles.man,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    minHeight: 48,
  },
});
