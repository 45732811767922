import React from 'react';
import PropTypes from 'prop-types';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';

const NavbarSimilarProducts = ({ children }) => (
  <React.Fragment>
    <NavbarTitleCentered title="Produk Serupa" />
    {children}
  </React.Fragment>
);

NavbarSimilarProducts.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavbarSimilarProducts;
