import 'resize-observer-polyfill/dist/ResizeObserver.global';

// SketchUp 2017 uses Chrome 52, therefore need these to polyfills
import values from 'object.values';
import entries from 'object.entries';
import getDescriptors from 'object.getownpropertydescriptors';

if (!Object.values) values.shim();
if (!Object.entries) entries.shim();
if (!Object.getOwnPropertyDescriptors) getDescriptors.shim();
