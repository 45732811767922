import firebase from 'firebase/app';
import has from 'lodash/has';
import superagent from 'superagent';

import config from 'config';

import { getDeviceUniqueId } from 'app-libs/session/AnonymousUser';

const K_FIREBASE_PUSH_MESSAGE_SW_SCOPE = '/firebase-cloud-messaging-push-scope';
const K_FCM_PUBLIC_VAPID_ID =
  'BOY1Y3XFQUoFyvcxA4q8C9_ZcTfwMukquEJoiRhwZFAKKcMx8eBLjslb3AXA1a1kQYHuWvlJ7w-SiCv1P4xO_bg';

const initFirebase = async () => {
  await Promise.all([
    import('firebase/messaging'),
    import('firebase/database'),
  ]);

  if (firebase.apps.length === 0) {
    firebase.initializeApp(config.FIREBASE);
    if (firebase.messaging.isSupported()) {
      firebase.messaging().usePublicVapidKey(K_FCM_PUBLIC_VAPID_ID);
    }
  }

  if (global.firebase) {
    return global.firebase;
  }

  if (!firebase) {
    return Promise.reject(new Error('loading error'));
  }

  global.firebase = firebase;

  return global.firebase ? global.firebase : firebase;
};

export async function getFirebaseApp() {
  if (!global.firebase) {
    await initFirebase();
  }
  return global.firebase.app();
}

export function removeNotificationUserDevice() {
  const localDeviceId = getDeviceUniqueId();
  return superagent
    .del(`${config.API_URL_NOTIF}/v1/user-devices/`)
    .send({ localDeviceId })
    .end(() => {});
}

export async function requestPermissionAndRegisterDevicePushNotification(
  email,
) {
  await getFirebaseApp();
  if (has(window, 'Notification') && global.firebase.messaging.isSupported()) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        registerNotificationToken(email);
      }
    });
  }
}

export async function registerNotificationToken(email) {
  const _firebaseApp = await getFirebaseApp();
  _firebaseApp
    .messaging()
    .getToken()
    .then((token) => {
      superagent
        .post(`${config.API_URL_NOTIF}/v1/user-devices/`)
        .send({
          email,
          notificationKey: token,
          localDeviceId: getDeviceUniqueId(),
        })
        .end(() => {});
    });

  _firebaseApp.messaging().onMessage((payload) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistration(K_FIREBASE_PUSH_MESSAGE_SW_SCOPE)
        .then((registration) => {
          const { data } = payload;
          const { title, body, icon } = data;
          const options = {
            body,
            icon,
            data,
          };
          registration.showNotification(title, options);
        });
    }
  });
}
