import keyMirror from 'keymirror';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import config from 'config';

import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';

import {
  ALGOLIA_DEFAULT_INDEX_NAME,
  fetchObjects,
  fetchSearchResults,
} from '../helper_modules/search/algoliaWrapper';

export const AT = keyMirror({
  GET_CAMPAIGNS: null,
  GET_CAMPAIGNS_SUCCESS: null,
  GET_CAMPAIGNS_FAIL: null,

  GET_CAMPAIGN_DETAIL: null,
  GET_CAMPAIGN_DETAIL_SUCCESS: null,
  GET_CAMPAIGN_DETAIL_FAIL: null,

  GET_OFFER_PRODUCTS: null,
  GET_OFFER_PRODUCTS_SUCCESS: null,
  GET_OFFER_PRODUCTS_FAIL: null,

  GET_OFFER_PREVIEW_PRODUCTS: null,
  GET_OFFER_PREVIEW_PRODUCTS_SUCCESS: null,
  GET_OFFER_PREVIEW_PRODUCTS_FAIL: null,

  SET_VOUCHER_SLIDERS_DATA: null,
});

const initialState = {
  campaignsAsyncState: initialAsyncState,
  campaignDetailAsyncState: initialAsyncState,
  offerProductsAsyncState: initialAsyncState,
  offerPreviewProductsAsyncState: initialAsyncState,

  campaigns: [],
  campaignBySlug: {},
  productsByCampaignName: {},

  voucherListsByVoucherSliderUuid: {},
};

const offerSearchParameter = (campaignNames) => ({
  disjunctiveFacetsRefinements: {
    'productOffer.offer.campaign.name': campaignNames,
  },
  attributesToRetrieve: [
    'objectID',
    'image',
    'productOffer',
    'title',
    'price',
    'categories',
  ],
  distinct: 10,
  hitsPerPage: 10,
});

export default function campaignReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.GET_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        campaigns: action.result,
        invalidated: false,
      };
      break;
    case AT.GET_CAMPAIGN_DETAIL_SUCCESS: {
      state = {
        ...state,
        campaignBySlug: {
          ...state.campaignBySlug,
          [action.result.slug]: action.result,
        },
      };
      break;
    }
    case AT.GET_OFFER_PRODUCTS_SUCCESS: {
      const productsByCampaignName = groupBy(
        action.result.content.hits,
        'productOffer.offer.campaign.name',
      );

      state = {
        ...state,
        productsByCampaignName,
      };
      break;
    }
    case AT.GET_OFFER_PREVIEW_PRODUCTS_SUCCESS: {
      const productsByCampaignName = groupBy(
        action.result.results,
        'productOffer.offer.campaign.name',
      );

      state = {
        ...state,
        productsByCampaignName,
      };
      break;
    }
    case AT.SET_VOUCHER_SLIDERS_DATA: {
      state = {
        ...state,
        voucherListsByVoucherSliderUuid: {
          ...state.voucherListsByVoucherSliderUuid,
          ...action.payload.voucherListsByVoucherSliderUuid,
        },
      };
      break;
    }
    default:
      break;
  }

  /** Async state update */
  switch (action.type) {
    case AT.GET_CAMPAIGNS:
    case AT.GET_CAMPAIGNS_SUCCESS:
    case AT.GET_CAMPAIGNS_FAIL:
      state = {
        ...state,
        campaignsAsyncState: asyncStateReducer(
          state.campaignsAsyncState,
          action,
          '_CAMPAIGNS_',
        ),
      };
      break;

    case AT.GET_CAMPAIGN_DETAIL:
    case AT.GET_CAMPAIGN_DETAIL_SUCCESS:
    case AT.GET_CAMPAIGN_DETAIL_FAIL:
      state = {
        ...state,
        campaignDetailAsyncState: asyncStateReducer(
          state.campaignDetailAsyncState,
          action,
          '_CAMPAIGN_DETAIL_',
        ),
      };
      break;

    case AT.GET_OFFER_PREVIEW_PRODUCTS:
    case AT.GET_OFFER_PREVIEW_PRODUCTS_SUCCESS:
    case AT.GET_OFFER_PREVIEW_PRODUCTS_FAIL:
      state = {
        ...state,
        offerPreviewProductsAsyncState: asyncStateReducer(
          state.offerPreviewProductsAsyncState,
          action,
          '_OFFER_PREVIEW_PRODUCTS_',
        ),
      };
      break;

    case AT.GET_OFFER_PRODUCTS:
    case AT.GET_OFFER_PRODUCTS_SUCCESS:
    case AT.GET_OFFER_PRODUCTS_FAIL:
      state = {
        ...state,
        offerProductsAsyncState: asyncStateReducer(
          state.offerProductsAsyncState,
          action,
          '_OFFER_PRODUCTS_',
        ),
      };
      break;
    default:
      break;
  }

  return state;
}

export function loadCampaigns() {
  return {
    types: [AT.GET_CAMPAIGNS, AT.GET_CAMPAIGNS_SUCCESS, AT.GET_CAMPAIGNS_FAIL],
    payload: {},
    promise: (client) =>
      client.get(`${config.API_URL_GOBLIN}/campaigns?format=json`),
    options: {
      transformer: (result) => {
        const newResult = result.results.map((campaign) => {
          const { endTime, ...rest } = campaign;
          const formattedEndTime = new Date(endTime).getTime() / 1000;
          return {
            endTime: formattedEndTime,
            ...rest,
          };
        });
        return sortBy(newResult, 'rank');
      },
    },
  };
}

export function loadCampaignDetail(slug) {
  const slugLowerCase = slug.toLowerCase();
  return {
    types: [
      AT.GET_CAMPAIGN_DETAIL,
      AT.GET_CAMPAIGN_DETAIL_SUCCESS,
      AT.GET_CAMPAIGN_DETAIL_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_KLAW}/microsites/${slugLowerCase}/`),
  };
}

export function loadOfferProducts(campaignNames) {
  return {
    types: [
      AT.GET_OFFER_PRODUCTS,
      AT.GET_OFFER_PRODUCTS_SUCCESS,
      AT.GET_OFFER_PRODUCTS_FAIL,
    ],
    promise: () =>
      fetchSearchResults(
        offerSearchParameter(campaignNames),
        ALGOLIA_DEFAULT_INDEX_NAME,
      ),
  };
}

export function loadOfferPreviewProducts(upcs) {
  return {
    types: [
      AT.GET_OFFER_PREVIEW_PRODUCTS,
      AT.GET_OFFER_PREVIEW_PRODUCTS_SUCCESS,
      AT.GET_OFFER_PREVIEW_PRODUCTS_FAIL,
    ],
    promise: () => fetchObjects(upcs),
  };
}

export function setVoucherSlidersData(voucherListsByVoucherSliderUuid) {
  return {
    type: AT.SET_VOUCHER_SLIDERS_DATA,
    payload: {
      voucherListsByVoucherSliderUuid,
    },
  };
}
