import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import superagent from 'superagent';

import config from 'config';

import {
  productCategoryTree,
  projectCategoryTree,
  threeDimensionalAssetCategoryTree,
} from 'app-libs/routes/fallbackCategoryTree';

export const K_TYPE_PROJECT_CATEGORY = 'project';
export const K_TYPE_PRODUCT_CATEGORY = 'product';
export const K_TYPE_3D_ASSET_CATEGORY = '3d_asset';

export const K_PRODUCT_CATEGORY_TREE_URL = `${config.API_URL_THANOS}/categories/?format=json`; // prettier-ignore
export const K_PROJECT_CATEGORY_TREE_URL = `${config.API_URL_CHITAURI}/category-tree/?format=json`; // prettier-ignore
export const K_PROJECT_3D_ASSET_TREE_URL = `${config.API_URL_GOBLIN}/3d-assets-category-tree/?format=json`; // prettier-ignore

const fallbackCategoryTreeByCategoryType = {
  [K_TYPE_PRODUCT_CATEGORY]: productCategoryTree,
  [K_TYPE_PROJECT_CATEGORY]: projectCategoryTree,
  [K_TYPE_3D_ASSET_CATEGORY]: threeDimensionalAssetCategoryTree,
};

class Category {
  constructor(type = K_TYPE_PRODUCT_CATEGORY) {
    this.categoryTree = {};
    this.fallbackCategoryTree = {};
    this.allBreadcrumbs = [];
    this.type = type;

    this.setFallbackCategoryTree();
  }

  setFallbackCategoryTree() {
    this.fallbackCategoryTree =
      fallbackCategoryTreeByCategoryType[this.type] || {};
  }

  initWithCategoryTree(categoryTree) {
    this.categoryTree = categoryTree;
    return this;
  }

  updateCategoryTree = (categoryTree) => {
    const isNewCategoryTree = !isEqual(this.categoryTree, categoryTree);

    this.categoryTree = categoryTree;
    if (isNewCategoryTree) {
      this.genBreadcrumbs();
    }
    return this;
  };

  fetchCategoryTree() {
    return getCategoryTree(this.type).then((result) =>
      this.initWithCategoryTree(result),
    );
  }

  genBreadcrumbs() {
    /**
     * Handle genBreadcrumbs which can be called before fetchCategoryTree finished in Non-SSR app
     */
    this.allBreadcrumbs = breadcrumbsFromCategoryTree(
      !isEmpty(this.categoryTree)
        ? this.categoryTree
        : this.fallbackCategoryTree,
    ).sort(sortByLvl0Lvl1Lvl2);
  }

  getBreadcrumbs() {
    if (!this.allBreadcrumbs.length) {
      this.genBreadcrumbs();
    }
    return this.allBreadcrumbs;
  }
}

// Singleton Pattern
// https://k94n.com/es6-modules-single-instance-pattern
export const category = new Category(K_TYPE_PRODUCT_CATEGORY);
export const projectCategory = new Category(K_TYPE_PROJECT_CATEGORY);
export const threeDimensionalAssetCategory = new Category(
  K_TYPE_3D_ASSET_CATEGORY,
);

/**
 * List all possible breadcrumbs
 *
 * Sample input:
 * "Dekor": {
        "Alas Kursi": {},
        "Dekorasi Ulang Tahun": {},
        "Jam": {
            "Jam Meja": {},
            "Aksesoris Jam": {},
            "Jam Digital": {},
            "Jam Dinding": {}
        },

 * Sample output:
    'Dekor',
    'Dekor > Alas Kursi',
    'Dekor > Dekorasi Ulang Tahun',
    'Dekor > Jam',
    'Dekor > Jam > Jam Meja',
    'Dekor > Jam > Aksesoris Jam',
    'Dekor > Jam > Jam Digital',
    'Dekor > Jam > Jam Dinding',
 */
export function breadcrumbsFromCategoryTree(tree) {
  const flattened = Object.entries(tree).map(([lvl0, lvl1]) => {
    if (!isEmpty(lvl1)) {
      const breadcrumbs = breadcrumbsFromCategoryTree(lvl1).map(
        (cat) => `${lvl0} > ${cat}`,
      );
      return [lvl0, ...breadcrumbs];
    }
    return lvl0;
  });
  return flattenDeep(flattened);
}

export function sortByLvl0Lvl1Lvl2(a, b) {
  return a.split(' > ').length - b.split(' > ').length;
}

export const getCategoryTree = (type) =>
  new Promise((resolve) => {
    let treeUrl = K_PRODUCT_CATEGORY_TREE_URL;
    let fallbackCategoryTree = productCategoryTree;
    if (type === K_TYPE_PROJECT_CATEGORY) {
      treeUrl = K_PROJECT_CATEGORY_TREE_URL;
      fallbackCategoryTree = projectCategoryTree;
    } else if (type === K_TYPE_3D_ASSET_CATEGORY) {
      treeUrl = K_PROJECT_3D_ASSET_TREE_URL;
      fallbackCategoryTree = threeDimensionalAssetCategoryTree;
    }
    // if (__DEVELOPMENT__) {
    //   resolve(fallbackCategoryTree);
    //   return fallbackCategoryTree;
    // }

    return superagent
      .get(treeUrl)
      .timeout(2000)
      .on('error', (error) => {
        console.error(
          `[category] error fetching category tree ${type} with error: ${error}`,
        );
        resolve(fallbackCategoryTree);
      })
      .end((err, response) => {
        // Category tree as depency for application (used for routing)
        // Will call `initializeCategoryTree` later.
        if (!err) {
          const categoryTree = response.body;
          resolve(categoryTree);
        } else {
          resolve(fallbackCategoryTree);
        }
      });
  });
