import config from 'config';

import { generateProductFilters } from 'app-libs/etc/productHelperV2';
import { K_DEFAULT_HITS_PER_PAGE } from 'app-libs/redux_modules/flow_modules/search';

import {
  K_DEFAULT_ROUTING_ZONE_CODE,
  K_MARKETPLACE_PRODUCT,
} from 'constants/productConstants';
import { K_FR_ALL } from 'constants/routeConstants';

export const HFR_KEY = 'hcategories_by_function';
export const PARAMETRIC_ROUTE = '/:lvl0';
export const DEFAULT_PARAMS = {
  dfr: `{"isBlacklisted":["-true"],"catalogueGroupNames":["${K_MARKETPLACE_PRODUCT}"],"isNotHavingActiveStock":["-true"],"isOfflineStockOnly":["-true"],"isNotAllowedToReceiveOrder":["-true]}`,
  page: 1,
  query: '',
  sort: config.ALGOLIA_DEFAULT_INDEX_NAME,
  [K_FR_ALL]: true,
  distinct: true,
};
export const DEFAULT_MAX_VALUES_PER_FACET = 100;

export const initialState = {
  index: config.ALGOLIA_DEFAULT_INDEX_NAME,
  query: '',
  distinct: true,
  hitsPerPage: K_DEFAULT_HITS_PER_PAGE,
  disjunctiveFacets: [
    'isBlacklisted',
    'catalogueGroupNames',
    'isNotHavingActiveStock',
    'isOfflineStockOnly',
    'isNotAllowedToReceiveOrder',
    'brand',
    'availablePromoTypes',
    'special',
    'color',
    'filteringAttributes.aPattern',
    'filteringAttributes.aStyle',
    'sectionAttributes.isNewProduct',
    'productOffer.offer.discountValue',
    'rating',
    'categories',
  ],
  hierarchicalFacets: [
    {
      name: 'hcategories_by_function',
      attributes: [
        'hcategories.lvl0',
        'hcategories.lvl1',
        'hcategories.lvl2',
        'hcategories.lvl3',
      ],
      sortBy: ['count:desc', 'name:asc'],
    },
  ],
  disjunctiveFacetsRefinements: {
    // - means exclude
    // src : https://github.com/algolia/instantsearch.js/issues/1232
    isBlacklisted: ['-true'],
    catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
    isOfflineStockOnly: ['-true'],
    isNotHavingActiveStock: ['-true'],
    isNotAllowedToReceiveOrder: ['-true'],
  },
  filters: generateProductFilters(K_DEFAULT_ROUTING_ZONE_CODE),
};

export const accessoriesProductInitialState = {
  index: config.ALGOLIA_DEFAULT_INDEX_NAME,
  query: '',
  distinct: true,
  hitsPerPage: K_DEFAULT_HITS_PER_PAGE,
  disjunctiveFacets: [
    'isBlacklisted',
    'catalogueGroupNames',
    'isNotHavingActiveStock',
    'isOfflineStockOnly',
    'isNotAllowedToReceiveOrder',
    'brand',
    'availablePromoTypes',
    'special',
    'color',
    'filteringAttributes.aPattern',
    'filteringAttributes.aStyle',
    'sectionAttributes.isNewProduct',
    'productOffer.offer.discountValue',
    'rating',
  ],
  hierarchicalFacets: [
    {
      name: 'hcategories_by_function',
      attributes: [
        'hcategories.lvl0',
        'hcategories.lvl1',
        'hcategories.lvl2',
        'hcategories.lvl3',
      ],
      sortBy: ['count:desc', 'name:asc'],
    },
  ],
  hierarchicalFacetsRefinements: {
    [HFR_KEY]: ['Aksesoris Rumah'],
  },

  disjunctiveFacetsRefinements: {
    // - means exclude
    // src : https://github.com/algolia/instantsearch.js/issues/1232
    isBlacklisted: ['-true'],
    catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
    isOfflineStockOnly: ['-true'],
    isNotHavingActiveStock: ['-true'],
    isNotAllowedToReceiveOrder: ['-true'],
  },
  filters: generateProductFilters(K_DEFAULT_ROUTING_ZONE_CODE),
};

export const brandingPushedProductInitialState = {
  index: config.ALGOLIA_DEFAULT_INDEX_NAME,
  query: '',
  distinct: true,
  hitsPerPage: 100,
  disjunctiveFacets: [
    'isBlacklisted',
    'catalogueGroupNames',
    'isNotHavingActiveStock',
    'isOfflineStockOnly',
    'isNotAllowedToReceiveOrder',
    'brand',
    'availablePromoTypes',
    'special',
    'color',
    'filteringAttributes.aPattern',
    'filteringAttributes.aStyle',
    'sectionAttributes.isNewProduct',
    'productOffer.offer.discountValue',
    'rating',
  ],
  hierarchicalFacets: [
    {
      name: 'hcategories_by_function',
      attributes: [
        'hcategories.lvl0',
        'hcategories.lvl1',
        'hcategories.lvl2',
        'hcategories.lvl3',
      ],
      sortBy: ['count:desc', 'name:asc'],
    },
  ],
  disjunctiveFacetsRefinements: {
    // - means exclude
    // src : https://github.com/algolia/instantsearch.js/issues/1232
    isBrandingPushed: ['true'],
    isBlacklisted: ['-true'],
    catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
    isOfflineStockOnly: ['-true'],
    isNotHavingActiveStock: ['-true'],
    isNotAllowedToReceiveOrder: ['-true'],
  },
  filters: generateProductFilters(K_DEFAULT_ROUTING_ZONE_CODE),
};

export const furnitureProductInitialState = {
  index: config.ALGOLIA_DEFAULT_INDEX_NAME,
  query: '',
  distinct: true,
  hitsPerPage: K_DEFAULT_HITS_PER_PAGE,
  disjunctiveFacets: [
    'isBlacklisted',
    'catalogueGroupNames',
    'isNotHavingActiveStock',
    'isOfflineStockOnly',
    'isNotAllowedToReceiveOrder',
    'brand',
    'availablePromoTypes',
    'special',
    'color',
    'filteringAttributes.aPattern',
    'filteringAttributes.aStyle',
    'sectionAttributes.isNewProduct',
    'productOffer.offer.discountValue',
    'rating',
  ],
  hierarchicalFacets: [
    {
      name: 'hcategories_by_function',
      attributes: [
        'hcategories.lvl0',
        'hcategories.lvl1',
        'hcategories.lvl2',
        'hcategories.lvl3',
      ],
      sortBy: ['count:desc', 'name:asc'],
    },
  ],
  hierarchicalFacetsRefinements: {
    [HFR_KEY]: ['Furniture'],
  },

  disjunctiveFacetsRefinements: {
    // - means exclude
    // src : https://github.com/algolia/instantsearch.js/issues/1232
    isBlacklisted: ['-true'],
    catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
    isOfflineStockOnly: ['-true'],
    isNotHavingActiveStock: ['-true'],
    isNotAllowedToReceiveOrder: ['-true'],
  },
  filters: generateProductFilters(K_DEFAULT_ROUTING_ZONE_CODE),
};

export const forcedState = {};
