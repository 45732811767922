import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

const BlockNavigationRoute = React.memo(
  ({ when, onBlockNavigation, shouldBlockNavigation }) => {
    const handleBlockNavigation = useCallback(
      (location) => {
        onBlockNavigation(location);
        return shouldBlockNavigation(location);
      },
      [onBlockNavigation, shouldBlockNavigation],
    );

    return (
      <Prompt
        when={when}
        message={(location) => handleBlockNavigation(location)}
      />
    );
  },
);

BlockNavigationRoute.propTypes = {
  when: PropTypes.bool,
  shouldBlockNavigation: PropTypes.func,
  onBlockNavigation: PropTypes.func.isRequired,
};

BlockNavigationRoute.defaultProps = {
  when: true,
  shouldBlockNavigation: () => false,
};

export default BlockNavigationRoute;
