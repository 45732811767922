import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import searchState from 'app-libs/SearchKit/reducer';
import auth from 'app-libs/redux_modules/auth';
import entities from 'app-libs/redux_modules/entities';
import entitiesStatus from 'app-libs/redux_modules/entitiesStatus';
import activity from 'app-libs/redux_modules/entity_modules/activity/reducer';
import advertisement from 'app-libs/redux_modules/entity_modules/advertisement';
import album from 'app-libs/redux_modules/entity_modules/album';
import basket from 'app-libs/redux_modules/entity_modules/basket';
import bundleOffer from 'app-libs/redux_modules/entity_modules/bundleOffer/reducer';
import campaign from 'app-libs/redux_modules/entity_modules/campaign';
import categoryAreaSeos from 'app-libs/redux_modules/entity_modules/categoryAreaSeo/reducer';
import categorySeo from 'app-libs/redux_modules/entity_modules/categorySeo';
import customer from 'app-libs/redux_modules/entity_modules/customer';
import flashSale from 'app-libs/redux_modules/entity_modules/flashSale/reducer';
import label from 'app-libs/redux_modules/entity_modules/label';
import location from 'app-libs/redux_modules/entity_modules/location/reducer';
import mattress from 'app-libs/redux_modules/entity_modules/mattress';
import membership from 'app-libs/redux_modules/entity_modules/membership';
import moodboard from 'app-libs/redux_modules/entity_modules/moodboard';
import order from 'app-libs/redux_modules/entity_modules/order';
import product from 'app-libs/redux_modules/entity_modules/product';
import productOption from 'app-libs/redux_modules/entity_modules/productOption';
import project from 'app-libs/redux_modules/entity_modules/project';
import promotion from 'app-libs/redux_modules/entity_modules/promotion';
import review from 'app-libs/redux_modules/entity_modules/review';
import shipping from 'app-libs/redux_modules/entity_modules/shipping';
import voucher from 'app-libs/redux_modules/entity_modules/voucher';
import experiments from 'app-libs/redux_modules/experiment_modules/experimentsReducer';
import area from 'app-libs/redux_modules/flow_modules/area';
import bank from 'app-libs/redux_modules/flow_modules/bank';
import browse from 'app-libs/redux_modules/flow_modules/browse';
import calendar from 'app-libs/redux_modules/flow_modules/calendar';
import category from 'app-libs/redux_modules/flow_modules/category';
import modal from 'app-libs/redux_modules/flow_modules/modal';
import notification from 'app-libs/redux_modules/flow_modules/notification';
import projectCategory from 'app-libs/redux_modules/flow_modules/projectCategory';
import search from 'app-libs/redux_modules/flow_modules/search';
import dimension from 'app-libs/redux_modules/helper_modules/dimension';
import queue from 'app-libs/redux_modules/helper_modules/queue';
import { installationReducer as installation } from 'app-libs/redux_modules/installation';
import profile from 'app-libs/redux_modules/profile_modules/';

const basketPersistConfig = {
  key: 'basket',
  whitelist: ['shippingAddress', 'paymentMethod'],
  storage,
};

const advertisementPersistConfig = {
  key: 'advertisement',
  whitelist: ['lastTimeClosePopupBanner'],
  storage,
};

const locationPersistConfig = {
  key: 'location',
  storage,
};

const reducers = {
  activity,
  auth,

  campaign,
  mattress,
  review,

  browse,
  search,
  category,
  projectCategory,
  area,
  bank,
  notification,
  modal,
  profile,

  advertisement: persistReducer(advertisementPersistConfig, advertisement),
  categorySeo,
  categoryAreaSeos,
  label,

  customer,
  basket: persistReducer(basketPersistConfig, basket),
  moodboard,
  product,
  order,
  membership,

  promotion,

  productOption,
  album,
  experiments,

  entities,
  entitiesStatus,

  queue,
  dimension,

  searchState,
  shipping,

  project,
  voucher,
  flashSale,
  bundleOffer,

  calendar,
  installation,
  location: persistReducer(locationPersistConfig, location),
};

export default combineReducers(reducers);
