import React, { memo, useMemo } from 'react';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

interface OfflineStoreLocationAreaListPageFooterProps {
  areaName: string;
}

const OfflineStoreLocationAreaListPageFooter: React.FC<OfflineStoreLocationAreaListPageFooterProps> =
  memo(({ areaName }) => {
    // @note(dika) 4 Nov 2024: Hardcoded as of now
    // we are not using article posts as footer anymore
    // instead we use template content
    const footerHtml = useMemo(() => {
      return {
        __html: `
      Ingin melihat langsung keunggulan furnitur premium dari Dekoruma? Yuk, kunjungi Toko Furniture ${areaName} Dekoruma. Di sini, Anda bisa mencoba kenyamanan setiap produk, mengecek kualitas materialnya secara langsung, dan menemukan koleksi eksklusif yang hanya tersedia di toko kami. Pengalaman ini tak bisa Anda dapatkan secara online!

      Selain itu, kami juga menawarkan penawaran khusus hanya untuk pengunjung toko! Dapatkan diskon eksklusif atau promo menarik yang hanya tersedia di Toko Furniture ${areaName} Dekoruma. Jangan lewatkan kesempatan ini untuk menemukan furnitur impian Anda dengan harga lebih terjangkau.

      Manfaatkan juga kesempatan untuk berkonsultasi langsung dengan tim ahli kami. Mereka siap membantu Anda menemukan solusi terbaik untuk kebutuhan furnitur dan desain interior Anda. Jadi, tunggu apa lagi? Segera datang ke Toko Furniture ${areaName} Dekoruma dan temukan inspirasi baru untuk hunian impian Anda.


      Kunjungi Toko Furniture ${areaName} Dekoruma

      Jadilah yang pertama mengetahui acara dan penawaran menarik di toko. Dapatkan penawaran di Toko Furniture ${areaName} Dekoruma. Nikmati fasilitas kenyamanan di Toko Furniture Dekoruma cabang ${areaName} dan sekitarnya. Pendapat anda tentang toko furniture Dekoruma di ${areaName}. 

      atau

      Jadilah yang pertama mengetahui acara dan penawaran menarik di toko. 
      Dapatkan penawaran di Toko Furniture ${areaName} Dekoruma. 
      Nikmati fasilitas kenyamanan di Toko Furniture Dekoruma cabang ${areaName} dan sekitarnya. 
      Sampaikan pendapat anda tentang toko furniture Dekoruma di ${areaName}.

      `,
      };
    }, [areaName]);

    const footerTitle = `Toko Furnitur di ${areaName}`;

    return (
      <View style={s.container}>
        <Text h2 theme="b4-neutral500" style={cStyles.tac}>
          {footerTitle}
        </Text>
        <Text theme="b5-neutral100">
          <div dangerouslySetInnerHTML={footerHtml} />
        </Text>
      </View>
    );
  });

const s = StyleSheet.create({
  container: {
    ...cStyles.pa16,
  },
});

export default OfflineStoreLocationAreaListPageFooter;
