import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useDimensions } from 'react-native-web-hooks';
import { useSelector } from 'react-redux';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { FlatList, Text, View } from '@dkrm/ui-kit-basic';

import { getIsOutOfStockByRoutingZoneCode } from 'app-libs/etc/productHelperV2';
import { useHistory } from 'app-libs/hook_modules/router';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';

const ProductRecommendationList = memo(
  ({
    baseUrl,
    contentStyle,
    isLoggedIn,
    pins,
    mainProduct,
    ProductCardComponent,
    recommendationProducts,
    productSection,
    RightButton,
    SubtitleComponent,
    showWishlistButton,
    title,
    titleStyle,
    headerStyle,
    containerStyle,
    toggleWishlistPin,
    trackingEventName,
    trackingReferrerName,
    trackOnPressProductChildren,
    shouldShowQuickAddToCart,
    onQuickAddToCart,
  }) => {
    const history = useHistory();
    const { window } = useDimensions();
    const { width } = window;

    const keyExtractor = (_product) => _product.objectID;

    const routingZoneCode = useSelector(getCurrentLocationRoutingZoneCode);

    const actionTrackPressedProductChildren = useCallback(
      (item, index) => {
        trackOnPressProductChildren({
          trackingEventName,
          mainProduct,
          product: item,
          productIndex: index + 1,
        });
      },
      [mainProduct, trackOnPressProductChildren, trackingEventName],
    );

    const renderItem = useCallback(
      ({ item, index }) => {
        return (
          <ProductCardComponent
            key={item.objectID}
            productSection={productSection}
            product={item}
            pinId={pins[item.objectID] ? pins[item.objectID].pk : null}
            showWishlistButton={showWishlistButton}
            toggleWishlistPin={toggleWishlistPin}
            history={history}
            isLoggedIn={isLoggedIn}
            containerStyle={[s.productCardContainer, { width: width / 2.4 }]}
            contentStyle={s.productCardContent}
            onPress={() => actionTrackPressedProductChildren(item, index)}
            referrer={trackingReferrerName}
            baseUrl={baseUrl}
            shouldShowSimilarProductsButton={false}
            shouldShowQuickAddToCart={shouldShowQuickAddToCart}
            onQuickAddToCart={onQuickAddToCart}
          />
        );
      },
      [
        productSection,
        pins,
        showWishlistButton,
        toggleWishlistPin,
        history,
        isLoggedIn,
        width,
        trackingReferrerName,
        baseUrl,
        shouldShowQuickAddToCart,
        onQuickAddToCart,
        actionTrackPressedProductChildren,
      ],
    );

    if (!recommendationProducts || !ProductCardComponent) return null;

    const inStockRecommendedProducts = recommendationProducts.filter(
      (product) => !getIsOutOfStockByRoutingZoneCode(product, routingZoneCode),
    );

    if (!inStockRecommendedProducts.length) return null;

    return (
      <View style={[s.container, containerStyle]}>
        <View style={[s.headerContainer, headerStyle]}>
          <View style={s.titleContainer}>
            <Text theme="h4" style={titleStyle}>
              {title}
            </Text>
            {RightButton}
          </View>
          {SubtitleComponent}
        </View>
        <FlatList
          data={inStockRecommendedProducts}
          renderItem={renderItem}
          horizontal
          contentContainerStyle={[s.listContentContainer, contentStyle]}
          keyExtractor={keyExtractor}
        />
      </View>
    );
  },
);

ProductRecommendationList.propTypes = {
  title: PropTypes.string.isRequired,
  recommendationProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  trackingReferrerName: PropTypes.string,
  trackingEventName: PropTypes.string,
  trackOnPressProductChildren: PropTypes.func,
  RightButton: PropTypes.element,
  SubtitleComponent: PropTypes.element,

  mainProduct: PropTypes.shape().isRequired,
  pins: PropTypes.shape(),
  showWishlistButton: PropTypes.bool,
  toggleWishlistPin: PropTypes.func,
  onQuickAddToCart: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  shouldShowQuickAddToCart: PropTypes.bool,

  titleStyle: stylePropTypes,
  contentStyle: stylePropTypes,
  headerStyle: stylePropTypes,
  containerStyle: stylePropTypes,

  baseUrl: PropTypes.string,
  ProductCardComponent: PropTypes.element.isRequired,
  productSection: PropTypes.string,
};

ProductRecommendationList.defaultProps = {
  baseUrl: '',
  pins: {},
  showWishlistButton: false,
  toggleWishlistPin: null,
  isLoggedIn: false,
  titleStyle: null,
  headerStyle: null,
  containerStyle: null,
  contentStyle: null,
  productSection: '',
  RightButton: null,
  SubtitleComponent: null,
  shouldShowQuickAddToCart: false,
  trackingEventName: null,
  trackingReferrerName: null,
  trackOnPressProductChildren: () => null,
  onQuickAddToCart: () => null,
};

const s = StyleSheet.create({
  container: {
    ...cStyles.pbm,
    ...cStyles.ptxl,
  },

  headerContainer: {
    ...cStyles.phxxl,
    ...cStyles.mbxxl,
  },

  titleContainer: {
    ...cStyles.flex1,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  listContentContainer: {
    ...cStyles.phxl,
  },

  productCardContainer: {
    maxWidth: 160,
  },

  productCardContent: {
    ...cStyles.pln,
  },

  separator: {
    ...cStyles.mvxxl,
  },
});

export default ProductRecommendationList;
