import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { OfflineStoreLocation } from 'entities/offlineStore/types';

interface OfflineStoreDescriptionProps {
  offlineStoreLocation: OfflineStoreLocation;
}

const OfflineStoreDescription: React.FC<OfflineStoreDescriptionProps> = memo(
  ({ offlineStoreLocation }) => {
    return (
      <View style={s.descriptionContainer}>
        <View style={s.storeTagContainer}>
          <View style={[s.tag, cStyles.mrs]}>
            <Text theme="body6-neutral100">Toko Furniture</Text>
          </View>
          {offlineStoreLocation.isSupportInteriorDesignService && (
            <View style={s.tag}>
              <Text theme="body6-neutral100">Desain Interior</Text>
            </View>
          )}
        </View>
        <Text theme="h6-teal500" style={s.text}>
          {offlineStoreLocation.storeName}
        </Text>
        <Text theme="body5-neutral100">{offlineStoreLocation.line1}</Text>
        <Text theme="body5-neutral100">{offlineStoreLocation.line2}</Text>
        <Text theme="body5-neutral100" style={s.text}>
          {offlineStoreLocation.line3}, {offlineStoreLocation.line4}
        </Text>
        {!!offlineStoreLocation.weekdayWorkingHourText && (
          <Text theme="h7-neutral500" style={s.text}>
            Weekdays: {offlineStoreLocation.weekdayWorkingHourText}
          </Text>
        )}
        {!!offlineStoreLocation.weekendWorkingHourText && (
          <Text theme="h7-neutral500">
            Weekend: {offlineStoreLocation.weekendWorkingHourText}
          </Text>
        )}
      </View>
    );
  },
);

const s = StyleSheet.create({
  descriptionContainer: cStyles.phm,
  text: cStyles.mbs,
  storeTagContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
    ...cStyles.flexWrap,
    alignItems: 'center',
  },
  tag: {
    ...cStyles.brs,
    ...cStyles.phs,
    ...cStyles.pvxs,
    ...cStyles.flexMiddle,
    ...cStyles.bwam,
    ...cStyles.mbm,
    borderColor: Colors.C_NEUTRAL_30,
  },
});

export default OfflineStoreDescription;
