/* eslint-disable camelcase */
import { Product } from 'app-libs/components/Product/types';
import { getFirstBreadcrumbFromProductCategories } from 'app-libs/etc/categoryHelper';
import { getOfferDiscountAmount } from 'app-libs/etc/productHelper';
import { getCartLinesValuePublic } from 'app-libs/redux_modules/entity_modules/selectors/project';

import { BasketLineProduct } from '../redux_modules/entity_modules/basket/types';
import { PortfolioProject, Project } from './types';

const K_PINTEREST_CAPI_CURRENCY_IDR = 'IDR';

export const genProduct = (
  product: Product | BasketLineProduct,
  quantity: number,
) => ({
  content_brand: product.brand,
  content_ids: [product.objectID],
  content_category: getFirstBreadcrumbFromProductCategories(product.categories),
  content_name: product.title,
  currency: K_PINTEREST_CAPI_CURRENCY_IDR,
  value: (product.priceNumber - getOfferDiscountAmount(product)).toString(),
  num_items: quantity,
});

export const genPortfolioProject = (project: PortfolioProject) => ({
  currency: K_PINTEREST_CAPI_CURRENCY_IDR,
  value: getCartLinesValuePublic(project).toString(),
  content_category: project.category,
  content_name: project.title,
  content_ids: [project.projectKey],
});

export const genProject = (project: Project) => ({
  currency: K_PINTEREST_CAPI_CURRENCY_IDR,
  value: parseFloat(project.dealValue).toString(),
  content_name: project.title,
  content_ids: [project.projectKey],
  /*
  @note (Radit) 3 Sep 2024: cannot use service_type and property_type as it is not allowed in pinterest api
   */
});
