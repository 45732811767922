/*
 In asyncState, these variable keys will be
 initialized when needed:

  loading: false,
  loaded: false,
  loadingError: null,

  creating: false,
  created: false,
  creatingError: null,

  updating: false,
  updated: false,
  updatingError: null,

  deleting: false,
  deleted: false,
  deletingError: null,
*/
export const initialAsyncState = {};

export const fetchingStatus = () => ({
  loading: true,
  loaded: false,
  error: null,
});

export const fetchSuccessStatus = () => ({
  loading: false,
  loaded: true,
  error: null,
});

export const fetchFailStatus = (error) => ({
  loading: false,
  loaded: false,
  error,
});

/**
 * Reducer to record async state
 * It uses regex to parse action.type and add appropriate state to the object
 * @example:
 *   -> GET***
 *   ->     ***SUCCESS
 *   ->     ***FAIL
 *   ->     ***
 *   etc...
 * It should only action with specific resourceName
 * @param {string} resourceName - when allowing GET_MOODBOARD_ITEM_SUCCESS, should block for _MOODBOARD_
 */
export default function asyncStateReducer(
  mutableState = initialAsyncState,
  action = {},
  resourceName,
) {
  if (!resourceName) {
    throw new Error('asyncStateReducer: Expected resourceName');
  }
  if (!isAllowed(action, resourceName)) return mutableState;

  let state = mutableState;
  if (
    !!action.type.match(/GET/i) ||
    !!action.type.match(/LOAD/i) ||
    !!action.type.match(/POST/i)
  ) {
    if (action.type.match(/SUCCESS/i)) {
      // case SUCCESS
      state = {
        ...state,
        loading: false,
        loaded: true,
        loadingError: undefined,
      };
    } else if (action.type.match(/FAIL/i)) {
      // case FAIL
      state = {
        ...state,
        loading: false,
        loaded: false,
        loadingError: action.error,
      };
    } else {
      // case LOAD
      state = {
        ...state,
        loading: true,
        loadingError: undefined,
      };
    }
    // console.log('is processing get', state);
  } else if (!!action.type.match(/POST/i) || !!action.type.match(/CREATE/i)) {
    if (action.type.match(/SUCCESS/i)) {
      // case SUCCESS
      state = {
        ...state,
        creating: false,
        created: true,
      };
    } else if (action.type.match(/FAIL/i)) {
      // case FAIL
      state = {
        ...state,
        creating: false,
        created: false,
        creatingError: action.error,
      };
    } else {
      // case LOAD
      state = {
        ...state,
        creating: true,
      };
    }
    // console.log('is processing post', state);
  } else if (action.type.match(/PUT/i) || action.type.match(/PATCH/i)) {
    if (action.type.match(/SUCCESS/i)) {
      // case SUCCESS
      state = {
        ...state,
        updating: false,
        updated: true,
      };
    } else if (action.type.match(/FAIL/i)) {
      // case FAIL
      state = {
        ...state,
        updating: false,
        updated: false,
        updatingError: action.error,
      };
    } else {
      // case LOAD
      state = {
        ...state,
        updating: true,
      };
    }
  } else if (action.type.match(/DELETE/i)) {
    if (action.type.match(/SUCCESS/i)) {
      // case SUCCESS
      state = {
        ...state,
        deleting: false,
        deleted: true,
      };
    } else if (action.type.match(/FAIL/i)) {
      // case FAIL
      state = {
        ...state,
        deleting: false,
        deleted: false,
        deletingError: action.error,
      };
    } else {
      // case LOAD
      state = {
        ...state,
        deleting: true,
      };
    }
  }
  return state;
}

/**
 * This function check whether action.type is allowed
 * if action.type's format follow <any><_SOME_RESOURCE_NAME_><any>, then it should be allowed
 */
// Quick test case
// () => {
//   console.log('it.should.to.be.true:    isAllowed(action, resourceName)');
//   console.log(isAllowed({type: 'LOAD_MOODBOARD'}, '_MOODBOARD_') === true);
//   console.log(isAllowed({type: 'LOAD_MOODBOARD_SUCCESS'}, '_MOODBOARD_') === true);
//   console.log(isAllowed({type: 'LOAD_MOODBOARDS'}, '_MOODBOARD_') === false);
//   console.log(isAllowed({type: 'LOAD_MOODBOARD_BABA'}, '_MOODBOARD_') === false);
//   console.log(isAllowed({type: 'LOAD_MOODBOARD_BABA'}, '_MOODBOARD_BABA_') === true);
//   console.log(isAllowed({type: 'LOAD_MOODBOARD_BABA_SUCCESS'}, '_MOODBOARD_BABA_') === true);
//   console.log(isAllowed({type: 'LOAD_BABA'}, '_MOODBOARD_') === false);
// }();
function isAllowed(action = {}, resourceName) {
  const splitResourceName = resourceName.split('_');
  if (splitResourceName.length < 3) return false;

  const splitActionType = action.type.split('_');
  if (splitActionType.length < 2) return false;
  const nActionType = splitActionType
    .slice(1, splitResourceName.length - 1)
    .join('');
  const nResourceName = splitResourceName.join('');
  const splitActionTypeLeftOver = splitActionType.slice(
    splitResourceName.length - 1,
  );
  const nSplitActionTypeLeftOver = splitActionTypeLeftOver.join('');
  const leftOverIsAllowed =
    nSplitActionTypeLeftOver === '' ||
    nSplitActionTypeLeftOver === 'SUCCESS' ||
    nSplitActionTypeLeftOver === 'FAIL';
  if (nActionType === nResourceName && leftOverIsAllowed) return true;
  return false;
}
