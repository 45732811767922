import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ErrorView } from '@dkrm/ui-kit-basic';
import { ModalEnhanced } from '@dkrm/ui-kit-basic/v2';
import NavbarErrorPrompt from 'app-libs/components/NavbarImplementation/NavbarErrorPrompt';

import { connect } from 'react-redux';
import * as actions from 'app-libs/redux_modules/flow_modules/notification';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

@connect(
  (state) => ({ notification: state.notification.prompt }),
  { hideNotificationPrompt: actions.hideNotificationPrompt },
)
export default class ErrorPrompt extends Component {
  static propTypes = {
    notification: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
      type: PropTypes.string,
    }),
    linkUrl: PropTypes.string,
    hideNotificationPrompt: PropTypes.func.isRequired,
  };

  static defaultProps = {
    notification: {},
    linkUrl: '/',
  };

  getTitle() {
    const title = get(this.props, 'notification.title');
    if (title.startsWith('Algolia')) return 'Gagal melakukan pencarian';
    return title;
  }

  getMessage() {
    const message = get(this.props, 'notification.message');
    return `${message}\n\nBantu kami menyelesaikan permasalahan ini dengan melakukan screenshot dan mengirim email ke hello@dekoruma.com.`;
  }

  actionHideNotification = () => {
    this.props.hideNotificationPrompt();
  };

  render() {
    const { notification } = this.props;
    if (isEmpty(notification)) return null;
    if (notification.type !== 'error') return null;
    return (
      <ModalEnhanced isVisible>
        <NavbarErrorPrompt onCloseButtonPress={this.actionHideNotification}>
          <ErrorView
            title={this.getTitle()}
            message={this.getMessage().substring(0, 500)}
            linkUrl={this.props.linkUrl}
          />
        </NavbarErrorPrompt>
      </ModalEnhanced>
    );
  }
}
