/*
  ===== REDUX STATE (check each reducer for more details) ======
  {
    ...
    profile: {
      userDetail:{
        byUserUuid: {
          '4dcf86ce-b919-43fa-93a0-d78790196acd': {
            firstName: 'David',
            lastName: 'Kwan',
          },
        },
      }
    }
  }
  ==============================================================
*/
import { combineReducers } from 'redux';

import designerProfileReducer from './reducers/designerProfile';
import userDetailReducer from './reducers/userDetail';

const reducers = combineReducers({
  userDetail: userDetailReducer,
  designerProfile: designerProfileReducer,
});

export default reducers;
