import get from 'lodash/get';

import { K_DISJUNCTIVE_FACET_BY_CATEGORY } from 'constants/disjunctiveFacetConstants';

export function getCurrentCategory(state) {
  const breadcrumb = get(
    get(state, 'hierarchicalFacetsRefinements', {}),
    'hcategories_by_function',
    [],
  )[0];
  if (!breadcrumb) {
    return '';
  }
  const cat = breadcrumb.split(' > ');
  return cat[cat.length - 1];
}

export function getAddedDisjunctiveFacet(state, defaultCategory = '') {
  return (
    K_DISJUNCTIVE_FACET_BY_CATEGORY[
      getCurrentCategory(state) || defaultCategory
    ] || []
  );
}
