import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import zipWith from 'lodash/zipWith';

import { getImageName, makeParametricUrl, slugify } from 'app-libs/etc';
import { isFurnitureCategory } from 'app-libs/etc/layoutHelper';
import { isMattressCategory } from 'app-libs/etc/mattressHelper';
import {
  getAttributeValueOf,
  getOptionAttributes,
} from 'app-libs/etc/productAttributesUtil';
// eslint-disable-next-line import/no-cycle
import {
  getProductAttributes,
  getProductCategory,
  getProductColorAttributes,
  getProductFirmness,
  getProductHeightSize,
  getProductLengthSize,
  getProductMaterial,
  getProductPattern,
  getProductPrice,
  getProductQuality,
  getProductRoom,
  getProductStyle,
  getProductSubStyle,
  getProductWidthSize,
} from 'app-libs/redux_modules/entity_modules/selectors/product';

import { K_IMAGE_BUNDLE_OFFER_URL } from 'constants/assetConstants';
import {
  K_A_O_PILIH_BARANG_LAIN,
  K_A_WARRANTY_DESC,
  K_A_WARRANTY_LONG,
  K_COLOR_SCORE,
  K_FBT_BRAND_SCORE,
  K_FBT_CATEGORY_SCORE,
  K_FBT_OFFER_SCORE,
  K_FBT_PAIRED_CATEGORY_SCORE,
  K_FBT_PRICE_SCORE,
  K_FBT_SIZE_LABEL_SCORE,
  K_FBT_STYLE_SCORE,
  K_FBT_SUBSTYLE_SCORE,
  K_FIRMNESS_SCORE,
  K_MARKETPLACE_PRODUCT,
  K_MATERIAL_SCORE,
  K_MIN_REVIEW_SCORE,
  K_PATTERN_SCORE,
  K_PRICE_SCORE,
  K_QUALITY_SCORE,
  K_ROOM_SCORE,
  K_SIZE_SCORE,
  K_STYLE_SCORE,
} from 'constants/productConstants';
import {
  K_HOST,
  K_ROUTE_HOME,
  K_ROUTE_MATTRESS,
  K_ROUTE_PRODUCT,
  K_ROUTE_SUPPLY_WAREHOUSE,
} from 'constants/routeConstants';

export const K_DEFAULT_PRODUCT_PROPERTIES = [
  'objectID',
  'categories',
  'title',
  'brand',
  'price',
  'image',
  'userWhoViewedThisAlsoViewedItemsIds',
];

export function getIsMarketplaceProduct(product) {
  const catalogueGroupNames = product?.catalogueGroupNames || [];
  return catalogueGroupNames.some(
    (catalogueGroupName) => catalogueGroupName === K_MARKETPLACE_PRODUCT,
  );
}

export function isMattressOrFurnitureCategory(product) {
  if (!has(product, 'categories')) return false;
  const { categories } = product;
  return isMattressCategory(categories) || isFurnitureCategory(categories);
}

export function getOfferDiscountAmount(product) {
  if (isEmpty(product.productOffer)) return 0;
  return product.productOffer.discount;
}

export function virtualizeProduct(product) {
  const res = Object.assign({}, product);
  if (has(product, 'image') && product.image) {
    res.imageName = getImageName(product.image);
  }
  return res;
}

export const beddingRatingLabelMapping = {
  softness: 'Kelembutan',
  coolness: 'Kesejukan',
  sameAsPicture: 'Sesuai Gambar',
};

/**
 * Example return (in string):
 *  /p/1021/super-duper-items
 */
export function genProductUrlWithoutHost(product, baseUrl = '') {
  const { objectID, title, categories } = product;

  let productURL = '';
  if (baseUrl) {
    productURL = baseUrl + K_ROUTE_PRODUCT;
  } else {
    const suppyWhProductURL =
      K_ROUTE_HOME + K_ROUTE_SUPPLY_WAREHOUSE + K_ROUTE_PRODUCT;
    const marketPlaceProductURL = isMattressCategory(categories)
      ? K_ROUTE_MATTRESS + K_ROUTE_PRODUCT
      : K_ROUTE_PRODUCT;

    productURL = isMaterialProduct(product)
      ? suppyWhProductURL
      : marketPlaceProductURL;
  }

  const url = makeParametricUrl(productURL, {
    pid: objectID,
    pslug: slugify(title),
  });
  return url;
}

/**
 * Example return (in string):
 *  /p/1021/super-duper-items
 */
export function genProductUrlV2WithoutHost(product, baseUrl = '') {
  const { objectID, title, categories } = product;

  let productURL = '';
  if (baseUrl) {
    productURL = `${baseUrl}/p2/:pid/:pslug`;
  } else {
    const suppyWhProductURL =
      K_ROUTE_HOME + K_ROUTE_SUPPLY_WAREHOUSE + K_ROUTE_PRODUCT;
    const marketPlaceProductURL = isMattressCategory(categories)
      ? `${K_ROUTE_MATTRESS}/p2/:pid/:pslug`
      : '/p2/:pid/:pslug';

    productURL = isMaterialProduct(product)
      ? suppyWhProductURL
      : marketPlaceProductURL;
  }

  const url = makeParametricUrl(productURL, {
    pid: objectID,
    pslug: slugify(title),
  });
  return url;
}

/**
 * Example return (in string):
      http://www.dekoruma.com/p/1021/super-duper-items
 */

export function genProductUrl(product, baseUrl = '') {
  const url = K_HOST + genProductUrlWithoutHost(product, baseUrl);
  return url;
}

export function genProductFromProps(
  props,
  properties = K_DEFAULT_PRODUCT_PROPERTIES,
) {
  return properties
    .map((property) => ({ [property]: props[property] }))
    .reduce((accumulator, current) => ({ ...accumulator, ...current }));
}

/**
 * Disjunctive facet
 */
export function getScoreDisjuctiveFacetData(result) {
  if (isEmpty(result)) return {};
  return (
    (result.disjunctiveFacets.find((facet) => facet.name === 'score') || {})
      .data || {}
  );
}

export function getProductRating(searchResult, totalReview) {
  const scoreData = getScoreDisjuctiveFacetData(searchResult);
  const scoreSum = Object.keys(scoreData).reduce((previous, curr) => {
    let prev = previous;
    if (curr >= K_MIN_REVIEW_SCORE) prev += scoreData[curr] * curr;
    return prev;
  }, 0);
  return (scoreSum * 1.0) / totalReview;
}

export function getProductReviewImages(userReviews) {
  const images = [];
  userReviews.forEach((review) => {
    if (review.score >= K_MIN_REVIEW_SCORE) {
      images.push(...get(review, 'reviewImages', []));
    }
  });
  return images;
}

export function getNormalizedEuclideanDistanceOfTwoArrayOfNumbers(X, Y) {
  /** https://reference.wolfram.com/language/ref/NormalizedSquaredEuclideanDistance.html */

  const sumX = X.reduce((x, y) => x + y, 0);
  const sumY = Y.reduce((x, y) => x + y, 0);
  const { length } = X;
  const numerators = zipWith(
    X,
    Y,
    (x, y) => Math.pow(x + ((1 / length) * -sumX - y + (1 / length) * sumY), 2), // eslint-disable-line no-restricted-properties
  );
  const numerator = numerators.reduce((x, y) => x + y, 0);
  const denominatorsX = X.map((x) => Math.pow(x + (1 / length) * -sumX, 2)); // eslint-disable-line no-restricted-properties
  const denominatorsY = Y.map((y) => Math.pow(y + (1 / length) * -sumY, 2)); // eslint-disable-line no-restricted-properties
  const denominator =
    2 *
    (denominatorsX.reduce((x, y) => x + y, 0) +
      denominatorsY.reduce((x, y) => x + y, 0));
  return numerator / denominator;
}

export function getSimilarityColorScoreOfTwoProducts(productOne, productTwo) {
  return isEqual(
    getProductColorAttributes(productOne).toLowerCase(),
    getProductColorAttributes(productTwo).toLowerCase(),
  )
    ? K_COLOR_SCORE
    : 0;
}

export function getSimilarityStyleScoreOfTwoProducts(productOne, productTwo) {
  const styleProductOne = getProductStyle(productOne).toLowerCase();
  const styleProductTwo = getProductStyle(productTwo).toLowerCase();
  return styleProductOne && isEqual(styleProductOne, styleProductTwo)
    ? K_STYLE_SCORE
    : 0;
}

export function getSimilarityQualityOfTwoProducts(productOne, productTwo) {
  const qualityProductOne = getProductQuality(productOne).toLowerCase();
  const qualityProductTwo = getProductQuality(productTwo).toLowerCase();
  return qualityProductOne && isEqual(qualityProductOne, qualityProductTwo)
    ? K_QUALITY_SCORE
    : 0;
}

export function getSimilarityPriceScoreOfTwoProducts(productOne, productTwo) {
  const priceProductOne = parseInt(getProductPrice(productOne), 10);
  const priceProductTwo = parseInt(getProductPrice(productTwo), 10);
  return (
    (1 -
      Math.abs(
        (priceProductOne - priceProductTwo) /
          Math.max(priceProductOne, priceProductTwo),
      )) *
    K_PRICE_SCORE
  );
}

export function getSimilarityMaterialScoreOfTwoProducts(
  productOne,
  productTwo,
) {
  const productOneMaterials = getProductMaterial(productOne)
    .toLowerCase()
    .split(', ');
  const productTwoMaterials = getProductMaterial(productTwo)
    .toLowerCase()
    .split(', ');
  const lengthOfProductOneMaterials = productOneMaterials.length;
  const lengthOfSimilarMaterials = productOneMaterials.filter((material) =>
    productTwoMaterials.includes(material),
  ).length;
  return (
    (lengthOfSimilarMaterials / lengthOfProductOneMaterials) * K_MATERIAL_SCORE
  );
}

export function getSimilarityRoomScoreOfTwoProducts(productOne, productTwo) {
  const productOneRooms = getProductRoom(productOne).toLowerCase().split(', ');
  const productTwoRooms = getProductRoom(productTwo).toLowerCase().split(', ');
  const lengthOfProductOneRooms = productOneRooms.length;
  const lengthOfSimilarRooms = productOneRooms.filter((room) =>
    productTwoRooms.includes(room),
  ).length;
  return productOneRooms
    ? (lengthOfSimilarRooms / lengthOfProductOneRooms) * K_ROOM_SCORE
    : 0;
}

export function getSimilarityPatternScoreOfTwoProducts(productOne, productTwo) {
  const patternProductOne = getProductPattern(productOne).toLowerCase();
  const patternProductTwo = getProductPattern(productTwo).toLowerCase();
  return patternProductOne && isEqual(patternProductOne, patternProductTwo)
    ? K_PATTERN_SCORE
    : 0;
}

export function getSimilarityFirmnessScoreOfTwoProducts(
  productOne,
  productTwo,
) {
  const firmnessProductOne = getProductFirmness(productOne).toLowerCase();
  const firmnessProductTwo = getProductFirmness(productTwo).toLowerCase();
  return firmnessProductOne && isEqual(firmnessProductOne, firmnessProductTwo)
    ? K_FIRMNESS_SCORE
    : 0;
}

export function getSimilaritySizeScoreOfTwoProducts(productOne, productTwo) {
  const sizeProductOne = [
    parseInt(getProductLengthSize(productOne), 10),
    parseInt(getProductWidthSize(productOne), 10),
    parseInt(getProductHeightSize(productOne), 10),
  ];
  const sizeProductTwo = [
    parseInt(getProductLengthSize(productTwo), 10),
    parseInt(getProductWidthSize(productTwo), 10),
    parseInt(getProductHeightSize(productTwo), 10),
  ];
  const score =
    1 -
    getNormalizedEuclideanDistanceOfTwoArrayOfNumbers(
      sizeProductOne,
      sizeProductTwo,
    );
  return Number.isNaN(score) ? 0 : score * K_SIZE_SCORE;
}

export function getSimilarityScoreOfTwoProducts(productOne, productTwo) {
  const colorScore = getSimilarityColorScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const materialScore = getSimilarityMaterialScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const patternScore = getSimilarityPatternScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const firmnessScore = getSimilarityFirmnessScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const priceScore = getSimilarityPriceScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const sizeScore = getSimilaritySizeScoreOfTwoProducts(productOne, productTwo);
  const roomScore = getSimilarityRoomScoreOfTwoProducts(productOne, productTwo);
  const styleScore = getSimilarityStyleScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const qualityScore = getSimilarityQualityOfTwoProducts(
    productOne,
    productTwo,
  );
  const product = productTwo;
  product.similarScores = {
    colorScore,
    materialScore,
    patternScore,
    firmnessScore,
    priceScore,
    sizeScore,
    roomScore,
    styleScore,
    qualityScore,
    sumScore:
      colorScore +
      materialScore +
      patternScore +
      firmnessScore +
      priceScore +
      sizeScore +
      roomScore +
      styleScore +
      qualityScore,
  };
  return product;
}

export function getProductsSortedBySimilarity(products, mainProduct) {
  const scoredSimilarProducts = products.map((item) =>
    getSimilarityScoreOfTwoProducts(mainProduct, item),
  );
  scoredSimilarProducts.sort(
    (x, y) => y.similarScores.sumScore - x.similarScores.sumScore,
  );
  return scoredSimilarProducts;
}

/** Bought Together ranking-related functions */
export function getFBTScoreOfTwoProducts(
  productOne,
  productTwo,
  pairedCategories = [],
) {
  const substyleScore = getFBTSubStyleScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const styleScore = getFBTStyleScoreOfTwoProducts(productOne, productTwo);
  const brandScore = getFBTBrandScoreOfTwoProducts(productOne, productTwo);
  const sizeLabelScore = getFBTSizeLabelScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const priceScore = getSimilarityPriceScoreOfTwoProducts(
    productOne,
    productTwo,
  );
  const categoryScore = getFBTCategoryScoreOfTwoProducts(
    productOne,
    productTwo,
    pairedCategories,
  );
  const offerScore = getFBTOfferScoreOfSecondProduct(productTwo);
  const product = productTwo;
  product.fbtScores = {
    substyleScore,
    styleScore,
    brandScore,
    sizeLabelScore,
    priceScore,
    categoryScore,
    offerScore,
    sumScore:
      substyleScore +
      styleScore +
      brandScore +
      sizeLabelScore +
      priceScore +
      categoryScore +
      offerScore,
  };
  return product;
}

export function getProductsSortedByFBTScore(
  product,
  products,
  pairedCategories = [],
) {
  const scoredProducts = products.map((item) =>
    getFBTScoreOfTwoProducts(product, item, pairedCategories),
  );
  scoredProducts.sort((x, y) => y.fbtScores.sumScore - x.fbtScores.sumScore);
  return scoredProducts;
}

export function getRecommendedSizeLabelsFromProduct(product) {
  const sizeLabel = get(product, 'sizeLabel', '');
  if (sizeLabel === 'Medium') return ['-Large'];
  if (sizeLabel === 'Small') return ['-Large', '-Medium'];
  return null;
}

export function getFBTCategoryScoreOfTwoProducts(
  productOne,
  productTwo,
  pairedCategories,
) {
  const categoryOne = getProductCategory(productOne).split(' > ');
  const categoryTwo = getProductCategory(productTwo).split(' > ');
  if (!categoryOne.length || !categoryTwo.length) return 0;
  const pairedCategoryIdx = pairedCategories.findIndex((category) =>
    categoryTwo.includes(category),
  );
  if (pairedCategoryIdx > -1) {
    const numOfCategories = pairedCategories.length;
    return (
      ((numOfCategories - pairedCategoryIdx) / numOfCategories) *
      K_FBT_PAIRED_CATEGORY_SCORE
    );
  }
  /** Don't score category if big volume items */
  const isBigVolumeItem = isMattressOrFurnitureCategory(productOne);
  if (isBigVolumeItem) return 0;
  return (
    (categoryOne.filter((cat) => categoryTwo.includes(cat)).length /
      categoryOne.length) *
    K_FBT_CATEGORY_SCORE
  );
}

export function getFBTSubStyleScoreOfTwoProducts(productOne, productTwo) {
  const substyleProductOne = getProductSubStyle(productOne).toLowerCase();
  const substyleProductTwo = getProductSubStyle(productTwo).toLowerCase();
  return substyleProductOne && isEqual(substyleProductOne, substyleProductTwo)
    ? K_FBT_SUBSTYLE_SCORE
    : 0;
}

export function getFBTStyleScoreOfTwoProducts(productOne, productTwo) {
  const styleProductOne = getProductSubStyle(productOne).toLowerCase();
  const styleProductTwo = getProductSubStyle(productTwo).toLowerCase();
  return styleProductOne && isEqual(styleProductOne, styleProductTwo)
    ? K_FBT_STYLE_SCORE
    : 0;
}

export function getFBTSizeLabelScoreOfTwoProducts(productOne, productTwo) {
  const sizeLabelOne = get(productOne, 'sizeLabel', '');
  const sizeLabelTwo = get(productTwo, 'sizeLabel', '');
  const recommendedSizeLabels = getRecommendedSizeLabelsFromProduct(productOne);
  if (isEqual(sizeLabelOne, sizeLabelTwo)) return K_FBT_SIZE_LABEL_SCORE;
  if (recommendedSizeLabels) {
    if (
      getRecommendedSizeLabelsFromProduct(productOne).findIndex((label) =>
        label.includes(sizeLabelTwo),
      ) < 0
    ) {
      return 0.5 * K_FBT_SIZE_LABEL_SCORE;
    }
  }
  if (!sizeLabelOne || !sizeLabelTwo) return 0.3 * K_FBT_SIZE_LABEL_SCORE;
  return 0;
}

export function getFBTBrandScoreOfTwoProducts(productOne, productTwo) {
  const brandOne = get(productOne, 'brand', '');
  const brandTwo = get(productTwo, 'brand', '');
  return brandOne && isEqual(brandOne, brandTwo) ? K_FBT_BRAND_SCORE : 0;
}

export function getFBTPriceScoreOfTwoProducts(productOne, productTwo) {
  const priceProductOne = parseInt(getProductPrice(productOne), 10);
  const priceProductTwo = parseInt(getProductPrice(productTwo), 10);
  return (
    (1 -
      Math.abs(
        (priceProductOne - priceProductTwo) /
          Math.max(priceProductOne, priceProductTwo),
      )) *
    K_FBT_PRICE_SCORE
  );
}

export function getFBTOfferScoreOfSecondProduct(productTwo) {
  return productTwo?.productOffer?.discount ? K_FBT_OFFER_SCORE : 0;
}

export function getProductOptionValuesByUpcFromAttributes(products) {
  const optionValuesByUpc = {};
  Object.values(products).forEach((product) => {
    const attributes = getProductAttributes(product);
    const optionAttributes = getOptionAttributes(attributes);
    const formattedOptionByName = {};
    optionAttributes.forEach((attr) => {
      if (attr.code === K_A_O_PILIH_BARANG_LAIN) return;
      Object.assign(formattedOptionByName, { [attr.name]: attr.value });
    });
    optionValuesByUpc[product.objectID] = formattedOptionByName;
  });
  return optionValuesByUpc;
}

export function getWarrantyProductDuration(attributes) {
  return getAttributeValueOf(attributes, K_A_WARRANTY_LONG);
}

export function getWarrantyProductDescription(attributes) {
  return getAttributeValueOf(attributes, K_A_WARRANTY_DESC);
}

/** supply warehouse product */
export function isMaterialProduct(product) {
  return get(product, 'productType.code') === 'CO';
}

export function getActiveFlashSaleItem(product) {
  const flashSaleItems = product?.flashSaleItems || [];
  const nowTimestamp = Date.now() / 1000;

  // assume no 2+ flash sales live in the same time
  return flashSaleItems.find(
    (flashSaleItem) =>
      flashSaleItem.dateStartTimestamp <= nowTimestamp &&
      flashSaleItem.dateEndTimestamp > nowTimestamp,
  );
}
export function isInstallationProduct(product) {
  // for now the only way we can know installation is from the categories
  return product?.categories?.[0] === 'Jasa';
}

export const isSpecialOffer = (product, minimumDiscountForSpecialOffer) =>
  product?.productOffer?.offer?.discountValue >= minimumDiscountForSpecialOffer;

export function getProductBundleOfferSticker(product) {
  if (product.hasBundleOffer) {
    return K_IMAGE_BUNDLE_OFFER_URL;
  }
  return null;
}

export const getCategoryFromCategoryBreadcrumb = (categoryBreadcrumb) => {
  const categories = categoryBreadcrumb.split(' > ');
  if (categories?.[1] === 'Kasur') return 'Kasur';
  return categories[categories.length - 1];
};

export const getTotalValueFromProducts = (products) => {
  return products.reduce(
    (result, curr) => result + curr.priceNumber - getOfferDiscountAmount(curr),
    0,
  );
};

export const getBasketLinesTotalValue = (lines) => {
  return lines.reduce(
    (result, curr) =>
      result +
      curr.quantity *
        (curr.product.priceNumber - getOfferDiscountAmount(curr.product)),
    0,
  );
};

export const getSplitProductCategories = (product) =>
  (product.categories?.[0] || '').split(' > ');

export const isFurnitureProduct = (product) =>
  getSplitProductCategories(product)?.[0] === 'Furniture';
