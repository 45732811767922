import config from 'config';
import { ApiClient } from 'types';

import { fetchSearchResults } from '../../helper_modules/search/algoliaWrapper';
import { FlashSaleItemUserQuantity } from './types';

export const loadFlashSalesAPI = () => {
  const nowTimestamp = Date.now() / 1000;
  const dateStartTimestampNumericRefinementValue =
    nowTimestamp + 7 * 24 * 60 * 60; // 7 days
  const dateEndTimestampNumericRefinementValue = nowTimestamp;

  const searchParameters = {
    attributesToRetrieve: [
      'dateStartTimestamp',
      'dateEndTimestamp',
      'flashSaleItems',
      'id',
    ],
    numericRefinements: {
      dateStartTimestamp: { '<=': [dateStartTimestampNumericRefinementValue] },
      dateEndTimestamp: { '>=': [dateEndTimestampNumericRefinementValue] },
    },
    hitsPerPage: 10,
  };

  return fetchSearchResults(
    searchParameters,
    config.ALGOLIA_FLASH_SALE_INDEX_NAME,
  );
};

export const loadFlashSaleItemUserQuantityAPI = (
  client: ApiClient<FlashSaleItemUserQuantity>,
  flashSaleItemId: number,
) =>
  client.get(
    `${config.API_URL_GOBLIN}/flash-sale-items/${flashSaleItemId}/quantity/`,
  );
