import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { ScrollView, StyleSheet, Text } from '@dkrm/ui-kit-basic';

import LoadingModal from 'app-libs/components/LoadingModal';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { loadBasket } from 'app-libs/redux_modules/entity_modules/basket';
import { setDefaultShippingAddress } from 'app-libs/redux_modules/entity_modules/customer';
import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';
import { setCurrentLocation } from 'app-libs/redux_modules/entity_modules/location/reducer';
import { getCustomerShippingAddresses } from 'app-libs/redux_modules/entity_modules/selectors/customer';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';

import { useInvalidateBasketStockRecordRelatedQueries } from 'entities/basket/utils';

import UserAddressCard from '../UserAddressCard';

interface UserAddressListProps {
  onClose: () => void;
}

const UserAddressList: React.FC<UserAddressListProps> = memo(({ onClose }) => {
  const dispatch = useDispatch();
  const [
    shouldShowLoadingModal,
    actionShowLoadingModal,
    actionHideLoadingModal,
  ] = useVisibility(false);

  const customerShippingAddresses = useSelector<
    void,
    UserAddressResponseFromGoblin[]
  >(getCustomerShippingAddresses);

  const actionInvalidateBasketStockRecordRelatedQueries =
    useInvalidateBasketStockRecordRelatedQueries();

  const {
    customerShippingAddressDefaultForShipping,
    otherCustomerShippingAddresses,
  }: {
    customerShippingAddressDefaultForShipping: UserAddressResponseFromGoblin | null;
    otherCustomerShippingAddresses: UserAddressResponseFromGoblin[];
  } = useMemo(() => {
    let _customerShippingAddressDefaultForShipping: UserAddressResponseFromGoblin | null =
      null;
    const _otherCustomerShippingAddresses: UserAddressResponseFromGoblin[] = [];

    customerShippingAddresses.forEach((customerAddress) => {
      if (customerAddress.isDefaultForShipping) {
        _customerShippingAddressDefaultForShipping = customerAddress;
      } else {
        _otherCustomerShippingAddresses.push(customerAddress);
      }
    });

    return {
      customerShippingAddressDefaultForShipping:
        _customerShippingAddressDefaultForShipping,
      otherCustomerShippingAddresses: _otherCustomerShippingAddresses,
    };
  }, [customerShippingAddresses]);

  const handleAddressPress = useCallback(
    async (userAddress: UserAddressResponseFromGoblin) => {
      actionShowLoadingModal();

      const res = await dispatch(
        dontShowPromptOnError(setDefaultShippingAddress(userAddress.id)),
      );
      if (res && res.result) {
        dispatch(loadBasket());
      } else {
        dispatch(setCurrentLocation(userAddress.shippingArea, true));
      }

      actionInvalidateBasketStockRecordRelatedQueries();
      actionHideLoadingModal();
      onClose();
    },
    [
      actionHideLoadingModal,
      actionShowLoadingModal,
      dispatch,
      onClose,
      actionInvalidateBasketStockRecordRelatedQueries,
    ],
  );

  return (
    <>
      {shouldShowLoadingModal && <LoadingModal />}
      <Text theme="h5">Pilih Alamat Tersimpan</Text>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={s.scrollContainer}
      >
        {!!customerShippingAddressDefaultForShipping && (
          <UserAddressCard
            userAddress={customerShippingAddressDefaultForShipping}
            onPress={handleAddressPress}
          />
        )}
        {otherCustomerShippingAddresses.map((shippingAddress) => (
          <UserAddressCard
            key={shippingAddress.id}
            userAddress={shippingAddress}
            onPress={handleAddressPress}
          />
        ))}
      </ScrollView>
    </>
  );
});

const s = StyleSheet.create({
  scrollContainer: {
    ...cStyles.mvl,
    maxHeight: 111,
  },
});

export default UserAddressList;
