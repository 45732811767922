/* eslint-disable camelcase */
import get from 'lodash/get';
import pick from 'lodash/pick';
import moment from 'moment';

import { formatPrice, makeParametricUrl } from 'app-libs/etc';
import { getFirstBreadcrumbFromProductCategories } from 'app-libs/etc/categoryHelper';
import { genProductUrl } from 'app-libs/etc/productHelper';
import {
  getProjectTemplateMainAttributeValue,
  getProjectTemplateStyleNames,
  getProjectTemplateTitle,
} from 'app-libs/redux_modules/entity_modules/selectors/projectTemplate';

import { K_ROUTE_POST_DETAIL } from 'constants/routeConstants';

import { genBaseUtmPropFromPath, genUtmPropFromQueryString } from './base';

/* eslint-disable camelcase */

export function genNotificationItem(notificationItem) {
  return pick(notificationItem, [
    'title',
    'body',
    'engagementType',
    'url',
    'objectId',
  ]);
}

export function genSearch(queryString, to = '') {
  return { queryString, to };
}

export function genExtraFilter(label, title, category) {
  return { label, title, category };
}

export function genProduct(product, baseUrl = '') {
  const {
    objectID,
    categories,
    title,
    brand,
    price,
    image,
    userWhoViewedThisAlsoViewedItemsIds,
  } = product;

  return {
    productId: objectID,
    category: getFirstBreadcrumbFromProductCategories(categories),
    title,
    brand,
    discountedValue: get(product, 'productOffer.salePrice', price),
    offerStartTime: get(product, 'productOffer.offer.startTime', null),
    offerEndTime: get(product, 'productOffer.offer.endTime', null),
    value: price,
    image,
    userWhoViewedThisAlsoViewedItemsIds,
    url: genProductUrl(product, baseUrl),
    displayPrice: `Rp ${formatPrice(price, '.', ',')}`,
  };
}

export function genLookSimilarProductsInfo(
  product,
  position,
  clickedFrom,
  sortIndex,
) {
  return {
    ...genProduct(product),
    position,
    clickedFrom,
    sortIndex,
  };
}

export function genChatInfo(product, clickedFrom) {
  return {
    ...genProduct(product),
    clickedFrom,
  };
}

export function genProductCellInfo(
  product,
  position,
  clickedFrom,
  sortIndex,
  usedFilter,
) {
  return {
    ...genProduct(product),
    position,
    clickedFrom,
    sortIndex,
    ...usedFilter,
  };
}

export function genProductImage(product, imageUrl, index) {
  return {
    product: genProduct(product),
    url: imageUrl,
    index,
  };
}

export function genProductCategorySer(to, categoryName) {
  return {
    ...trackTransitionTo(to),
    categoryName,
  };
}

export function genProductUpcOnly(product) {
  return { productId: product.objectID };
}

export function genProductUpsell(product, upsellItem) {
  return { productId: product.objectID, upsellItemId: upsellItem.objectID };
}

export function genPaymentMethod(paymentMethod) {
  return { paymentMethod };
}

export function genMattressSearch(filterUsed, from) {
  return { filterUsed, from };
}

export function genMattressBrand(name, from) {
  return { name, from };
}

export function genOpenedFrom(from) {
  return { from };
}

export function genUser(email, firstName, lastName, dateJoined) {
  const data = {
    id: email,
    email,
  };
  if (firstName) data.first_name = firstName;
  if (lastName) data.last_name = lastName;
  if (dateJoined) data.created_at = moment(dateJoined).unix(); // change to unix timestamp
  return data;
}

export function genMoodboard(moodboard) {
  return pick(moodboard, ['id', 'title', 'itemIds']);
}

export function genViewedMoodboard(moodboard, referrer) {
  return {
    moodboard: genMoodboard(moodboard),
    pathName: referrer,
    moodboard_title: moodboard.title, // backward compat
  };
}

export function genRequestVerifyPayment(order, reference) {
  return {
    order,
    reference,
  };
}

export function genCurrentUtmProp() {
  const pathQueryString = document.location.search;
  return genUtmPropFromQueryString(pathQueryString);
}

export function genUtmPropFromPath(path) {
  return genBaseUtmPropFromPath(path);
}

export function genCustomerAddress(address) {
  return { address };
}

export function genBasketSer({ basket, baseUrl }) {
  return {
    products: basket.lines.map((line) => genProduct(line.product, baseUrl)),
  };
}

export function genPurchase(order, lines, paymentMethod) {
  return {
    order: Object.assign({}, order, {
      lines:
        [] /** Amplitude Cannot have object nested in an array property value */,
      linesId: lines.map((line) => line.product.objectID),
      paymentMethod,
    }),
  };
}

export function genPurchaseSingle(order, line) {
  const { product, quantity } = line;
  return {
    order: {
      number: order.number,
      guestEmail: order.guestEmail,
    },
    product: genProduct(product),
    quantity,
  };
}

export function genOrder(order) {
  return {
    ...order,
    lines:
      [] /** Amplitude Cannot have object nested in an array property value */,
    linesId: order.lines.map((line) => line.product.upc),
  };
}

export function trackTransitionTo(to, pathName = null) {
  return { to, pathName };
}

export function trackRelatedProduct(mainProduct, product, productIndex) {
  return {
    product: genProduct(mainProduct),
    relatedProduct: genProduct(product),
    relatedProductIndex: productIndex,
  };
}

export function trackBestSeller(product, productIndex) {
  return {
    product: genProduct(product),
    bestSellerProductIndex: productIndex,
  };
}

export function genRequestProjectSer(questionnaire) {
  const {
    projectTemplate,
    serviceTypeName,
    propertyTypeName,
    dealValue,
    source,
  } = questionnaire;
  const minPrice = dealValue;
  const styleString = getProjectTemplateStyleNames(projectTemplate).join(', ');
  const projectTemplateTitle = getProjectTemplateTitle(projectTemplate);
  const type = getProjectTemplateMainAttributeValue(projectTemplate);
  return {
    value: minPrice, // min budget,
    currency: 'IDR',
    style: styleString, // style string
    serviceTypeName, // service type
    type, // primary filter,
    propertyTypeName, // property type,
    title: projectTemplateTitle, // project template title
    source,
  };
}

export function genProjectServiceTypeSer(to, serviceTypeName) {
  return {
    ...trackTransitionTo(to),
    serviceTypeName,
  };
}

export function genWishlistPin(placement, isWishlist, productInfo) {
  return { placement, isFavorited: isWishlist, productInfo };
}

export function genWishlistPins(items) {
  return {
    favoritedCount: items.length,
    favoritedItems: items.map((item) =>
      item.objectID ? item.objectID : item.product.objectID,
    ),
  };
}

export function genClickMainTab(tabName) {
  return { tabName };
}

export function genArticleSer(article, adSlots = []) {
  const { id, slug } = article;
  const categories = Object.keys(article.categories);
  const tags = Object.keys(article.tags);
  const url = makeParametricUrl(K_ROUTE_POST_DETAIL, {
    postid: id,
    postslug: slug,
  });
  const adSlotsInfo = adSlots.map((adSlot) => adSlot.name);

  return {
    categories,
    tags,
    url,
    ...pick(article, 'id', 'title', 'slug'),
    adSlots: adSlotsInfo,
  };
}

export function genSubscribedArticleSer(article, email) {
  return {
    article: genArticleSer(article),
    subscriberEmail: email,
  };
}

export function genDesignerIds(designers) {
  const designerIds = designers.map((designer) => designer.memberId);
  return { designerIds };
}

// #region Designer Portfolio
export function genPortfolioIdsSer(portfolioList) {
  const portfolioIds = portfolioList.map((o) => o.id);
  return { portfolioIds };
}

export function genPortfolioSer(portfolio) {
  return pick(portfolio, [
    'projectKey',
    'title',
    'cartLinesValuePublic',
    'propertyTypeName',
    'serviceTypeName',
  ]);
}

// #endregion

// #region DekoHouse

export function genLocationCardSer(to, locationName) {
  return {
    ...trackTransitionTo(to),
    locationName,
  };
}

// #endregion

// #endregion DekoHub

export function genRegisterMembership(membershipFormData) {
  return {
    email: membershipFormData.email,
    phoneNumber: membershipFormData.phoneNumber,
    gender: membershipFormData.gender,
    occupation: membershipFormData.occupation,
  };
}
