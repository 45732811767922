import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

import {
  Product,
  ProductFlashSaleItem,
  ProductStockRecord,
} from 'app-libs/components/Product/types';
import { BundleOfferItem } from 'app-libs/redux_modules/entity_modules/bundleOffer/types';
import { FlashSaleItem } from 'app-libs/redux_modules/entity_modules/flashSale/types';
import {
  K_MAX_BACKORDER,
  getProductAvailability,
  getProductAvailabilityFromStockRecord,
} from 'app-libs/redux_modules/entity_modules/selectors/product';

import {
  K_DEFAULT_PRICE_ZONE_CODE,
  K_DEFAULT_ROUTING_ZONE_CODE,
  K_PRODUCT_STYLE_HUMANIZED_TEXT_FROM_SLUG_MAPPING,
  K_PRODUCT_STYLE_SERIES_HUMANIZED_TEXT_FROM_SLUG_MAPPING,
} from 'constants/productConstants';

import { BasketLineProduct } from '../redux_modules/entity_modules/basket/types';

type ZonePriceDetail = {
  price: number;
  priceAfterDiscount: number;
  discountPercentage: number;
};

export const getProductDetailedPriceByZone = (
  product: Product,
  zoneCode = K_DEFAULT_PRICE_ZONE_CODE,
): ZonePriceDetail => {
  const { priceInfosByZone } = product;
  const zonePriceInfo = priceInfosByZone[zoneCode];

  if (zonePriceInfo) {
    return {
      price: zonePriceInfo.priceExclTax,
      priceAfterDiscount: zonePriceInfo.salePrice,
      discountPercentage: Math.floor(
        (zonePriceInfo.discount * 100) / zonePriceInfo.priceExclTax,
      ),
    };
  }

  if (
    product.productOffer &&
    'discount' in product.productOffer &&
    'offer' in product.productOffer
  ) {
    const { discount, offer } = product.productOffer;

    return {
      price: product.priceNumber,
      priceAfterDiscount: product.priceNumber - discount,
      discountPercentage: offer.discountValue,
    };
  }

  return {
    price: product.priceNumber,
    priceAfterDiscount: product.priceNumber,
    discountPercentage: 0,
  };
};

export const getStockRecordDetailedPriceByZone = (
  stockRecord: ProductStockRecord,
  zoneCode = K_DEFAULT_PRICE_ZONE_CODE,
): ZonePriceDetail => {
  const { priceInfosByZone } = stockRecord;
  const zonePriceInfo = priceInfosByZone[zoneCode];

  if (zonePriceInfo) {
    return {
      price: zonePriceInfo.priceExclTax,
      priceAfterDiscount: zonePriceInfo.salePrice,
      discountPercentage: Math.floor(
        (zonePriceInfo.discount * 100) / zonePriceInfo.priceExclTax,
      ),
    };
  }

  if (
    stockRecord.offer &&
    'discount' in stockRecord.offer &&
    'discountValue' in stockRecord.offer
  ) {
    const { discount, discountValue } = stockRecord.offer;

    return {
      price: stockRecord.priceExclTax,
      priceAfterDiscount: stockRecord.priceExclTax - discount,
      discountPercentage: discountValue,
    };
  }

  return {
    price: stockRecord.priceExclTax,
    priceAfterDiscount: stockRecord.priceExclTax,
    discountPercentage: 0,
  };
};

export const getFlashSaleItemDetailedPriceByZone = (
  flashSaleItem: FlashSaleItem | ProductFlashSaleItem,
  zoneCode = K_DEFAULT_PRICE_ZONE_CODE,
) => {
  const zonePriceInfo = flashSaleItem.priceInfosByZone[zoneCode];

  if (zonePriceInfo) {
    return {
      price: zonePriceInfo.priceExclTax,
      priceAfterDiscount: zonePriceInfo.salePrice,
      discountPercentage: Math.floor(
        (zonePriceInfo.discount * 100) / zonePriceInfo.priceExclTax,
      ),
    };
  }

  return {
    price: flashSaleItem.originalRetailPrice,
    priceAfterDiscount: flashSaleItem.retailPrice,
    discountPercentage: Math.floor(
      ((flashSaleItem.originalRetailPrice - flashSaleItem.retailPrice) * 100) /
      flashSaleItem.originalRetailPrice,
    ),
  };
};

export const getBundleOfferItemDetailedPriceByZone = (
  bundleOfferItem: BundleOfferItem,
  zoneCode = K_DEFAULT_PRICE_ZONE_CODE,
) => {
  const zonePriceInfo = bundleOfferItem.priceInfosByZone[zoneCode];

  if (zonePriceInfo) {
    return {
      price: zonePriceInfo.priceExclTax,
      priceAfterDiscount: zonePriceInfo.salePrice,
      discountPercentage: Math.floor(
        (zonePriceInfo.discount * 100) / zonePriceInfo.priceExclTax,
      ),
    };
  }

  const { stockRecord, discountValue } = bundleOfferItem;
  const discountFromOffer = stockRecord.productOffer?.discount || 0;
  const discountFromBundle = parseInt(discountValue, 10);
  const discount = Math.max(discountFromBundle, discountFromOffer);

  const priceAfterDiscount = parseFloat(stockRecord.priceExclTax) - discount;
  const discountPercentage = Math.floor(
    (discount * 100) / parseFloat(stockRecord.priceExclTax),
  );

  return {
    price: parseFloat(stockRecord.priceExclTax),
    priceAfterDiscount,
    discountPercentage,
  };
};

export function cleanProductAttributeValue(attrKey: string, attrValue: string) {
  let cleanedValue = attrValue;

  switch (attrKey) {
    case 'Style': {
      let cleanedValues = cleanedValue.split(',');
      cleanedValues = cleanedValues.map((value) => {
        return K_PRODUCT_STYLE_HUMANIZED_TEXT_FROM_SLUG_MAPPING[value] || value;
      });
      cleanedValue = cleanedValues.join(', ');
      break;
    }
    case 'Style Series': {
      let cleanedValues = cleanedValue.split(',');
      cleanedValues = cleanedValues.map((value) => {
        return K_PRODUCT_STYLE_SERIES_HUMANIZED_TEXT_FROM_SLUG_MAPPING[value] || value;
      });
      cleanedValue = cleanedValues.join(', ');
      break;
    }
    default: {
      break;
    }
  }

  return cleanedValue;
}

export const getWeightAmount = (
  product: Product | BasketLineProduct,
): number => {
  // Show rounded up amount because 3PL API
  // also calculate shipping price by rounding up amount
  // WARNING!!! do not use rounded amount for calculation
  // as this rounded amount can lead to bug if used in other calculation
  return Math.ceil(Math.max(product.weight, product.volumetric));
};

const getIsStockRecordMadeInOrder = (stockRecord: ProductStockRecord) => {
  return stockRecord.backorderAllowed && stockRecord.numAvailable <= 0;
};

export const getStockRecordBackorderFulfillmentTime = (
  stockRecord: ProductStockRecord,
) => {
  if (!getIsStockRecordMadeInOrder(stockRecord)) return 0;
  return stockRecord.backorderFulfillmentTime;
};

export const getStockRecordFulfillmentTime = (
  stockRecord: ProductStockRecord,
) => {
  return {
    minFulfillmentTime: 0,
    maxFulfillmentTime: stockRecord.fulfillmentTime,
  };
};

export const getProductMaxQuantityToBuy = (product: Product) => {
  const productAvailability = getProductAvailability(product);
  return productAvailability.backorderAllowed
    ? K_MAX_BACKORDER
    : productAvailability.numAvailable;
};

export const getProductMaxQuantityToBuyFromStockRecord = (
  stockRecord: ProductStockRecord,
) => {
  const stockRecordAvailability =
    getProductAvailabilityFromStockRecord(stockRecord);
  return stockRecordAvailability.backorderAllowed
    ? K_MAX_BACKORDER
    : stockRecordAvailability.numAvailable;
};

export const getProductMaxQuantityToBuyFromAllStockRecord = (
  product: Product,
  routingZoneCode: string,
) => {
  const availableStockRecords = getOnlineAvailableStockRecordsSortByPriority(
    product,
    routingZoneCode,
  );
  let maxQuantity = 0;
  availableStockRecords.forEach((stockRecord) => {
    if (stockRecord.backorderAllowed && stockRecord.numAvailable === 0)
      maxQuantity = Math.max(maxQuantity, K_MAX_BACKORDER);
    else maxQuantity = Math.max(maxQuantity, stockRecord.numAvailable);
  });

  return maxQuantity;
};

export const getProductNumAvailable = (
  product: Product,
  selectedStockRecord: ProductStockRecord,
  routingZoneCode: string,
  isShopAssistant: boolean,
) => {
  if (isShopAssistant) return selectedStockRecord.numAvailable;

  if (getIsFlashSaleStockRecord(selectedStockRecord))
    return selectedStockRecord.numAvailable ?? 0;
  if (selectedStockRecord?.isOfflineStock && !isShopAssistant) return 0;

  /*
   * @note (Radit) 6 Oct 2023: this is still returns zero instead of K_MAX_BACKORDER
   * reason: this is used for `XX Stok Tersedia` for online hub
   *
   * CHANGE THIS IF BUSINESS LOGIC CHANGES!
   */
  if (
    selectedStockRecord.backorderAllowed &&
    selectedStockRecord.numAvailable === 0
  )
    return 0;

  const availableStockRecords = getOnlineAvailableStockRecordsSortByPriority(
    product,
    routingZoneCode,
  );

  /* only see the online stock that have no mto */
  let numAvailable = 0;
  availableStockRecords.forEach((stockRecord) => {
    if (getIsFlashSaleStockRecord(stockRecord)) return;
    if (stockRecord.backorderAllowed && stockRecord.numAvailable === 0) return;
    if (stockRecord.isOfflineStock) return;
    numAvailable = Math.max(numAvailable, stockRecord.numAvailable);
  });
  return numAvailable;
};

export const getOnlineAvailableStockRecordsSortByPriority = (
  product: Product,
  routingZoneCode: string,
) => {
  const stockRecords = product.stockRecordChoices.filter((stockRecord) => {
    if (stockRecord.isOfflineStock) return false;
    if (stockRecord.numAvailable === 0 && !stockRecord.backorderAllowed)
      return false;
    const { routingZoneCodes } = stockRecord.partner;
    if (routingZoneCodes.includes(routingZoneCode)) return true;
    if (routingZoneCodes.includes(K_DEFAULT_ROUTING_ZONE_CODE)) return true;
    return false;
  });

  /*
   * Sort is determined by this:
   * 1. Flash Sale
   * 2. same with routingZoneCode
   * 3. default routingZoneCode
   */
  stockRecords.sort((a, b) => {
    if (getIsFlashSaleStockRecord(a) && !getIsFlashSaleStockRecord(b))
      return -1;
    if (!getIsFlashSaleStockRecord(a) && getIsFlashSaleStockRecord(b)) return 1;
    const aRoutingZoneCodes = a.partner.routingZoneCodes ?? [];
    const bRoutingZoneCodes = b.partner.routingZoneCodes ?? [];

    const isANearestZoneCode = aRoutingZoneCodes.includes(routingZoneCode);
    const isBNearestZoneCode = bRoutingZoneCodes.includes(routingZoneCode);
    if (isANearestZoneCode && !isBNearestZoneCode) return -1;
    if (!isANearestZoneCode && isBNearestZoneCode) return 1;

    return 0;
  });

  return stockRecords;
};

export const getOnlineOptimalStockRecordBasedOnCriteria = (
  product: Product,
  routingZoneCode: string,
  shouldShowFlashSaleItem: boolean,
  quantityToAdd: number,
  productFlashSaleItemMaximumQuantityPerUser: number,
) => {
  const availableStockRecords = getOnlineAvailableStockRecordsSortByPriority(
    product,
    routingZoneCode,
  );

  const optimalStockRecord = availableStockRecords.find(
    (stockRecord: ProductStockRecord) => {
      if (getIsFlashSaleStockRecord(stockRecord)) {
        const shouldUseFlashSaleStockRecordId =
          shouldShowFlashSaleItem &&
          quantityToAdd <= productFlashSaleItemMaximumQuantityPerUser;
        if (shouldUseFlashSaleStockRecordId) return true;
      } else {
        if (stockRecord.backorderAllowed && stockRecord.numAvailable === 0)
          return true;
        if (stockRecord.numAvailable === 0) return false;
        if (stockRecord.numAvailable >= quantityToAdd) return true;
      }
      return false;
    },
  );

  return optimalStockRecord;
};

const getIsFlashSaleStockRecord = (stockRecord: ProductStockRecord) => {
  return !!stockRecord.parentStockRecordId;
};

export const getOptimalRoutingZoneCodeFromProductFilters = (f: string) => {
  /*
   * format: {attribute}:'{value}'<score={score}> OR {attribute}:'{value}'<score={score}> or ....
   * e.g.: routingZoneCodes:'z2'<score=100> OR routingZoneCodes:'z1'<score=30> OR ....<other filters>
   * if there are tie, chose the most left one.
   */
  const fSplit = f.split(' OR ');
  let optimalRoutingZoneCode = K_DEFAULT_ROUTING_ZONE_CODE;
  let optimalScore = 1;

  fSplit.forEach((filter) => {
    if (!filter.startsWith('routingZoneCodes')) return;

    // format: routingZoneCodes:'{routingZoneCode}'<score={score}>
    const currentScoreStr = filter.split('<score=')[1].split('>')[0];
    const currentScore = Number.parseInt(currentScoreStr, 10);

    let routingZoneCode = filter.split('<score=')[0].split(':')[1];

    // 'z1' --> z1, 'z10' --> z10
    routingZoneCode = routingZoneCode.substring(1, routingZoneCode.length - 1);
    if (currentScore > optimalScore) {
      optimalScore = currentScore;
      optimalRoutingZoneCode = routingZoneCode;
    }
  });

  return optimalRoutingZoneCode;
};

export const generateProductFilters = (
  routingZoneCode: string,
  shouldHideEmptyStockInCurrentRoutingZoneProducts?: boolean,
) => {
  let filters = `routingZoneCodes:'${routingZoneCode}'<score=100>`;
  if (routingZoneCode !== K_DEFAULT_ROUTING_ZONE_CODE) {
    filters += ` OR routingZoneCodes:'${K_DEFAULT_ROUTING_ZONE_CODE}'<score=30>`;
  }
  if (!shouldHideEmptyStockInCurrentRoutingZoneProducts) {
    filters += ` OR isBlacklisted:false<score=1>`;
  }

  return filters;
};

export const generateCustomSortProductFilters = (
  routingZoneCode: string,
  shouldHideEmptyStockInCurrentRoutingZoneProducts?: boolean,
) => {
  let filters = `routingZoneCodes:'${routingZoneCode}'<score=100>`;
  if (routingZoneCode !== K_DEFAULT_ROUTING_ZONE_CODE) {
    filters += ` OR routingZoneCodes:'${K_DEFAULT_ROUTING_ZONE_CODE}'<score=100>`;
  }
  if (!shouldHideEmptyStockInCurrentRoutingZoneProducts) {
    filters += ` OR isBlacklisted:false<score=1>`;
  }

  return filters;
};

export const getIsOutOfStockByRoutingZoneCode = (
  product: Product,
  routingZoneCode: string,
): boolean => {
  /*
   * @note (Radit) 28 Nov 2023: as of today, if the stock is not available,
   * the routingZoneCode will be not exists in the product.routingZoneCodes.
   *
   * MODIFY THIS IF THE BUSINESS LOGIC CHANGED.
   */
  const availableRoutingZoneCodes = [routingZoneCode];
  if (routingZoneCode !== K_DEFAULT_ROUTING_ZONE_CODE)
    availableRoutingZoneCodes.push(K_DEFAULT_ROUTING_ZONE_CODE);
  const productRoutingZoneCodes = product.routingZoneCodes ?? [];
  return isEmpty(
    intersection(availableRoutingZoneCodes, productRoutingZoneCodes),
  );
};

export const getAvailableProductsByRoutingZoneCode = (
  products: Product[],
  routingZoneCode: string,
): Product[] => {
  return products.filter(
    (product) => !getIsOutOfStockByRoutingZoneCode(product, routingZoneCode),
  );
};
