import { normalize } from 'normalizr';

import {
  fetchFailStatus,
  fetchSuccessStatus,
  fetchingStatus,
  initialAsyncState,
} from '../../helper_modules/asyncState';
import { userDetailSchema } from '../schemas';
import AT from '../types';
import { mergeByUserUuid } from './helper';

/*
  ===== resultingState =====
  {
    asyncState: { ... }
    byUserUuid: {
      '4dcf86ce-b919-43fa-93a0-d78790196acd': {
        firstName: 'David',
        lastName: 'Kwan',
      },
    },
  }
*/

const initialState = {
  byUserUuid: {},
  asyncState: { ...initialAsyncState },
};

export default function userDetailReducer(
  mutableState = initialState,
  action = {},
) {
  const state = mutableState;

  switch (action.type) {
    case AT.GET_PROFILE:
      return getProfile(state, action);
    case AT.GET_PROFILE_SUCCESS:
      return getProfileSuccess(state, action);
    case AT.GET_PROFILE_FAIL:
      return getProfileFail(state, action);
    default:
      return state;
  }
}

function getProfile(state) {
  return {
    ...state,
    asyncState: fetchingStatus(),
  };
}

function getProfileSuccess(state, action) {
  const data = action.result;
  const normalizedData = normalize(data, userDetailSchema);
  const newUserDetail = normalizedData.entities.userDetails;

  return {
    ...state,
    byUserUuid: mergeByUserUuid(state, newUserDetail),
    asyncState: fetchSuccessStatus(),
  };
}

function getProfileFail(state, action) {
  return {
    ...state,
    asyncState: fetchFailStatus(action.error),
  };
}
