import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import cStyles from '@dkrm/general-libs/theme/styles';
import InfiniteScroll from './react-infinite-scroll-component';

export default class InfiniteScrollList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    renderItem: PropTypes.func.isRequired,
    onEndReached: PropTypes.func,
    onEndReachedThreshold: PropTypes.number,
    hasMore: PropTypes.bool,
    numColumns: PropTypes.number,
    contentContainerStyle: PropTypes.shape(),
    ListHeaderComponent: PropTypes.func,
    ListFooterComponent: PropTypes.func,
    isFull: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    hasMore: false,
    onEndReached: () => {},
    ListHeaderComponent: () => null,
    ListFooterComponent: () => null,
    onEndReachedThreshold: 0.5,
    numColumns: 1,
    contentContainerStyle: {},
    isFull: false,
  };

  renderInfiniteScroll() {
    const {
      contentContainerStyle,
      data,
      hasMore,
      ListFooterComponent,
      ListHeaderComponent,
      numColumns,
      onEndReached,
      onEndReachedThreshold,
      renderItem,
      isFull,
      style,
      ...rest
    } = this.props;

    const wrapperStyle = [cStyles.pageMaxWidth];
    if (numColumns > 1) {
      wrapperStyle.push(cStyles.flexDirRow, cStyles.flexWrap);
    }

    return (
      <InfiniteScroll
        next={onEndReached}
        scrollThreshold={onEndReachedThreshold}
        hasMore={hasMore}
        height="100%"
        loader={null}
        style={StyleSheet.flatten(contentContainerStyle)}
        {...rest}
      >
        <ListHeaderComponent />
        <View style={numColumns > 1 ? cStyles.flexDirCol : null}>
          <View style={wrapperStyle}>
            {data &&
              data.map((item, index) => (
                <div
                  key={index}
                  style={
                    numColumns > 1
                      ? { flexBasis: `${100 / numColumns}%`, flexShrink: 0 }
                      : null
                  }
                >
                  {renderItem({ item, index })}
                </div>
              ))}
          </View>
        </View>
        <ListFooterComponent />
      </InfiniteScroll>
    );
  }

  render() {
    const { isFull } = this.props;
    if (!isFull) return this.renderInfiniteScroll();
    return (
      <div style={{ position: 'relative', height: '100%', display: 'flex' }}>
        <div
          style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
        >
          {this.renderInfiniteScroll()}
        </div>
      </div>
    );
  }
}
