import React from 'react';
import config from 'config';

import {
  SearchProvider as AbstractSearchProvider,
  SearchConsumer as AbstractSearchConsumer,
} from '@dkrm/algolia-adapter';

export const SearchProvider = (props) => (
  <AbstractSearchProvider
    algoliaAPIKey={config.ALGOLIA_API_KEY}
    algoliaAppName={config.ALGOLIA_APP_NAME}
    {...props}
  />
);

export const SearchConsumer = AbstractSearchConsumer;
