// eslint-disable-next-line @typescript-eslint/no-unused-vars
import lazysizes from 'lazysizes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Link, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { K_DEKORUMA_LOGO } from 'constants/assetConstants';

import NavbarWithTitle from '../NavbarWithTitle';

export const K_ICON_SIZE = 24;
const K_DEFAULT_LOGO_URL = K_DEKORUMA_LOGO;
const K_LOGO_HEIGHT = 36;
const K_LOGO_WIDTH = 97;

export default class NavbarWithLogo extends PureComponent {
  static propTypes = {
    ...NavbarWithTitle.propTypes,
    logoImageUrl: PropTypes.string,
    logoButtonUrl: PropTypes.string,
    logoLinkProps: PropTypes.shape(),
    logoHeight: PropTypes.number,
    logoWidth: PropTypes.number,
  };

  static defaultProps = {
    logoImageUrl: K_DEFAULT_LOGO_URL,
    logoButtonUrl: '/',
    logoLinkProps: {},
    logoHeight: K_LOGO_HEIGHT,
    logoWidth: K_LOGO_WIDTH,
  };

  renderLogo() {
    const {
      logoImageUrl,
      logoButtonUrl,
      logoLinkProps,
      logoHeight,
      logoWidth,
    } = this.props;
    return (
      <Link
        to={logoButtonUrl}
        shouldOpenNewTab={false}
        showUnderlineOnHover={false}
        {...logoLinkProps}
      >
        <View style={s.logoImageContainer}>
          <SmartImage
            source={logoImageUrl}
            additionalQuery={{ fit: 'bounds' }}
            resizeMode="contain"
            width={logoWidth}
            height={logoHeight}
            style={s.logoImage}
            shouldPreloadSmallerVersion={false}
            smallerVersionRatio={2}
          />
        </View>
      </Link>
    );
  }

  render() {
    const {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      logoImageUrl,
      logoButtonUrl,
      logoLinkProps,
      logoHeight,
      logoWidth,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      ...rest
    } = this.props;
    return <NavbarWithTitle title={this.renderLogo()} {...rest} />;
  }
}

const s = StyleSheet.create({
  logoImage: {
    ...cStyles.mlm,
  },
  logoImageContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
  },
});
