/**
 * this file must access using relative path if it's not in node_modules
 * -> because it's used in client/config
 */
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import React from 'react';
import uuid from 'uuid-random';

import {
  getCDNImageUrl,
  getNoImageUrl as getNoImageUrlAdapter,
  getPatternImageCropUrl as getPatternImageCropUrlAdapter,
  getProductImageUrl as getProductImageUrlAdapter,
  getS3ImageAbsoluteUrl,
  getS3ImageName,
} from '@dkrm/general-libs/Utils/imageUtils';

import {
  getImageNormalLandscapeCropUrl as getImageNormalLandscapeCropUrlAdapter,
  getImageThumbLandscapeCropUrl as getImageThumbLandscapeCropUrlAdapter,
} from 'app-libs/etc/imageHelper';

export function capitalizeFirstLetter(val) {
  const str = String(val);
  return str.length ? str[0].toUpperCase() + str.substring(1) : str;
}

export function capitalizeText(val) {
  return capitalizeFirstLetter(val.toLowerCase());
}

/**
 * This Is Pascal Case
 */
export function toPascalCase(val) {
  const str = String(val);
  const letters = str.replace(
    /(\w)(\w*)/g,
    (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
  );
  return letters;
}

export function slugify(text) {
  return typeof text === 'string' ? text.replace(/\s+/g, '-') : '';
}

export function slugifyLowerCase(text) {
  return typeof text === 'string'
    ? text.toLowerCase().replace(/\s+/g, '-')
    : '';
}

export function unslugify(text) {
  return typeof text === 'string' ? text.replace(/-/g, ' ') : '';
}

export function unslugifyLowerCase(text) {
  return typeof text === 'string' ? text.toLowerCase().replace(/-/g, ' ') : '';
}

export function flatten(data) {
  return JSON.stringify(data);
}

export function unflatten(data) {
  return JSON.parse(data);
}

export function getEmailUsername(email) {
  if (!email) {
    return '';
  }

  const [username, domain] = email.split('@');
  if (domain === 'dekononymous.com') {
    return username.replace(/\d+$/, '');
  }
  return username;
}

export function ellipsize(text, maxLength = 100) {
  const addOn = '...';
  if (text && text.length > addOn.length && text.length > maxLength) {
    return `${text.substring(0, maxLength - addOn.length)}...`;
  }
  return text;
}

/**
 * [formatDateISO description]
 * @param  {Date} date
 * @return {String}  "YYYY-MM-DD"
 */
export function formatDateISO(date) {
  return date.toISOString().slice(0, 10);
}

/**
 * sample usage: formatPrice("200000.00", 2, '.', ',')
 */
export function formatPrice(n, cNum, d = '.', t = ',') {
  if (!n) return '0';
  const c = Number.isNaN(Math.abs(cNum)) ? 0 : cNum;
  const s = n < 0 ? '-' : '';
  const num = Math.abs(+n || 0).toFixed(c);
  const i = `${parseInt(num, 10)}`;
  const j = i.length > 3 ? i.length % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(num - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
}

export function formatSimplifiedPriceIDR(price, decimalPlace = 1) {
  let multiplier;
  let suffix;

  if (`${Math.round(price)}`.length > 9) {
    multiplier = 9;
    suffix = 'M';
  } else {
    multiplier = 6;
    suffix = 'jt';
  }

  const roundedPrice = (price / Math.pow(10, multiplier)).toFixed(decimalPlace); // eslint-disable-line no-restricted-properties

  if (decimalPlace === 0) return `${roundedPrice}${suffix}`;

  const formattedPrice =
    roundedPrice.substr(-1) === '0'
      ? `${roundedPrice.slice(0, -2)}${suffix}`
      : `${roundedPrice}${suffix}`;

  return formattedPrice;
}

export function formatPriceId(price) {
  return formatPrice(price, 0, '.', '.');
}

export function formatPriceSimplified(n) {
  const kilo = 1000;
  let formattedPrice = formatPrice(n / kilo, 0);
  formattedPrice += 'k';
  return formattedPrice;
}

/**
 * Helper to un-format price with currency and comma
 * Remove $ signs and ., accept only number
 */
export function unformatPrice(priceInString) {
  if (!priceInString) return '';
  return parseFloat(priceInString.replace(/[^0-9-.]/g, ''));
}

/**
 * Helper to re-format price with IDR currency (from other price format)
 */
export function reformatPriceId(price) {
  return formatPriceId(unformatPrice(price));
}

/**
 * Remove characters except digits, period and dash.
 * @param {string} str
 * @return {string}
 */
export function removeNonNumericCharacters(str) {
  if (typeof str !== 'string') return '';
  return str.replace(/[^0-9.-]/g, '');
}

/**
 * Remove characters except digits and dash.
 * @param {string} str
 * @return {string}
 */
export function removeNonIntegralCharacters(str) {
  if (typeof str !== 'string') return '';
  return str.replace(/[^0-9-]/g, '');
}

/**
 * Safely convert string/number/boolean to positive/negative floating point number.
 * @param {string|number|boolean} val
 * @return {number}
 */
export function toNumber(val) {
  if (typeof val === 'number') return val;
  if (typeof val === 'boolean') return val ? 1 : 0;
  if (typeof val !== 'string') return 0;
  const number = parseFloat(
    val
      .replace(/[^0-9.-]/g, '')
      .replace(/(\..*)\./g, '$1')
      .replace(/(?!^)-/g, ''),
  );
  return Number.isNaN(number) ? 0 : number;
}

export function getLastRefinedFacet(facet) {
  if (has(facet, 'data') && facet.data && facet.data.length) {
    const nextFacet = facet.data.find((d) => d.isRefined);
    if (nextFacet) {
      return getLastRefinedFacet(nextFacet); // recursive
    }
  }
  return facet;
}

export function getRefinedFacets(facet) {
  const arr = [];
  if (has(facet, 'data') && facet.data && facet.data.length) {
    const nextFacet = facet.data.find((d) => d.isRefined);
    if (nextFacet) {
      arr.push(nextFacet);
      return arr.concat(getRefinedFacets(nextFacet)); // recursive
    }
  }
  return arr;
}

export function getLastRefinedFacetSelections(facet) {
  const lastFacet = getLastRefinedFacet(facet);
  if (has(lastFacet, 'data') && lastFacet.data.length) {
    return facet.data;
  }
  return [];
}

/**
 * This is a helper function to do transition action that is weak (cancelled when already router)
 * An example use case:
 * - When we want to encapsulate a componennt with onclick, but inside the component, there's another routing function that will be executed before this, thus this transition should be cancelled
 *
 * @param  {Router} router - react router object. normally we get it from this.props.router
 * @param  {String} to - url
 * @return None
 */
export function transitionToIfNotRoutedYet(router, to) {
  if (
    has(router, 'props.history.location.pathname') &&
    has(router, 'state.location.pathname')
  ) {
    const { pathname } = router.props.history.location;
    if (pathname !== router.state.location.pathname) {
      return;
    }
  }
  router.transitionTo(to);
}

/**
 * Util function to concat parametricRoute, with its key pair value
 * @todo: move this to etc/routeHelper
 */
export function makeParametricUrl(parametricRoute, params) {
  return parametricRoute
    .split('/')
    .map((inbetween) => {
      // default value
      let currResult = inbetween;
      // do logic
      if (inbetween.startsWith(':')) {
        const key = inbetween.substring(1);
        currResult = params[key];
      }
      // concat
      return currResult;
    })
    .join('/');
}

export function makeSerializedParameters(obj) {
  if (isEmpty(obj)) return '';
  return Object.keys(obj)
    .reduce((a, k) => {
      a.push(`${k}=${encodeURIComponent(obj[k])}`);
      return a;
    }, [])
    .join('&');
}

/**
 * @return {object} {
 *   sort: 'sort_price_lowest',
 *   dfr: '{brands: ['IKEA']}'
 * }
 */
export function parseQuery(url) {
  if (url && url.indexOf('?') >= 0) {
    const querystring = url.substring(url.indexOf('?') + 1);
    return parseQueryString(querystring);
  }
  return {};
}

export function parseQueryString(qstr) {
  const query = {};
  if (qstr) {
    const a = qstr.split('&');
    for (let i = 0; i < a.length; i += 1) {
      const b = a[i].split('=');
      try {
        query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
      } catch (e) {
        query[b[0]] = b[1]; // eslint-disable-line prefer-destructuring
      }
    }
  }
  return query;
}

/**
 * To reverse makeParametricUrl
 * eg.
 *   SAMPLE INPUT
 *     parametricRoute = '/search/:cat1/:cat2'
 *     parametricUrl = '/search/baba/bubu'
 *   SAMPLE OUTPUT
 *     {
 *       cat1: baba
 *       cat2: bubu
 *     }
 */
export function parseParams(parametricRoute, parametricUrl) {
  const params = {};

  if (parametricRoute && parametricUrl) {
    let cleanedUrl = parametricUrl;
    if (parametricUrl.indexOf('?') > 0) {
      cleanedUrl = parametricUrl.substring(0, parametricUrl.indexOf('?'));
    }

    const splittedParametricRoute = parametricRoute.split('/');
    cleanedUrl.split('/').forEach((urlPart, index) => {
      if (splittedParametricRoute[index]) {
        if (splittedParametricRoute[index].startsWith(':')) {
          params[splittedParametricRoute[index].substr(1)] = urlPart;
        }
      }
    });
  }

  return params;
}

/**
 * @deprecated use getS3ImageName() from 'app-libs/etc/imageHelper' instead
 * eg.
 *     input: http://f1-static.s3-ap-southeast-1.amazonaws.com/catalogue/KER-7.jpg
 *     output: catalogue/KER-7.jpg
 */
export function getImageName(image) {
  return getS3ImageName(image);
}

/**
 * @deprecated use getS3ImageAbsoluteUrl() from '@dkrm/general-libs/Utils/imageUtils' instead
 * Util to get s3 absolute URL, from imageName or s3 absolute URL itself
 * eg.
 *     input: http://f1-static.s3-ap-southeast-1.amazonaws.com/catalogue/KER-7.jpg
 *     output: catalogue/KER-7.jpg
 */
export function getImageUrl(image) {
  return getS3ImageAbsoluteUrl(image);
}

/**
 * @deprecated use getProductImageUrl() from '@dkrm/general-libs/Utils/imageUtils' instead
 * Given the upc, get the Media CDN product url
 * @param  upc INF-1517
 * @return url https://media.dekoruma.com/catalogue/INF-1517.jpg
 */
export function getProductImageUrl(upc) {
  return getProductImageUrlAdapter(upc);
}

/**
 * @deprecated use getImageNormalLandscapeCropUrl() from 'app-libs/etc/imageHelper' instead
 */
export function getImageNormalLandscapeCropUrl(image) {
  return getImageNormalLandscapeCropUrlAdapter(image);
}

/**
 * @deprecated use getImageThumbLandscapeCropUrl() from 'app-libs/etc/imageHelper' instead
 */
export function getImageThumbLandscapeCropUrl(image) {
  return getImageThumbLandscapeCropUrlAdapter(image);
}

/**
 * @deprecated use getCDNImageUrl(image) from general-libs/Utils/imageUtils instead
 * Util to transform s3 absolute URL, into URL prefixed by Media CDN
 * e.g.
 *     input: http://f1-static.s3-ap-southeast-1.amazonaws.com/catalogue/KER-7.jpg?versionId=123
 *     output: https://media.dekoruma.com/catalogue/KER-7.jpg
 */
export function getImageCropUrl(image) {
  return getCDNImageUrl(image);
}

/**
 * @deprecated use getNoImageUrl() from @dkrm/general-libs/Utils/imageUtils instead
 */
export function getNoImageUrl() {
  return getNoImageUrlAdapter();
}

/**
 * @deprecated use getPatternImageCropUrl() from @dkrm/general-libs/Utils/imageUtils instead
 * Util to grab pattern URL from s3 bucket, already cropped with specific size by Media CDN.
 * With assumption that patternName equals to imagename
 * e.g.
 *     input: Teddybear
 *     output: https://media.dekoruma.com/contextual-images/teddybear.jpg
 */
export function getPatternImageCropUrl(patternName) {
  return getPatternImageCropUrlAdapter(patternName);
}

/**
 * TODO: Move this function to categoryHelper
 *
 * Sample Input
 * categories = [
 *   "Ruang Makan > Piring > Piring Makan",
 *   "Kamar Tidur > Sprei",
 * ]
 */
export function getLastCategory(categories) {
  if (!categories) return null;
  if (!categories.length) return null;
  const lastCategory = categories[0].split(' > ').pop();
  if (lastCategory.length) return lastCategory;
  return null;
}

export function getFirstCategory(categories) {
  if (!categories) return null;
  if (!categories.length) return null;
  const lastCategory = categories[0].split(' > ')[0];
  if (lastCategory.length) return lastCategory;
  return null;
}

export function generateGuid() {
  return uuid();
}

export function isEmptyDictionaryProperties(entry) {
  return Object.entries(entry).reduce((prev, curr) => {
    const [, attribute] = curr;
    return prev && isEmpty(attribute);
  });
}

export function joinNodes(sep, elems) {
  return (
    <span>
      {elems.map((e, i) => (
        <span key={`joined-${i}`}>
          {i > 0 ? sep : ''}
          {e}
        </span>
      ))}
    </span>
  );
}

export function assert(expr, failDescription) {
  if (!expr) {
    throw new Error(`Assert Error: ${failDescription}`);
  }
}

/**
 * Helper for iOS app which require https connection
 */
export function replaceHttpToHttpsUrl(url = '') {
  if (!url || url.startsWith('https')) return url;
  return url.replace('http', 'https');
}

/**
 * Recursively replace each values in object a with object b.
 * Works similarly with lodash.defaultsDeep(b, a), but arrays are also replaced.
 * @param {object} a
 * @param {object} b
 *
 * Example of usage:
 * const a = {
 *  a: 'a',
 *  b: [1, 2],
 *  d: {
 *    a: 'a',
 *    b: [1, 2],
 *  }
 * }
 * const b = {
 *  b: [3],
 *  c: 'c',
 *  d: {
 *    b: [5],
 *  }
 * }
 *
 * replaceDeep(a, b) === {
 *  a: 'a',
 *  b: [1, 2],
 *  c: 'c',
 *  d: {
 *    a: 'a',
 *    b: [5],
 *  }
 * }
 */
export function replaceDeep(a, b) {
  if (!(a instanceof Object) || a instanceof Array) {
    if (b === undefined) return a;
    return b;
  }

  if (b === undefined) return a;
  return union(Object.keys(a), Object.keys(b))
    .map((k) => [k, replaceDeep(a[k], b[k])])
    .reduce((obj, [k, v]) => {
      obj[k] = v; // eslint-disable-line no-param-reassign
      return obj;
    }, {});
}

/** derived from external/utils.js */
export function clearHtmlTagFromString(string) {
  return string.replace(/(<([^>]+)>)/gi, '');
}

/*
  Return true if secondVersion > firstVersion
  e.g.
  '2.29', '2.28' -> return false
  '2.29', '2.30' -> return true
  '2.29', '2.4' -> return false
  '2.29', '3.0' -> return true
  '2.29', '2.29' -> return false
  '2.29, '2.29.1' -> return true
*/
export function isNewerVersion(firstVersion, secondVersion) {
  return (
    secondVersion.localeCompare(firstVersion, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) === 1
  );
}
