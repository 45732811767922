import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';

import { createSearchAdapter } from '@dkrm/algolia-adapter';
import { SearchRouteChanger } from '@dkrm/algolia-components';

import { SearchProvider } from '../SearchContext';

const getFetchData = (component = {}) =>
  component.WrappedComponent
    ? getFetchData(component.WrappedComponent)
    : component.fetchData;

const withSSRSearch = (searchProviderProps) => (WrappedComponent) =>
  class SSRSearchWrapper extends React.Component {
    static async fetchData(context) {
      const { res, store, query, params } = context;
      const {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        shouldSearchOnChange,
        shouldSubscribeToStateChange,
        shouldSubscribeToResultChange,
        shouldSubscribeToResultError,
        shouldSearchOnMount,
        children,
        UrlSerializer,
        consumeSSRResultFunction,
        ...searchAdapterOptions
        /* eslint-enable @typescript-eslint/no-unused-vars */
      } = searchProviderProps;

      let { initialState } = searchAdapterOptions;
      if (UrlSerializer) {
        const urlSerializer = new UrlSerializer(store);
        const state = await urlSerializer.serialize(params, query);

        initialState = merge({}, searchProviderProps.initialState, state);
      }

      const searchAdapter = createSearchAdapter({
        ...searchAdapterOptions,
        initialState,
      });

      const result = await searchAdapter.searchOnce();
      // server must return status 404 on no results
      if ((result.content?.hits || []).length === 0) {
        res.status(404);
      }

      const wrappedFetchData = getFetchData(WrappedComponent);
      const wrappedInjectedProps = wrappedFetchData
        ? (await wrappedFetchData(context)) || {}
        : {};

      if (consumeSSRResultFunction) {
        await consumeSSRResultFunction(result, { store, params, query });
      }

      return {
        searchState: result.state,
        searchResult: result.content,
        ...wrappedInjectedProps,
      };
    }

    static propTypes = {
      searchResult: PropTypes.shape(),
      searchState: PropTypes.shape(),
      loading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      searchResult: null,
      searchState: null,
    };

    constructor(props) {
      super(props);

      const {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        shouldSearchOnChange,
        shouldSubscribeToStateChange,
        shouldSubscribeToResultChange,
        shouldSubscribeToResultError,
        shouldSearchOnMount,
        children,
        UrlSerializer,
        ...searchAdapterOptions
        /* eslint-enable @typescript-eslint/no-unused-vars */
      } = searchProviderProps;

      this.searchAdapter = createSearchAdapter(searchAdapterOptions);
      if (props.searchState || props.searchResult) {
        if (this.searchAdapter.initialState.index === props.searchState.index) {
          this.searchAdapter.setState(props.searchState);
          this.searchAdapter.setResult(props.searchResult);
        }
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { searchState, searchResult } = this.props;
      if (
        (nextProps.searchState !== searchState ||
          nextProps.searchResult !== searchResult) &&
        nextProps.searchState &&
        nextProps.searchResult
      ) {
        this.searchAdapter.setState(nextProps.searchState);
        this.searchAdapter.setResult(nextProps.searchResult);
      }
    }

    render() {
      const { UrlSerializer, shouldSearchOnMount = false } =
        searchProviderProps;
      return (
        <SearchProvider
          {...searchProviderProps}
          searchAdapter={this.searchAdapter}
          shouldSearchOnMount={shouldSearchOnMount}
        >
          {UrlSerializer && (
            <SearchRouteChanger UrlSerializer={UrlSerializer} />
          )}
          <WrappedComponent {...this.props} />
        </SearchProvider>
      );
    }
  };

export default withSSRSearch;
