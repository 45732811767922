import React, { memo, useCallback } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { Login } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { getUrlWithQuery } from 'app-libs/etc/routeHelper';
import { useHistory } from 'app-libs/hook_modules/router';
import { K_ROUTE_LOGIN } from 'constants/routeConstants';

const LoginButton = memo(() => {
  const history = useHistory();

  const actionRedirectToLogin = useCallback(() => {
    const {
      location: { pathname },
    } = history;

    const loginUrl = getUrlWithQuery(K_ROUTE_LOGIN, {
      redirect: pathname,
    });

    actionRouteActionOpenURL(loginUrl, history);
  }, [history]);

  return (
    <ButtonWrapper onPress={actionRedirectToLogin} style={s.buttonContainer}>
      {() => (
        <View style={s.searchButton}>
          <Login size={24} theme="black" style={{ alignSelf: 'center' }} />
          <View style={cStyles.mll}>
            <Text theme="h5" style={cStyles.mbs}>
              Masuk
            </Text>
            <Text theme="body3-black60">Gunakan alamat tersimpan</Text>
          </View>
        </View>
      )}
    </ButtonWrapper>
  );
});

const s = StyleSheet.create({
  buttonContainer: {
    ...cStyles.brm,
    ...cStyles.bwam,
    ...cStyles.mvm,
    borderColor: Colors.C_BLACK_10,
  },
  searchButton: {
    ...cStyles.flexDirRow,
    ...cStyles.pal,
  },
});

export default withRouter(LoginButton);
