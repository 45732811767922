import React, { memo, useMemo } from 'react';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

import { unslugify } from 'app-libs/etc';
import { useParams } from 'app-libs/hook_modules/router';

const CategoryLocationPageFooter: React.FC = memo(() => {
  const { lvl0 = '', areaSlug = '' } = useParams<Record<string, string>>();

  const areaString = areaSlug !== '' ? ` ${unslugify(areaSlug)}` : '';
  const categoryString = unslugify(lvl0);

  const footerTitle = `Temukan Koleksi ${categoryString}${areaString} Terbaru dan Terlengkap Hanya Di Dekoruma`;

  // @note(dika) 4 Nov 2024: Hardcoded as of now
  // we are not using article posts as footer anymore
  // instead we use template content
  const footerHtml = useMemo(() => {
    return {
      __html: `
      <p>Buat kamu yang sedang kebingungan mencari dan mendapatkan ${categoryString} dengan kualitas terbaik di ${areaString} dan sekitarnya, tak perlu khawatir lagi. Dekoruma telah hadir untuk memudahkan kamu mendapatkan koleksi ${categoryString}${areaString} terbaru dan terlengkap, dengan desain yang selalu mengikuti tren terbaru, tentunya.</p>
      <p>Dekoruma menyediakan beragam jenis ${categoryString}, mulai dari <a href="https://www.dekoruma.com/Sofa">sofa</a>, <a href="https://www.dekoruma.com/Rak-&-Penyimpanan">rak dan penyimpanan</a>, <a href="https://www.dekoruma.com/Lemari-Pakaian">lemari pakaian</a>, <a href="https://www.dekoruma.com/Tempat-Tidur">tempat tidur</a>, bahkan hingga kebutuhan kantor seperti <a href="https://www.dekoruma.com/Kursi-Kantor">kursi kantor</a> dan <a href="https://www.dekoruma.com/Meja-Kerja">meja kerja</a>. Pokoknya, Dekoruma menjadi one stop shopping untuk segala macam kebutuhan ${categoryString} kamu.</p>
      <p>Tidak hanya jenis produknya yang lengkap, koleksi ${categoryString}${areaString} Dekoruma juga bervariasi dan dapat menyesuaikan gaya interior rumah kamu, mulai dari gaya <a href="https://www.dekoruma.com/portfolio/japandi-natural">Japandi Natural</a> yang hangat, modern tropis yang mewah tetapi natural, <a href="https://www.dekoruma.com/portfolio/modern-kontemporer">modern kontemporer</a> yang elegan, bahkan hingga <a href="https://www.dekoruma.com/portfolio/modern-klasik">modern klasik</a> dengan kesan mewah yang abadi.</p>
      <p>Dekoruma juga tak hanya menjadi pilihan terbaik untuk pilihan ${categoryString}${areaString}, kami juga menyediakan jasa <a href="https://www.dekoruma.com/interior">desain interior</a> yang dikerjakan secara profesional dengan harga yang terjangkau, pembayaran yang dapat dicicil, sampai dengan garansi hingga dua tahun. Jadi, untuk beragam kebutuhan interior maupun ${categoryString}${areaString}, percayakan saja semuanya pada Dekoruma.</p>
      <p>Oh iya, Dekoruma tak cuma hadir di ${areaString} saja. <a href="https://www.dekoruma.com/toko-furniture">Toko furniture</a> Dekoruma juga hadir di banyak kota besar lainnya di Indonesia.</p>
      `,
    };
  }, [areaString, categoryString]);
  return (
    <View style={s.container}>
      <Text h2 theme="b4-neutral500" style={cStyles.tac}>
        {footerTitle}
      </Text>
      <Text theme="b5-neutral100">
        <div dangerouslySetInnerHTML={footerHtml} />
      </Text>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pa16,
  },
});

export default CategoryLocationPageFooter;
