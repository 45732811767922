import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { ArrowBack, LoveEmpty, Search, ShoppingCart } from '@dkrm/icons';
import { goBack } from '@dkrm/ui-kit-basic/Router';

import NavbarWithLogo from 'app-libs/components/NavbarImplementation/NavbarWithLogo';
import { headBasketAndLoadIfExist } from 'app-libs/redux_modules/entity_modules/basket';
import { getNumberOfPins } from 'app-libs/redux_modules/entity_modules/selectors/album';
import {
  getBasketAsyncState,
  getTotalQuantityOfBasket,
} from 'app-libs/redux_modules/entity_modules/selectors/basket';

import {
  K_ROUTE_CART,
  K_ROUTE_SEARCH_SUGGESTION,
  K_ROUTE_WISHLIST,
} from 'constants/routeConstants';

@withRouter
@connect(
  (state) => ({
    basketAsyncState: getBasketAsyncState(state),
    quantityOfBasket: getTotalQuantityOfBasket(state),
    numberOfPins: getNumberOfPins(state),
  }),
  {
    actionHeadBasketAndLoadIfExist: headBasketAndLoadIfExist,
  },
)
export default class NavbarStorefront extends PureComponent {
  static propTypes = {
    quantityOfBasket: PropTypes.number.isRequired,
    basketAsyncState: PropTypes.shape({
      loaded: PropTypes.bool,
    }).isRequired,
    containerStyle: stylePropTypes,
    actionHeadBasketAndLoadIfExist: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    children: PropTypes.node.isRequired,
    numberOfPins: PropTypes.number.isRequired,
    shouldUseBackButton: PropTypes.bool,
  };

  static defaultProps = {
    containerStyle: null,
    shouldUseBackButton: false,
  };

  componentDidMount() {
    const { basketAsyncState, actionHeadBasketAndLoadIfExist } = this.props;
    if (!basketAsyncState.loaded) {
      actionHeadBasketAndLoadIfExist();
    }
  }

  render() {
    const {
      shouldUseBackButton,
      history,
      quantityOfBasket,
      containerStyle,
      children,
      numberOfPins,
    } = this.props;
    const navbarRightButtons = [
      {
        IconComponent: Search,
        url: K_ROUTE_SEARCH_SUGGESTION,
        props: { isUsingAnchor: false, style: { paddingHorizontal: 8 } },
      },
      {
        IconComponent: LoveEmpty,
        url: K_ROUTE_WISHLIST,
        props: { isUsingAnchor: false, style: { paddingHorizontal: 8 } },
        showBadge: numberOfPins,
        iconProps: { badgeText: numberOfPins, badgeSize: 13 },
      },
      {
        IconComponent: ShoppingCart,
        url: K_ROUTE_CART,
        showBadge: !!quantityOfBasket,
        props: { isUsingAnchor: true, style: { paddingLeft: 8 } },
        iconProps: { badgeText: quantityOfBasket, badgeSize: 13 },
      },
    ];

    const backButtonProps = shouldUseBackButton
      ? {
          leftButtonIcon: ArrowBack,
          leftButtonProps: { onPress: () => goBack(history) },
        }
      : {};

    return (
      <React.Fragment>
        <View style={[{ backgroundColor: '#FFF', zIndex: 10 }, containerStyle]}>
          <NavbarWithLogo
            {...this.props}
            {...backButtonProps}
            containerStyle={containerStyle}
            rightButtons={navbarRightButtons}
          />
        </View>
        {children}
      </React.Fragment>
    );
  }
}
