import has from 'lodash/has';

import {
  K_POST_MESSAGE_TYPE_SET_USER_ID,
  K_POST_MESSAGE_TYPE_USER_SIGNED_IN,
  K_POST_MESSAGE_TYPE_USER_LOGGED_OUT,
  K_POST_MESSAGE_TYPE_FACEBOOK_LOGIN,
  K_POST_MESSAGE_TYPE_SHARE,
  K_POST_MESSAGE_TYPE_MARK_AS_SEEN_NOTIFICATION,
  K_POST_MESSAGE_TYPE_SET_DEFAULT_DASHBOARD,
  K_POST_MESSAGE_TYPE_RELOAD,
  K_POST_MESSAGE_TYPE_SHOW_IN_APP_REVIEW,
  K_POST_MESSAGE_TYPE_TRACK_FB_EVENT,
} from './constants';

const postMessage = (type, data) => {
  if (has(global, 'window.ReactNativeWebView.postMessage')) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
  }
};

const webView = {
  setUserId: (email) => {
    postMessage(K_POST_MESSAGE_TYPE_SET_USER_ID, { email });
  },
  trackEvent: (eventName, eventData = {}) => {
    // @todo(dika) 31 Oct 2022: Remove all usage of wv.trackEvent
    // currently still on testing phase to turn off appsflyer tracker
    return { eventName, eventData };
    // postMessage(K_POST_MESSAGE_TYPE_TRACK_EVENT, { eventName, eventData });
  },
  setUserLoggedIn: (user = {}) => {
    postMessage(K_POST_MESSAGE_TYPE_USER_SIGNED_IN, { user });
  },
  setUserLoggedOut: () => {
    postMessage(K_POST_MESSAGE_TYPE_USER_LOGGED_OUT);
  },
  setDefaultDashboard: ({ name, url }) => {
    postMessage(K_POST_MESSAGE_TYPE_SET_DEFAULT_DASHBOARD, { name, url });
  },
  actionShare: (text, url) => {
    postMessage(K_POST_MESSAGE_TYPE_SHARE, { text, url });
  },
  actionLoginWithFacebook: () => {
    postMessage(K_POST_MESSAGE_TYPE_FACEBOOK_LOGIN);
  },
  actionMarkAsSeenNotification: () => {
    postMessage(K_POST_MESSAGE_TYPE_MARK_AS_SEEN_NOTIFICATION);
  },
  actionReload: () => {
    postMessage(K_POST_MESSAGE_TYPE_RELOAD);
  },
  actionShowInAppReview: () => {
    postMessage(K_POST_MESSAGE_TYPE_SHOW_IN_APP_REVIEW);
  },
  actionTrackFBEvent: ({ eventName, valueToSum, eventData }) => {
    postMessage(K_POST_MESSAGE_TYPE_TRACK_FB_EVENT, {
      eventName,
      valueToSum,
      eventData,
    });
  },
};

export default webView;
