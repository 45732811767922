import loadable from '@loadable/component';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import union from 'lodash/union';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ACTION_FILTER } from '@dkrm/ui-kit-basic/Icon/constants';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import {
  ButtonWrapper as Button,
  ButtonWithTextAndIcon,
} from '@dkrm/ui-kit-basic/v2';

import { withSearchConsumer } from 'app-libs/AlgoliaAdapter';
import ModalFallback from 'app-libs/components/ModalFallback';

const ContextualFiltersGroupModal = loadable(() =>
  import('../ContextualFiltersGroupModal'),
);

const getNumberOfFilterUsed = (searchAdapter, { initialState }) => {
  const searchStates = searchAdapter.getState();
  const disjunctive = get(searchStates, 'disjunctiveFacetsRefinements', {});
  const numerical = get(searchStates, 'numericRefinements', {});
  const initialDisjunctive = get(
    initialState,
    'disjunctiveFacetsRefinements',
    {},
  );
  const initialNumerical = get(initialState, 'numericRefinements', {});
  const usedDisjunctive = union(
    Object.keys(disjunctive),
    Object.keys(initialDisjunctive),
  ).filter(
    (facet) =>
      !isEmpty(disjunctive[facet]) &&
      !isEqual(disjunctive[facet], initialDisjunctive[facet]),
  );
  const usedNumerical = union(
    Object.keys(numerical),
    Object.keys(initialNumerical),
  ).filter(
    (facet) =>
      !isEmpty(numerical[facet]) &&
      !isEqual(numerical[facet], initialNumerical[facet]),
  );
  const usedFilter = [...usedDisjunctive, ...usedNumerical];
  return usedFilter.length;
};

const K_FILTER_BUTTON_CONFIG = {
  listProps: {
    buttonProps: {
      align: 'left',
      theme: 'black',
      themeType: 'tertiary',
      small: true,
      iconName: ACTION_FILTER,
      iconColor: 'black',
      reversed: true,
    },
  },
};

const ContextualFilterButton = (props) => {
  const {
    config: {
      listProps: { isModalOpen, onRequestOpen, onRequestClose },
    },
    config,
    getFilterConfig,
    excludes,
    onDisjunctiveChange,
    onNumericalChange,
    onApplyFilter,
    initialState,
    numberOfUsedFilter,
    history,
    isRounded,
  } = props;

  const handleButtonPress = useCallback(() => {
    return isModalOpen ? onRequestClose() : onRequestOpen();
  }, [isModalOpen, onRequestClose, onRequestOpen]);

  const isFilterUsed = numberOfUsedFilter > 0;

  const filterConfig = merge(K_FILTER_BUTTON_CONFIG, config);
  const buttonStyle = isRounded ? s.roundedButtonStyle : s.buttonStyle;

  return (
    <>
      {!isFilterUsed ? (
        <ButtonWithTextAndIcon
          {...filterConfig.listProps.buttonProps}
          theme="black"
          iconColor={colors.C_BLACK_40}
          iconSize={20}
          themeType="tertiary"
          title="Filter"
          onPress={handleButtonPress}
          style={buttonStyle}
          buttonStyle={buttonStyle}
          textStyle={s.buttonTextStyle}
        />
      ) : (
        <Button
          onPress={handleButtonPress}
          align="left"
          theme="tosca"
          themeType="tertiary"
          small
          reversed
          style={buttonStyle}
          hoverableViewStyle={s.flexMiddle}
        >
          {() => (
            <View style={s.buttonContainerStyle}>
              <Text theme="h5-tosca4" style={s.numberOfUsedFilterContainer}>
                {numberOfUsedFilter}
              </Text>
              <Text theme="h5-tosca4">Filter</Text>
            </View>
          )}
        </Button>
      )}
      {isModalOpen && (
        <ContextualFiltersGroupModal
          excludes={excludes}
          onDisjunctiveChange={onDisjunctiveChange}
          onNumericalChange={onNumericalChange}
          onApplyFilter={onApplyFilter}
          history={history}
          initialState={initialState}
          isFilterUsed={isFilterUsed}
          {...config}
          getFilterConfig={getFilterConfig}
          fallback={<ModalFallback closeModal={onRequestClose} />}
        />
      )}
    </>
  );
};

ContextualFilterButton.propTypes = {
  config: PropTypes.shape({
    itemComponent: PropTypes.func.isRequired,
    label: PropTypes.string,
    listComponent: PropTypes.func.isRequired,
    listProps: PropTypes.shape({
      isModalOpen: PropTypes.bool.isRequired,
      onRequestOpen: PropTypes.func.isRequired,
      onRequestClose: PropTypes.func.isRequired,
    }).isRequired,
    facetStatsSearchAdapter: PropTypes.shape(),
    name: PropTypes.string,
  }).isRequired,
  excludes: PropTypes.arrayOf(PropTypes.string),
  getFilterConfig: PropTypes.func.isRequired,
  history: PropTypes.shape(),
  initialState: PropTypes.shape(),
  numberOfUsedFilter: PropTypes.number,
  onDisjunctiveChange: PropTypes.func,
  onNumericalChange: PropTypes.func,
  onApplyFilter: PropTypes.func,
  isRounded: PropTypes.bool,
};

ContextualFilterButton.defaultProps = {
  excludes: [],
  initialState: {},
  history: {},
  numberOfUsedFilter: 0,
  onApplyFilter: () => null,
  onDisjunctiveChange: () => null,
  onNumericalChange: () => null,
  isRounded: false,
};

export default withSearchConsumer({
  numberOfUsedFilter: getNumberOfFilterUsed,
})(ContextualFilterButton);

const s = StyleSheet.create({
  buttonContainerStyle: {
    ...cStyles.flex1,
    ...cStyles.flexMiddle,
    ...cStyles.flexDirRow,
    minHeight: 48,
  },
  buttonTextStyle: {
    ...tStyles.h5,
    lineHeight: 15,
    color: colors.C_BLACK_100,
  },
  buttonStyle: {
    ...cStyles.flexMiddle,
    ...cStyles.flex1,
    minHeight: 48,
  },
  roundedButtonStyle: {
    ...cStyles.flexMiddle,
    ...cStyles.flex1,
    ...cStyles.pan,
    ...cStyles.man,
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    minHeight: 48,
  },
  numberOfUsedFilterContainer: {
    ...cStyles.mrs,
    ...cStyles.flexMiddle,
    textAlign: 'center',
    width: 22,
    height: 22,
    backgroundColor: colors.C_SECONDARY_TOSCA_1,
    borderRadius: '50%',
  },
});
