import React from 'react';
import { Text, StyleSheet } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';
import { ViewStyle } from 'types/styles';

export interface FooterSectionTitleProps {
  title: string;
  styles?: ViewStyle;
}

export const FooterSectionTitle: React.FC<FooterSectionTitleProps> = ({
  title,
  styles,
}) => {
  return (
    <Text theme="h5-black40" style={[s.text, styles]}>
      {title}
    </Text>
  );
};

const s = StyleSheet.create({
  text: {
    ...cStyles.mvm,
    ...cStyles.mtxxl,
  },
});

export default FooterSectionTitle;
