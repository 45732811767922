import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { formatPriceId } from 'app-libs/etc';

interface PriceSectionProps {
  priceAfterDiscount: number;
  price: number;
  discountPercentage: number;
  installmentDuration?: number;
  isBrandingPushed?: boolean;
  priceBeforeDiscountTextTheme?: string;
  discountLabelTextTheme?: string;
  priceAfterDiscountTextTheme?: string;
  brandingPushedPriceTextTheme?: string;
  installmentTextTheme?: string;
  shouldShowMonthlyInstallmentPrice?: boolean;
  RightComponent?: React.ElementType;
  AdditionalInfoComponent?: React.ElementType;
}

const PriceSection: React.FC<PriceSectionProps> = memo(
  ({
    price,
    priceAfterDiscount,
    discountPercentage,
    installmentDuration = 3,
    isBrandingPushed = true,
    priceBeforeDiscountTextTheme = 'body4-neutral100',
    discountLabelTextTheme = 'h7-red',
    priceAfterDiscountTextTheme = 'h5-neutral900',
    brandingPushedPriceTextTheme = 'h4-white',
    installmentTextTheme = 'caption3-neutral100',
    shouldShowMonthlyInstallmentPrice = false,
    RightComponent = null,
    AdditionalInfoComponent = null,
  }) => {
    const monthlyInstallments = Math.ceil(
      priceAfterDiscount / installmentDuration,
    );
    const formattedMonthlyInstallments = formatPriceId(monthlyInstallments);

    return (
      <View style={s.container}>
        <View style={cStyles.flexDirRow}>
          <View style={cStyles.flexDirCol}>
            {discountPercentage > 0 && (
              <View style={s.discountedPriceContainer}>
                <Text
                  theme={priceBeforeDiscountTextTheme}
                  style={s.discountedPriceText}
                >
                  Rp{formatPriceId(price)}
                </Text>
                <View style={s.discountLabelContainer}>
                  <Text theme={discountLabelTextTheme}>
                    {discountPercentage}%
                  </Text>
                </View>
              </View>
            )}
            {!(isBrandingPushed && discountPercentage > 0) && (
              <Text theme={priceAfterDiscountTextTheme}>
                Rp{formatPriceId(priceAfterDiscount)}
              </Text>
            )}
            {isBrandingPushed && discountPercentage > 0 && (
              <>
                <View style={s.brandingPushedPriceContainer}>
                  <Text theme={brandingPushedPriceTextTheme}>
                    Rp{formatPriceId(priceAfterDiscount)}
                  </Text>
                </View>
              </>
            )}
          </View>
          {!!RightComponent && <RightComponent />}
        </View>
        <View style={s.installmentContainer}>
          {shouldShowMonthlyInstallmentPrice && (
            <Text theme={installmentTextTheme}>
              Cicilan: {installmentDuration} x {formattedMonthlyInstallments}
            </Text>
          )}
          {!!AdditionalInfoComponent && <AdditionalInfoComponent />}
        </View>
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.mbl,
  },
  discountedPriceContainer: {
    ...cStyles.flexDirRow,
  },
  discountedPriceText: {
    textDecorationLine: 'line-through',
  },
  discountLabelContainer: {
    ...cStyles.mls,
    ...cStyles.brs,
    ...cStyles.pvxs,
    ...cStyles.phs,
    ...cStyles.bwam,
    borderColor: Colors.C_PRIMARY_RED,
  },
  brandingPushedLogoContainer: {
    ...cStyles.mts,
    ...cStyles.phs,
    ...cStyles.mls,
    marginRight: 'auto',
    zIndex: 10,
    borderRadius: 2,
    backgroundColor: Colors.C_PRIMARY_RED,
    transform: 'skew(-20deg)',
  },
  brandingPushedLogoText: {
    fontSize: 6,
  },
  brandingPushedPriceContainer: {
    ...cStyles.phm,
    ...cStyles.pvs,
    ...cStyles.mts,
    marginRight: 'auto',
    backgroundColor: Colors.C_PRIMARY_RED,
  },
  installmentContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mtm,
  },
});

export default PriceSection;
