/* global fbq */

const K_DEKORUMA_COM_EMAIL = '@dekoruma.com';
const K_DEKORUMA_GMAIL_COM_EMAIL = 'dekoruma@gmail.com';

/*
  @note(dika) 22 Oct 2021: Exclude users with dekoruma email
  to improve facebook learning data
 */
const shouldTrackUserEmail = (email) => {
  if (!email) {
    return true;
  }
  const emailString = String(email);
  return (
    !emailString.includes(K_DEKORUMA_COM_EMAIL) &&
    !emailString.includes(K_DEKORUMA_GMAIL_COM_EMAIL)
  );
};

const fbPixelWrapper = {
  logEvent: (...args) => {
    if (!!fbq && shouldTrackUserEmail(fbq.email)) {
      fbq(...args);
    }
  },
  setEmail: (email) => {
    fbq.email = email;
  },
};

export default fbPixelWrapper;
