import React, { memo } from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import BaseUSPSection from 'components/USPSection';

import { useMediaQuery } from 'entities/footer/hooks';

import { K_DEKORUMA_LOGO } from 'constants/assetConstants';
import { K_ROUTE_PROMOTION } from 'constants/routeConstants';

import {
  K_HEIGHT_DEKORUMA_DEFAULT_ICON,
  K_MOBILE_FOOTER_BREAKPOINT,
  K_WIDTH_DEKORUMA_DEFAULT_ICON,
} from '../constants';
import { DekorumaLogo } from './DekorumaLogo';

export const K_CONTENT_MAX_WIDTH = 1200;

const K_USP_DATA = [
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/Japandi.png?auto=webp`,
    title: 'Japandi Design',
    linkUrl: `${K_ROUTE_PROMOTION}/japandi-gaya-hidup-masa-kini`,
  },
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/Gratis+Ongkir.png?auto=webp`,
    title: 'Gratis Ongkir* se-Indonesia',
    linkUrl: `${K_ROUTE_PROMOTION}/gratis-ongkir-jawa-sumatra-kalimantan-bali`,
  },
  {
    iconUri: `${config.API_URL_MEDIA_CDN}/usp/24x+Cicilan.png?auto=webp`,
    title: '24x Cicilan',
    linkUrl: `${K_ROUTE_PROMOTION}/cicilan-0-persen-retail`,
  },
];

const USPSection: React.FC = memo(() => {
  const isMobile = useMediaQuery(K_MOBILE_FOOTER_BREAKPOINT);
  return (
    <View style={cStyles.mbxxl}>
      <View style={[s.container, isMobile && cStyles.phxl]}>
        <View style={isMobile ? s.mFixedWidth : s.fixedWidth}>
          <DekorumaLogo
            logoUri={K_DEKORUMA_LOGO}
            width={K_WIDTH_DEKORUMA_DEFAULT_ICON}
            height={K_HEIGHT_DEKORUMA_DEFAULT_ICON}
          />
          <Text
            theme="body2-black80"
            style={[cStyles.mhxl, isMobile && s.mText]}
          >
            Temukan furnitur & aksesoris terbaik untuk hunian bergaya Japandi
            dengan harga terjangkau
          </Text>
        </View>
      </View>
      <BaseUSPSection data={K_USP_DATA} />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.flexMiddle,
    ...cStyles.paxxl,
  },
  fixedWidth: {
    ...cStyles.width100,
    ...cStyles.flexDirRow,
    maxWidth: K_CONTENT_MAX_WIDTH,
  },
  mFixedWidth: {
    ...cStyles.flexMiddle,
    ...cStyles.flexDirCol,
  },
  mText: {
    ...cStyles.mtxl,
    textAlign: 'center',
  },
});

export default USPSection;
