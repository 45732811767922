import React, { memo, useCallback } from 'react';

import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import {
  ButtonWithText,
  Colors,
  Image,
  StyleSheet,
  Text,
  View,
  cStyles,
} from '@dkrm/ui-kit-v2';

import { getAppStoreOrPlayStoreUrl } from 'app-libs/etc/appVersionHelper';
import { useHistory } from 'app-libs/hook_modules/router';

import {
  K_UNM_ONE_ACCOUNT_LOGO_URL,
  K_UNM_SSO_LOGO_URL,
} from 'constants/unmConstants';

const K_USPS_JOIN_BLIBLI_TIKET_ECOSYSTEM = [
  {
    title: 'Satu akun untuk semua',
    description:
      'Cukup satu akun untuk akses Blibli, Tiket.com, RANCH, dan Dekoruma tanpa ribet ingat banyak password.',
    imageUrl: K_UNM_ONE_ACCOUNT_LOGO_URL,
  },
  {
    title: 'Sekali login untuk semua aplikasi',
    description:
      'Cukup sekali login di Blibli, Tiket.com, RANCH, atau Dekoruma untuk akses seluruh ekosistem Blibli Tiket.',
    imageUrl: K_UNM_SSO_LOGO_URL,
  },
];

const K_LOGO_SIZE = 32;

const UNMLoginForceUpdateApp: React.FC = memo(() => {
  const history = useHistory();

  const actionGoToStore = useCallback(() => {
    actionRouteActionOpenURL(getAppStoreOrPlayStoreUrl(), history, {
      isUsingAnchor: true,
    });
  }, [history]);

  const actionContinueWithoutLogin = useCallback(() => {
    window.history.back();
  }, []);

  return (
    <View style={s.container}>
      <Text theme="h2" style={s.title}>
        Perbarui Aplikasi dulu Yuk!
      </Text>
      <Text theme="b3-neutral600" style={s.subtitle}>
        Aplikasi Dekoruma sekarang bergabung ke Ekosistem Blibli Tiket. Yuk
        perbarui aplikasi Dekoruma untuk menikmati keuntungan-keuntungan
        dibawah:
      </Text>
      <View style={s.contentContainer}>
        {K_USPS_JOIN_BLIBLI_TIKET_ECOSYSTEM.map((usp) => (
          <View key={`usp-blibli-tiket-${usp.title}`} style={s.uspCard}>
            <Image
              source={usp.imageUrl}
              alt={`unm-logo-${usp.title}`}
              width={K_LOGO_SIZE}
              height={K_LOGO_SIZE}
              resizeMode="contain"
            />
            <View style={s.uspDescriptionContainer}>
              <Text theme="h3" style={cStyles.mb8}>
                {usp.title}
              </Text>
              <Text theme="b4-neutral100">{usp.description}</Text>
            </View>
          </View>
        ))}
      </View>
      <View style={s.ctaContainer}>
        <ButtonWithText
          themeType="primary"
          theme="teal"
          title="Perbarui Aplikasi"
          onPress={actionGoToStore}
        />
        <ButtonWithText
          themeType="ghost"
          title="Lanjutkan Tanpa Login"
          onPress={actionContinueWithoutLogin}
        />
      </View>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pt40,
    ...cStyles.ph16,
    ...cStyles.flex1,
    ...cStyles.flexMiddleLeft,
    backgroundImage: `linear-gradient(${Colors.C_NEUTRAL_0} 0%, ${Colors.C_TEAL_100} 50%);`,
  },
  contentContainer: {
    ...cStyles.pb16,
    ...cStyles.flex1,
    overflow: 'scroll',
  },
  title: {
    ...cStyles.mb16,
    ...cStyles.tac,
  },
  subtitle: {
    ...cStyles.mb32,
    ...cStyles.tac,
  },
  uspCard: {
    ...cStyles.pa16,
    ...cStyles.mb16,
    ...cStyles.br8,
    ...cStyles.shadowRaised,
    ...cStyles.flexDirRow,
    ...cStyles.bgDefault,
  },
  uspDescriptionContainer: {
    ...cStyles.ml16,
    ...cStyles.flex1,
    ...cStyles.flexDirCol,
  },
  ctaContainer: {
    ...cStyles.pa16,
    ...cStyles.shadowOverlay,
    ...cStyles.bgDefault,
    ...cStyles.absoluteBottom,
    gap: 16,
  },
});

export default UNMLoginForceUpdateApp;
