import get from 'lodash/get';

export const getAds = (state) =>
  get(state, 'advertisement.advertisementListsByLocation', {});

export const getAdsByLocation = (state, location) =>
  get(state, `advertisement.advertisementListsByLocation[${location}]`, []);

export const isAdvertisementBannerLoaded = (state) =>
  get(state, 'advertisement.asyncState.loaded', false);

export const getShouldShowFloatingIcon = (state) =>
  get(state, 'advertisement.shouldShowFloatingIcon');

export const getLastTimeClosePopupBanner = (state) =>
  get(state, 'advertisement.lastTimeClosePopupBanner');

export const getMoodboardEntities = (state) => state.entities.moodboards;

export const getMoodboardByUUID = (state, uuid) =>
  get(state, `entities.moodboards[${uuid}]`);

export const getHotspotByUUID = (state, uuid) =>
  get(state, `entities.hotspots[${uuid}]`);
