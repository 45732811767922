import React, { memo, useCallback, useMemo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Check } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';

import { getLocationAddress, getRecipientName } from './utils';

interface UserAddressCardProps {
  userAddress: UserAddressResponseFromGoblin;
  onPress: (userAddress: UserAddressResponseFromGoblin) => void;
}

const UserAddressCard: React.FC<UserAddressCardProps> = memo(
  ({ userAddress, onPress }) => {
    const {
      phoneNumber,
      isDefaultForShipping,
      isDraft,
      state: province,
    } = userAddress;

    const name = useMemo(
      () => getRecipientName(['firstName', 'lastName', 'title'], userAddress),
      [userAddress],
    );

    const mainAddress = useMemo(
      () => getLocationAddress(['line1', 'line2'], userAddress),
      [userAddress],
    );

    const areaAddress = useMemo(
      () =>
        getLocationAddress(
          ['line3', 'line4', 'state', 'postcode'],
          userAddress,
        ),
      [userAddress],
    );

    const draftAddress = useMemo(
      () => getLocationAddress(['line3', 'line4'], userAddress),
      [userAddress],
    );

    const handleAddressPress = useCallback(async () => {
      onPress(userAddress);
    }, [onPress, userAddress]);

    return (
      <ButtonWrapper onPress={handleAddressPress} style={cStyles.height100}>
        {() => (
          <View style={[s.container, isDefaultForShipping && s.selectedCard]}>
            {isDraft ? (
              <View style={s.textDraftContainer}>
                <Text
                  theme="caption2-black60"
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {draftAddress}
                </Text>
                <Text
                  theme="caption2-black60"
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {province}
                </Text>
              </View>
            ) : (
              <View style={s.textContainer}>
                <Text theme="h5" style={cStyles.mbm}>
                  {name}
                </Text>
                <View>
                  <Text
                    theme="caption2-black60"
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {mainAddress}
                  </Text>
                  <Text
                    theme="caption2-black60"
                    numberOfLines={2}
                    ellipsizeMode="tail"
                  >
                    {areaAddress}
                  </Text>
                  {phoneNumber && (
                    <Text theme="caption2-black60">{phoneNumber}</Text>
                  )}
                </View>
              </View>
            )}
            <Check
              theme="green"
              size={24}
              style={!isDefaultForShipping && { opacity: 0 }}
            />
          </View>
        )}
      </ButtonWrapper>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.height100,
    ...cStyles.mrl,
    ...cStyles.pam,
    borderColor: Colors.C_BLACK_10,
  },
  selectedCard: {
    backgroundColor: Colors.C_SECONDARY_TOSCA_1,
    borderColor: Colors.C_PRIMARY_TOSCA,
  },
  textContainer: {
    ...cStyles.mrs,
    ...cStyles.height100,
    justifyContent: 'space-between',
    width: 176,
  },
  textDraftContainer: {
    ...cStyles.mrs,
    ...cStyles.height100,
    justifyContent: 'center',
    width: 176,
  },
});

export default UserAddressCard;
