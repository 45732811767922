import {
  getCookieByName,
  setCookie,
  expiredDateInUTC,
} from 'app-libs/etc/cookieHelper';

function generateRandomDeviceUniqueId() {
  /**
   * If ID's are generated more than 1 milliseconds apart, they are 100% unique.
   * If two ID's are generated at shorter intervals,
   *  the chance of not unique is 1 in 36^10
   */
  const uniqueId =
    Math.random()
      .toString(36)
      .substring(2) + new Date().getTime().toString(36);

  return uniqueId;
}

export function getDeviceUniqueId() {
  if (typeof window === 'undefined' || __SERVER__) {
    return;
  }
  const deviceId = getCookieByName('device_id');
  if (deviceId) {
    return deviceId;
  }
  const newUniqueId = generateRandomDeviceUniqueId();
  const expiredDate = expiredDateInUTC(10 * 365); // set cookie to expired in 10 years
  setCookie('device_id', newUniqueId, expiredDate);

  return newUniqueId;
}

export function getDeviceUniqueEmail() {
  return _localDeviceIdToEmail(getDeviceUniqueId());
}
function _localDeviceIdToEmail(localDeviceId) {
  return `${localDeviceId}@dekononymous.com`;
}
