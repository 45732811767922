import { StyleSheet } from 'react-native';
import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    minWidth: '100%',
    borderRadius: 50,
    ...cStyles.paxxl,
    ...cStyles.flexDirRow,
  },
  toast: {
    ...cStyles.width100,
    backgroundColor: colors.C_GREY_100,
    ...cStyles.pvm,
    ...cStyles.phl,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    borderRadius: 50,
  },
  text: {
    ...cStyles.tac,
  },
});
