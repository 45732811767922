/**
 * this file must access using relative path if it's not in node_modules
 * -> because it's used in client/config
 */
import React from 'react';

import config from 'config';

export const K_QUESTION_TYPE_TEXT = 'TEXT';
export const K_QUESTION_TYPE_INTEGER_POSITIVE = 'INTEGER_POSITIVE';
export const K_QUESTION_TYPE_MULTISELECT = 'MULTISELECT';
export const K_QUESTION_TYPE_SELECT = 'SELECT';
export const K_QUESTION_TYPE_TEXTAREA = 'TEXTAREA';
export const K_QUESTION_TYPE_ADDRESS = 'ADDRESS';
export const K_QUESTION_TYPE_REGISTRATION = 'REGISTRATION';
export const K_QUESTION_TYPE_CONTACT = 'CONTACT';

export const K_CHOICE_LAYOUT_ROW = 'ROW';
export const K_CHOICE_LAYOUT_GRID = 'GRID';

export const K_SCHEDULE_STATUS_PENDING = 'Pending';
export const K_SCHEDULE_STATUS_ON_PROGRESS = 'On Progress';
export const K_SCHEDULE_STATUS_COMPLETED = 'Completed';
export const K_SCHEDULE_STATUS_CANCELLED = 'Cancelled';

export const K_SCHEDULE_STATUS = [
  K_SCHEDULE_STATUS_PENDING,
  K_SCHEDULE_STATUS_ON_PROGRESS,
  K_SCHEDULE_STATUS_COMPLETED,
  K_SCHEDULE_STATUS_CANCELLED,
];

export const K_PROJECT_STATUS_PENDING = 'Pending';
export const K_PROJECT_STATUS_ON_PROGRESS = 'On Progress';
export const K_PROJECT_STATUS_COMPLETED = 'Completed';
export const K_PROJECT_STATUS_CANCELLED = 'Cancelled';

export const K_ROLE_CUSTOMER_NAME = 'Customer';
export const K_ROLE_DESIGNER_NAME = 'Designer';
export const K_ROLE_WORKSHOP_NAME = 'Workshop';
export const K_ROLE_PROJECT_MANAGER_NAME = 'Project Manager';
export const K_ROLE_BUSINESS_MANAGER_NAME = 'Business Manager';
export const K_ROLE_INTERIOR_DESIGNER_MANAGER = 'Interior Designer Manager';
export const K_ROLE_SALES_NAME = 'Sales Person';
export const K_ROLE_ADMIN_NAME = 'Admin';
export const K_ROLE_GUEST_NAME = 'Guest';

export const K_SCHEDULE_STATUS_LEVEL = {
  [K_SCHEDULE_STATUS_PENDING]: 0,
  [K_SCHEDULE_STATUS_ON_PROGRESS]: 1,
  [K_SCHEDULE_STATUS_COMPLETED]: 2,
  [K_SCHEDULE_STATUS_CANCELLED]: 3,
};

export const K_VIEW_MODE_PRESENTATION = 'presentation';
export const K_VIEW_MODE_SPREADSHEET = 'spreadsheet';
export const K_VIEW_MODE_VO_SPREADSHEET = 'vo-spreadsheet';
export const K_VIEW_MODE_PO_LIST = 'po-list';
export const K_VIEW_MODE_PO_STATUS = 'po-status';

/** CART LINE CONSTANTS */
// cart line partner types
export const K_CART_LINE_PARTNER_TYPE_MERCHANT = 'Merchant';
export const K_CART_LINE_PARTNER_TYPE_VENDOR = 'Vendor';
export const K_CART_LINE_PARTNER_TYPE_WORKSHOP = 'Workshop';

// cart line mode
export const K_CART_LINE_MODE_MANUAL = 'Manual';
export const K_CART_LINE_MODE_AUTOMATED = 'Automated';

// cart line status
export const K_CART_LINE_STATUS_WAITING = 'Pending';
export const K_CART_LINE_STATUS_REVISED = 'To Be Revised';
export const K_CART_LINE_STATUS_ACCEPTED = 'Accepted';
export const K_CART_LINE_STATUS_REJECTED = 'Rejected';

// cart line price status
export const K_CART_LINE_PRICE_STATUS_PENDING = 'Pending';
export const K_CART_LINE_PRICE_STATUS_PUBLISHED = 'Published';

// cart line price status level
export const K_CART_LINE_PRICE_STATUS_LEVEL = {
  [K_CART_LINE_PRICE_STATUS_PENDING]: 0,
  [K_CART_LINE_PRICE_STATUS_PUBLISHED]: 1,
};

export const K_CART_LINE_ROOM_VARIATION_ORDER = 'Variation Order';

export const K_QUANTITY_UNITS = ['Pcs', 'm2'];

export const K_PROJECT_FILE_S3_SUBDIRECTORY =
  'design-and-build/projects/:projectKey/files/';

export const K_PROJECT_RECORD_TYPE_NOTE = 1;
export const K_PROJECT_RECORD_TYPE_ACTIVITY = 2;
export const K_PROJECT_RECORD_TYPE_FILE = 4;

export const K_PROJECT_RECORD_TYPE_NAME_BY_RECORD_TYPE = {
  [K_PROJECT_RECORD_TYPE_NOTE]: 'Catatan',
  [K_PROJECT_RECORD_TYPE_ACTIVITY]: 'Tugas',
};

export const K_SLOT_NAME_MORNING = 'Pagi';
export const K_SLOT_NAME_AFTERNOON = 'Siang';
export const K_SLOT_NAME_EVENING = 'Sore';
export const K_SLOT_NAME_NIGHT = 'Malam';
export const K_TIME_SLOT_BY_SLOT_NAME = {
  [K_SLOT_NAME_MORNING]: {
    hourStart: 8,
    hourEnd: 11,
  },
  [K_SLOT_NAME_AFTERNOON]: {
    hourStart: 12,
    hourEnd: 15,
  },
  [K_SLOT_NAME_EVENING]: {
    hourStart: 15,
    hourEnd: 18,
  },
  [K_SLOT_NAME_NIGHT]: {
    hourStart: 18,
    hourEnd: 21,
  },
};

export const K_PROJECT_RECORD_TYPES = Object.keys(
  K_PROJECT_RECORD_TYPE_NAME_BY_RECORD_TYPE,
);

export const K_PROJECT_ALL_RECORD_TYPE = K_PROJECT_RECORD_TYPES.reduce(
  // eslint-disable-next-line no-bitwise
  (prev, curr) => prev | curr,
);

const K_IMAGE_PARAMS = 'width=300&auto=webp';

let idCounter = 1;
export const INITIAL_QUESTIONS = [
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'serviceTypeName',
    question: 'Jasa desain apa yang Anda butuhkan?',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    choices: [
      {
        value: 'Kitchen Set',
        title: (
          <span>
            Set Dapur / <em>Kitchen Set</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/A.What+design+services+you+need-kitchen+set.jpg?${K_IMAGE_PARAMS}`,
      },
      // { value: 'Lemari Custom', title: 'Lemari Custom', image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/A.What+design+services+you+need-wardrobe.jpg?${K_IMAGE_PARAMS}` },
      {
        value: 'Desain Interior',
        title: 'Desain Interior',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/A.What+design+services+you+need-interior+design.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'propertyTypeName',
    question: 'Apa tipe hunian yang Anda miliki?',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 'Rumah', title: 'Rumah' },
      { value: 'Apartemen', title: 'Apartemen' },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'timeframe',
    question: 'Kapan Anda ingin kita hubungi?',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 1, title: 'Waktu saya fleksibel' },
      { value: 7, title: 'Dalam kurun waktu 1 minggu' },
      { value: 30, title: 'Dalam kurun waktu 1 bulan' },
      { value: 90, title: 'Dalam kurun waktu 3 bulan' },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'ownershipTypeCode',
    question: 'Apakah status kepemilikan hunian tersebut?',
    descriptor: 'Kepemilikan',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 'Penyewa', title: 'Penyewa' },
      { value: 'Pemilik', title: 'Pemilik' },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'usageTypeCode',
    question: 'Apakah rencana Anda untuk hunian tersebut?',
    descriptor: 'Pemakaian',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 'Untuk ditempati', title: 'Untuk ditempati' },
      { value: 'Untuk disewakan', title: 'Untuk disewakan' },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    condition: { usageTypeCode: 'Untuk ditempati' },
    name: 'residenceTimeframe',
    question: 'Jika untuk ditempati, berapa lama Anda berencana menempatinya?',
    descriptor: 'Jangka waktu penempatan',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      {
        value: 'Jangka pendek (1-5 tahun)',
        title: 'Jangka pendek (1-5 tahun)',
      },
      {
        value: 'Jangka panjang (> 5 tahun)',
        title: 'Jangka panjang (> 5 tahun)',
      },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++ + 1000,
    name: 'address',
    question: 'Dimana alamat hunian Anda?',
    type: K_QUESTION_TYPE_ADDRESS,
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++ + 1000,
    name: 'contact',
    question: 'Tolong tuliskan kontak Anda.',
    type: K_QUESTION_TYPE_CONTACT,
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++ + 1000,
    condition: () => false,
    name: 'contactType',
    question: 'Apa cara terbaik untuk menghubungi Anda?',
    descriptor: 'Cara menghubungi',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 'Telepon', title: 'Telepon' },
      { value: 'E-mail', title: <em>E-mail</em> },
      {
        value: 'WhatsApp / LINE',
        title: (
          <span>
            <em>WhatsApp</em> / <em>LINE</em>
          </span>
        ),
      },
      { value: 'Bertemu langsung', title: 'Bertemu langsung' },
    ],
  },
];

export const KITCHEN_QUESTIONS = [
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'kitchenAreaSize',
    question: 'Berapakah luas area dapur yang akan didesain?',
    descriptor: 'Luas dapur',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 'sekitar 1-2 m2', title: 'sekitar 1-2 m2' },
      { value: 'sekitar 2-3 m2', title: 'sekitar 2-3 m2' },
      { value: 'di atas 3 m2', title: 'di atas 3 m2' },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'kitchenShape',
    question: 'Seperti apakah bentuk set dapur yang Anda inginkan?',
    descriptor: 'Bentuk set dapur',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    choices: [
      {
        value: 'Lurus / Inline',
        title: 'Lurus / *Inline*',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/B.Bentuk+kitchen+set+yang+diinginkan-INLINE.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Bentuk “L”',
        title: 'Bentuk “L”',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/B.Bentuk+kitchen+set+yang+diinginkan-L+Shape.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Bentuk “U”',
        title: 'Bentuk “U”',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/B.Bentuk+kitchen+set+yang+diinginkan-U+SHAPE.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Island',
        title: <em>Island</em>,
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/B.Bentuk+kitchen+set+yang+diinginkan-island.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  // {
  //   id: idCounter++,
  //   name: 'kitchenBudget',
  //   question: 'Berapakah anggaran yang Anda sediakan untuk pembuatan set dapur ini?',
  //   descriptor: 'Anggaran dapur',
  //   type: K_QUESTION_TYPE_SELECT,
  //   choiceLayout: K_CHOICE_LAYOUT_ROW,
  //   choices: [
  //     {value: '10 - 20 juta', title: '10 - 20 juta'},
  //     {value: '20 - 30 juta', title: '20 - 30 juta'},
  //     {value: 'di atas 30 juta', title: 'di atas 30 juta'},
  //   ]
  // }
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'projectNotes',
    question:
      'Apakah ada hal lain yang dapat kami ketahui untuk memberikan pelayanan terbaik bagi Anda?',
    descriptor: 'Catatan tambahan',
    type: K_QUESTION_TYPE_TEXTAREA,
  },
];

export const WARDROBE_QUESTIONS = [
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'wardrobeShape',
    question: 'Seperti apakah bentuk lemari yang Anda inginkan?',
    descriptor: 'Bentuk lemari',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    choices: [
      {
        value: 'Lurus / Inline',
        title: (
          <span>
            Lurus / <em>Inline</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/C.Bentuk+lemari+yang+diinginkan-inline.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Bentuk “L”',
        title: 'Bentuk “L”',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/C.Bentuk+lemari+yang+diinginkan-L+shaped.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  // {
  //   id: idCounter++,
  //   name: 'wardrobeBudget',
  //   question: 'Berapakah anggaran yang Anda sediakan untuk pembuatan lemari custom ini?',
  //   descriptor: 'Anggaran lemari',
  //   type: K_QUESTION_TYPE_SELECT,
  //   choiceLayout: K_CHOICE_LAYOUT_ROW,
  //   choices: [
  //     {value: '5 - 10 juta', title: '5 - 10 juta'},
  //     {value: '10 - 20 juta', title: '10 - 20 juta'},
  //     {value: '20 - 30 juta', title: '20 - 30 juta'},
  //     {value: '30 - 50 juta', title: '30 - 50 juta'},
  //     {value: 'di atas 50 juta', title: 'di atas 50 juta'},
  //   ]
  // },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'projectNotes',
    question:
      'Apakah ada hal lain yang dapat kami ketahui untuk memberikan pelayanan terbaik bagi Anda?',
    descriptor: 'Catatan tambahan',
    type: K_QUESTION_TYPE_TEXTAREA,
  },
];

export const ROOM_QUESTIONS = [
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'roomAreaSize',
    question: 'Berapakah luas area ruangan yang akan didesain?',
    descriptor: 'Luas ruangan',
    type: K_QUESTION_TYPE_SELECT,
    choiceLayout: K_CHOICE_LAYOUT_ROW,
    choices: [
      { value: 'di bawah 60 m2', title: 'Di bawah 60 m2' },
      { value: 'Sekitar 61 m2 - 90 m2 ', title: 'Sekitar 61 m2 - 90 m2 ' },
      { value: 'di atas 90 m2', title: 'Di atas 90 m2' },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'roomCount',
    question: 'Berapakah jumlah ruangan yang perlu didesain?',
    descriptor: 'Jumlah ruangan',
    type: K_QUESTION_TYPE_INTEGER_POSITIVE,
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'roomTypes',
    question: 'Silahkan pilih ruangan yang perlu Anda desain:',
    descriptor: 'Ruangan yang akan didesain',
    type: K_QUESTION_TYPE_MULTISELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    choices: [
      {
        value: 'Ruang Tamu',
        title: 'Ruang Tamu',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-Living+Room.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Ruang Makan',
        title: 'Ruang Makan',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-dining+room.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Kamar Tidur',
        title: 'Kamar Tidur',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-bedroom.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Kamar Mandi',
        title: 'Kamar Mandi',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-bathroom.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Dapur',
        title: 'Dapur',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-kitchen.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Ruang Kerja / Ruang Belajar',
        title: 'Ruang Kerja / Ruang Belajar',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-study+room.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Ruang Laundry',
        title: (
          <span>
            Ruang <em>Laundry</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-laundry+room.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Ruang Ganti (Walking Closet)',
        title: (
          <span>
            Ruang Ganti <em>Walking Closet</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/D.choose+the+following+rooms+to+be+designed-walk+in+closet.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  // {
  //   id: idCounter++,
  //   name: 'roomBudget',
  //   question: 'Berapakah anggaran yang Anda sediakan untuk mendesain hunian Anda?',
  //   descriptor: 'Anggaran ruangan',
  //   type: K_QUESTION_TYPE_SELECT,
  //   choiceLayout: K_CHOICE_LAYOUT_ROW,
  //   choices: [
  //     {value: 'dibawah 30jt', title: 'dibawah 30jt'},
  //     {value: '30jt - 50jt', title: '30jt - 50jt'},
  //     {value: '50 jt – 99jt', title: '50jt – 99jt'},
  //     {value: '100jt – 249jt', title: '100jt – 249jt'},
  //     {value: '250jt – 449jt', title: '250jt – 449jt'},
  //     {value: '500jt – 1 M', title: '500jt – 1 M'},
  //     {value: 'di atas 1M', title: 'di atas 1M'},
  //   ]
  // },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'projectNotes',
    question:
      'Apakah ada hal lain yang dapat kami ketahui untuk memberikan pelayanan terbaik bagi Anda?',
    descriptor: 'Catatan tambahan',
    type: K_QUESTION_TYPE_TEXTAREA,
  },
];

export const STYLE_QUESTIONS = [
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'likedRoomStyle',
    question: 'Ruangan manakah yang paling Anda sukai?',
    description: 'Silahkan pilih 1 foto atau lebih.',
    descriptor: 'Jenis desain yang disukai',
    type: K_QUESTION_TYPE_MULTISELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    hideChoiceTitle: true,
    choices: [
      {
        value: 'Skandinavia',
        title: 'Skandinavia',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-scandinavian.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Natural',
        title: 'Modern Natural',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-natural.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Minimalis',
        title: 'Modern Minimalis',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-modern+minimalist.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Korea',
        title: 'Korea',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-korean.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Industrial',
        title: 'Industrial',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-industrial.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Klasik / Shabby Chic',
        title: (
          <span>
            Klasik / <em>Shabby Chic</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-shabby+chic.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Klasik',
        title: 'Modern Klasik',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+disukai-modern+klasik.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'dislikedRoomStyle',
    question: 'Ruangan manakah yang tidak sesuai dengan selera desain Anda?',
    description: 'Silahkan pilih 1 foto atau lebih.',
    descriptor: 'Jenis desain yang tidak disukai',
    type: K_QUESTION_TYPE_MULTISELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    hideChoiceTitle: true,
    choices: [
      {
        value: 'Skandinavia',
        title: 'Skandinavia',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-scandinavian.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Natural',
        title: 'Modern Natural',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-natural.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Minimalis',
        title: 'Modern Minimalis',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-modern+minimalist.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Korea',
        title: 'Korea',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-korean.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Industrial',
        title: 'Industrial',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-insuatrial.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Klasik / Shabby Chic',
        title: (
          <span>
            Klasik / <em>Shabby Chic</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-shabby+chic.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Klasik',
        title: 'Modern Klasik',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/E.+Style+Photos-Ruang+mana+yang+tidak+disukai-modern+klasik.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'colorStyle',
    question: 'Set warna di bawah ini yang kamu suka?',
    description: 'Silahkan pilih 1 foto atau lebih.',
    descriptor: 'Jenis set warna yang disukai',
    type: K_QUESTION_TYPE_MULTISELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    hideChoiceTitle: true,
    choices: [
      {
        value: 'Skandinavia',
        title: 'Skandinavia',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/F.+set+warna+gambar-scandinavian.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Natural',
        title: 'Modern Natural',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/F.+set+warna+gambar-natural.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Modern Minimalis',
        title: 'Modern Minimalis',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/F.+set+warna+gambar-modern+minimalist.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Korea',
        title: 'Korea',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/F.+set+warna+gambar-korean.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Industrial',
        title: 'Industrial',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/F.+set+warna+gambar-industrial.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Klasik / Shabby Chic',
        title: (
          <span>
            Klasik / <em>Shabby Chic</em>
          </span>
        ),
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/F.+set+warna+gambar-shabby+chic.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
  {
    // eslint-disable-next-line no-plusplus
    id: idCounter++,
    name: 'colorPalette',
    question: 'Set warna yang kamu inginkan di rumahmu?',
    description: 'Silahkan pilih 1 foto atau lebih.',
    descriptor: 'Jenis set warna yang disukai',
    type: K_QUESTION_TYPE_MULTISELECT,
    choiceLayout: K_CHOICE_LAYOUT_GRID,
    hideChoiceTitle: true,
    choices: [
      {
        value: 'Cool',
        title: 'Cool',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-cool.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Glam',
        title: 'Glam',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-glam.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Warm',
        title: 'Warm',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-warm.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Greenery',
        title: 'Greenery',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-greenery.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Monochrome',
        title: 'Monochrome',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-monochrome.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Party',
        title: 'Party',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-party+or+colorful.jpg?${K_IMAGE_PARAMS}`,
      },
      {
        value: 'Pastel',
        title: 'Pastel',
        image: `${config.API_URL_MEDIA_CDN}/design-and-build/surveys/images/G.+set+warna+yang+diinginkan+(color+palette)-pastel.jpg?${K_IMAGE_PARAMS}`,
      },
    ],
  },
];

export const PROJECT_INFO_QUESTIONS = [
  ...KITCHEN_QUESTIONS,
  ...WARDROBE_QUESTIONS,
  ...ROOM_QUESTIONS,
].map((q) => q.name);

export const PROJECT_INFO_QUESTIONS_SET = new Set(PROJECT_INFO_QUESTIONS);

export const INITIAL_QUESTIONS_BY_NAME = INITIAL_QUESTIONS.reduce(
  (acc, q) => ({ ...acc, [q.name]: q }),
  {},
);
export const STYLE_QUESTIONS_BY_NAME = STYLE_QUESTIONS.reduce(
  (acc, q) => ({ ...acc, [q.name]: q }),
  {},
);
export const PROJECT_QUESTIONS_BY_NAME = []
  .concat(KITCHEN_QUESTIONS, WARDROBE_QUESTIONS, ROOM_QUESTIONS)
  .reduce((acc, q) => ({ ...acc, [q.name]: q }), {});
export const QUESTIONS_BY_NAME = {
  ...INITIAL_QUESTIONS_BY_NAME,
  ...PROJECT_QUESTIONS_BY_NAME,
  ...STYLE_QUESTIONS_BY_NAME,
};

export const DESCRIPTION_QUESTIONS = [
  'ownershipTypeCode',
  'usageTypeCode',
  'residenceTimeframe',
  'contactType',
];

const choiceReducer = (acc, { title, value }) => ({ ...acc, [value]: title });
export const K_PROPERTY_TYPE_MAP =
  INITIAL_QUESTIONS_BY_NAME.propertyTypeName.choices.reduce(choiceReducer, {});
export const K_SERVICE_TYPE_MAP =
  INITIAL_QUESTIONS_BY_NAME.serviceTypeName.choices.reduce(choiceReducer, {});

export const K_CUSTOMER_TYPE_PERSONAL = 'Personal';
export const K_CUSTOMER_TYPE_BUSINESS = 'Business';
export const K_CUSTOMER_TYPES = [
  K_CUSTOMER_TYPE_PERSONAL,
  K_CUSTOMER_TYPE_BUSINESS,
];

export const K_SERVICE_TYPE_KITCHEN = 2;
export const K_SERVICE_TYPE_WARDROBE = 3;
export const K_SERVICE_TYPE_ROOM = 1;

export const K_SERVICE_TYPE_NAME_KITCHEN = 'Kitchen Set';
export const K_SERVICE_TYPE_NAME_WARDROBE = 'Lemari Custom';
export const K_SERVICE_TYPE_NAME_ROOM = 'Desain Interior';
export const K_SERVICE_TYPE_NAME_WALK_IN_CLOSET = 'Walk-in Closet';

export const K_PORTFOLIO_CATEGORY_KITCHEN_SET = 'Kitchen Set';
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN = 'Desain Interior';
export const K_PORTFOLIO_CATEGORY_WALK_IN_CLOSET = 'Walk-in Closet';

export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Apartemen`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT_1_ROOM = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Apartemen 1 Kamar Tidur`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT_2_ROOM = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Apartemen 2 Kamar Tidur`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT_3_ROOM = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Apartemen 3+ Kamar Tidur`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT_STUDIO = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_APARTMENT} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Apartemen Studio`;

export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Rumah`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE_1_ROOM = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Rumah 1 Kamar Tidur`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE_2_ROOM = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Rumah 2 Kamar Tidur`;
export const K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE_3_ROOM = `${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN_HOUSE} > ${K_PORTFOLIO_CATEGORY_INTERIOR_DESIGN} Rumah 3+ Kamar Tidur`;

export const K_PORTFOLIO_CATEGORY_KITCHEN_SET_LETTER_I = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} > ${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Letter I`;
export const K_PORTFOLIO_CATEGORY_KITCHEN_SET_LETTER_L = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} > ${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Letter L`;
export const K_PORTFOLIO_CATEGORY_KITCHEN_SET_LETTER_U = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} > ${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Letter U`;
export const K_PORTFOLIO_CATEGORY_KITCHEN_SET_ISLAND = `${K_PORTFOLIO_CATEGORY_KITCHEN_SET} > ${K_PORTFOLIO_CATEGORY_KITCHEN_SET} Island`;

// Personal
export const K_PROPERTY_TYPE_NAME_HOME = 'Rumah';
export const K_PROPERTY_TYPE_NAME_APARTMENT = 'Apartemen';

// Commercial
export const K_PROPERTY_TYPE_NAME_BOURDING_HOUSE = 'Kos-kosan';
export const K_PROPERTY_TYPE_NAME_HOTEL = 'Hotel';
export const K_PROPERTY_TYPE_NAME_DEVELOPER = 'Developer';
export const K_PROPERTY_TYPE_NAME_OFFICE = 'Kantor';
export const K_PROPERTY_TYPE_NAME_CAFE = 'Kafe';
export const K_PROPERTY_TYPE_NAME_OTHER = 'Lainnya';

export const K_PROPERTY_COMMERCIAL_TYPE_NAMES = [
  K_PROPERTY_TYPE_NAME_OFFICE,
  K_PROPERTY_TYPE_NAME_HOTEL,
  K_PROPERTY_TYPE_NAME_DEVELOPER,
  K_PROPERTY_TYPE_NAME_CAFE,
  K_PROPERTY_TYPE_NAME_BOURDING_HOUSE,
  K_PROPERTY_TYPE_NAME_OTHER,
];

export const K_SERVICE_TYPE_NAMES = [
  K_SERVICE_TYPE_NAME_KITCHEN,
  K_SERVICE_TYPE_NAME_WARDROBE,
  K_SERVICE_TYPE_NAME_ROOM,
];

export const PROJECT_INFO_QUESTIONS_BY_TYPE = {
  [K_SERVICE_TYPE_NAME_KITCHEN]: KITCHEN_QUESTIONS.map((q) => q.name),
  [K_SERVICE_TYPE_NAME_WARDROBE]: WARDROBE_QUESTIONS.map((q) => q.name),
  [K_SERVICE_TYPE_NAME_ROOM]: ROOM_QUESTIONS.map((q) => q.name),
};

export const K_DEFAULT_BUDGET_CHOICE = '40000000';
export const K_MINIMUM_BUDGET_INTERIOR_DESIGN = 30000000;
export const K_MINIMUM_BUDGET_KITCHEN_SET = 30000000;
export const K_DEFAULT_ROOM_COUNT = '3';
export const K_DEFAULT_PROPERTY_TYPE_NAME = K_PROPERTY_TYPE_NAME_HOME;
export const K_DEFAULT_SERVICE_TYPE_NAME = K_SERVICE_TYPE_NAME_ROOM;
export const K_BUDGET_BY_ROOM_COUNT = {
  1: '30000000',
  2: '40000000',
  3: '60000000',
  4: '80000000',
  5: '100000000',
  '5++': '120000000',
};

export const K_ROOM_COUNT_CHOICES = [
  { label: '1 Ruangan', value: '1' },
  { label: '2 Ruangan', value: '2' },
  { label: '3 Ruangan', value: '3' },
  { label: '4 Ruangan', value: '4' },
  { label: '5 Ruangan', value: '5' },
  { label: 'Lebih dari 5', value: '5++' },
];

export const K_BUDGET_KITCHEN_AND_WARDROBE_CHOICES = [
  { label: '20 - 30 juta', value: '25000000' },
  { label: '30 - 50 juta', value: '40000000' },
  { label: '50 - 100 juta', value: '75000000' },
  { label: '100 - 150 juta', value: '125000000' },
  { label: '150 - 200 juta', value: '175000000' },
  { label: 'diatas 200 juta', value: '200000000' },
];

export const K_BUDGET_INTERIOR_CHOICES = [
  { label: '30 - 50 juta', value: '40000000' },
  { label: '50 - 100 juta', value: '75000000' },
  { label: '100 - 150 juta', value: '125000000' },
  { label: '150 - 200 juta', value: '175000000' },
  { label: 'diatas 200 juta', value: '200000000' },
];
export const K_BUDGET_ALL_CHOICES = K_BUDGET_KITCHEN_AND_WARDROBE_CHOICES;

export const K_DEKORUMA_PORTFOLIO_BENEFITS = [
  {
    title: 'Transparansi',
    description: 'harga',
    imageUrl:
      'https://s3-ap-southeast-1.amazonaws.com/f1-static/icon/benefit/harga-transparan-32.svg',
  },
  {
    title: 'Desain Menarik',
    description: 'konsultasi desain',
    imageUrl:
      'https://s3-ap-southeast-1.amazonaws.com/f1-static/icon/benefit/gratis-desain-32.svg',
  },
];

export const K_NO_IMAGE =
  'https://f1-static.s3-ap-southeast-1.amazonaws.com/design-and-build/projects/placeholder-image.png';

export const K_DESIGNER_LEVELS = {
  TRIAL: {
    key: 'TRIAL',
    title: 'Basic Designer',
    internalTitle: 'Trial Designer',
    description:
      'Designer yang baru bergabung dan telah menyelesaikan 0-4 proyek',
  },
  BASIC: {
    key: 'BASIC',
    title: 'Intermediate Designer',
    internalTitle: 'Basic Designer',
    description:
      'Designer yang bergabung dan telah menyelesaikan 4 atau lebih proyek di Dekoruma',
  },
  PROFESSIONAL: {
    key: 'PROFESSIONAL',
    title: 'Professional Designer',
    internalTitle: 'Professional Designer',
    description:
      'Designer yang bergabung dan telah menyelesaikan 7 atau lebih proyek di Dekoruma',
  },
};

export const K_DEFAULT_DESIGNER_DATA = {
  userUuid: '513ddea9-cfab-4a54-8236-79cad2bd73a0',
  name: 'Kania Bunga',
  photoUrl: `${config.API_URL_MEDIA_CDN}/profile/user_9.jpg?crop=800,600,x140,y520&width=400&height=300`, // prettier-ignore
};

// Cart Lines
export const K_PRODUCT_TYPE_LOOSE = 'Loose Furniture';
export const K_PRODUCT_TYPE_COMMODITY = 'Commodity';
export const K_PRODUCT_TYPE_DNB_COMMODITY = 'DnB Commodity';
export const K_PRODUCT_TYPE_CUSTOM = 'Custom';
export const K_PRODUCT_TYPE_SERVICE = 'Service';
export const K_PRODUCT_TYPE_MODULAR_FURNITURE = 'Modular Furniture';
export const K_PRODUCT_TYPE_INTERIOR_WORK = 'Interior Work';

export const K_MARKETPLACE_PRODUCT_SOURCE_TYPE = 'Merchant';
export const K_BLACKMARKET_PRODUCT_SOURCE_TYPE = 'Vendor';

export const K_PRODUCT_TYPE_SERVICE_CODE = 'SV';
export const K_PRODUCT_TYPE_SERVICE_OBJECT = {
  name: K_PRODUCT_TYPE_SERVICE,
  code: K_PRODUCT_TYPE_SERVICE_CODE,
};

export const K_PROCUREMENT_TYPE_MARKETPLACE = 'Marketplace';
export const K_PROCUREMENT_TYPE_HUB = 'Hub';
export const K_PROCUREMENT_TYPE_WORKSHOP = 'Workshop';
export const K_PROCUREMENT_TYPE_MANUAL = 'Manual';

export const K_ALLOWED_PROCUREMENT_TYPES_BY_PRODUCT_TYPE = {
  [K_PRODUCT_TYPE_LOOSE]: [
    K_PROCUREMENT_TYPE_MARKETPLACE,
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
  [K_PRODUCT_TYPE_COMMODITY]: [
    K_PROCUREMENT_TYPE_HUB,
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
  [K_PRODUCT_TYPE_DNB_COMMODITY]: [
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
  [K_PRODUCT_TYPE_CUSTOM]: [
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
  [K_PRODUCT_TYPE_SERVICE]: [
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
  [K_PRODUCT_TYPE_MODULAR_FURNITURE]: [
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
  [K_PRODUCT_TYPE_INTERIOR_WORK]: [
    K_PROCUREMENT_TYPE_MANUAL,
    K_PROCUREMENT_TYPE_WORKSHOP,
  ],
};

export const K_DEFAULT_ALLOWED_PROCUREMENT_TYPE_BY_PRODUCT_TYPE = {
  [K_PRODUCT_TYPE_LOOSE]: K_PROCUREMENT_TYPE_MARKETPLACE,
  [K_PRODUCT_TYPE_COMMODITY]: K_PROCUREMENT_TYPE_HUB,
  [K_PRODUCT_TYPE_DNB_COMMODITY]: K_PROCUREMENT_TYPE_MANUAL,
  [K_PRODUCT_TYPE_CUSTOM]: K_PROCUREMENT_TYPE_WORKSHOP,
  [K_PRODUCT_TYPE_SERVICE]: K_PROCUREMENT_TYPE_MANUAL,
  [K_PRODUCT_TYPE_MODULAR_FURNITURE]: K_PROCUREMENT_TYPE_MANUAL,
  [K_PRODUCT_TYPE_INTERIOR_WORK]: K_PROCUREMENT_TYPE_MANUAL,
};

/** @deprecated used only by f1-loki/src/components
 * new codes please refer to new ones
 */
export const K_BLACKLIST_FILTERING_ATTRIBUTES = [
  'fa.serviceTypes',

  'fa.memberUuids',
  'status',
];

/** @deprecated used only by f1-loki/src/components
 * new codes please refer to new ones
 */
export const K_SINGLE_VALUE_FILTERING_ATTRIBUTES = [
  'fa.aKitchenSetType',
  'fa.aDesignInteriorType',
  'fa.aWardrobeCustomType',
];

/** @deprecated used only by f1-loki/src/components
 * new codes please refer to new ones
 */
export const K_DESIGN_AREA_ATTRIBUTE_CODE = 'a_design_area';
export const K_DIMENSION_ATTRIBUTE_CODE = 'a_dimension';

/** @deprecated used only by f1-loki/src/components
 * new codes please refer to new ones
 */
export const K_MAIN_ATTRIBUTE_CODES = [
  'a_kitchen_set_type',
  'a_design_interior_type',
  'a_wardrobe_custom_type',
];

export const K_ACTION_ACCEPT_PROJECT_SUGGESTION = 'accept';
export const K_ACTION_IGNORE_PROJECT_SUGGESTION = 'ignore';

export const K_ITEM_TYPES = {
  TAG: 'tag',
  PRODUCT: 'product',
  LOOSE_PRODUCT: K_PRODUCT_TYPE_LOOSE,
  CUSTOM_PRODUCT: K_PRODUCT_TYPE_CUSTOM,
  COMMODITY_PRODUCT: K_PRODUCT_TYPE_COMMODITY,
  DNB_COMMODITY_PRODUCT: K_PRODUCT_TYPE_DNB_COMMODITY,
  SERVICE_PRODUCT: K_PRODUCT_TYPE_SERVICE,
};

export const K_PROCUREMENT_TYPES = [
  K_PROCUREMENT_TYPE_MARKETPLACE,
  K_PROCUREMENT_TYPE_HUB,
  K_PROCUREMENT_TYPE_WORKSHOP,
  K_PROCUREMENT_TYPE_MANUAL,
];

export const K_PURCHASE_ORDER_MARKETPLACE_GROUP = 'Marketplace';
export const K_PURCHASE_ORDER_HUB_GROUP = 'Hub';
export const K_PURCHASE_ORDER_WORKSHOP_GROUP = 'Workshop';
export const K_PURCHASE_ORDER_MANUAL_GROUP = 'Manual';
export const K_PURCHASE_ORDER_GROUP_TO_ITEM_TYPES_MAPPING = {
  [K_PURCHASE_ORDER_MARKETPLACE_GROUP]: [K_ITEM_TYPES.LOOSE_PRODUCT],
  [K_PURCHASE_ORDER_WORKSHOP_GROUP]: [K_ITEM_TYPES.CUSTOM_PRODUCT],
  [K_PURCHASE_ORDER_HUB_GROUP]: [K_ITEM_TYPES.COMMODITY_PRODUCT],
  [K_PURCHASE_ORDER_MANUAL_GROUP]: [
    K_ITEM_TYPES.SERVICE_PRODUCT,
    K_ITEM_TYPES.DNB_COMMODITY_PRODUCT,
  ],
};

export const K_PROJECT_CART_SECTION_TYPES = {
  TAGGABLE_IMAGE: 'Taggable Image',
};

export const K_TEMP_ITEM_DATA = {
  title: '[New Item]',
};

export const K_ADDRESS_TYPES = {
  DEKORUMA: 'DEKORUMA',
  PROJECT_LOCATION: 'PROJECT_LOCATION',
  CUSTOM_LOCATION: 'CUSTOM_LOCATION',
};

export const K_CUSTOM_PRODUCT_CATEGORY_ORDERING = [
  'Kitchen',
  'Wardrobe',
  'Storage',
  'Shelving',
  'Meja',
  'Bed',
  'Partisi',
  'Wallpanel',
  'Decorative',
  'Full Custom (new)',
  'Pekerjaan Sipil',
  'Lain-lain',
];

export const K_LETTER_OF_AGREEMENT_ARTICLE =
  'https://www.dekoruma.com/artikel/75423';

export const K_CONTRACT_ARTICLE = 'https://www.dekoruma.com/artikel/83261';

export const K_IDP_EMAIL = 'designpartner@dekoruma.com';

export const K_LABEL_DEAL_PACKAGE = 'Deal Package';
export const K_LABEL_COMMERCIAL = 'Commercial';

export const K_VOUCHER_DISCOUNT_TYPE_FIXED_AMOUNT = 'Fixed amount';
export const K_VOUCHER_DISCOUNT_TYPE_PERCENTAGE = 'Percentage';

export const K_LINE_NO_FILTER = {
  procurementType: [],
};

export const K_LINE_FILTER_MARKETPLACE = {
  procurementType: [K_PROCUREMENT_TYPE_MARKETPLACE],
};

export const K_LINE_FILTER_HUB = {
  procurementType: [K_PROCUREMENT_TYPE_HUB],
};

export const K_LINE_FILTER_WORKSHOP = {
  procurementType: [K_PROCUREMENT_TYPE_WORKSHOP],
};

export const K_LINE_FILTER_MANUAL = {
  procurementType: [K_PROCUREMENT_TYPE_MANUAL],
};

export const K_DEFAULT_LINE_FILTER_OPTION = {
  label: 'ALL',
  value: K_LINE_NO_FILTER,
};

export const K_LINE_FILTER_OPTIONS = [
  K_DEFAULT_LINE_FILTER_OPTION,
  { label: 'MARKETPLACE', value: K_LINE_FILTER_MARKETPLACE },
  { label: 'HUB', value: K_LINE_FILTER_HUB },
  { label: 'WORKSHOP', value: K_LINE_FILTER_WORKSHOP },
  { label: 'MANUAL', value: K_LINE_FILTER_MANUAL },
];

export const K_CUSTOM_SERVICE_PRODUCT_TYPE_CODES = ['CU', 'SV', 'DC', 'MO'];
export const K_LOOSE_PRODUCT_TYPE_CODES = ['LO'];

export const K_HOSPITALITY_AND_RETAIL_CATEGORY = 'Hospitality & Retail';
export const K_DEVELOPER_CATEGORY = 'Developer';

export const K_STYLE_NAMES_BY_URL_STYLE_SUFFIX = {
  japandi: 'Japandi Natural',
  classic: 'Japandi Classic',
  klasik: 'Japandi Classic',
  'minimalis-modern': 'Japandi Modern',
  industrial: 'Japandi Industrial',
  minimalis: 'Japandi Natural',
  modern: 'Japandi Modern',
};

export const K_LOCATION_JAKARTA_LOWER_CASE_PATHNAME = 'jakarta';
export const K_LOCATION_JAKARTA_STATE_VALUE = 'DKI Jakarta';
