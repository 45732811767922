import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useQuery, useQueryClient } from 'react-query';
import { isLoggedIn as getIsLoggedIn } from 'app-libs/redux_modules/selectors/auth';

import {
  getAvailablePremiumShippingSlot,
  getAvailablePremiumShippingSlotQueryKey,
  getSearchUserAddressQueryKey,
  searchUserAddress,
} from './api';
import { AvailablePremiumShippingSlotResponse } from './types';

const K_FALLBACK_AVAILABLE_PREMIUM_SHIPPING_SLOT: AvailablePremiumShippingSlotResponse = {
  deliverySlots: [],
  installationSlots: [],
};

export const useQuerySearchUserAddress = (query: string, page: number) => {
  const isLoggedIn: boolean = useSelector(getIsLoggedIn);

  let _query = query;

  if (query.startsWith('08')) {
    _query = `62${query.slice(1)}`;
  }

  const { data, isLoading } = useQuery(
    getSearchUserAddressQueryKey(_query, page),
    () => searchUserAddress(_query, page),
    {
      enabled: !!_query && isLoggedIn,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const addresses = useMemo(() => data?.results || [], [data]);
  return {
    results: addresses,
    isLoading,
    nbPages: data?.nbPages || 0,
  };
};

export const useQueryLoadAvailablePremiumShippingSlot = (
  city: string,
  district: string,
) => {
  const res = useQuery(
    getAvailablePremiumShippingSlotQueryKey(city, district),
    () => getAvailablePremiumShippingSlot(city, district),
    {
      enabled: !!city && !!district,
      staleTime: 10 * 60 * 1000,
      cacheTime: 20 * 60 * 1000,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const availableSlotInfo = useMemo(
    () => res.data || K_FALLBACK_AVAILABLE_PREMIUM_SHIPPING_SLOT,
    [res.data],
  );

  return {
    ...res,
    availableSlotInfo,
  };
};

export const useInvalidateLoadAvailablePremiumShippingSlotQuery = () => {
  const queryClient = useQueryClient();

  return async (city: string, district: string) => {
    await queryClient.invalidateQueries(
      getAvailablePremiumShippingSlotQueryKey(city, district),
    );
  };
};
