import { createSelector } from 'reselect';

const emptyArray = [];

export const getCustomer = (state) => state.customer;

export const getLastUsedPayment = (state) => state.customer.lastUsedPayment;

export const getCustomerShippingAddresses = (state) => {
  const customer = getCustomer(state);
  return customer.shippingAddresses || emptyArray;
};

export const getCustomerMainShippingAddress = createSelector(
  getCustomer,
  (customer) =>
    (customer?.shippingAddresses || []).find((e) => e.isDefaultForShipping) ||
    customer?.shippingAddresses?.[0],
);

export const getMainShippingAddress = (shippingAddresses) =>
  shippingAddresses?.find?.((e) => e.isDefaultForShipping) ||
  shippingAddresses?.[0];

export const getShippingAddressToEdit = (state) => {
  const shippingAddresses = getCustomerShippingAddresses(state);
  const { shippingAddressIDToEdit: id } = getCustomer(state);

  if (id >= 0) return shippingAddresses.find((e) => e.id === id) || {};
  return {};
};

export const getCityFromShippingAddress = (shippingAddress) => {
  if (!shippingAddress) return undefined;
  return shippingAddress.line4;
};

export function isShippingAddressLoaded(globalState) {
  return globalState.customer.isShippingAddressLoaded;
}

export function getIsLoadCustomerShippingAddressError(state) {
  return !!state.customer.loadCustomerShippingAddressError;
}

export function getIsCustomerShippingAddressLoading(state) {
  return state.customer.isShippingAddressLoading;
}

export const isUpdatingShippingAddress = (state) =>
  state.customer.isUpdatingShippingAddress || false;
