import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import * as Sentry from '@sentry/browser';

import ErrorView from './ErrorView';

const isReportToSentry = __CLIENT__;

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
    });
    console.error(error, errorInfo, isReportToSentry);
    if (isReportToSentry) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { errorInfo } = this.state;
    const { children } = this.props;
    if (errorInfo) return <ErrorView />;
    return children;
  }
}

export function withError(WrappedComponent) {
  return hoistStatics(
    (props) => (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    ),
    WrappedComponent,
  );
}
