import superagent from 'superagent';
import { DjangoPaginatedResponse } from 'types';

import config from 'config';

import {
  CancelOrderPayload,
  ChargeExistingCreditCardPayload,
  ChargeInstallmentWithoutCardPayload,
  ChargeInternetBankingPayload,
  ChargeNewCreditCardPayload,
  ChargeOfflinePaymentPayload,
  ChargeOfflinePaymentResponse,
  Order,
  OrderInsuranceInfo,
  OrderPaymentInfo,
  OrderPaymentStatusInfo,
  RechargeEWalletPayload,
  RequestVerifyBankTransferPaymentPayload,
  RequestVerifyBankTransferPaymentResponse,
} from './types';

export const getLoadOrderListQueryKey = (status: string) => [
  'order-list',
  status,
];

export const getLoadOrderDetailQueryKey = (orderKey: string) => [
  'order-detail',
  orderKey,
];

export const getLoadOrderInsuranceInfoQueryKey = (orderKey: string) => [
  'order-insurance-info',
  orderKey,
];

export const loadOrderList = async (
  page: number,
  status: string,
): Promise<DjangoPaginatedResponse<Order>> => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/orders-v2/`)
    .query({ page, status })
    .withCredentials();

  return res.body;
};

export const loadOrderDetail = async (orderKey: string): Promise<Order> => {
  const res = await superagent.get(
    `${config.API_URL_GOBLIN}/orders-v2/${orderKey}/`,
  );

  return res.body;
};

export const loadOrderPaymentStatus = async (
  orderKey: string,
): Promise<OrderPaymentStatusInfo> => {
  const res = await superagent.get(
    `${config.API_URL_GOBLIN}/orders-v2/${orderKey}/payment/status/`,
  );

  return res.body;
};

export const loadCreditCardTransactionStatus = async (
  orderKey: string,
): Promise<OrderPaymentInfo> => {
  const res = await superagent.get(
    `${config.API_URL_GOBLIN}/orders-v2/${orderKey}/credit-card/status/`,
  );

  return res.body;
};

export const updateCreditCardTransactionStatusToPending = async (
  orderKey: string,
): Promise<OrderPaymentInfo> => {
  const res = await superagent.put(
    `${config.API_URL_GOBLIN}/orders-v2/${orderKey}/credit-card/status/`,
  );

  return res.body;
};

export const cancelOrder = async (payload: CancelOrderPayload) => {
  const { orderKey, reason } = payload;
  const res = await superagent
    .put(`${config.API_URL_GOBLIN}/orders/${orderKey}/cancel-order/`)
    .send({ reason })
    .withCredentials();

  return res.body;
};

export const requestVerifyBankTransferPayment = async (
  payload: RequestVerifyBankTransferPaymentPayload,
): Promise<RequestVerifyBankTransferPaymentResponse> => {
  const res = await superagent
    .post(
      `${config.API_URL_GOBLIN}/payment-v2/bank-transfer/request-to-verify/`,
    )
    .send({
      order: `${config.API_URL_GOBLIN}/orders/${payload.orderKey}/`,
      reference: payload.reference,
      amount: payload.amount,
    })
    .withCredentials();

  return res.body;
};

export const chargeNewCreditCard = async (
  payload: ChargeNewCreditCardPayload,
): Promise<Order> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/payment-v2/credit-card/charge/`)
    .send({
      order: `${config.API_URL_GOBLIN}/orders/${payload.orderKey}/`,
      cvv: payload.cvv,
      installmentBank: payload.installmentBank,
      installmentTerm: payload.installmentTerm,
      lastFourDigits: payload.lastFourDigits,
      month: payload.month,
      saveCreditCard: payload.saveCreditCard,
      secureToken: payload.secureToken,
      year: payload.year,
    })
    .withCredentials();

  return res.body;
};

export const chargeExistingCreditCard = async (
  payload: ChargeExistingCreditCardPayload,
): Promise<Order> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/payment-v2/credit-card/following-charge/`)
    .send({
      order: `${config.API_URL_GOBLIN}/orders/${payload.orderKey}/`,
      initialToken: payload.initialToken,
      installmentBank: payload.installmentBank,
      installmentTerm: payload.installmentTerm,
      secureToken: payload.secureToken,
    })
    .withCredentials();

  return res.body;
};

export const rechargeEWallet = async (
  payload: RechargeEWalletPayload,
): Promise<Order> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/payment-v2/e-wallet/recharge/`)
    .send({
      ...payload,
      callbackUrl: `https://dekoruma.com/orders/${payload.orderKey}`,
    })
    .withCredentials();

  return res.body;
};

export const chargeInternetBanking = async (
  payload: ChargeInternetBankingPayload,
): Promise<Order> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/payment-v2/internet-banking/charge/`)
    .send(payload)
    .withCredentials();

  return res.body;
};

export const chargeInstallmentWithoutCard = async (
  payload: ChargeInstallmentWithoutCardPayload,
): Promise<Order> => {
  const res = await superagent
    .post(
      `${config.API_URL_GOBLIN}/payment-v2/installment-without-card/charge/`,
    )
    .send({
      ...payload,
      callbackUrl: `https://dekoruma.com/orders/${payload.orderKey}`,
    })
    .withCredentials();

  return res.body;
};

export const chargeOfflinePayment = async (
  payload: ChargeOfflinePaymentPayload,
): Promise<ChargeOfflinePaymentResponse> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/orders/receive-offline-payment/`)
    .send({
      order: `${config.API_URL_GOBLIN}/orders/${payload.orderKey}/`,
      reference: payload.reference,
      amount: payload.amount,
    })
    .withCredentials();
  return res.body;
};

export const loadOrderInsuranceInfo = async (
  orderKey: string,
): Promise<OrderInsuranceInfo> => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/orders-v2/${orderKey}/insurance/`)
    .withCredentials();

  return res.body;
};
