import keyMirror from 'keymirror';

import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';

export const AT = keyMirror({
  LOAD_PHASES: null,
  LOAD_PHASES_SUCCESS: null,
  LOAD_PHASES_FAIL: null,

  LOAD_PROJECT_PHASE: null,
  LOAD_PROJECT_PHASE_SUCCESS: null,
  LOAD_PROJECT_PHASE_FAIL: null,

  UPDATE_PROJECT_PHASE: null,
  UPDATE_PROJECT_PHASE_SUCCESS: null,
  UPDATE_PROJECT_PHASE_FAIL: null,
});

export const initialState = {
  asyncState: Object.assign({}, initialAsyncState),
  phases: [],
};

export default function phaseReducer(immutableState = initialState, action) {
  const state = Object.assign({}, immutableState);

  switch (action.type) {
    case AT.LOAD_PHASES_SUCCESS:
      state.phases = action.result;
      break;
  }

  state.asyncState = asyncStateReducer(state.asyncState, action, '_PHASES_');
  return state;
}
