import config from 'config';

declare const pintrk: any;

const pinterestTagWrapper = {
  logEvent: (
    eventType: string,
    eventName: string,
    customData: any,
    eventID: string,
  ) => {
    if (!!pintrk && config.isProduction) {
      const data = {
        ...customData,
        event_id: eventID,
      };
      pintrk(eventType, eventName, data);
    }
  },
};

export default pinterestTagWrapper;
