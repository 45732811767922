import React, { memo, useMemo } from 'react';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

const OfflineStoreLocationListPageFooter: React.FC = memo(() => {
  // @note(dika) 4 Nov 2024: Hardcoded as of now
  // we are not using article posts as footer anymore
  // instead we use template content
  const footerHtml = useMemo(() => {
    return {
      __html: `
      Ketika sudah berhasil membeli rumah idaman, tentu kita perlu mengisinya dengan ragam furnitur agar rumah bisa digunakan dengan nyaman. Urusan belanja furniture memang sebaiknya langsung dilakukan di toko furniture supaya bisa melihat langsung barang yang akan dibeli sekaligus mencobanya.
      Dekoruma Experience Center hadir sebagai toko furniture terlengkap yang tersebar di kota-kota besar di Indonesia. Kelengkapan furniture yang ada di Dekoruma Experience Center akan membantumu menghemat waktu untuk mendapatkan banyak furniture sekaligus.
      <h2>Toko Furniture dengan 29 Showroom</h2>
      Berawal dari kesuksesan e-commerce, Dekoruma pun membuka toko furniture offline sekaligus showroom pertamanya yakni Dekoruma Experience Center Radio Dalam (Jakarta) pada 20 April 2019. Meski sempat terhalang pandemi, nyatanya antusiasme publik begitu tinggi sehingga Dekoruma memutuskan untuk memperbanyak cabang di kota lainnya.
      Hingga saat ini, tercatat sudah ada 29 Dekoruma Experience Center dan masih akan terus bertambah agar masyarakat di Indonesia bisa mendapatkan furniture murah berkualitas. Berikut detail lokasi toko furniture Dekoruma Experience Center yang tersebar di Indonesia:
      <h3>Dekoruma Experience Center Jakarta</h3>
      Arteri: Jalan Sultan Iskandar Muda nomor 76B, Kebayoran Lama, Jakarta Selatan
      Radio Dalam: Jalan KH. Ahmad Dahlan Kby. Nomor 59, Kramat Pela, Jakarta Selatan
      Kemang: Jalan Benda nomor 16A, Kemang, Jakarta Selatan
      Pantai Indah Kapuk: Ruko Crown Golf, Blok D nomor 25-27, Jalan Marina Indah Raya, Pantai Indah Kapuk, Jakarta Utara
      Kelapa Gading: Jalan Boulevard Raya Blok WD2 nomor 1, Kelapa Gading, Jakarta Utara
      <h3>Dekoruma Experience Center Bogor</h3>
      Bogor: Jl. Raya Pajajaran No. 12 Sukasari, Bogor Timur, Kota Bogor, Jawa Barat
      <h3>Dekoruma Experience Center Depok</h3>
      Margonda Depok: Jalan Margonda Raya nomor 229, Depok, Jawa Barat
      The Park Sawangan Mall: Jalan Raya Parung - Ciputat nomor 1, Depok, Jawa Barat
      <h3>Dekoruma Experience Center Tangerang</h3>
      Alam Sutera: Ruko De Mansion, Jalan Jalur Sutera Timur nomor 5, Tangerang Selatan, Banten
      Bintaro Xchange Mall: Boulevard Bintaro Jaya, Sektor VII, Tangerang Selatan, Banten
      <h3>Dekoruma Experience Center Bekasi</h3>
      Summarecon Bekasi: Jalan Boulevard Ahmad Yani, Ruko Graha Boulevard Blok A nomor 05-06, Summarecon, Bekasi Utara
      Grand Galaxy Bekasi: Ruko RGJ nomor 570-572, Jalan Pulo Sirih Utama, Bekasi, Jawa Barat
      <h3>Dekoruma Experience Center Bandung</h3>
      Dago : Jalan Ir. H. Juanda nomor 150, Bandung, Jawa Barat
      Paskal : Jalan Pasir Kaliki nomor 166, Bandung, Jawa Barat
      <h3>Dekoruma Experience Center Surabaya</h3>
      HR Muhammad: Jalan Mayjen HR Muhammad nomor 96, Surabaya, Jawa Timur
      Manyar: Jalan Manyar Kertoarjo nomor 35, Surabaya, Jawa Timur
      <h3>Dekoruma Experience Center Yogyakarta</h3>
      Yogyakarta: Jl. Urip Sumoharjo No. 139A, Klitren, Gondokusuman, Kota Yogyakarta
      <h3>Dekoruma Experience Center Medan</h3>
      Medan Gatot Subroto: Jalan Jendral Gatot Subroto nomor 188A, Kota Medan
      Medan Cemara Asri: Jalan Cemara Asri Boulevard Raya nomor 28 A, B, C, Kota Medan
      Medan Ring Road: Jalan Ring Road Komplek OCBC nomor B49, Kota Medan
      <h3>Dekoruma Experience Center Semarang</h3>
      Ahmad Yani: Jalan Ahmad Yani nomor 136C, Karangkidul, Kota Semarang
      <h3>Dekoruma Experience Center Malang</h3>
      Mojolangu: Jalan Borobudur nomor 21, Mojolangu, Lowokwaru, Malang, Jawa Timur
      <h3>Dekoruma Experience Center Solo</h3>
      The Park Mall Solo: Lantai LG 0020, Jalan Ir. Soekarno, Dusun II, Madegondo, Grogol, Sukoharjo, Kota Solo
      <h3>Dekoruma Experience Center Pekanbaru</h3>
      Wonorejo: Jalan Jenderal Sudirman nomor 121E, Wonorejo, Kota Pekanbaru, Riau
      <h3>Dekoruma Experience Center Bali</h3>
      Denpasar: Jalan Teuku Umar nomor 12G, Deuh Puri Klod, Denpasar, Bali
      <h3>Dekoruma Experience Center Cirebon</h3>
      Ruko Kagum City: Blok A nomor 07-09, Jalan Tuparev nomor 168, Cirebon
      <h3>Dekoruma Experience Center Palembang</h3>
      Palembang: Ruko Martabak Har, Simpang Sekip No 1078, Jl. Jend Sudirman, Sungai Pangeran Ilir Timur I, Palembang
      <h3>Dekoruma Experience Center Makassar</h3>
      Makassar Pettarani: Komplek Ruko Johnny Aliman, Blok A1-A3, Jl. A.P Pettarani, Paccerakkang Rappocini, Makassar
      Makassar Perintis: Jl. Perintis Kemerdekaan Kilometer 14, Kav Nomor 6,7,8, Paccerakkang Biring Kanaya, Makassar
      <h2>Toko Furniture Terlengkap</h2>
      Saat kami bilang Dekoruma Experience Center merupakan toko furniture terlengkap, sebenarnya kami tidak hanya jual furniture. Tapi juga aneka aksesoris dan peralatan rumah tangga yang dibutuhkan oleh setiap pemilik hunian.
      <h3>Furniture</h3>
      Untuk kategori furniture, Dekoruma menyediakan ragam kebutuhan di setiap ruang. Misalnya saja sofa, sofabed, meja kopi, rak, lemari, dan meja TV untuk ruang tamu ataupun ruang keluarga. Ada pula ranjang, lemari pakaian, meja belajar, dan nakas untuk kamar tidur. Tidak ketinggalan furniture untuk ruang makan dan dapur seperti meja makan, kursi, rak, dan kabinet dapur.
      <h3>Aneka Aksesoris</h3>
      Kalau furniture sudah tersedia, saatnya beralih ke aksesoris pendukung agar interior rumah semakin sempurna. Beberapa ragam aksesoris yang tersedia di Dekoruma Experience Center adalah cermin, aneka tekstil, lampu, perlengkapan kamar tidur, karpet, hingga tanaman hias dan lukisan.
      <h3>Peralatan Dapur Lengkap</h3>
      Apalah artinya dapur cantik tanpa peralatan dapur lengkap? Penuhi kebutuhanmu untuk kegiatan masak di Dekoruma Experience Center yang menjual peralatan dapur lengkap. Mulai dari wajan, panci, kompor, talenan, pisau, kotak makan, piring, mangkok, sendok garpu, gelas, hingga nampan.
      <h3>Pilihan Elektronik di Dekoruma Experience Center</h3>
      Keseharian masyarakat sudah melekat dengan peralatan rumah tangga elektronik atau home appliances yang memudahkan banyak pekerjaan. Beberapa peralatan rumah tangga elektronik yang tersedia di Dekoruma adalah blender, oven, mixer, AC, kulkas, mesin cuci, kompor, serta vacuum cleaner.
      <h3>Cobain Langsung Furniture yang Diinginkan</h3>
      Toko furniture Dekoruma Experience Center juga berperan sebagai showroom dari koleksi furniture. Para pengunjung #BebasCobain semua furniture dan aksesoris rumah impian yang tersedia. Dengan demikian para pengunjung dapat memahami perbedaan masing-masing material dan model furniture.
      Karena #BebasCobain pula, mereka mampu merasakan sensasi kenyamanan dan kepraktisan dari sebuah furniture yang akan dibawa pulang. Pemilihan pun terasa lebih pas dan personal, tidak perlu takut salah pilih lagi karena barangnya ada di depan mata.
      <h3>Toko Funiture dengan Inspirasi Ruang Japandi</h3>
      Jika toko mebel pada umumnya hanya menata furniture sesuai kategori jenis, Dekoruma Experience Center justru menata produk berdasarkan kategori ruangan yang didesain dengan konsep Japandi. Di antaranya ruang keluarga, dekorasi dapur Japandi, ruang makan, kamar tidur, home office, dan kamar mandi ala Japandi.
      Keunggulan dari konsep Japandi sendiri adalah ruang mungil tetap terasa nyaman untuk beraktivitas lantaran furniturnya multifungsi dan space–saving. Penataan showroom toko mebel yang beda dari biasanya ini juga diharapkan mampu menjadi inspirasi interior bagi para pengunjung yang masih kesulitan memilih furniture.
      Tidak perlu pusing, mereka bisa meniru layout desain dan gunakan furniture yang sama seperti pada showroom tersebut. Memperkirakan budget pun turut dipermudah karena setiap inspirasi ruang Japandi sudah disertai dengan rincian biaya.
      <h3>Konsultasi dengan Ahlinya</h3>
      Apabila masih merasa kesulitan dalam menata interior rumah idaman, tidak perlu khawatir karena di Dekoruma Experience Center juga terdapat area konsultasi desain interior.
      Para pengunjung dapat berkonsultasi langsung dengan desainer profesional. Selagi konsultasi, para pengunjung juga akan dibawa ke area sampel material dinding dan lantai untuk melihat langsung dan menyentuh permukaannya.
      <h3>Ragam Promo</h3>
      Dekoruma Experience Center menyediakan berbagai promo menarik untuk para pembeli seperti gratis pengiriman, cashback, dan juga potongan harga. Promo yang berlangsung di masing-masing toko furniture Dekoruma Experience Center bisa berbeda-beda. Maka pastikan untuk mengikuti media sosial kami atau menanyakannya langsung kepada staff yang bertugas agar tidak ketinggalan infonya.
      Yuk, kunjungi toko furniture Dekoruma Experience Center terdekat di kotamu! Ajak seluruh anggota keluarga biar sama-sama pilih furniture murah nan berkualitas yang terbaik untuk rumah idaman.
      `,
    };
  }, []);

  return (
    <View style={s.container}>
      <Text h2 theme="b4-neutral500" style={cStyles.tac}>
        Dekoruma Experience Center, Toko Furniture Terlengkap untuk Rumah Idaman
      </Text>
      <Text theme="b5-neutral100">
        <div dangerouslySetInnerHTML={footerHtml} />
      </Text>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pa16,
  },
});

export default OfflineStoreLocationListPageFooter;
