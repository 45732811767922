import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';

import SimpleFilterItem from '../SimpleFilterItem';
import SimpleFilterList from '../SimpleFilterList';

export default class ListComponent extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    listComponent: PropTypes.func,
    itemComponent: PropTypes.func,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        count: PropTypes.number,
        selected: PropTypes.bool,
      }),
    ).isRequired,
    multiple: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelStyle: stylePropTypes,
    transformDataItem: PropTypes.func,
    listProps: PropTypes.shape(),
  };

  static defaultProps = {
    onChange: () => {},
    listComponent: SimpleFilterList,
    itemComponent: SimpleFilterItem,
    multiple: true,
    allowEmpty: true,
    label: undefined,
    labelStyle: {},
    transformDataItem: undefined,
    listProps: {},
  };

  /**
   * receives a single facet value or a whole selected facets
   */
  handleChange = (value) => {
    if (isArray(value)) {
      this.props.onChange(value);
      return;
    }

    const { multiple, allowEmpty } = this.props;
    const selected = this.props.data
      .filter((item) => item.selected)
      .map((item) => item.value);
    const inSelected = selected.findIndex((item) => item === value) !== -1;

    let returnData = null;
    if (multiple) {
      if (inSelected) {
        if (allowEmpty || selected.length > 1) {
          returnData = selected.filter((item) => item !== value);
        } else {
          returnData = selected;
        }
      } else {
        returnData = [...selected, value];
      }
    } else {
      returnData = inSelected && allowEmpty ? [] : [value];
    }

    this.props.onChange(returnData);
  };

  render() {
    const {
      listComponent: List,
      itemComponent,
      data: dataFromProps,
      name,
      label,
      labelStyle,
      multiple,
      allowEmpty,
      transformDataItem,
      listProps,
    } = this.props;

    let labelText = null;
    if (label !== undefined) {
      labelText = label;
    } else {
      labelText = name;
    }

    let data = dataFromProps;

    if (transformDataItem) {
      data = data.map((item) => transformDataItem(item));
    }

    return (
      <List
        label={labelText}
        data={data}
        onChange={this.handleChange}
        itemComponent={itemComponent}
        labelStyle={labelStyle}
        multiple={multiple}
        allowEmpty={allowEmpty}
        {...listProps}
      />
    );
  }
}
