import { schema } from 'normalizr';

export const customScoreConfigurationSchema = new schema.Entity(
  'customScoreConfigurationsByName',
  {},
  { idAttribute: 'name' },
);
export const customScoreConfigurationCollectionSchema = [
  customScoreConfigurationSchema,
];
