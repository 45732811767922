import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import {
  K_ROLE_DESIGNER_NAME,
  K_ROLE_WORKSHOP_NAME,
} from 'constants/designBuildConstants';
import { K_DEFAULT_ROUTING_ZONE_CODE } from 'constants/productConstants';
import {
  K_BETA_TESTER_ROLE,
  K_BUSINESS_ROLE,
  K_DEKORUMA_EMPLOYEE_ROLE,
  K_DEKORUMA_USER_ORDER_ON_BEHALF_USER_GROUPS,
  K_MARKETING_ROLE,
  K_OFFICIAL_STORE_REORDER_ROLE,
  K_ONLINE_STORE_ADMIN_ROLE,
  K_SHOP_ASSISTANT_ROLE,
} from 'constants/userConstants';

/** Optimization */
const emptyObject = {};

export const getAuth = (state) => state.auth;

export const getAuthUser = (state) => {
  const auth = getAuth(state);
  return auth.user || emptyObject;
};

export const getAuthMarketplaceProfile = createSelector(
  getAuth,
  (auth) => auth.marketplaceProfile || emptyObject,
);

export const getIsUserAbleToViewRegionalHubStockRecords = createSelector(
  getAuthMarketplaceProfile,
  (marketplaceProfile) =>
    marketplaceProfile?.currentStoreLocation?.routingZoneCode !==
    K_DEFAULT_ROUTING_ZONE_CODE,
);

export const getUserProfile = (state) => getAuth(state).profile || emptyObject;
export const getUserGroups = (state) => get(state, 'auth.user.groups', []);
export const getUserEmail = (state) => getAuthUser(state)?.email ?? '';

export const isSigningUp = (state) => getAuth(state).signingUp;
export const getIsLoggedInFromSignUp = (state) =>
  getAuth(state).isLoggedInFromSignUp;
export const isForgettingPassword = (state) =>
  getAuth(state).forgettingPassword;

export const isLoggedIn = (state) => {
  const user = getAuthUser(state);
  return !isEmpty(user);
};
export const getIsUserOnlineStoreAdmin = (state) => {
  const groups = getUserGroups(state);
  return groups.includes(K_ONLINE_STORE_ADMIN_ROLE);
};

export const getIsUserBusinessUser = (state) =>
  getUserGroups(state).includes(K_BUSINESS_ROLE);

export const getIsBetaTesterUser = (state) =>
  getUserGroups(state).includes(K_BETA_TESTER_ROLE);

export const getIsDekorumaEmployee = (state) =>
  getUserGroups(state).includes(K_DEKORUMA_EMPLOYEE_ROLE);

export const getIsUserMarketing = (state) =>
  getUserGroups(state).includes(K_MARKETING_ROLE);

export const getIsUserOfficialStoreReorder = (state) =>
  getUserGroups(state).includes(K_OFFICIAL_STORE_REORDER_ROLE);

export const getIsUserOrderOnBehalf = (state) =>
  getUserGroups(state).some((group) =>
    K_DEKORUMA_USER_ORDER_ON_BEHALF_USER_GROUPS.includes(group),
  );

export const getIsShopAssistant = createSelector(getUserGroups, (groups) =>
  groups.includes(K_SHOP_ASSISTANT_ROLE),
);

export const getStoreAddress = createSelector(
  getAuthMarketplaceProfile,
  (marketplaceProfile) =>
    marketplaceProfile.currentStoreLocation || emptyObject,
);

export const getStoreCode = (state) => {
  const store = getStoreAddress(state);
  return get(store, 'code', '');
};

export const isAuthLoaded = (state) => state.auth.loaded;

export const isAuthLoading = (state) =>
  !!__SERVER__ || (state.auth && state.auth.loading);

export const getLoadAuthError = (state) => state.auth.error;

export const getUserRoles = (state) => getAuthUser(state).groups;

export const isCurrentUserMemberOf = (state, roleName) => {
  const userRoles = getUserRoles(state);
  return !isEmpty(userRoles) && !!userRoles.find((role) => role === roleName);
};

export const isCurrentUserDesigner = (state) =>
  isCurrentUserMemberOf(state, K_ROLE_DESIGNER_NAME);

export const isCurrentUserWorkshop = (state) =>
  isCurrentUserMemberOf(state, K_ROLE_WORKSHOP_NAME);

export const getShouldShowLogoutModal = (state) =>
  getAuth(state).shouldShowLogoutModal;
