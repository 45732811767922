import React, {
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useMemo,
  useState,
} from 'react';

export const DrawerLockContext = createContext<{
  shouldLockDrawer: boolean;
  setShouldLockDrawer: Dispatch<SetStateAction<boolean>>;
}>({
  shouldLockDrawer: false,
  setShouldLockDrawer: () => new Promise((resolve) => resolve(null)),
});

export const DrawerLockContextProvider: React.FC = memo(({ children }) => {
  const [shouldLockDrawer, setShouldLockDrawer] = useState(false);

  const contextValue = useMemo(
    () => ({
      shouldLockDrawer,
      setShouldLockDrawer,
    }),
    [shouldLockDrawer, setShouldLockDrawer],
  );

  return (
    <DrawerLockContext.Provider value={contextValue}>
      {children}
    </DrawerLockContext.Provider>
  );
});
