import { ShippingAreaFromGoblin } from 'app-libs/redux_modules/flow_modules/area/types';

export const K_DEFAULT_AREA: ShippingAreaFromGoblin = {
  id: 1277,
  district: 'Kebayoran Baru',
  city: 'Jakarta Selatan',
  province: 'DKI Jakarta',
  island: 'Jawa',
  zoneCode: 'z1',
  routingZoneCode: 'z1',
};
