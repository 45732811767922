import keyMirror from 'keymirror';
import isEmpty from 'lodash/isEmpty';

import config from 'config';

import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';

export const K_FACEBOOK_PUBLIC_PROFILE_PERMISSION = 'public_profile';
export const K_FACEBOOK_EMAIL_PERMISSION = 'email';
export const K_FACEBOOK_PERMISSIONS = [
  K_FACEBOOK_PUBLIC_PROFILE_PERMISSION,
  K_FACEBOOK_EMAIL_PERMISSION,
];

export const AT = keyMirror({
  LOAD_AUTH: null,
  LOAD_AUTH_SUCCESS: null,
  LOAD_AUTH_FAIL: null,

  LOAD_MARKETPLACE_PROFILE: null,
  LOAD_MARKETPLACE_PROFILE_SUCCESS: null,
  LOAD_MARKETPLACE_PROFILE_FAIL: null,

  LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION: null,
  LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION_SUCCESS: null,
  LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION_FAIL: null,

  LOAD_PROFILE: null,
  LOAD_PROFILE_SUCCESS: null,
  LOAD_PROFILE_FAIL: null,

  VALIDATE_PROJECT_REGISTRATION: null,
  VALIDATE_PROJECT_REGISTRATION_SUCCESS: null,
  VALIDATE_PROJECT_REGISTRATION_FAIL: null,

  UPDATE_USER: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_FAIL: null,

  LOAD_USER_PROFILE: null,
  LOAD_USER_PROFILE_SUCCESS: null,
  LOAD_USER_PROFILE_FAIL: null,

  UPDATE_USER_PROFILE: null,
  UPDATE_USER_PROFILE_SUCCESS: null,
  UPDATE_USER_PROFILE_FAIL: null,

  SIGNUP: null,
  SIGNUP_SUCCESS: null,
  SIGNUP_FAIL: null,
  RESET_SIGNING_UP: null,

  SIGNUP_BUSINESS_USER: null,
  SIGNUP_BUSINESS_USER_SUCCESS: null,
  SIGNUP_BUSINESS_USER_FAIL: null,

  SIGNUP_WITHOUT_PASSWORD: null,
  SIGNUP_WITHOUT_PASSWORD_SUCCESS: null,
  SIGNUP_WITHOUT_PASSWORD_FAIL: null,

  LOGIN: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAIL: null,
  RESET_LOGGING_IN: null,

  LOGIN_SOCIAL: null,
  LOGIN_SOCIAL_SUCCESS: null,
  LOGIN_SOCIAL_FAIL: null,
  RESET_LOG_IN_SOCIAL: null,

  LOGOUT: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_FAIL: null,
  RESET_LOGGING_OUT: null,

  LOAD_RESET_PASSWORD_TOKEN_STATUS: null,
  LOAD_RESET_PASSWORD_TOKEN_STATUS_SUCCESS: null,
  LOAD_RESET_PASSWORD_TOKEN_STATUS_FAIL: null,

  FORGOT_PASSWORD: null,
  FORGOT_PASSWORD_SUCCESS: null,
  FORGOT_PASSWORD_FAIL: null,
  RESET_FORGOT_PASSWORD: null,

  PASSWORD_RESET: null,
  PASSWORD_RESET_SUCCESS: null,
  PASSWORD_RESET_FAIL: null,

  UPDATE_PASSWORD: null,
  UPDATE_PASSWORD_SUCCESS: null,
  UPDATE_PASSWORD_FAIL: null,

  UPDATE_NAME: null,
  UPDATE_NAME_SUCCESS: null,
  UPDATE_NAME_FAIL: null,

  UPDATE_PROFILE: null,
  UPDATE_PROFILE_SUCCESS: null,
  UPDATE_PROFILE_FAIL: null,

  LOAD_PARTNERS: null,
  LOAD_PARTNERS_SUCCESS: null,
  LOAD_PARTNERS_FAIL: null,
  SELECT_PARTNER: null,

  GET_PARTNER_API_KEY: null,
  GET_PARTNER_API_KEY_SUCCESS: null,
  GET_PARTNER_API_KEY_FAIL: null,

  LOAD_CONTRACTORS: null,
  LOAD_CONTRACTORS_SUCCESS: null,
  LOAD_CONTRACTORS_FAIL: null,
  SELECT_CONTRACTOR: null,

  LOAD_STORE_LOCATIONS: null,
  LOAD_STORE_LOCATIONS_SUCCESS: null,
  LOAD_STORE_LOCATIONS_FAIL: null,

  CHECK_IN_STORE: null,
  CHECK_IN_STORE_SUCCESS: null,
  CHECK_IN_STORE_FAIL: null,

  // LOAD_FIREBASE_USER_KEY: null,
  // LOAD_FIREBASE_USER_KEY_SUCCESS: null,
  // LOAD_FIREBASE_USER_KEY_FAIL: null,

  REQUEST_OTP: null,
  REQUEST_OTP_SUCCESS: null,
  REQUEST_OTP_FAIL: null,

  CHECK_PHONE: null,
  CHECK_PHONE_SUCCESS: null,
  CHECK_PHONE_FAIL: null,

  GET_SIGNUP_AVAILABILITY: null,
  GET_SIGNUP_AVAILABILITY_SUCCESS: null,
  GET_SIGNUP_AVAILABILITY_FAIL: null,

  POST_SIGNUP: null,
  POST_SIGNUP_SUCCESS: null,
  POST_SIGNUP_FAIL: null,

  VALIDATE_USER: null,
  VALIDATE_USER_SUCCESS: null,
  VALIDATE_USER_FAIL: null,

  VERIFY_PHONE_NUMBER_OTP: null,
  VERIFY_PHONE_NUMBER_OTP_SUCCESS: null,
  VERIFY_PHONE_NUMBER_OTP_FAIL: null,

  CLOSE_ANNOUNCEMENT_BANNER: null,

  REQUEST_USER_DELETION: null,
  REQUEST_USER_DELETION_SUCCESS: null,
  REQUEST_USER_DELETION_FAIL: null,

  SHOW_LOGOUT_MODAL: null,
  HIDE_LOGOUT_MODAL: null,
});

const initialState = {
  loaded: false,
  loading: false,
  error: null, // loading err

  loggingIn: false,
  loginError: null,

  loggingInSocial: false,
  loginSocialError: null,

  loggingOut: false,
  logoutError: null,

  signingUp: false,
  signupError: null,

  updatingPassword: false,
  updatePasswordError: false,

  loadingProfile: false,
  loadingProfileError: null,

  updatingName: false,
  udpateNameError: null,

  updatingProfile: false,
  updateProfileError: null,

  forgettingPassword: false,
  forgotPasswordError: null,

  signingUpBusinessUser: false,
  signupBusinessUserError: null,

  user: null, // when `logged in`, this user is not null
  userProfile: null,
  marketplaceProfile: null,
  profile: null,
  partners: null,
  selectedPartner: {
    partnerId: null,
    partnerName: null,
    APIKey: null,
  },

  resettingPassword: false,
  resettingPasswordError: null,
  resettingPasswordSuccess: false,

  checkingResetPasswordTokenStatus: false,
  resetPasswordTokenError: null,
  checkResetPasswordTokenStatusSuccess: false,

  signUp: {
    result: {},
    asyncState: initialAsyncState,
  },

  signUpAvailabilityStatus: {
    result: {},
    asyncState: initialAsyncState,
  },

  showAnnouncementBanner: true,

  isLoggedInFromSignUp: false,

  shouldShowLogoutModal: false,
};

export default function reducer(mutableState = initialState, action = {}) {
  let state = mutableState;

  switch (action.type) {
    case AT.LOAD_AUTH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AT.LOAD_AUTH_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
      };
      // api/me return empty object when user is not logged in
      state.user = isEmpty(action.result) ? null : action.result;
      break;
    case AT.LOAD_AUTH_FAIL:
      state = {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
      break;
    case AT.UPDATE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AT.UPDATE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
      };
      state.user = action.result;
      break;
    case AT.UPDATE_USER_FAIL:
      state = {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
      break;
    case AT.LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION:
    case AT.LOAD_MARKETPLACE_PROFILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AT.LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        marketplaceProfile: {
          ...state.marketplaceProfile,
          currentStoreLocation: action.result,
        },
      };
      break;
    case AT.LOAD_MARKETPLACE_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
      };
      state.marketplaceProfile = isEmpty(action.result) ? null : action.result;
      break;
    case AT.LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION_FAIL:
    case AT.LOAD_MARKETPLACE_PROFILE_FAIL:
      state = {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
      break;
    case AT.LOAD_PROFILE:
      state = {
        ...state,
        loadingProfile: true,
        profile: null,
      };
      break;
    case AT.LOAD_PROFILE_SUCCESS:
      state = {
        ...state,
        loadingProfile: false,
        profile: {
          ...action.result,
          gender:
            ['male', 'female'].indexOf(action.result.gender) !== -1
              ? action.result.gender
              : 'other',
        },
      };
      break;
    case AT.LOAD_PROFILE_FAIL:
      state = {
        ...state,
        loadingProfile: false,
        loadingProfileError: action.error,
      };
      break;
    case AT.LOAD_USER_PROFILE_SUCCESS:
      state.userProfile = isEmpty(action.result) ? null : action.result;
      break;
    case AT.UPDATE_USER_PROFILE_SUCCESS:
      state.userProfile = isEmpty(action.result) ? null : action.result;
      break;
    case AT.LOGIN:
      state = {
        ...state,
        loggingIn: true,
      };
      break;
    case AT.LOGIN_SUCCESS:
      state = {
        ...state,
        loggingIn: false,
        user: action.result,
      };
      break;
    case AT.LOGIN_FAIL:
      state = {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error,
      };
      break;
    case AT.LOGIN_SOCIAL:
      state = {
        ...state,
        loggingInSocial: true,
      };
      break;
    case AT.LOGIN_SOCIAL_SUCCESS:
      state = {
        ...state,
        loggingInSocial: false,
        user: action.result,
      };
      break;
    case AT.LOGIN_SOCIAL_FAIL:
      state = {
        ...state,
        loggingInSocial: false,
        loginSocialError: action.error,
      };
      break;
    case AT.RESET_LOGGING_IN:
      state = {
        ...state,
        loggingIn: false,
      };
      break;
    case AT.RESET_LOG_IN_SOCIAL:
      state = {
        ...state,
        loggingInSocial: false,
        loginSocialError: null,
      };
      break;
    case AT.FORGOT_PASSWORD:
      state = {
        ...state,
        forgettingPassword: true,
        forgotPasswordError: null,
      };
      break;
    case AT.FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgettingPassword: false,
        forgotPasswordSuccess: true,
      };
      break;
    case AT.FORGOT_PASSWORD_FAIL:
      state = {
        ...state,
        forgettingPassword: false,
        forgotPasswordError: action.error,
        forgotPasswordSuccess: false,
      };
      break;
    case AT.RESET_FORGOT_PASSWORD:
      state = {
        ...state,
        forgettingPassword: false,
        forgotPasswordSuccess: false,
      };
      break;
    case AT.PASSWORD_RESET:
      state = {
        ...state,
        resettingPassword: true,
        resettingPasswordError: null,
      };
      break;
    case AT.PASSWORD_RESET_SUCCESS:
      state = {
        ...state,
        resettingPassword: false,
        resettingPasswordSuccess: true,
      };
      break;
    case AT.PASSWORD_RESET_FAIL:
      state = {
        ...state,
        resettingPassword: false,
        resettingPasswordError: action.error,
        resettingPasswordSuccess: false,
      };
      break;
    case AT.SIGNUP:
      state = {
        ...state,
        signingUp: true,
      };
      break;
    case AT.SIGNUP_SUCCESS:
      state = {
        ...state,
        signingUp: false,
        isLoggedInFromSignUp: true,
        user: action.result,
      };
      break;
    case AT.SIGNUP_FAIL:
      state = {
        ...state,
        signingUp: false,
        user: null,
        signupError: action.error,
      };
      break;
    case AT.RESET_SIGNING_UP:
      state = {
        ...state,
        signingUp: false,
      };
      break;
    case AT.SIGNUP_BUSINESS_USER:
      state = {
        ...state,
        signingUpBusinessUser: true,
      };
      break;
    case AT.SIGNUP_BUSINESS_USER_SUCCESS:
      state = {
        ...state,
        signingUpBusinessUser: false,
        isLoggedInFromSignUp: true,
        user: action.result,
      };
      break;
    case AT.SIGNUP_BUSINESS_USER_FAIL:
      state = {
        ...state,
        signingUpBusinessUser: false,
        user: null,
        signupBusinessUserError: action.error,
      };
      break;
    case AT.LOGOUT:
      state = {
        ...state,
        loggingOut: true,
      };
      break;
    case AT.LOGOUT_SUCCESS:
      state = {
        ...state,
        loggingOut: false,
        user: null,
        partners: null,
        selectedPartner: {
          partnerId: null,
          partnerName: null,
          APIKey: null,
        },
        marketplaceProfile: null,
        isLoggedInFromSignUp: false,
      };
      break;
    case AT.LOGOUT_FAIL:
      state = {
        ...state,
        loggingOut: false,
        logoutError: action.error,
      };
      break;
    case AT.RESET_LOGGING_OUT:
      state = {
        ...state,
        loggingOut: false,
      };
      break;
    case AT.UPDATE_PASSWORD:
      state = {
        ...state,
        updatingPassword: true,
      };
      break;
    case AT.UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        updatingPassword: false,
      };
      break;
    case AT.UPDATE_PASSWORD_FAIL:
      state = {
        ...state,
        updatingPassword: false,
        updatePasswordError: action.error,
      };
      break;
    case AT.UPDATE_NAME:
      state = {
        ...state,
        updatingName: true,
      };
      break;
    case AT.UPDATE_NAME_SUCCESS:
      state = {
        ...state,
        updatingName: false,
        user: action.result,
      };
      break;
    case AT.UPDATE_NAME_FAIL:
      state = {
        ...state,
        updatingName: false,
        user: null,
        udpateNameError: action.error,
      };
      break;
    case AT.UPDATE_PROFILE:
      state = {
        ...state,
        updatingProfile: true,
      };
      break;
    case AT.UPDATE_PROFILE_SUCCESS:
      state = {
        ...state,
        updatingProfile: false,
        profile: action.result,
      };
      break;
    case AT.UPDATE_PROFILE_FAIL:
      state = {
        ...state,
        updatingProfile: false,
        updateProfileError: action.error,
      };
      break;
    case AT.LOAD_PARTNERS_SUCCESS: {
      const { partners } = action.result;
      state = {
        ...state,
        partners,
        selectedPartner: {
          APIKey: null,
          partnerId: partners.length ? partners[0].partnerId : null,
          partnerName: partners.length ? partners[0].partnerName : null,
        },
      };
      break;
    }
    case AT.LOAD_PARTNERS_FAIL:
      break;
    case AT.SELECT_PARTNER: {
      const partnerId = action.payload;
      const selectedPartner = state.partners.find(
        (partner) => partner.partnerId === partnerId,
      );
      state = {
        ...state,
        selectedPartner: {
          APIKey: null,
          partnerId,
          partnerName: selectedPartner.partnerName,
        },
      };
      break;
    }
    /*
     * Contractors
     */

    case AT.GET_PARTNER_API_KEY:
      state = {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          partnerId: action.payload.partnerId,
        },
      };
      break;
    case AT.GET_PARTNER_API_KEY_SUCCESS:
      state = {
        ...state,
        selectedPartner: {
          ...state.selectedPartner,
          APIKey: action.result.key,
        },
      };
      break;
    case AT.GET_PARTNER_API_KEY_FAIL:
      break;

    case AT.LOAD_CONTRACTORS:
      break;
    case AT.LOAD_CONTRACTORS_SUCCESS: {
      const { contractors } = action.result;
      state = {
        ...state,
        contractors,
        selectedContractor: {
          contractorId:
            contractors && contractors.length
              ? contractors[0].contractorId
              : null,
          contractorName:
            contractors && contractors.length
              ? contractors[0].contractorName
              : null,
        },
      };
      break;
    }
    case AT.LOAD_CONTRACTORS_FAIL:
      break;
    case AT.LOAD_RESET_PASSWORD_TOKEN_STATUS:
      state = {
        ...state,
        checkingResetPasswordTokenStatus: true,
        checkResetPasswordTokenStatusSuccess: false,
        resetPasswordTokenError: null,
      };
      break;
    case AT.LOAD_RESET_PASSWORD_TOKEN_STATUS_SUCCESS:
      state = {
        ...state,
        checkingResetPasswordTokenStatus: false,
        checkResetPasswordTokenStatusSuccess: true,
      };
      break;

    case AT.LOAD_RESET_PASSWORD_TOKEN_STATUS_FAIL:
      state = {
        ...state,
        checkingResetPasswordTokenStatus: false,
        checkResetPasswordTokenStatusSuccess: false,
        resetPasswordTokenError: action.error,
      };
      break;
    case AT.CHECK_IN_STORE_SUCCESS: {
      const offlineStoreLocation = action.result?.offlineStoreLocation || {};
      state = {
        ...state,
        marketplaceProfile: {
          ...state.marketplaceProfile,
          currentStoreLocation: {
            ...state.marketplaceProfile.currentStoreLocation,
            ...offlineStoreLocation,
          },
        },
      };
      break;
    }
    case AT.POST_SIGNUP:
    case AT.POST_SIGNUP_SUCCESS:
    case AT.POST_SIGNUP_FAIL:
      state = {
        ...state,
        signUp: signUpReducer(state.signUp, action),
      };
      break;
    case AT.GET_SIGNUP_AVAILABILITY:
    case AT.GET_SIGNUP_AVAILABILITY_SUCCESS:
    case AT.GET_SIGNUP_AVAILABILITY_FAIL:
      state = {
        ...state,
        signUpAvailabilityStatus: signUpAvailabilityStatusReducer(
          state.signUpAvailabilityStatus,
          action,
        ),
      };
      break;
    case AT.CLOSE_ANNOUNCEMENT_BANNER:
      state = {
        ...state,
        showAnnouncementBanner: false,
      };
      break;

    case AT.SHOW_LOGOUT_MODAL:
      state = {
        ...state,
        shouldShowLogoutModal: true,
      };
      break;
    case AT.HIDE_LOGOUT_MODAL:
      state = {
        ...state,
        shouldShowLogoutModal: false,
      };
      break;

    default:
      break;

    // case AT.LOAD_FIREBASE_USER_KEY_SUCCESS:
    //   state = {
    //     ...state,
    //     firebaseUserKey: isEmpty(action.result) ? null :  Object.keys(action.result)[0]
    //   };
    //   break;
  }
  return state;
}

/**
 * todo: change to `shouldLoad`, as there is a side-effect with __SERVER__
 */
export function isLoaded(globalState) {
  /**
   * we need to block server from loading, as we use cookies authentication for now
   */
  if (__SERVER__) {
    return true;
  }
  return globalState.auth && globalState.auth.loaded;
}

export function isLoading(globalState) {
  /**
   * we need to block server from loading, as we use cookies authentication for now
   */
  if (__SERVER__) {
    return true;
  }
  return globalState.auth && globalState.auth.loading;
}

export function signUpReducer(mutableState = initialState.signUp, action = {}) {
  let state = mutableState;
  const { type } = action;

  state = {
    ...state,
    asyncState: asyncStateReducer(state.asyncState, action, '_SIGNUP_'),
  };

  switch (type) {
    case AT.POST_SIGNUP_SUCCESS: {
      return {
        ...state,
        result: action.result,
      };
    }
    default:
      return state;
  }
}

export function signUpAvailabilityStatusReducer(
  mutableState = initialState.signUpAvailabilityStatus,
  action = {},
) {
  let state = mutableState;
  const { type } = action;

  state = {
    ...state,
    asyncState: asyncStateReducer(
      state.asyncState,
      action,
      '_SIGNUP_AVAILABILITY_',
    ),
  };

  switch (type) {
    case AT.GET_SIGNUP_AVAILABILITY_SUCCESS:
      return {
        ...state,
        result: action.result,
      };
    case AT.GET_SIGNUP_AVAILABILITY_FAIL: {
      const {
        error: { status },
      } = action;
      if (status === 400) {
        return {
          ...state,
          result: action.error,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function _load() {
  return {
    types: [AT.LOAD_AUTH, AT.LOAD_AUTH_SUCCESS, AT.LOAD_AUTH_FAIL],
    promise: (client) =>
      client.getUserData(`${config.API_URL_SURTUR_V2}/me/?format=json`),
    options: {
      transformer: (result) => result,
    },
  };
}

export function load(callback = (res) => res) {
  return (dispatch, getState) => {
    if (__SERVER__) {
      /**
       * Load auth in server should fail, since we haven't handle cache DNS per user yet.
       */
      return null;
    }
    return dispatch(dontShowPromptOnError(_load())).then((result, err) =>
      callback(result, err, getState().auth.user),
    );
  };
}

export function updateUser(data) {
  return {
    types: [AT.UPDATE_USER, AT.UPDATE_USER_SUCCESS, AT.UPDATE_USER_FAIL],
    promise: (client) =>
      client.post(`${config.API_URL_SURTUR_V2}/edit-profile/`, {
        data,
      }),
  };
}

export function validateEmailAndPhoneNumber(email, phoneNumber) {
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  return {
    types: [AT.VALIDATE_USER, AT.VALIDATE_USER_SUCCESS, AT.VALIDATE_USER_FAIL],
    promise: (client) =>
      client.get(
        `${config.API_URL_SURTUR}/users-availability/?email=${email}&phone_number=${encodedPhoneNumber}`,
      ),
  };
}

export function loadProfile() {
  return {
    types: [AT.LOAD_PROFILE, AT.LOAD_PROFILE_SUCCESS, AT.LOAD_PROFILE_FAIL],
    promise: (client) =>
      client.get(`${config.API_URL_GOBLIN}/me/profile/?format=json`),
  };
}

export function login(username, password) {
  return {
    types: [AT.LOGIN, AT.LOGIN_SUCCESS, AT.LOGIN_FAIL],
    promise: (client) =>
      client.login(`${config.API_URL_SURTUR_V2}/login/?format=json`, {
        data: {
          username,
          email: username,
          password,
        },
      }),
  };
}

export function loginSocial(accessToken, provider) {
  // eslint-disable-next-line camelcase
  const params = { accessToken };

  return {
    types: [AT.LOGIN_SOCIAL, AT.LOGIN_SOCIAL_SUCCESS, AT.LOGIN_SOCIAL_FAIL],
    promise: (client) =>
      client.getUserData(
        `${config.API_URL_SURTUR_V2}/login-social/${provider}/`,
        {
          params,
        },
      ),
  };
}

export function loginOTP(phoneNumber, otpCode) {
  return {
    types: [AT.LOGIN, AT.LOGIN_SUCCESS, AT.LOGIN_FAIL],
    promise: (client) =>
      client.login(`${config.API_URL_SURTUR_V2}/login-otp/?format=json`, {
        data: {
          phoneNumber,
          otpCode,
        },
      }),
  };
}

export function requestOTPToken(phoneNumber, platform) {
  return {
    types: [AT.REQUEST_OTP, AT.REQUEST_OTP_SUCCESS, AT.REQUEST_OTP_FAIL],
    promise: (client) =>
      client.post(`${config.API_URL_SURTUR}/login/otp/request/?format=json`, {
        data: {
          phoneNumber,
          platform,
        },
      }),
  };
}

export function checkPhoneNumber(phoneNumber) {
  return {
    types: [AT.CHECK_PHONE, AT.CHECK_PHONE_SUCCESS, AT.CHECK_PHONE_FAIL],
    promise: (client) =>
      client.get(`${config.API_URL_SURTUR}/login/otp/request/${phoneNumber}/`),
  };
}

export function getPhoneNumberRegisterAvailability(phoneNumber) {
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  return {
    types: [
      AT.GET_SIGNUP_AVAILABILITY,
      AT.GET_SIGNUP_AVAILABILITY_SUCCESS,
      AT.GET_SIGNUP_AVAILABILITY_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_SURTUR}/register/availability/?phone_number=${encodedPhoneNumber}`,
      ),
  };
}

export function getEmailRegisterAvailability(email) {
  return {
    types: [
      AT.GET_SIGNUP_AVAILABILITY,
      AT.GET_SIGNUP_AVAILABILITY_SUCCESS,
      AT.GET_SIGNUP_AVAILABILITY_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_SURTUR}/register/availability/?email=${email}`,
      ),
  };
}

export function requestVerificationOTPToken(
  phoneNumber,
  platform,
  captchaInput = '',
) {
  return {
    types: [AT.REQUEST_OTP, AT.REQUEST_OTP_SUCCESS, AT.REQUEST_OTP_FAIL],
    promise: (client) =>
      client.post(
        `${config.API_URL_SURTUR}/register/request-otp-phone-number/?format=json`,
        {
          data: {
            phoneNumber,
            platform,
            captchaInput,
          },
        },
      ),
  };
}

export function verifyOTPToken(phoneNumber, otpCode) {
  return {
    types: [
      AT.VERIFY_PHONE_NUMBER_OTP,
      AT.VERIFY_PHONE_NUMBER_OTP_SUCCESS,
      AT.VERIFY_PHONE_NUMBER_OTP_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_SURTUR}/register/verify-otp-phone-number/?format=json`,
        {
          data: {
            phoneNumber,
            otpCode,
          },
        },
      ),
  };
}

/**
 * @param  {Object} customer - KeyValue pair of
 * {firstName, lastName, email, password}
 */
export function signup(customer) {
  return {
    types: [AT.SIGNUP, AT.SIGNUP_SUCCESS, AT.SIGNUP_FAIL],
    promise: (client) =>
      client.post(`${config.API_URL_SURTUR_V2}/register/?format=json`, {
        data: customer,
      }),
  };
}

export function signupWithoutPassword(customer) {
  return {
    types: [AT.SIGNUP, AT.SIGNUP_SUCCESS, AT.SIGNUP_FAIL],
    promise: (client) =>
      client.post(
        `${config.API_URL_SURTUR_V2}/register-without-password/?format=json`,
        {
          data: customer,
        },
      ),
  };
}

export function forgotPassword(userEmail) {
  return {
    types: [
      AT.FORGOT_PASSWORD,
      AT.FORGOT_PASSWORD_SUCCESS,
      AT.FORGOT_PASSWORD_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_SURTUR_V2}/forget-password/`, {
        data: {
          email: userEmail,
        },
      }),
  };
}

export function logout() {
  return {
    types: [AT.LOGOUT, AT.LOGOUT_SUCCESS, AT.LOGOUT_FAIL],
    promise: (client) =>
      client.logout(`${config.API_URL_SURTUR_V2}/logout/?format=json`),
  };
}

export function updateName(firstName, lastName) {
  return {
    types: [AT.UPDATE_NAME, AT.UPDATE_NAME_SUCCESS, AT.UPDATE_NAME_FAIL],
    promise: (client) =>
      client.patch(`${config.API_URL_SURTUR_V2}/me/?format=json`, {
        data: {
          firstName,
          lastName,
        },
      }),
  };
}

export function updateProfile(description, birthday, gender, photoUrl) {
  return {
    types: [
      AT.UPDATE_PROFILE,
      AT.UPDATE_PROFILE_SUCCESS,
      AT.UPDATE_PROFILE_FAIL,
    ],
    promise: (client) =>
      client.put(`${config.API_URL_GOBLIN}/me/profile/?format=json`, {
        data: {
          description,
          birthday,
          gender,
          photoUrl,
        },
      }),
  };
}

export function passwordReset(uidb64, token, password) {
  return {
    types: [
      AT.PASSWORD_RESET,
      AT.PASSWORD_RESET_SUCCESS,
      AT.PASSWORD_RESET_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_SURTUR_V2}/forget-password/confirm/${uidb64}/${token}/`,
        {
          data: {
            password,
          },
        },
      ),
  };
}

export const loadMarketplaceProfile = () => {
  return {
    types: [
      AT.LOAD_MARKETPLACE_PROFILE,
      AT.LOAD_MARKETPLACE_PROFILE_SUCCESS,
      AT.LOAD_MARKETPLACE_PROFILE_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_SURTUR_V2}/me/?format=json`, {
        headers: {
          'Cache-Control': 'no-store',
        },
      }),
  };
};

export const loadShopAssistantCurrentStoreLocation = () => {
  return {
    types: [
      AT.LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION,
      AT.LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION_SUCCESS,
      AT.LOAD_SHOP_ASSISTANT_CURRENT_STORE_LOCATION_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/shop-assistant/current-store-location/?format=json`,
      ),
  };
};

export function loadStoreLocationChoices(callback = (res) => res) {
  return {
    types: [
      AT.LOAD_STORE_LOCATIONS,
      AT.LOAD_STORE_LOCATIONS_SUCCESS,
      AT.LOAD_STORE_LOCATIONS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/shop-assistant/store-locations/?format=json`,
      ),
    options: {
      transformer: (result) => {
        return result.map((store) => {
          return {
            label: store.storeName,
            value: store.code,
          };
        });
      },
      callback,
    },
  };
}

export function checkInStoreLocation(code) {
  return {
    types: [
      AT.CHECK_IN_STORE,
      AT.CHECK_IN_STORE_SUCCESS,
      AT.CHECK_IN_STORE_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/shop-assistant/add-sa-location/?format=json`,
        {
          data: {
            code,
          },
        },
      ),
  };
}

export function closeAnnouncementBanner() {
  return {
    type: AT.CLOSE_ANNOUNCEMENT_BANNER,
  };
}

export function requestUserDeletion() {
  return {
    types: [
      AT.REQUEST_USER_DELETION,
      AT.REQUEST_USER_DELETION_SUCCESS,
      AT.REQUEST_USER_DELETION_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_SURTUR_V2}/user-delete-request/?format=json`,
      ),
  };
}

export const showLogoutModal = () => ({ type: AT.SHOW_LOGOUT_MODAL });
export const hideLogoutModal = () => ({ type: AT.HIDE_LOGOUT_MODAL });
