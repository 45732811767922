import React from 'react';
import PropTypes from 'prop-types';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';

const NavbarTitleCenteredWrapper = (props) => {
  const { children, title } = props;
  return (
    <React.Fragment>
      <NavbarTitleCentered title={title} />
      {children}
    </React.Fragment>
  );
};

NavbarTitleCenteredWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavbarTitleCenteredWrapper;
