import { schema, normalize, denormalize } from 'normalizr';

export const lineSchema = new schema.Entity(
  'cartLines',
  {},
  { idAttribute: 'uuid' },
);

lineSchema.define({ childLines: [lineSchema] });

export const roomSectionSchema = new schema.Entity(
  'roomSections',
  {
    lines: [lineSchema],
  },
  { idAttribute: 'uuid' },
);

export const roomSchema = new schema.Entity(
  'rooms',
  {
    roomSections: [roomSectionSchema],
  },
  { idAttribute: 'uuid' },
);

export const portfolioCartSchema = new schema.Entity(
  'portfolioCart',
  {
    rooms: [roomSchema],
  },
  { idAttribute: 'cartRevision' },
);

export const portfolioSchema = new schema.Entity('portfolio', {
  portfolioCart: portfolioCartSchema,
  cartLines: [lineSchema],
}, { idAttribute: 'projectKey' });

export function getDenormalizedPortfolio(portfolio) {
  const normalized = normalize(portfolio, portfolioSchema);
  return denormalize(normalized.result, portfolioSchema, normalized.entities);
}
