import React, { memo } from 'react';
import { FCFetchData } from 'types';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  ActivityIndicator,
  Colors,
  HorizontalDivider,
  MessageDialog,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';

import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';
import { unslugify } from 'app-libs/etc';
import { useParams } from 'app-libs/hook_modules/router';

import { Footer } from 'components/Footer';
import NotFoundPageMeta from 'components/NotFoundPageMeta';

import {
  K_LOAD_OFFLINE_STORE_LOCATION_SEO_AREA_QUERY_KEY,
  getLoadOfflineStoreLocationListQueryKey,
  loadOfflineStoreLocationList,
  loadOfflineStoreLocationSEOAreaList,
} from 'entities/offlineStore/api';
import {
  useQueryLoadOfflineStoreLocationList,
  useQueryLoadOfflineStoreLocationSEOAreaList,
} from 'entities/offlineStore/utils';

import OfflineStoreLocationAreaListPageFooter from './OfflineStoreLocationAreaListPageFooter';
import OfflineStoreLocationListPageAreaFilter from './OfflineStoreLocationListPageAreaFilter';
import OfflineStoreLocationListPageBannerSection from './OfflineStoreLocationListPageBannerSection';
import OfflineStoreLocationListPageBreadcrumb from './OfflineStoreLocationListPageBreadcrumb';
import OfflineStoreLocationListPageContent from './OfflineStoreLocationListPageContent';
import OfflineStoreLocationListPageFooter from './OfflineStoreLocationListPageFooter';
import OfflineStoreLocationListPageMeta from './OfflineStoreLocationListPageMeta';

const OfflineStoreListPage: FCFetchData = memo(() => {
  const { areaSlug = '' } = useParams<{ areaSlug?: string }>();
  const areaName = unslugify(areaSlug);

  const { data, isLoading: isLoadingOfflineStoreLocations } =
    useQueryLoadOfflineStoreLocationList(areaName);

  const { data: seoAreas, isLoading: isLoadingSEOAreas } =
    useQueryLoadOfflineStoreLocationSEOAreaList();

  const isPageNotReady = isLoadingOfflineStoreLocations || isLoadingSEOAreas;

  if (!isLoadingOfflineStoreLocations && data.length === 0) {
    return (
      <>
        <NavbarTitleCentered title="Cek Lokasi Showroom" />
        <NotFoundPageMeta />
        <MessageDialog
          header="Tidak ditemukan"
          description="Kami tidak menemukan apapun. Coba kembali ke beranda."
          linkText="Kembali ke beranda"
          style={cStyles.paxxl}
        />
      </>
    );
  }

  return (
    <>
      <NavbarTitleCentered title="Cek Lokasi Showroom" />
      <OfflineStoreLocationListPageMeta />
      {isPageNotReady ? (
        <ActivityIndicator style={cStyles.mtxl} />
      ) : (
        <>
          <View style={s.container}>
            <OfflineStoreLocationListPageBreadcrumb />
            <OfflineStoreLocationListPageBannerSection style={s.banner} />
            <Text h1 theme="h3" style={s.pageTitle}>
              Toko Furniture dan Desain Interior {areaName}
            </Text>
            <OfflineStoreLocationListPageContent
              offlineStoreLocations={data}
              offlineStoreSEOAreas={seoAreas}
            />
          </View>
          <HorizontalDivider color={Colors.C_NEUTRAL_30} style={s.separator} />
          <OfflineStoreLocationListPageAreaFilter seoAreas={seoAreas} />
          {areaName ? (
            <OfflineStoreLocationAreaListPageFooter areaName={areaName} />
          ) : (
            <OfflineStoreLocationListPageFooter />
          )}
          <Footer />
        </>
      )}
    </>
  );
});

OfflineStoreListPage.fetchData = async ({
  res,
  params,
  queryClient,
  isServer,
}) => {
  const { areaSlug = '' } = params;
  const areaName = unslugify(areaSlug);

  if (isServer) {
    const queryKey = getLoadOfflineStoreLocationListQueryKey(areaName);

    await queryClient.prefetchQuery(queryKey, () =>
      loadOfflineStoreLocationList(areaName),
    );

    try {
      const activeOfflineStoreLocationAreas = await queryClient.fetchQuery(
        K_LOAD_OFFLINE_STORE_LOCATION_SEO_AREA_QUERY_KEY,
        loadOfflineStoreLocationSEOAreaList,
      );
      // return status 404 if area name is not exists
      if (
        !activeOfflineStoreLocationAreas.find(
          (area) => area.areaName === areaName,
        )
      ) {
        res.status(404);
      }
    } catch {
      res.status(404);
    }
  }

  return {};
};

const s = StyleSheet.create({
  container: cStyles.ptm,
  banner: {
    marginBottom: 28,
  },
  pageTitle: {
    ...cStyles.mbxl,
    ...cStyles.phxl,
  },
  separator: {
    ...cStyles.mbxl,
    borderTopWidth: 8,
  },
  internalLinkingContainer: {
    ...cStyles.paxl,
    backgroundColor: Colors.C_NEUTRAL_10,
    marginTop: 20,
  },
});

export default OfflineStoreListPage;
