import keyMirror from 'keymirror';

import { productCategoryTree as fallbackCategoryTree } from 'app-libs/routes/fallbackCategoryTree';
import { K_PRODUCT_CATEGORY_TREE_URL } from 'app-libs/serializers/category';

export const AT = keyMirror({
  LOAD_CATEGORY_TREE: null,
  LOAD_CATEGORY_TREE_SUCCESS: null,
  LOAD_CATEGORY_TREE_FAIL: null,
});

export const initialState = {
  productCategoryTree: fallbackCategoryTree,
  isLoadingCategoryTree: false,
  isLoadedCategoryTree: false,
};

export default function categoryReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;

  switch (action.type) {
    case AT.LOAD_CATEGORY_TREE:
      state = { ...state, isLoadingCategoryTree: true };
      break;
    case AT.LOAD_CATEGORY_TREE_SUCCESS:
      state = {
        ...state,
        isLoadingCategoryTree: false,
        isLoadedCategoryTree: true,
        productCategoryTree: action.result,
      };
      break;
    case AT.LOAD_CATEGORY_TREE_FAIL:
      state = {
        ...state,
        isLoadingCategoryTree: false,
        isLoadedCategoryTree: true,
        productCategoryTree: fallbackCategoryTree,
      };
      break;
  }
  return state;
}

export const loadProductCategoryTree = () => ({
  types: [
    AT.LOAD_CATEGORY_TREE,
    AT.LOAD_CATEGORY_TREE_SUCCESS,
    AT.LOAD_CATEGORY_TREE_FAIL,
  ],
  promise: (client) =>
    client.get(K_PRODUCT_CATEGORY_TREE_URL, { timeout: 3000 }),
});
