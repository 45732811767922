import { StyleSheet } from 'react-native';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';

import { K_ICON_SIZE } from './constants';

export default StyleSheet.create({
  container: {
    ...cStyles.phxxl,
    ...cStyles.bgw,
    ...cStyles.flexDirRow,
  },
  iconContainer: {
    ...cStyles.pvxl,
  },
  iconSizer: {
    width: K_ICON_SIZE,
    height: K_ICON_SIZE,
  },
  leftColInnerContainer: {
    ...cStyles.flex1,
    ...cStyles.flexDirRow,
    alignItems: 'center',
  },
  shareIcon: {
    ...cStyles.phm,
  },
  toastStyle: {
    ...cStyles.flexMiddle,
    backgroundColor: colors.C_BLACK_55,
    maxWidth: 190,
    maxHeight: 40,
  },
  toastText: {
    ...tStyles.body4,
    color: colors.C_BLACK_5,
  },
});
