import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import {
  makeSerializedParameters,
  parseQuery,
  parseQueryString,
  slugify,
  unslugify,
} from 'app-libs/etc';
import {
  getBreadcrumbFromPath,
  getParamsFromBreadcrumb,
  isSubCatInBreadcrumbs,
} from 'app-libs/etc/categoryHelper';

import { K_DKRMA_HOSTNAME } from 'constants/routeConstants';

export const allowedUrlPrefix = [
  'www.dekoruma.com',
  'dekoruma.com',
  'http://dekoruma.com',
  'https://dekoruma.com',
  'http://www.dekoruma.com',
  'https://www.dekoruma.com',
  'localhost:5000',
  'localhost:5005',
  'localhost:5010',
  'localhost:5015',
  'localhost:5020',
  'localhost:5025',
  'localhost:5030',
  'localhost:5035',
  'localhost:5040',
  'localhost:5045',
  'localhost:5050',
  'localhost:5055',
  'localhost:5060',
  'localhost:5065',
  'localhost:5070',
  'localhost:5075',
  'localhost:5080',
  'localhost:5085',
  'localhost:5090',
  'localhost:5095',
  'http://localhost:5000',
  'http://localhost:5005',
  'http://localhost:5010',
  'http://localhost:5015',
  'http://localhost:5020',
  'http://localhost:5025',
  'http://localhost:5030',
  'http://localhost:5035',
  'http://localhost:5040',
  'http://localhost:5045',
  'http://localhost:5050',
  'http://localhost:5055',
  'http://localhost:5060',
  'http://localhost:5065',
  'http://localhost:5070',
  'http://localhost:5075',
  'http://localhost:5080',
  'http://localhost:5085',
  'http://localhost:5090',
  'http://localhost:5095',
  'dkrm://open',
]; // Loki native deeplink

export const K_DNB_PATHNAME_REGEXES = [
  /\/interior/i,
  /\/proyek/i,
  /\/portfolio/i,
  /\/kitchen-set/i,
  /\/walk-in-closet/i,
  /\/desain/i,
];

export function getPathFromUrl(url) {
  if (!url) return '/';
  let matchedAllowedPrefix = '';
  matchedAllowedPrefix = allowedUrlPrefix
    .filter((prefix) => url.startsWith(prefix))
    .join('');
  if (url === matchedAllowedPrefix && matchedAllowedPrefix) {
    return '/';
  }
  return url.slice(matchedAllowedPrefix.length);
}

// from https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string
export function getHostnameFromUrl(url) {
  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('://') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  // remove www
  if (hostname.startsWith('www.')) {
    hostname = hostname.substring('www.'.length, hostname.length);
  }

  return hostname;
}

export function chooseLinkToHighlight(linkChoices, currentPath) {
  const currentPathWithoutParams = currentPath.split('?')[0];
  let toHighlight = linkChoices.find(
    (link) => link.to === currentPathWithoutParams,
  );
  const currentPathParentLink = currentPathWithoutParams.substring(
    0,
    currentPathWithoutParams.lastIndexOf('/'),
  );
  if (!toHighlight) {
    toHighlight = linkChoices.find((link) => link.to === currentPathParentLink);
  }
  return toHighlight;
}

export function getUrlStartWithHttpOrHttps(url) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `http://${url}`;
}

export function pathWithoutParams(path) {
  const resultPath = path.split('?');
  return resultPath[0];
}

export function pathWithoutHash(path) {
  const a = path.split('#');
  return a[0];
}

export function getParamByNameFromURL(url, name) {
  if (!name) return null;
  const _name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regexS = `[\\?&]${_name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  return results === null ? null : results[1];
}

export function getDisjunctiveFacetValues(existingQueryParams, facetName) {
  const queryParams = Object.assign({}, existingQueryParams);
  queryParams.noscroll = true;
  if (queryParams.dfr) {
    try {
      queryParams.dfr = JSON.parse(queryParams.dfr);
    } catch (err) {
      queryParams.dfr = {};
    }
    const res = queryParams.dfr[facetName];
    if (res) {
      return res.filter(Boolean);
    }
  }
  return [];
}

export function makeDisjunctiveFacetSingleUrl(
  existingPath,
  existingQueryParams,
  facetName,
  facetValue,
) {
  return makeDisjunctiveFacetUrl(existingPath, existingQueryParams, facetName, [
    facetValue,
  ]);
}

export function makeDisjunctiveFacetQueryParams(
  existingQueryParams,
  facetName,
  facetValues,
) {
  const queryParams = Object.assign({}, existingQueryParams);
  if (queryParams.dfr && typeof queryParams.dfr === 'string') {
    try {
      queryParams.dfr = JSON.parse(queryParams.dfr);
    } catch (err) {
      queryParams.dfr = {};
    }
  } else if (typeof queryParams.dfr === undefined) {
    queryParams.dfr = {};
  }
  const queryParamsDfr = {
    ...queryParams.dfr,
    [facetName]: [...uniq(facetValues)],
  };
  queryParams.dfr = JSON.stringify(queryParamsDfr);
  return queryParams;
}

export function makeDisjunctiveFacetUrl(
  existingPath,
  existingQueryParams,
  facetName,
  facetValues,
) {
  const queryParams = makeDisjunctiveFacetQueryParams(
    existingQueryParams,
    facetName,
    facetValues,
  );
  queryParams.noscroll = true;
  const to = `${existingPath}?${makeSerializedParameters(queryParams)}`;
  return to;
}

export function makeDisjunctiveFacetClearedUrl(
  existingPath,
  existingQueryParams,
  facetName,
) {
  const queryParams = Object.assign({}, existingQueryParams);
  queryParams.noscroll = true;
  if (queryParams.dfr) {
    queryParams.dfr = JSON.parse(queryParams.dfr);
    delete queryParams.dfr[facetName];
    if (isEmpty(queryParams.dfr)) {
      delete queryParams.dfr;
    } else {
      queryParams.dfr = JSON.stringify(queryParams.dfr);
    }
  }
  const to = `${existingPath}?${makeSerializedParameters(queryParams)}`;
  return to;
}

export function getHierarchicalFacetFilterItemUrl(baseUrl, category) {
  return `${baseUrl}/${slugify(category)}`;
}

export function getLowerCaseHierarchicalFacetFilterItemUrl(baseUrl, category) {
  return `${baseUrl}/${slugify(category).toLowerCase()}`;
}

/**
 * Sample input (params):
  { lvl0:'Aksesoris Jam'}

 * Sample input (allBreadcrumbs):
  [
      'Dekor',
      'Dekor > Alas Kursi',
      'Dekor > Jam',
      'Dekor > Jam > Jam Meja',
      'Dekor > Jam > Aksesoris Jam',
  ];

 * Sample output:
 * { lvl0:'Dekor',
 *   lvl1:'Jam',
 *   lvl2:'Aksesoris Jam'
 * }

 * params: 'shouldReturnNullIfNotExists' (default: false)
 *   if set to true, then return null if we cannot get the breadcrumb
 */
export function enrichParamsWithCatLvl(
  params,
  allBreadcrumbs,
  shouldReturnNullIfNotExists = false,
) {
  const { lvl0, lvl1, lvl2 } = params;
  if (lvl0 && !lvl1 && !lvl2) {
    const lastCat = unslugify(lvl0);
    const breadcrumb = allBreadcrumbs.find((b) =>
      isBreadcrumbHasLastCat(b, lastCat),
    );
    if (!breadcrumb) {
      if (shouldReturnNullIfNotExists) return null;
      return params; // err handler
    }
    const newParams = getParamsFromBreadcrumb(breadcrumb);
    return {
      ...params,
      ...newParams,
    };
  }
  return params;
}

export function enrichParamsWithAllDescendingCatLvl(
  params,
  allBreadcrumbs,
  shouldReturnNullIfNotExists = false,
) {
  const { lvl0, lvl1, lvl2 } = params;
  if (lvl0 && !lvl1 && !lvl2) {
    const lastCat = unslugify(lvl0);
    const breadcrumbs = allBreadcrumbs.filter((b) =>
      isBreadcrumbHasCat(b, lastCat),
    );
    if (isEmpty(breadcrumbs)) {
      if (shouldReturnNullIfNotExists) return null;
      return [params]; // err handler
    }
    const newParams = breadcrumbs.map((breadcrumb) => ({
      ...params,
      ...getParamsFromBreadcrumb(breadcrumb),
    }));
    return newParams;
  }
  return [params];
}

export function isBreadcrumbHasLastCat(breadcrumb, _lastCat) {
  const lastCat = _lastCat.toLowerCase();
  if (breadcrumb.toLowerCase().endsWith(lastCat)) {
    const categories = breadcrumb.split(' > ');
    const isExact = !!categories.find((c) => c.toLowerCase() === lastCat);
    if (isExact) return breadcrumb;
    return false;
  }
  return false;
}

export function isBreadcrumbHasCat(breadcrumb, _cat) {
  const cat = _cat.toLowerCase();
  if (breadcrumb.toLowerCase().includes(cat)) {
    const categories = breadcrumb.split(' > ');
    const isExact = !!categories.find((c) => c.toLowerCase() === cat);
    if (isExact) return breadcrumb;
    return false;
  }
  return false;
}

export function isPathnameRouteToCategory(currentPath, allBreadcrumbs) {
  const lastCat = getBreadcrumbFromPath(currentPath);
  return isSubCatInBreadcrumbs(lastCat, allBreadcrumbs);
}

export function getUrlWithQuery(url, query) {
  if (!isEmpty(query)) {
    return `${url}?${makeSerializedParameters(query)}`;
  }
  return url;
}

export function getUrlWithOverridenQuery(url, newQuery) {
  if (!isEmpty(newQuery)) {
    const parsedQuery = parseQuery(url);
    return getUrlWithQuery(pathWithoutParams(url), {
      ...parsedQuery,
      ...newQuery,
    });
  }
  return url;
}

/** override query string with query object */
export function getOverridenQueryString(queryString, queryObj) {
  if (!isEmpty(queryObj)) {
    const parsedQuery = parseQueryString(queryString);
    return makeSerializedParameters({ ...parsedQuery, ...queryObj });
  }
  return queryString;
}

export function getUrlWithoutProtocol(url) {
  return url.replace(/^[^#]*?:\/\//, '');
}

export function getFullUrlFromLocation(location) {
  const { pathname, search } = location;
  return `${pathname}${search}`;
}

export function forceHttps(url) {
  if (url) {
    return url.replace(/(^https?:\/\/)?/i, 'https://');
  }
  return url;
}

export const isDnBPage = (pathname) =>
  K_DNB_PATHNAME_REGEXES.reduce(
    (finalValue, regex) => finalValue || regex.test(pathname.toLowerCase()),
    false,
  );

export const getLinkConfigFromUrl = (url) => {
  if (getHostnameFromUrl(url) === K_DKRMA_HOSTNAME) {
    return {
      to: url,
      isUsingAnchor: true,
    };
  }
  return {
    to: getPathFromUrl(url),
    isUsingAnchor: false,
  };
};
