import loadable from '@loadable/component';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';
import PropTypes from 'prop-types';
import React from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { HorizontalDivider } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { ButtonWithTextAndIcon } from '@dkrm/ui-kit-basic/v2';

import InfiniteScrollList from 'app-libs/components/InfiniteScrollList';
import ModalFallback from 'app-libs/components/ModalFallback';

import styles from './styles';

const Modal = loadable(() => import('./Modal'));

export default class PopupFilterListv2 extends React.PureComponent {
  static propTypes = {
    allowEmpty: PropTypes.bool.isRequired,
    applyButtonLabel: PropTypes.string,
    buttonProps: PropTypes.shape({
      image: PropTypes.shape({
        theme: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
        style: stylePropTypes,
      }),
      align: PropTypes.string,
      style: stylePropTypes,
      buttonStyle: stylePropTypes,
      textStyle: stylePropTypes,
    }),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    dimension: PropTypes.shape({
      width: PropTypes.number,
    }),
    getLabel: PropTypes.func,
    getButtonTheme: PropTypes.func,
    isApplyOnClick: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    itemComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
    label: PropTypes.string.isRequired,
    labelStyle: stylePropTypes,
    multiple: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onRequestOpen: PropTypes.func,
    onRequestClose: PropTypes.func,
    width: PropTypes.number,
  };

  static defaultProps = {
    applyButtonLabel: 'Terapkan',
    buttonProps: {},
    dimension: { width: 0 },
    isApplyOnClick: false,
    isModalOpen: false,
    labelStyle: {},
    getLabel: (state, props) => PopupFilterListv2.getDefaultLabel(state, props),
    getButtonTheme: ({ selected }) =>
      selected.length > 0 ? 'primary' : 'default',
    onRequestOpen: () => null,
    onRequestClose: () => null,
    width: undefined,
  };

  static getDefaultLabel = (state, { label }) => {
    return label;
  };

  static getSelectedData = (props) =>
    props.data.filter((item) => item.selected).map((item) => item.value);

  state = {
    selected: PopupFilterListv2.getSelectedData(this.props),
    prevSelected: PopupFilterListv2.getSelectedData(this.props),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newSelected = PopupFilterListv2.getSelectedData(nextProps);
    if (!isEqual(newSelected, prevState.prevSelected)) {
      return {
        selected: [...newSelected],
        prevSelected: [...newSelected],
      };
    }
    return null;
  }

  getNewSelectedState =
    (value) =>
    ({ selected }, { multiple, allowEmpty }) => {
      let newSelected = xor(selected, [value]);

      if (!allowEmpty && newSelected.length === 0) return { selected };
      if (!multiple && newSelected.length > 1) {
        newSelected = [value];
      }

      return { selected: newSelected };
    };

  actionClear = () =>
    this.setState({
      selected: [],
    });

  handleChange = (value) => {
    const { isApplyOnClick, onChange } = this.props;
    if (isApplyOnClick) {
      const { selected } = this.getNewSelectedState(value)(
        this.state,
        this.props,
      );
      onChange(selected);
    } else {
      this.setState(this.getNewSelectedState(value));
    }
  };

  handleToggle = () => {
    const { isModalOpen, onRequestOpen, onRequestClose } = this.props;
    return isModalOpen ? onRequestClose() : onRequestOpen();
  };

  handleApply = () => {
    const { onChange } = this.props;
    const { selected } = this.state;
    onChange(selected);
  };

  renderFilterItem = ({ item, index }) => {
    const { itemComponent: Item } = this.props;
    const { selected } = this.state;
    return (
      <React.Fragment>
        {!!index && <HorizontalDivider />}
        <Item
          {...item}
          containerStyle={styles.whiteContainer}
          selected={selected.includes(item.value)}
          onChange={this.handleChange}
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      getLabel,
      width,
      isModalOpen,
      buttonProps,
      onRequestClose,
      label,
      allowEmpty,
      data,
      isApplyOnClick,
      applyButtonLabel,
    } = this.props;
    const { style, textStyle, buttonStyle, ...restButtonProps } = buttonProps;
    const { selected } = this.state;
    return (
      <React.Fragment>
        <ButtonWithTextAndIcon
          {...restButtonProps}
          theme={isEmpty(selected) ? 'black' : 'tosca'}
          iconColor={
            isEmpty(selected) ? colors.C_BLACK_100 : colors.C_SECONDARY_TOSCA_4
          }
          themeType="tertiary"
          title={getLabel(this.state, this.props)}
          style={[{ width }, style]}
          iconSize={20}
          buttonStyle={[styles.buttonStyle, buttonStyle]}
          onPress={this.handleToggle}
          textStyle={[{ lineHeight: 15 }, textStyle]}
        />
        {isModalOpen && (
          <Modal
            onRequestClose={onRequestClose}
            label={label}
            allowEmpty={allowEmpty}
            listComponent={
              <InfiniteScrollList
                data={data}
                renderItem={this.renderFilterItem}
                extraData={selected}
                initialNumToRender={20}
                keyExtractor={(item) => item.value}
                windowSize={20}
                maxToRenderPerBatch={20}
                removeClippedSubviews={false}
                isFull
              />
            }
            actionClear={this.actionClear}
            isApplyOnClick={isApplyOnClick}
            applyButtonLabel={applyButtonLabel}
            onApply={this.handleApply}
            fallback={<ModalFallback closeModal={onRequestClose} />}
          />
        )}
      </React.Fragment>
    );
  }
}
