/* global _cio */

import moment from 'moment';

import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

const cio = {
  identify: (properties) => {
    const data = {
      ...properties,
      timestamp: moment().unix(),
      platform: getPlatform(),
    };

    _cio.identify(data);
  },
  track: (eventName, properties) => {
    const data = {
      ...properties,
      timestamp: moment().unix(),
      platform: getPlatform(),
    };

    _cio.track(eventName, data);
  },
};

export default cio;
