import {
  getOfferDiscountAmount,
  getBasketLinesTotalValue,
} from 'app-libs/etc/productHelper';

import { Product } from 'app-libs/components/Product/types';
import { Basket, BasketLine, BasketLineProduct } from 'app-libs/redux_modules/entity_modules/basket/types';

const K_FB_PARAMS_CONTENT = 'fb_content';
const K_FB_PARAMS_CONTENT_ID = 'fb_content_id';
const K_FB_PARAMS_CONTENT_TYPE = 'fb_content_type';
const K_FB_PARAMS_CURRENCY = 'fb_currency';
const K_FB_PARAMS_SUCCESS = 'fb_success';

const K_FB_CURRENCY_IDR = 'IDR';
const K_FB_CONTENT_TYPE_PRODUCT = 'product';

const K_FB_EVENT_NAME_CONTENT_VIEW = 'fb_mobile_content_view';
const K_FB_EVENT_NAME_ADD_TO_WISHLIST = 'fb_mobile_add_to_wishlist';
const K_FB_EVENT_NAME_ADD_TO_CART = 'fb_mobile_add_to_cart';
const K_FB_EVENT_NAME_INITIATE_CHECKOUT = 'fb_mobile_initiated_checkout';
const K_FB_EVENT_NAME_ADD_PAYMENT_INFO = 'fb_mobile_add_payment_info';
const K_FB_EVENT_NAME_PURCHASE = 'fb_mobile_purchase';

export const genViewProductLogEventParams = (
  product: Product | BasketLineProduct,
) => ({
  eventName: K_FB_EVENT_NAME_CONTENT_VIEW,
  eventData: genProduct(product),
  valueToSum: product.priceNumber - getOfferDiscountAmount(product),
});

export const genAddToWishlistLogEventParams = (
  product: Product | BasketLineProduct,
) => ({
  eventName: K_FB_EVENT_NAME_ADD_TO_WISHLIST,
  eventData: genProduct(product),
  valueToSum: product.priceNumber - getOfferDiscountAmount(product),
});

export const genAddToCartLogEventParams = (
  product: Product | BasketLineProduct,
  quantity = 1,
) => ({
  eventName: K_FB_EVENT_NAME_ADD_TO_CART,
  eventData: genProduct(product, quantity),
  valueToSum: quantity * (product.priceNumber - getOfferDiscountAmount(product)),
});

export const genInitiateCheckoutLogEventParams = (
  basket: Basket,
) => {
  return {
    eventName: K_FB_EVENT_NAME_INITIATE_CHECKOUT,
    eventData: genBasket(basket.lines),
    valueToSum: getBasketLinesTotalValue(basket.lines),
  };
};

export const genAddPaymentInfoLogEventParams = () => ({
  eventName: K_FB_EVENT_NAME_ADD_PAYMENT_INFO,
  eventData: {
    [K_FB_PARAMS_SUCCESS]: 1,
  },
});

export const genPurchaseLogEventParams = (
  lines: BasketLine[],
) => {
  return {
    eventName: K_FB_EVENT_NAME_PURCHASE,
    eventData: genBasket(lines),
    valueToSum: getBasketLinesTotalValue(lines),
  };
};


const genProduct = (product: Product | BasketLineProduct, quantity = 1) => ({
  [K_FB_PARAMS_CONTENT]: JSON.stringify([{
    id: product.objectID,
    quantity,
  }]),
  [K_FB_PARAMS_CONTENT_ID]: product.objectID,
  [K_FB_PARAMS_CONTENT_TYPE]: K_FB_CONTENT_TYPE_PRODUCT,
  [K_FB_PARAMS_CURRENCY]: K_FB_CURRENCY_IDR,
});

const genBasket = (lines: BasketLine[]) => ({
  [K_FB_PARAMS_CONTENT]: JSON.stringify(lines.map(({ product, quantity }) => ({
    id: product.objectID,
    quantity,
  }))),
  [K_FB_PARAMS_CONTENT_TYPE]: K_FB_CONTENT_TYPE_PRODUCT,
  [K_FB_PARAMS_CURRENCY]: K_FB_CURRENCY_IDR,
});
