import React from 'react';

import { StyleSheet, View } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { Product } from 'app-libs/components/Product/types';

import { ViewStyle } from 'types/styles';

import { getProductSticker } from './utils';

interface ProductStickerProps {
  product: Product;
  size?: number;
  secondaryStickerSize?: number;
  isShowingCampaignSticker?: boolean;
  style?: ViewStyle;
}

const ProductSticker: React.FC<ProductStickerProps> = ({
  product,
  size = 48,
  secondaryStickerSize = 30,
  isShowingCampaignSticker = false,
  style = null,
}) => {
  const { primarySticker, secondarySticker } = getProductSticker(product);
  const top = isShowingCampaignSticker ? 22 : 4;

  if (!primarySticker) {
    return null;
  }
  return (
    <>
      <View
        style={[s.stickerContainer, { width: size, height: size, top }, style]}
      >
        <SmartImage
          source={primarySticker}
          width={size}
          height={size}
          shouldPreloadSmallerVersion={false}
          resizeMode="contain"
          accessibilityLabel="product-sticker"
        />
      </View>
      {secondarySticker && (
        <View style={s.subSecondaryStickerContainer}>
          <SmartImage
            source={secondarySticker}
            width={secondaryStickerSize}
            height={secondaryStickerSize}
            shouldPreloadSmallerVersion={false}
            resizeMode="contain"
            accessibilityLabel="product-sticker-bundle"
          />
        </View>
      )}
    </>
  );
};

const s = StyleSheet.create({
  stickerContainer: {
    position: 'absolute',
    right: 8,
  },
  secondaryStickerContainer: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
  subSecondaryStickerContainer: {
    position: 'absolute',
    right: 4,
    top: 35,
  },
});

export default ProductSticker;
