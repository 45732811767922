// plugin imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView } from 'react-native';

// module imports
import { K_CLOSE, K_ICON_URL_BY_NAMES } from '@dkrm/ui-kit-basic/constants';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, Navbar, FooterBar } from '@dkrm/ui-kit-basic';
import { Modal } from '@dkrm/ui-kit-basic/v2';
import { K_TAB_TITLE_HEIGHT } from '@dkrm/ui-kit-basic/Navbar/styles';
import NavbarImageButton from '@dkrm/ui-kit-basic/Navbar/NavbarButton/NavbarImageButton';
import COLORS from '@dkrm/general-libs/theme/colors';

export default class DesktopModal extends Component {
  static propTypes = {
    modalWidth: PropTypes.number,
    onCloseModalHandle: PropTypes.func,

    // content modal type
    contentContainerStyle: stylePropTypes,
    ContentComponent: PropTypes.element.isRequired,

    // header modal type
    headerTitle: PropTypes.string,
    headerTitleStyle: stylePropTypes,
    headerTitleAlign: PropTypes.oneOf(['left', 'center', 'right']),
    headerContainerStyle: stylePropTypes,
    HeaderComponent: PropTypes.element,

    // footer modal type
    footerContainerStyle: stylePropTypes,
    FooterComponent: PropTypes.element,
  };
  static defaultProps = {
    modalWidth: 400,
    onCloseModalHandle: null,

    // content modal default
    contentContainerStyle: null,

    // header modal default
    headerTitle: '',
    headerContainerStyle: null,
    HeaderComponent: null,
    headerTitleStyle: null,
    headerTitleAlign: 'left',

    // footer modal default
    footerContainerStyle: null,
    FooterComponent: null,
  };

  returnInjectedComponent(component) {
    if (!React.isValidElement(component)) return component;

    const { onCloseModalHandle } = this.props;

    const injectedComponent = React.cloneElement(component, {
      onCloseModalHandle,
    });

    return injectedComponent;
  }

  renderHeader() {
    const {
      headerTitle,
      headerTitleStyle,
      HeaderComponent,
      headerContainerStyle,
      onCloseModalHandle,
      headerTitleAlign,
    } = this.props;

    const headerStyle = headerContainerStyle || {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 50,
      paddingRight: 50,
    };

    const titleAlignStyle = {
      textAlign: headerTitleAlign,
    };

    const titleStyle = headerTitleStyle
      ? [titleAlignStyle, headerTitleStyle]
      : [cStyles.h3, titleAlignStyle];

    const middleNodeStyle =
      headerTitleAlign === 'center'
        ? {
            position: 'absolute',
            width: '100%',
            justifyContent: 'center',
            height: K_TAB_TITLE_HEIGHT,
            zIndex: -1,
          }
        : null;

    if (React.isValidElement(HeaderComponent)) {
      return (
        <View style={[headerStyle]}>
          {this.returnInjectedComponent(HeaderComponent)}
        </View>
      );
    }
    return (
      <View style={[headerStyle]}>
        <Navbar
          middleNodeStyle={middleNodeStyle}
          style={{
            shadowOpacity: 0,
            elevation: 0,
          }}
          leftButtonNode={
            <NavbarImageButton
              uri={K_ICON_URL_BY_NAMES[K_CLOSE]}
              onPress={onCloseModalHandle}
            />
          }
          middleNode={<Text style={titleStyle}>{headerTitle}</Text>}
        />
      </View>
    );
  }

  renderContent() {
    const { ContentComponent, contentContainerStyle } = this.props;

    const contentStyle = contentContainerStyle || {
      paddingLeft: 70,
      paddingRight: 70,
    };

    return (
      <View style={[contentStyle]}>
        {this.returnInjectedComponent(ContentComponent)}
      </View>
    );
  }
  renderFooter() {
    const { FooterComponent, footerContainerStyle } = this.props;

    if (React.isValidElement(FooterComponent)) {
      return (
        <FooterBar style={[footerContainerStyle]}>
          {this.returnInjectedComponent(FooterComponent)}
        </FooterBar>
      );
    }

    return <View style={[cStyles.paxxl, footerContainerStyle]} />;
  }
  render() {
    const { modalWidth, onCloseModalHandle } = this.props;
    const width = modalWidth;
    const borderStyle = {
      WebkitBackgroundClip: 'padding-box',
      borderRadius: '3px',
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 25px 100px',
    };

    return (
      <Modal
        onDismiss={onCloseModalHandle}
        backdropStyle={{
          backgroundColor: COLORS.C_BACKDROP,
        }}
        contentStyle={{
          // centering view
          transform: 'translate3d(-50%, -50%, 0)',
          left: '50%',
          top: '50%',
          bottom: null,
          right: null,
          width,
          ...borderStyle,
        }}
      >
        <View style={[cStyles.flex1]}>
          {this.renderHeader()}
          <View style={{ maxHeight: 700 }}>
            <ScrollView contentContainerStyle={[cStyles.flex1]}>
              {this.renderContent()}
            </ScrollView>
            {this.renderFooter()}
          </View>
        </View>
      </Modal>
    );
  }
}
