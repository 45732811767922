import React, { memo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { getCDNImageUrl } from '@dkrm/general-libs/Utils/imageUtils';
import { ReflowView } from '@dkrm/ui-kit-basic';
import { Colors, Image, StyleSheet, View, cStyles } from '@dkrm/ui-kit-v2';

import { OfflineStoreLocation } from 'entities/offlineStore/types';

import FooterButtonGroup from './FooterButtonGroup';
import OfflineStoreDescription from './OfflineStoreDescription';

const K_IMAGE_WIDTH = 162;
const K_IMAGE_HEIGHT = 81;

interface OfflineStoreLocationCardProps {
  area: string;
  offlineStoreLocation: OfflineStoreLocation;
  style?: StyleProp<ViewStyle>;
}

const OfflineStoreLocationCard: React.FC<OfflineStoreLocationCardProps> = memo(
  ({ offlineStoreLocation, style }) => {
    return (
      <View style={[s.container, style]}>
        {offlineStoreLocation.imageUrl ? (
          <ReflowView
            widthProportion={K_IMAGE_WIDTH}
            heightProportion={K_IMAGE_HEIGHT}
            style={s.imageReflowView}
          >
            <Image
              source={getCDNImageUrl(offlineStoreLocation.imageUrl)}
              alt={offlineStoreLocation.storeName}
              style={s.image}
              resizeMode="cover"
            />
          </ReflowView>
        ) : null}
        <OfflineStoreDescription offlineStoreLocation={offlineStoreLocation} />
        <FooterButtonGroup offlineStoreLocation={offlineStoreLocation} />
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.flex1,
    ...cStyles.bwam,
    ...cStyles.br4,
    ...cStyles.mb16,
    borderColor: Colors.C_NEUTRAL_30,
  },
  image: {
    ...cStyles.width100,
    ...cStyles.height100,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  imageReflowView: cStyles.mb8,
});

export default OfflineStoreLocationCard;
