// plugin imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView } from 'react-native';

// module imports
import { K_CLOSE, K_ICON_URL_BY_NAMES } from '@dkrm/ui-kit-basic/constants';
import { Text, Navbar, FooterBar } from '@dkrm/ui-kit-basic';
import { Modal as ModalV2 } from '@dkrm/ui-kit-basic/v2';
import { K_TAB_TITLE_HEIGHT } from '@dkrm/ui-kit-basic/Navbar/styles';
import NavbarImageButton from '@dkrm/ui-kit-basic/Navbar/NavbarButton/NavbarImageButton';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';

export default class MobileModalGeneric extends Component {
  static propTypes = {
    onCloseModalHandle: PropTypes.func,

    // content modal type
    contentContainerStyle: stylePropTypes,
    ContentComponent: PropTypes.element.isRequired,

    // header modal type
    headerTitle: PropTypes.string,
    headerTitleStyle: stylePropTypes,
    headerTitleAlign: PropTypes.oneOf(['left', 'center', 'right']),
    headerContainerStyle: stylePropTypes,
    HeaderComponent: PropTypes.element,

    // footer modal type
    footerContainerStyle: stylePropTypes,
    FooterComponent: PropTypes.element,
  };
  static defaultProps = {
    onCloseModalHandle: null,

    // content modal default
    contentContainerStyle: null,

    // header modal default
    headerTitle: '',
    headerTitleStyle: null,
    headerTitleAlign: 'left',
    headerContainerStyle: null,
    HeaderComponent: null,

    // footer modal default
    footerContainerStyle: null,
    FooterComponent: null,
  };

  returnInjectedComponent(component) {
    if (!React.isValidElement(component)) return component;

    const { onCloseModalHandle } = this.props;

    const injectedComponent = React.cloneElement(component, {
      onCloseModalHandle,
    });

    return injectedComponent;
  }

  renderHeader() {
    const {
      HeaderComponent,
      headerContainerStyle,
      headerTitle,
      headerTitleStyle,
      onCloseModalHandle,
      headerTitleAlign,
    } = this.props;
    if (React.isValidElement(HeaderComponent)) {
      return (
        <View style={[headerContainerStyle]}>
          {this.returnInjectedComponent(HeaderComponent)}
        </View>
      );
    }

    const titleAlignStyle = {
      textAlign: headerTitleAlign,
    };

    const titleStyle = headerTitleStyle
      ? [titleAlignStyle, headerTitleStyle]
      : [cStyles.contentl, titleAlignStyle];

    const middleNodeStyle =
      headerTitleAlign === 'center'
        ? {
            position: 'absolute',
            width: '100%',
            justifyContent: 'center',
            height: K_TAB_TITLE_HEIGHT,
            zIndex: -1,
          }
        : null;

    return (
      <Navbar
        middleNodeStyle={middleNodeStyle}
        style={{
          shadowOpacity: 0,
          elevation: 0,
        }}
        leftButtonNode={
          <NavbarImageButton
            uri={K_ICON_URL_BY_NAMES[K_CLOSE]}
            onPress={onCloseModalHandle}
          />
        }
        middleNode={<Text style={titleStyle}>{headerTitle}</Text>}
      />
    );
  }

  renderContent() {
    const { contentContainerStyle, ContentComponent } = this.props;

    const contentStyle = contentContainerStyle || cStyles.paxxl;

    return (
      <View style={[contentStyle]}>
        {this.returnInjectedComponent(ContentComponent)}
      </View>
    );
  }

  renderFooter() {
    const { footerContainerStyle, FooterComponent } = this.props;

    if (React.isValidElement(FooterComponent)) {
      return (
        <FooterBar style={footerContainerStyle}>
          {this.returnInjectedComponent(FooterComponent)}
        </FooterBar>
      );
    }
    return null;
  }

  render() {
    const { onCloseModalHandle } = this.props;

    return (
      <ModalV2 animationType="slide" onRequestClose={onCloseModalHandle}>
        <View style={[{ height: '100%' }]}>
          <View style={[cStyles.flex1]}>
            {this.renderHeader()}
            <ScrollView contentContainerStyle={[cStyles.flex1]}>
              {this.renderContent()}
            </ScrollView>
            {this.renderFooter()}
          </View>
        </View>
      </ModalV2>
    );
  }
}
