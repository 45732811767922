/* global fbc */

/* eslint-disable camelcase */
import crypto from 'crypto';
import superagent from 'superagent';

import config from 'config';

import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';

import { dayjs } from 'app-libs/etc/dateHelperV2';
import { User } from 'app-libs/redux_modules/auth/types';

const K_FB_PIXEL_ID_RETAIL = '209543129387571';
const K_FB_PIXEL_ID_INTERIOR = '326653771224316';
const K_FB_API_VERSION = 'v20.0';
const K_HASH_ALGORITHM = 'sha256';

const K_FB_CAPI_ENDPOINT_RETAIL = `https://graph.facebook.com/${K_FB_API_VERSION}/${K_FB_PIXEL_ID_RETAIL}/events?access_token=${config.FB_CAPI_ACCESS_TOKEN}`;
const K_FB_CAPI_ENDPOINT_INTERIOR = `https://graph.facebook.com/${K_FB_API_VERSION}/${K_FB_PIXEL_ID_INTERIOR}/events?access_token=${config.FB_CAPI_ACCESS_TOKEN}`;
const K_IPIFY_ENDPOINT = 'https://api.ipify.org?format=json';

const K_DEKORUMA_COM_EMAIL = '@dekoruma.com';
const K_DEKORUMA_GMAIL_COM_EMAIL = 'dekoruma@gmail.com';

/*
  @note(dika) 16 Aug 2022: FB Capi documentation
  https://developers.facebook.com/docs/marketing-api/conversions-api
*/

/*
  @note(dika) 22 Oct 2021: Exclude users with dekoruma email
  to improve facebook learning data
*/
const shouldTrackUserEmail = (email?: string) => {
  if (!email) {
    return true;
  }
  const emailString = String(email);
  return (
    !emailString.includes(K_DEKORUMA_COM_EMAIL) &&
    !emailString.includes(K_DEKORUMA_GMAIL_COM_EMAIL)
  );
};

interface IpifyResponse {
  body: {
    ip: string;
  };
}

const fbCapiWrapper = {
  _generateEventTime: () => {
    return dayjs().unix();
  },
  _generateActionSource: () => {
    return isWebView() ? 'app' : 'website';
  },
  _getClientIpAddress: async () => {
    await superagent.get(K_IPIFY_ENDPOINT).then((res: IpifyResponse) => {
      if (res?.body?.ip) {
        fbc.user.client_ip_address = res?.body?.ip;
      }
    });
  },
  logEvent: async (
    eventName: string,
    customData: Record<string, any>,
    eventID = '',
    businessUnit = 'retail',
  ) => {
    if (!shouldTrackUserEmail(fbc.email)) return;

    const endpoint =
      businessUnit === 'retail'
        ? K_FB_CAPI_ENDPOINT_RETAIL
        : K_FB_CAPI_ENDPOINT_INTERIOR;
    let data: Record<string, any>[] = [
      {
        event_name: eventName,
        event_time: fbCapiWrapper._generateEventTime(),
        action_source: fbCapiWrapper._generateActionSource(),
        user_data: fbc.user,
        custom_data: customData,
      },
    ];
    if (eventID) {
      data = [
        {
          ...data[0],
          event_id: eventID,
        },
      ];
    }
    if (config.isProduction) {
      superagent
        .post(endpoint)
        .send({ data })
        .end(() => null);
    } else {
      /*
        @note(dika) 19 Aug 2022: use test event code for development
        {
          data,
          test_event_code: 'TEST7518',
        }
        test_event_code usually needs to be updated
        value can be taken from https://www.facebook.com/events_manager2/list
      */
      superagent
        .post(endpoint)
        .send({ data, test_event_code: 'TEST7518' })
        .end(() => null);
    }
  },
  identify: async (user?: User) => {
    if (!fbc.user || Object.keys(fbc.user).length === 0) {
      fbc.user = {};
    }

    // @note(dika) 4 Dec 2024: Keep user email in fbc
    // to make sure users with Dekoruma email wont send any events to fbCapi
    fbc.email = user?.email;

    if (user?.email) {
      /*
        @note(dika) 16 Aug 2022: Hash normalized user data using sha256
        Email: Normalize by trimming any spaces and converting to lower case
        e.g. ` AndikA.Kusuma@Dekoruma.com ` to `andika.kusuma@dekoruma.com`
        Phone Number: Normalize by removing + sign
        e.g. `+628121979000` to `628121979000`
      */
      const normalizedEmail = user?.email.trim().toLowerCase();
      fbc.user.em = crypto
        .createHash(K_HASH_ALGORITHM)
        .update(normalizedEmail)
        .digest('hex');
    } else {
      delete fbc.user.em;
    }

    if (user?.phoneNumber) {
      const normalizedPhoneNumber = user?.phoneNumber.replace('+', '');
      fbc.user.ph = crypto
        .createHash(K_HASH_ALGORITHM)
        .update(normalizedPhoneNumber)
        .digest('hex');
    } else {
      delete fbc.user.ph;
    }

    if (navigator?.userAgent && !fbc.user.client_user_agent) {
      fbc.user.client_user_agent = navigator?.userAgent;
    }

    if (!fbc.user.client_ip_address) {
      await fbCapiWrapper._getClientIpAddress();
    }
  },
};

export default fbCapiWrapper;
