import isEqual from 'lodash/isEqual';

/**
 * @function - HELPER to test, whether we should load a new set of result
 */
export function shouldLoad(state, nextState) {
  // if old state is invalidated, means just fire load event immediately
  if (state.invalidated) {
    return true;
  }

  /**
   * Test fo equality between state & nextState that's affecting search results
   * Note: currently since we are still transitioning from algolia-helper,
   * we need to check for equality for each props, because algolia-helper added unrelevant props
   */
  if (!isQueryParamsEqual(state, nextState)) {
    return true;
  }

  return false;
}

/**
 * Test fo equality between state & nextState that's affecting search results
 */
export function isQueryParamsEqual(state, nextState) {
  const p1 = state.searchParameters;
  const p2 = nextState.searchParameters;

  if (
    isEqual(p1.query, p2.query) &&
    isEqual(p1.disjunctiveFacetsRefinements, p2.disjunctiveFacetsRefinements) &&
    isEqual(p1.facetsRefinements, p2.facetsRefinements) &&
    isEqual(p1.numericRefinements, p2.numericRefinements) &&
    isEqual(
      p1.hierarchicalFacetsRefinements,
      p2.hierarchicalFacetsRefinements,
    ) &&
    isEqual(p1.page, p2.page) &&
    isEqual(p1.hitsPerPage, p2.hitsPerPage) &&
    isEqual(p1.maxValuesPerFacet, p2.maxValuesPerFacet) &&
    isEqual(p1.distinct, p2.distinct) &&
    state.searchIndex === nextState.searchIndex
  ) {
    return true;
  }
  return false;
}
