import moment from 'moment';
import idLocale from 'moment/locale/id';
import {
  K_SHORTHAND_YEAR,
  K_SHORTHAND_MONTH,
  K_SHORTHAND_WEEK,
  K_SHORTHAND_DAY,
  K_SHORTHAND_HOUR,
  K_SHORTHAND_MINUTE,
  K_SHORTHAND_SECOND,
  K_SHORTHAND_MILLISECOND,
} from 'constants/dateConstants';

/** define proptypes for style passing */
import PropTypes from 'prop-types';

export const datePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape(),
]);

export function momentId(date) {
  if (!date) return moment().locale('id', idLocale);
  return moment(date).locale('id', idLocale);
}

export function momentIdFromUnix(timestamp) {
  return moment.unix(timestamp).locale('id', idLocale);
}

export function isSameDay(date1, date2) {
  return moment(date1).isSame(date2, K_SHORTHAND_DAY);
}

export const momentUtc = moment.utc;
export const momentDuration = moment.duration;

/**
 * Get datetime with:
 *  - date from souceDate
 *  - time from sourceTime
 * @param {(String/moment) of dateTime} sourceDate
 * @param {(String/moment) of dateTime} sourceTime
 * @return {moment of dateTime}
 *
 * eg.
 *  sourceDate -> '2018-03-21 14:00:00'
 *  sourceTime -> '2018-03-22 16:00:00'
 * return: '2018-03-21 16:00:00'
 */
export function genDateTime(sourceDate, sourceTime) {
  const momentSourceTime = moment(sourceTime);

  return genModifiedTimeFromDateTime(
    sourceDate,
    momentSourceTime.hour(),
    momentSourceTime.minute(),
    momentSourceTime.second(),
    momentSourceTime.millisecond(),
  );
}

export function genModifiedTimeFromDateTime(
  dateTime,
  hour = 0,
  minute = 0,
  second = 0,
  millisecond = 0,
  dateTimeFormat = 'YYYY-MM-DD',
) {
  return moment(dateTime, dateTimeFormat)
    .clone()
    .set({
      [K_SHORTHAND_HOUR]: hour,
      [K_SHORTHAND_MINUTE]: minute,
      [K_SHORTHAND_SECOND]: second,
      [K_SHORTHAND_MILLISECOND]: millisecond,
    });
}

export function getDateTimeDurationObject(startDate, endDate) {
  const durationLeft = moment.duration(
    momentId(endDate).diff(startDate),
    'milliseconds',
  );
  return durationLeft;
}

export function getDurationFormattedString(duration, format) {
  return momentId(duration.asMilliseconds()).format(format);
}

/**
 * To parse date and create countdown text string
 * @param {ArrayOf(Keys)} attrs - Attributes to show
 * @return {String} - string of '2 jam 3 menit', or '2 hari 1 jam', or '2 hari 2 menit'
 */
export function getTextDateTimeDuration(
  startDate,
  endDate,
  attrs,
  maxChunk = 3,
) {
  const durationLeft = moment.duration(
    moment(endDate).diff(startDate),
    'milliseconds',
  );
  const textArr = [];
  if (
    durationLeft.years() &&
    attrs.indexOf(K_SHORTHAND_YEAR) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.years()} tahun`);
  }
  if (
    durationLeft.months() &&
    attrs.indexOf(K_SHORTHAND_MONTH) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.months()} bulan`);
  }
  if (
    durationLeft.weeks() &&
    attrs.indexOf(K_SHORTHAND_WEEK) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.weeks()} minggu`);
  }
  if (
    durationLeft.days() &&
    attrs.indexOf(K_SHORTHAND_DAY) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.days()} hari`);
  }
  if (
    durationLeft.hours() &&
    attrs.indexOf(K_SHORTHAND_HOUR) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.hours()} jam`);
  }
  if (
    durationLeft.minutes() &&
    attrs.indexOf(K_SHORTHAND_MINUTE) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.minutes()} menit`);
  }
  if (
    durationLeft.seconds() &&
    attrs.indexOf(K_SHORTHAND_SECOND) > -1 &&
    textArr.length < maxChunk
  ) {
    textArr.push(`${durationLeft.seconds()} detik`);
  }
  return textArr.join(' ');
}

/**
 * To parse date and create countdown text string from now based on day unit
 * @param {(String/moment) of dateTime} dateExpired
 * @return {String} - string of '14 hari lagi'
 */
export function getTextDurationDayLeft(dateExpired) {
  const startDate = momentId().format('LL');
  const endDate = momentId(dateExpired);

  if (startDate >= endDate) return null;

  const duration = endDate.diff(startDate, 'days');

  return `${duration} hari lagi`;
}

export function getDurationTextInYearAndMonth(durationInMonth) {
  const durationTexts = [];
  const year = Math.floor(durationInMonth / 12);
  if (year) {
    durationTexts.push(`${year} tahun`);
  }

  const month = durationInMonth % 12;
  if (month) {
    durationTexts.push(`${month} bulan`);
  }

  return durationTexts.join(' ');
}

export function getDurationTextInDayHourMinuteAndSecond(durationInSeconds) {
  const duration = momentDuration(durationInSeconds, 'seconds');

  const numOfDays = Math.floor(duration.asDays());
  const durationTextHourMinuteAndSecond = momentUtc(
    duration.asMilliseconds(),
  ).format('HH:mm:ss');

  if (numOfDays > 0) {
    const durationTextDay = `${numOfDays.toString()} hari`;
    return `${durationTextDay} ${durationTextHourMinuteAndSecond}`;
  }

  return durationTextHourMinuteAndSecond;
}

export function addDaysWithHoliday({
  startDate,
  daysToAdd,
  holidayDict,
  isWeekendTreatedAsHoliday,
}) {
  const resultDate = new Date(startDate);

  let count = 0;

  while (count < daysToAdd) {
    resultDate.setDate(resultDate.getDate() + 1);
    const month = `0${resultDate.getMonth() + 1}`.slice(-2);
    const date = `0${resultDate.getDate()}`.slice(-2);
    const dayInWeek = resultDate.getDay();

    const dateString = `${resultDate.getFullYear()}-${month}-${date}`;
    const isWeekend = dayInWeek === 0 || dayInWeek === 6;

    if (
      !holidayDict[dateString] &&
      (!isWeekend || !isWeekendTreatedAsHoliday)
    ) {
      count += 1;
    }
  }

  return resultDate;
}

export function convertDateToAnotherTimezone(date, timezone) {
  return new Date(date).toLocaleString('en-US', {
    timeZone: timezone,
  });
}
