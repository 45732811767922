import React, { memo, useMemo } from 'react';
import DocumentMeta from 'react-document-meta';

const NotFoundPageMeta: React.FC = memo(() => {
  const link = useMemo(
    () => ({
      rel: {
        canonical: null,
      },
    }),
    [],
  );

  return (
    <DocumentMeta
      title={null}
      description={null}
      canonical={null}
      link={link}
      extend
    />
  );
});

export default NotFoundPageMeta;
