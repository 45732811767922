import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import union from 'lodash/union';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Animated, Easing } from 'react-native';
import { connect } from 'react-redux';

import { createSearchAdapter } from '@dkrm/algolia-adapter';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles from '@dkrm/general-libs/theme/styles';
import { SearchResultsMeta } from '@dkrm/storefront-commons/components/_abstractComponents/MetaManager';
import {
  K_ROUTE_CAMPAIGNS,
  K_ROUTE_PRODUCT_HOMEPAGE,
} from '@dkrm/storefront-commons/routes/constants';
import {
  ActivityIndicator,
  Colors,
  FilterGroup,
  FlatList,
  HorizontalDivider,
  MessageDialog,
  Pagination,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from '@dkrm/ui-kit-basic';
import {
  HeadComponent,
  HeadContainer,
  HeadroomProvider,
  ScrollableComponent,
} from '@dkrm/ui-kit-basic/Headroom';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import colors from '@dkrm/ui-kit-basic/colors';

import {
  HFR_KEY,
  initialState,
} from 'app-libs/AlgoliaAdapter/searchStates/productSearchStates';
import { generateUrlFromState } from 'app-libs/AlgoliaAdapter/urlSerializers/ProductSearchUrlSerializer';
import {
  CheckBoxFilterItem,
  ExtraFilter,
  FilterList,
  HierarchicalFacetFilter,
  PopupFilterListv2,
  SearchProvider,
} from 'app-libs/SearchKit/components';
import ContextualFilterButton from 'app-libs/SearchKit/components/filters/ContextualFilterButton';
import QuickFilterGroup from 'app-libs/SearchKit/components/filters/QuickFilterGroup';
import Breadcrumb from 'app-libs/components/Breadcrumb';
import CategoryCard from 'app-libs/components/CategoryCard/index';
import BestSellerProduct from 'app-libs/components/Product/BestSellerProduct';
import ProductCard, {
  K_DUMMY_PRODUCT,
  K_DUMMY_PRODUCT_STYLE,
} from 'app-libs/components/Product/ProductCard';
import { Separator } from 'app-libs/components/Product/ProductDetail/Shared';
import SortButton from 'app-libs/components/storefront/mobile/SortButton';
import { makeParametricUrl, slugify, unslugify } from 'app-libs/etc';
import {
  getBreadcrumbItemsFromCategory,
  getBreadcrumbRightMost,
  getMaterialFromSearchParameters,
  getPatternFromSearchParameters,
} from 'app-libs/etc/categoryHelper';
import { getUrlWithOverridenQuery } from 'app-libs/etc/routeHelper';
import { getSearch } from 'app-libs/redux/selector';
import { load as loadAuth } from 'app-libs/redux_modules/auth';
import {
  createWishlistAlbumPin,
  getWishlistAlbumPins,
  removePinFromAlbum,
} from 'app-libs/redux_modules/entity_modules/album';
import * as albumSelectors from 'app-libs/redux_modules/entity_modules/selectors/album';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import * as searchActions from 'app-libs/redux_modules/flow_modules/search';
import * as authSelectors from 'app-libs/redux_modules/selectors/auth';
import {
  trackClickBreadcrumbCategory,
  trackClickedLookSimilarProducts,
  trackClickedProductCell,
  trackClickedWishlistPin,
  trackViewedProductList,
} from 'app-libs/trackers';

import CampaignBanner from 'components/CampaignBanner';
import { Footer } from 'components/Footer';
import LocationNavigation from 'components/LocationNavigation';
import { ProductListStructuredData } from 'components/_abstractComponents/GoogleStructuredData';
import BlockNavigationRoute from 'components/commons/BlockNavigationRoute';
import ProductListCategoryCard from 'components/home/shared/components/ProductListCategoryCard';

import {
  K_JAPANDI,
  K_WISHLIST_BUSINESS_UNIT_MARKETPLACE,
} from 'constants/productConstants';
import { K_TRACKING_PLACEMENT_PRODUCT_CARD } from 'constants/trackingConstants';

import {
  getBestSellerProductSearchState,
  getCategoryAdsLocation,
  getFacetStatsDeriveFunction,
} from '../utils';
import { K_EXCLUDE_FILTERS, K_QUICK_FILTER_FACETS } from './constants';
import styles from './styles';

const K_MAX_SCROLL_ATTEMPT = 4;
const K_ANIMATION_DURATION = 200;
const K_DEFAULT_WIDTH = 375;
const K_ACCEPTABLE_SCROLLING_OFFSET = 12;
const K_NUM_COLUMNS = 2;

@withRouter
@connect((state) => ({
  search: getSearch(state),
  dimension: state.dimension,
  colorSiblingsByUpc: state.product.colorSiblingsByUpc,

  isAuthLoaded: authSelectors.isAuthLoaded(state),
  pins: albumSelectors.getPins(state),
  pinByObjectId: albumSelectors.getPinByObjectId(state),
  shouldLoadPins:
    !albumSelectors.isPinsLoaded(state) &&
    !albumSelectors.getPinsLoading(state),
  user: authSelectors.getAuthUser(state),
  isLoggedIn: authSelectors.isLoggedIn(state),
  lastVisitedOffset: state.search.lastVisitedOffset || 0,
}))
export default class ProductSearch extends Component {
  static propTypes = {
    search: PropTypes.shape({
      searchIndex: PropTypes.string,
      searchParameters: PropTypes.shape().isRequired,
    }).isRequired,
    searchAdapter: PropTypes.shape({
      handleChange: PropTypes.func,
      isLoading: PropTypes.bool,
      getData: PropTypes.func,
      getPage: PropTypes.func,
      getState: PropTypes.func,
      getNumberOfHits: PropTypes.func,
      getQuery: PropTypes.func,
      search: PropTypes.func,
      setDisjunctiveFacetRefinement: PropTypes.func,
      setIndex: PropTypes.func,
      setState: PropTypes.func,
      helper: PropTypes.shape(),
      subscribe: PropTypes.func,
      unsubscribe: PropTypes.func,
    }).isRequired,
    searchResult: PropTypes.shape({
      hits: PropTypes.arrayOf(PropTypes.shape()),
      index: PropTypes.string,
      page: PropTypes.number,
      nbPages: PropTypes.number,
      query: PropTypes.string,
      nbHits: PropTypes.number,
    }).isRequired,
    dimension: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    params: PropTypes.shape({
      areaSlug: PropTypes.string,
      querySlug: PropTypes.string,
      bslug: PropTypes.string,
      style: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape().isRequired,
    colorSiblingsByUpc: PropTypes.shape().isRequired,
    pins: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    pinByObjectId: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    shouldLoadPins: PropTypes.bool.isRequired,
    isAuthLoaded: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    baseUrl: PropTypes.string,
    lastVisitedOffset: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    queries: PropTypes.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    advertisements: PropTypes.shape().isRequired,
    HeaderInsertComponent: PropTypes.element,
    HeaderBottomComponent: PropTypes.element,
    derivedSearchAdapters: PropTypes.Array(PropTypes.shape()),
    overrideTitleBreadcrumbAndLevel: PropTypes.shape({
      level: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      listTitle: PropTypes.string.isRequired,
      breadcrumb: PropTypes.string.isRequired,
    }),
    genBreadcrumbs: PropTypes.func,
    additionalListProps: PropTypes.shape(),
    shouldDisableAutoRefresher: PropTypes.bool,
    shouldHideQuickFilter: PropTypes.bool,
    shouldHideFloatingSortAndFilter: PropTypes.bool,
    shouldHideSeparator: PropTypes.bool,
    shouldHideTitle: PropTypes.bool,
    shouldHideCampaignBannerSlider: PropTypes.bool,
    shouldHideHierarchicalFacetFilter: PropTypes.bool,
    renderSEOComponent: PropTypes.func,
    renderBlogInternalLinkingComponent: PropTypes.func,

    shouldUseJapandiCategoryPage: PropTypes.bool,
  };

  static defaultProps = {
    baseUrl: '',
    HeaderInsertComponent: undefined,
    HeaderBottomComponent: undefined,
    derivedSearchAdapters: [],
    overrideTitleBreadcrumbAndLevel: undefined,
    genBreadcrumbs: undefined,
    additionalListProps: {},
    shouldDisableAutoRefresher: false,
    shouldHideQuickFilter: false,
    shouldHideFloatingSortAndFilter: false,
    shouldHideSeparator: false,
    shouldHideTitle: false,
    shouldHideCampaignBannerSlider: false,
    shouldHideHierarchicalFacetFilter: false,
    renderSEOComponent: null,
    renderBlogInternalLinkingComponent: null,

    shouldUseJapandiCategoryPage: false,
  };

  constructor(props) {
    super(props);
    this.scrollViewRef = React.createRef();

    this.state = {
      modalOpened: null,
      avgRating: 0,
      bestSellerProducts: [],
      slideTranslation: new Animated.Value(0),
      isPreviouslyScrollingDown: false,
      categoryBreadcrumb: this.getBreadcrumb(),
    };

    this.trackViewedProductListWithDebounce = debounce(
      this.trackProductViewListAfterUpdate,
      1000,
    );

    this.flatListRef = React.createRef();
    this.scrollOffset = 0;
    this.bestSellerProductSearchAdapter = createSearchAdapter();
    this.bestSellerProductSearchAdapter.setState(
      getBestSellerProductSearchState(props?.location?.pathname),
    );
    this.facetStatsSearchAdapter = createSearchAdapter();
  }

  componentDidMount() {
    const {
      dispatch,
      searchAdapter,
      isAuthLoaded,
      shouldLoadPins,
      derivedSearchAdapters,
    } = this.props;
    const { bestSellerProductSearchAdapter } = this;
    this.trackViewedProductListWithDebounce();

    /**
     * @todo David - setRouteLeaveHook is no longer supported in react router 4.
     * A new component to support hooking is needed
     * */
    // const { route } = this.props;
    // this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
    if (!isAuthLoaded) {
      dispatch(loadAuth((_, err, user) => this.loadWishlistPins(user)));
    } else if (shouldLoadPins) {
      const { user } = this.props;
      this.loadWishlistPins(user);
    }
    this.timeoutScrollToProduct = setTimeout(
      () => this.actionScrollToLastVisitedProductWithTimeout(0),
      1000,
    );

    searchAdapter.subscribe('result', this.onSearchAdapterSearchResult);
    bestSellerProductSearchAdapter.subscribe(
      'result',
      this.onBestSellerProductSearchResult,
    );

    this.actionBuildDerivedAdapters(bestSellerProductSearchAdapter);

    const [facetSearchAdapter, ...otherDerivedSearchAdapters] =
      bestSellerProductSearchAdapter.derivedAdapters;

    if (facetSearchAdapter) {
      facetSearchAdapter.subscribe('result', this.onFacetStatsSearchResult);
    }

    otherDerivedSearchAdapters.forEach((derivedSearchAdapter, index) =>
      derivedSearchAdapter.subscribe(
        'result',
        derivedSearchAdapters[index].onResult,
      ),
    );

    bestSellerProductSearchAdapter.search({
      shouldBuildDisjunctiveRefinementList: false,
      shouldBuildHierarchicalRefinementList: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, derivedSearchAdapters } = this.props;
    const { categoryBreadcrumb } = this.state;
    const { bestSellerProductSearchAdapter } = this;
    /** close modal on route change */
    if (location !== prevProps.location) {
      this.closeModal();
      this.scrollToTop();
    }

    if (prevState.categoryBreadcrumb !== categoryBreadcrumb) {
      bestSellerProductSearchAdapter.resetDerivedAdapters();
      if (this.facetStatsSearchAdapter) {
        this.facetStatsSearchAdapter.resetPage();
      }
      derivedSearchAdapters.forEach(({ onCategoryChange }) =>
        onCategoryChange(),
      );
      this.actionBuildDerivedAdapters(bestSellerProductSearchAdapter);
      bestSellerProductSearchAdapter.search({
        shouldBuildDisjunctiveRefinementList: false,
        shouldBuildHierarchicalRefinementList: false,
      });
    }
  }

  componentWillUnmount() {
    const { searchAdapter, derivedSearchAdapters } = this.props;
    const { bestSellerProductSearchAdapter } = this;
    clearTimeout(this.timeoutScrollToProduct);
    searchAdapter.unsubscribe('result', this.onSearchAdapterSearchResult);
    const [facetSearchAdapter, ...otherDerivedSearchAdapters] =
      bestSellerProductSearchAdapter.derivedAdapters;

    if (facetSearchAdapter) {
      facetSearchAdapter.unsubscribe('result', this.onFacetStatsSearchResult);
    }

    otherDerivedSearchAdapters.forEach((derivedSearchAdapter, index) =>
      derivedSearchAdapter.unsubscribe(
        'result',
        derivedSearchAdapters[index].onResult,
      ),
    );

    bestSellerProductSearchAdapter.resetDerivedAdapters();
    bestSellerProductSearchAdapter.unsubscribe(
      'result',
      this.onBestSellerProductSearchResult,
    );
  }

  getBreadcrumb() {
    return this.getTitleBreadcrumbAndLevel().breadcrumb;
  }

  getTitleBreadcrumbAndLevel = () => {
    const { searchAdapter, overrideTitleBreadcrumbAndLevel } = this.props;
    const { hierarchicalFacetsRefinements, query } = searchAdapter.getState();
    const hfr = hierarchicalFacetsRefinements[HFR_KEY];

    if (overrideTitleBreadcrumbAndLevel) return overrideTitleBreadcrumbAndLevel;

    if (query && !hfr) {
      return {
        level: 0,
        title: `Hasil Pencarian: ${query}`,
        listTitle: `Semua Pencarian "${query}"`,
        breadcrumb: '',
      };
    }

    if (!hfr) {
      return {
        level: 0,
        title: 'Cari di Semua Produk',
        listTitle: 'Semua Produk',
        breadcrumb: '',
      };
    }

    const breadcrumb = hfr[0];
    const title = getBreadcrumbRightMost(breadcrumb);
    return {
      level: breadcrumb.split('>').length,
      title,
      listTitle: `Semua ${title}`,
      breadcrumb,
    };
  };

  getFacetLabel = (facetName) => {
    const facetLabels = {
      sort: 'Sort',
      filter: 'Filter',
    };

    return facetLabels[facetName] || facetName;
  };

  getFilterConfig = (facetName) => {
    const { modalOpened } = this.state;
    const config = {
      label: this.getFacetLabel(facetName),
      name: facetName || 'Unknown',
      listComponent: PopupFilterListv2,
      itemComponent: CheckBoxFilterItem,
      listProps: {
        isModalOpen: modalOpened === facetName,
        onRequestOpen: () => {
          return this.openModal(facetName);
        },
        onRequestClose: () => {
          return this.closeModal();
        },
      },
      facetStatsSearchAdapter: this.facetStatsSearchAdapter,
    };

    return config;
  };

  getCategoryUrl = (category) => {
    const {
      searchAdapter,
      params: { areaSlug = '' },
      shouldUseJapandiCategoryPage,
    } = this.props;
    const mergedState = Object.assign({}, searchAdapter.getState(), {
      hierarchicalFacetsRefinements: {
        [HFR_KEY]: [category],
      },
      page: 0,
    });
    if (shouldUseJapandiCategoryPage) {
      return `${makeParametricUrl('/:lvl0', {
        lvl0: slugify(category),
      })}-${K_JAPANDI}`;
    }
    if (areaSlug) {
      return makeParametricUrl('/:lvl0/:areaSlug', {
        areaSlug,
        lvl0: slugify(category),
      });
    }
    return generateUrlFromState(mergedState);
  };

  loadWishlistPins = (user) => {
    const { dispatch } = this.props;
    if (!isEmpty(user)) {
      dispatch(
        dontShowPromptOnError(
          getWishlistAlbumPins(user.uuid, K_WISHLIST_BUSINESS_UNIT_MARKETPLACE),
        ),
      );
    }
  };

  /** prevent route from leaving when modal exists
   * instead, close the modal
   */
  // eslint-disable-next-line react/sort-comp
  // routerWillLeave = () => {
  //   const { location, history } = this.props;
  //   const { modalOpened } = this.state;
  //   const currentPathname = location.pathname;
  //   if (modalOpened) {
  //     this.closeModal();

  //     /** workaround for <Route> change bug:
  //      * since route will still leave to the next path,
  //      * when path is resolved to other <Route> component
  //      * we should move the path back to the current path
  //      */
  //     const nextPathname = history.getCurrentLocation().pathname;
  //     if (nextPathname !== currentPathname) {
  //       history.goForward();
  //     }

  //     return false; // cancel routing
  //   }
  // };

  trackProductViewListAfterUpdate() {
    const { searchResult, history } = this.props;
    const { hits, query, index, page, nbPages } = searchResult;
    const { breadcrumb } = this.getTitleBreadcrumbAndLevel();
    const fullPath = `${history?.location?.pathname}${history?.location?.search}`;

    trackViewedProductList({
      products: hits || [],
      query,
      breadcrumb,
      index,
      page,
      nbPages,
      url: fullPath,
    });
  }

  genBreadcrumbs = () => {
    const {
      searchAdapter,
      baseUrl,
      genBreadcrumbs,
      history,
      params: { areaSlug = '' },
      shouldUseJapandiCategoryPage,
    } = this.props;
    const pathname = get(history, 'location.pathname', '');
    const { query } = searchAdapter.getState();
    const titleBreadcrumbAndLevel = this.getTitleBreadcrumbAndLevel();
    const breadcrumbs = [];
    breadcrumbs.push({
      name: 'Beranda',
      path: baseUrl + K_ROUTE_PRODUCT_HOMEPAGE,
      doNotAddQueryParameters: true,
    });
    if (query) {
      breadcrumbs.push({
        name: query,
        path: pathname,
        doNotAddQueryParameters: true,
      });
      breadcrumbs[breadcrumbs.length - 1].doNotUseHyperlink = true;
      return breadcrumbs;
    }

    if (genBreadcrumbs) {
      breadcrumbs.push(
        ...genBreadcrumbs(titleBreadcrumbAndLevel.breadcrumb, {
          doNotAddQueryParameters: true,
        }),
      );
      breadcrumbs[breadcrumbs.length - 1].doNotUseHyperlink = true;
      return breadcrumbs;
    }

    const categoryBreadcrumbs = getBreadcrumbItemsFromCategory(
      titleBreadcrumbAndLevel.breadcrumb,
      baseUrl,
      { doNotAddQueryParameters: true },
    );

    breadcrumbs.push(...categoryBreadcrumbs);

    // Japandi
    if (shouldUseJapandiCategoryPage) {
      breadcrumbs.push({
        name: K_JAPANDI,
        pathname,
        doNotAddQueryParameters: true,
      });
    }

    // Area Breadrumb
    if (areaSlug) {
      breadcrumbs.push({
        name: unslugify(areaSlug),
        pathname,
        doNotAddQueryParameters: true,
      });
    }

    // @note(dika) 4 Nov 2024: Unlink last breadcrumb
    breadcrumbs[breadcrumbs.length - 1].doNotUseHyperlink = true;

    return breadcrumbs;
  };

  openModal = (facetName) => this.setState({ modalOpened: facetName });

  closeModal = () => this.setState({ modalOpened: null });

  shouldBlockNavigation = (location) => {
    if (!location) return false;
    return !!location.search;
  };

  handleApplyFilter = () => {
    const { searchAdapter } = this.props;
    searchAdapter.handleChange({ shouldResetPage: true });
  };

  handleDisjunctiveChange = (facetName, value, doSearch = true) => {
    const { searchAdapter } = this.props;
    const newState = searchAdapter.getState();
    newState.disjunctiveFacetsRefinements[facetName] = value;
    if (doSearch) {
      searchAdapter.setState(newState);
      searchAdapter.search();
    }
  };

  handleOnPress = (facetName, value) => {
    this.handleDisjunctiveChange(facetName, value);
    this.handleApplyFilter();
  };

  handleNumericalChange = (facetName, value, doSearch = true) => {
    const { searchAdapter } = this.props;
    const newState = searchAdapter.getState();
    newState.numericRefinements[facetName] = {};
    if (value['>=']) {
      newState.numericRefinements[facetName]['>='] = [value['>=']];
    }
    if (value['<=']) {
      newState.numericRefinements[facetName]['<='] = [value['<=']];
    }
    if (doSearch) {
      searchAdapter.setState(newState);
      searchAdapter.search();
    }
  };

  handleSortChange = (indexName) => {
    const { searchAdapter } = this.props;
    searchAdapter.setIndex(indexName[0]);
    this.setState({ modalOpened: null });
  };

  onFacetStatsSearchResult = (facetStatsResult) => {
    this.facetStatsSearchAdapter.setResult(facetStatsResult);
  };

  onBestSellerProductSearchResult = (result) => {
    this.setState({
      bestSellerProducts: result.hits,
    });
  };

  onSearchAdapterSearchResult = () => {
    this.trackViewedProductListWithDebounce();
    this.setState({
      categoryBreadcrumb: this.getBreadcrumb(),
    });
  };

  actionBuildDerivedAdapters(searchAdapter) {
    const { location, derivedSearchAdapters } = this.props;
    const { categoryBreadcrumb } = this.state;

    searchAdapter.setState(getBestSellerProductSearchState(location?.pathname));
    searchAdapter.derive({}, getFacetStatsDeriveFunction(categoryBreadcrumb));

    derivedSearchAdapters.forEach(({ props, deriveFunc }) =>
      searchAdapter.derive(props, deriveFunc),
    );
  }

  animateScrolling = (direction) => {
    const { slideTranslation } = this.state;
    const animationSlide = Animated.timing(slideTranslation, {
      toValue: direction,
      easing: Easing.out(Easing.poly(4)),
      duration: K_ANIMATION_DURATION,
    });

    this.setState({ isPreviouslyScrollingDown: direction }, () => {
      requestAnimationFrame(() => animationSlide.start());
    });
  };

  animateScrollingDown = () => {
    this.animateScrolling(1);
  };

  animateScrollingUp = () => {
    this.animateScrolling(0);
  };

  handleScroll = ({ nativeEvent }) => {
    const { isPreviouslyScrollingDown } = this.state;
    const scrollY = get(nativeEvent, 'contentOffset.y', 0);
    const delta = scrollY - this.scrollOffset;
    const isScrollingDown = delta >= K_ACCEPTABLE_SCROLLING_OFFSET;
    const isScrollingUp = delta <= -K_ACCEPTABLE_SCROLLING_OFFSET;
    this.scrollOffset = scrollY;

    if (isPreviouslyScrollingDown && delta > 0) {
      return;
    }
    if (!isPreviouslyScrollingDown && delta < 0) {
      return;
    }

    if (isScrollingDown) {
      requestAnimationFrame(() => this.animateScrollingDown());
    } else if (isScrollingUp) {
      requestAnimationFrame(() => this.animateScrollingUp());
    }
  };

  scrollToTop = () => {
    const flatListNode = this.flatListRef.current;
    if (flatListNode) {
      flatListNode.scrollToOffset({
        offset: 0,
        animated: false,
      });
    }
  };

  actionScrollToLastVisitedProductWithTimeout = (attempt) => {
    const { lastVisitedOffset } = this.props;
    if (attempt > K_MAX_SCROLL_ATTEMPT || !lastVisitedOffset) {
      clearTimeout(this.timeoutScrollToProduct);
    } else {
      this.actionScrollToLastVisitedProduct();
      this.timeoutScrollToProduct = setTimeout(() => {
        this.actionScrollToLastVisitedProductWithTimeout(attempt + 1);
      }, 1000);
    }
  };

  actionScrollToLastVisitedProduct = () => {
    const { history, lastVisitedOffset } = this.props;
    const hasProducts = this.getProductList().length;
    const isFromBack = history.action === 'POP';
    if (hasProducts && isFromBack) {
      this.scrollToOffset(lastVisitedOffset);
    }
  };

  scrollToOffset = (offset) => {
    const { dispatch } = this.props;
    const flatListNode = this.flatListRef.current;
    if (flatListNode) {
      try {
        flatListNode.scrollToOffset({ offset, animated: false });
        /** Reset lastVisitedOffset */
        dispatch(searchActions.updateLastVisitedOffset(0));
      } catch (e) {
        /** Suppress error on scrolling for initial renders */
      }
    }
  };

  setClickedProductOffset = (offset) => {
    const { dispatch } = this.props;
    dispatch(searchActions.updateLastVisitedOffset(offset));
  };

  handleItemPress = (item, index) => {
    const { history, searchAdapter } = this.props;
    const clickedFrom = get(history, 'location.pathname', '');
    const sortIndex = searchAdapter.helper.getIndex();
    this.setClickedProductOffset(this.scrollOffset);
    trackClickedProductCell(
      item,
      index + 1,
      clickedFrom,
      sortIndex,
      this.usedFilter(),
    );
  };

  actionTrackPressBreadcrumb = ({ from, to }) => {
    trackClickBreadcrumbCategory(to, from);
  };

  actionToggleWishlistPin = (pinId, product) => {
    const { dispatch, user } = this.props;

    if (pinId) {
      dispatch(
        dontShowPromptOnError(
          removePinFromAlbum(user.uuid, pinId, product.objectID),
        ),
      );
    } else {
      dispatch(
        createWishlistAlbumPin(K_WISHLIST_BUSINESS_UNIT_MARKETPLACE, product),
      );
    }
    trackClickedWishlistPin(K_TRACKING_PLACEMENT_PRODUCT_CARD, !pinId, product);
  };

  usedFilter = () => {
    const { searchAdapter } = this.props;
    const searchStates = searchAdapter.getState();
    const disjunctive = get(searchStates, 'disjunctiveFacetsRefinements', {});
    const numerical = get(searchStates, 'numericRefinements', {});
    const initialDisjunctive = get(
      initialState,
      'disjunctiveFacetsRefinements',
      {},
    );
    const initialNumerical = get(initialState, 'numericRefinements', {});
    const usedDisjunctive = union(
      Object.keys(disjunctive),
      Object.keys(initialDisjunctive),
    ).filter(
      (facet) => !isEqual(disjunctive[facet], initialDisjunctive[facet]),
    );
    const usedNumerical = union(
      Object.keys(numerical),
      Object.keys(initialNumerical),
    ).filter((facet) => !isEqual(numerical[facet], initialNumerical[facet]));
    const usedFilter = [...usedDisjunctive, ...usedNumerical];
    return {
      isFilterUsed: !isEmpty(usedFilter),
      usedFilter,
    };
  };

  renderEmptyProduct = () => {
    const { history } = this.props;
    return (
      <TouchableOpacity
        onPress={() =>
          actionRouteActionOpenURL(K_ROUTE_PRODUCT_HOMEPAGE, history)
        }
        style={styles.seeAllCatButton}
      >
        <Text theme="body2-white">Lihat Semua Kategori</Text>
      </TouchableOpacity>
    );
  };

  renderItem = ({ item, index }) => {
    const {
      dimension: { width = K_DEFAULT_WIDTH },
      pinByObjectId,
      colorSiblingsByUpc,
      isLoggedIn,
      baseUrl,
      history,
      searchAdapter,
    } = this.props;
    const clickedFrom = get(history, 'location.pathname', '');
    const sortIndex = searchAdapter.helper.getIndex();
    if (item === K_DUMMY_PRODUCT) return <View style={K_DUMMY_PRODUCT_STYLE} />;
    const totalHorizontalMargin = 2 * 2 * 4 + 2 * 12;
    const cardWidth = (width - totalHorizontalMargin) / 2;
    return (
      <ProductCard
        product={item}
        colorSiblings={colorSiblingsByUpc[item.objectID]}
        width={cardWidth}
        containerStyle={styles.productCellContainer}
        baseUrl={baseUrl}
        pinByObjectId={pinByObjectId}
        pinId={get(pinByObjectId, `${item.objectID}.pk`, null)}
        toggleWishlistPin={this.actionToggleWishlistPin}
        isLoggedIn={isLoggedIn}
        history={history}
        onClickSimilarProducts={() =>
          trackClickedLookSimilarProducts(
            item,
            index + 1,
            clickedFrom,
            sortIndex,
          )
        }
        onPress={() => this.handleItemPress(item, index)}
      />
    );
  };

  renderLoading() {
    return (
      <View style={cStyles.paxxl}>
        <ActivityIndicator />
      </View>
    );
  }

  renderCategoryCard = (props) => {
    return (
      <ProductListCategoryCard
        linkUrl={this.getCategoryUrl(props.label)}
        {...props}
      />
    );
  };

  renderHierarchicalFacetFilter = () => {
    const {
      baseUrl,
      searchAdapter,
      additionalListProps,
      shouldUseJapandiCategoryPage,
    } = this.props;
    const { level, title } = this.getTitleBreadcrumbAndLevel();
    const page = searchAdapter.getPage();
    const numColumns = K_NUM_COLUMNS;
    const listProps = {
      numColumns,
      title,
      page,
      listStyle: cStyles.mhs,
      shouldShowListTitle: false,
      ...additionalListProps,
    };
    const { isFilterUsed } = this.usedFilter();
    if (!isFilterUsed || shouldUseJapandiCategoryPage) {
      return (
        <HierarchicalFacetFilter
          listComponent={FilterList}
          listProps={listProps}
          listStyle={styles.hierarchicalContainer}
          baseUrl={baseUrl}
          name={HFR_KEY}
          level={level + 1}
          labelStyle={{ color: Colors.C_BLACK_100, fontSize: 16 }}
          itemComponent={this.renderCategoryCard}
        />
      );
    }
    return null;
  };

  renderBreadcrumbs() {
    return (
      <View style={styles.breadCrumbContainer}>
        <ScrollView horizontal ref={this.scrollViewRef}>
          <Breadcrumb
            breadcrumbs={this.genBreadcrumbs()}
            containerStyle={styles.breadCrumb}
            textTheme="caption2-black60"
            iconSize={20}
            onItemPressed={this.actionTrackPressBreadcrumb}
          />
        </ScrollView>
      </View>
    );
  }

  renderProductTypeItem = (props) => (
    <CategoryCard
      {...props}
      labelStyle={{
        ...cStyles.fwn,
        color: colors.C_BLACK_100,
        fontSize: 14,
        lineHeight: 20,
      }}
      containerStyle={{
        backgroundColor: colors.C_BLACK_5,
        borderColor: colors.C_BLACK_5,
        minHeight: 32,
        minWidth: 64,
      }}
    />
  );

  renderHeader = () => {
    const {
      searchResult: { page },
      params: { areaSlug = '', querySlug = '' },
      searchAdapter,
      location: { pathname },
      HeaderInsertComponent,
      HeaderBottomComponent,
      shouldHideSeparator,
      shouldHideTitle,
      shouldHideCampaignBannerSlider,
      shouldHideHierarchicalFacetFilter,
      shouldUseJapandiCategoryPage,
    } = this.props;
    const { bestSellerProducts } = this.state;

    const { level, title, breadcrumb } = this.getTitleBreadcrumbAndLevel();
    const disjunctive = get(
      searchAdapter.getState(),
      'disjunctiveFacetsRefinements',
      {},
    );

    // @note(dika) 9 June 2023: Add Japandi in title for URL
    // /Furniture-Japandi so that will be Kategori Furniture Japandi
    const additionalJapandiTitle = shouldUseJapandiCategoryPage
      ? ' Japandi'
      : '';
    const additionalPageTitle = page > 0 ? ` - Halaman ${page + 1}` : '';

    const shouldShowSeparator =
      searchAdapter.getNumberOfHits() > 0 &&
      !shouldHideTitle &&
      !shouldHideSeparator;

    let shownTitle = title;
    if (querySlug) {
      shownTitle = `${title}${additionalPageTitle}`;
    } else if (level < 3) {
      shownTitle = `Kategori ${title}${additionalJapandiTitle} ${unslugify(
        areaSlug,
      )}${additionalPageTitle}`;
    } else {
      shownTitle = `${title}${additionalJapandiTitle} ${unslugify(
        areaSlug,
      )}${additionalPageTitle}`;
    }

    if (pathname === K_ROUTE_CAMPAIGNS) {
      return null;
    }
    return (
      <>
        {!shouldHideCampaignBannerSlider && (
          <CampaignBanner
            location={getCategoryAdsLocation(
              `${title}${additionalJapandiTitle}`,
            )}
          />
        )}
        {this.renderBreadcrumbs()}
        {!!title && !shouldHideTitle && (
          <Text h1 theme="h4" style={styles.title}>
            {shownTitle}
          </Text>
        )}
        {page === 0 && HeaderInsertComponent && <HeaderInsertComponent />}
        {!shouldHideHierarchicalFacetFilter &&
          this.renderHierarchicalFacetFilter()}
        <ExtraFilter
          breadcrumb={breadcrumb}
          disjunctive={disjunctive}
          handleOnPress={this.handleOnPress}
          title={title}
        />
        {page === 0 && (
          <BestSellerProduct
            breadcrumb={breadcrumb}
            products={bestSellerProducts}
          />
        )}
        {page === 0 && HeaderBottomComponent && <HeaderBottomComponent />}

        {/** Super quickfix, someone must refactor this components soon */}
        {shouldShowSeparator && <Separator wide style={cStyles.mbxxl} />}
      </>
    );
  };

  renderFooter = () => {
    const { searchResult, location, renderBlogInternalLinkingComponent } =
      this.props;
    const { page, nbPages } = searchResult;
    return (
      <View style={[cStyles.flexMiddle, cStyles.pvxxl]}>
        <Pagination
          nearActivePageCount={1}
          lastPage={nbPages}
          activePage={page + 1}
          currentUrl={location.pathname}
          search={location.search}
          onPageChange={this.scrollToTop}
        />
        {!!renderBlogInternalLinkingComponent &&
          renderBlogInternalLinkingComponent()}
        <Footer />
      </View>
    );
  };

  renderProductSearchSEO() {
    const {
      searchResult,
      location,
      search,
      renderSEOComponent,
      params: { areaSlug = '', style = '' },
    } = this.props;
    const { breadcrumb, title } = this.getTitleBreadcrumbAndLevel();
    const { searchParameters } = search;
    const material = getMaterialFromSearchParameters(searchParameters);
    const pattern = getPatternFromSearchParameters(searchParameters);
    if (renderSEOComponent) {
      return renderSEOComponent();
    }
    return (
      <div style={{ display: 'none' }}>
        <SearchResultsMeta
          pageNumber={searchResult.page}
          location={location}
          refinedFacets={breadcrumb.split(' > ').map((cat) => ({ name: cat }))}
          material={material}
          hits={searchResult.hits}
          patterns={pattern}
          areaSlug={areaSlug}
          style={style}
        />
        <ProductListStructuredData
          products={searchResult.hits}
          title={title}
          productCount={searchResult.nbHits}
        />
      </div>
    );
  }

  renderFloatingSortAndFilter() {
    const { history, shouldHideFloatingSortAndFilter } = this.props;
    const { slideTranslation } = this.state;
    const animationStyle = {
      bottom: slideTranslation.interpolate({
        inputRange: [0, 1],
        outputRange: [28, -48],
        extrapolate: 'clamp',
      }),
    };
    if (shouldHideFloatingSortAndFilter) return null;
    return (
      <Animated.View style={[animationStyle, styles.floatingSortAndFilter]}>
        <View style={[cStyles.flex1, { width: 180 }]}>
          <FilterGroup
            dividerStyle={{ marginVertical: 10 }}
            containerStyle={styles.floatingFilterContainer}
            components={[
              <ContextualFilterButton
                excludes={K_EXCLUDE_FILTERS}
                getFilterConfig={this.getFilterConfig}
                config={this.getFilterConfig('filter')}
                onDisjunctiveChange={this.handleDisjunctiveChange}
                onNumericalChange={this.handleNumericalChange}
                onApplyFilter={this.handleApplyFilter}
                initialState={initialState}
                history={history}
                isRounded
              />,
              <SortButton
                getFilterConfig={this.getFilterConfig}
                onChange={this.handleSortChange}
                isRounded
              />,
            ]}
          />
        </View>
      </Animated.View>
    );
  }

  getProductList() {
    const { searchAdapter } = this.props;
    const products = searchAdapter.getData();
    // Handling odd number of products
    if (products.length % 2 !== 0) {
      products.push(K_DUMMY_PRODUCT);
    }
    return products;
  }

  render() {
    const {
      searchAdapter,
      history,
      queries,
      location,
      loading,
      shouldHideQuickFilter,
      shouldDisableAutoRefresher,
    } = this.props;
    const { modalOpened, avgRating } = this.state;

    if (
      searchAdapter.getNumberOfHits() === 0 &&
      !modalOpened &&
      !shouldDisableAutoRefresher
    ) {
      /**
       * @workaround by Natan - auto-refresher
       * Since we have many apps running on same domain,
       * and linking to one another should use anchor (SSR redirect),
       * developer usually missed proper linking causing 404.
       *
       * Here we attempt to refresh the page,
       * so broken link that actually exists in another app will be shown.
       * This is similar to nextjs 404 behavior.
       * */
      const routeState = location.state;
      const { isRefreshed } = queries;
      // eslint-disable-next-line
      // @ts-ignore
      if (
        routeState?.shouldRefreshOn404 &&
        !isRefreshed &&
        typeof window !== 'undefined'
      ) {
        window.location.href = getUrlWithOverridenQuery(window.location.href, {
          isRefreshed: 1,
        });
        return null;
      }

      /** else return search empty state */
      return (
        <MessageDialog
          header="Tidak ditemukan"
          description="Kami tidak menemukan apapun. Coba kembali ke beranda."
          linkText="Kembali ke beranda"
          style={cStyles.paxxl}
        />
      );
    }

    /*
     * @note (Radit) 30 Nov 2023: handle searchAdapter.isLoading in the root page component
     */
    if (loading || typeof searchAdapter.getNumberOfHits() === 'undefined') {
      return this.renderLoading();
    }

    return (
      <HeadroomProvider defaultHeight={74}>
        {this.renderProductSearchSEO()}
        <SearchProvider searchAdapter={searchAdapter}>
          <HeadContainer style={{ backgroundColor: '#FFF', zIndex: 10 }}>
            <HeadComponent>
              <LocationNavigation />
            </HeadComponent>
            <HorizontalDivider color={colors.C_BLACK_15} />
            {!shouldHideQuickFilter && (
              <QuickFilterGroup
                filterFacets={K_QUICK_FILTER_FACETS}
                onDisjunctiveChange={this.handleDisjunctiveChange}
                onNumericalChange={this.handleNumericalChange}
                onApplyFilter={this.handleApplyFilter}
                initialState={initialState}
                history={history}
              />
            )}
          </HeadContainer>
          <View style={{ backgroundColor: '#FFF', zIndex: 10 }}>
            {this.renderFloatingSortAndFilter()}
          </View>
          <ScrollableComponent
            component={FlatList}
            onScroll={this.handleScroll}
            scrollEventThrottle={16}
            data={this.getProductList()}
            keyExtractor={(item) => item.objectID}
            renderItem={this.renderItem}
            numColumns={2}
            columnWrapperStyle={cStyles.mhl}
            extraData={avgRating}
            ListEmptyComponent={this.renderEmptyProduct()}
            ListHeaderComponent={this.renderHeader()}
            ListFooterComponent={this.renderFooter()}
            initialNumToRender={12}
            windowSize={24}
            maxToRenderPerBatch={12}
            ref={this.flatListRef}
          />
        </SearchProvider>
        <BlockNavigationRoute
          when={!isNull(modalOpened)}
          onBlockNavigation={this.closeModal}
          shouldBlockNavigation={this.shouldBlockNavigation}
        />
      </HeadroomProvider>
    );
  }
}
