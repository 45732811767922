import keyMirror from 'keymirror';

export const AT = keyMirror({
  SHOW_MODAL_GENERIC: null,
  HIDE_MODAL_GENERIC: null,

  DEPR_SHOW_MODAL_GENERIC: null,
  DEPR_HIDE_MODAL_GENERIC: null,

  SHOW_MODAL_AUTH: null,
  HIDE_MODAL_AUTH: null,

  RESET_MODAL: null, // Hide any kind of modals, returning to initial state
});

export const K_DEFAULT_OBJECTS_PER_PAGE = 3;

const initialState = {
  modalGenericNew: null,
  modalGeneric: null,
  modalAuth: null,
};

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Reducer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export default function modalReducer(mutableState = initialState, action = {}) {
  let state = mutableState;
  const payload = action.payload || {};
  const {
    type,
    title,
    contentNode,
    headerContent,
    width,
    showHeader,
    additionalData,
    actionCloseModalCallback,
    actionAuthSuccessCallback,
    version,

    onCloseModalHandle,
    modalWidth,

    contentContainerStyle,
    ContentComponent,

    headerTitle,
    headerTitleStyle,
    headerContainerStyle,
    HeaderComponent,

    headerTitleAlign,

    footerContainerStyle,
    FooterComponent,
  } = payload;

  switch (action.type) {
    case AT.SHOW_MODAL_GENERIC:
      state = {
        ...state,
        modalGenericNew: {
          onCloseModalHandle,
          modalWidth,

          contentContainerStyle,
          ContentComponent,

          headerTitle,
          headerTitleStyle,
          headerTitleAlign,
          headerContainerStyle,
          HeaderComponent,

          footerContainerStyle,
          FooterComponent,
        },
      };
      break;
    case AT.HIDE_MODAL_GENERIC:
      state = {
        ...state,
        modalGenericNew: initialState.modalGeneric,
      };
      break;
    case AT.DEPR_SHOW_MODAL_GENERIC:
      state = {
        ...state,
        modalGeneric: {
          title: title,
          contentNode: contentNode,
          width: width,
          showHeader,
          actionCloseModalCallback,
          version,
        },
      };
      break;
    case AT.DEPR_HIDE_MODAL_GENERIC:
      state = {
        ...state,
        modalGeneric: initialState.modalGeneric,
      };
      break;
    case AT.SHOW_MODAL_AUTH:
      state = {
        ...state,
        modalAuth: {
          type,
          title: title,
          headerContent: headerContent,
          width: width,
          showHeader,
          additionalData,
          actionAuthSuccessCallback,
        },
      };
      break;
    case AT.HIDE_MODAL_AUTH:
      state = {
        ...state,
        modalGeneric: initialState.modalGeneric,
        modalAuth: initialState.modalGeneric,
      };
      break;
    case AT.RESET_MODAL:
      state = initialState;
      break;
  }
  return state;
}

/**
 * @deprecated use showModalGeneric instead, on redux_modules/flow_modules/modal.js
 */
export function DEPR_showModalGeneric(
  title,
  contentNode,
  width,
  showHeader = true,
  actionCloseModalCallback,
) {
  return {
    type: AT.DEPR_SHOW_MODAL_GENERIC,
    payload: {
      title,
      contentNode,
      width,
      showHeader,
      actionCloseModalCallback,
    },
  };
}

export function showModalGeneric({
  modalWidth,
  onCloseModalHandle,

  // content modal
  contentContainerStyle,
  ContentComponent,

  // header modal
  headerTitle,
  headerTitleStyle,
  headerContainerStyle,
  headerTitleAlign,
  HeaderComponent,

  // footer modal
  footerContainerStyle,
  FooterComponent,
}) {
  return {
    type: AT.SHOW_MODAL_GENERIC,
    payload: {
      modalWidth,
      onCloseModalHandle,

      // content modal
      contentContainerStyle,
      ContentComponent,

      // header modal
      headerTitle,
      headerTitleStyle,
      headerContainerStyle,
      HeaderComponent,

      headerTitleAlign,

      // footer modal
      footerContainerStyle,
      FooterComponent,
    },
  };
}

/**
 *  @deprecated use hideModalGeneric() instead, on redux_modules/flow_modules/modal.js
 */
export function DEPR_hideModalGeneric() {
  return {
    type: AT.DEPR_HIDE_MODAL_GENERIC,
  };
}

export function hideModalGeneric() {
  return {
    type: AT.HIDE_MODAL_GENERIC,
  };
}

export function showModalAuth({
  type,
  title,
  headerContent,
  actionAuthSuccessCallback,
  additionalData,
}) {
  return {
    type: AT.SHOW_MODAL_AUTH,
    payload: {
      type,
      title,
      headerContent,
      actionAuthSuccessCallback,
      additionalData,
    },
  };
}

export function hideModalAuth() {
  return {
    type: AT.HIDE_MODAL_AUTH,
  };
}

export function resetModal() {
  return {
    type: AT.RESET_MODAL,
  };
}
