import assign from 'lodash/assign';
import has from 'lodash/has';
import merge from 'lodash/merge';
import { schema } from 'normalizr';

const productSchema = new schema.Entity(
  'products',
  {
    siblings: [productSchema],
  },
  { idAttribute: 'objectID' },
);
const productCollectionSchema = [productSchema];

const moodboardSchema = new schema.Entity(
  'moodboards',
  {},
  { idAttribute: 'venomExternalId' },
);

const photoRecordSchema = new schema.Entity(
  'photoRecords',
  {},
  { idAttribute: 'id' },
);

const advertisementSchema = new schema.Entity(
  'advertisements',
  {},
  { idAttribute: 'name' },
);
const advertisementCollectionSchema = [advertisementSchema];

const voucherSchema = new schema.Entity(
  'vouchers',
  {},
  { idAttribute: 'code' },
);
const voucherCollectionSchema = [voucherSchema];

const adSlotSchema = new schema.Entity(
  'adSlots',
  {},
  { idAttribute: 'objectID' },
);
const adSlotCollectionSchema = [adSlotSchema];

const categorySeoSchema = new schema.Entity(
  'categorySeos',
  {},
  { idAttribute: 'slug' },
);
const categorySeoCollectionSchema = [categorySeoSchema];

const postSchema = new schema.Entity('posts', {}, { idAttribute: 'id' });
const postCollectionSchema = [postSchema];

const orderSchema = new schema.Entity(
  'orders',
  {},
  { idAttribute: 'orderKey' },
);

const orderLineSchema = new schema.Entity(
  'orderLines',
  {},
  { idAttribute: 'id' },
);
const orderLineCollectionSchema = [orderLineSchema];

const productOptionSchema = new schema.Entity(
  'productOptions',
  {},
  {
    idAttribute: 'objectID',
  },
);
const productOptionCollectionSchema = [productOptionSchema];

const projectUserSchema = new schema.Entity(
  'users',
  {},
  { idAttribute: 'userUuid' },
);

const projectMemberRoleSchema = new schema.Entity(
  'projectMemberRoles',
  {
    member: projectUserSchema,
  },
  {
    idAttribute: 'id',
  },
);
const projectMemberRoleCollectionSchema = [projectMemberRoleSchema];

const projectFileSchema = new schema.Entity(
  'projectFiles',
  {},
  { idAttribute: 'id' },
);
const projectFileCollectionSchema = [projectFileSchema];

const projectPhaseSchema = new schema.Entity(
  'projectPhases',
  {},
  { idAttribute: 'id' },
);
const projectPhaseCollectionSchema = [projectPhaseSchema];

const projectSchema = new schema.Entity(
  'projects',
  {
    members: projectMemberRoleCollectionSchema,
    phases: projectPhaseCollectionSchema,
  },
  { idAttribute: 'projectKey' },
);

const summaryProjectSchema = new schema.Entity(
  'summaryProjects',
  {
    projects: projectSchema,
    files: projectFileCollectionSchema,
    phases: projectPhaseCollectionSchema,
  },
  {
    idAttribute: 'projectKey',
  },
);

const hotspotSchema = new schema.Entity(
  'hotspots',
  {},
  { idAttribute: 'uuid' },
);

const productReviewSchema = new schema.Entity(
  'productReview',
  {},
  { idAttribute: 'productReviewKey' },
);

const productReviewCollectionSchema = [productReviewSchema];

export const Schemas = {
  PRODUCT: productSchema,
  PRODUCT_COLLECTION: productCollectionSchema,
  PHOTO_RECORD: photoRecordSchema,
  MOODBOARD: moodboardSchema,
  POST: postSchema,
  POST_COLLECTION: postCollectionSchema,
  ORDER: orderSchema,
  ORDER_LINE: orderLineSchema,
  ORDER_LINE_COLLECTION: orderLineCollectionSchema,
  PRODUCT_OPTION: productOptionSchema,
  PRODUCT_OPTION_COLLECTION: productOptionCollectionSchema,
  ADVERTISEMENT: advertisementSchema,
  ADVERTISEMENT_COLLECTION: advertisementCollectionSchema,
  VOUCHER_COLLECTION: voucherCollectionSchema,
  AD_SLOT: adSlotSchema,
  AD_SLOT_COLLECTION: adSlotCollectionSchema,
  CATEGORY_SEO: categorySeoSchema,
  CATEGORY_SEO_COLLECTION: categorySeoCollectionSchema,
  HOTSPOT: hotspotSchema,
  PRODUCT_REVIEW: productReviewSchema,
  PRODUCT_REVIEW_COLLECTION: productReviewCollectionSchema,
  // Design And Build - Project

  SUMMARY_PROJECT: summaryProjectSchema,
  PROJECT: projectSchema,
};

const initialState = {
  products: {},
  albums: {},
  photoRecords: {},
  contests: {},
  moodboards: {},
  hotspots: {},
  posts: {},
  advertisements: {},
  categorySeos: {},
  orders: {},
  orderLines: {},
  area: {},
  users: {},
  productOptions: {},
  productReview: {},
  projects: {},
  projectFiles: {},
  projectMemberRoles: {},
  projectPhases: {},

  summaryProjects: {},

  vouchers: {},
};

/**
 * Main Reducer
 */
export default function entitiesReducer(state = initialState, action = {}) {
  if (has(action, 'result.entities')) {
    let resultState = state;
    const { entities } = action.result;

    if (has(entities, 'products')) {
      const products = { ...state.products, ...entities.products };
      resultState = Object.assign({}, resultState, { products });
    }
    if (has(entities, 'photoRecords')) {
      const photoRecords = assign(
        {},
        state.photoRecords,
        entities.photoRecords,
      );
      resultState = Object.assign({}, resultState, { photoRecords });
    }
    if (has(entities, 'moodboards')) {
      const moodboards = merge({}, state.moodboards, entities.moodboards);
      resultState = Object.assign({}, resultState, { moodboards });
    }
    if (has(entities, 'hotspots')) {
      const hotspots = merge({}, state.hotspots, entities.hotspots);
      resultState = Object.assign({}, resultState, { hotspots });
    }
    if (has(entities, 'posts')) {
      const posts = merge({}, state.posts, entities.posts);
      resultState = Object.assign({}, resultState, { posts });
    }
    if (has(entities, 'advertisements')) {
      const advertisements = merge(
        {},
        state.advertisements,
        entities.advertisements,
      );
      resultState = Object.assign({}, resultState, { advertisements });
    }
    if (has(entities, 'vouchers')) {
      const vouchers = { ...state.vouchers, ...entities.vouchers };
      resultState = Object.assign({}, resultState, { vouchers });
    }
    if (has(entities, 'adSlots')) {
      const adSlots = { ...state.adSlots, ...entities.adSlots };
      resultState = Object.assign({}, resultState, { adSlots });
    }
    if (has(entities, 'categorySeos')) {
      const categorySeos = {
        ...state.categorySeos,
        ...entities.categorySeos,
      };
      resultState = Object.assign({}, resultState, { categorySeos });
    }
    if (has(entities, 'orders')) {
      const orders = { ...state.orders, ...entities.orders };
      resultState = Object.assign({}, resultState, { orders });
    }
    if (has(entities, 'orderLines')) {
      const orderLines = merge({}, state.orderLines, entities.orderLines);
      resultState = Object.assign({}, resultState, { orderLines });
    }
    if (has(entities, 'productOptions')) {
      const productOptions = merge(
        {},
        state.productOptions,
        entities.productOptions,
      );
      resultState = Object.assign({}, resultState, { productOptions });
    }

    if (has(entities, 'users')) {
      const users = merge({}, state.users, entities.users);
      resultState = Object.assign({}, resultState, { users });
    }

    if (has(entities, 'projects')) {
      const projects = merge({}, state.projects, entities.projects);
      resultState = Object.assign({}, resultState, { projects });
    }

    if (has(entities, 'projectMemberRoles')) {
      const projectMemberRoles = {
        ...state.projectMemberRoles,
        ...entities.projectMemberRoles,
      };
      resultState = Object.assign({}, resultState, { projectMemberRoles });
    }

    if (has(entities, 'projectPhases')) {
      const projectPhases = {
        ...state.projectPhases,
        ...entities.projectPhases,
      };
      resultState = Object.assign({}, resultState, { projectPhases });
    }

    if (has(entities, 'projectFiles')) {
      const projectFiles = { ...state.projectFiles, ...entities.projectFiles };
      resultState = Object.assign({}, resultState, { projectFiles });
    }

    /** @deprecated - 23 Mei 2018 */
    if (has(entities, 'summaryProjects')) {
      const summaryProjects = merge(
        {},
        state.summaryProjects,
        entities.summaryProjects,
      );
      resultState = Object.assign({}, resultState, { summaryProjects });
    }

    if (has(entities, 'productReview')) {
      const productReview = {
        ...state.productReview,
        ...entities.productReview,
      };
      resultState = Object.assign({}, resultState, { productReview });
    }

    return resultState;
  }
  return state;
}
