import { useCallback, useState } from 'react';

import { getPlatform } from '@dkrm/general-libs/Utils/userAgentDetector';

import { compareVersions, getAppVersion } from 'app-libs/etc/appVersionHelper';

import { useLocation, useQueries } from './router';

export const useForceUpdate = (
  minAppVersionConfig: Record<string, string>,
  pathnamesShouldForceUpdate?: string[],
) => {
  const [shouldForceUpdateApp, setShouldForceUpdateApp] = useState(false);

  // @note(dika) 12 Mar 2025: temporary queries for testing purposes
  const { testingQuery, testingMinAppVersion } = useQueries();
  const { pathname } = useLocation();

  const isPathnameShouldForceUpdate =
    !pathnamesShouldForceUpdate ||
    !!pathnamesShouldForceUpdate.find((forcedPathname) =>
      pathname.startsWith(forcedPathname),
    );

  const forceUpdateAppVersionIfNecessary = useCallback(() => {
    // @note(dika) 12 Mar 2025: force update only when opening specific route paths
    if (isPathnameShouldForceUpdate && testingQuery === 'TEST_FORCE_UPDATE') {
      const appVersion = getAppVersion();
      const platform = getPlatform();
      const minimumAppVersion =
        minAppVersionConfig[platform] || testingMinAppVersion || '';
      if (!appVersion || compareVersions(minimumAppVersion, appVersion)) {
        setShouldForceUpdateApp(true);
      }
    }
  }, [
    testingQuery,
    testingMinAppVersion,
    minAppVersionConfig,
    isPathnameShouldForceUpdate,
  ]);

  const shouldShowForceUpdateModal =
    shouldForceUpdateApp && isPathnameShouldForceUpdate;

  return {
    shouldShowForceUpdateModal,
    forceUpdateAppVersionIfNecessary,
  };
};
