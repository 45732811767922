import keyMirror from 'keymirror';
import { normalize } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

import config from 'config';
import { makeSerializedParameters } from 'app-libs/etc';

import { Schemas } from '../entities';
import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

// ///////////////////// CHALLENGE REDUX MODULE ///////////////////// //

export const AT = keyMirror({
  LOAD_CATEGORY_SEOS: null,
  LOAD_CATEGORY_SEOS_SUCCESS: null,
  LOAD_CATEGORY_SEOS_FAIL: null,
});

const initialState = {
  categorySeo: { asyncState: initialAsyncState },
};

export default function categorySeoReducer(
  mutableState = initialState,
  action = {},
) {
  let state = Object.assign({}, mutableState);
  switch (action.type) {
    case AT.LOAD_CATEGORY_SEOS:
    case AT.LOAD_CATEGORY_SEOS_SUCCESS:
    case AT.LOAD_CATEGORY_SEOS_FAIL: {
      state = {
        ...state,
        categorySeo: {
          asyncState: asyncStateReducer(
            state.categorySeo.asyncState,
            action,
            '_CATEGORY_SEOS_',
          ),
        },
      };
      break;
    }
  }
  return state;
}

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Action Creator
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export function loadCategorySeos(
  hcat0 = '',
  hcat1 = '',
  hcat2 = '',
  callback = (res) => res,
) {
  const res = makeSerializedParameters({
    /* eslint-disable camelcase, @typescript-eslint/camelcase */
    hcategories_lvl0: hcat0,
    hcategories_lvl1: hcat1,
    hcategories_lvl2: hcat2,
    /* eslint-enable camelcase, @typescript-eslint/camelcase */
  });

  return {
    types: [
      AT.LOAD_CATEGORY_SEOS,
      AT.LOAD_CATEGORY_SEOS_SUCCESS,
      AT.LOAD_CATEGORY_SEOS_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_KLAW}/category-seos/?${res}&format=json`),
    options: {
      transformer: (result) => {
        // if there are no seos create dummies to mark as loaded
        if (isEmpty(result)) {
          const content = [];
          const entities = {
            categorySeos: {},
          };
          if (hcat0) {
            content.push(hcat0);
            entities.categorySeos[hcat0] = {};
          }
          if (hcat1) {
            content.push(hcat1);
            entities.categorySeos[hcat1] = {};
          }
          if (hcat2) {
            content.push(hcat2);
            entities.categorySeos[hcat2] = {};
          }
          return { content, entities };
        }

        const normalizedData = normalize(
          result,
          Schemas.CATEGORY_SEO_COLLECTION,
        );
        return {
          content: normalizedData.result,
          entities: normalizedData.entities,
        };
      },
      callback,
    },
  };
}
