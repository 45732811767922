import algoliasearchHelper, { SearchResults } from 'algoliasearch-helper';
import requestBuilder from 'algoliasearch-helper/src/requestBuilder';

import config from 'config';

/**
 * Incompatible library for react native
 */
let algoliasearch;
if (__NATIVE__) {
  algoliasearch = require('algoliasearch/reactnative');
} else {
  algoliasearch = require('algoliasearch');
}

export const {
  ALGOLIA_DEFAULT_INDEX_NAME,
  ALGOLIA_3D_ASSET_INDEX_NAME,
  ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME,
} = config;
export const ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME = 'sort_new_arrival';
export const ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME = 'sort_most_popular';

export const ALGOLIA_INDEXES_NAME = [
  ALGOLIA_DEFAULT_INDEX_NAME,
  ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME, // has default `distinct=2`
  ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME,
  ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME,
];

/**
 * Search index content, search performant with typo tollerance & everything.
 * When we need <1000 search results
 */
export function fetchSearchResults(
  searchParameters,
  searchIndex,
  APIKey = config.ALGOLIA_API_KEY,
) {
  const helper = createHelper(searchIndex, APIKey);
  const { page, hitsPerPage } = searchParameters;
  if ((page * hitsPerPage + hitsPerPage || hitsPerPage) > 1000) {
    // for more than 1000 results, we need browse. It also needs HTTPS.
    return browseSearchResults(searchParameters, searchIndex, APIKey);
  }

  helper.setState(searchParameters);
  helper.setIndex(searchIndex);
  const promise = new Promise((resolve, reject) => {
    helper.on('result', (result) => {
      /** simulate searchOnce result to prevent break
       * since the codes were previously using searchOnce */
      resolve({
        content: result,
        state: helper.getState(),
        _originalResponse: { results: result?._rawResults },
      });
    });
    helper.on('error', (err) => {
      reject(err);
    });
  });
  helper.search();

  return promise;
}

/**
 * Browse index content, when we need >1000 search results
 * It doesnt return searchParameters object like fetchSearchResults, thus return searchResults object directly
 * Therefore, to adheres to our fetchSearchResults convention, we add 'content' key
 * @return {Object} - searchResults
 * @example
 * {
 *   hits: [{
 *     objectID: ASA-1
 *     },{
 *     objectID: ASA-2
 *   }],
 *   params: 'hitsPerPage=100&query=a',
 *   cursor: 'ARdoaXRzUGVyUGFnZT0xMDAmcXVlcnk9YQEB6woGQ0FTQS0z7QEAgICAgICAgICAAQ=='
 * }
 */
export function browseSearchResults(
  searchParameters,
  searchIndex,
  APIKey = config.ALGOLIA_API_KEY,
) {
  const algolia = algoliasearch(config.ALGOLIA_APP_NAME, APIKey, {
    timeouts: {
      connect: 3000,
      read: 5000,
      write: 30000,
    },
  });

  const index = algolia.initIndex(searchIndex);
  const helper = algoliasearchHelper(algolia, searchIndex);
  Object.entries(searchParameters).map(([k, v]) => {
    helper.setQueryParameter(k, v);
  });
  const queries = requestBuilder._getQueries(searchIndex, helper.state);
  const queryParams = queries[0].params;

  return new Promise((resolve, reject) => {
    index.browse(queryParams, (err, _hits) => {
      const hits = _hits || { hits: [] };
      /**
       * AlgoliaHelper is having an error when we query 'browse' with subcategory in hierarchical facet,
       * the helper.getState() will return error & hits will return null (although results should actually exists)
       * @todo: submit bug fix to algoliahelper
       */
      let searchResults;
      try {
        searchResults = new SearchResults(helper.getState(), [
          Object.assign({}, hits, { query: '' }),
          hits,
        ]);
      } catch (e) {
        const newHelper = algoliasearchHelper(algolia, searchIndex, {
          timeouts: {
            connect: 3000,
            read: 5000,
            write: 30000,
          },
        });
        searchResults = new SearchResults(newHelper.getState(), [
          Object.assign({}, hits, { query: '' }),
          hits,
        ]);
        Object.entries(searchParameters).map(([k, v]) => {
          newHelper.setQueryParameter(k, v);
        });
      } finally {
        resolve({
          searchIndex,
          searchParameters: Object.assign({}, helper.getState()),
          content: searchResults,
        });
      }
    });
  });
}

/**
 * This method is very heavy should not be used for any ocations, but deadline
 *
 * TODO: makes this method faster & lighter
 */
export function browseSearchWithPaginationResults(
  searchParameters,
  searchIndex,
  APIKey = config.ALGOLIA_API_KEY,
) {
  return new Promise((resolve) => {
    browseAllSearchResults(searchParameters, searchIndex, APIKey).then(
      (res) => {
        const fullHits = res.content.hits;
        const { page, hitsPerPage } = searchParameters;
        resolve({
          ...res,
          content: {
            ...res.content,
            hits: fullHits.slice(
              page * hitsPerPage,
              page * hitsPerPage + hitsPerPage,
            ),
          },
        });
      },
    );
  });
}

export function browseAllSearchResults(
  searchParameters,
  searchIndex,
  APIKey = config.ALGOLIA_API_KEY,
) {
  const algolia = algoliasearch(config.ALGOLIA_APP_NAME, APIKey, {
    timeouts: {
      connect: 3000,
      read: 5000,
      write: 30000,
    },
  });

  const index = algolia.initIndex(searchIndex);
  const helper = algoliasearchHelper(algolia, searchIndex);
  Object.entries(searchParameters).map(([k, v]) => {
    helper.setQueryParameter(k, v);
  });
  const queries = requestBuilder._getQueries(searchIndex, helper.state);
  const queryParams = queries[0].params;

  const browser = index.browseAll(queryParams);
  let hits = [];
  return new Promise((resolve, reject) => {
    browser.on('result', (content) => {
      hits = hits.concat(content.hits);
    });

    browser.on('end', () => {
      const searchResults = new SearchResults(helper.getState(), [
        Object.assign({}, { hits }, { query: '' }),
        hits,
      ]);
      resolve({
        searchIndex,
        searchParameters: Object.assign({}, helper.getState()),
        content: searchResults,
      });
    });

    browser.on('error', (err) => {
      throw err;
    });
  });
}

/** attributesToRetrieve is array. can put "*" instead of array to retrieve all */
export function fetchObject(
  objectID,
  attributesToRetrieve,
  searchIndex = ALGOLIA_DEFAULT_INDEX_NAME,
  APIKey = config.ALGOLIA_API_KEY,
) {
  const algolia = algoliasearch(config.ALGOLIA_APP_NAME, APIKey, {
    timeouts: {
      connect: 3000,
      read: 5000,
      write: 30000,
    },
  });

  const index = algolia.initIndex(searchIndex);
  if (attributesToRetrieve) {
    return index.getObject(objectID, attributesToRetrieve);
  }
  return index.getObject(objectID);
}

export function fetchObjects(
  arrOfObjectIDs,
  searchIndex = ALGOLIA_DEFAULT_INDEX_NAME,
  APIKey = config.ALGOLIA_API_KEY,
  attributesToRetrieve = ['*'],
  callback,
) {
  const algolia = algoliasearch(config.ALGOLIA_APP_NAME, APIKey, {
    timeouts: {
      connect: 3000,
      read: 5000,
      write: 30000,
    },
  });

  const index = algolia.initIndex(searchIndex);
  return index.getObjects(arrOfObjectIDs, attributesToRetrieve, callback);
}

/**
 * @todo REMOVE algolia-helper
 */
export function createHelper(searchIndex, APIKey = config.ALGOLIA_API_KEY) {
  const algolia = algoliasearch(config.ALGOLIA_APP_NAME, APIKey, {
    timeouts: {
      connect: 3000,
      read: 5000,
      write: 30000,
    },
  });

  const helper = algoliasearchHelper(algolia, searchIndex);
  return helper;
}
