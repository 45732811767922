import assign from 'lodash/assign';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import qs from 'query-string';

import config from 'config';

import { AbstractUrlSerializer } from '@dkrm/algolia-adapter';
import {
  convertUrlQueriesToSearchState,
  replaceDeep,
} from '@dkrm/algolia-adapter/dist/utils';

import { initialState } from 'app-libs/AlgoliaAdapter/searchStates/productSearchStates';
import { convertStateToQueryParams } from 'app-libs/SearchKit/helper/queryParser';
import { makeParametricUrl, slugify, unslugify } from 'app-libs/etc';
import {
  getBreadcrumbFromIncompleteParams,
  getBreadcrumbRightMost,
} from 'app-libs/etc/categoryHelper';
import { getAddedDisjunctiveFacet } from 'app-libs/etc/filterHelper';
import { pathWithoutParams } from 'app-libs/etc/routeHelper';
import layout from 'app-libs/layout';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { category } from 'app-libs/serializers/category';

import {
  K_JAPANDI,
  K_MARKETPLACE_PRODUCT,
  K_PRIVATE_LABEL_HEIM_STUDIO,
} from 'constants/productConstants';
import {
  K_FR_ALL,
  K_HFR,
  K_ROUTE_BRAND_DETAIL,
  K_ROUTE_CAMPAIGNS,
  K_ROUTE_SEARCH,
} from 'constants/routeConstants';

import { loadProductCategoryTree } from '../../redux_modules/flow_modules/category';
import {
  getIsLoadedProductCategoryTree,
  getIsLoadingProductCategoryTree,
  getProductCategoryTree,
} from '../../redux_modules/flow_modules/selectors/category';

export const HFR_KEY = 'hcategories_by_function';
export const PARAMETRIC_ROUTE = '/:lvl0';
export const DEFAULT_PARAMS = {
  dfr: `{"isBlacklisted":["-true"],"catalogueGroupNames":["${K_MARKETPLACE_PRODUCT}"],"isOfflineStockOnly":["-true"],"isNotHavingActiveStock":["-true"],"isNotAllowedToReceiveOrder":["-true"]}`,
  distinct: true,
  page: 1,
  query: '',
  sort: config.ALGOLIA_DEFAULT_INDEX_NAME,
  [K_FR_ALL]: true,
};
export const DEFAULT_MAX_VALUES_PER_FACET = 100;

const K_PARAMS_SEARCH = 'search';
const K_PARAMS_FURNITURE = 'furniture';

export default class ProductSearchUrlSerializer extends AbstractUrlSerializer {
  constructor(store) {
    super(store);
    this.store = store;
  }

  /** @override */
  async serialize(params, queries) {
    return replaceDeep(
      assign(
        {},
        this.generateStateFromQueries(queries),
        await this.generateStateFromParams(params),
      ),
      this.forcedState,
    );
  }

  /** @override */
  generateStateFromParams = async (params) => {
    const stateFromParams = {};

    const isSalePage =
      params.lvl0 === K_ROUTE_CAMPAIGNS.substring(1, K_ROUTE_CAMPAIGNS.length);

    const isFurnitureHomePage =
      params.lvl0 && params.lvl0.toLowerCase() === K_PARAMS_FURNITURE;

    const shouldUseJapandiCategoryPage =
      params.style && params.style.toLowerCase() === K_JAPANDI.toLowerCase();

    if (isSalePage) {
      stateFromParams.numericRefinements = {
        'productOffer.discount': {
          '>=': [0],
        },
      };
    } else if (params.bslug) {
      const brandName = unslugify(params.bslug);
      stateFromParams.disjunctiveFacetsRefinements = {
        brand: [brandName],
      };
    } else if (params.querySlug) {
      stateFromParams.query = unslugify(params.querySlug);
    } else {
      /**
       * Modify params.lvl0 from Furniture to furnitur
       * Reason: Furniture homepage is not ready, redirecting to furniture search page */
      const modifiedParams = { ...params };
      if (isFurnitureHomePage) {
        modifiedParams.lvl0 = 'Furniture';
      }
      if (shouldUseJapandiCategoryPage) {
        stateFromParams.disjunctiveFacetsRefinements = {
          brand: [K_PRIVATE_LABEL_HEIM_STUDIO],
        };
      }
      const breadcrumb = await this.getBreadcrumbFromParams(modifiedParams);

      stateFromParams.hitsPerPage = layout.getHitsPerPage(breadcrumb);
      if (
        breadcrumb &&
        pathWithoutParams(modifiedParams.lvl0) !== K_PARAMS_SEARCH
      ) {
        stateFromParams.hierarchicalFacetsRefinements = {
          [HFR_KEY]: [breadcrumb],
        };
      }
    }

    const additionalFacet = getAddedDisjunctiveFacet(stateFromParams);
    if (!isEmpty(additionalFacet)) {
      const disjunctiveFacets = get(stateFromParams, 'disjunctiveFacets', []);
      stateFromParams.disjunctiveFacets = [
        ...new Set([
          ...initialState.disjunctiveFacets,
          ...disjunctiveFacets,
          ...additionalFacet,
        ]),
      ];
    }

    return stateFromParams;
  };

  /** @override */
  generateStateFromQueries = (queries) => {
    const stateFromQueries = convertUrlQueriesToSearchState(queries);
    const additionalFacet = getAddedDisjunctiveFacet(stateFromQueries);
    if (!isEmpty(additionalFacet)) {
      const disjunctiveFacets = get(stateFromQueries, 'disjunctiveFacets', []);
      stateFromQueries.disjunctiveFacets = [
        ...new Set([
          ...initialState.disjunctiveFacets,
          ...disjunctiveFacets,
          ...additionalFacet,
        ]),
      ];
    }
    return stateFromQueries;
  };

  /** @override */
  generateBaseUrlFromState = () => K_ROUTE_SEARCH;

  getBreadcrumbFromParams = async (params) => {
    try {
      if (!params.breadcrumb) {
        let categoryTree = {};
        const isLoadingCategoryTree = getIsLoadingProductCategoryTree(
          this.store.getState(),
        );
        const isLoadedCategoryTree = getIsLoadedProductCategoryTree(
          this.store.getState(),
        );
        if (!isLoadingCategoryTree && !isLoadedCategoryTree) {
          const { result } = await this.store.dispatch(
            dontShowPromptOnError(loadProductCategoryTree()),
          );
          categoryTree = result;
        } else {
          categoryTree = getProductCategoryTree(this.store.getState());
        }
        const allBreadcrumbs = category
          .updateCategoryTree(categoryTree)
          .getBreadcrumbs();
        return getBreadcrumbFromIncompleteParams(params, allBreadcrumbs);
      }
      return params.breadcrumb;
    } catch (err) {
      console.info(
        'Error catched on getBreadcrumbFromParams() on ProductSearchUrlSerializer.',
        err,
      );
      return undefined;
    }
  };
}

export const generateUrlFromState = (state) => {
  const queryParams = convertStateToQueryParams(state);

  const isSalePage = has(state, [
    'numericRefinements',
    'productOffer.discount',
  ]);

  const isBrandPage = has(state, ['disjunctiveFacetsRefinements', 'brand']);

  // strip default
  Object.keys(queryParams).forEach((key) => {
    if (
      has(DEFAULT_PARAMS, key) &&
      isEqual(queryParams[key], DEFAULT_PARAMS[key])
    ) {
      delete queryParams[key];
    }
  });

  let path = '';

  if (has(state, ['hierarchicalFacetsRefinements', HFR_KEY])) {
    const rightMostBreadcrumb = getBreadcrumbRightMost(
      state.hierarchicalFacetsRefinements[HFR_KEY][0],
    );
    path += makeParametricUrl(PARAMETRIC_ROUTE, {
      lvl0: slugify(rightMostBreadcrumb),
    });
    delete queryParams[K_HFR];
  } else if (isSalePage) {
    path += K_ROUTE_CAMPAIGNS;
  } else if (isBrandPage) {
    path += makeParametricUrl(K_ROUTE_BRAND_DETAIL, {
      bslug: state.disjunctiveFacetsRefinements.brand[0],
    });
  } else {
    path += K_ROUTE_SEARCH;
  }

  if (!isEmpty(queryParams)) {
    path += `?${qs.stringify(queryParams)}`;
  }

  return path;
};
