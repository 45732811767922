import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet } from '@dkrm/ui-kit-basic';

import Breadcrumb from 'app-libs/components/Breadcrumb';
import { makeParametricUrl, unslugify } from 'app-libs/etc';
import { useParams } from 'app-libs/hook_modules/router';

import {
  K_ROUTE_OFFLINE_STORE_LIST,
  K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER,
} from 'constants/routeConstants';

const OfflineStoreLocationListPageBreadcrumb = memo(() => {
  const { areaSlug } = useParams<{ areaSlug?: string }>();

  const breadcrumbs = [
    {
      name: 'Beranda',
      path: '/',
      doNotUseHyperlink: false,
    },
    {
      name: 'Toko Furniture dan Desain Interior',
      path: K_ROUTE_OFFLINE_STORE_LIST,
    },
  ];

  if (areaSlug) {
    breadcrumbs.push({
      name: unslugify(areaSlug),
      path: makeParametricUrl(K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER, {
        areaSlug,
      }),
    });
  }

  // @note(dika) 21 Nov 2024: Unlink last breadcrumb
  breadcrumbs[breadcrumbs.length - 1].doNotUseHyperlink = true;

  return (
    <Breadcrumb
      containerStyle={s.container}
      breadcrumbs={breadcrumbs}
      textTheme="body4-neutral100"
      iconTheme="neutral100"
    />
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.flexWrap,
    ...cStyles.flexMiddleLeft,
    ...cStyles.phxl,
    marginBottom: 20,
    height: 'fit-content',
  },
});

export default OfflineStoreLocationListPageBreadcrumb;
