import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { slugify } from 'app-libs/etc';

import ProductCardWithTitle from '../ProductCardWithTitle';

const ProductCardBestSeller = ({ product, productSection, ...rest }) => {
  const bestSellerCategory = slugify(`/${productSection}`);
  const bestSellerCategoryIndex = product.bestSellerCategories.indexOf(
    bestSellerCategory,
  );
  const label = get(product.bestSellerLabels, bestSellerCategoryIndex, '');
  return <ProductCardWithTitle title={label} product={product} {...rest} />;
};

ProductCardBestSeller.propTypes = {
  product: PropTypes.shape({
    bestSellerLabels: PropTypes.arrayOf(PropTypes.string),
    bestSellerCategories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  productSection: PropTypes.string.isRequired,
};

export default React.memo(ProductCardBestSeller);
