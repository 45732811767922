import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadBasket,
  updateBasketShippingAddress,
} from 'app-libs/redux_modules/entity_modules/basket';
import { BasketShippingAddress } from 'app-libs/redux_modules/entity_modules/basket/types';
import { createUpdateDraftShippingAddress } from 'app-libs/redux_modules/entity_modules/customer';
import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';
import { setCurrentLocation } from 'app-libs/redux_modules/entity_modules/location/reducer';
import {
  getCurrentLocation,
  getCurrentLocationPriceZoneCode,
  getCurrentLocationRoutingZoneCode,
} from 'app-libs/redux_modules/entity_modules/location/selectors';
import { getBasketBasketShippingAddress } from 'app-libs/redux_modules/entity_modules/selectors/basket';
import { getCustomerMainShippingAddress } from 'app-libs/redux_modules/entity_modules/selectors/customer';
import { ShippingAreaFromGoblin } from 'app-libs/redux_modules/flow_modules/area/types';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { getCityObjects } from 'app-libs/redux_modules/flow_modules/selectors/area';
import {
  isAuthLoaded as getIsAuthLoaded,
  isLoggedIn as getIsLoggedIn,
  getIsShopAssistant,
  getIsUserOrderOnBehalf,
  getStoreAddress,
} from 'app-libs/redux_modules/selectors/auth';

import { useMutationUpdateOrCreateBasketShippingAddress } from 'entities/basket/utils';
import { OfflineStoreLocation } from 'entities/offlineStore/types';

import {
  K_DEFAULT_PRICE_ZONE_CODE,
  K_DEFAULT_ROUTING_ZONE_CODE,
} from 'constants/productConstants';

export const usePreviousZoneCode = () => {
  const previousBasketShippingAddress: BasketShippingAddress = useSelector(
    getBasketBasketShippingAddress,
  );
  const currentStoreLocation: OfflineStoreLocation =
    useSelector(getStoreAddress);

  const zoneCodeRedux: string = useSelector(getCurrentLocationPriceZoneCode);

  const previousDefaultShippingAddress: UserAddressResponseFromGoblin =
    useSelector(getCustomerMainShippingAddress);

  const isShopAssistant = useSelector(getIsShopAssistant);
  const isUserOrderOnBehalf = useSelector(getIsUserOrderOnBehalf);
  const isAuthLoaded = useSelector(getIsAuthLoaded);
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  const isLoggedInAndNotOrderOnBehalf = isUserLoggedIn && !isUserOrderOnBehalf;
  const isAnonymousOrOrderOnBehalfExcludeSDA =
    (isAuthLoaded && !isUserLoggedIn) ||
    (isUserOrderOnBehalf && !isShopAssistant);

  if (isLoggedInAndNotOrderOnBehalf) {
    return (
      previousDefaultShippingAddress?.shippingArea?.zoneCode ?? zoneCodeRedux
    );
  }

  if (isAnonymousOrOrderOnBehalfExcludeSDA) {
    return (
      previousBasketShippingAddress?.shippingArea?.zoneCode ?? zoneCodeRedux
    );
  }

  // SDA
  return (
    previousBasketShippingAddress?.shippingArea?.zoneCode ??
    currentStoreLocation?.zoneCode ??
    K_DEFAULT_PRICE_ZONE_CODE
  );
};

export const usePreviousRoutingZoneCode = () => {
  const previousBasketShippingAddress: BasketShippingAddress = useSelector(
    getBasketBasketShippingAddress,
  );
  const currentStoreLocation: OfflineStoreLocation =
    useSelector(getStoreAddress);

  const routingZoneCodeRedux: string = useSelector(
    getCurrentLocationRoutingZoneCode,
  );

  const previousDefaultShippingAddress: UserAddressResponseFromGoblin =
    useSelector(getCustomerMainShippingAddress);

  const isShopAssistant = useSelector(getIsShopAssistant);
  const isUserOrderOnBehalf = useSelector(getIsUserOrderOnBehalf);
  const isAuthLoaded = useSelector(getIsAuthLoaded);
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  const isLoggedInAndNotOrderOnBehalf = isUserLoggedIn && !isUserOrderOnBehalf;
  const isAnonymousOrOrderOnBehalfExcludeSDA =
    (isAuthLoaded && !isUserLoggedIn) ||
    (isUserOrderOnBehalf && !isShopAssistant);

  if (isLoggedInAndNotOrderOnBehalf) {
    return (
      previousDefaultShippingAddress?.shippingArea?.routingZoneCode ??
      routingZoneCodeRedux
    );
  }

  if (isAnonymousOrOrderOnBehalfExcludeSDA) {
    return (
      previousBasketShippingAddress?.shippingArea?.routingZoneCode ??
      routingZoneCodeRedux
    );
  }

  // SDA
  return (
    previousBasketShippingAddress?.shippingArea?.routingZoneCode ??
    currentStoreLocation?.routingZoneCode ??
    K_DEFAULT_ROUTING_ZONE_CODE
  );
};

export const useLocationChange = () => {
  const dispatch = useDispatch();

  const handleLocationChangeFromUserAddress = useCallback(
    async ({
      userAddress,
      isSavedFromUserAction,
      shouldReloadBasket,
    }: {
      userAddress: UserAddressResponseFromGoblin;
      isSavedFromUserAction: boolean;
      shouldReloadBasket: boolean;
    }) => {
      await dispatch(updateBasketShippingAddress(userAddress));
      dispatch(
        setCurrentLocation(userAddress.shippingArea, isSavedFromUserAction),
      );
      if (shouldReloadBasket) {
        dispatch(loadBasket());
      }
    },
    [dispatch],
  );

  const handleLocationChangeFromShippingArea = useCallback(
    ({
      shippingArea,
      isSavedFromUserAction,
      shouldReloadBasket,
    }: {
      shippingArea: ShippingAreaFromGoblin;
      isSavedFromUserAction: boolean;
      shouldReloadBasket: boolean;
    }) => {
      dispatch(setCurrentLocation(shippingArea, isSavedFromUserAction));
      if (shouldReloadBasket) {
        dispatch(loadBasket());
      }
    },
    [dispatch],
  );

  return {
    handleLocationChangeFromUserAddress,
    handleLocationChangeFromShippingArea,
  };
};

export const useLocationAPIUtils = () => {
  const dispatch = useDispatch();

  const { mutateAsync: actionUpdateOrCreateBasketShippingAddress } =
    useMutationUpdateOrCreateBasketShippingAddress();

  const actionCreateDraftUserShippingAddressFromShippingArea = useCallback(
    async ({
      shippingAreaGoblin,
      onSuccess = () => null,
      onError = () => null,
    }: {
      shippingAreaGoblin: ShippingAreaFromGoblin;
      onSuccess?: (userAddress: UserAddressResponseFromGoblin) => void;
      onError?: () => void;
    }) => {
      const { result: userAddress } = await dispatch(
        dontShowPromptOnError(
          createUpdateDraftShippingAddress({
            line3: shippingAreaGoblin.district,
            line4: shippingAreaGoblin.city,
            state: shippingAreaGoblin.province,
          }),
        ),
      );
      if (userAddress) {
        onSuccess(userAddress);
      } else {
        onError();
      }
    },
    [dispatch],
  );

  const actionCreateBasketShippingAddressFromShippingArea = useCallback(
    async ({
      shippingAreaGoblin,
      onSuccess = () => null,
      onError = () => null,
    }: {
      shippingAreaGoblin: ShippingAreaFromGoblin;
      onSuccess?: (basketShippingAddress: BasketShippingAddress) => void;
      onError?: () => void;
    }) => {
      try {
        const res: BasketShippingAddress =
          await actionUpdateOrCreateBasketShippingAddress({
            firstName: '',
            line1: '',
            line3: shippingAreaGoblin.district,
            line4: shippingAreaGoblin.city,
            phoneNumber: '',
            postcode: '',
            state: shippingAreaGoblin.province,
            email: '',
          });
        if (res) {
          onSuccess(res);
        }
      } catch (error) {
        onError();
      }
    },
    [actionUpdateOrCreateBasketShippingAddress],
  );

  return {
    actionCreateDraftUserShippingAddressFromShippingArea,
    actionCreateBasketShippingAddressFromShippingArea,
  };
};

export const useCurrentSelectedArea = () => {
  const currentLocation = useSelector(getCurrentLocation);
  const { city: selectedCity } = currentLocation;
  const retrievedShippingCoverages: {
    city: string;
    maxDeliveryDay: number;
    minDeliveryDay: number;
  }[] = useSelector(getCityObjects);

  const area = useMemo(() => {
    return retrievedShippingCoverages.find(
      (shippingCoverage) => shippingCoverage.city === selectedCity,
    );
  }, [retrievedShippingCoverages, selectedCity]);

  return area;
};
