import { ALGOLIA_DEFAULT_INDEX_NAME } from 'config';
import { HFR_KEY } from 'app-libs/SearchKit/managers/productSearchStateManager';
import { K_MARKETPLACE_PRODUCT } from 'constants/productConstants';

export const initialState = {
  index: ALGOLIA_DEFAULT_INDEX_NAME,
  distinct: true,
  hitsPerPage: 40,
  facets: [
    'availability.isAvailableToBuy',
    'isBlacklisted',
    'isOfflineStockOnly',
    'catalogueGroupNames',
    'brand',
  ],
  facetsRefinements: {
    'availability.isAvailableToBuy': [true],
    isBlacklisted: ['-true'],
    isOfflineStockOnly: ['-true'],
    catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
  },
  hierarchicalFacets: [
    {
      name: HFR_KEY,
      attributes: [
        'hcategories.lvl0',
        'hcategories.lvl1',
        'hcategories.lvl2',
        'hcategories.lvl3',
      ],
      sortBy: ['count:desc', 'name:asc'],
    },
  ],
};

export const forcedState = {};
