import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';
import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'react-native';
import MediaQuery from 'react-native-web-responsive';

import config from 'config';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import {
  Modal,
  Text,
  TouchableNativeFeedbackOpacity,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import InfiniteScrollList from 'app-libs/components/InfiniteScrollList';

import ModalContainer from '../../../ModalContainer';
import styles from './styles';

export default class PopupFilterList extends React.PureComponent {
  static getDefaultLabel = ({ selected }, { data, label }) => {
    return selected.length > 0
      ? selected
          .map((value) => data.find((d) => d.value === value).label)
          .sort()
          .join(', ')
      : label;
  };

  static getSelectedData = (props) =>
    props.data.filter((item) => item.selected).map((item) => item.value);

  constructor(props) {
    super(props);
    this.state = {
      selected: PopupFilterList.getSelectedData(this.props),
      temporarySelected: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = this.props;

    if (!isEqual(data, nextProps.data)) {
      this.setState({
        selected: PopupFilterList.getSelectedData(nextProps),
      });
    }
  }

  setSelectedState =
    (value) =>
    ({ selected }, { multiple, allowEmpty }) => {
      let newSelected = xor(selected, [value]);

      if (!allowEmpty && newSelected.length === 0) return { selected };
      if (!multiple && newSelected.length > 1) {
        newSelected = [value];
      }

      return { selected: newSelected };
    };

  setTemporarySelectedState =
    (value) =>
    ({ temporarySelected, selected }) => {
      return { temporarySelected: xor(temporarySelected || selected, [value]) };
    };

  handleClear = () => {
    const { isApplyOnClick } = this.props;
    if (isApplyOnClick) {
      this.setState({
        selected: [],
      });
      this.onChange([]);
    } else {
      this.setState({ temporarySelected: [] });
    }
  };

  handleChange = (value) => {
    const { isApplyOnClick, onChange } = this.props;
    if (isApplyOnClick) {
      const { selected } = this.setSelectedState(value)(this.state, this.props);
      onChange(selected);
    } else {
      this.setState(this.setTemporarySelectedState(value));
    }
  };

  handleToggle = () => {
    const { isModalOpen, onRequestOpen } = this.props;
    if (isModalOpen) return this.handleClose();
    return onRequestOpen();
  };

  handleClose = () => {
    const { onRequestClose } = this.props;
    onRequestClose();
    this.setState({ temporarySelected: null });
  };

  handleApply = () => {
    const { onChange } = this.props;
    const { temporarySelected, selected } = this.state;
    onChange(temporarySelected === null ? selected : temporarySelected);
  };

  renderFilterItem = ({ item }) => {
    const { itemComponent: Item } = this.props;
    const { temporarySelected, selected } = this.state;
    return (
      <Item
        key={item.value}
        {...item}
        selected={(temporarySelected || selected).indexOf(item.value) > -1}
        onChange={this.handleChange}
      />
    );
  };

  renderModal() {
    const {
      data,
      applyButtonLabel,
      isApplyOnClick,
      label,
      allowEmpty,
      dimension,
    } = this.props;
    const { selected } = this.state;

    const listComponent = (
      <InfiniteScrollList
        data={data}
        renderItem={this.renderFilterItem}
        extraData={selected}
        initialNumToRender={20}
        windowSize={20}
        maxToRenderPerBatch={20}
        removeClippedSubviews={false}
        isFull={dimension.width <= 576}
      />
    );
    return (
      <ModalContainer>
        <Modal isHangable visible onRequestClose={this.handleClose}>
          <View style={styles.popupContainer}>
            <View style={styles.popupHeading}>
              <TouchableNativeFeedbackOpacity
                onPress={this.handleClose}
                style={styles.closeButtonContainer}
                hitSlop={{ top: 32, bottom: 32, left: 32, right: 32 }}
              >
                <Image
                  style={{ width: 24, height: 24 }}
                  source={{
                    uri: `${config.API_URL_MEDIA_CDN}/icon/navbar/x-20.svg?auto=webp`,
                  }}
                />
              </TouchableNativeFeedbackOpacity>
              <View style={styles.headingContainer}>
                <Text style={styles.textHeading}>{label}</Text>
              </View>
              {allowEmpty ? (
                <TouchableNativeFeedbackOpacity onPress={this.handleClear}>
                  <Text style={styles.textClear}>Clear</Text>
                </TouchableNativeFeedbackOpacity>
              ) : (
                <View style={{ width: 32, height: 32 }} />
              )}
            </View>
            <MediaQuery minWidth={576}>
              <View style={{ height: 210 }}>{listComponent}</View>
            </MediaQuery>
            <MediaQuery maxWidth={575}>{listComponent}</MediaQuery>
            {!isApplyOnClick && (
              <View style={styles.applyButtonContainer}>
                <ButtonWithText
                  title={applyButtonLabel}
                  onPress={this.handleApply}
                />
              </View>
            )}
          </View>
        </Modal>
      </ModalContainer>
    );
  }

  render() {
    const { getLabel, width, isModalOpen, getButtonTheme, buttonProps } =
      this.props;
    return (
      <View style={styles.container}>
        <ButtonWithText
          small
          {...buttonProps}
          title={getLabel(this.state, this.props)}
          themeType={getButtonTheme(this.state, this.props)}
          style={{ width }}
          buttonStyle={{ borderRadius: 4 }}
          textStyle={cStyles.filterText}
          onPress={this.handleToggle}
        />
        {isModalOpen && this.renderModal()}
      </View>
    );
  }
}

PopupFilterList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  itemComponent: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  allowEmpty: PropTypes.bool.isRequired,
  labelStyle: stylePropTypes,
  applyButtonLabel: PropTypes.string,
  width: PropTypes.number,
  isApplyOnClick: PropTypes.bool,
  getLabel: PropTypes.func,
  getButtonTheme: PropTypes.func,
  onRequestOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
  dimension: PropTypes.shape({
    width: PropTypes.number,
  }),
  buttonProps: PropTypes.shape({
    image: PropTypes.shape({
      theme: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      style: stylePropTypes,
    }),
    align: PropTypes.string,
  }),
};

PopupFilterList.defaultProps = {
  labelStyle: {},
  dimension: { width: 0 },
  width: undefined,
  applyButtonLabel: 'Terapkan',
  isApplyOnClick: false,
  isModalOpen: false,
  getLabel: (state, props) => PopupFilterList.getDefaultLabel(state, props),
  getButtonTheme: ({ selected }) =>
    selected.length > 0 ? 'primary' : 'secondary',
  onRequestOpen: () => null,
  onRequestClose: () => null,
  buttonProps: {},
};
