import React, { memo, useCallback } from 'react';

import { MessageDialog } from '@dkrm/ui-kit-basic';
import { cStyles } from '@dkrm/ui-kit-v2';

import { SearchAdapter, SearchResult } from 'app-libs/AlgoliaAdapter/types';
import { ProductCardProduct } from 'app-libs/components/Product/ProductCard/types';
import {
  useLocation,
  useParams,
  useQueries,
} from 'app-libs/hook_modules/router';

import NotFoundPageMeta from 'components/NotFoundPageMeta';
import PageRefresherOn404 from 'components/PageRefresherOn404';

import { useQueryLoadSEOAreas } from 'entities/seo/utils';

import { K_JAPANDI } from 'constants/productConstants';
import { K_ROUTE_SEARCH } from 'constants/routeConstants';

import ProductSearch from '../ProductSearch';
import CategoryJapandiPageFooter from '../footer/CategoryJapandiPageFooter';
import CategoryLocationPageFooter from '../footer/CategoryLocationPageFooter';
import CategoryPageFooter from '../footer/CategoryPageFooter';
import TopSearchPageFooter from '../footer/TopSearchPageFooter';
import ProductListPageMeta from '../meta/ProductListPageMeta';
import TopSearchPageMeta from '../meta/TopSearchPageMeta';
import { useSetProductFiltersEffect } from './utils';

interface ProductSearchWrapperProps {
  searchAdapter: SearchAdapter<ProductCardProduct>;
  searchResult: SearchResult<ProductCardProduct>;
  loading: boolean;
  HeaderBottomComponent?: React.FC;
  HeaderInsertComponent?: React.FC;
  renderSEOComponent?: () => React.ReactElement;
  overrideTitleBreadcrumbAndLevel?: {
    level: number;
    title: string;
    listTitle?: string;
    breadcrumb: string;
  };
  genBreadcrumbs?: (category: string, options: object) => void;
  additionalListProps?: Record<string, any>;
  shouldDisableAutoRefresher?: boolean;
  shouldHideQuickFilter?: boolean;
  shouldHideSeparator?: boolean;
  shouldHideFloatingSortAndFilter?: boolean;
  shouldHideHierarchicalFacetFilter?: boolean;
  shouldHideTitle?: boolean;
  shouldHideCampaignBannerSlider?: boolean;
}

const ProductSearchWrapper: React.FC<ProductSearchWrapperProps> = memo(
  (props) => {
    const {
      searchAdapter,
      loading,
      searchResult,
      renderSEOComponent: _renderSEOComponent,
      ...rest
    } = props;
    const {
      style = '',
      areaSlug = '',
      querySlug = '',
      lvl0 = '',
    } = useParams<Record<string, string>>();
    const { dfr = '' } = useQueries();
    const { pathname } = useLocation();

    const { areas } = useQueryLoadSEOAreas();

    const isOnDefaultSearchPage = pathname.startsWith(K_ROUTE_SEARCH);

    // @note(dika) 30 Oct 2024: Return empty state on
    // 1. Empty products (no products according to filter / page) - prevent /Furniture?page=100001 showing content
    // 2. Area slug params not empty but not found in SEO Areas - prevent /Furniture/Judi-Online showing content
    const shouldRenderEmptyPage =
      searchAdapter.getData().length === 0 ||
      (areaSlug !== '' && !areas.find(({ slug }) => slug === areaSlug));

    const renderSEOComponent = useCallback(() => {
      if (isOnDefaultSearchPage) return null;
      if (dfr) return null;
      if (querySlug) return <TopSearchPageMeta searchAdapter={searchAdapter} />;
      // @note(dika) 31 Oct 2024: Currently all Category List Page are using new Meta component
      // other pages' meta (Brand Page) has not yet been defined
      if (areaSlug || style || lvl0)
        return <ProductListPageMeta searchAdapter={searchAdapter} />;
      return _renderSEOComponent?.();
    }, [
      areaSlug,
      querySlug,
      style,
      lvl0,
      dfr,
      _renderSEOComponent,
      isOnDefaultSearchPage,
      searchAdapter,
    ]);

    const renderBlogInternalLinkingComponent = useCallback(() => {
      if (isOnDefaultSearchPage) return null;
      if (dfr) return null;
      if (querySlug) return <TopSearchPageFooter />;
      if (areaSlug) return <CategoryLocationPageFooter />;
      if (style) return <CategoryJapandiPageFooter />;
      if (lvl0) return <CategoryPageFooter />;
      return null;
    }, [areaSlug, querySlug, style, dfr, lvl0, isOnDefaultSearchPage]);

    useSetProductFiltersEffect(searchAdapter);

    const shouldUseJapandiCategoryPage =
      style.toLowerCase() === K_JAPANDI.toLowerCase();

    if (shouldRenderEmptyPage) {
      return (
        <>
          <NotFoundPageMeta />
          <PageRefresherOn404 />
          <MessageDialog
            header="Tidak ditemukan"
            description="Kami tidak menemukan apapun. Coba kembali ke beranda."
            linkText="Kembali ke beranda"
            style={cStyles.pa24}
          />
        </>
      );
    }

    return (
      <>
        {(isOnDefaultSearchPage || !!dfr) && <NotFoundPageMeta />}
        <ProductSearch
          searchAdapter={searchAdapter}
          searchResult={searchResult}
          shouldUseJapandiCategoryPage={shouldUseJapandiCategoryPage}
          renderSEOComponent={renderSEOComponent}
          renderBlogInternalLinkingComponent={
            renderBlogInternalLinkingComponent
          }
          loading={loading}
          {...rest}
        />
      </>
    );
  },
);

export default ProductSearchWrapper;
