import { ApiClient } from 'types';

import config from 'config';

import { GroupedBundleOfferItems } from './types';

export const loadProductBundleOffersAPI = (
  client: ApiClient<GroupedBundleOfferItems[]>,
  stockRecordId: number,
) =>
  client.get(
    `${config.API_URL_GOBLIN}/stock-records/${stockRecordId}/bundle-offer-items-v2/`,
  );
