import keyMirror from 'keymirror';

import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';

export const AT = keyMirror({
  CREATE_CONTRACT: null,
  CREATE_CONTRACT_SUCCESS: null,
  CREATE_CONTRACT_FAIL: null,
  CREATE_CONTRACT_STATUS: null,
  CREATE_CONTRACT_STATUS_SUCCESS: null,
  CREATE_CONTRACT_STATUS_FAIL: null,
});

export const initialState = {
  asyncState: { ...initialAsyncState },
  currentContract: {},
  success: null,
};

export default function contractReducer(mutableState = initialState, action) {
  const { type, result } = action;
  let state = mutableState;
  state.asyncState = asyncStateReducer(
    state.asyncState,
    action,
    '_CONTRACT_STATUS_',
  );
  switch (type) {
    case AT.CREATE_CONTRACT_SUCCESS:
      state = {
        ...state,
        success: true,
      };
      break;
    case AT.CREATE_CONTRACT_FAIL:
      state = {
        ...state,
        success: false,
      };
      break;
  }
  return state;
}
