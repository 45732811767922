import config from 'config';

import { withSearchProvider as abstractWithSearchProvider } from '@dkrm/algolia-adapter';

const withSearchProvider = (searchProviderProps) =>
  abstractWithSearchProvider({
    algoliaAPIKey: config.ALGOLIA_API_KEY,
    algoliaAppName: config.ALGOLIA_APP_NAME,
    ...searchProviderProps,
  });

export default withSearchProvider;
