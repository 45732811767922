import isEmpty from 'lodash/isEmpty';

import { K_MAX_BACKORDER } from 'app-libs/redux_modules/entity_modules/selectors/product';

import { K_SHIPPING_OPTION_TIERS_PREMIUM } from 'entities/shipping/constants';

import { K_BUNDLE_OFFER_STATUS_ONGOING } from 'constants/offerConstants';
import { PAYMENT_METHODS } from 'constants/paymentConstants';
import {
  K_B2B_TAX,
  K_PARTNER_ID_DEKORUMA_MARKETING,
  K_PRIVATE_LABEL_PARTNERS,
} from 'constants/productConstants';
import {
  K_PROMO_LABEL_DISCOUNT_TYPES,
  K_PROMO_LABEL_SHIPPING_TYPES,
  K_VOUCHER_ISSUER_MERCHANT,
} from 'constants/promoConstants';

export const getBasketLineProductAvailability = (basketLineProduct) =>
  basketLineProduct?.availability ?? {};

export const getBasketLineProductMaxQuantityToBuy = (basketLineProduct) => {
  /*
   * this is almost same from getProductMaxQuantityToBuy, but with slight modification
   * reason: product in basket and PDP (algolia) has a different data structure.
   */

  const availability = getBasketLineProductAvailability(basketLineProduct);
  if (availability.backorderAllowed && availability.numAvailable === 0)
    return K_MAX_BACKORDER;
  return availability.numAvailable;
};

export const getBasketLineProductItemCounterMaxQuantity = (
  basketLine,
  alternativeStockInfo,
) => {
  /* this is only for isShopAssistant, which do not load the alternativeStockInfos at all */
  if (isEmpty(alternativeStockInfo))
    return getBasketLineProductMaxQuantityToBuy(basketLine.product);
  let maxQuantityToBuy = 0;
  alternativeStockInfo.availableStockRecords.forEach((availableStockRecord) => {
    if (
      availableStockRecord.backorderAllowed &&
      availableStockRecord.numAvailable === 0
    )
      maxQuantityToBuy = Math.max(maxQuantityToBuy, K_MAX_BACKORDER);
    else
      maxQuantityToBuy = Math.max(
        maxQuantityToBuy,
        availableStockRecord.numAvailable,
      );
  });

  return maxQuantityToBuy;
};

export function getAddonLineUnitPriceAfterDiscount(addon) {
  return parseFloat(addon.priceInclTax) / addon.quantity;
}

export function getTotalProductVoucherDiscount(basket, maxDiscount) {
  if (!basket.voucherDiscounts || !basket.voucherDiscounts.length) {
    return 0;
  }
  let voucherDiscounts = 0;
  basket.voucherDiscounts.forEach((voucherDiscount) => {
    if (K_PROMO_LABEL_DISCOUNT_TYPES.includes(voucherDiscount.discountType)) {
      voucherDiscounts += parseInt(voucherDiscount.totalDiscount, 10);
    }
  });
  return Math.min(voucherDiscounts, maxDiscount);
}

export const getDiscountableShippingPrice = (shippingMethod) => {
  if (!shippingMethod) {
    return 0;
  }

  return Object.values(
    shippingMethod?.priceDetail?.chargeByMerchant || {},
  ).reduce((acc, merchantChargePackage) => {
    if (
      !K_SHIPPING_OPTION_TIERS_PREMIUM.includes(
        merchantChargePackage.shippingTier || '',
      )
    ) {
      const priceAfterDiscount =
        parseFloat(merchantChargePackage.charge) -
        parseFloat(merchantChargePackage.partnerDiscount);
      return acc + priceAfterDiscount;
    }
    return acc;
  }, 0);
};

export function getIsBasketUsingProductVoucher(basket) {
  return basket.vouchers.some((voucher) =>
    K_PROMO_LABEL_DISCOUNT_TYPES.includes(voucher.discountType),
  );
}

export const getAppliedDekorumaVoucherByPromoType = (vouchers, promoType) => {
  return vouchers.find(
    (voucher) =>
      promoType.includes(voucher.discountType) &&
      voucher.voucherIssuer !== K_VOUCHER_ISSUER_MERCHANT,
  );
};

export function getTotalShippingVoucherDiscount(basket, maxDiscount) {
  if (!basket.voucherDiscounts || !basket.voucherDiscounts.length) {
    return 0;
  }
  let voucherDiscounts = 0;
  basket.voucherDiscounts.forEach((voucherDiscount) => {
    if (
      K_PROMO_LABEL_SHIPPING_TYPES.includes(voucherDiscount.discountType) &&
      voucherDiscount.voucherIssuer !== K_VOUCHER_ISSUER_MERCHANT
    ) {
      voucherDiscounts += parseInt(voucherDiscount.totalDiscount, 10);
    }
  });
  return Math.min(voucherDiscounts, maxDiscount);
}

export function getUnavailableItems(basket, alternativeStockInfos) {
  if (!basket.lines) return [];

  return basket.lines.filter((line) => {
    const lineAlternativeStockInfo = alternativeStockInfos.find(
      (alternativeStockInfo) => alternativeStockInfo.id === line.id,
    );
    return getIsBasketLineUnavailable(line, lineAlternativeStockInfo);
  });
}

export function getIsBasketLineUnavailable(basketLine, alternativeStockInfo) {
  const { bundleOfferInfo, product } = basketLine;
  const availability = product?.availability ?? {};
  const isNotAllowedToReceiveOrder =
    product?.isNotAllowedToReceiveOrder ?? false;

  const maxQuantityToBuy = getBasketLineProductItemCounterMaxQuantity(
    basketLine,
    alternativeStockInfo,
  );
  const isExceedMaxQuantityAvailable =
    maxQuantityToBuy < basketLine.quantity && !availability.backorderAllowed;
  const isContainBundleOfferThatNotActive =
    bundleOfferInfo && bundleOfferInfo.status !== K_BUNDLE_OFFER_STATUS_ONGOING;

  return (
    isNotAllowedToReceiveOrder ||
    isExceedMaxQuantityAvailable ||
    !product.availability.isAvailableToBuy ||
    isContainBundleOfferThatNotActive
  );
}

export function findVoucherDiscount(basket, code) {
  if (!basket.voucherDiscounts || !basket.voucherDiscounts.length) {
    return {};
  }
  return (
    basket.voucherDiscounts.find(
      (voucherDiscount) => voucherDiscount.code === code,
    ) || {}
  );
}

export function getInvalidVoucherCodesAndAvailablePaymentMethodsFromPayment(
  vouchers,
  selectedPaymentMethod,
) {
  const invalidVoucherCodes = [];
  let availablePaymentMethods = [];

  vouchers.forEach((voucher) => {
    const voucherPaymentMethods = voucher?.voucherPaymentMethods ?? [];
    if (
      voucherPaymentMethods.length &&
      !voucherPaymentMethods.includes(selectedPaymentMethod)
    ) {
      invalidVoucherCodes.push(voucher.code);
    }
    if (availablePaymentMethods.length && voucherPaymentMethods.length) {
      availablePaymentMethods = availablePaymentMethods.filter(
        (paymentMethod) => voucherPaymentMethods.includes(paymentMethod),
      );
    } else if (voucherPaymentMethods.length) {
      availablePaymentMethods.push(...voucherPaymentMethods);
    }
  });
  return { invalidVoucherCodes, availablePaymentMethods };
}

export function getSupportedVoucherPaymentMethods(voucher) {
  const voucherPaymentMethodSet = new Set();
  const voucherPaymentMethods = voucher?.voucherPaymentMethods ?? [];
  voucherPaymentMethods.forEach((paymentMethod) => {
    let tempPaymentMethod = paymentMethod;
    if (paymentMethod.startsWith(PAYMENT_METHODS.CREDIT_CARD)) {
      tempPaymentMethod = PAYMENT_METHODS.CREDIT_CARD;
    }
    if (
      paymentMethod.startsWith(PAYMENT_METHODS.E_WALLET) ||
      paymentMethod.startsWith(PAYMENT_METHODS.INSTALLMENT_WITHOUT_CARD) ||
      paymentMethod.startsWith(PAYMENT_METHODS.INTERNET_BANKING)
    ) {
      [, tempPaymentMethod] = paymentMethod.split('-');
    }
    voucherPaymentMethodSet.add(tempPaymentMethod);
  });
  return Array.from(voucherPaymentMethodSet);
}

export function getInvalidVoucherCodesAndAvailablePaymentMethodsFromPaymentSelection(
  vouchers,
  selectedPayment,
) {
  const invalidVoucherCodes = [];
  let availablePaymentMethods = [];
  vouchers.forEach((voucher) => {
    const voucherPaymentMethods = getSupportedVoucherPaymentMethods(voucher);
    if (
      voucherPaymentMethods.length &&
      !voucherPaymentMethods.includes(selectedPayment)
    ) {
      invalidVoucherCodes.push(voucher.code);
    }
    if (availablePaymentMethods.length && voucherPaymentMethods.length) {
      availablePaymentMethods = availablePaymentMethods.filter(
        (paymentMethod) => voucherPaymentMethods.includes(paymentMethod),
      );
    } else if (voucherPaymentMethods.length) {
      availablePaymentMethods.push(...voucherPaymentMethods);
    }
  });

  return { invalidVoucherCodes, availablePaymentMethods };
}

export function getBasketLineByStockRecordId(basket, stockRecordId) {
  return basket.lines.find(
    (line) => line?.product?.stockRecordId === stockRecordId,
  );
}

export function getBasketLineBundleOfferByStockRecordId(
  basket,
  stockRecordId,
  stockRecordBillOfMaterialQuantityId,
) {
  return basket.lines.find(
    (line) =>
      line?.product?.stockRecordId === stockRecordId &&
      line?.stockRecordBillOfMaterialQuantityId ===
        stockRecordBillOfMaterialQuantityId,
  );
}

export const getIsBasketLineBrandingPushed = (line) => {
  return line.product.isBrandingPushed;
};

export const getIsBasketLineHaveDiscount = (line) => {
  return line.priceInclTaxExclDiscounts - line.priceInclTax > 0;
};

export const getIsGiftWithPurchaseLine = (line) => {
  return (
    line.product.partner.id === K_PARTNER_ID_DEKORUMA_MARKETING &&
    parseFloat(line.priceInclTax) === 0
  );
};

export const getIsBasketContainsGiftWithPurchase = (basket) => {
  return basket.lines.some((line) => getIsGiftWithPurchaseLine(line));
};

export const separateGiftWithPurchaseLine = (basketLines) => {
  const basketLinesWithoutGiftWithPurchaseLine = [];
  let giftWithPurchaseLine = null;

  basketLines.forEach((line) => {
    if (getIsGiftWithPurchaseLine(line)) {
      giftWithPurchaseLine = line;
    } else {
      basketLinesWithoutGiftWithPurchaseLine.push(line);
    }
  });

  return {
    giftWithPurchaseLine,
    basketLinesWithoutGiftWithPurchaseLine,
  };
};

export const getBasketTotalPriceExcludePLProducts = (lines) => {
  return lines.reduce((total, lineItem) => {
    let totalPriceAddon = 0;
    totalPriceAddon = lineItem.addonProducts.reduce(
      (addonTotal, addonProduct) => {
        if (
          K_PRIVATE_LABEL_PARTNERS.includes(addonProduct.product.partner.name)
        ) {
          return addonTotal;
        }
        return (
          addonTotal +
          parseFloat(addonProduct.priceInclTax) -
          parseFloat(addonProduct?.lineTotalVoucherDiscount || 0)
        );
      },
      0,
    );

    if (K_PRIVATE_LABEL_PARTNERS.includes(lineItem.product.partner.name)) {
      return total + totalPriceAddon;
    }
    return (
      total +
      parseFloat(lineItem.priceInclTax) -
      parseFloat(lineItem?.lineTotalVoucherDiscount || 0) +
      totalPriceAddon
    );
  }, 0);
};

export const getBasketTotalPrice = (lines) => {
  return lines.reduce((total, lineItem) => {
    const totalPriceAddon = lineItem.addonProducts.reduce(
      (addonTotal, addonProduct) => {
        return (
          addonTotal +
          parseFloat(addonProduct.priceInclTax) -
          parseFloat(addonProduct?.lineTotalVoucherDiscount || 0)
        );
      },
      0,
    );
    return (
      total +
      parseFloat(lineItem.priceInclTax) -
      parseFloat(lineItem?.lineTotalVoucherDiscount || 0) +
      totalPriceAddon
    );
  }, 0);
};

export const getBasketB2BTax = (
  basket,
  shippingTotal,
  serviceFee,
  additionalProductDiscount,
) => {
  /*
    @note(dika) 27 Nov 2023: B2B Tax
    Calculated from Non PL Products Price (after discount, voucher and additional product discount)
    Calculated from shipping fee (after discount)
    Calculated from service fee
  */
  const basketTotalPriceExcludePLProducts =
    getBasketTotalPriceExcludePLProducts(basket.lines);
  const basketTotalPrice = getBasketTotalPrice(basket.lines);
  const additionalProductDiscountForNonPLProducts = Math.round(
    (basketTotalPriceExcludePLProducts / basketTotalPrice) *
      additionalProductDiscount,
  );
  const totalPayableExclPLInclDisc = Math.max(
    0,
    basketTotalPriceExcludePLProducts -
      additionalProductDiscountForNonPLProducts,
  );

  return Math.ceil(
    K_B2B_TAX * (totalPayableExclPLInclDisc + shippingTotal + serviceFee),
  );
};

const getOptimalStockRecord = (alternativeStockInfo, quantity) => {
  const optimalStockRecord = alternativeStockInfo?.availableStockRecords?.find(
    (availableStockRecord) => {
      if (
        availableStockRecord.backorderAllowed &&
        availableStockRecord.numAvailable === 0
      )
        return true;
      if (availableStockRecord.numAvailable >= quantity) return true;
      return false;
    },
  );
  return optimalStockRecord ?? null;
};

const getShouldLoadBasketAlternativeStockInfo = (
  basket,
  alternativeStockInfos,
) => {
  if (isEmpty(basket)) return false;
  if (isEmpty(alternativeStockInfos)) return true;

  const firstStaleBasketLine = basket.lines.find((basketLine) => {
    if (basketLine.billOfMaterialStockRecordId) return false;
    if (getIsGiftWithPurchaseLine(basketLine)) return false;

    const basketLineAlternativeStockInfo = alternativeStockInfos.find(
      (alternativeStockInfo) => alternativeStockInfo.id === basketLine.id,
    );
    return isEmpty(basketLineAlternativeStockInfo);
  });

  return !isEmpty(firstStaleBasketLine);
};

export const getShouldCallRefreshStockAvailable = (
  basket,
  alternativeStockInfos,
  isShopAssistant,
) => {
  /*
   * Flow:
   * 1. Load Basket
   * 2. If user on behalf --> not doing anything (return false)
   * 3. If line is bundle / gwp, ignore that line (still checking other line)
   * 4. If line has a non-optimal line, return true
   */

  if (isShopAssistant) return false;
  if (isEmpty(basket)) return false;
  if (getShouldLoadBasketAlternativeStockInfo(basket, alternativeStockInfos))
    return false;

  /* by this line, the basket, user, and alternative stock info is already loaded.
   * not need to check again if the alternative stock info is still not loaded.
   */

  const nonOptimalBasketLine = basket.lines.find((basketLine) => {
    if (basketLine.billOfMaterialStockRecordId) return false;
    if (getIsGiftWithPurchaseLine(basketLine)) return false;

    const basketLineAlternativeStockInfo = alternativeStockInfos.find(
      (alternativeStockInfo) => alternativeStockInfo.id === basketLine.id,
    );

    const {
      quantity,
      product: { stockRecordId },
    } = basketLine;

    const optimalStockRecord = getOptimalStockRecord(
      basketLineAlternativeStockInfo,
      quantity,
    );

    return optimalStockRecord?.id !== stockRecordId;
  });

  return !isEmpty(nonOptimalBasketLine);
};
