import React from 'react';
import { View, StyleSheet } from '@dkrm/ui-kit-basic';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import cStyles from '@dkrm/general-libs/theme/styles';

const K_CAMPAIGN_STICKER_IMAGE_WIDTH = 48;
const K_CAMPAIGN_STICKER_IMAGE_HEIGHT = 18;

type CampaignStickerProps = {
  assetUrl?: string;
};

const CampaignSticker: React.FC<CampaignStickerProps> = ({ assetUrl }) => {
  if (!assetUrl) return null;
  return (
    <View
      style={[
        s.stickerContainer,
        {
          width: K_CAMPAIGN_STICKER_IMAGE_WIDTH,
          height: K_CAMPAIGN_STICKER_IMAGE_HEIGHT,
        },
      ]}
    >
      <SmartImage
        source={assetUrl}
        width={K_CAMPAIGN_STICKER_IMAGE_WIDTH}
        height={K_CAMPAIGN_STICKER_IMAGE_HEIGHT}
        shouldPreloadSmallerVersion={false}
        resizeMode="contain"
        accessibilityLabel="campaign-sticker"
      />
    </View>
  );
};

const s = StyleSheet.create({
  stickerContainer: {
    ...cStyles.abs,
    right: 4,
    top: 4,
  },
});

export default CampaignSticker;
