import PropTypes from 'prop-types';
import React from 'react';

import { Text, View } from '@dkrm/ui-kit-basic';

import { K_VARIANT_UKURAN, K_VARIANT_WARNA, K_VARIANT_WATT } from './constants';
import styles from './styles';

function getVariantTypeMapping(type) {
  if (type.includes(K_VARIANT_WARNA)) return K_VARIANT_WARNA;
  if (type.includes(K_VARIANT_UKURAN)) return K_VARIANT_UKURAN;
  if (type.includes(K_VARIANT_WATT)) return K_VARIANT_WATT;
  return type;
}

const VariantLabel = React.memo((props) => {
  const { variant } = props;
  const { type, number } = variant;
  const text = `+${number} ${getVariantTypeMapping(type)}`;

  return (
    <View style={styles.container}>
      <Text theme="h6" style={styles.text}>
        {text}
      </Text>
    </View>
  );
});

VariantLabel.propTypes = {
  variant: PropTypes.shape({
    type: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  }).isRequired,
};

export default VariantLabel;
