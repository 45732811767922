import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { StyleSheet } from '@dkrm/ui-kit-basic';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

import ProductCardBestSeller from 'app-libs/components/Product/ProductCardBestSeller';
import ProductRecommendationList from 'app-libs/components/Product/ProductRecommendationList';
import { getBreadcrumbRightMost } from 'app-libs/etc/categoryHelper';
import { getAvailableProductsByRoutingZoneCode } from 'app-libs/etc/productHelperV2';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';
import { getProductPrice } from 'app-libs/redux_modules/entity_modules/selectors/product';
import { trackClickedBestSeller } from 'app-libs/trackers';

import {
  K_PROPERTY_OPENED_FROM_BEST_SELLER,
  K_TRACK_CLICKED_BEST_SELLER,
} from 'constants/trackingConstants';

const BestSellerProduct = (props) => {
  const { breadcrumb, products } = props;
  const title = getBreadcrumbRightMost(breadcrumb);

  const routingZoneCode = useSelector(getCurrentLocationRoutingZoneCode);

  const availableProducts = getAvailableProductsByRoutingZoneCode(
    products,
    routingZoneCode,
  );

  const sortedProducts = useMemo(() => {
    return availableProducts.sort((item, other) =>
      getProductPrice(item) >= getProductPrice(other) ? 1 : -1,
    );
  }, [availableProducts]);

  return (
    !isEmpty(sortedProducts) && (
      <ProductRecommendationList
        ProductCardComponent={ProductCardBestSeller}
        recommendationProducts={sortedProducts}
        title="Terlaris"
        titleStyle={s.title}
        productSection={title}
        headerStyle={s.header}
        containerStyle={s.container}
        contentStyle={cStyles.plm}
        trackingReferrerName={K_PROPERTY_OPENED_FROM_BEST_SELLER}
        trackingEventName={K_TRACK_CLICKED_BEST_SELLER}
        trackOnPressProductChildren={trackClickedBestSeller}
      />
    )
  );
};

BestSellerProduct.propTypes = {
  breadcrumb: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      additionalImages: PropTypes.array.isRequired,
      availability: PropTypes.shape({
        numAvailable: PropTypes.number,
        isAvailableToBuy: PropTypes.bool,
      }),
      attributes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      productOffer: PropTypes.shape(),
    }).isRequired,
  ).isRequired,
};

const s = StyleSheet.create({
  header: {
    ...cStyles.phxl,
    ...cStyles.mbm,
  },
  title: tStyles.h5,
  container: {
    ...cStyles.pts,
    ...cStyles.pbn,
  },
});

export default BestSellerProduct;
