import React, { memo, useMemo } from 'react';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

import { unslugify } from 'app-libs/etc';
import { useParams } from 'app-libs/hook_modules/router';

const CategoryJapandiPageFooter: React.FC = memo(() => {
  const { lvl0 = '' } = useParams<Record<string, string>>();

  const categoryString = unslugify(lvl0);

  const footerTitle = `Belanja ${categoryString} Japandi Online di Dekoruma: Praktis, Hemat, dan Bebas Ongkir!`;

  // @note(dika) 4 Nov 2024: Hardcoded as of now
  // we are not using article posts as footer anymore
  // instead we use template content
  const footerHtml = useMemo(() => {
    return {
      __html: `
      ${categoryString} Japandi, seperti namanya, adalah ${categoryString} yang dibuat dengan gaya Japandi, sebuah gaya interior yang menggabungkan karakter tradisional Jepang yang natural dan bersahaja, dengan karakter Skandinavian yang minimalis dan praktis.
      Desain Japandi menggabungkan konsep wabi-sabi dari Jepang dan lagom dari Skandinavia. Wabi-sabi adalah filosofi Jepang yang menghargai keindahan dalam ketidaksempurnaan. Dalam interior, konsep ini diterapkan dengan memaksimalkan ruang kecil dan sederhana agar nyaman, dengan tata letak yang efisien serta penggunaan furnitur multifungsi.
      Di sisi lain, lagom adalah filosofi hidup dari Skandinavia yang menekankan keseimbangan dalam segala aspek. Perpaduan kedua konsep ini menciptakan gaya Japandi yang simpel namun estetis, fungsional, dan sesuai dengan kebutuhan serta gaya hidup penghuninya.
      Karena desainnya yang simple, fungsional, tetapi memiliki unsur alami yang menenangkan sekaligus menyegarkan, desain Japandi pun menjadi semakin diminati akhir-akhir ini. Nah, buat kamu yang tertarik mengusung gaya ini, ${categoryString} Japandi bisa jadi pilihan. Dekoruma.com memiliki beragam ${categoryString} Japandi dengan desain yang estetik dan material yang berkualitas.
      Tak hanya itu, kamu bisa menikmati beragam keuntungan dengan berbelanja ${categoryString} Japandi di Dekoruma.com, yaitu:
      <h3>1. Produk Berkualitas dengan Desain Modern & Trendy</h3>
      Dekoruma menawarkan koleksi ${categoryString} Japandi yang up-to-date dengan model dan material berkualitas. Kami selalu mengikuti tren terkini, sehingga kamu bisa menemukan desain yang pas dengan gaya dan kebutuhan ruang. Pilih yang kamu suka, check out, dan langsung nikmati tampilan baru di rumahmu!
      <h3>2. Promo dan Diskon Setiap Hari</h3>
      Dapatkan produk ${categoryString} Japandi impianmu dengan harga yang lebih hemat! Dekoruma hadir dengan berbagai promo harian dan penawaran spesial yang bikin belanja jadi lebih menyenangkan. Jangan lewatkan kesempatan untuk menikmati harga terjangkau setiap hari!
      <h3>3. Harga ${categoryString} Terbaru Selalu Terupdate</h3>
      Ingin tahu harga terkini ${categoryString} Japandi? Di Dekoruma.com, kamu bisa cek daftar harga terbaru kapan saja, langsung dari ponselmu. Belanja online untuk kebutuhan rumah kini lebih mudah dan praktis.
      <h3>4. Layanan Pengiriman Bebas Ongkir ke Seluruh Indonesia*</h3>
      Nikmati layanan bebas ongkir ke berbagai wilayah di Indonesia, membuat belanja makin nyaman dan hemat. Tinggal pesan produk ${categoryString} Japandi pilihanmu, dan tunggu barang sampai di rumah tanpa biaya tambahan.
      <h3>5. Berbagai Pilihan Pembayaran dan Cicilan Ringan</h3>
      Sedang berencana untuk makeover ruangan? Dekoruma menyediakan opsi pembayaran fleksibel dan cicilan ringan, jadi kamu bisa langsung mendapatkan ${categoryString} Japandi impian tanpa khawatir soal biaya.
      <h3>6. Dekoruma Offline Store di Berbagai Kota</h3>
      Selain itu Dekoruma juga menyediakan ${categoryString} Japandi secara offline lewat toko offline Dekoruma yang terdapat di banyak kota di Indonesia. Kamu bisa datang, cek kualitas ${categoryString} Japandi kami, dan buktikan sendiri betapa cocoknya produk Dekoruma untuk rumah impianmu.
Segera kunjungi Dekoruma.com untuk kemudahan belanja ${categoryString} Japandi online yang nyaman dan praktis, dengan pilihan pengiriman ke seluruh Indonesia. Selain ${categoryString} Japandi kami juga menyediakan koleksi dekorasi dan furniture lainnya yang lengkap. Jadi, apapun rencana dekorasimu, Dekoruma siap menemani!
      `,
    };
  }, [categoryString]);
  return (
    <View style={s.container}>
      <Text h2 theme="b4-neutral500" style={cStyles.tac}>
        {footerTitle}
      </Text>
      <Text theme="b5-neutral100">
        <div dangerouslySetInnerHTML={footerHtml} />
      </Text>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pa16,
  },
});

export default CategoryJapandiPageFooter;
