export function genProductPurchase(line) {
  const { product, quantity } = line;
  let category = null;
  if (product.categories && product.categories.length) {
    category = product.categories[0].split(' > ').join('/');
  }
  return {
    id: product.objectID,
    sku: product.objectID,
    name: product.title,
    brand: product.brand,
    price: product.price,
    category: category,
    quantity: quantity,
  };
}

export function genPurchase(order, lines) {
  return {
    id: order.number,
    revenue: order.totalExclTax,
    shipping: order.shippingExclTax,
  };
}
