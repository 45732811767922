import keyMirror from 'keymirror';

import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';

export const AT = keyMirror({
  CREATE_PROJECT_MEMBER_ROLE: null,
  CREATE_PROJECT_MEMBER_ROLE_SUCCESS: null,
  CREATE_PROJECT_MEMBER_ROLE_FAIL: null,
});

export const initialState = {
  asyncState: Object.assign({}, initialAsyncState),
};

export default function projectMemberReducer(
  immutableState = initialState,
  action,
) {
  const state = Object.assign({}, immutableState);
  state.asyncState = asyncStateReducer(
    state.asyncState,
    action,
    '_PROJECT_MEMBER_ROLE_',
  );
  return state;
}
