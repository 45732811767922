import keyMirror from 'keymirror';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { normalize } from 'normalizr';

import config from 'config';

import { getIsOutOfStockByRoutingZoneCode } from 'app-libs/etc/productHelperV2';
import { getShippingCoveragesFromAreaSearchResult } from 'app-libs/etc/shippingCalculator';
import { Schemas } from 'app-libs/redux_modules/entities';
import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';
import {
  ALGOLIA_DEFAULT_INDEX_NAME,
  fetchObjects,
  fetchSearchResults,
} from 'app-libs/redux_modules/helper_modules/search/algoliaWrapper';

import { K_PRODUCT_OPTION_COLOR } from 'constants/productConstants';

import { customScoreConfigurationCollectionSchema } from './product/schema';

export const { ALGOLIA_AREA_INDEX_NAME } = config;

export const AT = keyMirror({
  INITIALIZE_PRODUCT: null,
  GET_PRODUCT_SHIPPING_COVERAGES: null,
  GET_PRODUCT_SHIPPING_COVERAGES_SUCCESS: null,
  GET_PRODUCT_SHIPPING_COVERAGES_FAIL: null,

  LOAD_PRODUCT_COLOR_SIBLINGS: null,
  LOAD_PRODUCT_COLOR_SIBLINGS_SUCCESS: null,
  LOAD_PRODUCT_COLOR_SIBLINGS_FAIL: null,

  LOAD_BRAND_STATS: null,
  LOAD_BRAND_STATS_SUCCESS: null,
  LOAD_BRAND_STATS_FAIL: null,

  LOAD_REVIEWED_PRODUCTS: null,
  LOAD_REVIEWED_PRODUCTS_SUCCESS: null,
  LOAD_REVIEWED_PRODUCTS_FAIL: null,

  LOAD_STOCK_RECORD_SHIPPING_FEE: null,
  LOAD_STOCK_RECORD_SHIPPING_FEE_SUCCESS: null,
  LOAD_STOCK_RECORD_SHIPPING_FEE_FAIL: null,

  LOAD_RECOMMENDED_FBT_CATEGORY: null,
  LOAD_RECOMMENDED_FBT_CATEGORY_SUCCESS: null,
  LOAD_RECOMMENDED_FBT_CATEGORY_FAIL: null,

  LOAD_CUSTOM_SCORE_CONFIGURATIONS: null,
  LOAD_CUSTOM_SCORE_CONFIGURATIONS_SUCCESS: null,
  LOAD_CUSTOM_SCORE_CONFIGURATIONS_FAIL: null,

  UPDATE_IS_FBT_MODAL_OPENED: null,
  UPDATE_CLICKED_FBT_PRODUCT: null,

  SET_RECOMMENDED_FBT_CATEGORY: null,
});

const initialState = {
  objectID: null,
  shippingCoverages: [],
  colorSiblingsByUpc: {},
  brandStats: {},
  reviewedProductsByBrand: {},
  shippingCharge: {},
  recommendedFbtCategoryByName: {},
  isFBTModalOpened: {},
  clickedFBTProducts: {},
  customScoreConfigurationsByName: {},
  customScoreConfigurationsAsyncState: initialAsyncState,
};

const K_COLOR_SIBLINGS_ATTRIBUTES_TO_RETRIEVE = ['*'];

const K_REVIEWED_PRODUCTS_ATTRIBUTES_TO_RETRIEVE = ['image', 'title'];

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Reducer
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export default function productReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;
  state.customScoreConfigurationsAsyncState = asyncStateReducer(
    state.customScoreConfigurationsAsyncState,
    action,
    '_CUSTOM_SCORE_CONFIGURATIONS_',
  );
  switch (action.type) {
    case AT.INITIALIZE_PRODUCT:
      state = Object.assign({}, action.payload);
      break;
    case AT.GET_PRODUCT_SHIPPING_COVERAGES:
      state = Object.assign({}, state, {
        shippingCoverages: [],
      });
      break;
    case AT.GET_PRODUCT_SHIPPING_COVERAGES_SUCCESS:
      state = Object.assign({}, state, {
        shippingCoverages: action.result,
      });
      break;
    case AT.GET_PRODUCT_SHIPPING_COVERAGES_FAIL:
      state = Object.assign({}, state, {
        shippingCoverages: [],
      });
      break;
    case AT.LOAD_PRODUCT_COLOR_SIBLINGS_SUCCESS:
      state = {
        ...state,
        colorSiblingsByUpc: action.result.colorSiblingsByUpc,
      };
      break;
    case AT.LOAD_BRAND_STATS_SUCCESS:
      state = {
        ...state,
        brandStats: {
          ...state.brandStats,
          [action.payload.brandName]: action.result,
          [action.result.name]: action.result,
        },
      };
      break;
    case AT.LOAD_REVIEWED_PRODUCTS_SUCCESS:
      state = {
        ...state,
        reviewedProductsByBrand: action.result,
      };
      break;
    case AT.LOAD_STOCK_RECORD_SHIPPING_FEE:
      state = {
        ...state,
        shippingCharge: {
          ...state.shippingCharge,
          loading: true,
        },
      };
      break;
    case AT.LOAD_STOCK_RECORD_SHIPPING_FEE_SUCCESS:
      state = {
        ...state,
        shippingCharge: {
          ...state.shippingCharge,
          loading: false,
          [action.payload.stockRecordId]: {
            ...state.shippingCharge?.[action.payload.stockRecordId],
            [action.payload.city]: action.result,
          },
        },
      };
      break;
    case AT.LOAD_STOCK_RECORD_SHIPPING_FEE_FAIL:
      state = {
        ...state,
        shippingCharge: {
          ...state.shippingCharge,
          loading: false,
          [action.payload.stockRecordId]: {
            ...state.shippingCharge?.[action.payload.stockRecordId],
            [action.payload.city]: { error: action.error },
          },
        },
      };
      break;
    case AT.LOAD_RECOMMENDED_FBT_CATEGORY_SUCCESS:
      state = {
        ...state,
        recommendedFbtCategoryByName: {
          [action.payload.category]: action.result,
        },
      };
      break;
    case AT.SET_RECOMMENDED_FBT_CATEGORY:
      state = {
        ...state,
        recommendedFbtCategoryByName: {
          [action.payload.category]: action.payload.result,
        },
      };
      break;
    case AT.LOAD_CUSTOM_SCORE_CONFIGURATIONS_SUCCESS:
      state = {
        ...state,
        customScoreConfigurationsByName:
          action.result.entities.customScoreConfigurationsByName,
      };
      break;
    case AT.UPDATE_IS_FBT_MODAL_OPENED:
      state = {
        ...state,
        isFBTModalOpened: {
          [action.payload.upc]: action.payload.isOpened,
        },
      };
      break;
    case AT.UPDATE_CLICKED_FBT_PRODUCT:
      state = {
        ...state,
        clickedFBTProducts: {
          ...state.clickedFBTProducts,
          [action.payload.upc]: action.payload.addToCartReferenceNote,
        },
      };
      break;
  }
  return state;
}

export function initializeProduct(product) {
  return {
    type: AT.INITIALIZE_PRODUCT,
    payload: product,
  };
}

export function loadProductColorSiblings(
  mainProducts,
  siblingsUpcs,
  routingZoneCode,
) {
  return {
    types: [
      AT.LOAD_PRODUCT_COLOR_SIBLINGS,
      AT.LOAD_PRODUCT_COLOR_SIBLINGS_SUCCESS,
      AT.LOAD_PRODUCT_COLOR_SIBLINGS_FAIL,
    ],
    payload: siblingsUpcs,
    promise: async () => {
      const siblingsProducts = await fetchObjects(
        siblingsUpcs,
        ALGOLIA_DEFAULT_INDEX_NAME,
        config.ALGOLIA_API_KEY,
        K_COLOR_SIBLINGS_ATTRIBUTES_TO_RETRIEVE,
      );
      return siblingsProducts;
    },
    options: {
      transformer: (result) => {
        const siblings = get(result, 'results', []);
        const productByUpcs = {};
        siblings.forEach((sibling) => {
          productByUpcs[sibling.objectID] = sibling;
        });
        const colorSiblingsByUpc = {};
        mainProducts.forEach((item) => {
          const { simplifiedOptions } = item;
          const colorVariant = get(simplifiedOptions, 'variants', []).find(
            (variant) => variant.type === K_PRODUCT_OPTION_COLOR,
          );
          const colorSiblings = get(colorVariant, 'upcs', []);
          const unsortedColorSiblings = [];
          if (!isEmpty(colorSiblings)) {
            unsortedColorSiblings.push(item);
          }
          colorSiblings.forEach((sibling) => {
            unsortedColorSiblings.push(productByUpcs[sibling]);
          });
          colorSiblingsByUpc[item.objectID] = unsortedColorSiblings
            .map((product) => {
              const isOutOfStock = getIsOutOfStockByRoutingZoneCode(
                product,
                routingZoneCode,
              );
              const isOnSale =
                get(product, 'productOffer.offer.discountValue', 0) > 0;
              return {
                ...product,
                isSoldOut: isOutOfStock,
                isOnSale,
              };
            })
            .sort((first, second) => {
              if (first.objectID === item.objectID) {
                return -1;
              }
              if (second.objectID === item.objectID) {
                return 1;
              }
              if (first.isSoldOut) {
                if (second.isSoldOut) {
                  return first.customScore < second.customScore ? 1 : -1;
                }
                return 1;
              }
              if (first.isOnSale) {
                if (second.isOnSale && !second.isSoldOut) {
                  return first.customScore < second.customScore ? 1 : -1;
                }
                return -1;
              }
              if (second.isSoldOut) {
                return -1;
              }
              if (second.isOnSale) {
                return 1;
              }
              return first.customScore < second.customScore ? 1 : -1;
            })
            .map((product) => product.objectID);
        });

        const normalizedSiblings = normalize(
          siblings,
          Schemas.PRODUCT_COLLECTION,
        );

        return {
          colorSiblingsByUpc,
          entities: normalizedSiblings.entities,
        };
      },
    },
  };
}

export function loadShippingCoverages(
  shippingClassNames,
  availability,
  callback,
) {
  const parameters = {
    maxValuesPerFacet: 1000,
    attributesToRetrieve: [
      'city',
      'island',
      'province',
      'isPromoFreeShipping',
      'shippingPricingCoverages',
      'price',
      'minDeliveryDay',
      'maxDeliveryDay',
    ],
    attributesToHighlight: [],
    facets: ['hareas.lvl0', 'hareas.lvl1', 'shippingPricingCoverages'],
    facetsRefinements: {
      'shippingPricingCoverages.shippingClassName': shippingClassNames,
    },
    distinct: true,
  };

  const searchParameters = Object.assign({}, parameters, {
    hitsPerPage: 999, // load district as much as we can (we assume cities of a province can't be more than 1000)
  });

  return {
    types: [
      AT.GET_PRODUCT_SHIPPING_COVERAGES,
      AT.GET_PRODUCT_SHIPPING_COVERAGES_SUCCESS,
      AT.GET_PRODUCT_SHIPPING_COVERAGES_FAIL,
    ],
    promise: () =>
      fetchSearchResults(searchParameters, ALGOLIA_AREA_INDEX_NAME),
    // usually we don't need this, but after changing node version and redux batch dispatcher, but somehow "then" doesn't work
    options: {
      transformer: (result) => {
        const shippingCoverages = getShippingCoveragesFromAreaSearchResult(
          result.content.hits,
          shippingClassNames,
          availability,
        );

        if (typeof callback === 'function') {
          callback(shippingCoverages);
        }

        return shippingCoverages;
      },
    },
  };
}

export function loadBrandStats(brandName) {
  return {
    types: [
      AT.LOAD_BRAND_STATS,
      AT.LOAD_BRAND_STATS_SUCCESS,
      AT.LOAD_BRAND_STATS_FAIL,
    ],
    payload: { brandName },
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/brands/stats/${brandName}/?format=json`,
      ),
  };
}

export function loadReviewedProducts(brandName) {
  return {
    types: [
      AT.LOAD_REVIEWED_PRODUCTS,
      AT.LOAD_REVIEWED_PRODUCTS_SUCCESS,
      AT.LOAD_REVIEWED_PRODUCTS_FAIL,
    ],
    promise: () =>
      fetchSearchResults(
        {
          facets: ['brand'],
          facetsRefinements: {
            brand: [brandName],
          },
          hitsPerPage: 1000,
          attributesToRetrieve: K_REVIEWED_PRODUCTS_ATTRIBUTES_TO_RETRIEVE,
        },
        ALGOLIA_DEFAULT_INDEX_NAME,
      ),
    options: {
      transformer: (result) => {
        const productByUpc = result.content.hits.reduce(
          (res, curr) => ({ ...res, [curr.objectID]: curr }),
          {},
        );
        return { [brandName]: productByUpc };
      },
    },
  };
}

export function loadStockRecordShippingFee(stockRecordId, city) {
  return {
    types: [
      AT.LOAD_STOCK_RECORD_SHIPPING_FEE,
      AT.LOAD_STOCK_RECORD_SHIPPING_FEE_SUCCESS,
      AT.LOAD_STOCK_RECORD_SHIPPING_FEE_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/stock-records/${stockRecordId}/shipping-fee/${city}/?format=json`,
      ),
    payload: { stockRecordId, city },
    options: {
      transformer: (result) => {
        if (isEmpty(result)) return {};
        return result;
      },
    },
  };
}

export function loadRecommendedFBTCategory(category) {
  return {
    types: [
      AT.LOAD_RECOMMENDED_FBT_CATEGORY,
      AT.LOAD_RECOMMENDED_FBT_CATEGORY_SUCCESS,
      AT.LOAD_RECOMMENDED_FBT_CATEGORY_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/categories/${category}/bought-togethers/?format=json`,
      ),
    payload: { category },
    options: {
      transformer: (result) => get(result, 'recommendedCategories', []),
    },
  };
}

export function setRecommendedFBTCategory(category, result) {
  return {
    type: AT.SET_RECOMMENDED_FBT_CATEGORY,
    payload: {
      category,
      result: result?.recommendedCategories ?? [],
    },
  };
}

export function loadCustomScoreConfigurations() {
  return {
    types: [
      AT.LOAD_CUSTOM_SCORE_CONFIGURATIONS,
      AT.LOAD_CUSTOM_SCORE_CONFIGURATIONS_SUCCESS,
      AT.LOAD_CUSTOM_SCORE_CONFIGURATIONS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/product/custom-score-configurations/?format=json`,
      ),
    options: {
      transformer: (result) => {
        const normalizedData = normalize(
          result,
          customScoreConfigurationCollectionSchema,
        );
        return normalizedData;
      },
    },
  };
}

/** To save FBT modal state (opened/closed) */
export function updateIsFBTModalOpened(upc, isOpened) {
  return {
    type: AT.UPDATE_IS_FBT_MODAL_OPENED,
    payload: { upc, isOpened },
  };
}

/** To save clicked products from FBT */
export function updateClickedFBTProduct(upc, addToCartReferenceNote) {
  return {
    type: AT.UPDATE_CLICKED_FBT_PRODUCT,
    payload: { upc, addToCartReferenceNote },
  };
}
