/**
 *  SAMPLE INPUT:
 *  1. intialExperiments: {'key': [{name: variantA}, {name: variantB}]}
 *  2. identifier: c41po10-abswq-asdffp
 */

/**
 *  SAMPLE OUTPUT:
 *  {
 *    experiments: {
 *      'key': {variants: [{name: variantA}, {name: variantB}], selectedVariantName: null}
 *    }
 *    identifier: 'c41po10-abswq-asdffp'
 *  }
 */

export default function createExperiments(initialExperiments, identifier) {
  const experiments = {};

  for (const [key, value] of Object.entries(initialExperiments)) {
    experiments[key] = {
      variants: value,
      selectedVariantName: null,
    };
  }

  const state = {
    experiments,
    identifier,
  };

  return state;
}
