import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import withDrawerConsumer from '@dkrm/general-libs/Context/DrawerContext/withDrawerConsumer';
import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';
import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { ChevronLeft, Menu, Share } from '@dkrm/icons';
import {
  Clipboard,
  Link,
  Text,
  TouchableOpacity,
  View,
} from '@dkrm/ui-kit-basic';
import { goBack } from '@dkrm/ui-kit-basic/Router';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { K_POSITION_BOTTOM_CENTER } from 'app-libs/components/Notification/BasePopup/constants';
import wv from 'app-libs/libs/webView';
import { showToast } from 'app-libs/redux_modules/flow_modules/notification';

import { K_ICON_SIZE } from './constants';
import styles from './styles';

@withDrawerConsumer
@withRouter
@connect(null, {
  actionShowToast: showToast,
})
export default class NavbarWithTitle extends React.PureComponent {
  static propTypes = {
    containerStyle: stylePropTypes,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    theme: PropTypes.string,
    iconSize: PropTypes.number,
    leftButtonIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.shape()]),
    leftButtonLinkProps: PropTypes.shape(),
    leftButtonProps: PropTypes.shape(ButtonWrapper.propTypes),
    shouldForceBackButtonWebView: PropTypes.bool,
    shouldShowLeftButton: PropTypes.bool,
    shouldShowRightButton: PropTypes.bool,
    rightButtons: PropTypes.arrayOf(
      PropTypes.shape({
        IconComponent: PropTypes.shape().isRequired,
        url: PropTypes.string,
        onPress: PropTypes.func,
        props: PropTypes.shape(),
      }),
    ),
    actionShowToast: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    queries: PropTypes.shape().isRequired,
    drawerRef: PropTypes.shape().isRequired,
    shouldShowShareButton: PropTypes.bool,
  };

  static defaultProps = {
    containerStyle: null,
    shouldShowLeftButton: true,
    shouldShowRightButton: true,
    theme: 'black',
    iconSize: K_ICON_SIZE,
    leftButtonIcon: Menu,
    leftButtonLinkProps: {},
    leftButtonProps: {},
    shouldForceBackButtonWebView: true,
    shouldShowShareButton: false,
    title: '',
    rightButtons: [],
  };

  actionShareButton = () => {
    const { location, actionShowToast } = this.props;
    /** Commented out because we don't actually need the text for now  */
    // const shareText = this.getOpenGraphMetaProperty('og:title').split('|')[0];
    const shareUrl = `https://www.dekoruma.com${location.pathname}${location.search}`;
    if (navigator.share) {
      try {
        navigator.share({
          text: '',
          url: shareUrl,
        });
      } catch {
        // pass
      }
    } else if (isWebView()) {
      wv.actionShare('', shareUrl);
    } else {
      Clipboard.setString(shareUrl);
      actionShowToast({
        message: 'Link berhasil disalin',
        position: K_POSITION_BOTTOM_CENTER,
        toastStyle: styles.toastStyle,
        textStyle: styles.toastText,
        containerStyle: { paddingBottom: 80 },
      });
    }
  };

  getOpenGraphMetaProperty = (metaProperty) => {
    return document
      .querySelector(`meta[property="${metaProperty}"]`)
      .getAttribute('content');
  };

  renderLeftButton() {
    const {
      drawerRef,
      theme,
      leftButtonLinkProps,
      leftButtonProps,
      leftButtonIcon,
      iconSize,
      shouldForceBackButtonWebView,
      history,
      queries,
    } = this.props;
    let IconComponent = leftButtonIcon;

    let forcedBackButtonProps = {};
    if (isWebView(queries) && shouldForceBackButtonWebView) {
      IconComponent = ChevronLeft;
      forcedBackButtonProps = {
        to: undefined,
        onPress: () => goBack(history),
      };
    }

    return (
      <ButtonWrapper
        data-cy="drawer-button"
        onPress={() => drawerRef?.current?.openDrawer()}
        linkProps={{
          shouldOpenNewTab: false,
          showUnderlineOnHover: false,
          ...leftButtonLinkProps,
        }}
        history={history}
        {...forcedBackButtonProps}
        {...leftButtonProps}
      >
        {() => (
          <View style={styles.iconContainer}>
            <IconComponent size={iconSize} theme={theme} />
          </View>
        )}
      </ButtonWrapper>
    );
  }

  renderTitle() {
    const { title } = this.props;
    if (React.isValidElement(title)) {
      return title;
    }
    return (
      <Text
        style={{
          paddingLeft: 10,
          fontWeight: '700',
          fontSize: 15,
        }}
      >
        {title}
      </Text>
    );
  }

  renderRightButtons() {
    const { rightButtons, theme, iconSize, shouldShowShareButton } = this.props;

    return (
      <View style={[cStyles.flexDirRow, cStyles.flexMiddleLeft]}>
        {shouldShowShareButton && (
          <TouchableOpacity
            style={styles.shareIcon}
            onPress={this.actionShareButton}
          >
            <Share size={22} theme="black" nofill />
          </TouchableOpacity>
        )}
        {rightButtons.map(
          (
            {
              IconComponent,
              url,
              showBadge,
              props = {},
              iconProps = {},
              onPress,
            },
            key,
          ) => {
            const { style, ...rest } = props;
            if (url) {
              return (
                <Link
                  key={key}
                  to={url}
                  isUsingAnchor
                  shouldOpenNewTab={false}
                  showUnderlineOnHover={false}
                  {...rest}
                >
                  <View style={[styles.iconContainer, style]}>
                    <View style={styles.iconSizer}>
                      <IconComponent
                        size={iconSize}
                        theme={theme}
                        showBadge={showBadge}
                        {...iconProps}
                      />
                    </View>
                  </View>
                </Link>
              );
            }

            return (
              <ButtonWrapper key={key} onPress={onPress} {...props}>
                {() => (
                  <View style={[styles.iconContainer, style]}>
                    <View style={styles.iconSizer}>
                      <IconComponent
                        size={iconSize}
                        theme={theme}
                        {...iconProps}
                      />
                    </View>
                  </View>
                )}
              </ButtonWrapper>
            );
          },
        )}
      </View>
    );
  }

  render() {
    const { containerStyle, shouldShowLeftButton, shouldShowRightButton } =
      this.props;

    return (
      <View style={[styles.container, containerStyle]}>
        <View style={styles.leftColInnerContainer}>
          {shouldShowLeftButton && this.renderLeftButton()}
          {this.renderTitle()}
        </View>
        {shouldShowRightButton && this.renderRightButtons()}
      </View>
    );
  }
}
