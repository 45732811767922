import React, { memo, useCallback } from 'react';
import config from 'config';
import { Image } from 'react-native';

import { StyleSheet, Colors, View, Text } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';
import wv from 'app-libs/libs/webView';

import cStyles from '@dkrm/general-libs/theme/styles';

const K_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/error/Error General.png?auto=webp`;

const K_LINK_PROPS = {
  style: cStyles.width100,
};

Image.prefetch(K_IMAGE_URL).catch(() =>
  // eslint-disable-next-line no-console
  console.warn(
    'Failed to prefetch error image. Ignore this if running on server',
  ),
);

const ErrorView: React.FC = memo(() => {
  const actionReload = useCallback(() => {
    if (isWebView()) wv.actionReload();
    else window.location.reload();
  }, []);

  return (
    <View style={s.container}>
      <Image
        source={{ uri: K_IMAGE_URL, width: 200, height: 200 }}
        style={s.image}
      />
      <Text theme="h3-black90" style={s.title}>
        Yahh, terjadi kesalahan.
      </Text>
      <Text theme="body3-black70" style={s.description}>
        Terjadi kesalahan pada saat memuat halaman. Silahkan coba untuk muat
        ulang.
      </Text>
      <ButtonWrapper
        onPress={actionReload}
        hoverableViewStyle={s.buttonContainer}
      >
        {() => (
          <View style={s.primaryButton}>
            <Text theme="body2-white">Muat Ulang</Text>
          </View>
        )}
      </ButtonWrapper>
      <ButtonWrapper
        to="/"
        linkProps={K_LINK_PROPS}
        hoverableViewStyle={s.buttonContainer}
      >
        {() => (
          <View style={s.secondaryButton}>
            <Text theme="body2-tosca">Kembali ke Halaman Utama</Text>
          </View>
        )}
      </ButtonWrapper>
    </View>
  );
});

export default ErrorView;

const s = StyleSheet.create({
  container: {
    ...cStyles.bgw,
    ...cStyles.flexMiddle,
    padding: 40,
    width: '100vw',
    height: '100vh',
  },
  image: {
    width: 200,
    height: 200,
  },
  title: {
    ...cStyles.tac,
    marginTop: 40,
  },
  description: {
    ...cStyles.tac,
    ...cStyles.mtm,
  },
  buttonContainer: {
    ...cStyles.width100,
    ...cStyles.mtxl,
  },
  primaryButton: {
    ...cStyles.width100,
    ...cStyles.brs,
    ...cStyles.flexMiddle,
    backgroundColor: Colors.C_PRIMARY_TOSCA,
    paddingVertical: 10,
  },
  secondaryButton: {
    ...cStyles.width100,
    ...cStyles.brs,
    ...cStyles.flexMiddle,
    ...cStyles.bwam,
    borderColor: Colors.C_PRIMARY_TOSCA,
    paddingVertical: 9,
  },
});
