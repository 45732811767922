import React from 'react';
import { Link } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';
import cStyles from '@dkrm/general-libs/theme/styles';

interface Props {
  logoUri: string;
  width: number;
  height: number;
}

export const DekorumaLogo: React.FC<Props> = ({ logoUri, width, height }) => {
  return (
    <Link isUsingAnchor to="/" style={cStyles.flexMiddle}>
      <SmartImage
        source={logoUri}
        width={width}
        height={height}
        additionalQuery={{
          fit: 'bounds',
        }}
      />
    </Link>
  );
};

export default DekorumaLogo;
