import keyMirror from 'keymirror';
import invert from 'lodash/invert';

import config from 'config';

const K_BANK_KEY = keyMirror({
  BANK_BCA: null,
  BANK_MANDIRI: null,
  BANK_BRI: null,
  BANK_BNI: null,
  BANK_PERMATA: null,
});

/**
 * This sequence is to loop all bank, for example:
 *   K_BANK_KEY_SEQUENCE.map(bankKey => {
 *     return K_BANK_NAME[bankKey]
 *   })
 */
export const K_BANK_KEY_SEQUENCE = [
  K_BANK_KEY.BANK_BCA,
  K_BANK_KEY.BANK_MANDIRI,
];
export const K_VIRTUAL_ACCOUNT_KEY_SEQUENCE = [
  K_BANK_KEY.BANK_BCA,
  K_BANK_KEY.BANK_PERMATA,
];

/**
 * Bank constant
 */

export const K_BANK_MANDIRI = 'BANK_MANDIRI';
export const K_BANK_BCA = 'BANK_BCA';

export const K_BANK_NAME = {
  BANK_BCA: 'Bank Central Asia (BCA)',
  BANK_MANDIRI: 'Bank Mandiri',
  BANK_BRI: 'Bank Rakyat Indonesia (BRI)',
  BANK_BNI: 'Bank Negara Indonesia (BNI)',
  BANK_PERMATA: 'Bank Permata',
  BANK_UOB: 'Bank UOB',
  BANK_STANDARD_CHARTERED: 'Bank Standard Chartered',
  BANK_HSBC: 'Bank HSBC',
  BANK_CITIBANK: 'Citibank',
  BANK_MNC: 'MNC Bank',
  BANK_MAYBANK: 'Maybank',
  BANK_PANIN: 'Panin Bank',
  BANK_OCBC_NISP: 'Bank OCBC NISP',
  BANK_BUKOPIN: 'Bank Bukopin',
  BANK_CIMB_NIAGA: 'Bank CIMB Niaga',
  BANK_DIGIBANK_DBS: 'Bank Digibank DBS',
  BANK_BTN: 'Bank Tabungan Negara (BTN)',
  BANK_MEGA: 'Bank Mega',
};

export const K_BANK_NAME_FROM_GOBLIN = {
  DEFAULT: 'Default',
  BANK_BCA: 'BCA',
  BANK_MANDIRI: 'Mandiri',
  BANK_BRI: 'BRI',
  BANK_BNI: 'BNI',
  BANK_PERMATA: 'Permata',
  BANK_UOB: 'UOB INDONESIA',
  BANK_STANDARD_CHARTERED: 'STANDARD CHARTERED BANK',
  BANK_HSBC: 'HSBC',
  BANK_CITIBANK: 'CITIBANK',
  BANK_MNC: 'MNC BANK',
  BANK_MAYBANK: 'Maybank (d/h BII)',
  BANK_PANIN: 'BANK PANIN',
  BANK_OCBC_NISP: 'OCBC NISP',
  BANK_BUKOPIN: 'BUKOPIN',
  BANK_CIMB_NIAGA: 'Bank CIMB Niaga / Rekening Ponsel',
  BANK_DIGIBANK_DBS: 'BANK DBS INDONESIA',
  BANK_BTN: 'BTN (BANK TABUNGAN NEGARA)',
  BANK_MEGA: 'BANK MEGA',
};

export const K_BANK_KEY_FROM_GOBLIN_BANK_NAME = invert(K_BANK_NAME_FROM_GOBLIN);

export const K_BANK_NAME_SHORT = {
  BANK_BCA: 'BCA',
  BANK_MANDIRI: 'Mandiri',
  BANK_BRI: 'BRI',
  BANK_BNI: 'BNI',
  BANK_PERMATA: 'Permata',
  BANK_UOB: 'UOB',
  BANK_STANDARD_CHARTERED: 'Standard Chartered',
  BANK_HSBC: 'HSBC',
  BANK_CITIBANK: 'Citibank',
  BANK_MNC: 'MNC BANK',
  BANK_MAYBANK: 'Maybank',
  BANK_PANIN: 'Panin Bank',
  BANK_OCBC_NISP: 'Bank OCBC NISP',
  BANK_BUKOPIN: 'Bukopin',
  BANK_CIMB_NIAGA: 'CIMB Niaga',
  BANK_DIGIBANK_DBS: 'Bank Digibank DBS',
  BANK_BTN: 'BTN',
  BANK_MEGA: 'Mega',
};

/**
 * Our bank account's related
 */

/** Our account holder name */
export const K_BANK_ACCOUNT_NAME = 'PT DEKORUMA INOVASI LESTARI';

/** Our account's branch for each bank */
export const K_BANK_ACCOUNT_BRANCH = {
  BANK_BCA: 'KCU Kedoya Permai',
  BANK_MANDIRI: 'KCP Rawasari',
  BANK_BRI: 'KCP Green Pramuka',
  BANK_BNI: 'KCP Mall of Indonesia',
  BANK_PERMATA: 'VA',
};

/** Our account's number for each bank */
export const K_BANK_ACCOUNT_NUMBER = {
  BANK_BCA: '3721 71 72 73',
  BANK_MANDIRI: '123 0090 111 909',
  BANK_BRI: '1815 01 0000 11 30 2',
  BANK_BNI: '777 100 700 9',
  BANK_PERMATA: '777 100 700 9AAA',
};

export const K_DNB_BANK_ACCOUNT_NUMBER = {
  BANK_BCA: '3724 80 09 00',
};

/** Our DekoHub account's number for each bank */
export const K_DEKOHUB_BANK_ACCOUNT_NUMBER = {
  BANK_BCA: '3727 70 02 29',
};

/** Transfer Methods of BCA Virtual Account */
export const K_VIRTUAL_ACCOUNT_TRANSFER_METHODS_BCA = {
  ATM_BCA: { key: 'ATM_BCA', value: 'ATM BCA' },
  KLIKBCA: { key: 'KLIKBCA', value: 'KlikBCA' },
  MBANKING: { key: 'MBANKING', value: 'M-Banking' },
};

/** Credit Card Related Information */
export const K_CREDIT_CARD_TYPE = {
  MASTERCARD: 'mastercard',
  VISA: 'visa',
  AMEX: 'amex',
  JCB: 'jcb',
};

export const K_INVALID_CARD_NUMBER = 'Invalid card number';
export const K_INVALID_CARD_TYPE = 'Please use Visa or MasterCard';
export const K_EXPIRED_CARD = 'Expired Card';

/** Credit Card Installment Term (in months) */
export const K_DEFAULT_BANK_CREDIT_CARD_TERMS = [3, 6, 12];
export const K_CREDIT_CARD_INSTALLMENT_TERMS = {
  BANK_MANDIRI: [3, 6, 12, 18, 24],
  BANK_BNI: [3, 6, 12, 18, 24],
  BANK_BCA: [3, 6, 12],
  BANK_BRI: [3, 6, 12],
  BANK_PERMATA: [3, 6, 12, 18, 24],
  BANK_STANDARD_CHARTERED: [3, 6, 12],
  BANK_HSBC: [3, 6, 12],
  BANK_CITIBANK: [3, 6, 12, 18, 24],
  BANK_MNC: [3, 6, 12],
  BANK_MAYBANK: [3, 6, 12],
  BANK_PANIN: [3, 6, 12],
  BANK_OCBC_NISP: [3, 6, 12, 18, 24],
  BANK_UOB: [3, 6, 12, 18, 24],
  BANK_MEGA: [3, 6],
  BANK_BUKOPIN: [3, 6, 12],
  BANK_CIMB_NIAGA: [6, 12],
  BANK_DIGIBANK_DBS: [3, 6, 12],
  BANK_BTN: [3, 6, 12],
};

export const K_CREDIT_CARD_INSTALLMENT_ADMIN_FEE_BY_BANK_KEY = {
  BANK_STANDARD_CHARTERED: 20000,
  BANK_PERMATA: 15000,
  BANK_OCBC_NISP: 15000,
};

export const K_MINIMUM_TOTAL_PAYABLE_BY_INSTALLMENT_TERMS = {
  3: 2000000,
  6: 5000000,
  12: 10000000,
  18: 15000000,
  24: 20000000,
};

export const K_MIDTRANS_BANK_CODE = {
  BANK_MANDIRI: 'mandiri',
  BANK_BNI: 'bni',
  BANK_BCA: 'bca',
  BANK_MAYBANK: 'maybank',
  BANK_CIMB_NIAGA: 'cimb',
};

export const K_BANK_KEY_BY_MIDTRANS_BANK_CODE = invert(K_MIDTRANS_BANK_CODE);

export const K_MAX_DAYS_BANK_TRANSFER_PAYMENT = 1;

export const K_BANK_LOGO = {
  MANDIRI: `${config.API_URL_MEDIA_CDN}/assets/bank/mandiri.png?auto=webp`,
  BCA: `${config.API_URL_MEDIA_CDN}/assets/bank/bca.png?auto=webp`,
  BANK_BRI: `${config.API_URL_MEDIA_CDN}/assets/bank/bri.png?auto=webp`,
  BANK_BNI: `${config.API_URL_MEDIA_CDN}/assets/bank/bni.png?auto=webp`,
  BANK_PERMATA: `${config.API_URL_MEDIA_CDN}/assets/bank/permata.png?auto=webp`,
  BANK_STANDARD_CHARTERED: `${config.API_URL_MEDIA_CDN}/assets/bank/standard-chartered.png?auto=webp`,
  BANK_HSBC: `${config.API_URL_MEDIA_CDN}/assets/bank/hsbc.png?auto=webp`,
  BANK_MNC: `${config.API_URL_MEDIA_CDN}/assets/bank/mnc.png?auto=webp`,
  BANK_MAYBANK: `${config.API_URL_MEDIA_CDN}/assets/bank/maybank.png?auto=webp`,
  BANK_PANIN: `${config.API_URL_MEDIA_CDN}/assets/bank/panin.png?auto=webp`,
  BANK_OCBC_NISP: `${config.API_URL_MEDIA_CDN}/assets/bank/ocbc.png?auto=webp`,
  BANK_BUKOPIN: `${config.API_URL_MEDIA_CDN}/assets/bank/bukopin.png?auto=webp`,
  BANK_CIMB_NIAGA: `${config.API_URL_MEDIA_CDN}/assets/bank/cimb-niaga.png?auto=webp`,
  BANK_DIGIBANK_DBS: `${config.API_URL_MEDIA_CDN}/assets/bank/digibank.png?auto=webp`,
  BANK_BTN: `${config.API_URL_MEDIA_CDN}/assets/bank/BTN.png?auto=webp`,
};

export const K_ASSET_BANK_TRANSFER_FLOW = {
  IMAGE_1: `${config.API_URL_MEDIA_CDN}/assets/bank_transfer_flow_1.png?auto=webp`,
  IMAGE_2: `${config.API_URL_MEDIA_CDN}/assets/bank_transfer_flow_2.png?auto=webp`,
  IMAGE_3: `${config.API_URL_MEDIA_CDN}/assets/bank_transfer_flow_3.png?auto=webp`,
  IMAGE_4: `${config.API_URL_MEDIA_CDN}/assets/bank_transfer_flow_4.png?auto=webp`,
};
