import React from 'react';
import { View, Link, StyleSheet } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';
import { useMediaQuery } from 'entities/footer/hooks';
import {
  K_MOBILE_FOOTER_BREAKPOINT,
  FooterItem,
} from 'components/Footer/constants';

import { TopCategorySectionItem } from '../TopCategorySectionItem';

interface TopCategorySectionListProps {
  footerItems: FooterItem[];
}

export const TopCategorySectionList: React.FC<TopCategorySectionListProps> = ({
  footerItems,
}) => {
  const isMobile = useMediaQuery(K_MOBILE_FOOTER_BREAKPOINT);

  return (
    <View style={[s.topContent, isMobile && s.mTopContent]}>
      {footerItems.map(({ label, linkUrl }: FooterItem) => (
        <View style={cStyles.mvm} key={`footer-section-item-${label}`}>
          <Link isUsingAnchor to={linkUrl}>
            <TopCategorySectionItem label={label} />
          </Link>
        </View>
      ))}
    </View>
  );
};

const s = StyleSheet.create({
  topContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    ...cStyles.flexDirRow,
    ...cStyles.width100,
    justifyContent: 'space-between',
  },

  mTopContent: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});
