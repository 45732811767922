import {
  K_ROUTE_CART,
  K_ROUTE_OFFLINE_BASKET_LIST,
  K_ROUTE_CHECKOUT_SHIPPING_ADDRESS,
} from 'constants/routeConstants';

export const K_ROUTES_WITHOUT_OFFLINE_BASKET_FLOATING_ENTRYPOINT = [
  K_ROUTE_OFFLINE_BASKET_LIST,
  K_ROUTE_CART,
  K_ROUTE_CHECKOUT_SHIPPING_ADDRESS,
];
