import createStore from 'app-libs/redux/create';

import ApiClient from 'app-libs/redux/ApiClient';
import reducer from '../reducers';

const configureStore = (preloadedState = {}, req = undefined) => {
  const apiClient = new ApiClient(req);
  const store = createStore(apiClient, preloadedState, reducer);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(reducer);
    });
  }

  return store;
};

export default configureStore;
