import { templateWithoutSanitation } from 'app-libs/etc/templateHelper';

export const K_LANG_CS_PHONE_NUMBER = '(021) 5098-6977';
export const K_LANG_CS_EMAIL = 'hello@dekoruma.com';

export const K_IS_FASTING_PERIOD = false;

let csWorkingHourText = 'Senin - Minggu 09:00 - 18:00';
if (K_IS_FASTING_PERIOD) {
  csWorkingHourText = 'Senin - Minggu 08:00 - 17:00';
}
export const K_LANG_CS_WORKING_HOUR = csWorkingHourText;

export const K_LANG_CALL_CS = `mohon menghubungi customer service kami di ${K_LANG_CS_PHONE_NUMBER}`;
export const K_LANG_CALL_CS_IN_WORKING_HOUR = `${K_LANG_CALL_CS} pada hari ${K_LANG_CS_WORKING_HOUR}`;
export const K_LANG_IF_Q_CALL_CS = `Apabila ada pertanyaan, ${K_LANG_CALL_CS_IN_WORKING_HOUR}`;
export const K_LANG_SIMILAR_ITEMS = 'Produk Serupa';
export const K_LANG_RECOMMENDED_SERIES = 'Seri Lain Produk Ini';

export const K_LANG_B2B_PHONE_NUMBER = K_LANG_CS_PHONE_NUMBER; // deprecated: no more B2b in dekoruma, route to CS

// product option title
export const K_LANG_PRODUCT_OPTION_NAVIGATION_OPTION_VALUE_TITLE =
  'Option Value';
export const K_LANG_PRODUCT_OPTION_NAVIGATION_ADDON_PRODUCT_TITLE =
  'Product Addon';
export const K_LANG_PRODUCT_OPTION_NAVIGATION_ADDON_GROUP_TITLE = 'Addon Group';

// product material disclaimer
export const K_LANG_PRODUCT_MATERIAL_DISCLAIMER =
  'Pastikan bahwa spesifikasi produk telah sesuai dengan keinginan Anda untuk mencegah kesalahan ketika membeli.';
export const K_LANG_SHIPPING_COST_DISCLAIMER =
  '<a target="_blank" href="https://medium.com/@arunaharsa/biaya-pengiriman-furniture-yang-harus-dibayarkan-f21cd1dcd57c#.hdtk5nhu6">Detail biaya pengiriman lihat di sini</a>.';

// product credit card installment
export const K_ADD_MORE_PRODUCT_INSTALLMENT =
  'Belanja lagi s/d Rp 500,000 & nikmati';
export const K_INSTALLMENT_AVAILABLE = 'Selamat! Kamu bisa menikmati';
export const K_INSTALLMENT_PROMO = 'promo cicilan 0%';

export const K_IS_HOLIDAY = false;
export const K_LANG_HOLIDAY_NOTICE_MTO =
  'Produksi barang akan tertunda hingga tanggal 21 Juni dikarenakan libur lebaran';

let stockMadeToOrderText = 'Stok langsung dibuat setelah pemesanan';
if (K_IS_HOLIDAY) {
  stockMadeToOrderText = K_LANG_HOLIDAY_NOTICE_MTO;
}

export const K_LANG_STOCK_MADE_TO_ORDER = stockMadeToOrderText;
export const K_LANG_HOLIDAY_NOTICE =
  'Sehubungan dengan hari raya Idul Fitri, pengiriman dan produksi barang untuk marketplace, serta konsultasi jasa desain interior akan tertunda hingga 21 Juni 2018.';
export const K_LANG_HOLIDAY_NOTICE_SHORT =
  'Sehubungan dengan hari raya Idul Fitri, pengiriman, produksi, dan konsultasi desain interior tertunda hingga 21 Juni 2018.';

// order status
export const K_LANG_ORDER_PLEASE_COMPLETE_PAYMENT =
  'Mohon Selesaikan Pembayaran Anda';
export const K_LANG_ORDER_PAYMENT_NOT_FULLY_PAID = 'Pembayaran belum penuh';
export const K_LANG_ORDER_PROCESSING_PAYMENT =
  'Pembayaran Anda sedang diproses';
export const K_LANG_ORDER_WAIT_PAYMENT_VERIFICATION =
  'Konfirmasi pembayaran Anda diterima. Kami akan segera memverifikasi pembayaran Anda dalam 1 hari kerja.';
export const K_LANG_ORDER_FAILED_PAYMENT = 'Pembayaran Anda gagal';
export const K_LANG_ORDER_WAIT_SHIPMENT = 'Pesanan anda akan segera tiba';
export const K_LANG_ORDER_DONE = 'Pesanan sudah Anda terima';
export const K_LANG_ORDER_CANCELLED = 'Pesanan Anda dibatalkan';
export const K_LANG_ORDER_COD =
  'Mohon menunggu kedatangan pesanan Anda, dan siapkan pembayaran sesuai dengan pesanan Anda.';
export const K_LANG_ORDER_COD_ADDITIONAL =
  'Kurir kami akan mengantar pesanan Anda, mohon memeriksa pesanan Anda dan melakukan pembayaran ke kurir kami ketika barang diterima.';

// order status simplified
export const K_LANG_ORDER_PLEASE_COMPLETE_PAYMENT_SIMPLIFIED =
  'Menunggu Pembayaran';
export const K_LANG_ORDER_PROCESSING_PAYMENT_SIMPLIFIED =
  'Verifikasi Pembayaran';
export const K_LANG_ORDER_DONE_SIMPLIFIED = 'Barang Sampai';
export const K_LANG_ORDER_CANCELLED_SIMPLIFIED = 'Dibatalkan';
export const K_LANG_ORDER_WAIT_SHIPMENT_SIMPLIFIED = 'Proses Pengiriman';
export const K_LANG_ORDER_FAILED_PAYMENT_SIMPLIFIED = 'Pembayaran Gagal';

// order status additional info simplified template
export const K_LANG_ORDER_PLEASE_COMPLETE_PAYMENT_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Menunggu Pembayaran`;
export const K_LANG_ORDER_PROCESSING_PAYMENT_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Pesanan Anda dalam proses verifikasi oleh Dekoruma
Waktu Upload Pembayaran: ${0}`;
export const K_LANG_ORDER_DONE_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Pesanan Anda telah sampai
Waktu Pesanan Sampai: ${0}`;
export const K_LANG_ORDER_CANCELLED_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Dibatalkan otomatis oleh sistem Dekoruma karena anda tidak melakukan pembayaran tepat waktu
Waktu Dibatalkan: ${0}`;
export const K_LANG_ORDER_WAIT_SHIPMENT_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Pesanan Anda dalam pengiriman oleh kurir
Waktu Verifikasi Pembayaran: ${0}`;
export const K_LANG_ORDER_FAILED_PAYMENT_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Pembayaran Gagal`;
export const K_LANG_ORDER_PAYMENT_NOT_FULLY_PAID_SIMPLIFIED_TEMPLATE = templateWithoutSanitation`Pembayaran belum penuh`;

// unique code
export const K_LANG_NOTIFY_UNIQUE_CODE_TRANSFER = `Transfer tepat sampai 3 digit terakhir.`;
export const K_LANG_NOTIFY_UNIQUE_CODE_TRASNFER_ADDITIONAL_INFO = `Perbedaan nominal menghambat proses verifikasi.`;
export const K_LANG_AUTO_VERIFY_BANK_TRANSFER = `Bila Anda melakukan transfer ke rekening BCA, maka verifikasi akan dilakukan secara otomatis, dan Anda akan menerima email konfirmasi segera setelah pembayaran.`;

// failed cc
export const K_LANG_FAILED_CC_SOLUTION_PARENT =
  'Berikut hal-hal yang dapat Anda lakukan:';
export const K_LANG_FAILED_CC_SOLUTION_LINES = [
  'Cek kembali respons kartu Anda.',
  'Pastikan data kartu yang Anda masukkan sudah tepat dan benar.',
  'Jika berstatus "do not honour [05]", silahkan hubungi pihak bank yang menerbitkan kartu Anda.',
  'Gunakan kartu lain.',
];

// design and build
export const K_LANG_BANK_CUSTOMER_NAME = 'PT DEKORUMA INOVASI LESTARI';
export const K_LANG_BCA_ACCOUNT_NUMBER = '372-4800900';

export const K_LANG_HOLIDAY_NOTICE_DNB =
  'Konsultasi jasa desain interior akan tertunda hingga 21 Juni dikarenakan libur lebaran';

// read more
export const K_LANG_READ_MORE = 'tampilkan lebih banyak';
export const K_LANG_READ_LESS = 'tampilkan lebih sedikit';
