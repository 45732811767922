import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import React from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Row, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import {
  getHCategoryImageUrl,
  getHCategoryLevelBreadcrumbRightMost,
} from 'app-libs/etc/categoryHelper';

import styles from './styles';

export default class FilterList extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        selected: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    itemComponent: PropTypes.func.isRequired,
    label: PropTypes.string,
    labelStyle: stylePropTypes,
    listStyle: stylePropTypes,
    numColumns: PropTypes.number,
    title: PropTypes.string,
    titleStyle: stylePropTypes,
    level: PropTypes.number,
    page: PropTypes.number,
    brand: PropTypes.string,
    shouldShowListTitle: PropTypes.bool,
    shouldShowSeeMoreButton: PropTypes.bool,
    seeMoreButtonProps: PropTypes.shape({
      title: PropTypes.string,
      theme: PropTypes.string,
      themeType: PropTypes.string,
      buttonStyle: styles.stylePropTypes,
      onPress: PropTypes.func,
    }),
    sortComparator: PropTypes.func,
  };

  static defaultProps = {
    labelStyle: {},
    label: null,
    listStyle: {},
    numColumns: 1,
    title: null,
    titleStyle: {},
    level: null,
    page: null,
    brand: null,
    shouldShowListTitle: true,
    shouldShowSeeMoreButton: false,
    seeMoreButtonProps: null,
    sortComparator: () => null,
  };

  getListTitle() {
    const { title, data, page, brand } = this.props;
    if (!data.length && page > 0) return title;
    if (brand) return `Semua Produk ${title}`;
    if (data.length) return `Semua ${title}`;
    return '';
  }

  renderItemChunk() {
    const {
      data,
      onChange,
      itemComponent: Item,
      listStyle,
      numColumns,
      page,
      sortComparator,
      ...rest
    } = this.props;
    if (page > 0) return null;
    const sortedData = [...data].sort(sortComparator);
    const dataChunks = chunk(sortedData, numColumns);
    return (
      <View style={listStyle}>
        {sortedData &&
          dataChunks.map((chk, i) => (
            <View key={i} style={styles.itemChunkStyles}>
              <Row
                style={{
                  width:
                    chk.length < numColumns
                      ? `${(100 * chk.length) / numColumns}%`
                      : '100%',
                }}
              >
                {chk.map((cat) => (
                  <Item
                    key={cat.value}
                    onChange={onChange}
                    imageUri={getHCategoryImageUrl(
                      getHCategoryLevelBreadcrumbRightMost(cat.value),
                      cat.label,
                    )}
                    {...rest}
                    {...cat}
                  />
                ))}
              </Row>
            </View>
          ))}
      </View>
    );
  }

  renderSeeMoreButton() {
    const { seeMoreButtonProps } = this.props;
    return <ButtonWithText {...seeMoreButtonProps} />;
  }

  renderListTitle() {
    const { titleStyle } = this.props;
    const text = this.getListTitle();
    return (
      <Text theme="h4" style={[styles.titleStyles, titleStyle]}>
        {text}
      </Text>
    );
  }

  render() {
    const { label, labelStyle, shouldShowListTitle, shouldShowSeeMoreButton } =
      this.props;
    return (
      <View style={styles.flexDirCol}>
        {!!label && (
          <Text style={[styles.baseLabelStyles, labelStyle]}>{label}</Text>
        )}
        {this.renderItemChunk()}
        {shouldShowSeeMoreButton && this.renderSeeMoreButton()}
        {shouldShowListTitle && this.renderListTitle()}
      </View>
    );
  }
}
