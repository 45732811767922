import superagent from 'superagent';
import { DjangoPaginatedResponse } from 'types';

import config from 'config';

import { makeSerializedParameters } from 'app-libs/etc';

import {
  CategorySEO,
  LoadCategorySEOPayload,
  SEOArea,
  SEOArticle,
  SEOTopSearchQuery,
} from './types';

export const getLoadSEOAreasQueryKey = () => ['seo-areas'];
export const getLoadSEOTopSearchQueriesQueryKey = () => [
  'seo-top-search-queries',
];

export const getLoadCategorySEOQueryKey = (payload: LoadCategorySEOPayload) => [
  'category-seo',
  payload.lvl0,
  payload.lvl1,
  payload.lvl2,
];

export const getLoadSEOArticleQueryKey = (articleId?: string) => [
  'seo-article',
  articleId,
];

export const loadSEOAreas = async (): Promise<SEOArea[]> => {
  const res = await superagent.get(`${config.API_URL_KLAW}/seo-areas/`);

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const loadSEOTopSearchQueries = async (): Promise<
  DjangoPaginatedResponse<SEOTopSearchQuery>
> => {
  const res = await superagent.get(
    `${config.API_URL_KLAW}/top-search-queries/`,
  );

  return res.body;
};

export const loadCategorySEO = async (
  payload: LoadCategorySEOPayload,
): Promise<CategorySEO[]> => {
  const qs = makeSerializedParameters({
    /* eslint-disable camelcase */
    hcategories_lvl0: payload.lvl0,
    hcategories_lvl1: payload.lvl1,
    hcategories_lvl2: payload.lvl2,
    /* eslint-enable camelcase */
  });

  const res = await superagent.get(
    `${config.API_URL_KLAW}/category-seos/?${qs}`,
  );
  return res.body;
};

export const loadSEOArticle = async (
  articleId?: string,
): Promise<SEOArticle> => {
  const res = await superagent.get(`${config.API_URL_4}/posts/${articleId}`);
  return res.body;
};
