import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, View } from '@dkrm/ui-kit-basic';

export default class SimpleFilterList extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        selected: PropTypes.bool.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    itemComponent: PropTypes.func.isRequired,
    label: PropTypes.string,
    labelStyle: stylePropTypes,
  };

  static defaultProps = {
    labelStyle: {},
    label: null,
  };

  render() {
    const baseLabelStyles = [styles.fwb, styles.fs6];
    const {
      label,
      labelStyle,
      data,
      onChange,
      itemComponent: Item,
      ...rest
    } = this.props;
    return (
      <View style={styles.flexDirCol}>
        {label && <Text style={[...baseLabelStyles, labelStyle]}>{label}</Text>}
        <View style={[styles.flexDirRow, styles.flexWrap]}>
          {data &&
            data.map((item) => (
              <Item key={item.value} onChange={onChange} {...item} {...rest} />
            ))}
        </View>
      </View>
    );
  }
}
