import produce from 'immer';
import keyMirror from 'keymirror';
import { ApiClient } from 'types';

import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';

import { loadProductBundleOffersAPI } from './api';
import { GroupedBundleOfferItems } from './types';

export const AT = keyMirror({
  LOAD_PRODUCT_BUNDLE_OFFERS: null,
  LOAD_PRODUCT_BUNDLE_OFFERS_SUCCESS: null,
  LOAD_PRODUCT_BUNDLE_OFFERS_FAIL: null,
});

export const initialState = {
  bundleOffersByStockRecordId: {},
  bundleOffersAsyncState: initialAsyncState,
};

const bundleOfferReducer = produce((draft = initialState, action) => {
  draft.bundleOffersAsyncState = asyncStateReducer(
    draft.bundleOffersAsyncState,
    action,
    '_PRODUCT_BUNDLE_OFFERS_',
  );

  switch (action.type) {
    case AT.LOAD_PRODUCT_BUNDLE_OFFERS_SUCCESS:
      if (action.result) {
        const {
          result,
          payload: { stockRecordId },
        } = action;
        if (stockRecordId) {
          draft.bundleOffersByStockRecordId[stockRecordId] = result;
        }
      }
      break;
  }

  return draft;
}, initialState);

export const loadProductBundleOffers = (stockRecordId: number) => {
  return {
    types: [
      AT.LOAD_PRODUCT_BUNDLE_OFFERS,
      AT.LOAD_PRODUCT_BUNDLE_OFFERS_SUCCESS,
      AT.LOAD_PRODUCT_BUNDLE_OFFERS_FAIL,
    ],
    promise: (client: ApiClient<GroupedBundleOfferItems[]>) =>
      loadProductBundleOffersAPI(client, stockRecordId),
    payload: {
      stockRecordId,
    },
  };
};

export default bundleOfferReducer;
