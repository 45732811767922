import isEmpty from 'lodash/isEmpty';
import {
  K_SORT,
  K_QUERY,
  K_PAGE,
  K_DF,
  K_DFR,
  K_FR,
  K_FR_ALL,
  K_NR,
  K_HITS_PER_PAGE,
  K_HF,
  K_HFR,
  K_DISTINCT,
} from 'constants/routeConstants';

const showAllValuesPerFacet = 100;
const defaultMaxValuesPerFacet = 100;

/**
 * Convert  a human readable version of query parameters to a (partial) search state tree
 *
 * @function
 * @param {Object} queryParams - The human readable version of query parameters
 * @returns {Object} state - The state tree object of globalState.search
 */
export function convertQueryParamsToState(queryParams) {
  const state = {};

  for (const [key, value] of Object.entries(queryParams)) {
    if (key === K_SORT) {
      state.index = value;
    }
    if (key === K_QUERY) state.query = value;
    if (key === K_FR_ALL)
      state.maxValuesPerFacet = value
        ? showAllValuesPerFacet
        : defaultMaxValuesPerFacet;
    if (key === K_PAGE) state.page = parseInt(value, 10) - 1; // back to base 0
    if (key === K_HITS_PER_PAGE) state.hitsPerPage = parseInt(value, 10);
    if (key === K_DF) state.disjunctiveFacets = value;
    if (key === K_HF) state.hierarchicalFacets = value;

    let cleanedValue;
    try {
      if (typeof value === 'object') {
        cleanedValue = value;
      } else {
        cleanedValue = JSON.parse(value);
      }
    } catch (err) {
      cleanedValue = {};
    }
    if (key === K_DFR) {
      state.disjunctiveFacetsRefinements = cleanedValue;
    }
    if (key === K_HFR) {
      state.hierarchicalFacetsRefinements = cleanedValue;
    }
    if (key === K_FR) {
      state.facetsRefinements = cleanedValue;
    }
    if (key === K_NR) {
      state.numericRefinements = cleanedValue;
    }
    // - hierarchical facets
  }
  return state;
}

/**
 * Convert the current state tree to a human readable version of query parameters
 *
 * @function
 * @param {Object} state - The state tree object of globalState.search
 * @returns {Object} queryParams - The human readable version of query parameters
 */
export function convertStateToQueryParams(state, initialState = {}) {
  const queryParams = {};

  // empty checking
  if (isEmpty(state)) return queryParams;

  if (state.index !== initialState.index) {
    queryParams[K_SORT] = state.index;
  }

  // convert state
  if (isEmpty(state)) return queryParams;

  for (const [key, value] of Object.entries(state)) {
    if (key === 'query' && value !== initialState.query) {
      queryParams[K_QUERY] = value;
    }
    if (
      key === 'maxValuesPerFacet' &&
      value !== initialState.maxValuesPerFacet
    ) {
      queryParams[K_FR_ALL] = true;
    }
    if (key === 'page' && value !== initialState.page) {
      const newPageNumber = value + 1; // base 1
      if (newPageNumber >= 1) {
        queryParams[K_PAGE] = newPageNumber;
      }
    }
    if (key === 'distinct' && value !== initialState.distinct) {
      queryParams[K_DISTINCT] = value;
    }
    if (key === 'disjunctiveFacetsRefinements') {
      if (!isEmpty(value)) {
        queryParams[K_DFR] = JSON.stringify(value);
      }
    }
    if (key === 'facetsRefinements') {
      if (!isEmpty(value)) {
        queryParams[K_FR] = JSON.stringify(value);
        queryParams[K_FR] = JSON.stringify(value);
      }
    }
    if (key === 'numericRefinements') {
      if (!isEmpty(value)) {
        queryParams[K_NR] = JSON.stringify(value);
      }
    }
    if (key === 'hierarchicalFacetsRefinements') {
      if (!isEmpty(value)) {
        queryParams[K_HFR] = JSON.stringify(value);
      }
    }
  }
  return queryParams;
}
