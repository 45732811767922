import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import React from 'react';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';
import { getFreeShippingLabel } from 'app-libs/etc/productAttributesUtil';

import { K_IMAGE_FREE_SHIPPING } from 'constants/assetConstants';

import cStyles from '@dkrm/general-libs/theme/styles';

import { ProductCardProduct } from '../types';

type FreeShippingLabelProps = {
  product: ProductCardProduct;
};

const FreeShippingLabel: React.FC<FreeShippingLabelProps> = ({ product }) => {
  const freeShippingLabel = getFreeShippingLabel(product);
  return (
    <View style={s.freeShippingContainer}>
      <SmartImage
        source={K_IMAGE_FREE_SHIPPING}
        width={40}
        height={16}
        style={s.freeShipping}
        shouldPreloadSmallerVersion={false}
        resizeMode="contain"
      />
      <Text theme="h6-tosca3">se-{freeShippingLabel}</Text>
    </View>
  );
};

const s = StyleSheet.create({
  freeShippingContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mts,
    ...cStyles.mbm,
    alignItems: 'center',
  },

  freeShipping: {
    ...cStyles.mrs,
  },
});

export default FreeShippingLabel;
