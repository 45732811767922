import React, { memo } from 'react';
import { StyleSheet, View, Text } from '@dkrm/ui-kit-basic';
import { ModalEnhanced, ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';

interface RemoveFromCartConfirmationModalProps {
  onCancel: () => void;
  onSubmit: () => void;
}

const RemoveFromCartConfirmationModal: React.FC<RemoveFromCartConfirmationModalProps> = memo(
  ({ onCancel, onSubmit }) => (
    <ModalEnhanced
      isVisible
      animationType="fade"
      onBackdropPress={onCancel}
      style={s.modalContainer}
    >
      <View style={s.confirmationBox}>
        <Text theme="body3" style={s.confirmationText}>
          Hapus produk dari keranjang?
        </Text>
        <View style={s.confirmationButtonContainer}>
          <ButtonWithText
            onPress={onCancel}
            title="BATAL"
            theme="tosca"
            themeType="tertiary"
          />
          <ButtonWithText
            onPress={onSubmit}
            title="HAPUS"
            theme="tosca"
            themeType="tertiary"
          />
        </View>
      </View>
    </ModalEnhanced>
  ),
);

const s = StyleSheet.create({
  confirmationBox: {
    ...cStyles.brs,
    ...cStyles.ptxxl,
    ...cStyles.plxxl,
    ...cStyles.pbm,
    ...cStyles.prm,
    ...cStyles.shadowxl,
    width: '80%',
    maxWidth: 600,
    backgroundColor: colors.C_WHITE_100,
    shadowColor: '#002022',
  },
  confirmationButtonContainer: {
    ...cStyles.flexDirRow,
    width: '100%',
    paddingTop: 20,
    justifyContent: 'flex-end',
  },
  confirmationText: {
    color: colors.C_BLACK_100,
  },
  modalContainer: {
    margin: 0,
    alignItems: 'center',
    backgroundColor: colors.generateRGBA('black', 0.1),
  },
});

export default RemoveFromCartConfirmationModal;
