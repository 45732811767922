/* eslint-disable camelcase */
import { Product } from 'app-libs/components/Product/types';
import { getFirstBreadcrumbFromProductCategories } from 'app-libs/etc/categoryHelper';
import {
  getBasketLinesTotalValue,
  getOfferDiscountAmount,
} from 'app-libs/etc/productHelper';
import { BasketLine } from 'app-libs/redux_modules/entity_modules/basket/types';

export const genProductProperties = (product: Product) => ({
  content_name: product.title,
  content_category: getFirstBreadcrumbFromProductCategories(product.categories),
  content_id: product.objectID,
  content_type: 'product',
  value: product.priceNumber - getOfferDiscountAmount(product),
  currency: 'IDR',
});

export const genOrderProperties = (lines: BasketLine[]) => ({
  contents: lines.map((line) => ({
    content_id: line.product.objectID,
    content_type: 'product',
    content_name: line.product.title,
    content_category: getFirstBreadcrumbFromProductCategories(
      line.product.categories,
    ),
    quantity: line.quantity,
    price: line.product.priceNumber - getOfferDiscountAmount(line.product),
  })),
  value: getBasketLinesTotalValue(lines),
  currency: 'IDR',
});

export const genMicrositeProperties = (slug: string) => ({
  content_name: 'microsite',
  content_id: slug,
});

export const genHomepageProperties = () => ({
  content_name: 'homepage',
  content_id: 'storefront-mobile',
});
