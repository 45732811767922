import PropTypes from 'prop-types';
import React from 'react';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { ChevronRight } from '@dkrm/icons';
import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import colors from '@dkrm/ui-kit-basic/colors';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { BreadcrumbStructuredData } from 'app-libs/components/_abstractComponents/GoogleStructuredData';
import { trackClickBreadcrumb } from 'app-libs/trackers';

@withRouter
export default class Breadcrumb extends React.Component {
  static propTypes = {
    location: PropTypes.shape(),
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        doNotExtendQueryParameters: PropTypes.bool,
        doNotUseHyperlink: PropTypes.bool,
      }),
    ).isRequired,
    onItemPressed: PropTypes.func,
    containerStyle: stylePropTypes,
    textTheme: PropTypes.string,
    iconSize: PropTypes.number,
    iconTheme: PropTypes.string,
  };

  static defaultProps = {
    onItemPressed: () => null,
    containerStyle: null,
    textTheme: 'black40',
    iconSize: 20,
    iconTheme: 'black40',
    location: {},
  };

  onItemPressed = (params) => {
    const { onItemPressed } = this.props;
    trackClickBreadcrumb(params.to, params.from, params.name);
    onItemPressed(params);
  };

  renderLiContent(name, isActive = false) {
    const { textTheme, iconSize, iconTheme } = this.props;
    return (
      <View style={styles.breadcrumb}>
        <Text theme={textTheme} style={styles.breadcrumbText}>
          {name}
        </Text>
        {!isActive && (
          <ChevronRight
            size={iconSize}
            theme={iconTheme}
            style={styles.breadcrumbSeparator}
          />
        )}
      </View>
    );
  }

  renderLi(
    name,
    to,
    query,
    isActive = false,
    doNotUseHyperlink = false,
    linkProps = {},
  ) {
    const { location } = this.props;
    if (isActive && doNotUseHyperlink) {
      return (
        <span className="active">{this.renderLiContent(name, isActive)}</span>
      );
    }
    if (doNotUseHyperlink) {
      return <span>{this.renderLiContent(name, isActive)}</span>;
    }
    return (
      <ButtonWrapper
        to={{ pathname: to, query }}
        onPress={() =>
          this.onItemPressed({
            name,
            to,
            query,
            from: location.pathname,
          })
        }
        linkProps={linkProps}
      >
        {() => this.renderLiContent(name, isActive)}
      </ButtonWrapper>
    );
  }

  renderBreadcrumbGstruct() {
    const { breadcrumbs } = this.props;
    return (
      <div style={{ display: 'none' }}>
        <BreadcrumbStructuredData breadcrumbs={breadcrumbs} />
      </div>
    );
  }

  render() {
    const { breadcrumbs, containerStyle, location } = this.props;

    const existingQueryParams = location.query || {};
    const newQueryParams = Object.assign({}, existingQueryParams);
    delete newQueryParams.page;
    delete newQueryParams.dfr;

    return (
      <View style={[styles.container, containerStyle]}>
        {!!breadcrumbs && this.renderBreadcrumbGstruct()}
        {breadcrumbs.map((breadcrumb, key) => {
          const isLast = key === breadcrumbs.length - 1;
          const query = breadcrumb.doNotAddQueryParameters
            ? {}
            : newQueryParams;
          return (
            <View key={key}>
              {this.renderLi(
                breadcrumb.name,
                breadcrumb.path,
                query,
                isLast,
                breadcrumb.doNotUseHyperlink,
                breadcrumb.linkProps,
                breadcrumb.isDisabled,
              )}
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.phs,
    height: 50,
  },
  breadcrumb: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
  },
  breadcrumbText: {
    color: colors.C_BLACK_40,
  },
  breadcrumbSeparator: {
    ...cStyles.mhm,
  },
});
