import keyMirror from 'keymirror';
import update from 'immutability-helper';
import config from 'config';
import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

export const AT = keyMirror({
  LOAD_LABEL: null,
  LOAD_LABEL_SUCCESS: null,
  LOAD_LABEL_FAIL: null,
});

export const initialState = {
  asyncState: initialAsyncState,
  labels: [],
};

export default function labelReducer(mutableState = initialState, action = {}) {
  const { type, result } = action;
  let state = mutableState;

  switch (type) {
    case AT.LOAD_LABEL_SUCCESS: {
      state = update(state, {
        labels: {
          $set: result,
        },
      });
      break;
    }
  }

  state = update(state, {
    asyncState: {
      $merge: asyncStateReducer(state.asyncState, action, '_LABEL_'),
    },
  });

  return state;
}
