import React from 'react';

import config from 'config';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { FooterSectionTitle } from '../FooterSection/FooterSectionTitle';
import { K_HEIGHT_SMALL_ICON, K_WIDTH_SMALL_ICON } from '../constants';

const K_PAYMENT_METHODS = [
  'visa',
  'mastercard',
  'jcb',
  'american',
  'ocbc',
  'bca',
  'mandiri',
  'bri',
  'hsbc',
  'bni',
  'permata',
  'standard',
  'mnc',
  'panin',
  'maybank',
  'bukopin',
  'cimb-niaga',
  'digibank-dbs',
  'gopay',
  'ovo',
  'kredivo',
  'bcaklikpay',
];

export const PaymentMethodSection: React.FC = () => {
  return (
    <>
      <FooterSectionTitle title="Metode Pembayaran" />
      <View style={s.container}>
        {K_PAYMENT_METHODS.map((paymentMethod) => (
          <View style={s.icon} key={paymentMethod}>
            <SmartImage
              source={`${config.API_URL_MEDIA_CDN}/homepage/footer/logo/v2/${paymentMethod}.png?auto=webp`}
              width={K_WIDTH_SMALL_ICON}
              height={K_HEIGHT_SMALL_ICON}
              additionalQuery={{
                fit: 'bounds',
              }}
            />
          </View>
        ))}
      </View>
    </>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.mtm,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 72px)',
    gridColumnGap: '12px',
    gridRowGap: '12px',
  },
  icon: {
    ...cStyles.flexMiddle,
    ...cStyles.phs,
    ...cStyles.brs,
    ...cStyles.pvxs,
    borderWidth: 1,
    borderColor: Colors.C_BLACK_15,
  },
});

export default PaymentMethodSection;
