import PropTypes from 'prop-types';
import React from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, View } from '@dkrm/ui-kit-basic';

import ProductCard from '../ProductCard';
import styles from './styles';

const ProductCardWithTitle = ({ containerStyle, title, ...rest }) => (
  <View style={styles.container}>
    {!!title && (
      <View style={[styles.textContainer, containerStyle]}>
        <Text theme="h6-white" style={styles.title}>
          {title}
        </Text>
      </View>
    )}
    <ProductCard containerStyle={containerStyle} {...rest} />
  </View>
);

ProductCardWithTitle.propTypes = {
  title: PropTypes.string,
  containerStyle: stylePropTypes,
};

ProductCardWithTitle.defaultProps = {
  title: '',
  containerStyle: null,
};

export default React.memo(ProductCardWithTitle);
