import { ProductOffer } from '../types';
import { ProductCardProduct } from './types';

const K_PDL__CAMPAIGN_STICKER = 'PDL__CAMPAIGN_STICKER';

export const getCampaignStickerAssetUrl = (product: ProductCardProduct) => {
  let campaignAssets = [];
  let campaignStickerAssetUrl = '';

  if (product.productOffer && Object.keys(product.productOffer).length > 0) {
    const productOffer = product.productOffer as ProductOffer;
    campaignAssets = productOffer.offer?.campaignAssets || [];
    campaignStickerAssetUrl =
      campaignAssets.find(
        (campaignAsset) =>
          campaignAsset.assetLocation === K_PDL__CAMPAIGN_STICKER,
      )?.assetUrl || '';
  }

  return campaignStickerAssetUrl;
};
