import keyMirror from 'keymirror';
import { normalize, schema as normalizrSchema } from 'normalizr';

export const AT = keyMirror({
  STORE_OBJECTS_IN_ENTITIES: null,
});

export function storeObjectsInEntities(
  objects: unknown,
  schema: normalizrSchema.Entity | Array<normalizrSchema.Entity>,
) {
  const normalized = normalize(objects, schema);
  const result = {
    content: normalized.result,
    entities: normalized.entities,
  };

  return {
    type: AT.STORE_OBJECTS_IN_ENTITIES,
    result,
  };
}
