import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import isEmpty from 'lodash/isEmpty';

import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';

import * as authSelectors from 'app-libs/redux_modules/selectors/auth';
import { requestPermissionAndRegisterDevicePushNotification } from 'app-libs/components/_abstractComponents/notification/utils';

const wrappedCompnent = (WrappedComponent) => {
  @connect((state) => ({
    user: authSelectors.getAuthUser(state),
  }))
  class Component extends React.Component {
    static propTypes = {
      user: PropTypes.shape().isRequired,
    };

    componentDidUpdate(prevProps) {
      const { user } = this.props;
      setTimeout(() => {
        if (!isWebView() && !isEmpty(user) && isEmpty(prevProps.user)) {
          requestPermissionAndRegisterDevicePushNotification(user.email);
        }
      }, 5000);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistStatics(Component, WrappedComponent);
};

export default wrappedCompnent;
