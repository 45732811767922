import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';

import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';

import * as customerActions from 'app-libs/redux_modules/entity_modules/customer';
import * as customerSelectors from 'app-libs/redux_modules/entity_modules/selectors/customer';

import { K_ROUTE_CHECKOUT_SHIPPING_ADDRESS } from 'constants/routeConstants';

import styles from '../styles';

@withRouter
@connect(
  (state) => ({
    isShippingAddressLoaded: customerSelectors.isShippingAddressLoaded(state),
  }),
  {
    resetShippingAddressToEdit: customerActions.resetShippingAddressToEdit,
  },
)
export default class CheckoutAddShippingAddressButton extends React.PureComponent {
  static propTypes = {
    isShippingAddressLoaded: PropTypes.bool.isRequired,

    resetShippingAddressToEdit: PropTypes.func.isRequired,

    baseUrl: PropTypes.string,
    history: PropTypes.shape().isRequired,
    router: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    baseUrl: '',
  };

  render() {
    const {
      isShippingAddressLoaded,
      resetShippingAddressToEdit,
      baseUrl,
      history,
      router,
    } = this.props;

    return (
      <ButtonWithText
        title="Tambah"
        themeType="tertiary"
        onPress={() => {
          resetShippingAddressToEdit();
          actionRouteActionOpenURL(
            {
              pathname: `${baseUrl}${K_ROUTE_CHECKOUT_SHIPPING_ADDRESS}`,
              state: {
                title: 'Tambah Alamat',
              },
            },
            history || router,
          );
        }}
        style={styles.addAddressButton}
        disabled={!isShippingAddressLoaded}
      />
    );
  }
}
