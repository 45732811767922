export const K_DISJUNCTIVE_FACET_BY_CATEGORY = {
  'Gorden & Aksesoris': ['filteringAttributes.aMaterial'],
  'Aromaterapi & Diffuser': ['filteringAttributes.aMaterial'],
  Asbak: ['filteringAttributes.aMaterial'],
  'Bantal Dekorasi': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aPattern',
  ],
  'Isi Bantal': ['filteringAttributes.aMaterial'],
  'Sarung Bantal': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aPattern',
  ],
  'Bingkai Foto': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aStyle',
  ],
  Boneka: ['filteringAttributes.aMaterial'],
  Cermin: ['filteringAttributes.aMaterial', 'filteringAttributes.aStyle'],
  'Alat Makan': ['filteringAttributes.aMaterial'],
  'Hiasan Ulang Tahun': ['filteringAttributes.aMaterial'],
  Figurin: ['filteringAttributes.aMaterial', 'filteringAttributes.aStyle'],
  'Hiasan Seasonal': ['filteringAttributes.aMaterial'],
  'Hiasan Gantung': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aPattern',
  ],
  Poster: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aPattern',
  ],
  Karpet: ['filteringAttributes.aMaterial', 'filteringAttributes.aStyle'],
  'Karpet Kanvas': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  Lentera: ['filteringAttributes.aMaterial', 'filteringAttributes.aStyle'],
  'Pengharum Ruangan': ['filteringAttributes.aMaterial'],
  'Alas Laptop': ['filteringAttributes.aMaterial'],
  'Alat Tulis': ['filteringAttributes.aMaterial'],
  Folder: ['filteringAttributes.aMaterial'],
  'Papan Tulis': ['filteringAttributes.aMaterial'],
  'Tempat Alat Tulis': ['filteringAttributes.aMaterial'],
  'Tempat Kartu Nama': ['filteringAttributes.aMaterial'],
  Sajadah: ['filteringAttributes.aMaterial'],
  'Table Runner': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Bunga Plastik': ['filteringAttributes.aMaterial'],
  'Tanaman Hias': ['filteringAttributes.aMaterial'],
  'Tempat Terrarium': ['filteringAttributes.aMaterial'],
  'Vas & Pot Bunga': ['filteringAttributes.aMaterial'],
  'Taplak Meja': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Tempat Lilin': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Kotak Rias': ['filteringAttributes.aMaterial'],
  'Tas & Pouch': ['filteringAttributes.aMaterial'],
  'Tempat Majalah': ['filteringAttributes.aMaterial'],
  'Kursi Bar': [
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aStyle',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aFTipeTinggiDudukan',
    'filteringAttributes.aFKursiBarTipeSandaran',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Kursi Kantor': [
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aTipeSandaranTangan',
    'filteringAttributes.aStyle',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aFKursiKursikantorMaterialSandaran',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Kursi Makan': [
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aStyle',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Kursi Panjang': [
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aStyle',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Kabinet Dapur': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Lemari Pakaian 2 Pintu': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFJumlahRakText',
    'filteringAttributes.aFJumlahGantunganBajuText',
    'filteringAttributes.aFJumlahLaciText',
    'filteringAttributes.aFJenisPintu',
    'filteringAttributes.aFCerminLemari',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Lemari Pakaian 3 Pintu': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFJumlahRakText',
    'filteringAttributes.aFJumlahGantunganBajuText',
    'filteringAttributes.aFJumlahLaciText',
    'filteringAttributes.aFJenisPintu',
    'filteringAttributes.aFCerminLemari',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja Bar': [
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFBentukTableTop',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja Kerja': [
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja Kantor': [
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja Makan': [
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFBentukTableTop',
    'filteringAttributes.aFKapasitasMejaMakan',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja Rias': [
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja Tamu': [
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFBentukTableTop',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja TV': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFKondisiProduk',
  ],
  Nakas: [
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFBentukTableTop',
    'filteringAttributes.aFKondisiProduk',
  ],
  Credenza: [
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFBentukTableTop',
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Lemari Pakaian': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Lemari Kantor': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Rak Buku & Rak Display': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFJumlahRakText',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Rak Dinding & Ambalan': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Rak Sepatu': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFJumlahRakText',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Sofa 1 Seater & Armchair': [
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aFKondisiProduk',
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFSofaMaterialCover',
    'filteringAttributes.aFSofaMaterialSandaran',
    // 'filteringAttributes.aFSofaKeempukanSandaran',
    'filteringAttributes.aSizeFTinggiSandaranText',
    'filteringAttributes.aFTipeSandaran',
    'filteringAttributes.aFSofaBentukSandaran',
    'filteringAttributes.aFSofaMaterialDudukan',
    // 'filteringAttributes.aFSofaKeempukanDudukan',
    'filteringAttributes.aFSofaBentukDudukan',
    'filteringAttributes.aFTipeDudukan',
    'filteringAttributes.aFSofaMaterialKakiSofa',
    'filteringAttributes.aSizeFTinggiKakiText',
  ],
  'Sofa 2 Seater': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFSofaMaterialCover',
    'filteringAttributes.aFSofaMaterialSandaran',
    // 'filteringAttributes.aFSofaKeempukanSandaran',
    'filteringAttributes.aSizeFTinggiSandaranText',
    'filteringAttributes.aFTipeSandaran',
    'filteringAttributes.aFSofaBentukSandaran',
    'filteringAttributes.aFSofaMaterialDudukan',
    // 'filteringAttributes.aFSofaKeempukanDudukan',
    'filteringAttributes.aFSofaBentukDudukan',
    'filteringAttributes.aFTipeDudukan',
    'filteringAttributes.aFSofaMaterialKakiSofa',
    'filteringAttributes.aSizeFTinggiKakiText',
  ],
  'Sofa 3 Seater': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFSofaMaterialCover',
    'filteringAttributes.aFSofaMaterialSandaran',
    // 'filteringAttributes.aFSofaKeempukanSandaran',
    'filteringAttributes.aSizeFTinggiSandaranText',
    'filteringAttributes.aFTipeSandaran',
    'filteringAttributes.aFSofaBentukSandaran',
    'filteringAttributes.aFSofaMaterialDudukan',
    // 'filteringAttributes.aFSofaKeempukanDudukan',
    'filteringAttributes.aFSofaBentukDudukan',
    'filteringAttributes.aFTipeDudukan',
    'filteringAttributes.aFSofaMaterialKakiSofa',
    'filteringAttributes.aSizeFTinggiKakiText',
  ],
  'Kursi Ottoman & Stool': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Bean Bag & Kursi Lesehan': [
    'filteringAttributes.aStyle',
    'filteringAttributes.aFKursiMaterialDudukan',
    'filteringAttributes.aFKursiMaterialFrame',
    'filteringAttributes.aPattern',
  ],
  'Sofa Bed': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFSofaMaterialCover',
    'filteringAttributes.aFSofaMaterialSandaran',
    // 'filteringAttributes.aFSofaKeempukanSandaran',
    'filteringAttributes.aSizeFTinggiSandaranText',
    'filteringAttributes.aFSofaBentukSandaran',
    'filteringAttributes.aFSofaMaterialDudukan',
    // 'filteringAttributes.aFSofaKeempukanDudukan',
    'filteringAttributes.aFSofaBentukDudukan',
    'filteringAttributes.aFSofaMaterialKakiSofa',
    'filteringAttributes.aTipeProduk',
  ],
  'Sofa L': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFSofaMaterialCover',
    'filteringAttributes.aFSofaMaterialSandaran',
    // 'filteringAttributes.aFSofaKeempukanSandaran',
    'filteringAttributes.aSizeFTinggiSandaranText',
    'filteringAttributes.aFTipeSandaran',
    'filteringAttributes.aFSofaBentukSandaran',
    'filteringAttributes.aFSofaMaterialDudukan',
    // 'filteringAttributes.aFSofaKeempukanDudukan',
    'filteringAttributes.aFSofaBentukDudukan',
    'filteringAttributes.aFTipeDudukan',
    'filteringAttributes.aFSofaMaterialKakiSofa',
    'filteringAttributes.aSizeFTinggiKakiText',
    'filteringAttributes.aTipeProduk',
  ],
  'Tempat Tidur Single': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Tempat Tidur Queen': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Tempat Tidur King': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Gagang Lemari': ['filteringAttributes.aMaterial'],
  'Handle Pintu': ['filteringAttributes.aMaterial'],
  'Rak Kamar Mandi': ['filteringAttributes.aMaterial'],
  Bathtub: ['filteringAttributes.aMaterial'],
  'Wastafel & Closet': ['filteringAttributes.aMaterial'],
  Shower: ['filteringAttributes.aMaterial'],
  Keran: ['filteringAttributes.aMaterial'],
  'Water Heater': ['filteringAttributes.aMaterial'],
  'Baterai & Charger': ['filteringAttributes.aMaterial'],
  'Kunci Ring': ['filteringAttributes.aMaterial'],
  Obeng: ['filteringAttributes.aMaterial'],
  Senter: ['filteringAttributes.aMaterial'],
  Tang: ['filteringAttributes.aMaterial'],
  Bor: ['filteringAttributes.aMaterial'],
  Gergaji: ['filteringAttributes.aMaterial'],
  'Rak Kabel': ['filteringAttributes.aMaterial'],
  'Stop Kontak': ['filteringAttributes.aMaterial'],
  Sink: ['filteringAttributes.aMaterial'],
  'Alas Pot': ['filteringAttributes.aMaterial'],
  'Payung Tenda': ['filteringAttributes.aMaterial'],
  'Wallpaper Dinding': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  Aeroflow: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Airland: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Comforta: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Domi: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Dunlopillo: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Elite: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'eazy Sleep': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Florence: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Guhdo: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'In The Box': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'King Koil': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'La Dova': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'Lady Americana': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Luxio: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Quantum: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Serta: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Simmons: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'Simply Bed': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Superland: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'The Luxe': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Theraspine: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'Tote Bed': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Zees: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  Ballast: ['filteringAttributes.aMaterial'],
  'Bohlam & Lampu LED': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Fitting Lampu': ['filteringAttributes.aMaterial'],
  'Aksesoris Lampu': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  'Lampu Dinding': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
  ],
  'Lampu Emergency': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
  ],
  'Lampu Gantung & Lampu Plafon': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
  ],
  'Standing Lamp': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
  ],
  'Lampu Meja': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
  ],
  'Lampu Taman': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
  ],
  'Lampu Tidur': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeFitting',
    'filteringAttributes.aIsiPaket',
    'filteringAttributes.aStyle',
  ],
  'Wajan & Panci': ['filteringAttributes.aMaterial'],
  'Kitchen Utensils': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Blender: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Deep Fryer': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Dispenser: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Microwave: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Mixer: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Oven & Toaster': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Rice Cooker': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Slow Cooker': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Teko: ['filteringAttributes.aMaterial', 'filteringAttributes.aKonsumsiDaya'],
  'Baking Utensils': ['filteringAttributes.aMaterial'],
  'Loyang & Wadah Panggang': ['filteringAttributes.aMaterial'],
  'Pisau & Talenan': ['filteringAttributes.aMaterial'],
  Panci: ['filteringAttributes.aMaterial'],
  'Serbet & Celemek': ['filteringAttributes.aMaterial'],
  'Sarung Galon & Kulkas': ['filteringAttributes.aMaterial'],
  'Rak Dapur': ['filteringAttributes.aMaterial'],
  'Kotak Makanan & Toples': ['filteringAttributes.aMaterial'],
  Troli: ['filteringAttributes.aMaterial'],
  'Alat Pembersih': ['filteringAttributes.aMaterial'],
  'Kotak P3K': ['filteringAttributes.aMaterial'],
  'Kotak Penyimpanan': ['filteringAttributes.aMaterial'],
  Laci: ['filteringAttributes.aMaterial'],
  'Tempat Tisu': ['filteringAttributes.aMaterial'],
  'Air Conditioner': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Dryer: ['filteringAttributes.aMaterial', 'filteringAttributes.aKonsumsiDaya'],
  Kulkas: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Mesin Cuci': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Vacuum Cleaner': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Gantungan Pakaian': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aTipeProduk',
  ],
  'Keranjang Baju': ['filteringAttributes.aMaterial'],
  Keset: ['filteringAttributes.aMaterial'],
  Slippers: ['filteringAttributes.aMaterial'],
  'Gantungan Handuk': ['filteringAttributes.aMaterial'],
  'Kasur Lipat': ['filteringAttributes.aMaterial'],
  'Alas Kasur': ['filteringAttributes.aMaterial'],
  'Selimut Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Selimut & Quilt': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aSizeType',
    'filteringAttributes.aQuality',
    'filteringAttributes.aPattern',
  ],
  Sprei: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aSizeType',
    'filteringAttributes.aQuality',
    'filteringAttributes.aPattern',
  ],
  Carafe: ['filteringAttributes.aMaterial'],
  'Tempat Es': ['filteringAttributes.aMaterial'],
  'Aksesoris Peralatan Kopi': ['filteringAttributes.aMaterial'],
  Chemex: ['filteringAttributes.aMaterial'],
  'Coffee Dripper': ['filteringAttributes.aMaterial'],
  'Espresso Maker': ['filteringAttributes.aMaterial'],
  'Filter Kopi': ['filteringAttributes.aMaterial'],
  'French Press': ['filteringAttributes.aMaterial'],
  'Latte Art Pen & Stencils': ['filteringAttributes.aMaterial'],
  'Moka Pot': ['filteringAttributes.aMaterial'],
  'Pembuih Susu': ['filteringAttributes.aMaterial'],
  Penghangat: ['filteringAttributes.aMaterial'],
  'Penyaring Teh': ['filteringAttributes.aMaterial'],
  'Pour Over': ['filteringAttributes.aMaterial'],
  Scales: ['filteringAttributes.aMaterial'],
  Syphon: ['filteringAttributes.aMaterial'],
  Tampers: ['filteringAttributes.aMaterial'],
  Placemat: ['filteringAttributes.aMaterial'],
  Mangkok: ['filteringAttributes.aMaterial'],
  Piring: ['filteringAttributes.aMaterial'],
  'Set Peralatan Makan': ['filteringAttributes.aMaterial'],
  'Tempat Tusuk Gigi': ['filteringAttributes.aMaterial'],
  'Alas & Tutup Gelas': ['filteringAttributes.aMaterial'],
  'Botol & Tumbler': ['filteringAttributes.aMaterial'],
  'Cangkir & Mug': ['filteringAttributes.aMaterial'],
  Gelas: ['filteringAttributes.aMaterial'],
  Pitcher: ['filteringAttributes.aMaterial'],
  Tumbler: ['filteringAttributes.aMaterial'],
  'Alat Saji Buah': ['filteringAttributes.aMaterial'],
  'Alat Saji Kue': ['filteringAttributes.aMaterial'],
  'Piring Saji': ['filteringAttributes.aMaterial'],
  'Food Warmer': ['filteringAttributes.aMaterial'],
  Nampan: ['filteringAttributes.aMaterial'],
  Rantang: ['filteringAttributes.aMaterial'],
  'Tempat Nasi': ['filteringAttributes.aMaterial'],
  'Tudung Saji': ['filteringAttributes.aMaterial'],
  'Botol Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Garpu Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Gelas Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Kotak Makan Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Mangkuk Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Piring Makan Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Alat Kebersihan': ['filteringAttributes.aMaterial'],
  'Aneka Penyimpanan': ['filteringAttributes.aMaterial'],
  'Elektronik Rumah Tangga': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  'Perlengkapan Laundry': ['filteringAttributes.aMaterial'],
  'Perlengkapan Pakaian': ['filteringAttributes.aMaterial'],
  'Peralatan Rumah Tangga': ['filteringAttributes.aMaterial'],
  'Peralatan Bar': ['filteringAttributes.aMaterial'],
  'Peralatan Kopi & Teh': ['filteringAttributes.aMaterial'],
  'Peralatan Makan': ['filteringAttributes.aMaterial'],
  'Peralatan Minum': ['filteringAttributes.aMaterial'],
  'Peralatan Saji': ['filteringAttributes.aMaterial'],
  'Perlengkapan Makan Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Perlengkapan Makan & Minum': ['filteringAttributes.aMaterial'],
  'Peralatan Kamar Mandi': ['filteringAttributes.aMaterial'],
  Perkakas: ['filteringAttributes.aMaterial'],
  'Smart Home': ['filteringAttributes.aMaterial'],
  'Perlengkapan Dapur': ['filteringAttributes.aMaterial'],
  'Perlengkapan Outdoor & Taman': ['filteringAttributes.aMaterial'],
  'Meja & Kursi Kerja': ['filteringAttributes.aStyle'],
  Lemari: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Meja & Kursi Makan': [
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFMejaMaterialTableTop',
    'filteringAttributes.aFMejaMaterialKakiMeja',
    'filteringAttributes.aFKondisiProduk',
  ],
  'Rak & Penyimpanan': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
    'filteringAttributes.aFKondisiProduk',
  ],
  Sofa: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
    'filteringAttributes.aSizePanjang',
    'filteringAttributes.aSizeLebar',
    'filteringAttributes.aSizeTinggi',
  ],
  'Tempat Tidur': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aStyle',
  ],
  Furniture: ['filteringAttributes.aStyle'],
  'Bantal & Guling': ['filteringAttributes.aMaterial'],
  'Perlengkapan Tidur Bayi & Anak': ['filteringAttributes.aMaterial'],
  'Perlengkapan Kamar Tidur': ['filteringAttributes.aMaterial'],
  Jam: ['filteringAttributes.aMaterial', 'filteringAttributes.aStyle'],
  'Perlengkapan Ruang Kerja': ['filteringAttributes.aMaterial'],
  Tanaman: ['filteringAttributes.aMaterial'],
  'Tempat Penyimpanan Dekoratif': ['filteringAttributes.aMaterial'],
  'Wall Covering': ['filteringAttributes.aMaterial'],
  Dekor: ['filteringAttributes.aMaterial'],
  'Aksesoris Dapur': ['filteringAttributes.aMaterial'],
  'Elektronik Dapur': [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aKonsumsiDaya',
  ],
  Kompor: ['filteringAttributes.aMaterial', 'filteringAttributes.aTipeProduk'],
  'Peralatan Kue': ['filteringAttributes.aMaterial'],
  'Peralatan Masak': ['filteringAttributes.aMaterial'],
  'Tekstil Dapur': ['filteringAttributes.aMaterial'],
  'Tempat Penyimpanan Dapur': ['filteringAttributes.aMaterial'],
  'Peralatan Dapur': ['filteringAttributes.aMaterial'],
  Lampu: ['filteringAttributes.aMaterial'],
  Kasur: [
    'filteringAttributes.aMaterial',
    'filteringAttributes.aFirmnessMatras',
    'filteringAttributes.aKategoriMatras',
    'filteringAttributes.aTipeProduk',
    'filteringAttributes.aLebarMatras',
    'filteringAttributes.aSizeTinggi',
  ],
  'Aksesoris Kamar Mandi': ['filteringAttributes.aMaterial'],
  Handuk: ['filteringAttributes.aMaterial'],
  'Tempat Penyimpanan Kamar Mandi': ['filteringAttributes.aMaterial'],
  'Perlengkapan Kamar Mandi': ['filteringAttributes.aMaterial'],
};
