import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withSearchConsumer } from 'app-libs/AlgoliaAdapter';

import ListComponent from '../ListComponent';
import PopupFilterList from '../lists/PopupFilterList';
import CheckBoxFilterItem from '../items/CheckBoxFilterItem';

const getIndexes = (searchAdapter, { indexes }) => {
  const state = searchAdapter.getState();
  const currentIndex = state.index;

  return indexes.map((index) => ({
    ...index,
    selected: index.value === currentIndex,
  }));
};

@withSearchConsumer({ data: getIndexes })
export default class IndexSetterComponent extends Component {
  static propTypes = {
    listProps: PropTypes.shape().isRequired,
  };

  render() {
    const { listProps } = this.props;
    return (
      <ListComponent
        listComponent={PopupFilterList}
        itemComponent={CheckBoxFilterItem}
        multiple={false}
        allowEmpty={false}
        {...this.props}
        listProps={{
          ...listProps,
          isApplyOnClick: true,
        }}
      />
    );
  }
}
