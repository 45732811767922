import React from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Add } from '@dkrm/icons';
import {
  Colors,
  HorizontalDivider,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import WishlistButton from 'app-libs/components/WishlistButton';
import { makeParametricUrl } from 'app-libs/etc';
import * as albumSelectors from 'app-libs/redux_modules/entity_modules/selectors/album';

import { K_ROUTE_SIMILAR_PRODUCTS } from 'constants/routeConstants';

import FreeShippingLabel from '../FreeShippingLabel';
import ProductRating from '../ProductRating';
import { ProductCardProduct } from '../types';

type BottomButtonsProps = {
  firstProduct: ProductCardProduct;
  product: ProductCardProduct;
  buttonProps?: object;
  onSimilarProductClick?: () => void;
  toggleWishlistPin: (pinId: number, product: ProductCardProduct) => void;
  shouldShowSimilarProductsButton?: boolean;
  referrer?: string;
  shouldShowQuickAddToCart?: boolean;
  onQuickAddToCart?: (
    upc: string,
    stockRecordId: number,
    addedQuantity: number,
  ) => void;
};

type PinByObjectId = { [param: string]: { pk: number } };

const noop = () => null;

const BottomButtons: React.FC<BottomButtonsProps> = ({
  firstProduct,
  product,
  buttonProps = {},
  onSimilarProductClick = noop,
  shouldShowSimilarProductsButton = true,
  referrer = null,
  toggleWishlistPin = noop,
  shouldShowQuickAddToCart = false,
  onQuickAddToCart = () => null,
}) => {
  const { objectID } = product;
  const similarProductsUrl = makeParametricUrl(K_ROUTE_SIMILAR_PRODUCTS, {
    pid: objectID,
  });
  const pinByObjectId: PinByObjectId = useSelector(
    albumSelectors.getPinByObjectId,
  );

  const pinId = pinByObjectId?.[objectID]?.pk ?? null;

  return (
    <View style={{ justifySelf: 'flex-end ' }}>
      <View style={s.ratingShippingContainer}>
        <ProductRating product={firstProduct} />
        <FreeShippingLabel product={product} />
        {shouldShowQuickAddToCart && (
          <ButtonWrapper
            onPress={() =>
              onQuickAddToCart(product.objectID, product.stockRecordId, 1)
            }
            containerStyle={s.addCartBtn}
          >
            {() => (
              <>
                <Add size={11} theme="white" style={cStyles.mrs} />
                <Text theme="body5-white">Tambah Barang</Text>
              </>
            )}
          </ButtonWrapper>
        )}
      </View>
      {shouldShowSimilarProductsButton ? (
        <>
          <HorizontalDivider color={Colors.C_BLACK_15} />
          <View style={s.similarProductsContainer}>
            <ButtonWrapper
              to={{
                pathname: similarProductsUrl,
                state: { openedFrom: referrer },
              }}
              onPress={onSimilarProductClick}
              showUnderlineOnHover={false}
              {...buttonProps}
            >
              {() => <Text theme="h6-tosca">Lihat Produk Serupa</Text>}
            </ButtonWrapper>
            <WishlistButton
              product={product}
              pinId={pinId}
              toggleWishlistPin={toggleWishlistPin}
            />
          </View>
        </>
      ) : (
        <View style={s.emptyView} />
      )}
    </View>
  );
};

const s = StyleSheet.create({
  similarProductsContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mam,
    justifyContent: 'space-between',
  },
  emptyView: {
    ...cStyles.mtm,
  },
  addCartBtn: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.pvm,
    ...cStyles.mvm,
    backgroundColor: Colors.C_PRIMARY_TOSCA,
    borderRadius: 50,
  },
  ratingShippingContainer: {
    ...cStyles.mhm,
  },
});
export default BottomButtons;
