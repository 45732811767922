import keyMirror from 'keymirror';
import cloneDeep from 'lodash/cloneDeep';

import config from 'config';

export const K_CONTENT_TYPE_IMAGE = 'image';
export const K_CONTENT_TYPE_PRODUCT = 'product';
export const K_CONTENT_TYPE_TITLE = 'title';
export const K_CONTENT_TYPE_HTML_CONTENT = 'html_content';

// ///////////////////// ALBUM REDUX MODULE ///////////////////// //

export const AT = keyMirror({
  INITIALIZE_ALBUM: null,
  UPDATE_ALBUM: null,
  RESET_ALBUM: null,

  ADD_IMAGE: null,
  ADD_TITLE: null,
  ADD_TEXT: null,
  ADD_PRODUCT: null,
  CHANGE_TITLE: null,
  CHANGE_TEXT: null,
  CHANGE_IMAGE_SUBTITLE: null,
  MOVE_UP: null,
  MOVE_DOWN: null,
  REMOVE_PIN: null,

  CREATE_ALBUM: null,
  CREATE_ALBUM_SUCCESS: null,
  CREATE_ALBUM_FAIL: null,

  CREATE_PIN: null,
  CREATE_PIN_SUCCESS: null,
  CREATE_PIN_FAIL: null,

  SAVE_ALBUM: null,
  SAVE_ALBUM_SUCCESS: null,
  SAVE_ALBUM_FAIL: null,

  LOAD_ALBUMS: null,
  LOAD_ALBUMS_SUCCESS: null,
  LOAD_ALBUMS_FAIL: null,

  LOAD_ALBUM: null,
  LOAD_ALBUM_SUCCESS: null,
  LOAD_ALBUM_FAIL: null,

  LOAD_PINS: null,
  LOAD_PINS_SUCCESS: null,
  LOAD_PINS_FAIL: null,

  LIKE_ALBUM: null,
  LIKE_ALBUM_SUCCESS: null,
  LIKE_ALBUM_FAIL: null,

  UNLIKE_ALBUM: null,
  UNLIKE_ALBUM_SUCCESS: null,
  UNLIKE_ALBUM_FAIL: null,

  REMOVE_PIN_FROM_ALBUM: null,
  REMOVE_PIN_FROM_ALBUM_SUCCESS: null,
  REMOVE_PIN_FROM_ALBUM_FAIL: null,

  LOAD_ALBUM_WISHLIST_PINS: null,
  LOAD_ALBUM_WISHLIST_PINS_SUCCESS: null,
  LOAD_ALBUM_WISHLIST_PINS_FAIL: null,

  CREATE_ALBUM_WISHLIST_PIN: null,
  CREATE_ALBUM_WISHLIST_PIN_SUCCESS: null,
  CREATE_ALBUM_WISHLIST_PIN_FAIL: null,
});

export const initialState = {
  album: {
    slug: '',
    title: '',
    description: '',
    isPrivate: false,
    userUuid: '',
    user: {},
    pins: [],
    isLiked: false,
  },
  status: {
    loading: false,
    loaded: false,
    saving: false,
    saved: false,
    error: null,
    pinsLoading: false,
    pinsLoaded: false,
  },
  albums: [],
  lastPinId: 0,
};

export default function albumReducer(mutableState = initialState, action = {}) {
  let state = cloneDeep(mutableState);
  let newPin = {};
  let newPinList = [];
  let pinIndex = -2;
  let pinId = 0;

  switch (action.type) {
    case AT.INITIALIZE_ALBUM:
      let identifiedPins = [];
      if (action.payload) {
        identifiedPins = action.payload.pins.map((item, idx) => {
          pinId = idx;
          return {
            ...item,
            pinId,
          };
        });
      }
      state = {
        ...state,
        lastPinId: pinId,
        album: {
          ...action.payload,
          pins: identifiedPins,
        },
      };
      break;
    case AT.UPDATE_ALBUM:
      state = {
        ...state,
        album: {
          ...state.album,
          ...action.payload,
        },
      };
      break;
    case AT.RESET_ALBUM:
      state = { ...initialState };
      break;
    case AT.ADD_PRODUCT:
      pinId = state.lastPinId + 1;
      newPin = {
        pinId,
        ...action.payload,
      };
      newPinList = [...state.album.pins];
      newPinList.push(newPin);
      state = {
        ...state,
        lastPinId: pinId,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.ADD_TITLE:
      pinId = state.lastPinId + 1;
      newPin = {
        pinId,
        ...action.payload,
      };
      newPinList = [...state.album.pins];
      newPinList.push(newPin);
      state = {
        ...state,
        lastPinId: pinId,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.ADD_TEXT:
      pinId = state.lastPinId + 1;
      newPin = {
        pinId,
        ...action.payload,
      };
      newPinList = [...state.album.pins];
      newPinList.push(newPin);
      state = {
        ...state,
        lastPinId: pinId,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.ADD_IMAGE:
      pinId = state.lastPinId + 1;
      newPin = {
        pinId,
        ...action.payload,
      };
      newPinList = [...state.album.pins];
      newPinList.push(newPin);
      state = {
        ...state,
        lastPinId: pinId,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.CHANGE_TITLE:
      newPinList = [...state.album.pins];
      pinIndex = newPinList.findIndex(
        (element) => element.pinId === action.payload.pinId,
      );
      if (pinIndex > -1) {
        newPin = {
          ...newPinList[pinIndex],
          title: action.payload.title,
        };
      }
      newPinList[pinIndex] = newPin;
      state = {
        ...state,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.CHANGE_TEXT:
      newPinList = [...state.album.pins];
      pinIndex = newPinList.findIndex(
        (element) => element.pinId === action.payload.pinId,
      );
      if (pinIndex > -1) {
        newPin = {
          ...newPinList[pinIndex],
          title: action.payload.text,
        };
      }
      newPinList[pinIndex] = newPin;
      state = {
        ...state,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.CHANGE_IMAGE_SUBTITLE:
      newPinList = [...state.album.pins];
      pinIndex = newPinList.findIndex(
        (element) => element.pinId === action.payload.pinId,
      );
      if (pinIndex > -1) {
        newPin = {
          ...newPinList[pinIndex],
          title: action.payload.title,
        };
      }
      newPinList[pinIndex] = newPin;
      state = {
        ...state,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.MOVE_UP:
      newPinList = [...state.album.pins];
      pinIndex = newPinList.findIndex(
        (element) => element.pinId === action.payload,
      );
      if (pinIndex > 0) {
        const pinAbove = newPinList[pinIndex - 1];
        const currentPin = newPinList[pinIndex];
        newPinList[pinIndex - 1] = currentPin;
        newPinList[pinIndex] = pinAbove;
        state = {
          ...state,
          album: {
            ...state.album,
            pins: newPinList,
          },
        };
      }
      break;
    case AT.MOVE_DOWN:
      newPinList = [...state.album.pins];
      pinIndex = newPinList.findIndex(
        (element) => element.pinId === action.payload,
      );
      if (pinIndex < newPinList.length - 1) {
        const pinBelow = newPinList[pinIndex + 1];
        const currentPin = newPinList[pinIndex];
        newPinList[pinIndex] = pinBelow;
        newPinList[pinIndex + 1] = currentPin;
        state = {
          ...state,
          album: {
            ...state.album,
            pins: newPinList,
          },
        };
      }
      break;
    case AT.REMOVE_PIN:
      newPinList = [...state.album.pins];
      pinIndex = newPinList.findIndex(
        (element) => element.pinId === action.payload,
      );
      if (pinIndex > -1) {
        newPinList.splice(pinIndex, 1);
      }
      state = {
        ...state,
        album: {
          ...state.album,
          pins: newPinList,
        },
      };
      break;
    case AT.CREATE_ALBUM:
      state = {
        ...state,
        status: {
          ...state.status,
          saving: true,
          error: null,
        },
      };
      break;
    case AT.CREATE_ALBUM_FAIL:
      state = {
        ...state,
        status: {
          ...state.status,
          saving: false,
          error: action.result,
        },
      };
      break;
    case AT.CREATE_ALBUM_SUCCESS:
      state = {
        ...state,
        album: {
          ...state.album,
          ...action.result.content,
        },
        status: {
          ...state.status,
          saving: false,
          error: null,
          saved: true,
        },
      };
      break;
    case AT.SAVE_ALBUM:
    case AT.CREATE_PIN:
      state = {
        ...state,
        status: {
          ...state.status,
          saving: true,
          saved: false,
          error: null,
        },
      };
      break;
    case AT.SAVE_ALBUM_FAIL:
    case AT.CREATE_PIN_FAIL:
      state = {
        ...state,
        status: {
          ...state.status,
          saving: false,
          saved: false,
          error: action.result,
        },
      };
      break;
    case AT.SAVE_ALBUM_SUCCESS:
    case AT.CREATE_PIN_SUCCESS:
      state = {
        ...state,
        status: {
          ...state.status,
          saving: false,
          error: null,
          saved: true,
        },
      };
      break;
    case AT.LOAD_ALBUMS:
      state = {
        ...state,
        status: {
          ...state.status,
          loading: true,
          loaded: false,
          error: null,
        },
      };
      break;
    case AT.LOAD_ALBUMS_SUCCESS:
      state = {
        ...state,
        status: {
          ...state.status,
          loading: false,
          loaded: true,
          error: null,
        },
        albums: action.result.results,
      };
      break;
    case AT.LOAD_ALBUMS_FAIL:
      state = {
        ...state,
        status: {
          ...state.status,
          loading: false,
          loaded: false,
          error: action.result,
        },
      };
      break;
    case AT.LOAD_PINS:
      state = {
        ...state,
        status: {
          ...state.status,
          pinsLoading: true,
          pinsLoaded: false,
          error: null,
        },
      };
      break;
    case AT.LOAD_PINS_SUCCESS:
      identifiedPins = action.result.results.map((item, idx) => {
        pinId = idx;
        return {
          ...item,
          pinId,
        };
      });
      state = {
        ...state,
        status: {
          ...state.status,
          pinsLoading: false,
          pinsLoaded: true,
          error: null,
        },
        lastPinId: pinId,
        album: {
          ...state.album,
          pins: identifiedPins,
        },
      };
      break;
    case AT.LOAD_PINS_FAIL:
      state = {
        ...state,
        status: {
          ...state.status,
          pinsLoading: false,
          pinsLoaded: false,
          error: action.result,
        },
      };
      break;
    case AT.LOAD_ALBUM:
      state = {
        ...state,
        status: {
          ...state.status,
          loading: true,
          loaded: false,
          error: null,
        },
        album: {},
      };
      break;
    case AT.LOAD_ALBUM_SUCCESS:
      state = {
        ...state,
        status: {
          ...state.status,
          loading: false,
          loaded: true,
          error: null,
        },
        album: {
          ...action.result,
          pins: identifiedPins,
        },
        lastPinId: pinId,
      };
      break;
    case AT.LOAD_ALBUM_FAIL:
      state = {
        ...state,
        status: {
          ...state.status,
          loading: false,
          loaded: false,
          error: action.result,
        },
        album: {},
      };
      break;
    case AT.LIKE_ALBUM:
    case AT.LIKE_ALBUM_SUCCESS:
      state = {
        ...state,
        album: {
          ...state.album,
          isLiked: true,
        },
      };
      break;
    case AT.LIKE_ALBUM_FAIL:
      state = {
        ...state,
        album: {
          ...state.album,
          isLiked: false,
        },
      };
      break;
    case AT.UNLIKE_ALBUM:
    case AT.UNLIKE_ALBUM_SUCCESS:
      state = {
        ...state,
        album: {
          ...state.album,
          isLiked: false,
        },
      };
      break;
    case AT.UNLIKE_ALBUM_FAIL:
      state = {
        ...state,
        album: {
          ...state.album,
          isLiked: true,
        },
      };
      break;
    case AT.LOAD_ALBUM_WISHLIST_PINS:
      state = Object.assign({}, state, {
        status: {
          pinsLoaded: false,
          pinsLoading: true,
          error: null,
        },
      });
      break;
    case AT.LOAD_ALBUM_WISHLIST_PINS_SUCCESS:
      state = Object.assign({}, state, {
        album: {
          pins: action.result.pins,
          userUuid: action.result.userUuid,
          isPrivate: action.result.isPrivate,
        },
        status: {
          pinsLoaded: true,
          pinsLoading: false,
          error: null,
        },
      });
      break;
    case AT.LOAD_ALBUM_WISHLIST_PINS_FAIL:
      state = Object.assign({}, state, {
        album: {
          pins: [],
          userUuid: '',
          isPrivate: false,
        },
        status: {
          pinsLoaded: false,
          pinsLoading: false,
          error: action.error,
        },
      });
      break;
    case AT.REMOVE_PIN_FROM_ALBUM:
      newPinList = state.album.pins.filter(
        (element) => element.pk !== action.payload.pinId,
      );
      state = Object.assign({}, state, {
        album: {
          pins: newPinList,
        },
      });
      break;
    case AT.REMOVE_PIN_FROM_ALBUM_SUCCESS:
      state = {
        ...state,
      };
      break;
    case AT.REMOVE_PIN_FROM_ALBUM_FAIL:
      state = {
        ...state,
      };
      break;
    case AT.CREATE_ALBUM_WISHLIST_PIN:
      state = {
        ...state,
      };
      break;
    case AT.CREATE_ALBUM_WISHLIST_PIN_SUCCESS:
      state = Object.assign({}, state, {
        album: {
          pins: action.result.pins,
        },
      });
      break;
    case AT.CREATE_ALBUM_WISHLIST_PIN_FAIL:
      state = {
        ...state,
      };
      break;
  }

  return state;
}

export function removePinFromAlbum(
  userUuid,
  pinId,
  upc = '',
  callback = () => {},
) {
  return {
    types: [
      AT.REMOVE_PIN_FROM_ALBUM,
      AT.REMOVE_PIN_FROM_ALBUM_SUCCESS,
      AT.REMOVE_PIN_FROM_ALBUM_FAIL,
    ],
    promise: (client) =>
      client.del(`${config.API_URL_KINGPIN}/owners/${userUuid}/pins/${pinId}/`),
    payload: {
      pinId,
    },
    options: {
      transformer: (result) => {
        const res = {
          ...result,
          pinId,
          upc,
        };
        typeof callback === 'function' && callback(res);
        return res;
      },
    },
  };
}

/** Album: Wishlist-specific functions */

export function getWishlistAlbumPins(
  userUuid,
  businessUnit = null,
  callback = () => {},
) {
  return {
    types: [
      AT.LOAD_ALBUM_WISHLIST_PINS,
      AT.LOAD_ALBUM_WISHLIST_PINS_SUCCESS,
      AT.LOAD_ALBUM_WISHLIST_PINS_FAIL,
    ],
    promise: (client) =>
      client.get(`${config.API_URL_KINGPIN}/wishlists/?format=json`, {
        params: {
          business_unit: businessUnit,
        },
      }),
    options: {
      transformer: (result) => {
        const res = {
          ...result,
          userUuid,
        };
        typeof callback === 'function' && callback(res);
        return res;
      },
    },
  };
}

export function createWishlistAlbumPin(
  businessUnit,
  product,
  callback = () => {},
) {
  return {
    types: [
      AT.CREATE_ALBUM_WISHLIST_PIN,
      AT.CREATE_ALBUM_WISHLIST_PIN_SUCCESS,
      AT.CREATE_ALBUM_WISHLIST_PIN_FAIL,
    ],
    promise: (client) =>
      client.post(`${config.API_URL_KINGPIN}/wishlists/`, {
        data: {
          pins: [
            {
              title: product.title,
              imageUrl: product.image,
              contentId: product.objectID,
              contentType: K_CONTENT_TYPE_PRODUCT,
              contentUrl: product.url,
              ideabookSlugs: '',
            },
          ],
          businessUnit,
        },
      }),
    options: {
      transformer: (result) => {
        typeof callback === 'function' && callback(result);
        return result;
      },
    },
  };
}
