import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import {
  getProductOptionValuesByUpcFromAttributes,
  virtualizeProduct,
} from 'app-libs/etc/productHelper';

import { K_PRODUCT_CHILDREN_KEYS } from 'constants/productConstants';

import { getProductEntities } from './entities';

const emptyObject = {};

export function _transformProduct(rawProduct) {
  let product = Object.assign({}, rawProduct);
  if (product?.isBlacklisted) {
    return {};
  }
  product = virtualizeProduct(product);
  return product;
}

/**
 * get product by objectID
 * @param  objectID
 * @return product
 */
export const getProductByObjectIDFromProductEntities = memoize((objectID) =>
  createSelector(getProductEntities, (products) =>
    _transformProduct(products[objectID] || emptyObject),
  ),
);

export const getProductRawByObjectIDFromProductEntities = memoize((objectID) =>
  createSelector(
    getProductEntities,
    (products) => products[objectID] || emptyObject,
  ),
);

/**
 * handling get product in product detail
 * - if product is not in entities, return props.productFromPrevious
 * - else return product from entities
 * @param  props {pid: 'ASA-8', productFromPrevious: product}
 * @return product
 */
export const getProductFromEntitiesIfExistsElseGetFromProps = memoize((props) =>
  createSelector(
    getProductByObjectIDFromProductEntities(
      get(props, 'pid') || get(props, 'params.pid'),
    ),
    (product) => {
      if (isEmpty(product)) return props.productFromPrevious || emptyObject;
      return product || emptyObject;
    },
  ),
);

export const makeGetProductsFromEntitiesByUpcsSelector = () =>
  createSelector(
    getProductEntities,
    (_, upcs) => upcs,
    (productsByUpc, upcs) => {
      const products = [];
      upcs.forEach((upc) => {
        if (upc in productsByUpc) {
          products.push(productsByUpc[upc]);
        }
      });
      return products;
    },
  );

export const genProductOptionAttributesByUpc = createSelector(
  getProductEntities,
  (products) => getProductOptionValuesByUpcFromAttributes(products),
);

export const isReviewedProductByBrandLoaded = memoize(
  (brand) => (state) => has(state, `product.reviewedProductsByBrand.${brand}`),
);

export const getBrandStats = memoize(
  (brand) => (state) => get(state, `product.brandStats.${brand}`, emptyObject),
);

export const getRecommendedFBTCategory = memoize(
  (category) => (state) =>
    get(state, `product.recommendedFbtCategoryByName.${category}`, []),
);

export const getColorSiblingsByUpc = (state) =>
  state?.product?.colorSiblingsByUpc || {};

export const isProductChildrenLoaded = memoize(
  (product, producttChildrenPropKeys = K_PRODUCT_CHILDREN_KEYS) =>
    (state) => {
      let productChildrenIds = [];
      producttChildrenPropKeys.forEach((prop) => {
        /** Use get because prop can be nested attributes, which won't work with [] */
        const ids = get(product, prop);
        productChildrenIds = productChildrenIds.concat(ids);
      });
      productChildrenIds = productChildrenIds.filter(Boolean);
      return productChildrenIds.every(
        (productChildrenId) =>
          !isEmpty(
            getProductRawByObjectIDFromProductEntities(productChildrenId)(
              state,
            ),
          ),
      );
    },
);
