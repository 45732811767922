import { K_MOBILE_MAX_WIDTH } from '@dkrm/general-libs/theme/styles';
import { Call, Email, Store } from '@dkrm/icons';

import { KeyValueByVariable } from 'components/types';

import {
  K_LANG_CS_EMAIL,
  K_LANG_CS_PHONE_NUMBER,
  K_LANG_CS_WORKING_HOUR,
} from 'constants/langConstants';
import {
  K_ASSEMBLE_SERVICE_URL,
  K_HOW_TO_SHOP_URL,
  K_INSTALLMENT_URL,
  K_PRIVACY_POLICY,
  K_PRODUCT_REPLACEMENT_URL,
  K_PROMO_VOUCHER_URL,
  K_REFUND_URL,
  K_RETAIL_PRODUCT_PROTECTION_URL,
  K_ROUTE_ABOUT_US,
  K_ROUTE_CAREER,
  K_ROUTE_HELP,
  K_ROUTE_HOW_TO_SELL,
  K_ROUTE_OFFLINE_STORE_LIST,
  K_ROUTE_PORTFOLIO_LIST,
  K_ROUTE_REAL_ESTATE_LIST_CITY,
  K_TERMS_CONDITIONS,
} from 'constants/routeConstants';

export const K_TABLET_FOOTER_BREAKPOINT = 1024;
export const K_MOBILE_FOOTER_BREAKPOINT = K_MOBILE_MAX_WIDTH;
export const K_WIDTH_DEKORUMA_DEFAULT_ICON = 120;
export const K_HEIGHT_DEKORUMA_DEFAULT_ICON = 28;
export const K_WIDTH_SMALL_ICON = 57;
export const K_HEIGHT_SMALL_ICON = 24;
export const K_WIDTH_APP_ICON = 120;
export const K_HEIGHT_APP_ICON = 36;

export const K_FOOTER_DESCRIPTIONS = `PT Dekoruma Inovasi Lestari © ${new Date().getFullYear()} | No.1 Japandi Style Furnishing`;

export interface FooterItem {
  label: string;
  linkUrl?: string;
  isUsingAnchor?: boolean;
  Icon?: React.FC;
}

const K_DEKORUMA_ITEMS_MOBILE: FooterItem[] = [
  {
    label: 'Tentang Kami',
    linkUrl: K_ROUTE_ABOUT_US,
    isUsingAnchor: true,
  },
  {
    label: 'Artikel',
    linkUrl: '/artikel',
    isUsingAnchor: true,
  },
  {
    label: 'Kebijakan Privasi',
    linkUrl: K_PRIVACY_POLICY,
    isUsingAnchor: true,
  },
  {
    label: 'Karir',
    linkUrl: K_ROUTE_CAREER,
    isUsingAnchor: true,
  },
  {
    label: 'Syarat & Ketentuan',
    linkUrl: K_TERMS_CONDITIONS,
    isUsingAnchor: true,
  },
  {
    label: 'Help Center',
    linkUrl: K_ROUTE_HELP,
    isUsingAnchor: true,
  },
  {
    label: 'Lokasi Toko / Dekoruma Experience Center',
    linkUrl: K_ROUTE_OFFLINE_STORE_LIST,
    isUsingAnchor: true,
  },
];

const K_DEKORUMA_ITEMS_TABLET: FooterItem[] = [
  {
    label: 'Tentang Kami',
    linkUrl: K_ROUTE_ABOUT_US,
    isUsingAnchor: true,
  },
  {
    label: 'Kebijakan Privasi',
    linkUrl: K_PRIVACY_POLICY,
    isUsingAnchor: true,
  },
  {
    label: 'Syarat & Ketentuan',
    linkUrl: K_TERMS_CONDITIONS,
    isUsingAnchor: true,
  },
  {
    label: 'Artikel',
    linkUrl: '/artikel',
    isUsingAnchor: true,
  },
  {
    label: 'Karir',
    linkUrl: K_ROUTE_CAREER,
    isUsingAnchor: true,
  },
  {
    label: 'Help Center',
    linkUrl: K_ROUTE_HELP,
    isUsingAnchor: true,
  },
  {
    label: 'Lokasi Toko / Dekoruma Experience Center',
    linkUrl: K_ROUTE_OFFLINE_STORE_LIST,
    isUsingAnchor: true,
  },
];

const K_FURNITURE_AND_DECORATIONS_MOBILE: FooterItem[] = [
  {
    label: 'Cara Belanja',
    linkUrl: K_HOW_TO_SHOP_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Penukaran Produk',
    linkUrl: K_PRODUCT_REPLACEMENT_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Promo & Voucher',
    linkUrl: K_PROMO_VOUCHER_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Pengembalian Dana',
    linkUrl: K_REFUND_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Cara Pembayaran & Cicilan',
    linkUrl: K_INSTALLMENT_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Partner Jasa Rakit Dekoruma',
    linkUrl: K_ASSEMBLE_SERVICE_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Perlindungan Produk Retail',
    linkUrl: K_RETAIL_PRODUCT_PROTECTION_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Jadi Merchant Dekoruma',
    linkUrl: K_ROUTE_HOW_TO_SELL,
    isUsingAnchor: true,
  },
];

const K_FURNITURE_AND_DECORATIONS_TABLET: FooterItem[] = [
  {
    label: 'Cara Belanja',
    linkUrl: K_HOW_TO_SHOP_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Promo & Voucher',
    linkUrl: K_PROMO_VOUCHER_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Cara Pembayaran & Cicilan',
    linkUrl: K_INSTALLMENT_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Perlindungan Produk Retail',
    linkUrl: K_RETAIL_PRODUCT_PROTECTION_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Penukaran Produk',
    linkUrl: K_PRODUCT_REPLACEMENT_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Pengembalian Dana',
    linkUrl: K_REFUND_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Partner Jasa Rakit Dekoruma',
    linkUrl: K_ASSEMBLE_SERVICE_URL,
    isUsingAnchor: true,
  },
  {
    label: 'Jadi Merchant Dekoruma',
    linkUrl: K_ROUTE_HOW_TO_SELL,
    isUsingAnchor: true,
  },
];

const K_CONTACT_INFO: FooterItem[] = [
  {
    label: 'PT. Dekoruma Inovasi Lestari',
  },
  {
    Icon: Call,
    label: K_LANG_CS_PHONE_NUMBER,
    linkUrl: 'tel:02150986977',
    isUsingAnchor: true,
  },
  {
    Icon: Email,
    label: K_LANG_CS_EMAIL,
    linkUrl: 'mailto:hello@dekoruma.com',
    isUsingAnchor: true,
  },
  { Icon: Store, label: K_LANG_CS_WORKING_HOUR },
];

export const K_FOOTER_SECTION_DEKORUMA = 'dekoruma';
export const K_FOOTER_SECTION_BUSINESS_UNIT = 'businessUnit';
export const K_FOOTER_SECTION_CONTACT = 'contact';

export const K_FOOTER_SECTION_ITEMS = {
  [K_FOOTER_SECTION_DEKORUMA]: {
    title: 'Dekoruma',
    items: [K_DEKORUMA_ITEMS_MOBILE, K_DEKORUMA_ITEMS_TABLET],
  },
  [K_FOOTER_SECTION_BUSINESS_UNIT]: {
    title: 'Furnitur & Aksesoris',
    items: [
      K_FURNITURE_AND_DECORATIONS_MOBILE,
      K_FURNITURE_AND_DECORATIONS_TABLET,
    ],
  },
  [K_FOOTER_SECTION_CONTACT]: {
    title: 'Layanan Pengaduan Konsumen / Kontak Kami',
    items: [K_CONTACT_INFO, K_CONTACT_INFO],
  },
};

const K_DEKORUMA_TOP_FURNITURE: FooterItem[] = [
  {
    label: 'Sofa',
    linkUrl: '/Sofa',
  },
  {
    label: 'Tempat Tidur',
    linkUrl: '/Tempat-Tidur',
  },
  {
    label: 'Rak & Penyimpanan',
    linkUrl: '/Rak-&-Penyimpanan',
  },
  {
    label: 'Sofa Bed',
    linkUrl: '/Sofa-Bed',
  },
  {
    label: 'Lemari Pakaian',
    linkUrl: '/Lemari-Pakaian',
  },
  {
    label: 'Meja & Kursi Makan',
    linkUrl: '/Meja-&-Kursi-Makan',
  },
  {
    label: 'Meja TV',
    linkUrl: '/Meja-TV',
  },
  {
    label: 'Kursi Kantor',
    linkUrl: '/Kursi-Kantor',
  },
  {
    label: 'Meja Makan',
    linkUrl: '/Meja-Makan',
  },
  {
    label: 'Sofa 2 Seater',
    linkUrl: '/Sofa-2-Seater',
  },
  {
    label: 'Meja Kerja',
    linkUrl: '/Meja-Kerja',
  },
  {
    label: 'Sofa 3 Seater',
    linkUrl: '/Sofa-3-Seater',
  },
  {
    label: 'Sofa L',
    linkUrl: '/Sofa-L',
  },
  {
    label: 'Lampu',
    linkUrl: '/Lampu',
  },
  {
    label: 'Lemari Pakaian 3 Pintu',
    linkUrl: '/Lemari-Pakaian-3-Pintu',
  },
  {
    label: 'Lemari Laci',
    linkUrl: '/Lemari-Laci',
  },
  {
    label: 'Kursi Makan',
    linkUrl: '/Kursi-Makan',
  },
  {
    label: 'Rak Sepatu',
    linkUrl: '/Rak-Sepatu',
  },
  {
    label: 'Meja Kantor',
    linkUrl: '/Meja-Kantor',
  },
  {
    label: 'Kursi Bar',
    linkUrl: '/Kursi-Bar',
  },
];

const K_DEKORUMA_TOP_INTERIOR: FooterItem[] = [
  {
    label: 'Desain Interior Rumah',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Rumah`,
  },
  {
    label: 'Desain Interior Rumah Modern Minimalis',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Rumah-Modern-Minimalis`,
  },
  {
    label: 'Desain Interior Rumah Scandinavian',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Rumah-Scandinavian`,
  },
  {
    label: 'Desain Interior Rumah Space Saving dan Multifungsi',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Rumah-Space-Saving-dan-Multifungsi`,
  },
  {
    label: 'Desain Interior Rumah Modern Klasik',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Rumah-Modern-Klasik`,
  },
  {
    label: 'Desain Interior Apartemen',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Apartemen`,
  },
  {
    label: 'Desain Interior Apartemen 2 Kamar Tidur',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Apartemen-2-Kamar-Tidur`,
  },
  {
    label: 'Desain Interior Apartemen Studio',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Apartemen-Studio`,
  },
  {
    label: 'Desain Interior Apartemen 1 Kamar Tidur',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Apartemen-1-Kamar-Tidur`,
  },
  {
    label: 'Desain Interior Apartemen Space Saving dan Multifungsi',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior-Apartemen-Space-Saving-dan-Multifungsi`,
  },
  {
    label: 'Kitchen Set',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Kitchen-Set`,
  },
  {
    label: 'Kitchen Set Modern Minimalis',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Kitchen-Set-Modern-Minimalis`,
  },
  {
    label: 'Kitchen Set Scandinavian',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Kitchen-Set-Scandinavian`,
  },
  {
    label: 'Kitchen Set Korea',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Kitchen-Set-Korea`,
  },
  {
    label: 'Desain Interior',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior`,
  },
  {
    label: 'Desain Interior Tangerang Selatan',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior/Kota/Tangerang-Selatan`,
  },
  {
    label: 'Desain Interior Bekasi',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior/Kota/Bekasi`,
  },
  {
    label: 'Desain Interior Depok',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior/Kota/Depok`,
  },
  {
    label: 'Desain Interior Jakarta Timur',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior/Kota/Jakarta-Timur`,
  },
  {
    label: 'Desain Interior Tangerang',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}/Desain-Interior/Kota/Tangerang`,
  },
];

const K_DEKORUMA_TOP_PROPERTI: FooterItem[] = [
  {
    label: 'Jakarta Selatan',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/jakarta-selatan`,
  },
  {
    label: 'Depok',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/depok`,
  },
  {
    label: 'Bogor',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/bogor`,
  },
  {
    label: 'Jakarta Barat',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/jakarta-barat`,
  },
  {
    label: 'Jakarta Timur',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/jakarta-timur`,
  },
  {
    label: 'Bekasi',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/bekasi`,
  },
  {
    label: 'Jakarta Pusat',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/jakarta-pusat`,
  },
  {
    label: 'Tangerang',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/tangerang`,
  },
  {
    label: 'Tangerang Selatan',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/tangerang-selatan`,
  },
  {
    label: 'Jakarta Utara',
    linkUrl: `${K_ROUTE_REAL_ESTATE_LIST_CITY}/jakarta-utara`,
  },
];

const K_DEKORUMA_TOP_FURNITURE_JAPANDI: FooterItem[] = [
  {
    label: 'Sofa Japandi',
    linkUrl: '/Sofa-Japandi',
  },
  {
    label: 'Tempat Tidur Japandi',
    linkUrl: '/Tempat-Tidur-Japandi',
  },
  {
    label: 'Rak & Penyimpanan Japandi',
    linkUrl: '/Rak-&-Penyimpanan-Japandi',
  },
  {
    label: 'Sofa Bed Japandi',
    linkUrl: '/Sofa-Bed-Japandi',
  },
  {
    label: 'Lemari Pakaian Japandi',
    linkUrl: '/Lemari-Pakaian-Japandi',
  },
  {
    label: 'Meja & Kursi Makan Japandi',
    linkUrl: '/Meja-&-Kursi-Makan-Japandi',
  },
  {
    label: 'Meja TV Japandi',
    linkUrl: '/Meja-TV-Japandi',
  },
  {
    label: 'Kursi Kantor Japandi',
    linkUrl: '/Kursi-Kantor-Japandi',
  },
  {
    label: 'Meja Makan Japandi',
    linkUrl: '/Meja-Makan-Japandi',
  },
  {
    label: 'Sofa 2 Seater Japandi',
    linkUrl: '/Sofa-2-Seater-Japandi',
  },
  {
    label: 'Meja Kerja Japandi',
    linkUrl: '/Meja-Kerja-Japandi',
  },
  {
    label: 'Sofa 3 Seater Japandi',
    linkUrl: '/Sofa-3-Seater-Japandi',
  },
  {
    label: 'Sofa L Japandi',
    linkUrl: '/Sofa-L-Japandi',
  },
  {
    label: 'Lampu Japandi',
    linkUrl: '/Lampu-Japandi',
  },
  {
    label: 'Lemari Pakaian 3 Pintu Japandi',
    linkUrl: '/Lemari-Pakaian-3-Pintu-Japandi',
  },
  {
    label: 'Lemari Laci Japandi',
    linkUrl: '/Lemari-Laci-Japandi',
  },
  {
    label: 'Kursi Makan Japandi',
    linkUrl: '/Kursi-Makan-Japandi',
  },
  {
    label: 'Rak Sepatu Japandi',
    linkUrl: '/Rak-Sepatu-Japandi',
  },
  {
    label: 'Meja Kantor Japandi',
    linkUrl: '/Meja-Kantor-Japandi',
  },
  {
    label: 'Kursi Bar Japandi',
    linkUrl: '/Kursi-Bar-Japandi',
  },
];

export const K_TOP_CATEGORY_SECTION_FURNITURE = 'furnitur';
export const K_TOP_CATEGORY_SECTION_INTERIOR = 'interior';
export const K_TOP_CATEGORY_SECTION_PROPERTI = 'properti';
export const K_TOP_CATEGORY_SECTION_FURNITURE_JAPANDI = 'furniture-japandi';
export const K_TOP_CATEGORY_SECTION_CATEGORY_AREA = 'area';
export const K_TOP_CATEGORY_LABELS = [
  K_TOP_CATEGORY_SECTION_FURNITURE,
  K_TOP_CATEGORY_SECTION_INTERIOR,
  K_TOP_CATEGORY_SECTION_PROPERTI,
  K_TOP_CATEGORY_SECTION_FURNITURE_JAPANDI,
];

export const K_TOP_CATEGORY_SECTION_TITLE_CATEGORY_AREA_MOBILE = 'Top Area';
export const K_TOP_CATEGORY_SECTION_TITLE_CATEGORY_AREA_TABLET =
  'Top Kategori Area';

interface SectionItem {
  title: string;
  items:
    | typeof K_DEKORUMA_TOP_FURNITURE
    | typeof K_DEKORUMA_TOP_INTERIOR
    | typeof K_DEKORUMA_TOP_PROPERTI;
}

export const K_TOP_CATEGORY_SECTION_ITEMS_MOBILE: KeyValueByVariable<SectionItem> =
  {
    [K_TOP_CATEGORY_SECTION_FURNITURE]: {
      title: 'Top Furnitur',
      items: K_DEKORUMA_TOP_FURNITURE,
    },
    [K_TOP_CATEGORY_SECTION_INTERIOR]: {
      title: 'Top Interior',
      items: K_DEKORUMA_TOP_INTERIOR,
    },
    [K_TOP_CATEGORY_SECTION_PROPERTI]: {
      title: 'Top Properti',
      items: K_DEKORUMA_TOP_PROPERTI,
    },
    [K_TOP_CATEGORY_SECTION_FURNITURE_JAPANDI]: {
      title: 'Top Furniture Japandi',
      items: K_DEKORUMA_TOP_FURNITURE_JAPANDI,
    },
  };

export const K_TOP_CATEGORY_SECTION_ITEMS_TABLET: KeyValueByVariable<SectionItem> =
  {
    [K_TOP_CATEGORY_SECTION_FURNITURE]: {
      title: 'Top Kategori Furnitur',
      items: K_DEKORUMA_TOP_FURNITURE,
    },
    [K_TOP_CATEGORY_SECTION_INTERIOR]: {
      title: 'Top Kategori Interior',
      items: K_DEKORUMA_TOP_INTERIOR,
    },
    [K_TOP_CATEGORY_SECTION_PROPERTI]: {
      title: 'Top Kategori Properti',
      items: K_DEKORUMA_TOP_PROPERTI,
    },
    [K_TOP_CATEGORY_SECTION_FURNITURE_JAPANDI]: {
      title: 'Top Kategori Furniture Japandi',
      items: K_DEKORUMA_TOP_FURNITURE_JAPANDI,
    },
  };
