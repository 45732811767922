import keyMirror from 'keymirror';

const AT = keyMirror({
  RESET_PROFILE: null,

  GET_PROFILE: null,
  GET_PROFILE_SUCCESS: null,
  GET_PROFILE_FAIL: null,

  REGISTER_DESIGNER: null,
  REGISTER_DESIGNER_SUCCESS: null,
  REGISTER_DESIGNER_FAIL: null,

  GET_PROFILE_DESIGNER: null,
  GET_PROFILE_DESIGNER_SUCCESS: null,
  GET_PROFILE_DESIGNER_FAIL: null,

  PATCH_PROFILE_DESIGNER: null,
  PATCH_PROFILE_DESIGNER_SUCCESS: null,
  PATCH_PROFILE_DESIGNER_FAIL: null,

  POST_REGISTER_PARTNER: null,
  POST_REGISTER_PARTNER_SUCCESS: null,
  POST_REGISTER_PARTNER_FAIL: null,
});

export default AT;
