import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { NearMe, Whatsapp } from '@dkrm/icons';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { OfflineStoreLocation } from 'entities/offlineStore/types';

interface FooterButtonGroupProps {
  offlineStoreLocation: OfflineStoreLocation;
}

const FooterButtonGroup: React.FC<FooterButtonGroupProps> = memo(
  ({ offlineStoreLocation }) => {
    return (
      <View style={s.buttonGroup}>
        <ButtonWrapper
          style={s.mapButton}
          to={offlineStoreLocation.mapUrl}
          linkProps={{ isUsingAnchor: true }}
        >
          {() => (
            <View style={s.horizontalContainer}>
              <NearMe size={16} theme="teal500" style={s.icon} />
              <Text theme="body4-teal500">Arahkan</Text>
            </View>
          )}
        </ButtonWrapper>
        <ButtonWrapper
          style={s.waButton}
          to={offlineStoreLocation.whatsappUrl}
          linkProps={{ isUsingAnchor: true }}
        >
          {() => (
            <View style={s.horizontalContainer}>
              <Whatsapp size={16} theme="teal500" style={s.icon} />
              <Text theme="body4-teal500">Whatsapp</Text>
            </View>
          )}
        </ButtonWrapper>
      </View>
    );
  },
);

const s = StyleSheet.create({
  buttonGroup: {
    ...cStyles.phm,
    ...cStyles.pbm,
    marginTop: 'auto',
  },
  icon: {
    marginRight: 6,
  },
  horizontalContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
    alignItems: 'center',
  },
  waButton: {
    ...cStyles.brm,
    ...cStyles.bwam,
    ...cStyles.pvm,
    ...cStyles.phl,
    borderColor: Colors.C_TEAL_500,
  },
  mapButton: {
    ...cStyles.mtl,
    ...cStyles.mbm,
    ...cStyles.brm,
    ...cStyles.bwam,
    ...cStyles.pvm,
    ...cStyles.phl,
    borderColor: Colors.C_TEAL_500,
  },
});

export default FooterButtonGroup;
