import produce from 'immer';
import keyMirror from 'keymirror';

import config from 'config';

export const AT = keyMirror({
  LOAD_SHIPPING_EVENT_BY_ORDER_KEY: null,
  LOAD_SHIPPING_EVENT_BY_ORDER_KEY_SUCCESS: null,
  LOAD_SHIPPING_EVENT_BY_ORDER_KEY_FAIL: null,

  LOAD_SINGLE_SHIPPING_EVENT: null,
  LOAD_SINGLE_SHIPPING_EVENT_SUCCESS: null,
  LOAD_SINGLE_SHIPPING_EVENT_FAIL: null,

  COMPLETE_SHIPPING_EVENT: null,
  COMPLETE_SHIPPING_EVENT_SUCCESS: null,
  COMPLETE_SHIPPING_EVENT_FAIL: null,
});

const initialState = {
  loading: false,
  results: {},
  resultsByOrderKey: {},
};

const shippingEventReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    /** Loading cases */
    case AT.LOAD_SHIPPING_EVENT_BY_ORDER_KEY:
    case AT.LOAD_SINGLE_SHIPPING_EVENT:
    case AT.COMPLETE_SHIPPING_EVENT:
      draft.loading = true;
      break;

    /** success cases */
    case AT.LOAD_SINGLE_SHIPPING_EVENT_SUCCESS: {
      const { orderKey } = action.result;
      draft.loading = false;
      draft.results[action.result.number] = action.result;

      if (!draft.resultsByOrderKey[orderKey]) {
        draft.resultsByOrderKey[orderKey] = [];
        draft.resultsByOrderKey[orderKey].push(action.result);
      } else {
        draft.resultsByOrderKey[orderKey] = draft.resultsByOrderKey[
          orderKey
        ].map((shippingEvent) =>
          shippingEvent.number === action.result.number
            ? action.result
            : shippingEvent,
        );
      }
      break;
    }
    case AT.LOAD_SHIPPING_EVENT_BY_ORDER_KEY_SUCCESS: {
      const { orderKey } = action.result.results[0];
      draft.loading = false;
      draft.resultsByOrderKey[orderKey] = action.result.results;

      action.result.results.forEach((shippingEvent) => {
        draft.results[shippingEvent.number] = shippingEvent;
      });
      break;
    }
    case AT.COMPLETE_SHIPPING_EVENT_SUCCESS: {
      const { orderKey } = action.result;
      draft.loading = false;
      draft.resultsByOrderKey[orderKey] = draft.resultsByOrderKey[orderKey].map(
        (shippingEvent) =>
          shippingEvent.number === action.result.number
            ? action.result
            : shippingEvent,
      );
      draft.results[action.result.number] = action.result;
      break;
    }

    /** fail cases */
    case AT.LOAD_SHIPPING_EVENT_BY_ORDER_KEY_FAIL:
    case AT.LOAD_SINGLE_SHIPPING_EVENT_FAIL:
    case AT.COMPLETE_SHIPPING_EVENT_FAIL:
      draft.loading = false;
      break;
  }
  return draft;
}, initialState);

export const loadShippingEventsFromOrderKey = (orderKey, callback) => {
  return {
    types: [
      AT.LOAD_SHIPPING_EVENT_BY_ORDER_KEY,
      AT.LOAD_SHIPPING_EVENT_BY_ORDER_KEY_SUCCESS,
      AT.LOAD_SHIPPING_EVENT_BY_ORDER_KEY_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/orders/${orderKey}/shipping-events/?format=json`,
      ),
    options: {
      callback,
    },
  };
};

export const loadShippingEventByNumber = (shippingEventNumber, callback) => {
  return {
    types: [
      AT.LOAD_SINGLE_SHIPPING_EVENT,
      AT.LOAD_SINGLE_SHIPPING_EVENT_SUCCESS,
      AT.LOAD_SINGLE_SHIPPING_EVENT_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/shipping-event/${shippingEventNumber}/?format=json`,
      ),
    options: {
      callback,
    },
  };
};

export const completeShippingEvent = (shippingNumber, callback) => {
  return {
    types: [
      AT.COMPLETE_SHIPPING_EVENT,
      AT.COMPLETE_SHIPPING_EVENT_SUCCESS,
      AT.COMPLETE_SHIPPING_EVENT_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/shipping-event/complete/?format=json`,
        {
          data: {
            shippingEventNumber: shippingNumber,
          },
        },
      ),
    options: {
      callback,
    },
  };
};

export default shippingEventReducer;
