import has from 'lodash/has';

const initialState = {};

function getStatus(action) {
  let status = {};
  if (action.type.match(/SUCCESS/i)) {
    // case SUCCESS
    status = {
      ...status,
      loading: false,
      loaded: true,
      loadingError: undefined,
      lastLoadedAt: new Date(),
    };
  } else if (action.type.match(/FAIL/i)) {
    // case FAIL
    status = {
      ...status,
      loading: false,
      loaded: false,
      loadingError: action.error,
    };
  } else {
    // case LOAD
    status = {
      ...status,
      loading: true,
      loadingError: undefined,
    };
  }
  return status;
}

export default function entitiesStatusReducer(
  immutableState = initialState,
  action,
) {
  let state = Object.assign({}, immutableState);

  if (has(action, 'fetchStatus.entities')) {
    const entities = action.fetchStatus.entities;
    const status = getStatus(action);
    Object.entries(entities).map(([entityName, entityByKey]) => {
      Object.keys(entityByKey).map((entityKey) => {
        state = {
          ...state,
          [entityName]: {
            ...state[entityName],
            [entityKey]: Object.assign({}, entityByKey[entityKey], status),
          },
        };
      });
    });
  }

  return state;
}
