import React, { memo } from 'react';
import { ViewStyle } from 'react-native';

import { Link, StyleSheet, Colors, View, Text } from '@dkrm/ui-kit-basic';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import cStyles from '@dkrm/general-libs/theme/styles';

const K_ICON_SIZE = 60;

interface USPTileProps {
  iconUri: string;
  title: string;
  linkUrl: string;
  style?: ViewStyle;
}

const USPTile: React.FC<USPTileProps> = memo(
  ({ iconUri, title, linkUrl, style }) => (
    <View style={[s.container, style]}>
      <Link
        style={{ ...cStyles.flex1, ...cStyles.height100 }}
        to={linkUrl}
        showUnderlineOnHover={false}
      >
        <View style={s.innerContainer}>
          <SmartImage
            source={iconUri}
            width={K_ICON_SIZE}
            height={K_ICON_SIZE}
            resizeMode="contain"
          />
          <Text theme="h5" style={s.title}>
            {title}
          </Text>
        </View>
      </Link>
    </View>
  ),
);

const s = StyleSheet.create({
  container: {
    ...cStyles.pas,
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.flex1,
    ...cStyles.height100,
    borderColor: Colors.C_BLACK_20,
  },
  innerContainer: {
    ...cStyles.flexMiddle,
  },
  title: {
    ...cStyles.tac,
  },
});

export default USPTile;
