import React, { memo } from 'react';

import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';
import cStyles from '@dkrm/general-libs/theme/styles';
import { Link, View } from '@dkrm/ui-kit-basic';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import {
  K_LOGO_APPLE_STORE,
  K_LOGO_GOOGLE_PLAY,
} from 'constants/nativeAppConstants';

import { FooterSectionTitle } from '../FooterSection/FooterSectionTitle';
import { K_HEIGHT_APP_ICON, K_WIDTH_APP_ICON } from '../constants';

const K_APP_DOWNLOAD_LINKS = [
  {
    iconUri: K_LOGO_GOOGLE_PLAY,
    to: 'https://play.google.com/store/apps/details?id=com.lokinative&hl=en',
  },
  {
    iconUri: K_LOGO_APPLE_STORE,
    to: 'https://apps.apple.com/id/app/dekoruma-furnitur-dekorasi/id1294213400',
  },
];

const AppDownloadSection: React.FC = memo(() => {
  if (isWebView()) return null;
  return (
    <>
      <FooterSectionTitle title="Download Aplikasi Kami" />
      <View style={cStyles.flexDirRow}>
        {K_APP_DOWNLOAD_LINKS.map(({ iconUri, to }, idx) => (
          <Link key={idx} to={to} isUsingAnchor>
            <SmartImage
              source={iconUri}
              width={K_WIDTH_APP_ICON}
              height={K_HEIGHT_APP_ICON}
              style={{
                marginLeft: idx && 12,
              }}
              additionalQuery={{
                fit: 'bounds',
              }}
            />
          </Link>
        ))}
      </View>
    </>
  );
});

export default AppDownloadSection;
