import { getOfferDiscountAmount } from 'app-libs/etc/productHelper';

export const K_SHOULD_USE_REBATE = false; // (basket.site !== K_DOMAIN + K_ROUTE_BUSINESS)

export const BULK_ITEM_TIERS = [
  { MIN_NUMBER_OF_VARIANT: 1, REBATE_PERCENTAGE: 0 },
  { MIN_NUMBER_OF_VARIANT: 2, REBATE_PERCENTAGE: 5 },
  { MIN_NUMBER_OF_VARIANT: 3, REBATE_PERCENTAGE: 12.5 },
  { MIN_NUMBER_OF_VARIANT: 4, REBATE_PERCENTAGE: 25 },
];

export const REBATE_ADJUSTMENT = [
  { MIN_QUANTITY: 1, ADJUSTMENT: 100 },
  { MIN_QUANTITY: 2, ADJUSTMENT: 81 },
  { MIN_QUANTITY: 3, ADJUSTMENT: 71 },
  { MIN_QUANTITY: 4, ADJUSTMENT: 68 },
  { MIN_QUANTITY: 5, ADJUSTMENT: 66 },
  { MIN_QUANTITY: 6, ADJUSTMENT: 65 },
  { MIN_QUANTITY: 7, ADJUSTMENT: 65 },
  { MIN_QUANTITY: 8, ADJUSTMENT: 63 },
  { MIN_QUANTITY: 9, ADJUSTMENT: 62 },
  { MIN_QUANTITY: 10, ADJUSTMENT: 64 },
];

export function getBulkItemTier(totalNumberOfVariant) {
  let minDelta = null;
  let result = null;
  for (let i = 0; i < BULK_ITEM_TIERS.length; i += 1) {
    const delta =
      totalNumberOfVariant - BULK_ITEM_TIERS[i].MIN_NUMBER_OF_VARIANT;
    if (delta >= 0) {
      if (minDelta === null || delta < minDelta) {
        minDelta = delta;
        result = BULK_ITEM_TIERS[i];
      }
    }
  }
  return result;
}

export function getRebateAdjustmentTier(quantity) {
  let minDelta = null;
  let result = REBATE_ADJUSTMENT[0];
  for (let i = 0; i < REBATE_ADJUSTMENT.length; i += 1) {
    const delta = quantity - REBATE_ADJUSTMENT[i].MIN_QUANTITY;
    if (delta >= 0) {
      if (minDelta === null || delta < minDelta) {
        minDelta = delta;
        result = REBATE_ADJUSTMENT[i];
      }
    }
  }
  return result;
}

export function getRebatePercentage(totalNumberOfVariant) {
  // quick fix, to disable multibuy as per Sep 4, 2017
  if (!K_SHOULD_USE_REBATE) return 0;

  const bulkItemTier = getBulkItemTier(totalNumberOfVariant);
  let rebatePercentage = 0;
  if (bulkItemTier !== null) {
    rebatePercentage = bulkItemTier.REBATE_PERCENTAGE;
  }
  // const rebatePercentage = 0 ? (bulkItemTier === null) : bulkItemTier.REBATE_PERCENTAGE;
  return rebatePercentage / 100;
}

export function getQuantityAdjustment(quantity) {
  const rebateAdjustmentTier = getRebateAdjustmentTier(quantity);
  const quantityAdjustment = rebateAdjustmentTier.ADJUSTMENT;
  return quantityAdjustment / 100;
}

/**
 * Calculate display of rebate per item.
 * Lines sorting order matter, because we carry on the rebate to the next item.
 */
export function getRebate(product, basketLines, stockRecord = null) {
  const newBasketLines = [...basketLines];
  const IdToBeFound = stockRecord ? stockRecord.id : product.stockRecordId;
  const isProductInBasket = !!basketLines.find(
    (line) => line.product.stockRecordId === IdToBeFound,
  );
  // we simulate basket content to calculate the rebate of a product
  if (!isProductInBasket) {
    // quick fix, because stockrecord could change based on user selection of stockrecord
    const newProduct = Object.assign({}, product);
    if (stockRecord) {
      newProduct.maxRebate = stockRecord.maxRebate;
      newProduct.stockRecordId = stockRecord.id;
    }
    newBasketLines.push({ product: newProduct, quantity: 1 });
  }

  /**
   * Because we need to loop from start until that product
   * state 0: 0
   * state 1: total rebate state 1 - rebate state 0
   * state 2: total rebate state 2 - rebate state 1
   * ...
   */

  let totalCurrentRebate = 0;
  let totalMargin = 0;
  let rebateBefore = 0;

  const productIdx = newBasketLines.findIndex(
    (line) => line.product.stockRecordId === IdToBeFound,
  );
  for (let i = 0; i <= productIdx; i += 1) {
    rebateBefore += totalCurrentRebate;
    const currentMargin = newBasketLines[i].product.maxRebate; // getting the maxRebate from the basketline.product
    const currentQuantity = newBasketLines[i].quantity;
    const currentPercentage = getRebatePercentage(i + 1);
    const currentQuantityAdjustment = getQuantityAdjustment(currentQuantity);

    totalMargin += currentQuantity * currentMargin * currentQuantityAdjustment;
    totalCurrentRebate = totalMargin * currentPercentage - rebateBefore;
  }
  const totalOfferDiscountAmount =
    getOfferDiscountAmount(newBasketLines[productIdx].product) *
    newBasketLines[productIdx].quantity;

  return Math.floor(totalCurrentRebate) + totalOfferDiscountAmount;
}

/**
 * Calculate what will be shown when 2 or more items simultaneously added to the basket
 * eg. There's already product A & B in the basket. How much the rebate if we add C+D together into the basket?
 *
 * edge case: there's possibility that products already exists in basketLines
 *            such case is not handled (we use assumption that products pushed are always different SKUs)
 */
export function getRebateBoughtTogether(products, basketLines) {
  let rebate = 0;
  const newBasketLines = [...basketLines];
  products.map((product, idx) => {
    if (idx > 0) {
      const prevProduct = products[idx - 1];
      newBasketLines.push({ product: prevProduct, quantity: 1 });
    }
    rebate += getRebate(product, newBasketLines);
    return null;
  });
  return rebate;
}

/**
 * Total rebate of the basket, depending on variation of lines & qty of each line.
 * Lines sorting order does not matter.
 */
export function getTotalRebate(lines) {
  let totalRebate = 0;
  let totalOfferDiscount = 0;

  lines.forEach((line) => {
    totalRebate +=
      line.product.maxRebate *
      line.quantity *
      getQuantityAdjustment(line.quantity);
    totalOfferDiscount += getOfferDiscountAmount(line.product) * line.quantity;

    const { addonProducts } = line;
    addonProducts.forEach((addon) => {
      totalRebate +=
        addon.product.maxRebate *
        line.quantity *
        getQuantityAdjustment(line.quantity);
      totalOfferDiscount +=
        getOfferDiscountAmount(addon.product) * line.quantity;
    });
  });

  const totalNumberOfVariant = lines.length;
  const rebatePercentage = getRebatePercentage(totalNumberOfVariant);
  return Math.floor(totalRebate * rebatePercentage) + totalOfferDiscount;
}
