const production = {
  isProduction: true,
  port: process.env.PORT || 5005,
  apiPort: 3030,
  app: {
    name: 'f1-loki production',
  },
  // f1-venom
  API_URL_1: 'https://feeds.dekoruma.com/api',
  API_URL_GOBLIN: 'https://records.dekoruma.com/api/v2',
  API_URL_GOBLIN_CDN: 'https://records-dekoruma.global.ssl.fastly.net/api/v2',
  VIEW_URL_GOBLIN: 'https://records.dekoruma.com',
  API_URL_MEDIA_CDN: 'https://media.dekoruma.com',

  API_URL_4:
    'https://wp2-dekoruma.global.ssl.fastly.net/rest/v1.1/sites/dekoruma.wordpress.com',
  API_URL_5: 'https://b2b-records-dekoruma.global.ssl.fastly.net',
  // f1-cuckoos
  // API_URL_5: 'http://f1-cuckoos.herokuapp.com', // f1-cuckoos
  API_URL_MADTHINKER: 'https://cash.dekoruma.com/api/v1',
  API_URL_7: 'https://collector-dekoruma.global.ssl.fastly.net/api/v1',
  // f1-collector
  API_URL_DORMAMMU: 'https://attributes.dekoruma.com/api/v1',
  API_URL_THANOS: 'https://assortment.dekoruma.com/api/v1',
  API_URL_GALACTUS: 'https://galactus.dekoruma.com',
  API_URL_KLAW: 'https://campaign.dekoruma.com/api/v1',
  API_URL_SIN: 'https://review.dekoruma.com/api/v1',
  API_KEY_SIN: 'tUrf@-G4n7en6_w3-f4ll-1n_L0v3-w1Th_s0m30n3-W3_c4n-N07_h@V3',
  API_URL_CHITAURI: 'https://dnb.dekoruma.com/api/v1',
  API_KEY_CHITAURI: 'ywbi&nvxph()q^j+!$2^cdh5)qzg8dq6em4@jcxla3g8k#2njw',
  API_URL_ULTRON: 'http://f1-ultron.herokuapp.com/api/v1',
  API_URL_KINGPIN: 'https://idea.dekoruma.com/api/v1',
  API_URL_VULTURE: 'http://172.16.77.106:7000/api/v1',
  API_URL_HELA: 'https://download-dekoruma.global.ssl.fastly.net/api/v1',
  API_URL_SURTUR: 'https://auth.dekoruma.com/api/v1',
  API_URL_SURTUR_V2: 'https://auth.dekoruma.com/api/v2',
  API_URL_MAXIMUS: 'https://maximus.dekoruma.com/api/v1',
  API_URL_MANDARIN: 'https://f1-mandarin.herokuapp.com/api/v1',
  API_URL_EGO: 'https://property.dekoruma.com/api/v1',
  API_URL_BULLSEYE: 'https://us-central1-f1-bullseye.cloudfunctions.net',
  API_URL_RONAN: 'https://activity.dekoruma.com/api/v1',
  API_URL_DOOM: `https://member.dekoruma.com/api/v1`,
  API_URL_TINKERER: `https://tinkerer.dekoruma.com`,
  API_URL_NOTIF: 'https://notif.dekoruma.com/api',
  ALGOLIA_DEFAULT_INDEX_NAME: 'goblin_products3',
  ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME: 'sort_price_lowest',
  ALGOLIA_AREA_INDEX_NAME: 'master_locations',
  ALGOLIA_ORDER_INDEX_NAME: 'master_orders',
  ALGOLIA_MOODBOARD_INDEX_NAME: 'master_moodboards',
  ALGOLIA_HOTSPOT_INDEX_NAME: 'master_hotspots',
  ALGOLIA_BUSINESS_PRODUCT_AGGREGATOR_INDEX_NAME:
    'master_b2b_product_aggregated',
  ALGOLIA_FEED_INDEX_NAME: 'master_feeds',
  ALGOLIA_STOCK_RECORD_INDEX_NAME: 'master_stockrecords',
  ALGOLIA_PHOTO_RECORD_INDEX_NAME: 'master_photorecords',
  ALGOLIA_PRODUCT_OPTION_VALUE_INDEX_NAME: 'master_product_options',
  ALGOLIA_COMPLAIN_INDEX_NAME: 'master_complains',
  ALGOLIA_PROJECT_TEMPLATE_INDEX_NAME: 'master_project_templates',
  ALGOLIA_ALBUM_INDEX_NAME: 'master_albums',
  ALGOLIA_USER_INDEX_NAME: 'master_users',
  ALGOLIA_BLACKMARKET_PRODUCT_INDEX_NAME: 'master_chitauri_products',
  ALGOLIA_ARTICLE_SEARCH_INDEX_NAME: 'wp_searchable_posts',
  ALGOLIA_PROJECT_INDEX_NAME: 'master_projects',
  ALGOLIA_PORTFOLIO_INDEX_NAME: 'master_portfolios',
  ALGOLIA_PORTFOLIO_INDEX_NAME_SORT_BY_STYLE: 'master_portfolios_sort_by_style',
  ALGOLIA_PRODUCT_SUGGESTION_INDEX_NAME: 'query_suggestion_v3',
  ALGOLIA_PRODUCT_DISTINCT_CATEGORY_INDEX_NAME: 'distinct_categories',
  ALGOLIA_SUPPLY_WAREHOUSE_INDEX_NAME: 'supply_warehouse',
  ALGOLIA_REVIEW_INDEX_NAME: 'master_reviews',
  ALGOLIA_UNIT_TYPE_INDEX_NAME: 'master_unit_types',
  ALGOLIA_REAL_ESTATE_INDEX_NAME: 'master_real_estates',
  ALGOLIA_BOOKING_INDEX_NAME: 'master_bookings',
  ALGOLIA_AD_SLOT_INDEX_NAME: 'master_ad_slots',
  ALGOLIA_BANK_INDEX_NAME: 'master_banks',
  ALGOLIA_3D_ASSET_INDEX_NAME: 'master_3d_assets',
  ALGOLIA_3D_ASSET_MOST_DOWNLOADED_INDEX_NAME:
    'master_3d_assets_most_downloaded',
  ALGOLIA_3D_ASSET_RECENTLY_UPDATED_INDEX_NAME:
    'master_3d_assets_recently_updated',
  ALGOLIA_INVESTMENT_INDEX_NAME: 'master_investments',
  ALGOLIA_DESIGNER_INDEX_NAME: 'master_designers',
  ALGOLIA_VOUCHER_INDEX_NAME: 'master_vouchers',
  ALGOLIA_FLASH_SALE_INDEX_NAME: 'master_flash_sales',
  S3_SIGN_URL: 'https://sign.dekoruma.com/api/s3/sign',
  // S3_SIGN_URL: 'https://feeds.dekoruma.com/api/s3/sign',
  S3_BUCKET_PUBLIC_URL: 'https://f1-static.s3-ap-southeast-1.amazonaws.com',
  S3_BUCKET_PRIVATE_URL: 'https://s3-ap-southeast-1.amazonaws.com/f1-static',
  // CDN_S3: '//de19nmb9dniyb.cloudfront.net',
  CDN_SELF: 'https://www.dekoruma.com',
  SOMA_URL: 'https://soma.dekoruma.com',

  BLIBLI_TIKET_ACCOUNT_CENTER_URL:
    'https://account.bliblitiket.com/account/edit',
  BLIBLI_TIKET_REWARDS_HOMEPAGE_URL: 'https://www.bliblitiketrewards.com',
  BLIBLI_TIKET_REWARDS_VOUCHER_DEALS_URL:
    'https://www.bliblitiketrewards.com/member/voucher-deals',
  ENABLE_ULP_FEATURES: false,

  FACEBOOK_APP_ID: 1112371492108485,
  FACEBOOK_SDK_URL:
    'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=1112371492108485',
  // CDN_SELF: 'https://dekoruma.global.ssl.fastly.net'
  // CDN_SELF: 'http://www.dekoruma.com.global.prod.fastly.net'
  // CDN_SELF: '//f1-loki.herokuapp.com.global.prod.fastly.net'
  // CDN_SELF: '//d1vu3w9l3l461d.cloudfront.net' // towards `f1-loki.herokuapp.com`
  VERITRANS_CLIENT_KEY: 'VT-client-ZVxrsqdYeKeULdy6',
  VERITRANS_TOKEN_URL: 'https://api.midtrans.com/v2/token',
  ALGOLIA_APP_NAME: 'D2VY06YP2A',
  ALGOLIA_API_KEY: '5bbda829365ba015eb6fcec10cccdf72',
  FB_CAPI_ACCESS_TOKEN:
    'EAAKbK4BDxo0BAMZA8ZBAddxW8MbROQaSWq9RDlqa8bNQZBBBa06JK4QJikRSfskevRAHctUyPVQvMzdHb7vhVo5THtnIZAUgtwo4HZCJcS2DRiE5CPdiEQiQLLOMnDjvV2RaBtGjWt1GukAFaJC6AQYcLnS6OwXsLcnvS1QpJXpHIU2jgZAIq7SsmoTcDnT7gZD',
  PINTEREST_CAPI_ACCESS_TOKEN:
    'pina_AIA2RFAWAC42MAQAGAAG4CGD6XAULEIBAAAABXOC4BGRAV47BLSQJDXHT7REHDAFTI6CRLNZJB7V2GNSJC2VCWSZDMAHO4QA',
  FIREBASE: {
    apiKey: 'AIzaSyB5RZ-k9Fd8RVCMJRbmOHK_32fwVWhHpIA',
    authDomain: 'f1-bullseye.firebaseapp.com',
    databaseURL: 'https://f1-bullseye.firebaseio.com',
    projectId: 'f1-bullseye',
    storageBucket: 'f1-bullseye.appspot.com',
    messagingSenderId: '588138102088',
    appId: '1:588138102088:web:57eea37640c43756',
  },
  SENTRY_DSN: 'https://79eb625a247046a8893095de327feb7c@sentry.dekoruma.com/4',
  GOOGLE_MAPS_API_URL:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyB5RZ-k9Fd8RVCMJRbmOHK_32fwVWhHpIA&v=3.exp&libraries=geometry,drawing,places',
};

module.exports = production;
