import { momentId } from 'app-libs/etc/dateHelper';

export const getPhase = (state) => state.project.phase;

export const getPhases = (state) => getPhase(state).phases;

export const getPhasesWithProjects = (phases, projects) =>
  phases.map((phase) => ({
    ...phase,
    projects: projects.filter((project) =>
      project.currentPhase ? project.currentPhase.name === phase.name : false,
    ),
  }));

export const getProjectPhaseById = (state, projectPhaseId) => {
  const phase = state.entities.projectPhases[projectPhaseId];
  return {
    ...phase,
    dateStart: phase.dateStart ? momentId(phase.dateStart) : null,
    dateEnd: phase.dateEnd ? momentId(phase.dateEnd) : null,
  };
};
