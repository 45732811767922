import config from 'config';

export const K_PROMPT_STRING_TRADE_IN = 'Tambahkan Trade In (tukar tambah)';

export const K_TRADE_IN_ADDON_SLUG = 'trade-in';
export const K_TRADE_IN_LINK =
  'https://www.dekoruma.com/artikel/179/bagaimana-prosedur-trade-in';

export const K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_SIMPLE_INSTALLATION =
  'butuh perakitan sederhana';
export const K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_COMPLEX_INSTALLATION =
  'butuh perakitan kompleks';
export const K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NOT_NEED_INSTALLATION =
  'tidak butuh perakitan';

export const K_VALID_PRODUCT_CONDITION_ATTRIBUTE_VALUES = [
  K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_SIMPLE_INSTALLATION,
  K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_COMPLEX_INSTALLATION,
  K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NOT_NEED_INSTALLATION,
];

export const K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_INSTALLATIONS = [
  K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_SIMPLE_INSTALLATION,
  K_PRODUCT_ATTR_PRODUCT_CONDITION_VALUE_NEED_COMPLEX_INSTALLATION,
];

export const K_INSTALLATION_PLACEHOLDER_IMAGE = `${config.API_URL_MEDIA_CDN}/product-detail/jasa-rakit-unchecked.png?auto=webp`;

export const K_NO_INSTALLATION_PROVIDED_IMAGE = `${config.API_URL_MEDIA_CDN}/product-detail/no-installation-provided.png?auto=webp`;
export const K_INSTALLATION_FROM_MERCHANT_IMAGE = `${config.API_URL_MEDIA_CDN}/product-detail/installation-from-merchant.png?auto=webp`;
export const K_INSTALLATION_FROM_ADDON_IMAGE = `${config.API_URL_MEDIA_CDN}/product-detail/installation-from-addon.png?auto=webp`;
