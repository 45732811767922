import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  sectionTitle: {
    ...tStyles.h4,
    color: colors.C_BLACK_100,
  },
  modalTitle: {
    ...tStyles.h4,
    color: colors.C_BLACK_100,
  },
  label: {
    ...tStyles.body2,
    color: colors.C_BLACK_60,
  },
  labelSmall: {
    ...tStyles.body4,
    color: colors.C_BLACK_60,
  },
  content: {
    ...tStyles.body2,
    color: colors.C_BLACK_90,
  },
  checkBoxTitle: {
    ...tStyles.body3,
    ...cStyles.fwn,
    color: colors.C_BLACK_60,
  },
  checkBoxText: {
    ...tStyles.h3,
    color: colors.C_BLACK_100,
  },
  link: {
    color: colors.C_SECONDARY_TOSCA_4,
  },
  linkSmall: {
    ...tStyles.body4,
    color: colors.C_SECONDARY_TOSCA_4,
  },
  productShipmentInfo: {
    ...tStyles.body4,
    color: colors.C_TERTIARY_GREEN_2,
  },
  mutedDetails: {
    ...tStyles.body3,
    color: colors.C_BLACK_60,
  },
  separator: {
    borderTopColor: colors.C_BLACK_25,
  },
  wideSeparator: {
    ...cStyles.mtxl,
    borderTopColor: colors.C_BLACK_5,
    borderColor: colors.C_BLACK_5,
    borderWidth: 12,
  },
  seeAll: {
    backgroundColor: colors.C_SECONDARY_TOSCA_1,
    ...cStyles.pvm,
    ...cStyles.flex1,
    ...cStyles.flexMiddle,
    ...cStyles.brs,
  },
});
