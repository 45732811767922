import superagent from 'superagent';

import config from 'config';

import {
  BasketShippingAddressPayload,
  BulkUpdateBasketLineShippingTierByPartnerPayload,
  BulkUpdateBasketLineShippingTierByPartnerResponse,
  LoadShippingMethodPayload,
  LoadShippingMethodResponse,
  ShippingChoicesPayload,
  ShippingChoicesResponse,
} from './types';

export const K_QUERY_KEY_PREFIX_LOAD_SHIPPING_METHOD = 'shipping-method';
export const K_QUERY_KEY_PREFIX_LOAD_SHIPPING_CHOICE = 'shipping-choices';

export const K_QUERY_KEY_PREFIX_LOAD_BASKET_ALTERNATIVE_STOCK_INFO =
  'basket-alternative-stock-info';

export const getLoadShippingChoicesQueryKey = (basketId: number) => [
  K_QUERY_KEY_PREFIX_LOAD_SHIPPING_CHOICE,
  basketId,
];

export const getLoadShippingMethodQueryKey = (basketId: number) => [
  K_QUERY_KEY_PREFIX_LOAD_SHIPPING_METHOD,
  basketId,
];

export const getLoadBasketAlternativeStockInfoQueryKey = (
  basketId: number,
  routingZoneCode: string,
) => [
  K_QUERY_KEY_PREFIX_LOAD_BASKET_ALTERNATIVE_STOCK_INFO,
  basketId,
  routingZoneCode,
];

export const bulkUpdateBasketLineShippingTier = async (
  payload: BulkUpdateBasketLineShippingTierByPartnerPayload[],
): Promise<BulkUpdateBasketLineShippingTierByPartnerResponse> => {
  const res = await superagent
    .put(`${config.API_URL_GOBLIN}/basket/lines/bulk-update-shipping-tier/`)
    .send(payload)
    .withCredentials();

  return res.body;
};

/**
 * @todo(Varian): change loadBasketShippingChoices and loadBasketShippingMethod to use GET. Deadline: May 2023
 * Reason: The API itself is declarative and should be implemented as GET, but the BE choose to implement it as POST
 * because it must receive a parameter called `shippingAddress` which is an object via request body.
 * Must change the BE implementation first, because the only payload needed are city, district and state, which can be
 * put inside query params and therefore can use GET as the method.
 */

export const loadBasketShippingChoices = async (
  payload: ShippingChoicesPayload,
): Promise<ShippingChoicesResponse> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/basket-v2/shipping-choices/`)
    .send({ shippingAddress: payload })
    .withCredentials();

  return res.body;
};

export const loadBasketShippingMethod = async (
  payload: LoadShippingMethodPayload,
): Promise<LoadShippingMethodResponse> => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/basket-v2/shipping-methods/`)
    .send({ shippingAddress: payload.shippingAddress })
    .withCredentials();

  return res.body;
};

export const updateOrCreateBasketShippingAddress = async (
  address: BasketShippingAddressPayload,
) => {
  const res = await superagent
    .put(`${config.API_URL_GOBLIN}/basket/basket-shipping-addresses/`)
    .send(address)
    .withCredentials();

  if (res.error === false) return res.body;
  throw new Error('Terjadi Kesalahan');
};

export const loadBasketAlternativeStockInfo = async () => {
  const res = await superagent
    .get(`${config.API_URL_GOBLIN}/basket-v2/alternative-stock-info/`)
    .withCredentials();

  return res.body;
};

export const refreshBasketStockAvailable = async () => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/basket-v2/refresh-stock-available/`)
    .withCredentials();

  return res.body;
};

export const blacklistGWP = async (basketId: number) => {
  const res = await superagent
    .post(`${config.API_URL_GOBLIN}/baskets-v2/${basketId}/blacklist-gwp/`)
    .withCredentials();

  return res.body;
};

export const unblacklistGWP = async (basketId: number) => {
  const res = await superagent
    .del(`${config.API_URL_GOBLIN}/baskets-v2/${basketId}/blacklist-gwp/`)
    .withCredentials();

  return res.body;
};
