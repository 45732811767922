/* eslint-disable camelcase, @typescript-eslint/camelcase */

import config from 'config';
import { K_TRADE_IN_ADDON_SLUG } from 'constants/addonConstants';

export const K_MATTRESS_CATEGORY_BREADCRUMB = 'Furniture > Kasur';

export const K_BEDFRAME_ADDON_SLUG = 'bedframe';
export const K_HEADBOARD_ADDON_SLUG = 'sandaran';
export const K_DIVAN_ADDON_SLUG = 'divan';
export const K_SLIDING_MATTRESS_ADDON_SLUG = 'kasur-sorong';

export const K_CODE_ATTR_FIRMNESS_MATTRESS = 'a_firmness_matras';
export const K_CODE_ATTR_SIZE_MATTRESS = 'a_lebar_matras';
export const K_CODE_ATTR_SPECIAL_FEATURE_MATTRESS = 'a_special_feature';
export const K_CODE_ATTR_TYPE_MATTRESS = 'a_tipe_matras';
export const K_CODE_ATTR_CATEGORY_MATTRESS = 'a_kategori_matras';
export const K_CODE_ATTR_MATERIAL_MATTRESS = 'a_bahan_matras';

export const K_PROMPT_STRING_SLIDING_MATTRESS_MWEB =
  'Tambahkan kasur sorong (kasur bawah)';
export const K_YES_STATEMENT_SLIDING_MATTRESS_MWEB = 'Cuma perlu tambah';

export const K_PROMP_STRING_SLIDING_MATTRESS =
  'Tambahkan kasur sorong (kasur bawah) pada pesanan anda?';
export const K_YES_STATEMENT_SLIDING_MATTRESS = 'Ya, tambahkan kasur sorong';

export const K_YES_STATEMENT_FREE_RECYCLE_MATTRESS =
  'Saya ingin dekoruma pickup kasur saya gratis tanpa biaya';

export const K_INFO_MESSAGE_FOR_3IN1 =
  'Harga tertera di awal hanya untuk Kasur. Silahkan pilih tambahan dipan, sandaran, dan kasur sorong bila diperlukan';
export const K_INFO_MESSAGE =
  'Harga tertera di awal hanya untuk Kasur. Silahkan pilih tambahan dipan dan sandaran bila diperlukan';

export const K_MATTRESS_FILTER_BANNER_MWEB_IMAGE_URL = `${config.API_URL_MEDIA_CDN}/homepage/mattress/banner_mattress_4x.png?auto=webp`;
export const K_MATTRESS_FILTER_BANNER_MWEB_WIDTH_PROPORTION = 25;
export const K_MATTRESS_FILTER_BANNER_MWEB_HEIGHT_PROPORTION = 8;

export const K_FACET_TYPE = {
  DISJUNCTIVE: 'DISJUNCTIVE',
  NUMERICAL: 'NUMERICAL',
};

export const K_AVAILABLE_PROMO_TYPES_FACET = 'availablePromoTypes';
export const K_READY_STOCK_FACET = 'availability.numAvailable';

export const K_QUICK_FILTER_FACETS = [
  {
    facetName: K_AVAILABLE_PROMO_TYPES_FACET,
    facetType: K_FACET_TYPE.DISJUNCTIVE,
  },
  {
    facetName: K_READY_STOCK_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Ready Stock',
    value: { '>=': 1 },
  },
];

export const K_ADDON_GROUPS_BY_ADDON_SLUG = [
  {
    value: 0,
    label: 'Dipan dan Sandaran',
    addonSlugs: [
      K_TRADE_IN_ADDON_SLUG,
      K_SLIDING_MATTRESS_ADDON_SLUG,
      K_DIVAN_ADDON_SLUG,
      K_HEADBOARD_ADDON_SLUG,
    ],
    description: 'Anda dapat memilih dipan dan sandaran secara terpisah.',
  },
  {
    value: 1,
    label: 'Bedframe',
    addonSlugs: [
      K_TRADE_IN_ADDON_SLUG,
      K_SLIDING_MATTRESS_ADDON_SLUG,
      K_BEDFRAME_ADDON_SLUG,
    ],
    description: 'Bedframe merupakan dipan dan sandaran yang tergabung.',
  },
];

export const K_MATTRESS_CUSTOMER_LIVE_IN = {
  name: 'Tempat Tinggal',
  optionValues: [
    {
      id: 1,
      value: 'Apartemen',
    },
    {
      id: 2,
      value: 'Rumah',
    },
    {
      name: 3,
      types: 'Lainnya',
    },
  ],
};

export const K_MATTRESS_FIRMNESS_INFO_BY_NAME = {
  Soft: {
    title: 'SOFT',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/firm/6-soft.png?auto=webp`,
    description:
      'Kasur ini empuk dan terasa lebih seimbang dan memiliki penopang yang cukup baik.',
    altDescriptions: [
      'Empuk namun tetap terasa seimbang.',
      'Memiliki penopang yang cukup baik.',
    ],
    detailUrl: 'https://www.dekoruma.com/artikel/14359/tingkat-keempukan-kasur',
  },
  'Medium Soft': {
    title: 'MEDIUM SOFT',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/firm/5-medium-soft.png?auto=webp`,
    description:
      'Kasur ini sedikit empuk namun tetap seimbang. Kasur ini tidak terlalu membenam ketika kita berbaring',
    altDescriptions: [
      'Sedikit empuk dan lebih seimbang.',
      'Tubuh tidak terlalu tenggelam ketika berbaring.',
    ],
    detailUrl: 'https://www.dekoruma.com/artikel/14359/tingkat-keempukan-kasur',
  },
  Medium: {
    title: 'MEDIUM',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/firm/4-medium.png?auto=webp`,
    description: 'Kasur ini tidak terlalu empuk dan juga tidak terlalu keras',
    altDescriptions: [
      'Tingkat keempukan yang tidak terlalu keras namun tidak terlalu lembut.',
    ],
    detailUrl: 'https://www.dekoruma.com/artikel/14359/tingkat-keempukan-kasur',
  },
  'Medium Firm': {
    title: 'MEDIUM FIRM',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/firm/3-medium-firm.png?auto=webp`,
    description: 'Kasur ini hanya akan sedikit membenam ketika kamu berbaring',
    altDescriptions: [
      'Termasuk golongan kasur yang keras.',
      'Tubuh hampir tidak tenggelam ketika berbaring.',
    ],
    detailUrl: 'https://www.dekoruma.com/artikel/14359/tingkat-keempukan-kasur',
  },
  Firm: {
    title: 'FIRM',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/firm/2-firm.png?auto=webp`,
    description:
      'Kasur ini keras dan tidak akan mengikuti bentuk tubuh saat digunakan. Kania merekomendasikan kasur ini untuk posisi tidur telentang atau tengkurap',
    altDescriptions: [
      'Keras dan tidak mengikuti bentuk tubuh.',
      'Direkomendasikan untuk yang gemar tidur telentang dan tengkurap.',
    ],
    detailUrl: 'https://www.dekoruma.com/artikel/14359/tingkat-keempukan-kasur',
  },
  'Extra Firm': {
    title: 'EXTRA FIRM',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/firm/1-extra-firm.png?auto=webp`,
    description: 'Kasur ini sangat keras dan hampir tidak terbenam sama sekali',
    altDescriptions: [
      'Paling keras.',
      'Tubuh tidak tenggelam sama sekali saat berbaring karena dorongan yang kuat.',
    ],
    detailUrl: 'https://www.dekoruma.com/artikel/14359/tingkat-keempukan-kasur',
  },
};

export const K_ATTR_MATTRESS_MATERIAL_KEYS = [
  'memory_foam',
  'pillow_top',
  'plush_top',
  'latex',
  'pocketed_coil',
  'coil',
  'foam',
  'adjustable_base',
];

/* eslint-disable camelcase */
export const K_MATTRESS_MATERIAL_INFO_BY_ATTR_MATTRESS_MATERIAL_KEY = {
  memory_foam: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-1.png?auto=webp`,
    title: 'Memory Foam',
    description:
      'Bahannya yang mengikuti bentuk tubuh pemakai memberi kamu pengalaman tidur dalam pelukan yang besar!',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  pillow_top: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-2.png?auto=webp`,
    title: 'Pillow Top',
    description:
      'Lapisan teratas pada permukaan kasur menawarkan kelembutan ekstra. Rasanya seperti tidur di atas awan.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  plush_top: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-3.png?auto=webp`,
    title: 'Plush Top',
    description:
      'Pilihan favorit untuk mereka yang senang tidur dengan posisi menyamping atau telentang. Bantalan ekstranya mendukung pundak dan pinggul dengan baik.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  latex: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-4.png?auto=webp`,
    title: 'Latex',
    description:
      'Pilihan ideal untuk kamu yang mengincar kesejukan dan kelenturan dari sebuah kasur. Waktu tidur pun jadi lebih nyaman.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  pocketed_coil: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-5.png?auto=webp`,
    title: 'Pocket Coil',
    description:
      'Kumpulan single per yang dilapis dan disusun membentuk honey comb atau Sarang lebah. Waktu tidur pun tidak merasa terganggu oleh partner yang suka berguling kesana kemari.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  coil: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-6.png?auto=webp`,
    title: 'Coil',
    description:
      'Dengan per yang menjadi penopang berat badan, kamu akan mendapatkan membal ekstra yang dapat bertahan lama.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  foam: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-7.png?auto=webp`,
    title: 'Foam',
    description:
      'Empuknya kasur yang memberikan kenyamanan untuk tidur kamu. Saat berbaring di kasur rasanya seperti di negeri impian.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
  adjustable_base: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/for-card/card-8.png?auto=webp`,
    title: 'Adjustable Base',
    description:
      'Adjustable Base terkenal akan dukungan tubuh yang baik, dan membentuk tubuh pemakai.',
    detailUrl: 'https://www.dekoruma.com/artikel/13506/material-kasur',
  },
};
/* eslint-enable camelcase */

export const K_MATTRESS_MATERIAL_INFO_BY_VALUE = {
  Foam: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/foam.png?auto=webp`,
    title: 'BUSA / FOAM',
    altDescriptions: [
      'Kasur empuk yang nyaman saat tidur.',
      'Alas yang aman bagi anak-anak mereka jika mereka terjatuh dari ranjang.',
    ],
  },
  Spring: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/coil.png?auto=webp`,
    title: 'SPRING BED',
    altDescriptions: [
      'Per yang menjadi penopang berat badan memberikan membal ekstra yang membuat kasur tahan lama.',
    ],
  },
  'Pocket Spring': {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/pocket-coil.png?auto=webp`,
    title: 'POCKET SPRING BED',
    altDescriptions: [
      'Mencegah terjadinya kasus per keluar dari kasur.',
      'Pergerakan di satu sisi tidak akan berimbas ke sisi lainnya.',
    ],
  },
  Latex: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/latex.png?auto=webp`,
    title: 'LATEX',
    altDescriptions: [
      'Anti tungau dan tidak menyebabkan alergi.',
      'Sesuai untuk yang mencari kelenturan dan kesejukan dalam sebuah kasur.',
    ],
  },
  'Memory Foam': {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/memory-foam.png?auto=webp`,
    title: 'MEMORY FOAM',
    altDescriptions: [
      'Menahan beban tubuh secara merata.',
      'Pilihan ideal bagi mereka yang memiliki masalah tulang belakang.',
    ],
  },
  'Plush Top': {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/plush-top.png?auto=webp`,
    title: 'PLUSH TOP',
    altDescriptions: [
      'Menopang pundak dan pinggul dengan baik.',
      'Cocol bagi yang gemar tidur menyamping dan telentang.',
    ],
  },
  'Pillow Top': {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/pillow-top.png?auto=webp`,
    title: 'PILLOW TOP',
    altDescriptions: [
      'Lapisan teratas kasur memberikan kelembutan ekstra.',
      'Memberikan sensasi membal ekstra.',
    ],
  },
  Hybrid: {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/material/hybrid.png?auto=webp`,
    title: 'HYBRID',
    altDescriptions: [
      'Mengombinasikan lebih dari satu material untuk tingkat keempukan sempurna.',
    ],
  },
};

export const K_MATTRESS_SIZE_INFO_BY_VALUE = {
  '90': {
    title: 'SINGLE 90x200 cm',
    altDescriptions: [
      'Sesuai untuk pemakaian 1 anak atau dewasa.',
      'Solusi menghemat ruangan di kamar yang kecil.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/90x200.png?auto=webp`,
  },
  '100': {
    title: 'SINGLE 100x200 cm',
    altDescriptions: [
      'Sesuai untuk pemakaian 1 anak atau dewasa yang membutuhkan ruang gerak saat tidur.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/100x200.png?auto=webp`,
  },
  '120': {
    title: 'SUPER SINGLE 120x200 cm',
    altDescriptions: [
      'Sesuai untuk 1 orang yang memiliki ukuran tubuh agak besar atau yang sering bergerak saat tidur.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/120x200.png?auto=webp`,
  },
  '140': {
    title: 'SUPER SINGLE 140x200 cm',
    altDescriptions: [
      'Sesuai untuk pemakaian 1 orang yang menginginkan ruang tidur luas.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/140x200.png?auto=webp`,
  },
  '160': {
    title: 'QUEEN 160x200 cm',
    altDescriptions: [
      'Sesuai untuk pasangan yang baru menikah.',
      'Membuat kamar terlihat lebih luas.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/160x200.png?auto=webp`,
  },
  '180': {
    title: 'KING 180x200 cm',
    altDescriptions: [
      'Sesuai untuk keluarga yang ingin tidur bertiga dengan anak.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/180x200.png?auto=webp`,
  },
  '200': {
    title: 'EXTRA KING 200x200 cm',
    altDescriptions: [
      'Sesuai untuk keluarga yang memiliki ukuran tubuh besar dan tinggi.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/size/200x200.png?auto=webp`,
  },
};

export const K_MATTRESS_TYPE_INFO_BY_VALUE = {
  Kasur: {
    title: 'KASUR UTAMA',
    altDescriptions: [
      'Kasur utama bisa ditentukan tingkat keempukan dan materialnya sesuai dengan preferensi kamu.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/type/Kasur-Single.png?auto=webp`,
  },
  'Kasur 2 in 1': {
    title: 'KASUR 2 IN 1',
    altDescriptions: [
      'Terdiri dari kasur utama dan kasur sorong.',
      'Cocok bagi saudara yang saling berbagi kamar tidur.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/type/Kasur-Double.png?auto=webp`,
  },
  'Kasur 3 in 1': {
    title: 'KASUR 3 IN 1',
    altDescriptions: [
      'Terdiri dari kasur 2in1 dan kasur portable.',
      'Kasur portable dapat ditumpuk atau disandarkan saat tidak digunakan.',
    ],
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mattress/type/Kasur-3in1.png?auto=webp`,
  },
};

export const MATTRESS_MOBILE_FILTERS = [
  {
    listTitle: 'Jenis Kasur',
    dataByValue: K_MATTRESS_TYPE_INFO_BY_VALUE,
    facetName: 'filteringAttributes.aTipeProduk',
  },
  {
    listTitle: 'Ukuran',
    dataByValue: K_MATTRESS_SIZE_INFO_BY_VALUE,
    facetName: 'filteringAttributes.aLebarMatras',
  },
  {
    listTitle: 'Material',
    dataByValue: K_MATTRESS_MATERIAL_INFO_BY_VALUE,
    facetName: 'filteringAttributes.aMaterial',
  },
  {
    listTitle: 'Tingkat Keempukan',
    dataByValue: K_MATTRESS_FIRMNESS_INFO_BY_NAME,
    facetName: 'filteringAttributes.aFirmnessMatras',
    shouldSortData: true,
  },
];

export const K_MATTRESS_CATEGORY_ALL_CATEGORY = 'Semua Kategori';
export const K_MATTRESS_CATEGORY_SPRING_BED = 'Spring Bed';
export const K_MATTRESS_CATEGORY_KASUR_LATEX = 'Kasur Latex';
export const K_MATTRESS_CATEGORY_KASUR_BUSA = 'Kasur Busa';

export const K_MATTRESS_CATEGORIES = [
  K_MATTRESS_CATEGORY_ALL_CATEGORY,
  K_MATTRESS_CATEGORY_SPRING_BED,
  K_MATTRESS_CATEGORY_KASUR_LATEX,
  K_MATTRESS_CATEGORY_KASUR_BUSA,
];

export const K_MATTRESS_BRANDS = [
  'Sleep Care',
  'Zinus',
  'Comforta',
  'King Koil',
  'Guhdo',
  'Elite',
  'Airland',
  'Florence',
  'Serta',
  'Quantum',
  'In The Box',
  'The Luxe',
  'Dunlopillo',
  'Simmons',
  'Theraspine',
  'Simply Bed',
  'Zees',
  'La Dova',
  'Domi',
  'Luxio',
  'Lady Americana',
  'Tote Bed',
  'Aeroflow',
  'Eazy Sleep',
];

export const K_SHOWN_MATTRESS_UPCS = [
  'NRA-404403',
  'SCE-371222',
  'NRA-386905',
  'NRA-386901',
  'ZFI-453910',
];

export const K_SIGNATURE_MATTRESS_SERIES = [
  'YORU',
  'KENKO',
  'ANKO',
  'TOMO',
  'ZINUS',
];

export const K_LOGO_IMAGE_URI = `${config.API_URL_MEDIA_CDN}/facets/hcategories.lvl1/Sleep Care.png?auto=webp`;

export const K_SIGNATURE_MATTRESS_ADDITIONAL_INFO_BY_SERIES = {
  YORU: {
    title: 'Yoru',
    description: 'Buat yang Ingin Nyaman Tanpa Ribet',
    subDescription:
      'Penggunaan fleksibel: bisa dibolak-balik. Harga ramah, cocok buat mengisi hunian pertama.',
    images: [
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/yoru/yoru-1.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/yoru/yoru-2.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/yoru/yoru-3.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/yoru/yoru-4.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/yoru/yoru-5.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/yoru/yoru-6.jpg?auto=webp`,
    ],
    badges: [
      {
        url: `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/free-pillow.png?auto=webp`,
        width: 70,
        height: 78,
        style: {
          top: 58,
          right: 31,
        },
      },
    ],
  },
  KENKO: {
    title: 'Kenko',
    description: 'Buat yang Ingin Kenyamanan & Harga Ramah',
    subDescription:
      'Menyangga tubuh dengan nyaman dan cocok untuk berbagai bentuk tubuh.',
    images: [
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/kenko/new-kenko-1.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/kenko/new-kenko-2.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/kenko/new-kenko-3.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/kenko/new-kenko-4.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/kenko/new-kenko-5.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/kenko/new-kenko-6.jpg?auto=webp`,
    ],
  },
  ANKO: {
    title: 'Anko',
    description: 'Buat yang Ingin Tidur Seperti di Hotel',
    subDescription:
      'Super nyaman, lembut, dan sejuk, bikin tidur di rumah senyaman di hotel.',
    images: [
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/anko/new-anko-1.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/anko/new-anko-2.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/anko/new-anko-3.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/anko/new-anko-4.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/anko/new-anko-5.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/anko/new-anko-6.jpg?auto=webp`,
    ],
  },
  TOMO: {
    title: 'Tomo',
    description: 'Buat yang Mencari Topangan Lebih',
    subDescription: 'Baik untuk menjaga kesehatan tulang dan mencegah alergi.',
    images: [
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/tomo/new-tomo-1.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/tomo/new-tomo-2.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/tomo/new-tomo-3.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/tomo/new-tomo-4.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/tomo/new-tomo-5.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/homepage/mattress/mattress-card/tomo/new-tomo-6.jpg?auto=webp`,
    ],
  },
  ZINUS: {
    title: 'Zinus Pocket Hybrid',
    description: 'Kasur Zinus Rekomendasi dari Natasha Surya!',
    subDescription:
      'Pocket Spring dan busa Memory Foam memberikan rasa empuk namun tetap seimbang.',
    images: [
      `${config.API_URL_MEDIA_CDN}/assortments/PT. Zinus Furniture Indonesia/ID-MSHAEP-12SK.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/assortments/PT. Zinus Furniture Indonesia/ID-MSHAEP-12SK_1.jpg?auto=webp`,
      `${config.API_URL_MEDIA_CDN}/assortments/PT. Zinus Furniture Indonesia/ID-MSHAEP-12SK_2.jpg?auto=webp`,
    ],
    shouldHideLogo: true,
  },
};
