import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import config from 'config';

import { SearchAdapter } from 'app-libs/AlgoliaAdapter/types';
import { ProductCardProduct } from 'app-libs/components/Product/ProductCard/types';
import {
  generateCustomSortProductFilters,
  generateProductFilters,
  getOptimalRoutingZoneCodeFromProductFilters,
} from 'app-libs/etc/productHelperV2';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';

export const useSetProductFiltersEffect = (
  searchAdapter: SearchAdapter<ProductCardProduct>,
) => {
  /*
   * @note (Radit): filters must be checked for the useEffect dependencies
   * reason: searchAdapter cannot detect if the filters is changed or not, and can leads to stale data.
   */

  const filters = searchAdapter.getState()?.filters ?? '';
  const routingZoneCode: string = useSelector(
    getCurrentLocationRoutingZoneCode,
  );
  const { index } = searchAdapter.getState();

  const newFilters =
    index === config.ALGOLIA_DEFAULT_INDEX_NAME
      ? generateProductFilters(routingZoneCode)
      : generateCustomSortProductFilters(routingZoneCode);

  useEffect(() => {
    const currentOptimalRoutingZoneCode =
      getOptimalRoutingZoneCodeFromProductFilters(filters);
    const isRoutingZoneNotOptimal =
      currentOptimalRoutingZoneCode !== routingZoneCode;

    const isFiltersDifferent = filters !== newFilters;
    const shouldUpdateFilters = isRoutingZoneNotOptimal || isFiltersDifferent;

    if (shouldUpdateFilters) {
      const newState = searchAdapter.getState();
      newState.filters = newFilters;
      searchAdapter.setState(newState);
      searchAdapter.search();
    }
  }, [routingZoneCode, searchAdapter, filters, index, newFilters]);
};
