import basedayjs from 'dayjs';
import localeId from 'dayjs/locale/id';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import minMaxPlugin from 'dayjs/plugin/minMax';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';

basedayjs.locale({
  ...localeId,
  weekStart: 1,
});
basedayjs.extend(customParseFormatPlugin);
basedayjs.extend(durationPlugin);
basedayjs.extend(isSameOrAfterPlugin);
basedayjs.extend(isSameOrBeforePlugin);
basedayjs.extend(minMaxPlugin);
basedayjs.extend(relativeTimePlugin);
basedayjs.extend(localizedFormatPlugin);
basedayjs.extend(advancedFormatPlugin);
basedayjs.extend(utc);
basedayjs.extend(timezone);
basedayjs.extend(weekday);
basedayjs.tz.setDefault('Asia/Jakarta');

export const dayjs = basedayjs;
