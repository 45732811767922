import PropTypes from 'prop-types';
import React from 'react';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, View } from '@dkrm/ui-kit-basic';

import styles from './styles';

const PageSection = ({ title, children, containerStyle }) => (
  <View style={[styles.container, containerStyle]}>
    {!!title && (
      <Text theme="h4" style={styles.headerText}>
        {title}
      </Text>
    )}
    {children}
  </View>
);

PageSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  containerStyle: stylePropTypes,
};

PageSection.defaultProps = {
  title: '',
  children: null,
  containerStyle: {},
};

export default PageSection;
