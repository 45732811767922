import {
  experimentsReducer as reducer,
  createExperiments,
} from 'app-libs/libs/TwoFace';
import experiments from './experiments';
import { getDeviceUniqueId } from 'app-libs/session/AnonymousUser';

const deviceId = getDeviceUniqueId();

export default function experimentsReducer(mutableState = {}, action = {}) {
  let state = mutableState;
  if (!state.hasOwnProperty('identifier') && deviceId) {
    state = createExperiments(experiments, deviceId);
  }
  return reducer(state, action);
}
