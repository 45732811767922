import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';

const NavbarCheckout = ({
  title,
  defaultTitle,
  children,
  leftButtonAction,
  rightItems,
  location,
}) => (
  <React.Fragment>
    <NavbarTitleCentered
      title={title || get(location, 'state.title', defaultTitle)}
      leftButtonAction={leftButtonAction}
      rightItems={rightItems}
    />
    {children}
  </React.Fragment>
);

NavbarCheckout.propTypes = {
  title: PropTypes.string,
  defaultTitle: PropTypes.string,
  children: PropTypes.node,
  leftButtonAction: PropTypes.func,
  rightItems: PropTypes.arrayOf(PropTypes.element),
  location: PropTypes.shape().isRequired,
};

NavbarCheckout.defaultProps = {
  title: '',
  defaultTitle: '',
  children: null,
  leftButtonAction: null,
  rightItems: [],
};

export default withRouter(NavbarCheckout);
