import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { GatewayDest, GatewayProvider } from 'react-gateway';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RootStateOrAny, useSelector } from 'react-redux';
import { FCFetchData } from 'types';

import { isWebView } from '@dkrm/general-libs/Utils/userAgentDetector';
import { Colors, View } from '@dkrm/ui-kit-basic';
import { ModalPortal } from '@dkrm/ui-kit-basic/v2';
import { StyleSheet } from '@dkrm/ui-kit-v2';

import { withError } from 'app-libs/components/ErrorBoundary';
import ModalGeneric from 'app-libs/components/ModalGeneric';
import { SnackBar, Toast } from 'app-libs/components/Notification';
import ErrorPrompt from 'app-libs/components/Notification/ErrorPrompt';
import withPushNotification from 'app-libs/components/_abstractComponents/notification/withPushNotification';
import { DrawerLockContextProvider } from 'app-libs/contexts/DrawerLockContext';
import { RouteContextProvider } from 'app-libs/contexts/RouteContext';
import { isDnBPage } from 'app-libs/etc/routeHelper';
import {
  getCurrentVersionHashFromCloudFunction,
  getCurrentVersionHashFromLocalStorage,
  setCurrentVersionHashToLocalStorage,
} from 'app-libs/etc/versionHelper';
import { useForceUpdate } from 'app-libs/hook_modules/app';
import { useLoadAuthEffect } from 'app-libs/hook_modules/auth';
import { useLocation } from 'app-libs/hook_modules/router';
import { loadAdvertisementsV2 } from 'app-libs/redux_modules/entity_modules/advertisement';
import { loadCustomScoreConfigurations } from 'app-libs/redux_modules/entity_modules/product';
import { getIsCustomScoreConfigurationsLoaded } from 'app-libs/redux_modules/entity_modules/selectors/product';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import { isAdvertisementBannerLoaded } from 'app-libs/redux_modules/selectors/advertisement';
import { getAuthUser } from 'app-libs/redux_modules/selectors/auth';
import { trackIdentifyUser } from 'app-libs/trackers';

import DrawerContainer from 'components/DrawerContainer/Loadable';
import NProgressBar from 'components/NProgressBar';
import UNMLoginForceUpdateApp from 'components/UNMLoginForceUpdateApp';
import {
  OrganizationKnowledgeGraph,
  SiteNameWithSiteLinkSearchBox,
  SiteNavigation,
} from 'components/_abstractComponents/GoogleStructuredData';
import OfflineBasketFloatingEntrypoint from 'components/offlineStore/OfflineBasketFloatingEntrypoint';

import {
  K_DEKORUMA_APP_MIN_APP_VERSION_CONFIG_FOR_UNM_LOGIN,
  K_DEKORUMA_APP_PATHNAMES_SHOULD_FORCE_UPDATE_FOR_UNM_LOGIN,
} from 'constants/nativeAppConstants';

import DefaultAppMeta from './DefaultAppMeta';
import { useSetShopbackDataEffect } from './utils';

interface AppContainerProps {
  children?: React.ReactNode;
}

const K_NOOP = () => null;

const AppContainer: FCFetchData<AppContainerProps> = memo(({ children }) => {
  const location = useLocation();
  const dimension = useSelector((state: RootStateOrAny) => state.dimension);
  const user = useSelector(getAuthUser);

  const {
    shouldShowForceUpdateModal: shouldShowForceUpdateModalForUNMLoginFeature,
    forceUpdateAppVersionIfNecessary:
      forceUpdateAppVersionForUNMLoginFeatureIfNecessary,
  } = useForceUpdate(
    K_DEKORUMA_APP_MIN_APP_VERSION_CONFIG_FOR_UNM_LOGIN,
    K_DEKORUMA_APP_PATHNAMES_SHOULD_FORCE_UPDATE_FOR_UNM_LOGIN,
  );

  const handleOnFocus = useCallback(async () => {
    const localStorageVersionHash = getCurrentVersionHashFromLocalStorage();
    const currentVersionHash = await getCurrentVersionHashFromCloudFunction();
    if (
      localStorageVersionHash &&
      currentVersionHash &&
      localStorageVersionHash !== currentVersionHash
    ) {
      setCurrentVersionHashToLocalStorage(currentVersionHash);
      // eslint-disable-next-line
      // @ts-ignore params forceReload=true
      // to forcefully reload from the server (does not store data cached by browser)
      window.location?.reload(true);
    }

    if (!localStorageVersionHash && currentVersionHash) {
      setCurrentVersionHashToLocalStorage(currentVersionHash);
    }

    // @note(dika) 12 Mar 2025: To run check app version when app state back to active
    // this will be used when user keep their Dekoruma App open all time
    // force update will be triggered the next time app is on focus
    forceUpdateAppVersionForUNMLoginFeatureIfNecessary();
  }, [forceUpdateAppVersionForUNMLoginFeatureIfNecessary]);

  const memoizedChildrenContainerStyle = useMemo(
    () => ({
      height: dimension.height || '100vh',
      display: 'flex',
      flexDirection: 'column',
    }),
    [dimension.height],
  );

  useEffect(() => {
    trackIdentifyUser(user);
  }, [user]);

  useEffect(() => {
    if (isWebView()) {
      window.handleAppStateOnActive = handleOnFocus;
      // @note(dika) 12 Mar 2025: To run check app version when opening page first time
      forceUpdateAppVersionForUNMLoginFeatureIfNecessary();
    }
  }, [handleOnFocus, forceUpdateAppVersionForUNMLoginFeatureIfNecessary]);

  useEffect(() => {
    // @note(dika) 12 Nov 2024: disable BFCache
    // Ref: https://web.dev/articles/bfcache
    // As Dekoruma has several repos for its frontends, having bfcache
    // will make old page (opened via back / forward) having old stale redux states
    // and query client data (browser will show last render)
    window.addEventListener('unload', K_NOOP);
    window.addEventListener('beforeunload', K_NOOP);

    return () => {
      window.removeEventListener('unload', K_NOOP);
      window.removeEventListener('beforeunload', K_NOOP);
    };
  }, []);

  useLoadAuthEffect();

  useSetShopbackDataEffect();

  const isHome = location.pathname === '/';
  const ParentComponent = isDnBPage(location.pathname)
    ? React.Fragment
    : DrawerContainer;

  return (
    <>
      <GatewayProvider>
        <RouteContextProvider>
          <DrawerLockContextProvider>
            <ErrorPrompt />
            <DefaultAppMeta />
            <ModalGeneric />
            <View />
            <ParentComponent>
              <ModalPortal>
                <SnackBar />
                <Toast />
              </ModalPortal>
              <NProgressBar color={Colors.C_PRIMARY_TOSCA} />
              <View style={memoizedChildrenContainerStyle}>
                {shouldShowForceUpdateModalForUNMLoginFeature ? (
                  <UNMLoginForceUpdateApp />
                ) : (
                  children
                )}
              </View>
              <GatewayDest
                name="portal"
                component={View}
                style={s.portalContainer}
              />
              {isHome && (
                <>
                  <SiteNavigation />
                  <OrganizationKnowledgeGraph />
                  <SiteNameWithSiteLinkSearchBox />
                </>
              )}
            </ParentComponent>
            <OfflineBasketFloatingEntrypoint />
          </DrawerLockContextProvider>
        </RouteContextProvider>
      </GatewayProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
});

AppContainer.fetchData = async ({ store }) => {
  const state = store.getState();
  const promises = [];
  if (!isAdvertisementBannerLoaded(state)) {
    promises.push(
      store.dispatch(dontShowPromptOnError(loadAdvertisementsV2())),
    );
  }
  if (!getIsCustomScoreConfigurationsLoaded(state)) {
    promises.push(
      store.dispatch(dontShowPromptOnError(loadCustomScoreConfigurations())),
    );
  }
  await Promise.all(promises);

  return {};
};

const s = StyleSheet.create({
  portalContainer: {
    zIndex: 4,
  },
});

export default withError(withPushNotification(AppContainer));
