import flatten from 'lodash/flatten';
import has from 'lodash/has';
import sortBy from 'lodash/sortBy';

import { K_MAIN_ATTRIBUTE_CODES } from 'constants/designBuildConstants';

/**
 * Project Template Search
 */

export const getProjectTemplateSearch = (state) => state.projectTemplateSearch;

export const getProjectTemplateSearchByTitle = (state, title) => {
  const projectTemplateSearch = getProjectTemplateSearch(state);
  return projectTemplateSearch.projectTemplateUidByTitle[title] || {};
};

export const getProjectTemplateSearchResult = (state) => {
  const projectTemplateSearch = getProjectTemplateSearch(state);
  return projectTemplateSearch.searchResults;
};

/**
 * Project Template
 */

/* Project Template Images */

export const getProjectTemplateImages = (projectTemplate) => {
  const projectTemplateImagesWithoutSitemapImage = Object.assign(
    {},
    projectTemplate.images,
  );
  if (has(projectTemplateImagesWithoutSitemapImage, 'Denah')) {
    delete projectTemplateImagesWithoutSitemapImage.Denah;
  }
  const images =
    Object.values(projectTemplateImagesWithoutSitemapImage || {}) || [];
  const flattenImages = flatten(images);
  const sortedImages = sortBy(flattenImages, 'displayOrder');
  return sortedImages;
};

export const getProjectTemplateImageUrls = (projectTemplate) => {
  const projectTemplateImages = getProjectTemplateImages(projectTemplate);
  return projectTemplateImages.map((image) => image.imageUrl);
};

export const getMainProjectTemplateImageUrl = (projectTemplate) => {
  const projectTemplateImageUrls = getProjectTemplateImageUrls(projectTemplate);
  if (projectTemplateImageUrls.length) {
    return projectTemplateImageUrls[0];
  }
};

export const getProjectTemplateMinPrice = (projectTemplate) => {
  if (projectTemplate) {
    return Number.parseInt(projectTemplate.price, 10);
  }
};

export const getProjectTemplateMaxPrice = (projectTemplate) => {
  if (projectTemplate) {
    return Number.parseInt(projectTemplate.maxPrice, 10);
  }
};

/**
 * @return {[{name, code, description}]}
 */
export const getProjectTemplateStyles = (projectTemplate) => {
  if (projectTemplate) {
    return projectTemplate.styles || [];
  }
  return [];
};

export const getProjectTemplateStyleNames = (projectTemplate) => {
  const styles = getProjectTemplateStyles(projectTemplate);
  return styles.map((style) => style.name);
};

export const getProjectTemplateTitle = (projectTemplate) => {
  if (projectTemplate) {
    return projectTemplate.title;
  }
};

export const getProjectTemplateAttributes = (projectTemplate) => {
  if (projectTemplate) {
    return projectTemplate.attributes || [];
  }
  return [];
};

export const getProjectTemplateMainAttributeValue = (projectTemplate) => {
  if (projectTemplate) {
    const attributes = getProjectTemplateAttributes(projectTemplate);
    const mainAttributes = attributes.filter((attribute) =>
      K_MAIN_ATTRIBUTE_CODES.some((code) => code === attribute.code),
    );
    if (mainAttributes.length) {
      return mainAttributes[0].value;
    }
  }
};
