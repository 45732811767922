import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  container: {
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.flex1,
    ...cStyles.pam,
    ...cStyles.phl,
    backgroundColor: colors.C_BLACK_5,
    borderColor: colors.C_BLACK_5,
  },
  title: {
    ...tStyles.h5,
    fontSize: 14,
    color: colors.C_BLACK_100,
  },
  subtitle: {
    ...tStyles.body3,
    fontSize: 14,
    color: colors.C_BLACK_100,
  },
});
