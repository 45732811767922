import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { getSsrData } from 'centarius/client';
import React from 'react';
import { hydrate } from 'react-dom';
import { Dimensions } from 'react-native';
import { QueryClient } from 'react-query';
import { persistStore } from 'redux-persist';

import configureStore from '../app/store/configureStore';
import { SENTRY_DSN } from 'config';

import { setDimension } from 'app-libs/redux_modules/helper_modules/dimension';
import createDummyGlobalVariableThirdParty from 'app-libs/trackers/createDummyGlobalVariableThirdParty';

import App from '../app/index';
import { K_IGNORED_SENTRY_ERROR_MESSAGES } from '../constants';
import './polyfills';
import { register } from './registerServiceWorker';

/**
 * @learn this!
 * all non-code comments should be encased with /**  */

/** DON'T DELETE THESE! These are global variables for runtime codes */
global.__DEVELOPMENT__ = process.env.NODE_ENV !== 'production';
global.__CLIENT__ = true;
global.__SERVER__ = false;
global.__NATIVE__ = false;

/**
 * @learn this!
 * code comments should be encased with //
 * you should also document why you comment it out, with format:
 * @commented out by <your-name>. <reason-why-you-comment-it-out>
 */
/** @commented out by Natan. The following is not needed yet while there's no devtools attached */
// global.__DEVTOOLS__ = false;

/** init Sentry error reporting in browser. Get the dsn in your sentry project */
if (!__DEVELOPMENT__) {
  Sentry.init({
    release: process.env.RAZZLE_VERSION || process.env.SOURCE_VERSION,
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.Integrations.TryCatch({ requestAnimationFrame: false }),
      new Integrations.BrowserTracing(),
    ],
    ignoreErrors: K_IGNORED_SENTRY_ERROR_MESSAGES,
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /cdn\.sematext\.com/i,
    ],
    tracesSampleRate: 0.25,
  });
}

/** mocks third-party global variable such as gtm/fbq */
createDummyGlobalVariableThirdParty();

const K_ONE_HOUR_IN_MILLISECONDS = 3600 * 1000;

const render = (AppWrapper) => {
  /** Setting any options to passed into Centarius here */
  const store = configureStore(window.__PRELOADED_STATE__);

  const dehydratedState = window.__REACT_QUERY_STATE__;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: K_ONE_HOUR_IN_MILLISECONDS,
        cacheTime: K_ONE_HOUR_IN_MILLISECONDS,
      },
    },
  });
  const isomorphicState = window.__ISOMORPHIC_STATE__;
  const uiKitImageIsomorphicState = window.__UI_KIT_IMAGE_ISOMORPHIC_STATE__;

  /* non-blocking persist store */
  persistStore(store);

  delete window.__PRELOADED_STATE__;

  /** Set Dimension for Layout */
  const dimension = Dimensions.get('window');
  store.dispatch(
    setDimension({ width: dimension.width, height: dimension.height }),
  );
  const ssrData = getSsrData();

  hydrate(
    <AppWrapper
      store={store}
      ssrData={ssrData}
      queryClient={queryClient}
      reactQueryDehydratedState={dehydratedState}
      isomorphicState={isomorphicState}
      uiKitImageIsomorphicState={uiKitImageIsomorphicState}
    />,
    document.getElementById('root'),
  );
};

loadableReady(() => render(App));

/** app registration and rendering */

/** enable webpack hot module replacement (HMR) for react */
if (module.hot) {
  module.hot.accept('../app', () => {
    render(App);
  });
}

/** register service worker for offline caching */
register({
  swDest: 'StorefrontMobileApp-sw.js',
});
