export const getEntities = (state) => state.entities;

export const getProductEntities = (state) => {
  const entities = getEntities(state);
  return entities.products;
};

export const getProductOptionEntities = (state) => {
  const entities = getEntities(state);
  return entities.productOptions;
};

export const getProductReviewEntities = (state) => {
  const entities = getEntities(state);
  return entities.productReview;
};

export const getMoodboardEntities = (state) => {
  const entities = getEntities(state);
  return entities.moodboards;
};

export const getOrderEntities = (state) => {
  const entities = getEntities(state);
  return entities.orders;
};
