import { formatPriceId } from 'app-libs/etc';

export function parseProject(proj) {
  if (!proj) return proj;

  let parsedProjectDescription = {};
  try {
    parsedProjectDescription = JSON.parse(proj.description);
  } catch (e) {
    parsedProjectDescription = {};
  }

  return {
    ...proj,
    description: parsedProjectDescription,
  };
}

/**
 * To get price object
 * @param {Number} price
 * @param {Number} installmentTerm - total installment, default = 12
 * @return {String}
 */
export function getInstallmentPriceString(
  price,
  installmentTerm = 36,
  suffix = 'bulan',
) {
  const installmentPrice = formatPriceId(price / installmentTerm);
  return `Cicilan: ${installmentTerm} x ${installmentPrice} / ${suffix}`;
}
