import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';

import { headBasketAndLoadIfExist } from 'app-libs/redux_modules/entity_modules/basket';
import { useSnackBar } from 'app-libs/hook_modules/snackBar';

import { getLoadOfflineBasketListQueryKey } from 'entities/offlineBasket/api';
import { useMutationClearBasketLines } from 'entities/offlineBasket/utils';

import {
  K_ALERT_ERROR,
  showNotificationPrompt,
} from 'app-libs/redux_modules/flow_modules/notification';

import {
  K_POSITION_MIDDLE_CENTER,
  K_DURATION_LONG,
} from 'app-libs/components/Notification/BasePopup/constants';

export const useDeleteBasket = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { showSnackBar } = useSnackBar();

  const onDeleteOfflineBasketSuccess = async () => {
    await queryClient.invalidateQueries(getLoadOfflineBasketListQueryKey());
    dispatch(headBasketAndLoadIfExist());
    showSnackBar({
      message: 'Berhasil mengosongkan data keranjang.',
      position: K_POSITION_MIDDLE_CENTER,
      duration: K_DURATION_LONG,
    });
  };

  const onDeleteOfflineBasketFail = () => {
    dispatch(
      showNotificationPrompt(
        `Gagal mengosongkan data keranjang.`,
        'Clear Basket Error',
        K_ALERT_ERROR,
      ),
    );
  };

  const clearBasketLinesMutation = useMutationClearBasketLines({
    onSuccess: onDeleteOfflineBasketSuccess,
    onError: onDeleteOfflineBasketFail,
  });

  return clearBasketLinesMutation.mutate;
};
