import keyMirror from 'keymirror';
import { normalize } from 'normalizr';
import config from 'config';
import get from 'lodash/get';

import {
  K_MARKETPLACE_PRODUCT,
  K_PRODUCT_CHILDREN_KEYS,
} from 'constants/productConstants';

import { Schemas } from '../entities';

import {
  convertQueryParamsToStateTree as _convertQueryParamsToStateTree,
  convertStateToQueryParams as _convertStateToQueryParams,
  newQueryParamsByAddingDisjunctiveFilter as _newQueryParamsByAddingDisjunctiveFilter,
  newQueryParamsByRemovingDisjunctiveFilter as _newQueryParamsByRemovingDisjunctiveFilter,
  newQueryParamsByClearingSingleDisjunctiveFilter as _newQueryParamsByClearingSingleDisjunctiveFilter,
  newQueryParamsByAddingNumericFilter as _newQueryParamsByAddingNumericFilter,
} from '../helper_modules/search/queryParser';

import { shouldLoad } from '../helper_modules/search/stateComparator';
import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

import { searchQueryParamsChangeReducer as _searchQueryParamsChangeReducer } from '../helper_modules/search/searchQueryParamsChangeReducer';

import {
  fetchSearchResults as _fetchSearchResults,
  browseSearchResults as _browseSearchResults,
  browseAllSearchResults as _browseAllSearchResults,
  browseSearchWithPaginationResults as _browseSearchWithPaginationResults,
  fetchObject,
  fetchObjects,
  ALGOLIA_DEFAULT_INDEX_NAME as _ALGOLIA_DEFAULT_INDEX_NAME,
  ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME as _ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME,
  ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME as _ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME,
  ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME as _ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME,
  ALGOLIA_3D_ASSET_INDEX_NAME as _ALGOLIA_3D_ASSET_INDEX_NAME,
  ALGOLIA_INDEXES_NAME as _ALGOLIA_INDEXES_NAME,
} from '../helper_modules/search/algoliaWrapper';

export const convertQueryParamsToStateTree = _convertQueryParamsToStateTree;
export const convertStateToQueryParams = _convertStateToQueryParams;
export const newQueryParamsByAddingDisjunctiveFilter = _newQueryParamsByAddingDisjunctiveFilter;
export const newQueryParamsByRemovingDisjunctiveFilter = _newQueryParamsByRemovingDisjunctiveFilter;
export const newQueryParamsByClearingSingleDisjunctiveFilter = _newQueryParamsByClearingSingleDisjunctiveFilter;
export const newQueryParamsByAddingNumericFilter = _newQueryParamsByAddingNumericFilter;

export const searchQueryParamsChangeReducer = _searchQueryParamsChangeReducer;

export const fetchSearchResults = _fetchSearchResults;
export const browseSearchResults = _browseSearchResults;
export const browseSearchWithPaginationResults = _browseSearchWithPaginationResults;
export const browseAllSearchResults = _browseAllSearchResults;

export const ALGOLIA_DEFAULT_INDEX_NAME = _ALGOLIA_DEFAULT_INDEX_NAME;
export const ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME = _ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME;
export const ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME = _ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME;
export const ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME = _ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME;
export const ALGOLIA_3D_ASSET_INDEX_NAME = _ALGOLIA_3D_ASSET_INDEX_NAME;
export const ALGOLIA_INDEXES_NAME = _ALGOLIA_INDEXES_NAME;

export const K_DEFAULT_HITS_PER_PAGE = 24;

export const AT = keyMirror({
  SEARCH_QUERY_PARAMS_CHANGE: null,
  SEARCH_DISJUNCTIVE_FACET_CHANGE: null,
  CLEAR_SEARCH: null,

  LOAD_SEARCH: null,
  LOAD_SEARCH_SUCCESS: null,
  LOAD_SEARCH_FAIL: null,

  LOAD_SEARCH_OBJECT: null,
  LOAD_SEARCH_OBJECT_SUCCESS: null,
  LOAD_SEARCH_OBJECT_FAIL: null,

  LOAD_SEARCH_OBJECTS: null,
  LOAD_SEARCH_OBJECTS_SUCCESS: null,
  LOAD_SEARCH_OBJECTS_FAIL: null,

  CALCULATE_PRODUCT_RECOMMENDATION: null,
  CALCULATE_PRODUCT_RECOMMENDATION_SUCCESS: null,
  CALCULATE_PRODUCT_RECOMMENDATION_FAIL: null,

  UPDATE_LAST_VISITED_OFFSET: null,
});

const defaultMaxValuesPerFacet = 200;

export const initialState = {
  /**
   * Concept of nonce is used to ignore incoming successful search request (called by AT.LOAD_SEARCH_SUCCESS).
   * This idea comes from problem that React continuously reupdate search UI on query change causing a lot of lag.
   * We use nonce to eliminate unnecessary component update after search response received.
   * Mark this as AFTER REQUEST optimization.
   * See concept of nonce here: https://en.wikipedia.org/wiki/Cryptographic_nonce
   */
  nonce: 0,
  cnonce: 0,
  loading: false,
  loaded: false,
  error: null,
  searchObjectAsyncState: initialAsyncState,
  // Invalidated means the combination of `searchParameters` and `searchIndex` hasn't produce `searchResults` yet because we haven't fetched the data (either failed or any other reason)
  invalidated: true,
  // Initial state for searchParameters
  // - To see what can be set: https://github.com/algolia/algoliasearch-helper-js#query-parameters
  // - To read more about it: https://www.algolia.com/doc/javascript#query-parameters
  searchParameters: {
    index: ALGOLIA_DEFAULT_INDEX_NAME,
    query: '',
    // Pagination Parameters
    hitsPerPage: K_DEFAULT_HITS_PER_PAGE,
    // page: 0,
    // Faceting Parameters
    maxValuesPerFacet: defaultMaxValuesPerFacet,
    // facets: ['type', 'shipping', 'salePrice'],
    disjunctiveFacets: [
      'brand',
      'color',
      'special',
      'vouchers.code',
      'productOffer.offer.campaign.name',
      'productOffer.discount',
      'filteringAttributes.aPattern',
      'hcategories.lvl0',
      'hcategories.lvl1',
    ], // di override di etc/layoutHelper, getDisjunctiveFacets
    hierarchicalFacets: [
      {
        name: 'hcategories_by_function',
        attributes: [
          'hcategories.lvl0',
          'hcategories.lvl1',
          'hcategories.lvl2',
          'hcategories.lvl3',
        ],
        sortBy: ['count:desc', 'name:asc'],
      },
    ],

    disjunctiveFacetsRefinements: {
      // - means exclude
      // src : https://github.com/algolia/instantsearch.js/issues/1232
      isBlacklisted: ['-true'],
      catalogueGroupNames: [K_MARKETPLACE_PRODUCT],
    },

    // Refinements
    // disjunctiveFacetsRefinements: {},
    // hierarchicalFacetsRefinements: { hcategories_by_function: [ 'Home > Bathroom' ] }
  },

  searchIndex: ALGOLIA_DEFAULT_INDEX_NAME,

  // Return state for searchParameters
  // Refer to https://www.algolia.com/doc/javascript#query-parameters
  searchResults: {
    hits: [],
    disjunctiveFacets: [],
    hierarchicalFacets: [],
    page: 0,
    // nbHits: 0,
    nbPages: 0,
    processingTimeMS: 0,
    // query: '',
    // params: '',
    // hitsPerPage: 20,
  },
};

/**
 * Main reducer function
 *
 * @param {object} state - redux store
 * @param {object} action - with shape of {type:<ActionType>, payload:<...>}
 * @return {object} state - redux store
 */

export default function searchReducer(
  mutableState = initialState,
  action = {},
) {
  let state = { ...mutableState };

  switch (action.type) {
    case AT.SEARCH_QUERY_PARAMS_CHANGE: {
      state = searchQueryParamsChangeReducer(state, initialState, action);
      break;
    }
    case AT.CLEAR_SEARCH: {
      state = { ...initialState };
      break;
    }
    case AT.LOAD_SEARCH: {
      /*
      actionType: LOAD,
      payload:
        searchParameters
        searchIndex
    */
      state = {
        ...state,
        loading: true,
        nonce: action.payload.nonce,
        error: null,
        searchParameters: action.payload.searchParameters,
        searchIndex: action.payload.searchIndex,
      };
      break;
    }
    case AT.LOAD_SEARCH_SUCCESS: {
      /**
       * actionType: LOAD_SUCCESS,
       * result:
       *   state: (obj*)searchParameters // DONT STORE THIS: to prevent race-condition
       *   content: (obj*)searchResults
       */
      const changedState = {
        loading: false,
        loaded: true,
        cnonce: action.result.nonce,
        searchResults: action.result.content,
        error: null,
        invalidated: false,
      };
      state = Object.assign({}, state, changedState);
      break;
    }
    case AT.LOAD_SEARCH_OBJECTS: {
      state = {
        ...state,
        loading: true,
        loaded: false,
      };
      break;
    }
    case AT.LOAD_SEARCH_OBJECTS_SUCCESS: {
      state = {
        ...state,
        loading: false,
        loaded: true,
      };
      break;
    }
    case AT.LOAD_SEARCH_FAIL:
    case AT.LOAD_SEARCH_OBJECT_FAIL:
      /**
       * actionType: LOAD_FAIL,
       * error: (obj*)error
       */
      state = {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
      break;
    case AT.UPDATE_LAST_VISITED_OFFSET:
      state = {
        ...state,
        lastVisitedOffset: action.payload,
      };
      break;

    default: {
      state = { ...state };
    }
  }

  switch (action.type) {
    case AT.LOAD_SEARCH_OBJECT:
    case AT.LOAD_SEARCH_OBJECT_SUCCESS:
    case AT.LOAD_SEARCH_OBJECT_FAIL: {
      state = {
        ...state,
        searchObjectAsyncState: asyncStateReducer(state.searchObjectAsyncState, action, '_SEARCH_OBJECT_') // prettier-ignore
      };
      break;
    }
    default:
      break;
  }

  return state;
}

/**
 * @todo change name to FETCH, because there's `shouldLoad` logic
 *
 * @function - ACTION CREATOR
 * It creates an action that:
 *   1. @fires updateQueryParams(params, query)
 *   2. Check if the state change after that event
 *   3. If the state change, @fires load(state)
 *
 * @param {Object} params - object of key-value pair of url parameter,
 *   @example: routing `/search/:input` when called `/search/example` will have params {input: 'example'}
 * @param {Object} query - object of key-value pair of url parameter,
 *   @example: routing `/search` when called `/search?input=example` will have query {input: 'example'}
 */

export function loadSearchQueryParams(params, query, callback) {
  return (dispatch, getState) => {
    const oldGlobalState = getState();
    dispatch(updateQueryParams(params, query));
    if (shouldLoad(oldGlobalState.search, getState().search)) {
      return dispatch(
        load(getState().search, (getState().search.nonce + 1) % 100, callback),
      );
    }
    return Promise.resolve();
  };
}

/**
 * @function - ACTION CREATOR
 */

/** It notifies that params & query has changed */
export function updateQueryParams(params, query) {
  return {
    type: AT.SEARCH_QUERY_PARAMS_CHANGE,
    payload: { params, query },
  };
}

/** It notifies that a disjunctive facet's is being refined */
export function searchDisjunctiveFacetChange(facetName, facetValue, isRefined) {
  return {
    type: AT.SEARCH_DISJUNCTIVE_FACET_CHANGE,
    payload: {
      facetName,
      facetValue,
      isRefined,
    },
  };
}

/** It creates an action to clear everything back to initial condition */
export function clearSearch() {
  return {
    type: AT.CLEAR_SEARCH,
  };
}

/** It notifies that we need a load event, followed by load success / fail */
export function load(newSearch, nonce, callback) {
  return {
    types: [AT.LOAD_SEARCH, AT.LOAD_SEARCH_SUCCESS, AT.LOAD_SEARCH_FAIL],
    promise: () =>
      fetchSearchResults(newSearch.searchParameters, newSearch.searchIndex),
    shouldCallAPI: (globalState) => shouldLoad(globalState.search, newSearch),
    payload: {
      nonce,
      searchParameters: newSearch.searchParameters,
      searchIndex: newSearch.searchIndex,
    },
    options: {
      transformer: (result) => {
        const normalized = normalize(
          result.content.hits,
          Schemas.PRODUCT_COLLECTION,
        );
        const newResult = {
          content: Object.assign({}, result.content, {
            hits: normalized.result,
          }),
          entities: normalized.entities,
          nonce,
        };
        return newResult;
      },
      callback,
    },
  };
}

/** It notifies that we need a load event, followed by load success / fail */
export function loadProduct(objectID, attributesToRetrieve, callback) {
  return {
    types: [
      AT.LOAD_SEARCH_OBJECT,
      AT.LOAD_SEARCH_OBJECT_SUCCESS,
      AT.LOAD_SEARCH_OBJECT_FAIL,
    ],
    promise: () => fetchObject(objectID, attributesToRetrieve),
    payload: { objectID },
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.PRODUCT);
        const newResult = {
          content: normalized.result,
          entities: normalized.entities,
        };
        return newResult;
      },
      callback,
    },
  };
}

export function loadProducts(
  arrOfObjectIDs,
  searchIndex = ALGOLIA_DEFAULT_INDEX_NAME,
  callback,
) {
  return {
    types: [
      AT.LOAD_SEARCH_OBJECTS,
      AT.LOAD_SEARCH_OBJECTS_SUCCESS,
      AT.LOAD_SEARCH_OBJECTS_FAIL,
    ],
    promise: () => fetchObjects(arrOfObjectIDs, searchIndex),
    payload: arrOfObjectIDs,
    options: {
      transformer: (result) => {
        const normalized = normalize(
          result.results,
          Schemas.PRODUCT_COLLECTION,
        );
        const newResult = {
          content: normalized.result,
          entities: normalized.entities,
        };
        return newResult;
      },
      callback,
    },
  };
}

/** It notifies that we need a load event, followed by load success / fail */
export function load3DAsset(objectID, attributesToRetrieve, callback) {
  return {
    types: [
      AT.LOAD_SEARCH_OBJECT,
      AT.LOAD_SEARCH_OBJECT_SUCCESS,
      AT.LOAD_SEARCH_OBJECT_FAIL,
    ],
    promise: () =>
      fetchObject(objectID, attributesToRetrieve, ALGOLIA_3D_ASSET_INDEX_NAME),
    payload: { objectID },
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.THREE_DIMENSIONAL_ASSET);
        const newResult = {
          content: normalized.result,
          entities: normalized.entities,
        };
        return newResult;
      },
      callback,
    },
  };
}

export function load3DAssets(arrOfObjectIDs, callback) {
  return {
    types: [
      AT.LOAD_SEARCH_OBJECTS,
      AT.LOAD_SEARCH_OBJECTS_SUCCESS,
      AT.LOAD_SEARCH_OBJECTS_FAIL,
    ],
    promise: () => fetchObjects(arrOfObjectIDs, ALGOLIA_3D_ASSET_INDEX_NAME),
    payload: arrOfObjectIDs,
    options: {
      transformer: (result) => {
        const normalized = normalize(
          result.results,
          Schemas.THREE_DIMENSIONAL_ASSET_COLLECTION,
        );
        const newResult = {
          content: normalized.result,
          entities: normalized.entities,
        };
        return newResult;
      },
      callback,
    },
  };
}

/**
 * To load all children of a given product, given ids
 */
export function loadProductChildren(
  product,
  whitelist = K_PRODUCT_CHILDREN_KEYS,
) {
  return loadProductsChildren([product], whitelist);
}

/**
 * To load all children of given products, given ids
 * @param  {Array}  whitelist, to override defaultProductPropsToLoad
 */
export function loadProductsChildren(
  products,
  whitelist = K_PRODUCT_CHILDREN_KEYS,
) {
  return (dispatch) => {
    let additionalProductIdsToLoad = [];
    products.filter(Boolean).forEach((product) => {
      const propsToLoad = whitelist;
      propsToLoad.forEach((prop) => {
        const ids = get(product, prop); // eg. product.similarItemsIds, product.freqBoughtTogetherIds, ...
        additionalProductIdsToLoad = additionalProductIdsToLoad.concat(ids);
      });
    });
    additionalProductIdsToLoad = additionalProductIdsToLoad.filter(Boolean);
    if (additionalProductIdsToLoad.length) {
      return dispatch(loadProducts(additionalProductIdsToLoad));
    }
    return null;
  };
}

/**
 * To calculate product recommendations if expired / not found
 * Recommendation names: Similar Products, Exclusive Products, Sponsored Products
 */
export function calculateProductRecommendations(objectID) {
  return {
    types: [
      AT.CALCULATE_PRODUCT_RECOMMENDATION,
      AT.CALCULATE_PRODUCT_RECOMMENDATION_SUCCESS,
      AT.CALCULATE_PRODUCT_RECOMMENDATION_FAIL,
    ],
    promise: (client) =>
      client.post(
        `${config.API_URL_GOBLIN}/products/calculate-recommendation/`,
        {
          data: { upc: objectID },
        },
      ),
  };
}

/**
 * To save last visited product index from Product Search (for scrollToIndex)
 */
export function updateLastVisitedOffset(offset) {
  return {
    type: AT.UPDATE_LAST_VISITED_OFFSET,
    payload: offset,
  };
}
