import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAdsByLocation } from 'app-libs/redux_modules/selectors/advertisement';
import { getBannersFromAdvertisements } from 'app-libs/etc/advertisementHelper';

import CampaignBannerSlider from 'components/promo/Shared/CampaignBannerSlider';
import { K__STOREFRONT_MWEB__OFFLINE_STORE_LIST__TOP } from 'constants/advertisementConstants';
import { ViewStyle } from 'types/styles';

const K_HEIGHT_PROPORTION = 90;
const K_WIDTH_PROPORTION = 360;

interface OfflineStoreListPageBannerSectionProps {
  style?: ViewStyle | ViewStyle[];
}

const OfflineStoreListPageBannerSection: React.FC<OfflineStoreListPageBannerSectionProps> = memo(
  ({ style }) => {
    const ads = useSelector((state) =>
      getAdsByLocation(state, K__STOREFRONT_MWEB__OFFLINE_STORE_LIST__TOP),
    );

    const bannersToShow = useMemo(() => getBannersFromAdvertisements(ads), [
      ads,
    ]);

    if (bannersToShow.length === 0) {
      return null;
    }

    return (
      <CampaignBannerSlider
        banners={bannersToShow}
        height={K_HEIGHT_PROPORTION}
        width={K_WIDTH_PROPORTION}
        style={style}
      />
    );
  },
);

export default OfflineStoreListPageBannerSection;
