import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  itemChunkStyles: {
    ...cStyles.phm,
  },

  titleStyles: {
    ...cStyles.phxxl,
    marginVertical: 10,
  },

  baseLabelStyles: {
    ...cStyles.fwb,
    ...cStyles.fs6,
  },
});
