import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

import { initialState as productRecommendationInitialState } from 'app-libs/AlgoliaAdapter/searchStates/productRecommendationSearchState';
import {
  HFR_KEY,
  initialState,
} from 'app-libs/AlgoliaAdapter/searchStates/productSearchStates';
import { unslugify } from 'app-libs/etc';
import { Schemas } from 'app-libs/redux_modules/entities';
import { getCurrentLocationRoutingZoneCode } from 'app-libs/redux_modules/entity_modules/location/selectors';
import {
  loadBrandStats,
  loadProductColorSiblings,
  loadReviewedProducts,
} from 'app-libs/redux_modules/entity_modules/product';
import { isReviewedProductByBrandLoaded } from 'app-libs/redux_modules/entity_modules/selectors/productV2';
import { storeObjectsInEntities } from 'app-libs/redux_modules/helper_modules/entities';

import ProductDetailsPage from 'components/product/@ProductDetailsPage/Loadable';

import {
  getLoadSEOAreasQueryKey,
  getLoadSEOTopSearchQueriesQueryKey,
  loadSEOAreas,
  loadSEOTopSearchQueries,
} from 'entities/seo/api';

import { K_STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER } from 'constants/advertisementConstants';

export const consumeSSRResultFunction = (result, { store }) => {
  const { dispatch, getState } = store;
  const products = result?.content?.hits || [];

  dispatch(storeObjectsInEntities(products, Schemas.PRODUCT_COLLECTION));

  const siblings = products.reduce((acc, curr) => {
    const { simplifiedOptions } = curr;
    const colorVariant = (simplifiedOptions?.variants || []).find(
      (variant) => variant.type === 'Opsi Warna',
    );
    const colorSiblings = colorVariant?.upcs || [];
    return [...acc, ...colorSiblings];
  }, []);

  if (siblings.length > 0) {
    const state = getState();
    const routingZoneCode = getCurrentLocationRoutingZoneCode(state);
    return dispatch(
      loadProductColorSiblings(products, siblings, routingZoneCode),
    );
  }
  return null;
};

export const fetchProductSearchData = async (
  { res, store, params, isServer, queryClient },
  promises = [],
) => {
  const { dispatch, getState } = store;
  const brandSlug = params.bslug || '';
  const areaSlug = params.areaSlug || '';

  if (brandSlug) {
    const brandName = unslugify(brandSlug);
    promises.push(dispatch(loadBrandStats(brandName)));
    if (!isReviewedProductByBrandLoaded(brandName)(getState())) {
      promises.push(dispatch(loadReviewedProducts(brandName)));
    }
  }

  if (isServer) {
    promises.push(
      queryClient.fetchQuery(getLoadSEOAreasQueryKey(), loadSEOAreas),
    );
    promises.push(
      queryClient.fetchQuery(
        getLoadSEOTopSearchQueriesQueryKey(),
        loadSEOTopSearchQueries,
      ),
    );
  }

  await Promise.all(promises);

  // @note(dika) 25 Nov 2024: do all checks to determine server should return status 404 or not
  if (isServer) {
    // 1. Show 404 on Category Location Page when Area Slug not exists
    const areas = queryClient.getQueryData(getLoadSEOAreasQueryKey()) || [];
    if (areaSlug !== '' && !areas.find(({ slug }) => slug === areaSlug)) {
      res.status(404);
    }
  }
  return { isServer };
};

export const isPostsLoaded = (
  modifiedParams,
  categorySeos,
  posts,
  categoryAreaSeos,
) => {
  // MUST exclude areaSlug from params
  // MUST exclude style from params
  const { areaSlug, style, ...modifiedParamsWithoutArea } = modifiedParams; // eslint-disable-line

  return Object.values(modifiedParamsWithoutArea).every((category) => {
    const categoryAreaSeo = categoryAreaSeos?.[`${category}+${areaSlug || ''}`];
    const categorySeo = categorySeos[category];

    if (!categorySeo || !categoryAreaSeo) return false;
    if (isEmpty(categorySeo)) return true;

    const { articleId: areaArticleId } = categoryAreaSeo;
    const { articleId, other } = categorySeo;

    let postIds = [];
    if (areaArticleId) {
      postIds.push(areaArticleId);
    } else if (articleId) {
      postIds.push(articleId);
    }
    if (other) {
      postIds = postIds.concat(other);
    }
    return postIds.every((id) => posts[id]);
  });
};

export const getFacetStatsDeriveFunction =
  (categoryBreadcrumb) => (searchParams) => {
    const { disjunctiveFacetsRefinements, disjunctiveFacets } = initialState;
    let derivedParams = searchParams
      .clearRefinements()
      .setDisjunctiveFacets(disjunctiveFacets);

    derivedParams = derivedParams.addHierarchicalFacetRefinement(
      HFR_KEY,
      categoryBreadcrumb,
    );

    Object.keys(disjunctiveFacetsRefinements).forEach((facet) => {
      derivedParams = derivedParams.addDisjunctiveFacetRefinement(
        facet,
        disjunctiveFacetsRefinements[facet],
      );
    });

    return derivedParams;
  };

export function getBestSellerProductSearchState(pathname) {
  return {
    ...productRecommendationInitialState,
    attributesToRetrieve: '*',
    facets: [
      ...productRecommendationInitialState.facets,
      'bestSellerCategories',
    ],
    facetsRefinements: {
      ...productRecommendationInitialState.facetsRefinements,
      bestSellerCategories: [pathname],
    },
  };
}

export const getCategoryAdsLocation = (categoryName) => {
  if (isEmpty(categoryName)) {
    return K_STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER;
  }

  const name = categoryName.replace(/ /g, '_');

  return K_STOREFRONT_MWEB__CATEGORY_DEFAULT__BANNER.replace(
    'DEFAULT',
    name.toUpperCase(),
  );
};

export const useProductListDataPreloadEffect = () => {
  useEffect(() => {
    if (window.requestIdleCallback)
      window.requestIdleCallback(ProductDetailsPage.preload);
    else setTimeout(ProductDetailsPage.preload, 5000);
  }, []);
};
