import keyMirror from 'keymirror';
import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';
import config from 'config';
import { fetchSearchResults } from 'app-libs/redux_modules/helper_modules/search/algoliaWrapper';

export const ALGOLIA_BANK_INDEX_NAME = config.ALGOLIA_BANK_INDEX_NAME;

export const AT = keyMirror({
  GET_BANKS: null,
  GET_BANKS_SUCCESS: null,
  GET_BANKS_FAIL: null,
});

const defaultMaxValuesPerFacet = 100000;
const defaultParameters = {
  maxValuesPerFacet: defaultMaxValuesPerFacet,
  facets: [],
  attributesToRetrieve: [],
  attributesToHighlight: [],
};

export const initialState = {
  asyncState: initialAsyncState,
  data: [],
};

export default function(mutableState = initialState, action = {}) {
  let state = mutableState;

  switch (action.type) {
    case AT.GET_BANKS:
    case AT.GET_BANKS_FAIL:
      break;
    case AT.GET_BANKS_SUCCESS:
      state = Object.assign({}, state, {
        data: action.result,
      });
      break;
  }

  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_BANKS_'),
  });
}

export function loadBanks(additionalParameters, callback = () => {}) {
  const searchParameters = Object.assign(
    {},
    defaultParameters,
    additionalParameters,
    {
      hitsPerPage: 999, // load as much as we can assuming total banks in Indonesia not greater than 999
      attributesToRetrieve: ['shortName', 'longName', 'code', 'objectID'],
    },
  );

  return {
    types: [AT.GET_BANKS, AT.GET_BANKS_SUCCESS, AT.GET_BANKS_SUCCESS],
    promise: () =>
      fetchSearchResults(searchParameters, ALGOLIA_BANK_INDEX_NAME),
    options: {
      callback,
      transformer: (result) => {
        const { content } = result;
        if (!content) {
          return [];
        }

        return content.hits;
      },
    },
  };
}
