import { schema } from 'normalizr';

export const flashSaleSchema = new schema.Entity(
  'flashSalesById',
  {},
  { idAttribute: 'id' },
);
export const flashSaleCollectionSchema = [flashSaleSchema];

export const flashSaleItemUserQuantitySchema = new schema.Entity(
  'flashSaleItemUserQuantitiesById',
  {},
  { idAttribute: 'id' },
);
export const flashSaleItemUserQuantityCollectionSchema = [
  flashSaleItemUserQuantitySchema,
];
