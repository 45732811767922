import { Colors, StyleSheet } from '@dkrm/ui-kit-basic';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    ...cStyles.flexDirCol,
    ...cStyles.flex1,
    justifyContent: 'space-between',
  },
  title: {
    ...cStyles.flexWrap,
  },
  textContainer: {
    ...cStyles.mlm,
    ...cStyles.phm,
    ...cStyles.flexLeftMiddle,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: Colors.C_BLACK_40,
    minHeight: 24,
  },
});
