/* eslint-disable camelcase */
import { Product } from 'app-libs/components/Product/types';
import {
  getBasketLinesTotalValue,
  getOfferDiscountAmount,
  getSplitProductCategories,
  getTotalValueFromProducts,
} from 'app-libs/etc/productHelper';
import {
  Basket,
  BasketLine,
  BasketLineProduct,
} from 'app-libs/redux_modules/entity_modules/basket/types';
import { getCartLinesValuePublic } from 'app-libs/redux_modules/entity_modules/selectors/project';

import { Order } from 'entities/order/types';

import { PortfolioProject } from './types';

const K_GA4_IDR_CURRENCY = 'IDR';
const K_GA4_BUSINESS_UNIT_RETAIL = 'retail';
const K_GA4_BUSINESS_UNIT_INTERIOR = 'interior';

const generateItemDataFromProduct = (
  product: Product | BasketLineProduct,
  quantity: number,
) => {
  const categories = getSplitProductCategories(product);
  return {
    item_id: product.objectID,
    item_name: product.title,
    currency: K_GA4_IDR_CURRENCY,
    discount: getOfferDiscountAmount(product),
    item_brand: product.brand,
    item_category: categories?.[0] || '',
    item_category2: categories?.[1] || '',
    item_category3: categories?.[2] || '',
    price: product.priceNumber,
    quantity,
  };
};

export const genProduct = (product: Product) => {
  return {
    currency: K_GA4_IDR_CURRENCY,
    value: getTotalValueFromProducts([product]),
    business_unit: K_GA4_BUSINESS_UNIT_RETAIL,
    items: [generateItemDataFromProduct(product, 1)],
  };
};

export const genBasket = (basket: Basket) => {
  return {
    currency: K_GA4_IDR_CURRENCY,
    value: getBasketLinesTotalValue(basket.lines),
    business_unit: K_GA4_BUSINESS_UNIT_RETAIL,
    items: basket.lines.map((line) =>
      generateItemDataFromProduct(line.product, line.quantity),
    ),
  };
};

export const genOrder = (order: Order, lines: BasketLine[]) => {
  return {
    transaction_id: order.number,
    currency: K_GA4_IDR_CURRENCY,
    value: getBasketLinesTotalValue(lines),
    business_unit: K_GA4_BUSINESS_UNIT_RETAIL,
    shipping: order.shippingBeforeDiscountsInclTax,
    items: lines.map((line) =>
      generateItemDataFromProduct(line.product, line.quantity),
    ),
  };
};

export const genProject = (project: PortfolioProject) => {
  const splitCategories = project?.category?.split(' > ') || '';
  return {
    currency: K_GA4_IDR_CURRENCY,
    value: getCartLinesValuePublic(project),
    business_unit: K_GA4_BUSINESS_UNIT_INTERIOR,
    items: [
      {
        item_id: project.projectKey,
        item_name: project.title,
        currency: K_GA4_IDR_CURRENCY,
        item_category: splitCategories?.[0] || '',
        item_category2: splitCategories?.[1] || '',
        item_designer_name: project?.mainDesigner?.member?.name || '',
        item_property_type: project.propertyTypeName,
        item_service_type: project.serviceTypeName,
        price: getCartLinesValuePublic(project),
        quantity: 1,
      },
    ],
  };
};

export function genPortfolioKeys(portfolioProjects: PortfolioProject[]) {
  const portfolioKeys = portfolioProjects.map(
    (portfolio) => portfolio.projectKey,
  );
  return {
    business_unit: K_GA4_BUSINESS_UNIT_INTERIOR,
    portfolio_keys: portfolioKeys,
  };
}
