import React from 'react';
import PropTypes from 'prop-types';
import NavbarTitleCentered from 'app-libs/components/NavbarImplementation/NavbarTitleCentered';

const NavbarPromo = ({ children }) => (
  <React.Fragment>
    <NavbarTitleCentered title="Voucher" />
    {children}
  </React.Fragment>
);

NavbarPromo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavbarPromo;
