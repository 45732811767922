import React, { memo } from 'react';
import { ViewStyle } from 'react-native';

import RView, { MediaRule } from 'emotion-native-media-query';

import cStyles from '@dkrm/general-libs/theme/styles';
import USPTile from './USPTile';
import USPCard from './USPCard';

interface USPSectionProps {
  data: Array<{
    iconUri: string;
    title: string;
    linkUrl: string;
    description?: string;
  }>;
  style?: ViewStyle;
}

const K_CONTENT_MAX_WIDTH = 1200;
const K_THRESHOLDS = [640, 850];

const USPSection: React.FC<USPSectionProps> = memo(({ data, style }) => (
  <>
    <RView
      style={{
        ...cStyles.phxl,
        ...cStyles.flexDirRow,
        ...cStyles.flexMiddleLeft,
        ...style,
      }}
      rStyle={{
        [MediaRule.MinWidth]: {
          [K_THRESHOLDS[0]]: { display: 'none' },
        },
      }}
    >
      {data.map(({ iconUri, title, linkUrl }, index) => (
        <USPTile
          key={title}
          iconUri={iconUri}
          title={title}
          linkUrl={linkUrl}
          style={index > 0 && cStyles.mlxl}
        />
      ))}
    </RView>
    <RView
      style={{
        ...cStyles.phxxl,
        ...cStyles.flexDirRow,
        ...cStyles.width100,
        alignSelf: 'center',
        maxWidth: K_CONTENT_MAX_WIDTH + 48,
        ...style,
        display: 'none',
      }}
      rStyle={{
        [MediaRule.MinWidth]: {
          [K_THRESHOLDS[0]]: { display: 'flex' },
          [K_THRESHOLDS[1]]: { display: 'none' },
        },
      }}
    >
      {data.map(({ iconUri, title, description = '', linkUrl }, index) => (
        <USPCard
          key={title}
          iconUri={iconUri}
          title={title}
          description={description}
          linkUrl={linkUrl}
          style={index > 0 ? { marginLeft: 32 } : undefined}
          shouldHideDescription
        />
      ))}
    </RView>
    <RView
      style={{
        ...cStyles.phxxl,
        ...cStyles.flexDirRow,
        ...cStyles.width100,
        alignSelf: 'center',
        maxWidth: K_CONTENT_MAX_WIDTH + 48,
        ...style,
        display: 'none',
      }}
      rStyle={{
        [MediaRule.MinWidth]: {
          [K_THRESHOLDS[1]]: { display: 'flex' },
        },
      }}
    >
      {data.map(({ iconUri, title, description = '', linkUrl }, index) => (
        <USPCard
          key={title}
          iconUri={iconUri}
          title={title}
          description={description}
          linkUrl={linkUrl}
          style={index > 0 ? { marginLeft: 32 } : undefined}
        />
      ))}
    </RView>
  </>
));

export default USPSection;
