import update from 'immutability-helper';
import keyMirror from 'keymirror';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { normalize } from 'normalizr';

import config from 'config';

import { Schemas } from 'app-libs/redux_modules/entities';

import { AT as AT_AUTH } from '../auth';
import asyncStateReducer from '../helper_modules/asyncState';
import { fetchSearchResults } from '../helper_modules/search/algoliaWrapper';

const defaultMaxValuesPerFacet = 100000;
const defaultParameters = {
  maxValuesPerFacet: defaultMaxValuesPerFacet,
  facets: ['startTime', 'endTime', 'publicBanner'],
  attributesToRetrieve: '*',
  attributesToHighlight: [],
  facetFilters: ['publicBanner:-false', 'voucherIssuer:-Merchant'],
  numericRefinements: {
    startTime: {
      '<=': [Date.now() / 1000],
    },
    endTime: { '>=': [Date.now() / 1000] },
    quota: { '>': [0] },
  },
  hitsPerPage: 50,
};

const partnerVoucherParameters = {
  facetFilters: ['publicBanner:-false'],
};

export const AT = keyMirror({
  LOAD_VOUCHERS: null,
  LOAD_VOUCHERS_SUCCESS: null,
  LOAD_VOUCHERS_FAIL: null,

  LOAD_CUSTOMER_VOUCHERS: null,
  LOAD_CUSTOMER_VOUCHERS_SUCCESS: null,
  LOAD_CUSTOMER_VOUCHERS_FAIL: null,

  LOAD_PRODUCT_VOUCHERS: null,
  LOAD_PRODUCT_VOUCHERS_SUCCESS: null,
  LOAD_PRODUCT_VOUCHERS_FAIL: null,

  LOAD_BRAND_VOUCHERS: null,
  LOAD_BRAND_VOUCHERS_SUCCESS: null,
  LOAD_BRAND_VOUCHERS_FAIL: null,

  SET_CUSTOMER_VOUCHERS: null,

  SET_SHOULD_SHOW_SUCCESS_GETTING_WELCOME_VOUCHER_MODAL: null,
  SET_SHOULD_SHOW_FAILED_GETTING_WELCOME_VOUCHER_MODAL: null,
});

const initialState = {
  publicVouchers: {
    asyncState: { loading: false },
    result: [],
  },
  customerVouchers: {
    asyncState: { loading: true },
    result: [],
  },
  productVouchers: {
    asyncState: { loading: false },
    result: {},
  },
  brandVouchers: {
    asyncState: { loading: false },
    result: {},
  },

  shouldShowSuccessGettingWelcomeVoucherModal: false,
  shouldShowFailedGettingWelcomeVoucherModal: false,
};

export default function voucherReducer(
  mutableState = initialState,
  action = {},
) {
  const state = mutableState;

  const { type, result, payload } = action;

  const newAsyncStateCustomer = asyncStateReducer(
    state.customerVouchers.asyncState,
    action,
    '_CUSTOMER_VOUCHERS_',
  );
  const newAsyncStatePublic = asyncStateReducer(
    state.publicVouchers.asyncState,
    action,
    '_VOUCHERS_',
  );
  const newAsyncStateProduct = asyncStateReducer(
    state.productVouchers.asyncState,
    action,
    '_PRODUCT_VOUCHERS_',
  );
  const newAsyncStateBrand = asyncStateReducer(
    state.brandVouchers.asyncState,
    action,
    '_BRAND_VOUCHERS_',
  );

  switch (type) {
    case AT.LOAD_VOUCHERS_SUCCESS:
      return update(state, {
        publicVouchers: {
          result: { $set: result.vouchers },
          asyncState: { $set: newAsyncStatePublic },
        },
      });
    case AT.LOAD_CUSTOMER_VOUCHERS_SUCCESS:
      return update(state, {
        customerVouchers: {
          result: { $set: result.vouchers },
          asyncState: { $set: newAsyncStateCustomer },
        },
      });
    case AT.LOAD_PRODUCT_VOUCHERS_SUCCESS:
      return update(state, {
        productVouchers: {
          asyncState: { $set: newAsyncStateProduct },
          result: {
            $set: {
              [payload.upc]: result.vouchers,
            },
          },
        },
      });
    case AT.LOAD_BRAND_VOUCHERS_SUCCESS:
      return update(state, {
        brandVouchers: {
          asyncState: { $set: newAsyncStateBrand },
          result: {
            $set: {
              [payload.brand]: result.vouchers,
            },
          },
        },
      });
    case AT.SET_CUSTOMER_VOUCHERS:
      return update(state, {
        customerVouchers: {
          result: { $set: payload.vouchers },
        },
      });
    case AT.SET_SHOULD_SHOW_SUCCESS_GETTING_WELCOME_VOUCHER_MODAL:
      return update(state, {
        shouldShowSuccessGettingWelcomeVoucherModal: {
          $set: payload.shouldShowModal,
        },
      });
    case AT.SET_SHOULD_SHOW_FAILED_GETTING_WELCOME_VOUCHER_MODAL:
      return update(state, {
        shouldShowFailedGettingWelcomeVoucherModal: {
          $set: payload.shouldShowModal,
        },
      });
    case AT_AUTH.LOGOUT_SUCCESS:
      return initialState;
    default:
      return update(state, {
        publicVouchers: {
          asyncState: { $set: newAsyncStatePublic },
        },
        customerVouchers: {
          asyncState: { $set: newAsyncStateCustomer },
        },
        productVouchers: {
          asyncState: { $set: newAsyncStateProduct },
        },
        brandVouchers: {
          asyncState: { $set: newAsyncStateBrand },
        },
      });
  }
}

export function loadPublicVouchers(callback) {
  const searchParameters = { ...defaultParameters };
  return {
    types: [AT.LOAD_VOUCHERS, AT.LOAD_VOUCHERS_SUCCESS, AT.LOAD_VOUCHERS_FAIL],
    promise: () =>
      fetchSearchResults(searchParameters, config.ALGOLIA_VOUCHER_INDEX_NAME),
    payload: {
      searchParameters,
    },
    options: {
      transformer: (result) => {
        if (isEmpty(result.content.hits)) return { vouchers: [] };
        const normalized = normalize(
          result.content.hits,
          Schemas.VOUCHER_COLLECTION,
        );
        return {
          vouchers: result.content.hits,
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function loadCustomerVouchers(callback) {
  return {
    types: [
      AT.LOAD_CUSTOMER_VOUCHERS,
      AT.LOAD_CUSTOMER_VOUCHERS_SUCCESS,
      AT.LOAD_CUSTOMER_VOUCHERS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_GOBLIN}/voucher/customer-vouchers/?format=json`,
      ),
    options: {
      transformer: (result) => {
        if (isEmpty(result)) return { vouchers: [] };
        const normalized = normalize(result, Schemas.VOUCHER_COLLECTION);
        return {
          vouchers: result,
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function loadProductVouchers(product, callback = () => null) {
  const searchParameters = {
    ...defaultParameters,
    facetFilters: [
      ...defaultParameters.facetFilters,
      [
        `includedBrands:${get(product, 'brand')}`,
        `includedCategories:${get(product, 'categories', [])[0]}`,
        `includedProducts:${get(product, 'objectID')}`,
        'includesAllProducts:true',
      ],
    ],
  };
  return {
    types: [
      AT.LOAD_PRODUCT_VOUCHERS,
      AT.LOAD_PRODUCT_VOUCHERS_SUCCESS,
      AT.LOAD_PRODUCT_VOUCHERS_FAIL,
    ],
    promise: () =>
      fetchSearchResults(searchParameters, config.ALGOLIA_VOUCHER_INDEX_NAME),
    payload: {
      searchParameters,
      upc: product.objectID,
    },
    options: {
      transformer: (result) => {
        if (isEmpty(result.content.hits)) return { vouchers: [] };
        const normalized = normalize(
          result.content.hits,
          Schemas.VOUCHER_COLLECTION,
        );
        return {
          vouchers: result.content.hits,
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function loadBrandVouchers(brand) {
  const searchParameters = {
    ...defaultParameters,
    facetFilters: [
      ...partnerVoucherParameters.facetFilters,
      `partnerBrands:${brand}`,
    ],
  };
  return {
    types: [
      AT.LOAD_BRAND_VOUCHERS,
      AT.LOAD_BRAND_VOUCHERS_SUCCESS,
      AT.LOAD_BRAND_VOUCHERS_FAIL,
    ],
    promise: () =>
      fetchSearchResults(searchParameters, config.ALGOLIA_VOUCHER_INDEX_NAME),
    payload: {
      searchParameters,
      brand,
    },
    options: {
      transformer: (result) => {
        if (isEmpty(result.content.hits)) return { vouchers: [] };
        return {
          vouchers: result.content.hits,
        };
      },
    },
  };
}

export function setCustomerVouchers(vouchers) {
  return {
    type: AT.SET_CUSTOMER_VOUCHERS,
    payload: { vouchers },
  };
}

export function setShouldShowSuccessGettingWelcomeVoucherModal(
  shouldShowModal,
) {
  return {
    type: AT.SET_SHOULD_SHOW_SUCCESS_GETTING_WELCOME_VOUCHER_MODAL,
    payload: { shouldShowModal },
  };
}

export function setShouldShowFailedGettingWelcomeVoucherModal(shouldShowModal) {
  return {
    type: AT.SET_SHOULD_SHOW_FAILED_GETTING_WELCOME_VOUCHER_MODAL,
    payload: { shouldShowModal },
  };
}
