import {
  K_IMAGE_BRANDING_PUSHED_URL,
  K_IMAGE_NEW_URL,
} from 'constants/assetConstants';
import {
  K_A_BOHLAM,
  K_A_FLATPACK,
  K_A_FURNITURE_PREFIX,
  K_A_GENERALS,
  K_A_MATERIAL,
  K_A_OPTION_PREFIX,
  K_A_SPECS,
  K_A_STD_PACKAGING_CODES,
  K_A_STD_SIZE_CODES,
  K_CONFIG_ATTRIBUTE,
  K_HIDDEN_ATTRIBUTE_CODES,
  K_PRODUCT_ADDITIONAL_QUANTITY_SOLD,
  K_UNIT_BY_ATTRIBUTE,
} from 'constants/productConstants';

function isHiddenAttribute(code) {
  return K_HIDDEN_ATTRIBUTE_CODES.includes(code);
}

function isConfigAttribute(code) {
  return code.startsWith(K_CONFIG_ATTRIBUTE);
}

function isAttributeShownOnProductDetail(code) {
  return !(isHiddenAttribute(code) || isConfigAttribute(code));
}

export function splitAttributesToGeneralAndSpecificationAndOther({
  attributes,
  shouldShowAttributeDimension = true,
  shouldShowUnitOnName = false,
  shouldShowUnitOnValue = true,
}) {
  const attributeGenerals = [];
  const attributeSpecifications = [];
  const attributeOthers = [];

  if (shouldShowAttributeDimension) {
    attributeSpecifications.push(
      genAttributeDimension({
        attributes,
        attrCodes: K_A_STD_PACKAGING_CODES,
        attrName: 'Ukuran Kemasan',
        shouldShowUnitOnName,
        shouldShowUnitOnValue,
      }),
    );
    attributeSpecifications.push(
      genAttributeDimension({
        attributes,
        attrCodes: K_A_STD_SIZE_CODES,
        attrName: 'Ukuran Barang',
        shouldShowUnitOnName,
        shouldShowUnitOnValue,
      }),
    );
  }

  attributes
    .filter(Boolean)
    .filter((attribute) => {
      const code = attribute.code || attribute.key;
      return isAttributeShownOnProductDetail(code);
    })
    .forEach((attribute) => {
      const code = attribute.code || attribute.key;
      const unit = attribute.unit || K_UNIT_BY_ATTRIBUTE[code] || '';

      if (code.startsWith('a_custom')) {
        // pass; // Don't render variation
      } else if (code.startsWith(K_A_OPTION_PREFIX)) {
        // pass; // Don't render variation
      } else if (code.startsWith(K_A_FLATPACK)) {
        attributeGenerals.push({
          ...attribute,
          name: 'Packing',
          value: 'Flatpack',
        });
      } else if (code === 'a_color') {
        attributeGenerals.push(
          Object.assign({}, attribute, { value: attribute.value }),
        );
      } else if (code === 'a_material') {
        attributeGenerals.push(
          Object.assign({}, attribute, { value: attribute.value }),
        );
      } else if (code.startsWith('a_detail')) {
        attributeGenerals.push(attribute);
      } else if (code.startsWith(K_A_BOHLAM)) {
        attributeGenerals.push(attribute);
      } else if (code.startsWith('a_bonus')) {
        attributeGenerals.push(attribute);
      } else if (code.startsWith(K_A_FURNITURE_PREFIX)) {
        attributeGenerals.push(attribute);
      } else if (code.startsWith('a_tipe')) {
        attributeGenerals.push(attribute);
      } else if (K_A_GENERALS.includes(code)) {
        attributeGenerals.push(attribute);
      } else if (K_A_SPECS.includes(code)) {
        attributeSpecifications.push(
          Object.assign({}, attribute, {
            name:
              shouldShowUnitOnName && unit
                ? `${attribute.name} (${unit})`
                : attribute.name,
            value: shouldShowUnitOnValue
              ? `${attribute.value}${unit}`
              : attribute.value,
          }),
        );
      } else if (code.startsWith('a_size')) {
        attributeSpecifications.push(attribute);
      } else {
        attributeOthers.push(attribute);
      }
    });
  return { attributeGenerals, attributeSpecifications, attributeOthers };
}

export function getMaterialAttributeValue({ attributes }) {
  return attributes.find((attr) => attr.code === K_A_MATERIAL) || {};
}

export function genAttributeDimension({
  attributes,
  attrCodes,
  attrName,
  shouldShowUnitOnName = false,
  shouldShowUnitOnValue = true,
  shouldShowNameInitial = false,
}) {
  const attributeValues = attrCodes
    .map((code) => {
      const attr = attributes.find((attribute) => attribute.code === code);
      if (!attr) return null;
      if (shouldShowNameInitial) return `${attr.name.charAt(0)}: ${attr.value}`;
      return attr.value;
    })
    .filter(Boolean);
  const result = {
    name: shouldShowUnitOnName ? `${attrName} (cm)` : attrName,
    value: attributeValues
      .map((val) => (shouldShowUnitOnValue ? `${val}cm` : val))
      .join(' x '),
    code: `${attrName}_${attributeValues.join('_')}`,
  };
  if (!result.value.length) {
    return {
      name: attrName,
      value: 'Sama dengan ukuran kemasan',
      code: `${attrName}_Sama dengan ukuran kemasan`,
    };
  }
  return result;
}

export function getAttributeOf(attributes, attrCode) {
  return attributes.find((attribute) => attribute.code === attrCode) || {};
}

export function getAttributeValueOf(attributes, attrCode) {
  return getAttributeOf(attributes, attrCode).value || '';
}

export function getAttributeByKeyOf(attributes, attrCode) {
  return attributes.find((attribute) => attribute.key === attrCode) || {};
}

export function getAttributeValueByKeyOf(attributes, attrCode) {
  return getAttributeByKeyOf(attributes, attrCode).value;
}

export function getOptionAttributes(attributes) {
  return attributes.filter((attribute) => {
    const code = attribute.code || attribute.key;
    return code.startsWith(K_A_OPTION_PREFIX);
  });
}

export function getAttributeByNameOf(attributes, name) {
  return attributes.find((attribute) => attribute.name === name) || {};
}

export function getAttributeValueByNameOf(attributes, name) {
  return getAttributeByNameOf(attributes, name).value || '';
}

/**
Example input:
  {
    name: 'Material',
    code: 'a_material'
    value: 'Lapisan Bantalan: Low Density Foam, High Density Foam, dan Dacron; Kerangka Sofa: Kayu Mahoni Solid; Kaki Sofa: Kayu Mahoni',
    type: 'text'
  }

Example output:
  [{
    name: 'Lapisan Bantalan',
    code: 'a_material_lapisan bantalan'
    value: 'Low Density Foam, High Density Foam, dan Dacron'
  },{
    name: 'Kerangka Sofa:',
    code: 'a_material_Kerangka Sofa'
    value: 'Kayu Mahoni Solid'
  },
]
* */
export function expandMaterialAttribute(attribute) {
  try {
    const { code } = attribute;
    let materialAttrs = attribute.value.split(';').map((s) => s.trim());
    if (materialAttrs.length > 1) {
      materialAttrs = materialAttrs
        .map((str) => {
          const [name, value] = str.split(':').map((s) => s.trim());
          return {
            name,
            value,
            type: 'text',
            code: `${code}_${name}`,
          };
        })
        .filter((attr) => !!attr.name && !!attr.value);
      return materialAttrs;
    }
  } catch (e) {
    return [];
  }
  return [attribute];
}

/**
 * Fetches material data from materialFAQ.js or materialInfo.js
 * for a product according to their corresponsing product attributes.
 */

export function getMaterialCards(attributes, materialData) {
  const cards = [];

  attributes
    .filter((attr) => !!materialData[attr.name]) // filter undefined
    .forEach((attr) => {
      let matches = materialData[attr.name].filter(
        (m) => m.values.indexOf(attr.value) >= 0,
      );
      if (matches.length > 0) {
        cards.push(...matches);
      } else {
        matches = materialData[attr.name].filter(
          (m) => m.values.indexOf('Lain-lain') >= 0,
        );
        cards.push(...matches);
      }
    });
  if (!cards.length) {
    return [];
  }
  if (materialData['Lain-lain']) {
    return [...cards, ...materialData['Lain-lain']];
  }
  return cards;
}

export const isNewArrival = (product) =>
  product?.sectionAttributes?.isNewProduct ?? false;

export const isBrandingPushed = (product) => product?.isBrandingPushed || false;

export const getQuantitySold = (product) =>
  (product?.sectionAttributes?.allTimeQuantitySold ?? 0) +
  K_PRODUCT_ADDITIONAL_QUANTITY_SOLD;

export const getFreeShippingLabel = (product) =>
  product?.sectionAttributes?.freeShippingLabel ?? 'Jabodetabek';

export const getStickerImageUrl = (product) => {
  if (isBrandingPushed(product)) {
    return K_IMAGE_BRANDING_PUSHED_URL;
  }
  if (isNewArrival(product)) {
    return K_IMAGE_NEW_URL;
  }
  return '';
};
