import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, Link, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { makeParametricUrl, slugify } from 'app-libs/etc';
import { getOrderLineInsuranceInfo } from 'app-libs/etc/addonInsuranceHelper';

import { InsuranceOrderLine, OrderInsuranceInfo } from 'entities/order/types';

import {
  K_ROUTE_ORDER_PROTECTION_LINE_DETAIL,
  K_ROUTE_PRODUCT,
} from 'constants/routeConstants';

const K_IMAGE_SIZE = 40;

interface InsuranceOrderLineCardProps {
  order: OrderInsuranceInfo;
  insuranceLine: InsuranceOrderLine;
}

const InsuranceOrderLineCard: React.FC<InsuranceOrderLineCardProps> = ({
  order,
  insuranceLine,
}) => {
  const { orderKey } = order;

  const insuredLine = insuranceLine.insuredOrderLine;

  const productUrl = makeParametricUrl(K_ROUTE_PRODUCT, {
    pid: insuredLine.upc,
    pslug: slugify(insuredLine.title),
  });

  const {
    insuranceStatusLabel,
    insuranceStatusLabelTheme,
    insuranceStatusLabelBackgroundTheme,
    insuranceCoverageEndDate,
    canSeeInsurancePolicy,
  } = getOrderLineInsuranceInfo(insuranceLine);

  const history = useHistory();

  const handleLinePressed = useCallback(() => {
    actionRouteActionOpenURL(
      {
        pathname: makeParametricUrl(K_ROUTE_ORDER_PROTECTION_LINE_DETAIL, {
          orderKey,
          lineId: insuranceLine.id,
        }),
        state: {
          title: 'Detail Proteksi',
        },
      },
      history,
    );
  }, [orderKey, insuranceLine.id, history]);

  return (
    <View style={s.container}>
      <View style={s.lineInfoContainer}>
        <Link to={productUrl} isUsingAnchor>
          <SmartImage
            source={insuredLine.imageUrl}
            accessibilityLabel={insuredLine.title}
            style={s.image}
            width={K_IMAGE_SIZE}
            height={K_IMAGE_SIZE}
            resizeMode="contain"
          />
        </Link>
        <View style={s.descriptionContainer}>
          <Text theme="h5" numberOfLines={2}>
            {insuredLine.title}
          </Text>
          <Text theme="body3-black60">{insuredLine.quantity} barang</Text>
        </View>
        <View style={s.statusContainer}>
          <View>
            <Text
              theme={insuranceStatusLabelTheme}
              style={[
                s.status,
                { backgroundColor: insuranceStatusLabelBackgroundTheme },
              ]}
            >
              {insuranceStatusLabel}
            </Text>
          </View>
        </View>
      </View>
      <View style={s.policyAndClaimInfoContainer}>
        <View style={s.policyInfo}>
          <Text theme="body5-black60">Polis aktif sampai</Text>
          <Text theme="h5">{insuranceCoverageEndDate}</Text>
        </View>
        <ButtonWithText
          onPress={handleLinePressed}
          disabled={!canSeeInsurancePolicy}
          buttonStyle={s.buttonStyle}
          title="Lihat Polis"
          themeType="primary"
          small
        />
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    ...cStyles.mal,
    ...cStyles.pal,
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.bgw,
    ...cStyles.flexDirCol,
    borderColor: Colors.C_BLACK_15,
  },
  lineInfoContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
  },
  image: {
    ...cStyles.brs,
    ...cStyles.mrl,
  },
  descriptionContainer: {
    ...cStyles.flexWrap,
    ...cStyles.flex1,
  },
  statusContainer: {
    ...cStyles.flexDirRow,
  },
  status: {
    ...cStyles.pvs,
    ...cStyles.phm,
    ...cStyles.brs,
    ...cStyles.mll,
  },
  policyAndClaimInfoContainer: {
    ...cStyles.mtl,
    ...cStyles.flexDirRow,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  policyInfo: {
    ...cStyles.flexDirCol,
  },
  buttonStyle: {
    ...cStyles.mvn,
  },
});

export default InsuranceOrderLineCard;
