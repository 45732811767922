import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { Animated } from 'react-native';
import { connect } from 'react-redux';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Icon, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import {
  BUTTON_THEME,
  BUTTON_TYPE,
} from '@dkrm/ui-kit-basic/v2/Button/constants';

import BasePopup from 'app-libs/components/Notification/BasePopup';
import { hideSnackBar } from 'app-libs/redux_modules/flow_modules/notification';

import * as basePopupConstants from '../BasePopup/constants';
import styles from './styles';

const K_ANIMATION_SLIDE_DURATION = 650;

@connect((state) => ({ snackBar: state.notification.snackBar }), {
  actionHideSnackBar: hideSnackBar,
})
export default class SnackBar extends React.PureComponent {
  static propTypes = {
    snackBar: PropTypes.shape({
      message: PropTypes.string,
      position: PropTypes.oneOf([
        basePopupConstants.K_POSITION_TOP,
        basePopupConstants.K_POSITION_BOTTOM,
        basePopupConstants.K_POSITION_LEFT,
        basePopupConstants.K_POSITION_RIGHT,
        basePopupConstants.K_POSITION_CENTER,
        basePopupConstants.K_POSITION_TOP_LEFT,
        basePopupConstants.K_POSITION_TOP_RIGHT,
        basePopupConstants.K_POSITION_TOP_CENTER,
        basePopupConstants.K_POSITION_BOTTOM_LEFT,
        basePopupConstants.K_POSITION_BOTTOM_RIGHT,
        basePopupConstants.K_POSITION_BOTTOM_CENTER,
      ]),
      containerStyle: stylePropTypes,
      snackBarStyle: stylePropTypes,
      textStyle: stylePropTypes,
      duration: PropTypes.oneOf([
        basePopupConstants.K_DURATION_LONG,
        basePopupConstants.K_DURATION_SHORT,
        PropTypes.number,
        null,
      ]),
      iconName: PropTypes.string,
      iconProps: PropTypes.shape(),
      actionButton: PropTypes.shape({
        title: PropTypes.string,
        onPress: PropTypes.func,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
        position: PropTypes.oneOf(['beside', 'bottom']),
        buttonTextStyle: stylePropTypes,
      }),
      animationProps: PropTypes.shape({
        initialValue: PropTypes.number,
        finalValue: PropTypes.number,
      }),
    }),
    actionHideSnackBar: PropTypes.func.isRequired,
  };

  static defaultProps = {
    snackBar: null,
  };

  constructor(props) {
    super(props);
    this.animatedValue = new Animated.Value(
      get(props, 'snackBar.animationProps.initialValue', 0),
    );
  }

  componentDidMount() {
    this.actionShowSnackBarWithAnimate();
  }

  componentDidUpdate(prevProps) {
    const { snackBar } = this.props;

    if (!isEqual(snackBar, prevProps.snackBar) && !isEmpty(snackBar)) {
      this.actionShowSnackBarWithAnimate();
    }
  }

  getSnackBarStyle() {
    const {
      snackBar: { snackBarStyle = null, actionButton },
    } = this.props;

    const snackBarStyles = [styles.snackBar, snackBarStyle];

    const position = get(actionButton, 'position');
    if (position === 'bottom') {
      snackBarStyles.push(styles.stackedSnackBar);
    }

    return snackBarStyles;
  }

  getTextStyle() {
    const {
      snackBar: { textStyle = null },
    } = this.props;
    return [styles.text, textStyle];
  }

  actionHideSnackBarWithAnimate = () => {
    const {
      actionHideSnackBar,
      snackBar: { animationProps },
    } = this.props;

    if (animationProps) {
      const { initialValue } = animationProps;
      Animated.timing(this.animatedValue, {
        toValue: initialValue,
        duration: K_ANIMATION_SLIDE_DURATION,
      }).start(actionHideSnackBar);
    }
  };

  actionShowSnackBarWithAnimate = () => {
    const animationProps = get(this.props, 'snackBar.animationProps', null);
    if (animationProps) {
      const { finalValue } = animationProps;
      Animated.timing(this.animatedValue, {
        toValue: finalValue,
        duration: K_ANIMATION_SLIDE_DURATION,
      }).start();
    }
  };

  renderIcon() {
    const {
      snackBar: { iconName, iconProps },
    } = this.props;

    if (!iconName) return null;

    return (
      <Icon
        name={iconName}
        theme="white"
        size={20}
        style={styles.icon}
        {...iconProps}
      />
    );
  }

  renderActionButton() {
    const {
      snackBar: { actionButton },
    } = this.props;

    if (isEmpty(actionButton)) return null;

    const {
      title,
      onPress,
      to,
      position = 'beside',
      buttonTextStyle,
    } = actionButton;

    const actionButtonStyle = [];
    if (position === 'bottom') {
      actionButtonStyle.push(styles.actionButtonContainerBottom);
    }

    return (
      <ButtonWithText
        title={title}
        onPress={onPress}
        to={to}
        themeType={BUTTON_TYPE.TERTIARY}
        theme={BUTTON_THEME.TOSCA}
        buttonStyle={[cStyles.bgt, styles.actionButton]}
        textStyle={[styles.actionButtonText, buttonTextStyle]}
        hoverableViewStyle={actionButtonStyle}
        small
      />
    );
  }

  render() {
    const { snackBar, actionHideSnackBar } = this.props;
    if (!snackBar) return null;
    const { message, position, duration, animationProps, containerStyle } =
      snackBar;

    const hidePopup = animationProps
      ? this.actionHideSnackBarWithAnimate
      : actionHideSnackBar;

    return (
      <BasePopup
        position={position}
        duration={duration}
        containerStyle={[styles.container, containerStyle]}
        actionHidePopup={hidePopup}
      >
        {animationProps ? (
          <Animated.View
            style={[
              this.getSnackBarStyle(),
              { transform: [{ translateY: this.animatedValue }] },
            ]}
          >
            <View style={styles.iconAndTextContainer}>
              {this.renderIcon()}
              <Text style={this.getTextStyle()}>{message}</Text>
            </View>
            {this.renderActionButton()}
          </Animated.View>
        ) : (
          <View style={this.getSnackBarStyle()}>
            <View style={styles.iconAndTextContainer}>
              {this.renderIcon()}
              <Text style={this.getTextStyle()}>{message}</Text>
            </View>
            {this.renderActionButton()}
          </View>
        )}
      </BasePopup>
    );
  }
}
