import keyMirror from 'keymirror';
import produce from 'immer';

import { ApiClient } from 'types';
import { loadCategoryAreaSeoApi } from './api';
import { CategoryAreaSeo } from './types';

export const AT = keyMirror({
  LOAD_CATEGORY_AREA_SEO: null,
  LOAD_CATEGORY_AREA_SEO_SUCCESS: null,
  LOAD_CATEGORY_AREA_SEO_FAIL: null,
});

export const initialState = {};

const categoryAreaSeoReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case AT.LOAD_CATEGORY_AREA_SEO_SUCCESS: {
      const {
        result,
        payload: { areaSlug, categorySlug },
      } = action;
      draft[`${categorySlug}+${areaSlug}`] = result;
      break;
    }
    case AT.LOAD_CATEGORY_AREA_SEO_FAIL: {
      const {
        payload: { areaSlug, categorySlug },
      } = action;
      draft[`${categorySlug}+${areaSlug}`] = {};
      break;
    }
  }

  return draft;
}, initialState);

export function loadCategoryAreaSeo(areaSlug: string, categorySlug: string) {
  return {
    types: [
      AT.LOAD_CATEGORY_AREA_SEO,
      AT.LOAD_CATEGORY_AREA_SEO_SUCCESS,
      AT.LOAD_CATEGORY_AREA_SEO_FAIL,
    ],
    promise: (client: ApiClient<CategoryAreaSeo>) =>
      loadCategoryAreaSeoApi(client, areaSlug, categorySlug),
    payload: {
      areaSlug,
      categorySlug,
    },
  };
}

export default categoryAreaSeoReducer;
