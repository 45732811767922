import camelize from 'camelize';
import keyMirror from 'keymirror';
import { normalize } from 'normalizr';

import config from 'config';

import { initializeMoodboard } from 'app-libs/redux_modules/entity_modules/moodboard';

import { Schemas } from '../entities';
import asyncStateReducer, {
  initialAsyncState,
} from '../helper_modules/asyncState';

export const AT = keyMirror({
  GET_MOODBOARD: null,
  GET_MOODBOARD_SUCCESS: null,
  GET_MOODBOARD_FAIL: null,

  GET_MOODBOARD_WITH_PRODUCTS: null,
  GET_MOODBOARD_WITH_PRODUCTS_SUCCESS: null,
  GET_MOODBOARD_WITH_PRODUCTS_FAIL: null,

  GET_POSTS: null,
  GET_POSTS_SUCCESS: null,
  GET_POSTS_FAIL: null,

  GET_HOTSPOT: null,
  GET_HOTSPOT_SUCCESS: null,
  GET_HOTSPOT_FAIL: null,
});

const initialState = {
  moodboard: {
    asyncState: initialAsyncState,
    parameters: {},
    results: {
      hits: [],
      page: 0,
    },
  },

  categories: [],

  post: {
    asyncState: initialAsyncState,
    parameters: {},
    results: {
      hits: [],
      page: 0, // We use zero based pagination for this redux-store, although wordpress using 1 based pagination. It has been handled in the reducer
      nbPages: 1,
    },
  },

  hotspot: {
    asyncState: initialAsyncState,
    parameters: {},
    results: {
      hits: [],
      page: 0,
    },
  },
};

export default function browseReducer(
  mutableState = initialState,
  action = {},
) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_MOODBOARD:
    case AT.GET_MOODBOARD_SUCCESS:
    case AT.GET_MOODBOARD_FAIL:
    case AT.GET_MOODBOARD_WITH_PRODUCTS:
    case AT.GET_MOODBOARD_WITH_PRODUCTS_SUCCESS:
    case AT.GET_MOODBOARD_WITH_PRODUCTS_FAIL:
      state = {
        ...state,
        moodboard: browseMoodboardReducer(state.moodboard, action),
      };
      break;
    case AT.GET_HOTSPOT:
    case AT.GET_HOTSPOT_SUCCESS:
    case AT.GET_HOTSPOT_FAIL:
      state = {
        ...state,
        hotspot: browseHotspotReducer(state.hotspot, action),
      };
      break;
    case AT.GET_POSTS:
    case AT.GET_POSTS_SUCCESS:
    case AT.GET_POSTS_FAIL:
      state = {
        ...state,
        post: browsePostReducer(state.post, action),
      };
      break;
    case AT.GET_POPULAR_POSTS:
    case AT.GET_POPULAR_POSTS_SUCCESS:
      state = {
        ...state,
        popularPosts: action.result,
      };
      break;
    case AT.GET_POPULAR_POSTS_FAIL:
      break;
  }
  return state;
}

function browsePostReducer(mutableState = initialState.post, action = {}) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_POSTS:
      state = {
        ...state,
        parameters: action.payload.parameters,
      };
      break;
    case AT.GET_POSTS_SUCCESS:
      state = {
        ...state,
        results: {
          ...state.results,
          hits: [...action.result.content],
          page: action.result.page - 1, // We transform 1-based pagination to zero-based pagination
          nbPages: action.result.nbPages,
        },
      };
      break;
  }

  /** Track async state, with limit to **_POSTS_* */
  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_POSTS_'),
  });
}

function browseMoodboardReducer(
  mutableState = initialState.moodboard,
  action = {},
) {
  let state = mutableState;
  switch (action.type) {
    case AT.GET_MOODBOARDS_RELATED_TO_PRODUCT_SUCCESS:
      state = {
        ...state,
        results: {
          ...state.results,
          hits: [...action.result.content],
          page:
            action.payload && action.payload.query
              ? Number(action.payload.query.page)
              : 0,
        },
      };
      break;
  }

  /** Track async state, with limit to **_MOODBOARDS_* */
  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_MOODBOARDS_'),
  });
  // return state;
}

function browseHotspotReducer(
  mutableState = initialState.hotspot,
  action = {},
) {
  const state = mutableState;
  /** Track async state, with limit to **_HOTSPOTS_* */
  return Object.assign({}, state, {
    asyncState: asyncStateReducer(state.asyncState, action, '_HOTSPOTS'),
  });
}

export function loadMoodboard(moodboardId, callback) {
  return {
    types: [AT.GET_MOODBOARD, AT.GET_MOODBOARD_SUCCESS, AT.GET_MOODBOARD_FAIL],
    promise: (client) =>
      client.get(`${config.API_URL_KLAW}/moodboards/${moodboardId}/`),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.MOODBOARD);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function loadMoodboardWithProducts(moodboardId, fields = ['*']) {
  return {
    types: [
      AT.GET_MOODBOARD_WITH_PRODUCTS,
      AT.GET_MOODBOARD_WITH_PRODUCTS_SUCCESS,
      AT.GET_MOODBOARD_WITH_PRODUCTS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `${config.API_URL_KLAW}/moodboards/${moodboardId}/deep/?format=json`,
        {
          params: {
            fields,
          },
        },
      ),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.MOODBOARD);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export function loadMoodboardWithProductsAndInitialize(moodboardId) {
  return async (dispatch, getState) => {
    await dispatch(loadMoodboardWithProducts(moodboardId));
    const moodboard = getState().entities.moodboards[moodboardId];
    return dispatch(initializeMoodboard(moodboard));
  };
}

export function loadPostsIn(arrOfPostIds, callback) {
  return {
    types: [AT.GET_POSTS, AT.GET_POSTS_SUCCESS, AT.GET_POSTS_FAIL],
    promise: (client) => {
      const promises = [];
      arrOfPostIds.forEach((postId) => {
        promises.push(
          client.get(`${config.API_URL_4}/posts/${postId}`, {
            withoutCredentials: true,
          }),
        );
      });
      return Promise.all(promises);
    },
    payload: { parameters: [] },
    options: {
      transformer: (result) => {
        const normalized = normalize(
          result.map((p) => {
            p.id = p.ID;
            delete p.ID;
            return camelize(p);
          }),
          Schemas.POST_COLLECTION,
        );
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}

export function loadHotspot(hotspotId, callback) {
  return {
    types: [AT.GET_HOTSPOT, AT.GET_HOTSPOT_SUCCESS, AT.GET_HOTSPOT_FAIL],
    promise: (client) =>
      client.get(`${config.API_URL_KLAW}/hotspots/${hotspotId}/`),
    options: {
      transformer: (result) => {
        const normalized = normalize(result, Schemas.HOTSPOT);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
      callback,
    },
  };
}
