import React, { memo } from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import {
  Colors,
  HorizontalDivider,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';

import { useMediaQuery } from 'entities/footer/hooks';

import { ViewStyle } from 'types/styles';

import AppDownloadSection from './AppDownloadSection';
import ConsumerComplaintServiceSection from './ConsumerComplaintServiceSection';
import { FooterSection } from './FooterSection';
import { MediaPartnerSection } from './MediaPartnerSection';
import { PaymentMethodSection } from './PaymentMethodSection';
import SocialMediaSection from './SocialMediaSection';
import { TopCategorySection } from './TopCategorySection';
import USPSection from './USPSection';
import {
  K_FOOTER_DESCRIPTIONS,
  K_FOOTER_SECTION_BUSINESS_UNIT,
  K_FOOTER_SECTION_CONTACT,
  K_FOOTER_SECTION_DEKORUMA,
  K_MOBILE_FOOTER_BREAKPOINT,
} from './constants';

const K_SECTION_WIDTH = 1200;

interface FooterProps {
  categorySectionContainerStyle?: ViewStyle;
}

export const Footer: React.FC<FooterProps> = memo(
  ({ categorySectionContainerStyle }) => {
    const isMobile = useMediaQuery(K_MOBILE_FOOTER_BREAKPOINT);
    return (
      <>
        <TopCategorySection style={categorySectionContainerStyle} />
        <View style={[{ backgroundColor: Colors.C_BLACK_5 }, cStyles.pvm]} />
        <USPSection />
        <View style={isMobile ? s.mContainer : s.container}>
          <View style={isMobile ? s.mFooter : s.footer}>
            <View style={isMobile ? s.mFooterContent : s.footerContent}>
              <FooterSection type={K_FOOTER_SECTION_DEKORUMA} />
              <FooterSection type={K_FOOTER_SECTION_BUSINESS_UNIT} />
              <View style={cStyles.flex1}>
                <FooterSection type={K_FOOTER_SECTION_CONTACT} />
                <SocialMediaSection />
                <ConsumerComplaintServiceSection />
                <AppDownloadSection />
              </View>
            </View>
            <MediaPartnerSection />
            <PaymentMethodSection />
            <HorizontalDivider style={cStyles.mvxxl} />
            <Text theme="h5-black80" style={cStyles.mbxxl}>
              {K_FOOTER_DESCRIPTIONS}
            </Text>
          </View>
        </View>
      </>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.flexMiddle,
    ...cStyles.phxxl,
    backgroundColor: Colors.C_BLACK_5,
  },
  mContainer: {
    ...cStyles.phxl,
    backgroundColor: Colors.C_BLACK_5,
  },
  footer: {
    maxWidth: K_SECTION_WIDTH,
    ...cStyles.width100,
  },
  mFooter: {
    ...cStyles.flexDirCol,
  },

  footerContent: {
    ...cStyles.flexDirRow,
    ...cStyles.width100,
    justifyContent: 'space-between',
  },
  mFooterContent: {
    ...cStyles.flexDirCol,
    ...cStyles.width100,
    justifyContent: 'space-between',
  },
});

export default Footer;
