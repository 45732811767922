import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import {
  K_BEDFRAME_ADDON_SLUG,
  K_ADDON_GROUPS_BY_ADDON_SLUG,
} from 'constants/mattressConstants';
import { K_TRADE_IN_ADDON_SLUG } from 'constants/addonConstants';
import { getAttributeValueByNameOf } from 'app-libs/etc/productAttributesUtil';
import { getProductEntities, getProductOptionEntities } from './entities';

export const getProductOption = (state) => state.productOption;
export const getAddonGroups = (state) =>
  getProductOption(state).addonGroups.groups;

export const getProductAddonTypes = (state) =>
  getProductOption(state).addons.lines;

export const getProductAddonGroups = (state) =>
  getProductOption(state).addonGroups.productAddonGroups;

export const getAddonGroupsOfProduct = createSelector(
  getProductAddonGroups,
  (productAddonGroups) => productAddonGroups.map((pag) => pag.addonGroup),
);

/**
 * getProductOptionValueByUpcFromProductOptionEntities must return productOptionValue object by param product upc
 * @param  state
 * @param  {[type: string]} productUpc
 * @return productOptionValue {addons: array, options: array, objectID, upc}
 */
export const getProductOptionValueByUpcFromProductOptionEntities = (
  state,
  productUpc,
) => getProductOptionEntities(state)[productUpc] || {};

export const getProductOptions = (productOptionValue) =>
  productOptionValue.options || [];

export const getProductAddons = (productOptionValue) =>
  productOptionValue.addons || [];

export const getOptionValues = (option) => option.optionValues || [];

export const getProductOptionsWithSelectedValue = (
  state,
  objectID = '',
  selectedOptionValues = [],
) => {
  const productOptionValue = getProductOptionValueByUpcFromProductOptionEntities(
    state,
    objectID,
  );
  const options = getProductOptions(productOptionValue);
  return options.map((option) => {
    const selectedOptionValue = selectedOptionValues.find(
      (currOptionValue) => currOptionValue.optionId === option.id,
    );
    return {
      ...option,
      selectedOptionValue: selectedOptionValue
        ? selectedOptionValue.optionValueId
        : undefined,
    };
  });
};

export const sortOptionValuesByValue = (optionValues) =>
  sortBy(optionValues, (optionValue) => {
    const startingNumberInValue = parseFloat(optionValue.value);
    if (!Number.isNaN(startingNumberInValue)) {
      return startingNumberInValue;
    }
    return optionValue.value;
  });

export const getProductOptionsWithSelectedValueByUpc = (
  state,
  objectID = '',
  attributes = [],
  sorted = true,
) => {
  const productOptionValue = getProductOptionValueByUpcFromProductOptionEntities(
    state,
    objectID,
  );
  let options = getProductOptions(productOptionValue);
  if (sorted) {
    options = options.map((option) => {
      const sortedOptionValues = sortOptionValuesByValue(option.optionValues);
      return { ...option, optionValues: sortedOptionValues };
    });
  }

  /**
   * select the first in case objectID is not found or attribute value from
   * products cannot be found.
   * this behavior might cause bug,
   * However, it is the current behavior in the desktop site and prevent failure in case
   * the current opened product is not in any of the option value and there is no
   * matching attribute from opened product attributes in options
   */
  return options.map((option) => {
    const attributeValue = getAttributeValueByNameOf(attributes, option.name);
    const targetOptionValue =
      option.optionValues.find(
        (optionValue) =>
          optionValue.targetProductUpc === objectID ||
          optionValue.value === attributeValue,
      ) || option.optionValues[0];
    const selectedOptionValue = {
      optionId: option.id,
      optionName: option.name,
      optionValueId: targetOptionValue && targetOptionValue.id,
      optionValueName: targetOptionValue && targetOptionValue.value,
    };
    return {
      ...option,
      selectedOptionValue,
    };
  });
};

export const getProductAddonsWithSelectedValue = (
  state,
  product = {},
  selectedAddonProducts = [],
) => {
  const productOptionValue = getProductOptionValueByUpcFromProductOptionEntities(
    state,
    product.objectID,
  );
  const addons = getProductAddons(productOptionValue);
  const products = getProductEntities(state);
  return addons.map((addon) => {
    const addonProductsWithProductInformation = addon.addonProducts.map(
      (addonProduct) => {
        const { productAddon } = addonProduct;
        return { ...addonProduct, product: products[productAddon] };
      },
    );
    const selectedAddonProduct = selectedAddonProducts.find(
      (currAddonValue) => currAddonValue.addonId === addon.id,
    );
    return {
      ...addon,
      addonProducts: addonProductsWithProductInformation,
      selectedProductUpc: selectedAddonProduct
        ? selectedAddonProduct.productUpc
        : undefined,
      selectedProduct: selectedAddonProduct
        ? selectedAddonProduct.product
        : undefined,
      selectedOptionValues: selectedAddonProduct
        ? selectedAddonProduct.optionValues
        : undefined,
    };
  });
};

/** *******************
 *
 * - if option already have optionValue that has been selected, use it as initial value.
 * - if option has optionValue with replacementProductUpc with current product page, use it as initial value
 * - other than that, use first optionValue as initialValue
 */
export function getInitialValueOptionValue(
  option,
  optionValueIdByOptionId = {},
  productUpc = '',
) {
  const optionValueWithCurrentProduct = getOptionValues(option).find(
    (optionValue) => optionValue.targetProductUpc === productUpc,
  );
  if (optionValueWithCurrentProduct) {
    return optionValueWithCurrentProduct.id;
  }

  if (optionValueIdByOptionId && option.id in optionValueIdByOptionId) {
    return optionValueIdByOptionId[option.id];
  }

  const firstOptionValueWithoutTargetProductUpc = getOptionValues(option).find(
    (optionValue) => !has(optionValue, 'targetProductUpc'),
  );

  if (firstOptionValueWithoutTargetProductUpc) {
    return firstOptionValueWithoutTargetProductUpc.id;
  }
  return option.optionValues[0].id;
}

/** **********
 *
 * update selectedProductOptionValues in redux
 * base on param in url and initial value of each product option
 */
export const refreshPairsOptionValue = (
  optionValueIdByOptionId = {},
  productOptionValue = {},
) => {
  if (isEmpty(productOptionValue)) return {};
  const newOptionIdByOptionValueId = {};
  getProductOptions(productOptionValue).forEach((option) => {
    const initialValueOptionValue = getInitialValueOptionValue(
      option,
      optionValueIdByOptionId,
      productOptionValue.upc,
    );
    newOptionIdByOptionValueId[option.id] = initialValueOptionValue;
  });
  return newOptionIdByOptionValueId;
};

/*
 * - serializePairsOptionValue used for change structure pairsOptionValue to colection of optionValue
 * - structure of optionValue is
 *   {
 *     optionId,
 *     optionName,
 *     optionValueId,
 *     optionValueName
 *   }
 */
export function serializePairsOptionValue(
  pairsOptionValue,
  productOptionValue,
) {
  const optionValues = [];
  Object.keys(pairsOptionValue).forEach((optionId) => {
    if (pairsOptionValue[optionId]) {
      const optionValueId = pairsOptionValue[optionId];
      const option = getProductOptions(productOptionValue).find(
        (item) => item.id === parseInt(optionId, 0),
      );
      if (!option) return;
      const optionValue = getOptionValues(option).find(
        (item) => item.id === parseInt(optionValueId, 0),
      );
      if (option && optionValue) {
        optionValues.push({
          optionId: option.id,
          optionName: option.name,
          optionValueId: optionValue.id,
          optionValueName: optionValue.value,
        });
      }
    }
  });
  return optionValues;
}

/**
 * getOptionValuesOfProductByQuery must return array of optionValue object, by optionValueIdByOptionId
 * @param  state
 * @param  optionValueIdByOptionId      {[optionId]: [optionValueId]}
 * @param  productUpc
 * @return optionValues [{optionId, optionName, optionValueId, optionValueName}]
 */
export const getOptionValuesOfProductByQuery = (
  state,
  optionValueIdByOptionId,
  productUpc,
) => {
  const productOptionValue = getProductOptionValueByUpcFromProductOptionEntities(
    state,
    productUpc,
  );
  const refreshedPairsOptionValue = refreshPairsOptionValue(
    optionValueIdByOptionId,
    productOptionValue,
  );
  const optionValues = serializePairsOptionValue(
    refreshedPairsOptionValue,
    productOptionValue,
  );
  return optionValues;
};

export const getQueryProductOption = (props) => {
  if (isEmpty(props)) return {};
  if (!has(props, 'query.productOption')) return {};
  return props.query.productOption;
};

export const getQueryProductAddon = (query) => {
  let productAddons = has(query, 'productAddon') ? query.productAddon : {};

  try {
    productAddons = JSON.parse(productAddons);
  } catch (err) {
    productAddons = {};
  }

  return productAddons;
};

export function serializeAddonProducts(
  addonProducts,
  productOptionValue,
  productOptionValues = [],
  products,
) {
  const serializedAddonProducts = [];
  Object.keys(addonProducts).forEach((addonId) => {
    if (addonProducts[addonId] && !isEmpty(productOptionValue)) {
      const addon = productOptionValue.addons.find(
        (item) => item.id === parseInt(addonId, 0),
      );
      if (!addon) return;
      const addonProduct = addon.addonProducts.find(
        (item) =>
          item.productAddon === addonProducts[addonId].selectedProductUpc,
      );
      if (addon && addonProduct) {
        const currentUpc = addonProduct.productAddon;
        let serializedAddonProduct = {};
        if (productOptionValues[currentUpc]) {
          const newPairsOptionValue = refreshPairsOptionValue(
            addonProducts[addonId].pairsOptionValue,
            productOptionValues[currentUpc],
            currentUpc,
          );
          serializedAddonProduct = {
            addonId: addon.id,
            addonName: addon.name,
            addonSlug: addon.slug,
            productUpc: currentUpc,
            product: products[currentUpc],
            optionValues: serializePairsOptionValue(
              newPairsOptionValue,
              productOptionValues[currentUpc],
            ),
          };
        } else {
          serializedAddonProduct = {
            addonId: addon.id,
            addonName: addon.name,
            addonSlug: addon.slug,
            productUpc: currentUpc,
            product: products[currentUpc],
            optionValues: [],
          };
        }
        serializedAddonProducts.push(serializedAddonProduct);
      }
    }
  });
  return serializedAddonProducts;
}

export const getAddonValuesOfProductByQuery = (
  state,
  addonValueIdByAddonId,
  objectID,
) => {
  const productOptionValue = getProductOptionValueByUpcFromProductOptionEntities(
    state,
    objectID,
  );

  const selectedProductAddons = serializeAddonProducts(
    addonValueIdByAddonId,
    productOptionValue,
    getProductOptionEntities(state),
    getProductEntities(state),
  );
  return selectedProductAddons;
};

export function getAddonBySlug(addons, slug) {
  return addons.find((addon) => addon.slug === slug);
}

export function isContainAddon(addons = [], addonSlug) {
  return getAddonBySlug(addons, addonSlug);
}

export function isServiceAddon(addon) {
  return addon.slug.startsWith('jasa') || addon.slug === K_TRADE_IN_ADDON_SLUG;
}

export function getAddonProductTotalPrice(addonProducts = []) {
  let totalPrice = 0;
  addonProducts.forEach((addonProduct) => {
    if (addonProduct.product) {
      totalPrice +=
        addonProduct.product.priceNumber ||
        parseInt(addonProduct.product.price, 0);
    }
  });
  return totalPrice;
}

export function getSlugsByAddonsWithSelectedValue(
  addonsWithSelectedValue = [],
) {
  // eslint-disable-next-line array-callback-return, consistent-return
  return addonsWithSelectedValue.map((addon) => {
    if (addon.selectedProduct) return addon.slug;
  });
}

export function getGroupsBySelectedAddonSlugs(addonSlugs = []) {
  if (includes(addonSlugs, K_BEDFRAME_ADDON_SLUG)) {
    return K_ADDON_GROUPS_BY_ADDON_SLUG.find((group) =>
      includes(group.addonSlugs, K_BEDFRAME_ADDON_SLUG),
    );
  }
  return K_ADDON_GROUPS_BY_ADDON_SLUG[0];
}

export function isAnyAddonChanged(
  addonProductsInBasket = [],
  selectedAddonProducts = [],
) {
  if (
    Object.keys(addonProductsInBasket).length !==
    Object.keys(selectedAddonProducts).length
  ) {
    return true;
  }

  let isChanged = false;
  selectedAddonProducts.forEach((currSelectedAddonProducts) => {
    const addonProductInBasket = addonProductsInBasket.find(
      (currAddonProductInBasket) =>
        currSelectedAddonProducts.addonId === currAddonProductInBasket.addonId,
    );
    isChanged =
      isChanged ||
      isEmpty(addonProductInBasket) ||
      !isEqual(
        currSelectedAddonProducts.product.objectID,
        addonProductInBasket.productUpc,
      ) ||
      !isEqual(
        sortBy(currSelectedAddonProducts.optionValues, (o) => o.optionId),
        sortBy(addonProductInBasket.optionValues, (o) => o.optionId),
      );
  });
  return isChanged;
}
