import keyMirror from 'keymirror';
import produce from 'immer';
import { normalize } from 'normalizr';
import { ApiClient } from 'types';

import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';

import { loadActivitiesAPI } from './api';
import { Schemas } from './schema';
import { Activity } from './types';

export const AT = keyMirror({
  LOAD_ACTIVITIES: null,
  LOAD_ACTIVITIES_SUCCESS: null,
  LOAD_ACTIVITIES_FAIL: null,

  POST_CREATE_ACTIVITY: null,
  POST_CREATE_ACTIVITY_SUCCESS: null,
  POST_CREATE_ACTIVITY_FAIL: null,

  PATCH_SUBMIT_ACTIVITY_SUBMISSION: null,
  PATCH_SUBMIT_ACTIVITY_SUBMISSION_SUCCESS: null,
  PATCH_SUBMIT_ACTIVITY_SUBMISSION_FAIL: null,
});

export const initialState = {
  activitiesById: {},
  activitiesAsyncState: initialAsyncState,
};

const activityReducer = produce((draft = initialState, action) => {
  draft.activitiesAsyncState = asyncStateReducer(
    draft.activitiesAsyncState,
    action,
    '_ACTIVITIES_',
  );

  switch (action.type) {
    case AT.LOAD_ACTIVITIES_SUCCESS: {
      if (action.result?.entities?.activitiesById) {
        const { entities } = action.result;
        Object.entries(entities.activitiesById).forEach(
          ([idAttribute, value]) => {
            draft.activitiesById[idAttribute] = value;
          },
        );
      }
      break;
    }

    default:
      break;
  }

  return draft;
}, initialState);

export function loadActivities(processKeys: string[]) {
  return {
    types: [
      AT.LOAD_ACTIVITIES,
      AT.LOAD_ACTIVITIES_SUCCESS,
      AT.LOAD_ACTIVITIES_FAIL,
    ],
    promise: (client: ApiClient<Activity[]>) =>
      loadActivitiesAPI(client, processKeys),
    options: {
      transformer: (result: Activity[]) => {
        const normalized = normalize(result, Schemas.ACTIVITY_COLLECTION);
        return {
          content: normalized.result,
          entities: normalized.entities,
        };
      },
    },
  };
}

export default activityReducer;
