// plugin imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View } from 'react-native';
import MediaQuery from 'react-native-web-responsive';

// module imports
import { hideModalGeneric } from 'app-libs/redux_modules/flow_modules/modal';
import { stylePropTypes } from '@dkrm/general-libs/theme/styles';

// local imports
import DesktopModal from './desktopModalGeneric';
import MobileOrTabletModal from './mobileModalGeneric';

// mungkin bisa di pindahin ke util (kalo sering kepake)
export const Desktop = (props) => <MediaQuery {...props} minWidth={992} />;
export const MobileOrTablet = (props) => (
  <MediaQuery {...props} maxWidth={992} />
);

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 *
 * xxxContainerStyle  : to style xxx container (may has a default style)
 *
 * XxxComponent       : to override xxx default component
 *                      XxxComponent will injected 'onCloseModalHandle'
 *
 * onCloseModalHandle : call close modal function
 *
 *
 *  default modal
 * ------------------
 * | X |   1        | header with close button
 * ------------------
 * |                |
 * |       2        |
 * |                | content
 * |                |
 * |                |
 * |                |
 * ------------------
 * |       3        | footer (fixed) with padding
 * ------------------
 *
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */
@connect(
  (state) => ({
    modal: state.modal.modalGenericNew,
  }),
  {
    actionHideModalGeneric: hideModalGeneric,
  },
)
export default class ModalGeneric extends Component {
  static propTypes = {
    modal: PropTypes.shape({
      modalWidth: PropTypes.number.isRequired,
      onCloseModalHandle: PropTypes.func,

      contentContainerStyle: stylePropTypes,
      ContentComponent: PropTypes.element.isRequired,

      headerTitle: PropTypes.string,
      headerContainerStyle: stylePropTypes,
      HeaderComponent: PropTypes.element,

      footerContainerStyle: stylePropTypes,
      FooterComponent: PropTypes.element,
    }),
    actionHideModalGeneric: PropTypes.func.isRequired,
  };

  static defaultProps = {
    modal: {},
  };

  actionHideModal = () => {
    const { modal, actionHideModalGeneric } = this.props;
    actionHideModalGeneric();
    if (modal && modal.onCloseModalHandle) {
      modal.onCloseModalHandle();
    }
  };

  renderDesktopModal(modal) {
    const { onCloseModalHandle, ...rest } = modal;
    return <DesktopModal {...rest} onCloseModalHandle={this.actionHideModal} />;
  }

  renderMobileOrTabletModal(modal) {
    const { modalWidth, onCloseModalHandle, ...rest } = modal;
    return (
      <MobileOrTabletModal
        {...rest}
        onCloseModalHandle={this.actionHideModal}
      />
    );
  }

  render() {
    const { modal } = this.props;
    if (!modal) return null;

    return (
      <View style={[{ zIndex: 100 }]}>
        <Desktop>{this.renderDesktopModal(modal)}</Desktop>
        <MobileOrTablet>{this.renderMobileOrTabletModal(modal)}</MobileOrTablet>
      </View>
    );
  }
}
