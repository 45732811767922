import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  K_LOAD_OFFLINE_STORE_LOCATION_SEO_AREA_QUERY_KEY,
  getLoadOfflineStoreLocationListQueryKey,
  loadOfflineStoreLocationList,
  loadOfflineStoreLocationSEOAreaList,
} from './api';
import { OfflineStoreLocation, OfflineStoreLocationSEOArea } from './types';

const K_ONE_MINUTE_IN_MILLISECONDS = 60000;

const emptyOfflineStoreLocationArray: OfflineStoreLocation[] = [];
const emptyOfflineStoreLocationSEOAreaArray: OfflineStoreLocationSEOArea[] = [];

export const useQueryLoadOfflineStoreLocationList = (areaFilter?: string) => {
  const res = useQuery(
    getLoadOfflineStoreLocationListQueryKey(areaFilter),
    () => loadOfflineStoreLocationList(areaFilter),
    {
      staleTime: 30 * K_ONE_MINUTE_IN_MILLISECONDS,
      cacheTime: 60 * K_ONE_MINUTE_IN_MILLISECONDS,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    return res?.data || emptyOfflineStoreLocationArray;
  }, [res]);

  return {
    ...res,
    data,
  };
};

export const useQueryLoadOfflineStoreLocationSEOAreaList = () => {
  const res = useQuery(
    K_LOAD_OFFLINE_STORE_LOCATION_SEO_AREA_QUERY_KEY,
    loadOfflineStoreLocationSEOAreaList,
    {
      staleTime: 30 * K_ONE_MINUTE_IN_MILLISECONDS,
      cacheTime: 60 * K_ONE_MINUTE_IN_MILLISECONDS,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    return res?.data || emptyOfflineStoreLocationSEOAreaArray;
  }, [res]);

  return {
    ...res,
    data,
  };
};
