import { StyleSheet } from 'react-native';

import colors from '@dkrm/ui-kit-basic/colors';
import cStyles from '@dkrm/general-libs/theme/styles';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  container: {
    ...cStyles.brs,
    ...cStyles.bwam,
    backgroundColor: colors.C_GREY_100,
    borderColor: colors.C_BLACK_10,
  },
  containerVertical: {
    minHeight: 80,
  },
  containerHorizontal: {
    minHeight: 60,
  },

  cell: {
    alignItems: 'center',
  },
  cellHorizontal: {
    ...cStyles.flexDirRow,
  },
  cellHorizontalCentered: {
    ...cStyles.flex1,
  },
  cellVertical: {
    ...cStyles.flexDirCol,
    ...cStyles.flex1,
    justifyContent: 'space-between',
  },
  cellVerticalCentered: {
    justifyContent: 'center',
  },

  labelContainerHorizontal: {
    ...cStyles.flex1,
    ...cStyles.mam,
    ...cStyles.mll,
    justifyContent: 'center',
  },
  labelContainerHorizontalCentered: {
    ...cStyles.tac,
  },
  labelContainerVertical: {
    ...cStyles.tac,
    ...cStyles.mam,
    ...cStyles.mbs,
  },
  labelContainerVerticalCentered: {
    ...cStyles.man,
  },

  label: {
    ...tStyles.h6,
    color: colors.C_SECONDARY_TOSCA_4,
    letterSpacing: 0.05,
  },
});
