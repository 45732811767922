import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { StyleSheet, Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { ArrowForwardIos, ArrowBack } from '@dkrm/icons';

import { getIsShopAssistant } from 'app-libs/redux_modules/selectors/auth';
import { useVisibility } from 'app-libs/hook_modules/visibility';
import { useLocation } from 'app-libs/hook_modules/router';

import cStyles from '@dkrm/general-libs/theme/styles';

import BasketPreview from './BasketPreview';
import { K_ROUTES_WITHOUT_OFFLINE_BASKET_FLOATING_ENTRYPOINT } from './constants';

const OfflineBasketFloatingEntryPoint = memo(() => {
  const [
    shouldShowFloatingEntryPoint,
    actionShowFloatingEntrypoint,
    actionHideFloatingEntrypoint,
  ] = useVisibility();
  const location = useLocation();

  const isShopAssistant = useSelector(getIsShopAssistant);

  if (
    K_ROUTES_WITHOUT_OFFLINE_BASKET_FLOATING_ENTRYPOINT.includes(
      location.pathname,
    )
  ) {
    return null;
  }

  if (!isShopAssistant) {
    return null;
  }

  if (!shouldShowFloatingEntryPoint) {
    return (
      <View style={s.collapsedContainer}>
        <ButtonWrapper onPress={actionShowFloatingEntrypoint}>
          {() => <ArrowForwardIos size={16} />}
        </ButtonWrapper>
      </View>
    );
  }

  return (
    <View style={s.container}>
      <View style={s.horizontalContainer}>
        <ArrowBack size={16} style={s.icon} />
        <Text
          style={s.hideText}
          theme="h5-tosca"
          onPress={actionHideFloatingEntrypoint}
        >
          Sembunyikan
        </Text>
      </View>
      <BasketPreview />
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pvxl,
    ...cStyles.bgw,
    ...cStyles.brs,
    ...cStyles.shadowxl,
    position: 'fixed',
    bottom: 17,
    right: 18,
    left: 18,
    paddingHorizontal: 20,
  },
  horizontalContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mbxxl,
    alignItems: 'center',
  },
  hideText: { lineHeight: 15 },
  icon: { ...cStyles.mrm },
  collapsedContainer: {
    ...cStyles.pvxxl,
    ...cStyles.bgw,
    ...cStyles.shadowxl,
    ...cStyles.flexMiddle,
    position: 'fixed',
    bottom: 100,
    left: 0,
    paddingHorizontal: 20,
  },
});

export default OfflineBasketFloatingEntryPoint;
