import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, View } from '@dkrm/ui-kit-basic';

import BasePopup from 'app-libs/components/Notification/BasePopup';
import { hideToast } from 'app-libs/redux_modules/flow_modules/notification';

import * as basePopupConstants from '../BasePopup/constants';
import styles from './styles';

@connect((state) => ({ toast: state.notification.toast }), {
  actionHideToast: hideToast,
})
export default class Toast extends React.PureComponent {
  static propTypes = {
    toast: PropTypes.shape({
      message: PropTypes.string,
      position: PropTypes.oneOf([
        basePopupConstants.K_POSITION_TOP,
        basePopupConstants.K_POSITION_BOTTOM,
        basePopupConstants.K_POSITION_LEFT,
        basePopupConstants.K_POSITION_RIGHT,
        basePopupConstants.K_POSITION_CENTER,
        basePopupConstants.K_POSITION_TOP_LEFT,
        basePopupConstants.K_POSITION_TOP_RIGHT,
        basePopupConstants.K_POSITION_TOP_CENTER,
        basePopupConstants.K_POSITION_BOTTOM_LEFT,
        basePopupConstants.K_POSITION_BOTTOM_RIGHT,
        basePopupConstants.K_POSITION_BOTTOM_CENTER,
      ]),
      containerStyle: stylePropTypes,
      toastStyle: stylePropTypes,
      textStyle: stylePropTypes,
      duration: PropTypes.oneOf([
        basePopupConstants.K_DURATION_LONG,
        basePopupConstants.K_DURATION_SHORT,
        PropTypes.number,
        null,
      ]),
    }),
    actionHideToast: PropTypes.func.isRequired,
  };

  static defaultProps = {
    toast: null,
  };

  getToastStyle() {
    const {
      toast: { toastStyle = null },
    } = this.props;
    return [styles.toast, toastStyle];
  }

  getTextStyle() {
    const {
      toast: { textStyle = null },
    } = this.props;
    return [styles.text, textStyle];
  }

  render() {
    const { toast, actionHideToast } = this.props;
    if (!toast) return null;
    const { message, position, duration, containerStyle } = toast;

    return (
      <BasePopup
        position={position}
        duration={duration}
        containerStyle={[styles.container, containerStyle]}
        actionHidePopup={actionHideToast}
      >
        <View style={this.getToastStyle()}>
          <Text theme="body4" style={this.getTextStyle()}>
            {message}
          </Text>
        </View>
      </BasePopup>
    );
  }
}
