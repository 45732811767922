import isEmpty from 'lodash/isEmpty';

import { K_TABLET_MAX_WIDTH } from '@dkrm/ui-kit-v2/Styles';

import { catContextual } from 'app-libs/etc/categoryHelper';

import { K_MATTRESS_CATEGORY_BREADCRUMB } from 'constants/mattressConstants';
import { K_PRODUCT_CHILDREN_KEYS } from 'constants/productConstants';

export function isMattressPage(breadcrumb) {
  return breadcrumb.startsWith(K_MATTRESS_CATEGORY_BREADCRUMB);
}

export function isFurnitureCategory(categories) {
  return categories[0].startsWith('Furniture');
}

export function isBedroomPage(breadcrumb) {
  if (breadcrumb.startsWith('Perlengkapan Kamar Tidur')) return true;
  return false;
}

export function isSubCategoryOfBedroom(breadcrumb) {
  return breadcrumb.startsWith('Perlengkapan Kamar Tidur >');
}

export function isCushionPage(breadcrumb) {
  if (
    breadcrumb.startsWith('Dekor > Bantal Dekorasi > Bantal Sofa & Cushion')
  ) {
    return true;
  }
  if (
    breadcrumb.startsWith(
      'Dekor > Bantal Dekorasi > Sarung Bantal Sofa & Cushion',
    )
  ) {
    return true;
  }
  return false;
}

export function isFurnitureProduct(product) {
  return !isEmpty(product.extension);
}

export default function getDisjunctiveFacets(breadcrumb) {
  let disjunctiveFacets = [];

  // For all products
  const disjunctiveFacetsForAllProducts = [
    'brand',
    'color',
    'special',
    'vouchers.code',
    'productOffer.offer.campaign.name',
    'productOffer.discount',
    'filteringAttributes.aMaterial',
    'categories',
  ];
  disjunctiveFacets = [
    ...disjunctiveFacets,
    ...disjunctiveFacetsForAllProducts,
  ];

  // For specific breadcrumb
  let disjunctiveFacetsForBreadcrumb = [];
  if (isMattressPage(breadcrumb)) {
    disjunctiveFacetsForBreadcrumb = [
      'filteringAttributes.aMaterial',
      'filteringAttributes.aFirmnessMatras',
      'filteringAttributes.aLebarMatras',
      'filteringAttributes.aKategoriMatras',
      'filteringAttributes.aTipeProduk',
    ];
  } else if (isBedroomPage(breadcrumb)) {
    disjunctiveFacetsForBreadcrumb = [
      'filteringAttributes.aPattern',
      'filteringAttributes.aSizeType',
      'filteringAttributes.aQuality',
    ];
  } else if (isCushionPage(breadcrumb)) {
    disjunctiveFacetsForBreadcrumb = [
      'filteringAttributes.aPattern',
      'lengthWidthDimension',
      // 'filteringAttributes.aSizeType',
      // 'filteringAttributes.aQuality',
    ];
  } else if (!isEmpty(catContextual[breadcrumb])) {
    disjunctiveFacetsForBreadcrumb = [...catContextual[breadcrumb].values];
  }
  disjunctiveFacets = [...disjunctiveFacets, ...disjunctiveFacetsForBreadcrumb];
  return disjunctiveFacets;
}

export function getProductChildrenKeysByBreadcrumb(breadcrumb) {
  if (isMattressPage(breadcrumb)) {
    return ['siblingIds'];
  }
  return K_PRODUCT_CHILDREN_KEYS;
}

export const getIsDesktopPage = () => {
  return window.innerWidth > K_TABLET_MAX_WIDTH;
};
