import { createStore, applyMiddleware, compose } from 'redux';
import debounce from 'lodash/debounce';
import { setDimension } from 'app-libs/redux_modules/helper_modules/dimension';
import { batchedSubscribe } from 'redux-batched-subscribe';
import createMiddleware from './clientMiddleware';

export default function createApiClientStore(client, data, reducer) {
  const middleware = createMiddleware(client);
  let finalCreateStore;
  if (__DEVELOPMENT__ && __CLIENT__ && __DEVTOOLS__) {
    const { devTools, persistState } = require('redux-devtools');
    finalCreateStore = compose(
      devTools(),
      persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)),
    )(createStore);
  } else if (__DEVELOPMENT__ && __CLIENT__) {
    const createLogger = require('redux-logger');
    const loggerMiddleware = createLogger({
      // We transform the state before printed such that
      // it print ONLY the `current object we are working on`
      // transformer: state => {
      //   return {
      //     browse: state.browse,
      //     moodboard: state.moodboard,
      //     entities: state.entities,
      //   };
      // }
    });

    /* eslint-disable no-underscore-dangle, indent */
    const composeEnhancers =
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // TODO: Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
            // Prevent recomputing reducers for `replaceReducer`
            shouldHotReload: false,
          })
        : compose;
    /* eslint-enable */

    finalCreateStore = composeEnhancers(
      applyMiddleware(middleware, loggerMiddleware),
      batchedSubscribe(debounce((notify) => notify())),
    )(createStore);
    // finalCreateStore = applyMiddleware(middleware, diffLoggerMiddleware)(createStore);
  } else {
    finalCreateStore = compose(
      applyMiddleware(middleware),
      batchedSubscribe(debounce((notify) => notify())),
    )(createStore);
  }

  const store = finalCreateStore(reducer, data);
  store.client = client;

  if (__CLIENT__) {
    const onResize = (event) => {
      const { innerHeight, innerWidth } = event.target;
      store.dispatch(setDimension({ height: innerHeight, width: innerWidth }));
    };

    window.addEventListener('resize', debounce(onResize, 100));
  }

  return store;
}
