import PropTypes from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import CheckBoxRow from '@dkrm/ui-kit-basic/CheckBox/CheckBoxRow';

export default class SimpleFilterItem extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    count: PropTypes.number,
    selected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    containerStyle: stylePropTypes,
    titleStyle: stylePropTypes,
    rightComponent: PropTypes.node,
  };

  static defaultProps = {
    count: 0,
    containerStyle: null,
    titleStyle: null,
    rightComponent: null,
  };

  render() {
    const {
      label,
      selected,
      count,
      containerStyle,
      onChange,
      value,
      titleStyle,
      rightComponent,
    } = this.props;
    const labelWithCount = count ? `${label} (${count})` : label;
    return (
      <CheckBoxRow
        rightComponent={rightComponent}
        checked={selected}
        title={labelWithCount}
        titleStyle={titleStyle}
        onChange={() => onChange(value)}
        containerStyle={[cStyles.phl, cStyles.pvm, containerStyle]}
      />
    );
  }
}
