import { RootStateOrAny } from 'react-redux';
import { createSelector } from 'reselect';
import { AddonFromAlgolia } from 'app-libs/components/Product/types';

export const getProductOptionEntities = (state: RootStateOrAny) =>
  state.entities.productOptions;

export const getProductOptionByUpc = (state: RootStateOrAny, upc: string) =>
  state.entities.productOptions[upc] || null;

export const makeGetInstallationAddonByUpcSelector = () =>
  createSelector(
    (state: RootStateOrAny, upc: string) => getProductOptionByUpc(state, upc),
    (productOption) => {
      const addons: AddonFromAlgolia[] | null = productOption?.addons || null;

      const installationAddon: AddonFromAlgolia | null =
        (addons || []).find(({ name }) => name.startsWith('Jasa')) || null;

      return installationAddon;
    },
  );
