import { useEffect, useState } from 'react';

export const useMediaQuery = (breakpoint: number) => {
  const isServer =
    typeof window === 'undefined' || typeof window.matchMedia === 'undefined';

  const mediaQuery = isServer
    ? null
    : window?.matchMedia(`(max-width: ${breakpoint}px)`);

  const [match, setMatch] = useState(!!mediaQuery?.matches);

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery?.matches);
    // eslint-disable-next-line no-unused-expressions
    mediaQuery?.addEventListener?.('change', handler);
    return () => mediaQuery?.removeEventListener?.('change', handler);
  }, [mediaQuery]);

  return match;
};
