import { useMemo } from 'react';
import {
  OfflineStoreLocation,
  OfflineStoreLocationSEOArea,
} from 'entities/offlineStore/types';

export const useOfflineStoreLocationListSectionData = (
  offlineStoreLocations: OfflineStoreLocation[],
  offlineStoreSEOAreas: OfflineStoreLocationSEOArea[],
) => {
  const offlineStoreLocationsToShow = useMemo(() => {
    return offlineStoreLocations.filter(
      (storeLocation) => storeLocation.seoAreas.length > 0,
    );
  }, [offlineStoreLocations]);

  const offlineStoreLocationsBySEOArea = useMemo(() => {
    const _offlineStoresBySEOArea: Record<string, OfflineStoreLocation[]> = {};
    offlineStoreLocationsToShow.forEach((offlineStore) => {
      const seoArea = offlineStore.seoAreas[0]?.areaName;
      if (!(seoArea in _offlineStoresBySEOArea)) {
        _offlineStoresBySEOArea[seoArea] = [];
      }
      _offlineStoresBySEOArea[seoArea].push(offlineStore);
    });

    return _offlineStoresBySEOArea;
  }, [offlineStoreLocationsToShow]);

  const offlineStoreSEOAreaRankByName = useMemo(() => {
    const _offlineStoreSEOAreaRankByName: Record<string, number> = {};
    offlineStoreSEOAreas.forEach((seoArea) => {
      _offlineStoreSEOAreaRankByName[seoArea.areaName] = seoArea.rank;
    });

    return _offlineStoreSEOAreaRankByName;
  }, [offlineStoreSEOAreas]);

  const sectionData = useMemo(() => {
    return Object.entries(offlineStoreLocationsBySEOArea)
      .map(([area, offlineStores]) => ({
        title: area,
        data: offlineStores,
      }))
      .sort(
        (a, b) =>
          (offlineStoreSEOAreaRankByName[a.title] ?? Infinity) -
          (offlineStoreSEOAreaRankByName[b.title] ?? Infinity),
      );
  }, [offlineStoreLocationsBySEOArea, offlineStoreSEOAreaRankByName]);

  return sectionData;
};
