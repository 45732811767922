import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Product, ProductStockRecord } from 'app-libs/components/Product/types';
import { getStockRecordDetailedPriceByZone } from 'app-libs/etc/productHelperV2';
import { MarketplaceProfile } from 'app-libs/redux_modules/auth/types';
import {
  getCurrentLocation,
  getCurrentLocationPriceZoneCode,
} from 'app-libs/redux_modules/entity_modules/location/selectors';
import {
  getAllProductShippingCharge,
  getProductStockRecordByStockRecordId,
  getShippingChargeByStockRecordIdAndCity,
  getShopAssistantAvailableStockRecordBasedOnPriority,
} from 'app-libs/redux_modules/entity_modules/selectors/product';
import {
  getAuthMarketplaceProfile,
  getIsUserAbleToViewRegionalHubStockRecords,
} from 'app-libs/redux_modules/selectors/auth';

import { ProductShippingCharge } from 'entities/shipping/types';

export const useProductStockRecord = (
  product: Product,
): ProductStockRecord | {} => {
  const marketplaceProfile: MarketplaceProfile = useSelector(
    getAuthMarketplaceProfile,
  );

  const isUserAbleToViewRegionalHubStockRecords = useSelector(
    getIsUserAbleToViewRegionalHubStockRecords,
  );

  if (isUserAbleToViewRegionalHubStockRecords) {
    const storeCode = marketplaceProfile?.currentStoreLocation?.code ?? '';
    const storeRoutingZoneCode =
      marketplaceProfile?.currentStoreLocation?.routingZoneCode ?? '';

    return getShopAssistantAvailableStockRecordBasedOnPriority(
      product,
      storeCode,
      storeRoutingZoneCode,
    );
  }

  return getProductStockRecordByStockRecordId(product, product.stockRecordId);
};

export const useProductPrice = (stockRecord: ProductStockRecord | {}) => {
  const zoneCode = useSelector(getCurrentLocationPriceZoneCode);

  if (stockRecord && Object.keys(stockRecord).length > 0) {
    return getStockRecordDetailedPriceByZone(
      stockRecord as ProductStockRecord,
      zoneCode,
    );
  }

  // should not return this line, just for safety if there is error in product data
  return {
    price: 0,
    priceAfterDiscount: 0,
    discountPercentage: 0,
  };
};

export const useCurrentShippingCharge = (
  stockRecord: ProductStockRecord,
): ProductShippingCharge => {
  const reduxShippingCharge = useSelector(getAllProductShippingCharge);
  const { city: selectedCity } = useSelector(getCurrentLocation);

  const currentShippingCharge = useMemo(() => {
    return getShippingChargeByStockRecordIdAndCity(
      reduxShippingCharge,
      stockRecord.id,
      selectedCity,
    );
  }, [reduxShippingCharge, selectedCity, stockRecord.id]);

  return currentShippingCharge;
};
