/* eslint-disable global-require */
if (process.env.RAZZLE_DOCKER_ENV === 'test') {
  module.exports = require('./config.docker.test');
} else if (process.env.RAZZLE_IS_STAGING === '1') {
  module.exports = require('./config.staging');
} else if (process.env.NODE_ENV === 'production') {
  module.exports = require('./config.production');
} else {
  module.exports = require('./config.development');
}
