import React, { memo } from 'react';

import NavbarWithLogo from 'app-libs/components/NavbarImplementation/NavbarWithLogo';

import { K_INTERIOR_LOGO_V3_IMAGE_URL } from 'constants/assetConstants';
import { K_ROUTE_DESIGN_BUILD } from 'constants/routeConstants';

const K_LOGO_WIDTH = 200;
const K_LOGO_HEIGHT = 36;

const NavbarWithDnbLogo: React.FC = memo((props) => {
  return (
    <NavbarWithLogo
      logoImageUrl={K_INTERIOR_LOGO_V3_IMAGE_URL}
      logoButtonUrl={K_ROUTE_DESIGN_BUILD}
      logoLinkProps={{ isUsingAnchor: true }}
      logoWidth={K_LOGO_WIDTH}
      logoHeight={K_LOGO_HEIGHT}
      {...props}
    />
  );
});

export default NavbarWithDnbLogo;
