import PropTypes from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import styles from './styles';

const K_CONTENT_DIRECTION_HORIZONTAL = 'horizontal';
const K_CONTENT_DIRECTION_VERTICAL = 'vertical';

export default class CategoryCard extends React.PureComponent {
  static propTypes = {
    contentDirection: PropTypes.oneOf([
      K_CONTENT_DIRECTION_HORIZONTAL,
      K_CONTENT_DIRECTION_VERTICAL,
    ]),
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    onPress: PropTypes.func,
    containerStyle: stylePropTypes,
    labelStyle: stylePropTypes,
    cellStyle: stylePropTypes,
    imageUri: PropTypes.string,
    imageStyle: stylePropTypes,
    buttonProps: PropTypes.shape(),
  };

  static defaultProps = {
    contentDirection: K_CONTENT_DIRECTION_HORIZONTAL,
    onPress: () => null,
    containerStyle: null,
    labelStyle: null,
    cellStyle: null,
    imageUri: null,
    imageStyle: null,
    buttonProps: null,
  };

  isVerticalContentDirection() {
    const { contentDirection } = this.props;

    return contentDirection === K_CONTENT_DIRECTION_VERTICAL;
  }

  handlePress = () => {
    const { onPress, label, value } = this.props;

    onPress(label, value);
  };

  renderLabel() {
    const { labelStyle, label, imageUri } = this.props;

    const labelContainer = [];
    if (this.isVerticalContentDirection()) {
      labelContainer.push(styles.labelContainerVertical);
      if (!imageUri) labelContainer.push(styles.labelContainerVerticalCentered);
    } else {
      labelContainer.push(styles.labelContainerHorizontal);
      if (!imageUri)
        labelContainer.push(styles.labelContainerHorizontalCentered);
    }

    return (
      <View style={labelContainer}>
        <Text style={[styles.label, labelStyle]}>{label}</Text>
      </View>
    );
  }

  renderCategoryPicture() {
    const { label, imageUri, imageStyle } = this.props;

    if (!imageUri) return null;

    let imageSize;
    let resizeMode;
    if (this.isVerticalContentDirection()) {
      imageSize = { width: 158, height: 31 };
      resizeMode = 'contain';
    } else {
      imageSize = { width: 115, height: 58 };
      resizeMode = 'cover';
    }

    return (
      <View style={cStyles.flexDirRow}>
        <SmartImage
          style={[imageSize, imageStyle]}
          source={imageUri || ''}
          width={imageSize.width}
          height={imageSize.height}
          additionalQuery={{ fit: 'bounds' }}
          resizeMode={resizeMode}
          accessibilityLabel={label}
        />
      </View>
    );
  }

  renderCell = () => {
    const { cellStyle, imageUri } = this.props;

    const cellContainer = [styles.cell];
    if (this.isVerticalContentDirection()) {
      cellContainer.push(styles.cellVertical);
      if (!imageUri) cellContainer.push(styles.cellVerticalCentered);
    } else {
      cellContainer.push(styles.cellHorizontal);
      if (!imageUri) cellContainer.push(styles.cellHorizontalCentered);
    }

    return (
      <View style={[cellContainer, cellStyle]}>
        {this.renderLabel()}
        {this.renderCategoryPicture()}
      </View>
    );
  };

  render() {
    const { linkUrl, containerStyle, buttonProps } = this.props;

    const buttonStyle = [styles.container];
    if (this.isVerticalContentDirection()) {
      buttonStyle.push(styles.containerVertical);
    } else {
      buttonStyle.push(styles.containerHorizontal);
    }

    return (
      <ButtonWrapper
        to={linkUrl}
        onPress={this.handlePress}
        style={{ flex: 1 }}
        hoverableViewStyle={[buttonStyle, containerStyle]}
        {...buttonProps}
      >
        {this.renderCell}
      </ButtonWrapper>
    );
  }
}
