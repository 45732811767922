import keyMirror from 'keymirror';

import { ShippingAreaFromGoblin } from 'app-libs/redux_modules/flow_modules/area/types';

export const AT = keyMirror({
  SET_CURRENT_LOCATION: null,
});

export interface LocationAction {
  type?: typeof AT.SET_CURRENT_LOCATION;
  payload?: LocationState;
}

export interface LocationState extends ShippingAreaFromGoblin {
  isCurrentLocationSavedFromUserAction: boolean;
  isInitial: boolean;
  _persist?: {
    version: number;
    rehydrated: boolean;
  };
}
