import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

import NavbarWithDnbLogoV2 from 'app-libs/components/NavbarImplementation/NavbarWithDnbLogoV2';

export default class NavbarProjectDetail extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <View style={cStyles.flex1}>
        <NavbarWithDnbLogoV2 shouldShowShareButton />
        {children}
      </View>
    );
  }
}
