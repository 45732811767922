import { Product } from 'app-libs/components/Product/types';
import { getStickerImageUrl } from 'app-libs/etc/productAttributesUtil';
import { getProductBundleOfferSticker } from 'app-libs/etc/productHelper';

export const getProductSticker = (product: Product) => {
  const { productStickerData } = product;
  if (productStickerData) {
    return {
      primarySticker: productStickerData.primary,
      secondarySticker: productStickerData.secondary,
    };
  }

  // @todo(dika) 18 Oct 2023: Fully use BE data, deadline 1 Nov 2023
  // Currently not all products is reindexed, therefore
  // still using logic fallback from FE
  const stickerUrl = getStickerImageUrl(product);
  const bundleOfferSticker = getProductBundleOfferSticker(product);

  if (stickerUrl && bundleOfferSticker) {
    return {
      primarySticker: stickerUrl,
      secondarySticker: bundleOfferSticker,
    };
  }

  if (!stickerUrl && bundleOfferSticker) {
    return {
      primarySticker: bundleOfferSticker,
      secondarySticker: '',
    };
  }

  return {
    primarySticker: stickerUrl,
    secondarySticker: '',
  };
};
