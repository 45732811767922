import React, { memo } from 'react';

import { StyleSheet, View, Link } from '@dkrm/ui-kit-basic';
import cStyles from '@dkrm/general-libs/theme/styles';

import config from 'config';
import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

const K_SOCIAL_MEDIA_LINKS = [
  {
    name: 'instagram',
    to: 'https://www.instagram.com/dekoruma',
  },
  {
    name: 'facebook',
    to: 'https://www.facebook.com/dekoruma',
  },
  {
    name: 'pinterest',
    to: 'https://id.pinterest.com/dekoruma',
  },
  {
    name: 'youtube',
    to: 'https://www.youtube.com/channel/UChTzNjQZNl71M1atn_Rn0mQ',
  },
  {
    name: 'linkedin',
    to: 'https://www.linkedin.com/company/dekoruma',
  },
  {
    name: 'tiktok',
    to: 'https://www.tiktok.com/@dekoruma',
  },
];

const K_ICON_SIZE = 20;

const SocialMediaSection: React.FC = memo(() => (
  <View style={s.socialMediaLinkContainer}>
    {K_SOCIAL_MEDIA_LINKS.map(({ to, name }, index) => (
      <Link key={index} to={to} style={{ paddingRight: 20 }} isUsingAnchor>
        <SmartImage
          source={`${config.API_URL_MEDIA_CDN}/homepage/footer/logo/social/${name}.png?auto=webp`}
          width={K_ICON_SIZE}
          height={K_ICON_SIZE}
          additionalQuery={{
            fit: 'bounds',
          }}
        />
      </Link>
    ))}
  </View>
));

const s = StyleSheet.create({
  socialMediaLinkContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.mvxl,
  },
});

export default SocialMediaSection;
