import keyMirror from 'keymirror';

export const X = keyMirror({
  MOBILE_PRODUCT_DETAIL_INSTALLMENT: null,
  MOBILE_PRODUCT_DETAIL_INSTALLMENT_A__CICILAN_3_BULAN: null,
  MOBILE_PRODUCT_DETAIL_INSTALLMENT_B__CICILAN_12_BULAN: null,

  MOBILE_CHECKOUT_SHIPPING_ADDRESS_PAYMENT_METHOD: null,
  MOBILE_CHECKOUT_SHIPPING_ADDRESS_PAYMENT_METHOD_A__CART: null,
  MOBILE_CHECKOUT_SHIPPING_ADDRESS_PAYMENT_METHOD_B__REVIEW: null,

  MOBILE_PRODUCT_DETAIL_PRODUCT_OPTION: null,
  MOBILE_PRODUCT_DETAIL_PRODUCT_OPTION_A__BUTTON_GROUP: null,
  MOBILE_PRODUCT_DETAIL_PRODUCT_OPTION_B__MODAL_SELECTION: null,

  HOUSE_FOOTER_BAR_BUTTON_TEXT: null,
  HOUSE_FOOTER_BAR_BUTTON_TEXT_A__PRICE_LIST: null,
  HOUSE_FOOTER_BAR_BUTTON_TEXT_B__CONTACT_US: null,
});

/**
 * Add new experiments here:
 * 1. exp[X.EXPERIMENT_NAME] = [
 *      {name: K.EXPERIMENT_NAME_A__VARIANT_NAME_1, weight: 7},
 *      {name: K.EXPERIMENT_NAME_B__VARIANT_NAME_2, weight: 3},
 *    ]
 */

const exp = {};

// exp[X.BANNER_CAMPAIGN_SOFA_MTO] = [
//   { name: X.BANNER_CAMPAIGN_SOFA_MTO_A__10_HARI },
//   { name: X.BANNER_CAMPAIGN_SOFA_MTO_B__DISKON },
// ];

exp[X.HOUSE_FOOTER_BAR_BUTTON_TEXT] = [
  { name: X.HOUSE_FOOTER_BAR_BUTTON_TEXT_A__PRICE_LIST },
  { name: X.HOUSE_FOOTER_BAR_BUTTON_TEXT_B__CONTACT_US },
];

/**
 * variant A (control):
 * variant B:
 * Expected impact:
 */

export default exp;
