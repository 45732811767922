import PropTypes from 'prop-types';
import React from 'react';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import {
  HoverableView,
  Link,
  TouchableNativeFeedbackOpacity,
} from '@dkrm/ui-kit-basic';
import { BUTTON_STATE } from '@dkrm/ui-kit-basic/v2/Button/constants';

@withRouter
export default class Button extends React.PureComponent {
  static propTypes = {
    /* button text */
    small: PropTypes.bool,
    /* general button */
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    /* link props */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    linkProps: PropTypes.shape({
      shouldOpenNewTab: PropTypes.bool,
      isUsingAnchor: PropTypes.bool,
    }),
    id: PropTypes.string,
    anchor: PropTypes.bool,
    children: PropTypes.func.isRequired,

    hoverableViewStyle: stylePropTypes,

    history: PropTypes.shape(),
  };

  static defaultProps = {
    small: false,
    onPress: null,
    disabled: false,
    selected: false,
    to: '',
    anchor: false,
    linkProps: {
      shouldOpenNewTab: false,
      buttonInitialState: false,
      isUsingAnchor: false,
    },
    id: undefined,
    hoverableViewStyle: undefined,
    history: undefined,
  };

  constructor(props) {
    super(props);

    let buttonInitialState = BUTTON_STATE.DEFAULT;
    if (props.selected) buttonInitialState = BUTTON_STATE.SELECTED;
    if (props.disabled) buttonInitialState = BUTTON_STATE.DISABLED;
    this.state = { currentButtonState: buttonInitialState };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.currentButtonState === BUTTON_STATE.HOVER &&
      !nextProps.disabled &&
      !nextProps.selected
    ) {
      return null;
    }

    let currentButtonState = BUTTON_STATE.DEFAULT;
    if (nextProps.selected) {
      currentButtonState = BUTTON_STATE.SELECTED;
    } else if (nextProps.disabled) {
      currentButtonState = BUTTON_STATE.DISABLED;
    }

    return {
      prevButtonState: prevState.prevButtonState,
      currentButtonState,
    };
  }

  getModifiedProps() {
    const { id, ...rest } = this.props;
    return {
      ...rest,
      nativeID: id,
    };
  }

  handleButtonHover = () => {
    this.setState((oldState) => ({
      prevButtonState: oldState.currentButtonState,
      currentButtonState: BUTTON_STATE.HOVER,
    }));
  };

  handleButtonHoverLeave = () => {
    this.setState((oldState) => ({
      currentButtonState: oldState.prevButtonState || BUTTON_STATE.DEFAULT,
    }));
  };

  handleButtonPress = (event) => {
    const { onPress } = this.props;

    if (onPress) {
      onPress(event);
    }
  };

  wrapWithLinkView(buttonComponent) {
    const { to, anchor, disabled, linkProps } = this.props;
    if (to) {
      return (
        <Link
          {...linkProps}
          disabled={disabled}
          to={to}
          isUsingAnchor={anchor || linkProps.isUsingAnchor}
          showUnderlineOnHover={false}
        >
          {buttonComponent}
        </Link>
      );
    }
    return buttonComponent;
  }

  wrapWithTouchableView(buttonComponent) {
    const {
      small,
      onPress,
      disabled,
      selected,
      to,
      anchor,
      children,
      ...rest
    } = this.getModifiedProps();

    const { currentButtonState } = this.state;

    if (currentButtonState === BUTTON_STATE.DISABLED) {
      return buttonComponent;
    }

    return (
      <TouchableNativeFeedbackOpacity
        onPress={this.handleButtonPress}
        {...rest}
      >
        {buttonComponent}
      </TouchableNativeFeedbackOpacity>
    );
  }

  wrapWithHoverableView(buttonComponent) {
    const { hoverableViewStyle } = this.props;
    const { currentButtonState } = this.state;

    if (currentButtonState === BUTTON_STATE.DISABLED) {
      return buttonComponent;
    }

    return (
      <HoverableView
        onMouseEnter={this.handleButtonHover}
        onMouseLeave={this.handleButtonHoverLeave}
        style={hoverableViewStyle}
      >
        {buttonComponent}
      </HoverableView>
    );
  }

  render() {
    const { children } = this.props;
    const { currentButtonState } = this.state;

    const buttonComponent = children(currentButtonState);
    const touchableButton = this.wrapWithTouchableView(buttonComponent);
    const hoverableButton = this.wrapWithHoverableView(touchableButton);
    const linkButton = this.wrapWithLinkView(hoverableButton);
    return linkButton;
  }
}
