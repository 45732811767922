import React, { memo, useMemo } from 'react';

import { StyleSheet, Text, View, cStyles } from '@dkrm/ui-kit-v2';

import { unslugify } from 'app-libs/etc';
import { useParams } from 'app-libs/hook_modules/router';

const CategoryPageFooter: React.FC = memo(() => {
  const { lvl0 = '' } = useParams<Record<string, string>>();

  const categoryString = unslugify(lvl0);

  const footerTitle = `Beli ${categoryString} Aman dan Nyaman dengan Belanja di Dekoruma.com!`;

  // @note(dika) 4 Nov 2024: Hardcoded as of now
  // we are not using article posts as footer anymore
  // instead we use template content
  const footerHtml = useMemo(() => {
    return {
      __html: `
      Untuk kamu yang ingin mengubah suasana rumah tanpa ribet, Dekoruma.com punya semua yang kamu butuhkan. Tak hanya menyediakan pilihan ${categoryString} yang lengkap, kami juga hadir dengan berbagai kemudahan untuk membuat pengalaman belanja lebih praktis dan menyenangkan. Kenapa pilih Dekoruma?
      <h3>Cek Harga ${categoryString} Terbaru Setiap Hari!</h3>
      Penasaran dengan harga ${categoryString} terbaru? Di Dekoruma, kamu bisa cek daftar harga setiap harinya! Nikmati pengalaman belanja ${categoryString} online yang mudah dan terjangkau, langsung dari ponselmu.
      <h3>Banyak Promo Setiap Hari</h3>
      Dekoruma selalu hadir dengan promo menarik setiap harinya! Kamu bisa mendapatkan produk ${categoryString} impian dengan harga lebih terjangkau. Jangan lewatkan kesempatan untuk menikmati diskon dan penawaran spesial yang bikin belanja di Dekoruma makin hemat dan menyenangkan!
      <h3>Bayar Fleksibel, Cicilan Ada!</h3>
      Kamu punya rencana besar untuk makeover isi ruangan? Dekoruma menyediakan berbagai metode pembayaran dan cicilan yang memudahkan, bikin kamu tak perlu menunda lagi untuk mendapatkan produk ${categoryString} impian tanpa beban.
      <h3>Pengiriman Bebas Ongkir Se-Indonesia*</h3>
      Untuk kenyamananmu, kami menyediakan layanan bebas ongkir* untuk berbagai lokasi di Indonesia. Cukup pesan, duduk manis, dan tunggu produk pilihanmu sampai ke rumah dengan cepat.
      <h3>Produk Kekinian dengan Desain Modern & Berkualitas</h3>
      Dekoruma menghadirkan koleksi ${categoryString} terbaik dan berkualitas, dengan material, desain, dan model terlengkap. Setiap produk kami selalu up-to-date mengikuti tren terbaru, sehingga kamu bisa langsung menemukan yang sesuai gaya dan kebutuhan ruang. Tinggal pilih, check out, dan siap bawa pulang!
      <h3>Toko Offline Dekoruma di Berbagai Kota</h3>
      Selain itu Dekoruma juga menyediakan ${categoryString} secara offline lewat toko offline Dekoruma yang terdapat di banyak kota di Indonesia. Kamu bisa datang, cek kualitas ${categoryString} kami, dan buktikan sendiri betapa cocoknya produk Dekoruma untuk rumah impianmu.
Langsung saja, kunjungi Dekoruma.com dan rasakan kenyamanan dan keuntungan belanja ${categoryString} secara online tanpa ribet, dengan layanan pengiriman ke seluruh wilayah Indonesia. Tak cuma ${categoryString}, Dekoruma.com juga punya banyak koleksi ${categoryString} lainnya. Jadi, apapun rencana dekorasi ruanganmu, wujudkan semuanya dengan produk berkualitas dan berkelas!
      `,
    };
  }, [categoryString]);
  return (
    <View style={s.container}>
      <Text h2 theme="b4-neutral500" style={cStyles.tac}>
        {footerTitle}
      </Text>
      <Text theme="b5-neutral100">
        <div dangerouslySetInnerHTML={footerHtml} />
      </Text>
    </View>
  );
});

const s = StyleSheet.create({
  container: {
    ...cStyles.pa16,
  },
});

export default CategoryPageFooter;
