import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    position: 'fixed',
    zIndex: 10000,
  },
  top: {
    top: 0,
  },
  bottom: {
    bottom: 0,
  },
  middle: {
    top: '45%',
  },
  right: {
    justifyContent: 'flex-end',
  },
  left: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
});
