import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { getImageName } from 'app-libs/etc';
import {
  getBasketB2BTax,
  getTotalProductVoucherDiscount,
} from 'app-libs/etc/basketHelper';
import { PAYMENT_METHODS } from 'app-libs/redux_modules/entity_modules/basket';
import { getLinesTotalDiscount } from 'app-libs/redux_modules/entity_modules/basket/utils';
import { isOptionValuesEqual } from 'app-libs/redux_modules/entity_modules/productOption';

import { K_BUSINESS_ROLE } from 'constants/userConstants';

const basketSelector = (state) => state.basket;
const basketLinesSelector = (state) => state.basket.lines;
const areaSelector = (state) => state.area;
const authSelector = (state) => state.auth;
const basketAdditionalProductDiscountSelector = (state) =>
  state.basket.additionalProductDiscount;
const basketAdditionalShippingDiscountSelector = (state) =>
  state.basket.additionalShippingDiscount;

// Calculate subtotal - each item * quantity

const basketSubtotalSelector = createSelector(basketLinesSelector, (lines) =>
  lines
    .map((lineItem) => {
      let totalPriceAddon = 0;
      if (lineItem.addonProducts) {
        totalPriceAddon = lineItem.addonProducts
          .map((addonProduct) => {
            return parseFloat(addonProduct.priceInclTaxExclDiscounts);
          })
          .reduce((prev, curr) => prev + curr, 0);
      }
      return parseFloat(lineItem.priceInclTaxExclDiscounts) + totalPriceAddon;
    })
    .reduce((prev, curr) => prev + curr, 0),
);

// Calculate tot quantity
const basketTotalQuantitySelector = createSelector(
  basketLinesSelector,
  (lines) =>
    lines
      .map((lineItem) => {
        let totalLineItemAddon = 0;
        if (lineItem.addonProducts) {
          totalLineItemAddon = lineItem.addonProducts
            .map((addonProduct) => addonProduct.quantity)
            .reduce((prev, curr) => prev + curr, 0);
        }
        return lineItem.quantity + totalLineItemAddon;
      })
      .reduce((prev, curr) => prev + curr, 0),
);

const entitiesSelector = (state) => state.entities;

const searchSelector = (state) => state.search;
const searchResultsSelector = (state) => state.search.searchResults;

const _virtualizeProduct = (product) => {
  const res = Object.assign({}, product);
  if (has(product, 'image') && product.image) {
    res.imageName = getImageName(product.image);
  }
  return res;
};

const _excludeNonBuyableProducts = (products) =>
  products
    .filter(Boolean)
    .filter((product) => !has(product, 'isVisible') || product.isVisible)
    .filter(
      (product) =>
        has(product, 'availability') &&
        has(product.availability, 'isAvailableToBuy') &&
        product.availability.isAvailableToBuy,
    );

const _getProductFromState = (state) => {
  const product = _virtualizeProduct(state.product);

  // Add sibling
  // - case siblings are supplied directly (no need siblingIds) --> now backward compat only
  if (has(product, 'siblings') && !isEmpty(product, 'siblings')) {
    product.siblings = product.siblings.map(
      (id) => state.entities.products[id],
    );
    product.siblings = _excludeNonBuyableProducts(product.siblings);
  }
  // - case siblings are not supplied directly
  if (has(product, 'siblingIds') && product.siblingIds.length) {
    product.siblings = product.siblingIds.map(
      (id) => state.entities.products[id],
    );
    product.siblings = _excludeNonBuyableProducts(product.siblings);
  }

  // Add frequently bought together
  if (
    has(product, 'freqBoughtTogetherIds') &&
    product.freqBoughtTogetherIds.length
  ) {
    product.freqBoughtTogethers = product.freqBoughtTogetherIds.map(
      (id) => state.entities.products[id],
    );
    product.freqBoughtTogethers = _excludeNonBuyableProducts(
      product.freqBoughtTogethers,
    );
  }

  // Add recommendation
  if (
    has(product, 'recommendedSeriesIds') &&
    product.recommendedSeriesIds.length
  ) {
    product.recommendedSeries = product.recommendedSeriesIds.map(
      (id) => state.entities.products[id],
    );
    product.recommendedSeries = _excludeNonBuyableProducts(
      product.recommendedSeries,
    );
  }

  // Add similarItems
  if (has(product, 'similarItemsIds') && product.similarItemsIds.length) {
    product.similarItems = product.similarItemsIds.map(
      (id) => state.entities.products[id],
    );
    product.similarItems = _excludeNonBuyableProducts(product.similarItems);
  }

  // Add userWhoViewedThisAlsoViewedItems
  if (
    has(product, 'userWhoViewedThisAlsoViewedItemsIds') &&
    product.userWhoViewedThisAlsoViewedItemsIds.length
  ) {
    product.userWhoViewedThisAlsoViewedItems =
      product.userWhoViewedThisAlsoViewedItemsIds.map(
        (id) => state.entities.products[id],
      );
    product.userWhoViewedThisAlsoViewedItems = _excludeNonBuyableProducts(
      product.userWhoViewedThisAlsoViewedItems,
    );
  }

  // Add upsellItems
  if (has(product, 'upsellItemsIds') && product.upsellItemsIds.length) {
    product.upsellItems = product.upsellItemsIds.map(
      (id) => state.entities.products[id],
    );
    product.upsellItems = _excludeNonBuyableProducts(product.upsellItems);
  }

  // Add quantity and product option value in the basket
  if (has(product, 'objectID') && !isEmpty(product, 'objectID')) {
    const { selectedProductOptionValues } = state.productOption || {};
    const lineItem = state.basket.lines.find(
      (line) =>
        line.product.stockRecordId === product.stockRecordId &&
        isOptionValuesEqual(selectedProductOptionValues, line.optionValues),
    );
    if (lineItem) {
      product.quantityInTheBasket = lineItem.quantity;
      product.optionValues = lineItem.optionValues;
      product.addonProducts = lineItem.addonProducts;
      product.basketLineId = lineItem.id;
    } else {
      product.quantityInTheBasket = 0;
      product.optionValue = [];
    }
  }

  if (has(product, 'objectID') && !isEmpty(product, 'objectID')) {
    if (state.entities.productOptions[product.objectID]) {
      product.options = state.entities.productOptions[product.objectID].options;
      product.addons = state.entities.productOptions[product.objectID].addons;
    } else {
      product.options = [];
      product.addons = [];
    }
  }

  // set product and product option in each product addon
  if (has(product, 'addons') && product.addons.length) {
    product.addons = product.addons.map((addon) => {
      const availableAddon = addon;
      availableAddon.addonProducts = addon.addonProducts.map((addonProduct) => {
        const availableAddonProduct = addon;
        const upc = addonProduct.productAddon;
        availableAddonProduct.product = _virtualizeProduct(
          state.entities.products[upc],
        );
        availableAddonProduct.product.options = state.entities.productOptions[
          upc
        ]
          ? state.entities.productOptions[upc].options
          : [];
        return availableAddonProduct;
      });
      return availableAddon;
    });
  }
  return product;
};

const _getProductWithChildren = (mutableProduct, state) => {
  const product = Object.assign({}, mutableProduct);

  // Add sibling
  // - case siblings are supplied directly (no need siblingIds) --> now backward compat only
  if (has(product, 'siblings') && !isEmpty(product, 'siblings')) {
    product.siblings = product.siblings
      .map((id) => state.entities.products[id])
      .filter(Boolean);
  }
  // - case siblings are not supplied directly
  if (has(product, 'siblingIds') && product.siblingIds.length) {
    product.siblings = product.siblingIds
      .map((id) => state.entities.products[id])
      .filter(Boolean);
  }

  // Add frequently bought together
  if (
    has(product, 'freqBoughtTogetherIds') &&
    product.freqBoughtTogetherIds.length
  ) {
    product.freqBoughtTogethers = product.freqBoughtTogetherIds
      .map((id) => state.entities.products[id])
      .filter(Boolean);
  }

  // Add recommendation
  if (
    has(product, 'recommendedSeriesIds') &&
    product.recommendedSeriesIds.length
  ) {
    product.recommendedSeries = product.recommendedSeriesIds
      .map((id) => state.entities.products[id])
      .filter(Boolean);
  }

  // Add similarItems
  if (has(product, 'similarItemsIds') && product.similarItemsIds.length) {
    product.similarItems = product.similarItemsIds
      .map((id) => state.entities.products[id])
      .filter(Boolean);
  }

  // Add upsellItems
  if (has(product, 'upsellItemsIds') && product.upsellItemsIds.length) {
    product.upsellItems = product.upsellItemsIds
      .map((id) => state.entities.products[id])
      .filter(Boolean);
  }

  // Add quantity in the basket
  if (has(product, 'objectID') && !isEmpty(product, 'objectID')) {
    const lineItem = state.basket.lines.find(
      (line) => line.product.objectID === product.objectID,
    );
    if (lineItem) {
      product.quantityInTheBasket = lineItem.quantity;
    } else {
      product.quantityInTheBasket = 0;
    }
  }
  return product;
};

// get back product object from entities.products
const productsEntitiesFromHitsSelector = createSelector(
  [searchResultsSelector, entitiesSelector],
  (rSearchResults, rEntities) =>
    rSearchResults.hits.map((objectID) => {
      const product = rEntities.products[objectID];
      return _virtualizeProduct(product);
    }),
  // (rSearchResults, rEntities) => rSearchResults.hits.map( objectID => {
  //   const product = rEntities.products[objectID];
  //   return product;
  // })
);

export const getSearchResults = createSelector(
  [searchResultsSelector, productsEntitiesFromHitsSelector],
  (main, rProducts) =>
    Object.assign({}, main, {
      hits: rProducts,
    }),
);

export const getSearch = createSelector(
  [searchSelector, getSearchResults],
  (main, rSearchResults) =>
    Object.assign({}, main, {
      searchResults: rSearchResults,
    }),
);

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Basket
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

/** Helper for Basket */
function isBasketEmpty(basket) {
  return !(basket.lines && basket.lines.length);
}

function isVoucherAllowed(basket) {
  return !(basket.paymentMethod === PAYMENT_METHODS.CASH_ON_DELIVERY);
}

function virtualizeBasketLine(line) {
  const priceExclTax = line.product.price * line.quantity;
  return Object.assign({}, line, { priceExclTax });
}

/** Implementation */
const _getBasketFromState = createSelector(
  [
    basketSelector,
    basketTotalQuantitySelector,
    basketSubtotalSelector,
    areaSelector,
    authSelector,
    basketAdditionalProductDiscountSelector,
    basketAdditionalShippingDiscountSelector,
  ],
  (
    basket,
    totalQuantity,
    calcSubtotal,
    _,
    auth,
    additionalProductDiscount,
    additionalShippingDiscount,
  ) => {
    // set default
    // Subtotal: subtotal of item prices (before discounts and vouchers)
    const subtotal = calcSubtotal;
    let shippingTotal = 0;
    let shippingTotalBeforeRebate = 0;
    let hasCalculateShippingTotal = false; // to determine whether shipping total really 0, or it has not been calculated

    const { shippingMethod } = basket;
    const isBusinessUser = get(auth, 'user.groups', []).includes(
      K_BUSINESS_ROLE,
    );

    // // 1. replace w/ total from server calculation if exist
    // if (has(basket, 'totalExclTax')) {
    //   subtotal = parseFloat(basket.totalExclTax, 10);
    // }

    // 2. replace w/ shipping total from server calculation if exist
    if (has(basket, 'shippingMethod.price.exclTax')) {
      /*
        @note(dika) 24 Nov 2023: Add additional shipping discount from FE
        to final shippingTotal, only to a minimum of 0 shipping
      */
      hasCalculateShippingTotal = true;
      shippingTotal = Math.max(
        0,
        parseFloat(basket.shippingMethod.price.exclTax, 10) -
          additionalShippingDiscount,
      );
      shippingTotalBeforeRebate = parseFloat(
        basket.shippingMethod.priceBeforeDiscount.exclTax,
        10,
      );
    }

    // 1. calculate each line item
    const lines = basket.lines.map((line) => virtualizeBasketLine(line));
    const shippingClassNames = lines
      .map((line) => line.product.shippingClassName)
      .filter(Boolean);
    const unableToCODLines = lines
      .filter(
        (line) =>
          line.product.availability && !line.product.availability.codAllowed,
      )
      .filter(Boolean);
    lines.forEach((line) => {
      line.addonProducts.forEach((addonProduct) => {
        const addonProductShippingClassName =
          addonProduct.product.shippingClassName;
        if (addonProductShippingClassName) {
          shippingClassNames.push(addonProductShippingClassName);
        }
      });
    });

    /**
     * Old calculation:
     * total = total product (before discount) + total shipping (after discount)
     * New calculation (21 Dec 2020):
     * total = total product (before discount) + total shipping (before discount)
     *
     * Note: total product (before discount) = subtotal = sum(product price * quantity)
     */
    const serviceFee = parseInt(
      basket?.shippingMethod?.serviceFee?.inclTax ?? '0',
      10,
    );
    const total = subtotal + shippingTotalBeforeRebate + serviceFee;

    let totalRebate = getLinesTotalDiscount(lines);
    const maxDiscount = subtotal - totalRebate;
    /** Only count product voucher since shipping voucher is counted in shippingRebate already */
    let totalDiscount = getTotalProductVoucherDiscount(basket, maxDiscount);
    const specialDiscount = Math.min(
      additionalProductDiscount,
      subtotal - totalRebate - totalDiscount,
    );
    totalRebate = totalRebate || 0;
    totalDiscount = totalDiscount || 0;
    const shippingDiscount = shippingTotalBeforeRebate - shippingTotal;
    const totalPayableInclDiscInclShipping =
      parseInt(total, 10) -
      totalRebate -
      totalDiscount -
      specialDiscount -
      shippingDiscount;

    const totalTax = isBusinessUser
      ? getBasketB2BTax(
          basket,
          shippingTotal,
          serviceFee,
          additionalProductDiscount,
        )
      : 0;

    const totalPayable = totalPayableInclDiscInclShipping + totalTax;
    const isCODAllowedByMerchant = !unableToCODLines.length;

    return Object.assign({}, basket, {
      isEmpty: isBasketEmpty(basket),
      isVoucherAllowed: isVoucherAllowed(basket),
      lines,
      totalQuantity,
      subtotal,
      serviceFee,
      specialDiscount,
      shippingTotal,
      shippingTotalBeforeRebate,
      shippingMethod,
      total,
      totalPayable,
      totalTax,
      shippingClassNames,
      hasCalculateShippingTotal,
      isCODAllowedByMerchant,
    });
  },
);

/** Interface */
export const getBasket = _getBasketFromState;

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Product
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

export const getProduct = _getProductFromState;
export const getProductWithChildren = _getProductWithChildren;

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Moodboard
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

/** Implementation */
const _getMoodboardIdsFromState = (state) =>
  state.browse.moodboard.results.hits;

const _getMoodboardsEntitiesFromIds = createSelector(
  [_getMoodboardIdsFromState, entitiesSelector],
  (ids, rEntities) => ids.map((id) => rEntities.moodboards[id]),
);

const _getMoodboardFromState = (state) => state.moodboard;

export const _getMoodboard = createSelector(
  [_getMoodboardFromState, entitiesSelector],
  (main, rEntities) => {
    if (!main.itemIds) return main;

    let items = [];
    if (!main.items.length) {
      // case where where server return only itemIds
      items = main.itemIds
        .map((pid) => rEntities.products[pid])
        .filter(Boolean);
    } else {
      // case where server returns deep object (up to each item)
      ({ items } = main);
    }
    items = items.map((product) => _virtualizeProduct(product));
    return Object.assign({}, main, { items });
  },
);

/** Interface */
export const getMoodboards = _getMoodboardsEntitiesFromIds;
export const getMoodboard = _getMoodboard;

/**
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 * Post
 * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
 */

/** Implementation */
const _getPostsIdsFromState = (state) => state.browse.post.results.hits;

const _getPostsEntitiesFromIds = createSelector(
  [_getPostsIdsFromState, entitiesSelector],
  (ids, rEntities) => ids.map((id) => rEntities.posts[id]).filter(Boolean),
);

/** Interface */
export const getPosts = _getPostsEntitiesFromIds;
export const getPostById = (state, postId) => state.entities.posts[postId];
export const getPost = (state) => state.post;
