import React from 'react';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import { isAllowedCreditCardZeroInterestInstallment } from 'app-libs/etc/paymentHelper';
import {
  useProductPrice,
  useProductStockRecord,
} from 'app-libs/hook_modules/product';

import { K_BUNDLE_OFFER_LABEL_COMBO } from 'constants/offerConstants';

import PriceSection from '../../PriceSection';
import { ProductCardProduct } from '../types';

const K_NUMBER_OF_LINES = 2;

type ProductDescriptionProps = {
  product: ProductCardProduct;
  isOutOfStock: boolean;
};

const ProductDescription: React.FC<ProductDescriptionProps> = ({
  product,
  isOutOfStock,
}) => {
  const { isBrandingPushed = false } = product;
  const stockRecordToUse = useProductStockRecord(product);
  const { price, priceAfterDiscount, discountPercentage } =
    useProductPrice(stockRecordToUse);

  const productDescriptionStyle = isOutOfStock
    ? s.blurDescription
    : s.productDescription;

  const shouldShowMonthlyInstallmentPrice =
    isAllowedCreditCardZeroInterestInstallment({
      totalPayable: priceAfterDiscount,
      ignoreInstallmentBankValidation: true,
    });

  return (
    <View style={productDescriptionStyle}>
      {product.hasBundleOffer && (
        <View style={s.bundleOfferContainer}>
          <Text style={s.bundleOfferText} theme="body5-teal500">
            {K_BUNDLE_OFFER_LABEL_COMBO}
          </Text>
        </View>
      )}
      <Text
        numberOfLines={K_NUMBER_OF_LINES}
        theme="body4-neutral900"
        style={s.title}
      >
        {product.title}
      </Text>
      <PriceSection
        price={price}
        priceAfterDiscount={priceAfterDiscount}
        discountPercentage={discountPercentage}
        isBrandingPushed={isBrandingPushed}
        shouldShowMonthlyInstallmentPrice={shouldShowMonthlyInstallmentPrice}
      />
    </View>
  );
};

const s = StyleSheet.create({
  blurDescription: {
    ...cStyles.mhm,
    ...cStyles.mtm,
    ...cStyles.flex1,
    opacity: 0.4,
  },
  productDescription: {
    ...cStyles.mhm,
    ...cStyles.mtm,
    ...cStyles.flex1,
  },
  title: {
    ...cStyles.mbs,
  },
  priceTag: {
    ...cStyles.mbl,
  },
  bundleOfferContainer: {
    ...cStyles.brs,
    ...cStyles.bwam,
    ...cStyles.flexMiddle,
    ...cStyles.pvxs,
    ...cStyles.phs,
    ...cStyles.mvs,
    borderColor: Colors.C_TEAL_500,
    maxWidth: 'fit-content',
  },
  bundleOfferText: {
    fontSize: 8,
    lineHeight: 12,
  },
});

export default ProductDescription;
