import {
  K_ROUTE_FURNITURE_HOMEPAGE,
  K_ROUTE_MATTRESS,
  K_ROUTE_SEARCH,
  K_DFR,
} from 'constants/routeConstants';

import {
  K_BEDFRAME_ADDON_SLUG,
  K_DIVAN_ADDON_SLUG,
  K_HEADBOARD_ADDON_SLUG,
  K_SLIDING_MATTRESS_ADDON_SLUG,
  K_ADDON_GROUPS_BY_ADDON_SLUG,
  K_MATTRESS_CATEGORY_BREADCRUMB,
  K_CODE_ATTR_MATERIAL_MATTRESS,
  K_CODE_ATTR_FIRMNESS_MATTRESS,
  K_CODE_ATTR_TYPE_MATTRESS,
  K_CODE_ATTR_CATEGORY_MATTRESS,
  K_SIGNATURE_MATTRESS_SERIES,
  K_SIGNATURE_MATTRESS_ADDITIONAL_INFO_BY_SERIES,
} from 'constants/mattressConstants';
import { getFirstBreadcrumbFromProductCategories } from 'app-libs/etc/categoryHelper';

import { getAttributeOf } from 'app-libs/etc/productAttributesUtil';

import {
  isContainAddon,
  getAddonBySlug,
} from 'app-libs/redux_modules/entity_modules/selectors/productOption';

import { makeSerializedParameters, slugify, unslugify } from 'app-libs/etc';
import has from 'lodash/has';

import { K_TRADE_IN_ADDON_SLUG } from 'constants/addonConstants';

export function getSizeOfMattressByLebarMatras(lebarMatras) {
  if (parseInt(lebarMatras, 0)) {
    return `${lebarMatras}x200cm`;
  }
  return lebarMatras;
}

export function getSizeClusterOfMattressByLebarMatras(lebarMatras) {
  switch (parseInt(lebarMatras, 0)) {
    case 90:
    case 100:
      return 'Single';
    case 120:
    case 140:
      return 'Super Single';
    case 160:
      return 'Queen Size';
    case 180:
      return 'King Size';
    case 200:
      return 'Extra King Size';
    case 230:
      return 'Double';
    default:
      return '-';
  }
}

export function getMattressURLFilterByBrand(brand) {
  return `${K_ROUTE_MATTRESS + K_ROUTE_SEARCH}/Semua-Kategori/${slugify(
    brand,
  )}`;
}
export function getMattressURLFilterBySize(lebarMatras) {
  const query = {
    [K_DFR]: JSON.stringify({
      'filteringAttributes.aLebarMatras': [lebarMatras],
    }),
  };
  return `${K_ROUTE_MATTRESS + K_ROUTE_SEARCH}?${makeSerializedParameters(
    query,
  )}`;
}

export function getTotalGroup(addons, blacklistAddonSlugs = []) {
  return getGroups(addons, blacklistAddonSlugs).length;
}

export function getGroups(mutableAddons = [], blacklistAddonSlugs = []) {
  const addons = mutableAddons.filter(
    (addon) =>
      !addon.hidden &&
      !blacklistAddonSlugs.find(
        (blacklistAddonSlug) => blacklistAddonSlug === addon.slug,
      ),
  );
  const groups = [];
  K_ADDON_GROUPS_BY_ADDON_SLUG.forEach((group) => {
    let found = false;
    group.addonSlugs.forEach((addonSlug) => {
      found = found || !!addons.find((addon) => addon.slug === addonSlug);
    });
    if (found) {
      groups.push(group);
    }
  });
  return groups;
}

export function isContainBedframeAddon(addons = []) {
  return getAddonBySlug(addons, K_BEDFRAME_ADDON_SLUG);
}

export function isContainSlidingMattressAddon(addons = []) {
  return getAddonBySlug(addons, K_SLIDING_MATTRESS_ADDON_SLUG);
}

export function getValueSelectedGroupAddon(
  existingQueryParams,
  addons = [],
  blacklistAddonSlugs = [],
) {
  if (__CLIENT__) {
    const groups = getGroups(addons, blacklistAddonSlugs);
    let valueSelectedGroupAddon = 0;
    if (has(existingQueryParams, 'selectedGroupAddon')) {
      const selectedGroupAddon = groups.find(
        (group) =>
          group.value === parseInt(existingQueryParams.selectedGroupAddon, 0),
      );
      if (selectedGroupAddon) {
        valueSelectedGroupAddon = parseInt(
          existingQueryParams.selectedGroupAddon,
          0,
        );
      } else {
        valueSelectedGroupAddon = groups.length ? groups[0].value : 0;
      }
    }
    return valueSelectedGroupAddon;
  }
  const defaultGroup = getGroups(addons, blacklistAddonSlugs)[0];
  return defaultGroup ? defaultGroup.value : -1;
}

export function getSelectedMattressGroupAddon(
  existingQueryParams,
  addons = [],
  blacklistAddonSlugs = [],
) {
  const valueSelectedGroupAddon =
    getValueSelectedGroupAddon(
      existingQueryParams,
      addons,
      blacklistAddonSlugs,
    ) % K_ADDON_GROUPS_BY_ADDON_SLUG.length;
  return K_ADDON_GROUPS_BY_ADDON_SLUG[valueSelectedGroupAddon];
}

export function getAddonNamesBySlug(addonSlug) {
  switch (addonSlug) {
    case K_BEDFRAME_ADDON_SLUG:
      return 'Bedframe';
    case K_HEADBOARD_ADDON_SLUG:
      return 'Sandaran';
    case K_DIVAN_ADDON_SLUG:
      return 'Dipan';
    case K_SLIDING_MATTRESS_ADDON_SLUG:
      return 'Kasur Sorong';
    case K_TRADE_IN_ADDON_SLUG:
      return 'Trade In';
    default:
      return 'Unknown';
  }
}

export function validateAddons(
  mutableAddons,
  selectedAddonProducts = [],
  whitelistAddonSlugs = undefined,
) {
  let addons = mutableAddons;
  if (whitelistAddonSlugs) {
    addons = addons.filter((addon) =>
      whitelistAddonSlugs.some(
        (whitelistAddonSlug) => whitelistAddonSlug === addon.slug,
      ),
    );
  }

  if (isContainAddon(addons, K_HEADBOARD_ADDON_SLUG)) {
    // check headboard
    const selectedHeadboardProduct = selectedAddonProducts.find(
      (addonProduct) => addonProduct.addonSlug === K_HEADBOARD_ADDON_SLUG,
    );
    if (
      selectedHeadboardProduct &&
      isContainAddon(addons, K_DIVAN_ADDON_SLUG)
    ) {
      const selectedDivanProduct = selectedAddonProducts.find(
        (addonProduct) => addonProduct.addonSlug === K_DIVAN_ADDON_SLUG,
      );
      if (!selectedDivanProduct) {
        // remove headboard
        return {
          error: true,
          message:
            'Anda harus memilih dipan terlebih dahulu baru dapat membeli sandaran.',
        };
      }
    }
  }

  if (isContainAddon(addons, K_SLIDING_MATTRESS_ADDON_SLUG)) {
    const selectedSlidingMattressProduct = selectedAddonProducts.find(
      (addonProduct) =>
        addonProduct.addonSlug === K_SLIDING_MATTRESS_ADDON_SLUG,
    );
    if (
      selectedSlidingMattressProduct &&
      isContainAddon(addons, K_DIVAN_ADDON_SLUG)
    ) {
      const selectedDivanProduct = selectedAddonProducts.find(
        (addonProduct) => addonProduct.addonSlug === K_DIVAN_ADDON_SLUG,
      );
      if (!selectedDivanProduct) {
        // remove headboard
        return {
          error: true,
          message:
            'Anda harus memilih dipan terlebih dahulu baru dapat membeli kasur sorong.',
        };
      }
    } else if (
      selectedSlidingMattressProduct &&
      isContainAddon(addons, K_BEDFRAME_ADDON_SLUG)
    ) {
      const selectedBedframeProduct = selectedAddonProducts.find(
        (addonProduct) => addonProduct.addonSlug === K_BEDFRAME_ADDON_SLUG,
      );
      if (!selectedBedframeProduct) {
        // remove headboard
        return {
          error: true,
          message:
            'Anda harus memilih bedframe terlebih dahulu baru dapat membeli kasur sorong.',
        };
      }
    }
  }
  return {
    error: false,
    message: 'Valid',
  };
}

export function isMattressCategory(categories) {
  const breadcrumb = getFirstBreadcrumbFromProductCategories(categories);
  return breadcrumb.startsWith(K_MATTRESS_CATEGORY_BREADCRUMB);
}

export function decodeBrandsFromParam(brandsInParam) {
  if (!brandsInParam) return [];
  return brandsInParam
    .split('--')
    .filter((brand) => brand !== 'semua-brand')
    .map(unslugify);
}

export function encodeBrandsForParam(brands = []) {
  if (brands.length === 0) return 'semua-brand';
  return brands.map(slugify).join('--');
}

export function decodeCategoryMattressFromParam(categoryInParam = '') {
  if (categoryInParam.toLowerCase() === 'semua-kategori') return '';
  return unslugify(categoryInParam);
}

export function encodeCategoryMattressForParam(categoryMattress = '') {
  if (categoryMattress === '') return 'Semua-Kategori';
  return slugify(categoryMattress);
}

export function getParametricRouteSearchMattress(
  paramBrand = 'semua-brand',
  paramCat = 'semua-kategori',
) {
  if (paramBrand.toLowerCase() !== 'semua-brand') {
    return `${K_ROUTE_MATTRESS}${K_ROUTE_SEARCH}/:cat/:brands`;
  }
  if (paramCat.toLowerCase() !== 'semua-kategori') {
    return `${K_ROUTE_MATTRESS}${K_ROUTE_SEARCH}/:cat`;
  }
  return `${K_ROUTE_MATTRESS}${K_ROUTE_SEARCH}`;
}

export function getProductMattressMaterials(attributes) {
  const attrMattressMaterial = getAttributeOf(
    attributes,
    K_CODE_ATTR_MATERIAL_MATTRESS,
  );
  return attrMattressMaterial.value
    ? attrMattressMaterial.value.split(',').map((item) => item.trim())
    : [];
}

export function isProductContainMattressMaterial(
  productMattressMaterials = [],
  mattressMaterial,
) {
  return (
    productMattressMaterials.findIndex(
      (currMaterial) => currMaterial === mattressMaterial,
    ) >= 0
  );
}

export function getProductMattressFirmness(attributes) {
  return getAttributeOf(attributes, K_CODE_ATTR_FIRMNESS_MATTRESS).value;
}

export function getProductMattressCategory(attributes) {
  return getAttributeOf(attributes, K_CODE_ATTR_CATEGORY_MATTRESS).value;
}

export function isMattressTypeIs3in1(attributes) {
  return (
    getAttributeOf(attributes, K_CODE_ATTR_TYPE_MATTRESS).value ===
    'Kasur 3 in 1'
  );
}

export function isMattressTypeIs2in1(attributes) {
  return (
    getAttributeOf(attributes, K_CODE_ATTR_TYPE_MATTRESS).value ===
    'Kasur 2 in 1'
  );
}

export function isMattressSignatureSeries(product) {
  const { title } = product;
  return (
    K_SIGNATURE_MATTRESS_SERIES.findIndex((series) =>
      title.toUpperCase().includes(series),
    ) !== -1
  );
}

export function getSignatureMattressSeries(product) {
  const { title } = product;
  return (
    K_SIGNATURE_MATTRESS_SERIES.find((series) =>
      title.toUpperCase().includes(series),
    ) || ''
  );
}

export function getSignatureMattressAdditionalInfo(product) {
  const series = getSignatureMattressSeries(product);
  return K_SIGNATURE_MATTRESS_ADDITIONAL_INFO_BY_SERIES[series] || {};
}

/**
 * Given
 *   category: 'Kasur > Lady Americana',
 *   prefixPath: '/kasur/search'
 *
 * return [{
 *   name: 'Kasur'
 *   path: '/kasur'
 * }, {
 *   name: 'Semua Kasur'
 *   path: '/kasur/search'
 * }, {
 *   name: 'Lady Americana'
 *   path: '/kasur/search/semua-kategori/Lady-Americana'
 * }]
 */
export function getMattressBreadcrumbItemsFromCategory(
  category,
  prefixPath = '',
  options = {},
) {
  const breadcrumbs = [
    {
      name: 'Kasur',
      path: `${prefixPath}`,
      ...options,
    },
  ];
  if (!category) return breadcrumbs;
  const arrOfCategories = category.split(' > ');
  arrOfCategories.forEach((cat, index) => {
    const paramsMatch = arrOfCategories
      .map((c) => {
        if (options.lowerCase) {
          return slugify(c).toLowerCase();
        }
        return slugify(c);
      })
      .filter((_, i) => i <= index);
    const path =
      paramsMatch && cat !== 'Kasur'
        ? `${prefixPath}${K_ROUTE_SEARCH}/semua-kategori/${paramsMatch.pop()}`
        : `${prefixPath}${K_ROUTE_SEARCH}/`;
    const name = cat === 'Kasur' ? 'Semua Kasur' : cat;
    breadcrumbs.push({ name, path, ...options });
  });
  return breadcrumbs;
}

export function getMattressBreadcrumbItemsFromParams(
  category = '',
  brands = [],
  prefixPath = '',
  options = {},
) {
  const breadcrumbs = [
    {
      name: 'Furniture',
      path: K_ROUTE_FURNITURE_HOMEPAGE,
      doNotAddQueryParameters: true,
    },
    {
      name: 'Kasur',
      path: `${prefixPath}`,
      ...options,
    },
  ];
  const catName = category || 'Semua Kasur';
  const catSlug = encodeCategoryMattressForParam(category);
  let catPath = `/${catSlug}`;
  if (brands.length !== 1 && !category) {
    catPath = '';
  }
  breadcrumbs.push({
    name: catName,
    path: `${prefixPath}${K_ROUTE_SEARCH}${catPath}`,
    ...options,
  });
  if (brands.length !== 1) return breadcrumbs;
  breadcrumbs.push({
    name: brands[0],
    path: `${prefixPath}${K_ROUTE_SEARCH}${catPath}/${brands[0]}`,
  });

  return breadcrumbs;
}
