import { StyleSheet } from 'react-native';
import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    ...cStyles.mbl,
    ...cStyles.phxxl,
    paddingVertical: 20,
    ...cStyles.bgw,
  },

  headerText: {
    ...cStyles.mbl,
  },
});
