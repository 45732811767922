import produce from 'immer';

import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';

import * as installationActions from './actions';
import * as installationSelectors from './selectors';
import {
  AT,
  AvailableInstallationSlotState,
  InstallationActionTypes,
  InstallationAvailability,
  InstallationState,
} from './types';

export const initialState: InstallationState = {
  availableInstallationSlot: {
    asyncState: initialAsyncState,
    availabilityByPartnerName: {},
  },
};

const installationReducer = produce(
  (
    draft: InstallationState = initialState,
    action: InstallationActionTypes,
  ) => {
    draft.availableInstallationSlot = availableInstallationSlotReducer(
      draft.availableInstallationSlot,
      action,
    );
    return draft;
  },
  initialState,
);

const availableInstallationSlotReducer = produce(
  (
    draft: AvailableInstallationSlotState = initialState.availableInstallationSlot,
    action: InstallationActionTypes,
  ) => {
    draft.asyncState = asyncStateReducer(
      draft.asyncState,
      action,
      '_AVAILABLE_INSTALLATION_SLOT_',
    );

    switch (action.type) {
      case AT.LOAD_AVAILABLE_INSTALLATION_SLOT_SUCCESS:
        draft.availabilityByPartnerName = {
          [action.payload.partnerName]: action.result,
        } as Record<string, InstallationAvailability>;
        break;
    }

    return draft;
  },
  initialState.availableInstallationSlot,
);

export { installationActions, installationReducer, installationSelectors };
