import { CheckedHouse } from '@dkrm/icons';

import { K_EXCLUSIVE_BRANDS } from 'constants/productConstants';

export const K_EXCLUDE_FILTERS = [
  'filteringAttributes.aMaterial',
  'filteringAttributes.aFirmnessMatras',
  'filteringAttributes.aLebarMatras',
  'filteringAttributes.aKategoriMatras',
  'sectionAttributes.isBestPrice',
  'sectionAttributes.isMostPopular',
  'vouchers.code',
  'lengthWidthDimension',
  'isOfferAvailable',
  'isBlacklisted',
  'catalogueGroupNames',
  'isNotHavingActiveStock',
  'isOfflineStockOnly',
  'isNotAllowedToReceiveOrder',
  'sectionAttributes.priceRangeLvl1',
  'sectionAttributes.priceRangeLvl2',
  'availablePromoTypes',
  'availability.numAvailable',
  'sectionAttributes.isNewProduct',
  'productOffer.offer.discountValue',
  'special',
  'categories',
];

export const K_FACET_TYPE = {
  DISJUNCTIVE: 'DISJUNCTIVE',
  NUMERICAL: 'NUMERICAL',
};

export const K_AVAILABLE_PROMO_TYPES_FACET = 'availablePromoTypes';
export const K_READY_STOCK_FACET = 'availability.numAvailable';
export const K_DISCOUNT_VALUE_FACET = 'productOffer.offer.discountValue';
export const K_BRAND_FACET = 'brand';
export const K_NEW_ARRIVAL_FACET = 'sectionAttributes.isNewProduct';
export const K_RATING_FACET = 'rating';

export const K_QUICK_FILTER_FACETS = [
  {
    facetName: K_BRAND_FACET,
    facetType: K_FACET_TYPE.DISJUNCTIVE,
    label: 'Exclusive Brand',
    value: K_EXCLUSIVE_BRANDS,
    isUsingMultipleValueDisjunctiveFacet: true,
    Icon: CheckedHouse,
  },
  {
    facetName: K_DISCOUNT_VALUE_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Discount Up To 50%',
    value: { '>=': 1, '<=': 50 },
  },
  {
    facetName: K_DISCOUNT_VALUE_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Discount Up To 80%',
    value: { '>=': 51, '<=': 100 },
  },
  {
    facetName: K_NEW_ARRIVAL_FACET,
    facetType: K_FACET_TYPE.DISJUNCTIVE,
    label: 'New Arrival',
    value: true,
    isUsingDisjunctiveSelector: false,
  },
  {
    facetName: K_RATING_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Rating 4+',
    value: { '>=': 4 },
  },
];

export const K_BRAND_PAGE_QUICK_FILTER_FACETS = [
  {
    facetName: K_DISCOUNT_VALUE_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Discount Up To 50%',
    value: { '>=': 1, '<=': 50 },
  },
  {
    facetName: K_DISCOUNT_VALUE_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Discount Up To 80%',
    value: { '>=': 51, '<=': 100 },
  },
  {
    facetName: K_NEW_ARRIVAL_FACET,
    facetType: K_FACET_TYPE.DISJUNCTIVE,
    label: 'New Arrival',
    value: true,
    isUsingDisjunctiveSelector: false,
  },
  {
    facetName: K_RATING_FACET,
    facetType: K_FACET_TYPE.NUMERICAL,
    label: 'Rating 4+',
    value: { '>=': 4 },
  },
];

export const K_SORT_LABEL = 'Urut Berdasarkan';
