import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import LocationSearchModal from './LocationSearchModal';
import PopularCitiesModal from './PopularCitiesModal';
import UserAddressModal from './UserAddressModal';
import { SearchBarRef } from './types';

interface LocationModalProps {
  actionCloseLocationModal: () => void;
  shouldShowCloseButton: boolean;
}

const K_USER_ADDRESS_MODAL = 'User Address';
const K_POPULAR_CITIES_MODAL = 'Popular Cities';
const K_LOCATION_SEARCH_MODAL = 'Location Search';

const LocationModal: React.FC<LocationModalProps> = memo(
  ({ actionCloseLocationModal, shouldShowCloseButton }) => {
    const searchBarRef = useRef<SearchBarRef>(null);

    const [activeComponent, setActiveComponent] = useState(
      K_USER_ADDRESS_MODAL,
    );

    const [query, setQuery] = useState('');

    const actionGotoPopularCityModal = useCallback(() => {
      setActiveComponent(K_POPULAR_CITIES_MODAL);
    }, []);

    useEffect(() => {
      if (query.length > 2 && activeComponent !== K_LOCATION_SEARCH_MODAL)
        setActiveComponent(K_LOCATION_SEARCH_MODAL);
    }, [activeComponent, query.length]);

    switch (activeComponent) {
      case K_USER_ADDRESS_MODAL:
        return (
          <UserAddressModal
            onClose={actionCloseLocationModal}
            onLocationButtonPress={actionGotoPopularCityModal}
            shouldShowCloseButton={shouldShowCloseButton}
          />
        );

      case K_POPULAR_CITIES_MODAL:
        return (
          <PopularCitiesModal
            ref={searchBarRef}
            onClose={actionCloseLocationModal}
            query={query}
            setQuery={setQuery}
            shouldShowCloseButton={shouldShowCloseButton}
          />
        );
      case K_LOCATION_SEARCH_MODAL:
        return (
          <LocationSearchModal
            ref={searchBarRef}
            actionCloseLocationModal={actionCloseLocationModal}
            query={query}
            setQuery={setQuery}
            shouldShowCloseButton={shouldShowCloseButton}
          />
        );
      default:
        return (
          <UserAddressModal
            onClose={actionCloseLocationModal}
            onLocationButtonPress={actionGotoPopularCityModal}
            shouldShowCloseButton={shouldShowCloseButton}
          />
        );
    }
  },
);

export default LocationModal;
