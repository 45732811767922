import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

export const getQueue = (state) => state.queue;

export const getChannelByChannelName = (queue, channelName) => {
  if (has(queue, `channelByChannelName.${channelName}`)) {
    return queue.channelByChannelName[channelName] || {};
  }
  return {};
};

export const getChannelNames = (queue) =>
  Object.keys(queue.channelByChannelName || {});

export const getJobs = (channel) => channel.jobs || [];

export const getFirstJob = (channel) => {
  const jobs = getJobs(channel);
  if (!jobs || isEmpty(jobs)) return null;
  return jobs[0];
};

export const getAvailableWorker = (channel) => channel.availableWorker;
