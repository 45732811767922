import { getCDNImageUrl } from '@dkrm/general-libs/Utils/imageUtils';

import { Product } from 'app-libs/components/Product/types';

const K_IMAGE_ADDITIONAL_QUERY_PARAMS = '?width=476&height=279';

export const generateProductImagesMeta = (product: Product) => {
  const { image } = product;

  const productImages = [];
  if (image) {
    productImages.push(getCDNImageUrl(image) + K_IMAGE_ADDITIONAL_QUERY_PARAMS);
  }
  return productImages;
};
