import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@dkrm/ui-kit-basic';
import { Modal, ButtonWithText } from '@dkrm/ui-kit-basic/v2';
import { actionRouteActionOpenURL } from '@dkrm/ui-kit-basic/Router';
import withRouter from '@dkrm/general-libs/Utils/withRouter';

import { K_ROUTE_LOGIN, K_ROUTE_SIGNUP } from 'constants/routeConstants';

import styles from './styles';

@withRouter
export default class LoginQueryModal extends Component {
  static propTypes = {
    showLoginQueryModal: PropTypes.bool,
    history: PropTypes.shape({
      location: PropTypes.shape(),
    }),
    loginUrl: PropTypes.string,
    registerUrl: PropTypes.string,
    changeShouldLoginModal: PropTypes.func,
  };

  static defaultProps = {
    showLoginQueryModal: false,
    history: null,
    loginUrl: K_ROUTE_LOGIN,
    registerUrl: K_ROUTE_SIGNUP,
    changeShouldLoginModal: () => null,
  };

  actionRedirectToLogin = () => {
    const { history, loginUrl } = this.props;
    const {
      location: { pathname },
    } = history;
    const url = `${loginUrl}?redirect=${pathname}`;
    actionRouteActionOpenURL(url, history, {
      isUsingAnchor: true,
    });
  };

  actionRedirectToRegister = () => {
    const { history, registerUrl } = this.props;

    actionRouteActionOpenURL(registerUrl, history, {
      isUsingAnchor: true,
    });
  };

  actionCloseShouldLoginModal = () => {
    const { changeShouldLoginModal } = this.props;
    changeShouldLoginModal(false);
  };

  render() {
    const { showLoginQueryModal } = this.props;
    return (
      <Modal visible={showLoginQueryModal} transparent animationType="fade">
        <View style={styles.modalContainer}>
          <View style={styles.confirmationBox}>
            <Text theme="body3-black">Anda perlu Login terlebih dahulu</Text>
            <View style={styles.confirmationButtonContainer}>
              <ButtonWithText
                onPress={this.actionCloseShouldLoginModal}
                title="BATAL"
                theme="grey"
                themeType="tertiary"
              />
              <ButtonWithText
                onPress={this.actionRedirectToRegister}
                title="DAFTAR"
                theme="tosca"
                themeType="tertiary"
              />
              <ButtonWithText
                onPress={this.actionRedirectToLogin}
                title="LOGIN"
                theme="tosca"
                themeType="tertiary"
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}
