import {
  K_SORT,
  K_QUERY,
  K_PAGE,
  K_DFR,
  K_HF,
  K_HFR,
  K_FR,
  K_FR_ALL,
  K_NR,
  K_HITS_PER_PAGE,
  K_DISTINCT,
} from 'constants/routeConstants';
import config from 'config';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { isQueryParamsEqual } from './stateComparator';
import { getBreadcrumbFromParams } from 'app-libs/etc/categoryHelper';
import layout from 'app-libs/etc/layoutHelper';

const showAllValuesPerFacet = 100;
const defaultMaxValuesPerFacet = 100;

export function searchQueryParamsChangeReducer(
  state,
  initialState,
  action = {},
) {
  const newQuery = action.payload.query;
  const newParams = action.payload.params;

  /**
   * Parse get parameter, eg. ?page=1
   */

  const newState = Object.assign({}, state, {
    searchParameters: Object.assign({}, initialState.searchParameters),
  });

  if (!isEmpty(newQuery)) {
    for (const [key, value] of Object.entries(newQuery)) {
      if (key === K_QUERY) newState.searchParameters.query = value;
      if (key === K_DISTINCT) newState.searchParameters.distinct = value;
      if (key === K_PAGE)
        newState.searchParameters.page = parseInt(value, 10) - 1; // back to base 0
      let cleanedValue;
      try {
        cleanedValue = JSON.parse(value);
      } catch (err) {
        cleanedValue = {};
      }
      if (key === K_DFR) {
        newState.searchParameters.disjunctiveFacetsRefinements = cleanedValue;
      }

      if (key === K_NR) {
        newState.searchParameters.numericRefinements = cleanedValue;
      }

      if (key === K_HF) {
        newState.searchParameters.hierarchicalFacets = cleanedValue;
      }

      if (key === K_HFR) {
        newState.searchParameters.hierarchicalFacetsRefinements = cleanedValue;
      }

      // do extra checking if we're not dealing with orders
      if (!isEqual(state.searchIndex, config.ALGOLIA_ORDER_INDEX_NAME)) {
        if (key === K_SORT) {
          newState.searchIndex = value;
          newState.searchParameters.index = value;
        }
        if (key === K_FR_ALL)
          newState.searchParameters.maxValuesPerFacet = value
            ? showAllValuesPerFacet
            : defaultMaxValuesPerFacet;
        if (key === K_HITS_PER_PAGE)
          newState.searchParameters.hitsPerPage = parseInt(value, 10);
        if (key === K_FR) {
          newState.searchParameters.facetsRefinements = cleanedValue;
        }
      }
    }
  }

  // if we're dealing with productSearch, do some extra checking
  if (!isEqual(state.searchIndex, config.ALGOLIA_ORDER_INDEX_NAME)) {
    /*
      Default Value
     */
    if (!newQuery[K_SORT]) {
      newState.searchIndex = initialState.searchIndex;
      newState.searchParameters.index = initialState.searchIndex;
    }

    /**
     * Parse url params, eg. category level /:lvl0/:lvl1
     */
    if (!isEmpty(newParams)) {
      const breadcrumb = getBreadcrumbFromParams(newParams);
      if (breadcrumb.length) {
        newState.searchParameters.hierarchicalFacetsRefinements = {
          hcategories_by_function: [breadcrumb],
        };
        newState.searchParameters.disjunctiveFacets = [
          ...layout.getDisjunctiveFacets(breadcrumb), // contextual filter
        ];
      }
    }
  }

  // Invalidate only if there's difference
  if (!isQueryParamsEqual(state, newState)) {
    newState.invalidated = true;
  }

  return newState;
}
