export const getRoundedRating = (rating: number) => rating.toFixed(1);

export const getNumberInHumanReadableText = (num: number) => {
  /*
    Format number to more human readable text
    e.g.
    Input 917, output '917'
    Input 10101, output '10K'
    Input 201555, output '202K'
  */
  if (num < 1000) return num.toString();
  return `${Math.round(num / 1000).toString()}K`;
};
