import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { HorizontalDivider, Text, View } from '@dkrm/ui-kit-basic';
import { NAV_ARROW_BACK } from '@dkrm/ui-kit-basic/Icon/constants';
import { goBack } from '@dkrm/ui-kit-basic/Router';
import colors from '@dkrm/ui-kit-basic/colors';
import { ButtonWithIcon } from '@dkrm/ui-kit-basic/v2';

import styles from './styles';

@withRouter
export default class NavbarTitleCentered extends React.PureComponent {
  static propTypes = {
    leftButtonIcon: PropTypes.string,
    leftButtonAction: PropTypes.func,
    title: PropTypes.string,
    titleTheme: PropTypes.string,
    titleMaxwidth: PropTypes.string,
    containerStyle: stylePropTypes,
    titleContainerStyle: stylePropTypes,
    separatorStyle: stylePropTypes,
    history: PropTypes.shape().isRequired,
    rightItems: PropTypes.arrayOf(PropTypes.element),
    shouldRenderSeparator: PropTypes.bool,
    renderLeftButton: PropTypes.func,
  };

  static defaultProps = {
    leftButtonIcon: NAV_ARROW_BACK,
    leftButtonAction: null,
    title: '',
    titleTheme: 'h4',
    titleMaxwidth: 'Calc(100% - 96px)',
    containerStyle: null,
    separatorStyle: null,
    titleContainerStyle: null,
    rightItems: [],
    shouldRenderSeparator: true,
    renderLeftButton: null,
  };

  handleLeftButtonAction = () => {
    const { history, leftButtonAction } = this.props;
    if (leftButtonAction) {
      leftButtonAction();
    } else {
      goBack(history);
    }
  };

  renderRightItems() {
    const { rightItems } = this.props;
    if (isEmpty(rightItems)) {
      return null;
    }
    return (
      <View style={styles.rightItemsContainer}>
        {rightItems.map((item, index) => (
          <View key={index}>{item}</View>
        ))}
      </View>
    );
  }

  renderLeftButton = () => {
    const { leftButtonIcon, renderLeftButton } = this.props;
    if (renderLeftButton) {
      return renderLeftButton();
    }

    return (
      <ButtonWithIcon
        onPress={this.handleLeftButtonAction}
        name={leftButtonIcon}
        theme="black"
        iconColor={colors.C_BLACK_100}
        size={24}
        hoverableViewStyle={styles.navigationIcon}
      />
    );
  };

  render() {
    const {
      title,
      containerStyle,
      separatorStyle,
      titleTheme,
      titleMaxwidth,
      titleContainerStyle,
      shouldRenderSeparator,
    } = this.props;
    return (
      <View style={containerStyle}>
        <View style={[styles.titleContainer, titleContainerStyle]}>
          <Text
            theme={titleTheme}
            style={[styles.titleStyle, { maxWidth: titleMaxwidth }]}
          >
            {title}
          </Text>
        </View>
        {this.renderLeftButton()}
        {this.renderRightItems()}
        {shouldRenderSeparator && (
          <HorizontalDivider color={colors.C_BLACK_15} style={separatorStyle} />
        )}
      </View>
    );
  }
}
