import React, { memo } from 'react';

import {
  Colors,
  HorizontalDivider,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { makeParametricUrl, slugify } from 'app-libs/etc';

import cStyles from '@dkrm/general-libs/theme/styles';
import { K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER } from 'constants/routeConstants';
import { OfflineStoreLocationSEOArea } from 'entities/offlineStore/types';

interface OfflineStoreLocationListPageAreaFilterProps {
  seoAreas: OfflineStoreLocationSEOArea[];
}

const OfflineStoreLocationListPageAreaFilter: React.FC<OfflineStoreLocationListPageAreaFilterProps> = memo(
  ({ seoAreas }) => {
    return (
      <View style={s.container}>
        <Text theme="h5-neutral900" style={s.title}>
          Area
        </Text>
        {seoAreas.map((seoArea) => {
          return (
            <React.Fragment key={seoArea.areaName}>
              <ButtonWrapper
                to={makeParametricUrl(
                  K_ROUTE_OFFLINE_STORE_LIST_WITH_AREA_FILTER,
                  {
                    areaSlug: slugify(seoArea.areaName),
                  },
                )}
              >
                {() => (
                  <Text theme="body4-neutral500" style={s.filterText}>
                    Toko Furniture {seoArea.areaName}
                  </Text>
                )}
              </ButtonWrapper>
              <HorizontalDivider style={s.separator} />
            </React.Fragment>
          );
        })}
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: cStyles.mbs,
  title: {
    ...cStyles.phxl,
    marginBottom: 20,
  },
  filterText: {
    ...cStyles.phxl,
  },
  separator: {
    ...cStyles.mvl,
    borderTopColor: Colors.C_NEUTRAL_30,
  },
});

export default OfflineStoreLocationListPageAreaFilter;
