import React from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from '@dkrm/ui-kit-basic';
import * as notificationActions from 'app-libs/redux_modules/flow_modules/notification';

import {
  K_POSITION_BOTTOM_CENTER,
  K_DURATION_SHORT,
} from 'app-libs/components/Notification/BasePopup/constants';

const K_SNACKBAR_MAX_HEIGHT = 50;

export function useSnackBar() {
  const dispatch = useDispatch();

  const showSnackBar = React.useCallback(
    ({
      message = '',
      backgroundColor = Colors.C_SECONDARY_TOSCA_5,
      textStyle = {},
      position = K_POSITION_BOTTOM_CENTER,
      maxHeight = K_SNACKBAR_MAX_HEIGHT,
      duration = K_DURATION_SHORT,
    }) => {
      dispatch(
        notificationActions.showSnackBar({
          message,
          position,
          duration,
          snackBarStyle: {
            maxHeight,
            maxWidth: 480,
            backgroundColor,
          },
          textStyle,
        }),
      );
    },
    [dispatch],
  );

  const hideSnackBar = React.useCallback(() => {
    dispatch(notificationActions.hideSnackBar());
  }, [dispatch]);

  return { showSnackBar, hideSnackBar };
}
