import AT from '../types';

export default function experimentsReducer(mutableState = {}, action = {}) {
  let state = mutableState;

  switch (action.type) {
    case AT.SET_EXPERIMENT_VARIANT:
      const { experiment, variant } = action.payload;

      const newExperimentState = {
        ...state[experiment],
        selectedVariantName: variant,
      };

      state = {
        ...state,
        experiments: {
          ...state.experiments,
          [experiment]: newExperimentState,
        },
      };
      break;
  }
  return state;
}
