import keyMirror from 'keymirror';

import asyncStateReducer, {
  initialAsyncState,
} from '../../helper_modules/asyncState';

export const AT = keyMirror({
  LOAD_PROJECT_LABEL: null,
  LOAD_PROJECT_LABEL_SUCCESS: null,
  LOAD_PROJECT_LABEL_FAIL: null,
  UPDATE_PROJECT_LABEL: null,
  UPDATE_PROJECT_LABEL_SUCCESS: null,
  UPDATE_PROJECT_LABEL_FAIL: null,
});

export const initialState = {
  asyncState: { ...initialAsyncState },
};

export default function projectLabelReducer(
  immutableState = initialState,
  action,
) {
  const state = { ...immutableState };
  state.asyncState = asyncStateReducer(
    state.asyncState,
    action,
    '_PROJECT_LABEL_',
  );
  return state;
}
