import { unslugify } from 'app-libs/etc/';
import { useParams } from 'app-libs/hook_modules/router';

import { K_HOST, K_ROUTE_OFFLINE_STORE_LIST } from 'constants/routeConstants';

export const useOfflineStoreLocationListPageMetaTitleAndDescriptionAndUrl =
  () => {
    const { areaSlug } = useParams<{ areaSlug?: string }>();
    const areaName = unslugify(areaSlug);

    let title = 'Lokasi Toko Furniture Dekoruma di Seluruh Indonesia';
    let description =
      'Daftar lokasi toko furniture/showroom Dekoruma yang tersebar di seluruh Indonesia. Kunjungi lokasi terdekat dengan Anda dan dapatkan promo menarik lainnya.';
    let url = `${K_HOST}${K_ROUTE_OFFLINE_STORE_LIST}`;

    if (areaName) {
      title = `Lokasi Toko Furniture Dekoruma di ${areaName} | Dekoruma.com`;
      description = `Daftar lokasi toko furniture/showroom Dekoruma di ${areaName}. Kunjungi lokasi terdekat dengan Anda dan dapatkan promo menarik lainnya.`;
      url = `${K_HOST}${K_ROUTE_OFFLINE_STORE_LIST}/${areaSlug}`;
    }

    return {
      title,
      description,
      url,
    };
  };
