import keyMirror from 'keymirror';
import config from 'config';
import produce from 'immer';

import asyncStateReducer, {
  initialAsyncState,
} from 'app-libs/redux_modules/helper_modules/asyncState';

export const AT = keyMirror({
  LOAD_GLOBAL_HOLIDAY: null,
  LOAD_GLOBAL_HOLIDAY_SUCCESS: null,
  LOAD_GLOBAL_HOLIDAY_FAIL: null,
});

const initialState = {
  globalHolidayAsyncState: initialAsyncState,
  globalHoliday: {},
};

const calendarReducer = produce((draft = initialState, action) => {
  draft.globalHolidayAsyncState = asyncStateReducer(
    draft.globalHolidayAsyncState,
    action,
    '_GLOBAL_HOLIDAY_',
  );

  if (action.type === AT.LOAD_GLOBAL_HOLIDAY_SUCCESS) {
    const { hits: holidays } = action.result;
    const holidayEventByDateStart = {};
    holidays.forEach((holiday) => {
      holidayEventByDateStart[holiday.dateStart] = holiday;
    });

    draft.globalHoliday = {
      ...draft.globalHoliday,
      ...holidayEventByDateStart,
    };
  }

  return draft;
}, initialState);

export const loadGlobalHolidays = ({ dateStart, dateEnd }) => ({
  types: [
    AT.LOAD_GLOBAL_HOLIDAY,
    AT.LOAD_GLOBAL_HOLIDAY_SUCCESS,
    AT.LOAD_GLOBAL_HOLIDAY_FAIL,
  ],
  promise: (client) =>
    client.get(
      `${config.API_URL_GOBLIN}/partners/holidays/?date_start=${dateStart}&date_end=${dateEnd}`,
    ),
});

export default calendarReducer;
