import React from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';

import { ACTION_SORT } from '@dkrm/ui-kit-basic/Icon/constants';

import {
  ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME,
  ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME,
  ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME,
  ALGOLIA_DEFAULT_INDEX_NAME,
} from 'app-libs/redux_modules/flow_modules/search';
import { IndexSetterComponent } from 'app-libs/SearchKit/components';

import cStyles from '@dkrm/general-libs/theme/styles';
import styles from './styles';

function getSortingIndexes() {
  return [
    {
      label: 'Recommended',
      value: ALGOLIA_DEFAULT_INDEX_NAME,
    },
    {
      label: 'Terpopular',
      value: ALGOLIA_SORT_MOST_POPULAR_INDEX_NAME,
    },
    {
      label: 'Harga Rendah ke Tinggi',
      value: ALGOLIA_SORT_PRICE_LOWEST_INDEX_NAME,
    },
    {
      label: 'Terbaru',
      value: ALGOLIA_SORT_NEW_ARRIVAL_INDEX_NAME,
    },
  ];
}

function getSortConfig(props) {
  const { isRounded } = props;
  const buttonStyle = isRounded ? styles.buttonStyle : null;
  const sortConfig = {
    listProps: {
      buttonProps: {
        textStyle: cStyles.fwb,
        align: 'left',
        small: true,
        iconName: ACTION_SORT,
        iconColor: 'black',
        reversed: true,
        buttonStyle,
        style: buttonStyle,
      },
    },
  };

  return sortConfig;
}

const SortButton = (props) => {
  const { getFilterConfig, onChange } = props;

  const sortConfig = getSortConfig(props);
  merge(sortConfig, getFilterConfig('sort'));
  return (
    <IndexSetterComponent
      indexes={getSortingIndexes()}
      {...sortConfig}
      onChange={onChange}
    />
  );
};

SortButton.propTypes = {
  getFilterConfig: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isRounded: PropTypes.bool,
};

SortButton.defaultProps = {
  onChange: () => null,
  isRounded: false,
};

export default SortButton;
