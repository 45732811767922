import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Close } from '@dkrm/icons';
import {
  Colors,
  HorizontalDivider,
  StyleSheet,
  Text,
  View,
} from '@dkrm/ui-kit-basic';
import { ButtonWrapper } from '@dkrm/ui-kit-basic/v2';

import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';
import { getCustomerShippingAddresses } from 'app-libs/redux_modules/entity_modules/selectors/customer';
import { isLoggedIn as getIsLoggedIn } from 'app-libs/redux_modules/selectors/auth';

import LocationButton from './LocationButton';
import LoginButton from './LoginButton';
import UserAddressList from './UserAddressList';

interface UserAddressModalProps {
  onClose: () => void;
  onLocationButtonPress: () => void;
  shouldShowCloseButton: boolean;
}

const UserAddressModal: React.FC<UserAddressModalProps> = memo(
  ({ onClose, onLocationButtonPress, shouldShowCloseButton }) => {
    const isLoggedIn = useSelector(getIsLoggedIn);
    const customerShippingAddresses = useSelector<
      void,
      UserAddressResponseFromGoblin[]
    >(getCustomerShippingAddresses);

    return (
      <View style={s.container}>
        {shouldShowCloseButton ? (
          <ButtonWrapper
            onPress={onClose}
            hoverableViewStyle={{ alignSelf: 'flex-end' }}
          >
            {() => <Close size={24} theme="black" />}
          </ButtonWrapper>
        ) : (
          <View style={cStyles.mvl} />
        )}
        <Text theme="h4" style={cStyles.mvm}>
          Pilih Tujuan Pengiriman
        </Text>
        <Text theme="body3-black60" style={cStyles.mbxl}>
          Untuk memudahkan pengalaman belanja Kamu. Yuk pilih tujuan pengiriman
          sekarang
        </Text>
        {isLoggedIn ? (
          customerShippingAddresses.length > 0 && (
            <>
              <UserAddressList onClose={onClose} />
              <HorizontalDivider
                color={Colors.C_BLACK_10}
                style={{ marginVertical: 10 }}
              />
              <Text theme="h5-black40" style={s.dividerText}>
                Atau
              </Text>
            </>
          )
        ) : (
          <LoginButton />
        )}
        <LocationButton onPress={onLocationButtonPress} />
      </View>
    );
  },
);

const s = StyleSheet.create({
  container: {
    ...cStyles.height100,
    ...cStyles.paxxl,
    ...cStyles.width100,
  },
  buttonContainer: {
    ...cStyles.brm,
    ...cStyles.bwam,
    ...cStyles.mvm,
    borderColor: Colors.C_BLACK_10,
  },
  dividerText: {
    ...cStyles.bgw,
    ...cStyles.mbs,
    alignSelf: 'center',
    marginTop: -20,
    paddingHorizontal: 20,
    zIndex: 1,
  },
  searchButton: {
    ...cStyles.flexDirRow,
    ...cStyles.pal,
  },
});

export default UserAddressModal;
