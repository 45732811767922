import config from 'config';

import { ApiClient } from 'types';
import { Activity } from './types';

export const loadActivitiesAPI = (
  client: ApiClient<Activity[]>,
  processKeys: string[],
) =>
  client.post(`${config.API_URL_RONAN}/activities-by-processes?format=json`, {
    data: {
      processKeys,
    },
  });
