import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrentLocationFromLocalStorage,
  isCurrentLocationSet as getIsCurrentLocationInLocalStorageExists,
} from 'app-libs/etc/locationHelper';
import {
  useLocationAPIUtils,
  useLocationChange,
} from 'app-libs/hook_modules/location';
import { useParams, useQueries } from 'app-libs/hook_modules/router';
import { loadBasket } from 'app-libs/redux_modules/entity_modules/basket';
import { BasketShippingAddress } from 'app-libs/redux_modules/entity_modules/basket/types';
import { getCategoryAreaSeos } from 'app-libs/redux_modules/entity_modules/categoryAreaSeo/selectors';
import { loadShippingAddress } from 'app-libs/redux_modules/entity_modules/customer';
import { UserAddressResponseFromGoblin } from 'app-libs/redux_modules/entity_modules/customer/types';
import { setCurrentLocation } from 'app-libs/redux_modules/entity_modules/location/reducer';
import {
  getCurrentLocation,
  getIsCurrentLocationSet,
} from 'app-libs/redux_modules/entity_modules/location/selectors';
import { LocationState } from 'app-libs/redux_modules/entity_modules/location/types';
import {
  getBasketBasketShippingAddress,
  isBasketLoaded as getIsBasketLoaded,
} from 'app-libs/redux_modules/entity_modules/selectors/basket';
import {
  getCustomerMainShippingAddress,
  isShippingAddressLoaded as getIsShippingAddressLoaded,
} from 'app-libs/redux_modules/entity_modules/selectors/customer';
import { getShippingAreaByViperId } from 'app-libs/redux_modules/flow_modules/area';
import { ShippingAreaFromGoblin } from 'app-libs/redux_modules/flow_modules/area/types';
import { dontShowPromptOnError } from 'app-libs/redux_modules/flow_modules/notification';
import {
  isAuthLoaded as getIsAuthLoaded,
  isLoggedIn as getIsLoggedIn,
  getIsShopAssistant,
  getIsUserOrderOnBehalf,
  getStoreAddress,
} from 'app-libs/redux_modules/selectors/auth';

import { OfflineStoreLocation } from 'entities/offlineStore/types';

export const useLoadUserAddressesEffect = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isUserOrderOnBehalf: boolean = useSelector(getIsUserOrderOnBehalf);

  const isShippingAddressLoaded = useSelector(getIsShippingAddressLoaded);

  useEffect(() => {
    if (isLoggedIn && !isShippingAddressLoaded && !isUserOrderOnBehalf) {
      dispatch(dontShowPromptOnError(loadShippingAddress()));
    }
  }, [dispatch, isLoggedIn, isShippingAddressLoaded, isUserOrderOnBehalf]);
};

export const useSetLoggedUserLocationEffect = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isUserOrderOnBehalf: boolean = useSelector(getIsUserOrderOnBehalf);

  const isShippingAddressLoaded = useSelector(getIsShippingAddressLoaded);
  const mainShippingAddress = useSelector<void, UserAddressResponseFromGoblin>(
    getCustomerMainShippingAddress,
  );

  const currentLocation: LocationState = useSelector(getCurrentLocation);
  const isCurrentLocationSet = useSelector(getIsCurrentLocationSet);

  const { handleLocationChangeFromUserAddress } = useLocationChange();
  const { actionCreateDraftUserShippingAddressFromShippingArea } =
    useLocationAPIUtils();

  const isMainShippingAddressExists =
    mainShippingAddress && !!mainShippingAddress.id;
  const isLoggedInAndShippingAddressLoaded =
    isLoggedIn && isShippingAddressLoaded;

  useEffect(() => {
    if (
      isLoggedInAndShippingAddressLoaded &&
      !isUserOrderOnBehalf &&
      mainShippingAddress
    ) {
      handleLocationChangeFromUserAddress({
        userAddress: mainShippingAddress,
        isSavedFromUserAction: true,
        shouldReloadBasket: false,
      });
    }
  }, [
    handleLocationChangeFromUserAddress,
    isLoggedInAndShippingAddressLoaded,
    isUserOrderOnBehalf,
    mainShippingAddress,
  ]);

  useEffect(() => {
    if (
      isLoggedInAndShippingAddressLoaded &&
      isCurrentLocationSet &&
      !isUserOrderOnBehalf &&
      !isMainShippingAddressExists
    ) {
      actionCreateDraftUserShippingAddressFromShippingArea({
        shippingAreaGoblin: {
          id: currentLocation.id,
          city: currentLocation.city,
          district: currentLocation.district,
          province: currentLocation.province,
          island: currentLocation.island,
          zoneCode: currentLocation.zoneCode,
          routingZoneCode: currentLocation.routingZoneCode,
        },
        onSuccess: () => {
          dispatch(loadBasket());
        },
      });
    }
  }, [
    actionCreateDraftUserShippingAddressFromShippingArea,
    currentLocation.city,
    currentLocation.district,
    currentLocation.id,
    currentLocation.island,
    currentLocation.province,
    currentLocation.routingZoneCode,
    currentLocation.zoneCode,
    dispatch,
    isCurrentLocationSet,
    isLoggedInAndShippingAddressLoaded,
    isMainShippingAddressExists,
    isUserOrderOnBehalf,
  ]);
};

export const useSetAnonymousOrOnBehalfUserLocationEffect = () => {
  const dispatch = useDispatch();

  const basketShippingAddress: BasketShippingAddress | null = useSelector(
    getBasketBasketShippingAddress,
  );
  const isBasketLoaded = useSelector(getIsBasketLoaded);

  const currentLocation: LocationState = useSelector(getCurrentLocation);
  const isCurrentLocationSet = useSelector(getIsCurrentLocationSet);

  const isShopAssistant = useSelector(getIsShopAssistant);
  const isUserOrderOnBehalf: boolean = useSelector(getIsUserOrderOnBehalf);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isAuthLoaded = useSelector(getIsAuthLoaded);

  const shopAssistantCurrentStoreLocation: OfflineStoreLocation =
    useSelector(getStoreAddress);

  const isAnonymousOrOnBehalf =
    isAuthLoaded && (!isLoggedIn || isUserOrderOnBehalf);

  const { actionCreateBasketShippingAddressFromShippingArea } =
    useLocationAPIUtils();

  // if user is anonymous / order on behalf (osa, sda, etc) and the basket
  // already has address, use the address as existing location
  useEffect(() => {
    if (
      isBasketLoaded &&
      basketShippingAddress?.shippingArea &&
      isAnonymousOrOnBehalf
    ) {
      dispatch(setCurrentLocation(basketShippingAddress.shippingArea, true));
    }
  }, [isBasketLoaded, basketShippingAddress, dispatch, isAnonymousOrOnBehalf]);

  // If user is shop assistant and not yet select basket with address
  // then use the store location address as the location
  useEffect(() => {
    if (
      isShopAssistant &&
      !basketShippingAddress?.shippingArea &&
      shopAssistantCurrentStoreLocation.shippingAreaId
    ) {
      dispatch(
        setCurrentLocation(
          {
            id: shopAssistantCurrentStoreLocation.shippingAreaId,
            district: shopAssistantCurrentStoreLocation.line3,
            city: shopAssistantCurrentStoreLocation.line4,
            province: shopAssistantCurrentStoreLocation.state,
            island: shopAssistantCurrentStoreLocation.island,
            zoneCode: shopAssistantCurrentStoreLocation.zoneCode,
            routingZoneCode:
              shopAssistantCurrentStoreLocation.routingZoneCode as string,
          },
          true,
        ),
      );
    }
  }, [
    basketShippingAddress,
    dispatch,
    isShopAssistant,
    shopAssistantCurrentStoreLocation.island,
    shopAssistantCurrentStoreLocation.line3,
    shopAssistantCurrentStoreLocation.line4,
    shopAssistantCurrentStoreLocation.routingZoneCode,
    shopAssistantCurrentStoreLocation.shippingAreaId,
    shopAssistantCurrentStoreLocation.state,
    shopAssistantCurrentStoreLocation.zoneCode,
  ]);

  // if currentLocation exists in redux / localStorage (backward compatibility)
  // then create the address to BE
  // Exclude SDA for the address creation to avoid confusion within the sda + so they can still use
  // search address feature (in checkout page)
  useEffect(() => {
    if (
      isBasketLoaded &&
      !basketShippingAddress?.shippingArea &&
      isAnonymousOrOnBehalf &&
      !isShopAssistant
    ) {
      const isLocationFromLocalStorageExists =
        getIsCurrentLocationInLocalStorageExists();
      const locationFromLocalStorage = getCurrentLocationFromLocalStorage();

      if (isCurrentLocationSet) {
        actionCreateBasketShippingAddressFromShippingArea({
          shippingAreaGoblin: {
            id: currentLocation.id,
            district: currentLocation.district,
            city: currentLocation.city,
            province: currentLocation.province,
            island: currentLocation.island,
            zoneCode: currentLocation.zoneCode,
            routingZoneCode: currentLocation.routingZoneCode,
          },
          onSuccess: () => {
            dispatch(loadBasket());
          },
        });
      } else if (isLocationFromLocalStorageExists) {
        actionCreateBasketShippingAddressFromShippingArea({
          shippingAreaGoblin: {
            id: locationFromLocalStorage.id,
            district: locationFromLocalStorage.district,
            city: locationFromLocalStorage.city,
            province: locationFromLocalStorage.province,
            island: locationFromLocalStorage.island,
            zoneCode: locationFromLocalStorage.zoneCode,
            routingZoneCode: locationFromLocalStorage.routingZoneCode,
          },
          onSuccess: () => {
            dispatch(loadBasket());
          },
        });
      }
    }
  }, [
    actionCreateBasketShippingAddressFromShippingArea,
    basketShippingAddress,
    currentLocation.city,
    currentLocation.district,
    currentLocation.id,
    currentLocation.island,
    currentLocation.province,
    currentLocation.routingZoneCode,
    currentLocation.zoneCode,
    dispatch,
    isAnonymousOrOnBehalf,
    isBasketLoaded,
    isCurrentLocationSet,
    isShopAssistant,
  ]);
};

export const useSetLocationFromURLEffect = () => {
  const dispatch = useDispatch();
  const params = useParams<Record<string, string>>();
  const queries = useQueries();

  const currentLocation: LocationState = useSelector(getCurrentLocation);
  const categoryAreaSeos = useSelector(getCategoryAreaSeos);

  const isUserOrderOnBehalf: boolean = useSelector(getIsUserOrderOnBehalf);
  const isAuthLoaded: boolean = useSelector(getIsAuthLoaded);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const {
    actionCreateBasketShippingAddressFromShippingArea,
    actionCreateDraftUserShippingAddressFromShippingArea,
  } = useLocationAPIUtils();

  const locationFromLocalStorage = getCurrentLocationFromLocalStorage();

  let locationId: number | null = null;
  let isCurrentLocationSavedFromUserAction = false;

  if (currentLocation.isCurrentLocationSavedFromUserAction) {
    locationId = currentLocation.id;
    ({ isCurrentLocationSavedFromUserAction } = currentLocation);
  } else if (locationFromLocalStorage?.isCurrentLocationSavedFromUserAction) {
    locationId = locationFromLocalStorage.id;
    ({ isCurrentLocationSavedFromUserAction } = locationFromLocalStorage);
  }

  /**
   * Only set location if:
   * 1. areaId not equal currently selected location id
   * 2. current location is not set by user action
   * 3. anonymous user / non dekoruma staff user (this is for ads purpose so dekoruma staff is not important)
   */
  const setCurrentLocationFromAreaId = useCallback(
    async (areaId: number) => {
      const isUserLoggedInNonOrderOnBehalf = isLoggedIn && !isUserOrderOnBehalf;
      const isAnonymousUser = isAuthLoaded && !isLoggedIn;

      if (
        areaId &&
        areaId !== locationId &&
        !isCurrentLocationSavedFromUserAction &&
        (isUserLoggedInNonOrderOnBehalf || isAnonymousUser)
      ) {
        const { result: shippingArea } = await dispatch(
          dontShowPromptOnError(getShippingAreaByViperId(areaId)),
        );

        if (shippingArea) {
          if (isUserLoggedInNonOrderOnBehalf) {
            actionCreateDraftUserShippingAddressFromShippingArea({
              shippingAreaGoblin: shippingArea,
              onSuccess: () => {
                dispatch(loadBasket());
              },
              onError: () => {
                dispatch(setCurrentLocation(shippingArea, false));
              },
            });
          } else if (isAnonymousUser) {
            actionCreateBasketShippingAddressFromShippingArea({
              shippingAreaGoblin: shippingArea,
              onSuccess: () => {
                dispatch(loadBasket());
              },
              onError: () => {
                dispatch(setCurrentLocation(shippingArea, false));
              },
            });
          }
        }
      }
    },
    [
      locationId,
      isCurrentLocationSavedFromUserAction,
      isAuthLoaded,
      isLoggedIn,
      isUserOrderOnBehalf,
      dispatch,
      actionCreateDraftUserShippingAddressFromShippingArea,
      actionCreateBasketShippingAddressFromShippingArea,
    ],
  );

  useEffect(() => {
    const handleAreaIdPresentInQueries = async () => {
      if ('areaId' in queries) {
        const { areaId } = queries;
        setCurrentLocationFromAreaId(parseInt(areaId, 10));
      }
    };

    handleAreaIdPresentInQueries();
  }, [queries, setCurrentLocationFromAreaId]);

  useEffect(() => {
    const handleAreaSlugPresentInParams = async () => {
      if ('areaSlug' in params && 'lvl0' in params) {
        const { areaSlug, lvl0: categorySlug } = params;
        const categoryAreaSeo =
          categoryAreaSeos?.[`${categorySlug}+${areaSlug}`];
        const shippingAreaId =
          categoryAreaSeo?.seoArea?.shippingArea?.viperExternalId;

        if (shippingAreaId) {
          setCurrentLocationFromAreaId(shippingAreaId);
        }
      }
    };

    handleAreaSlugPresentInParams();
  }, [params, setCurrentLocationFromAreaId, categoryAreaSeos]);
};

export const genLocationName = (location: ShippingAreaFromGoblin) => {
  return location?.district && location?.city
    ? `${location?.district}, ${location?.city}`
    : '...';
};
