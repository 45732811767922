import {
  K_AVAILABILITY_SHOW_INSTANT,
  K_AVAILABILITY_MTO,
  K_AVAILABILITY_EMPTY_STOCK,
} from 'constants/shippingConstants';
import { K_FREE_SHIPPING_BRANDS_IN_JAVA } from 'constants/productConstants';

export const isFreeShipping = (brand, island) =>
  K_FREE_SHIPPING_BRANDS_IN_JAVA.includes(brand) && island === 'Jawa';

export const getStockConstant = ({
  numAvailable,
  backorderAllowed,
  isAvailableToBuy,
}) => {
  if (!isAvailableToBuy) return K_AVAILABILITY_EMPTY_STOCK;

  if (numAvailable && numAvailable > 0) return K_AVAILABILITY_SHOW_INSTANT;

  if (backorderAllowed) return K_AVAILABILITY_MTO;

  return K_AVAILABILITY_EMPTY_STOCK;
};

export const getShippingTotalWithoutVoucher = (
  shippingTotalBeforeRebate,
  shippingRebate,
  totalShippingVoucher,
) => {
  const shippingRebateNonVoucher = shippingRebate - totalShippingVoucher;
  return shippingTotalBeforeRebate - shippingRebateNonVoucher;
};
