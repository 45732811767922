import { useEffect } from 'react';

import { expiredDateInUTC, setCookie } from 'app-libs/etc/cookieHelper';
import { useQueries } from 'app-libs/hook_modules/router';

const K_SHOPBACK_TRANSACION_ID_COOKIE_EXPIRY_TIME_IN_DAYS = 14;
const K_QUERY_PARAMS_SOURCE_SHOPBACK = 'shopback';
const K_COOKIE_NAME_SHOPBACK_TRANSACTION_ID = 'shopbackTransactionId';
const K_COOKIE_PATH_SHOPBACK_TRANSACTION_ID = '/';
const K_COOKIE_DOMAIN_DEKORUMA_COM = '.dekoruma.com';

export const useSetShopbackDataEffect = () => {
  const { source, transaction_id: transactionId } = useQueries();

  useEffect(() => {
    // @note(dika) 12 Aug 2024: Set `transaction_id` data to cookie for later use in checkout
    // This is used for affiliate tracking by shopback
    if (source === K_QUERY_PARAMS_SOURCE_SHOPBACK && !!transactionId) {
      const expiredDate = expiredDateInUTC(
        K_SHOPBACK_TRANSACION_ID_COOKIE_EXPIRY_TIME_IN_DAYS,
      );
      setCookie(
        K_COOKIE_NAME_SHOPBACK_TRANSACTION_ID,
        transactionId,
        expiredDate,
        K_COOKIE_PATH_SHOPBACK_TRANSACTION_ID,
        K_COOKIE_DOMAIN_DEKORUMA_COM,
      );
    }
  }, [transactionId, source]);
};
